import React from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { MangoTurnBack } from '@intellihr/blueberry'
import { useErrorFooterButtons } from 'src/services/error/components/ErrorFooter'
import { StyledTurnBackWrapper, StyledTurnBackUl } from '../style'

interface IErrorBoundaryContentProps {
  errorId: string
  onReloadPageClick: () => void
}

const ErrorBoundaryContent: React.FC<IErrorBoundaryContentProps> = ({ errorId, onReloadPageClick }) => {
  const tCommon = useScope('common:error')
  const errorFooterButtons = useErrorFooterButtons()

  return (
    <StyledTurnBackWrapper>
      <MangoTurnBack
        variant="generic-error"
        title={tCommon('EncounterError.headerPrimary', { defaultValue: 'System error' })}
        description={[
          tCommon('EncounterError.headerSecondary', {
            defaultValue: 'Something went wrong on our end',
          }),
          tCommon('EncounterError.contentHeader', {
            defaultValue: 'Try the following to resolve the issue:',
          }),
          <StyledTurnBackUl key="resolve-list">
            <li>
              <a onClick={onReloadPageClick}>
                {tCommon('EncounterError.contentRefreshLink', { defaultValue: 'Refresh' })}
              </a>
              {tCommon('EncounterError.contentRefreshText', { defaultValue: ' the page and try again' })}
            </li>
            <li>
              <a
                className="request-support"
                href="https://help.humanforce.com/hc/en-au/requests/new"
                target="_blank"
                rel="noreferrer"
              >
                {tCommon('EncounterError.contentContactLink', {
                  defaultValue: 'Contact our Customer Support ',
                })}
              </a>
              {tCommon('EncounterError.contentContactText', {
                defaultValue: 'and use the following reference:',
              })}
              {errorId}
            </li>
          </StyledTurnBackUl>,
        ]}
        actions={errorFooterButtons}
      />
    </StyledTurnBackWrapper>
  )
}

const NetworkErrorContent: React.FC<IErrorBoundaryContentProps> = ({ errorId, onReloadPageClick }) => {
  const tCommon = useScope('common:error', { dontErrorOnAccentConnectionFailure: true })
  const errorFooterButtons = useErrorFooterButtons()

  return (
    <StyledTurnBackWrapper>
      <MangoTurnBack
        variant="connection-error"
        title={tCommon('NetworkError.headerPrimary', { defaultValue: "You're offline" })}
        description={[
          tCommon('NetworkError.headerSecondary', {
            defaultValue: 'You do not seem to be able to access our servers.',
          }),
          tCommon('NetworkError.contentHeader', { defaultValue: 'This could be caused by:' }),
          <StyledTurnBackUl key="resolve-list">
            <li>
              {tCommon('NetworkError.issueUserBody', {
                defaultValue: `Something on your end blocking your browser from accessing our servers, such as a vpn connection.
                    Contact your tech team to resolve this issue.`,
              })}
              <a
                target="_blank"
                href="https://help.humanforce.com/hc/en-au/articles/9122376423823-Accessing-intelliHR-when-using-a-VPN"
                rel="noreferrer"
              >
                {tCommon('NetworkError.issueUserLink', { defaultValue: ' This article ' })}
              </a>
              {tCommon('NetworkError.issueUserText', { defaultValue: 'may be helpful.' })}
            </li>
            <li>
              {tCommon('NetworkError.issueServerBody', {
                defaultValue: 'Our servers might currently be undergoing some maintenance. Wait 5 minutes and then',
              })}
              <a onClick={onReloadPageClick}>
                {tCommon('NetworkError.issueServerLink', { defaultValue: ' Refresh.' })}
              </a>
            </li>
          </StyledTurnBackUl>,
          tCommon('NetworkError.issueNoneHeader', { defaultValue: 'If none of the above works:' }),
          <StyledTurnBackUl key="support-list">
            <li>
              <a
                href="https://help.humanforce.com/hc/en-au/requests/new"
                target="_blank"
                rel="noreferrer"
              >
                {tCommon('EncounterError.contentContactLink', {
                  defaultValue: 'Contact our Customer Support ',
                })}
              </a>
              {tCommon('NetworkError.issueNoneContactText', { defaultValue: 'and use the following reference:' })}
              {errorId}
            </li>
          </StyledTurnBackUl>,
        ]}
        actions={errorFooterButtons}
      />
    </StyledTurnBackWrapper>
  )
}

const MemoNetworkErrorContent = React.memo(NetworkErrorContent)

const MemoErrorBoundaryContent = React.memo(ErrorBoundaryContent)

export { MemoErrorBoundaryContent as ErrorBoundaryContent, MemoNetworkErrorContent as NetworkErrorContent }
