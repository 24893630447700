import moment, { DurationInputArg1, DurationInputArg2, unitOfTime } from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useUserContext } from '../user/UserContext/helpers/hook'

/**
 * Returns the current date as an ISO8601 compatible date+time string.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
 */
const getCurrentIsoDate = () => {
  return moment().toISOString()
}

/**
 * A hook which returns the current date+time as an ISO8601 date+time string. The string returned will auto
 * update every 5 seconds, so it is always up to date
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
 */
const useAutomaticallyUpdatingCurrentIsoDate = () => {
  const [now, setNow] = useState(() => moment())

  useEffect(() => {
    const updater = setInterval(() => setNow(moment()), 5000)

    return () => clearInterval(updater)
  })

  return now.toISOString()
}

/**
 * A hook which creates some helper functions that can be used to modify string ISO dates.
 * These functions will always be in the user's timezone.
 *
 * Supports:
 *   - isBefore
 *   - isSame
 *   - isSameOrBefore
 *   - isAfter
 *   - isSameOrAfter
 *   - isBetween
 *   - isValid
 *   - diff
 *   - monthsList
 *   - min
 *   - year
 *   - hours
 *   - startOf
 *   - endOf
 *   - add
 *   - subtract
 *   - set
 *
 * These work similar to their moment.js counterparts, but will automatically be converted to the user timezone.
 *
 * This also supports non-moment functions:
 *  - toYMD is a function to change format of ISO string date to YMD format. Use this when required by API.
 *  - toHoursMinutes is a function to get the hours and minutes part out of an ISO string. This should be used in forms where time is configured separately to the date
 */
const useUserTimezoneDateModifiers = () => {
  const { timezone } = useUserContext()

  const isBefore = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined, granularity?: unitOfTime.StartOf) =>
      (date1 && date2 && moment(date1).isBefore(date2, granularity)) || false,
    [],
  )

  const isSame = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined, granularity?: unitOfTime.StartOf) =>
      (date1 && date2 && moment(date1).isSame(date2, granularity)) || false,
    [],
  )

  const isSameOrBefore = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined, granularity?: unitOfTime.StartOf) =>
      (date1 && date2 && moment(date1).isSameOrBefore(date2, granularity)) || false,
    [],
  )

  const isAfter = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined, granularity?: unitOfTime.StartOf) =>
      (date1 && date2 && moment(date1).isAfter(date2, granularity)) || false,
    [],
  )

  const isSameOrAfter = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined, granularity?: unitOfTime.StartOf) =>
      (date1 && date2 && moment(date1).isSameOrAfter(date2, granularity)) || false,
    [],
  )

  const isBetween = useCallback(
    (
      needleDate: string | null | undefined,
      lowerDate: string | null | undefined,
      upperDate: string | null | undefined,
      granularity?: unitOfTime.StartOf,
      inclusivity?: '()' | '[)' | '(]' | '[]',
    ) =>
      (needleDate &&
        lowerDate &&
        upperDate &&
        moment(needleDate).isBetween(lowerDate, upperDate, granularity, inclusivity)) ||
      false,
    [],
  )

  const isValid = useCallback((date: string | null | undefined) => date && moment(date).isValid(), [])

  const diff = useCallback(
    (
      date1: string | null | undefined,
      date2: string | null | undefined,
      unitOfTime?: unitOfTime.Diff,
      precise?: boolean,
    ) => (date1 && date2 && moment(date1).diff(date2, unitOfTime, precise)) || 0,
    [],
  )

  const min = useCallback(
    (date1: string | null | undefined, date2: string | null | undefined) =>
      date1 && date2 && moment.min([moment(date1), moment(date2)]).toISOString(),
    [],
  )

  const monthsList = useCallback(() => moment.months(), [])

  const year = useCallback((date: string) => moment(date).tz(timezone).year(), [timezone])

  const hours = useCallback((date: string) => moment(date).tz(timezone).hours(), [timezone])

  const startOf = useCallback(
    (date: string, startOf: unitOfTime.StartOf) => moment(date).tz(timezone).startOf(startOf).toISOString(),
    [timezone],
  )

  const endOf = useCallback(
    (date: string, endOf: unitOfTime.StartOf) => moment(date).tz(timezone).endOf(endOf).toISOString(),
    [timezone],
  )

  const add = useCallback(
    (date: string, amount?: DurationInputArg1, unit?: DurationInputArg2) =>
      moment(date).tz(timezone).add(amount, unit).toISOString(),
    [timezone],
  )

  const subtract = useCallback(
    (date: string, amount?: DurationInputArg1, unit?: DurationInputArg2) =>
      moment(date).tz(timezone).subtract(amount, unit).toISOString(),
    [timezone],
  )

  const toYMD = useCallback((date: string) => moment(date).tz(timezone).format('YYYY-MM-DD'), [timezone])

  const toHoursMinutes = useCallback((date: string) => moment(date).tz(timezone).format('HH:mm'), [timezone])

  const set = useCallback(
    (date: string, setObject: moment.MomentSetObject) => moment(date).tz(timezone).set(setObject).toISOString(),
    [timezone],
  )

  return {
    isBefore,
    isSame,
    isSameOrBefore,
    isAfter,
    isSameOrAfter,
    isBetween,
    isValid,
    diff,
    min,
    monthsList,
    year,
    hours,
    startOf,
    endOf,
    add,
    subtract,
    toYMD,
    toHoursMinutes,
    set,
  }
}

export { getCurrentIsoDate, useAutomaticallyUpdatingCurrentIsoDate, useUserTimezoneDateModifiers }
