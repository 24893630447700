import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type { PerformPeopleSearch, PerformPeopleSearchVariables } from './__gql__/gqlCodeGen'

type IBusinessUnit = NonNullable<PerformPeopleSearch['performPeopleDropdownSearch']['results'][0]['businessUnit']>

const jobForPersonDropdownFragment = gql`
  fragment JobForPersonDropdownFragment on Job {
    id
    isOnExtendedLeave
    jobName {
      id
      name
    }
    jobBusinessEntity {
      id
      businessEntity {
        id
        name
        country {
          id
        }
      }
    }
    jobLocation {
      id
      location {
        id
        name
      }
    }
  }
`

const personForPersonDropdownFragment = gql`
  ${jobForPersonDropdownFragment}

  fragment PersonForPersonDropdownFragment on Person {
    id
    displayName
    initials
    employmentStatusId
    profilePictureUrl(size: SMALL)
    primaryJob {
      ...JobForPersonDropdownFragment
    }
  }
`

const PERFORM_PEOPLE_DROPDOWN_SEARCH = gql`
  ${personForPersonDropdownFragment}
  ${jobForPersonDropdownFragment}

  query PerformPeopleSearch($searchText: String, $showPastStaff: Boolean) {
    performPeopleDropdownSearch(searchText: $searchText, showPastStaff: $showPastStaff) {
      noResultsReason
      results {
        person {
          ...PersonForPersonDropdownFragment
        }
        businessUnit {
          id
          name
        }
      }
    }
  }
`

const GET_PEOPLE_FOR_DROPDOWN_SEARCH = gql`
  ${personForPersonDropdownFragment}
  query GetValuesForPeopleSearch($peopleIds: [ID!]!, $jobIds: [ID!]!, $businessUnitIds: [ID!]!) {
    getBusinessUnits(ids: $businessUnitIds) {
      id
      name
    }
    getPeople(ids: $peopleIds) {
      ...PersonForPersonDropdownFragment
    }
    getJobs(ids: $jobIds) {
      ...JobForPersonDropdownFragment
    }
  }
`

const usePerformPeopleSearch = (variables: PerformPeopleSearchVariables, skip: boolean) => {
  return useQuery<PerformPeopleSearch, PerformPeopleSearchVariables>(PERFORM_PEOPLE_DROPDOWN_SEARCH, {
    errorBehaviour: 'do-nothing',
    variables,
    skip,
  })
}

export type { IBusinessUnit }
export { usePerformPeopleSearch, GET_PEOPLE_FOR_DROPDOWN_SEARCH, PERFORM_PEOPLE_DROPDOWN_SEARCH }
