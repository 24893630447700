import React from 'react'
import { IModule } from 'src/services/user/SessionDetailsProvider'

interface ITenantContext {
  tenantName: string
  tenantCountry?: {
    id: string
    name: string
    isoCode: string
    dialCode: string
  } | null
  tenantCurrency?: {
    id: string
    code: string
    decimalPlaces?: number | null
  } | null
  language: string
  minPasswordLength: number
  modules: IModule[]
}

const TenantContext = React.createContext<ITenantContext>({
  tenantName: 'Unknown',
  tenantCountry: null,
  tenantCurrency: null,
  language: 'en',
  minPasswordLength: 8,
  modules: [],
})

export type { ITenantContext }
export { TenantContext }
