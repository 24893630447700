import { Button, ButtonGroup } from '@intellihr/blueberry'
import { VerticalForm, CheckboxInput, GridLayout, Text, Variables, Props } from '@intellihr/ui-components'
import React, { useCallback } from 'react'
import { FormSubmitHandler, Field } from 'redux-form'
import { BrowserBackButton } from 'src/services/layout/components/BrowserBackButton'
import {
  IRuleSet,
  ReduxForm,
  IFieldValues,
  useValidateMap,
  IReduxVerticalFormFieldParam,
  ReduxVerticalFormField,
} from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import {
  ICustomFieldFormValues,
  getCustomFieldsFormFields,
} from 'src/domain/EDM/public/services/customFieldValuesFormGeneratorService'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'
import { AddressFields } from './components/AddressFields'

interface IPersonAddressFormData {
  addressTypeId: string
  street: string
  suburb: string
  state: string
  country: string
  postcode: string
  latitude: number
  longitude: number
  isPrimary: boolean
  customFields?: ICustomFieldFormValues | null
}

interface IPersonAddressFormProps {
  submitText: string
  submitting?: boolean
  hasAddress: boolean
  initialValues?: {
    addressTypeId: string
    currentAddress?: string
    customFields?: ICustomFieldFormValues | null
  }
  onSubmit?: FormSubmitHandler<IPersonAddressFormData>
  customFieldDefinitions: Parameters<typeof getCustomFieldsFormFields>[0]['customFields']
}

const validationRules = (): IRuleSet[] => {
  return [
    {
      fields: ['addressTypeId', 'fullAddress', 'country'],
      rule: 'required',
    },
  ]
}

const PersonAddressForm: React.FC<IPersonAddressFormProps> = ({
  submitText,
  initialValues,
  hasAddress,
  onSubmit,
  submitting,
  customFieldDefinitions,
}) => {
  const tCommon = useScope('common:components')
  const tTerminology = useScope('edm:terminology.Address')
  const { validateMap } = useValidateMap()
  const validate = useCallback((values: IFieldValues) => validateMap(validationRules(), values), [validateMap])

  const customFieldFormFields = getCustomFieldsFormFields({
    customFields: customFieldDefinitions,
    modelType: CustomFieldDefinitionModelType.ADDRESS,
  }).map((customField, idx) => (
    <GridLayout.Cell
      key={idx}
      size={{ tablet: 6, min: 'fullWidth' }}
    >
      {customField}
    </GridLayout.Cell>
  ))

  return (
    <ReduxForm
      form="addressForm"
      validate={validate}
      initialValues={{
        formType: 'auto',
        ...initialValues,
      }}
      onSubmit={onSubmit}
      formComponent={VerticalForm}
      enableReinitialize
      keepDirtyOnReinitialize
    >
      {({ reduxFormChange }) => (
        <>
          <AddressFields
            initialValues={initialValues}
            reduxFormChange={reduxFormChange}
          />

          {hasAddress ? (
            <Field<IReduxVerticalFormFieldParam>
              name="isPrimary"
              component={ReduxVerticalFormField}
              props={{
                inputProps: {
                  label: tTerminology('primaryVerbose'),
                },
                component: CheckboxInput,
              }}
            />
          ) : (
            ''
          )}

          {customFieldFormFields.length > 0 ? (
            <>
              <Text
                type={Props.TypographyType.Heading}
                weight={Variables.FontWeight.fwBold}
                isInline={false}
                margins={{ bottom: Variables.Spacing.sSmall }}
              >
                {tTerminology('additionalDetailsSection')}
              </Text>
              <GridLayout
                gutterMarginX={Variables.Spacing.s2XLarge}
                verticalAlignment={GridLayout.VerticalAlignment.Top}
              >
                {customFieldFormFields}
              </GridLayout>
            </>
          ) : null}
          <VerticalForm.LeftAlignControls>
            <ButtonGroup>
              <Button
                variant="primary"
                disabled={submitting}
                type="submit"
              >
                {submitText}
              </Button>
              <BrowserBackButton>{tCommon('buttons.cancel')}</BrowserBackButton>
            </ButtonGroup>
          </VerticalForm.LeftAlignControls>
        </>
      )}
    </ReduxForm>
  )
}

export type { IPersonAddressFormData }
export { PersonAddressForm }
