import React, { useState } from 'react'
import { Button, Column, ColumnContainer, Inline, Input, MangoButton, TextStyle } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import {
  IFormChildProps,
  IRuleSet,
  ReduxForm,
  ReduxVerticalFormField,
  useValidationRules,
} from 'src/services/reduxForm'
import { SingleDateInputV2, TextAreaInput, VerticalForm } from '@intellihr/ui-components'
import { Field } from 'redux-form'
import { ExpiryVisibilityTypeIds } from 'src/services/constants/qualifications/expiryVisibilityTypeIds'
import { RegistrationNumberVisibilityTypeIds } from 'src/services/constants/qualifications/registrationNumberVisibilityTypeIds'
import { PersonDocument } from 'src/__gql__/globalTypes'
import { useToasts } from 'src/services/toasts'
import { AttachmentRequirementTypeIds } from 'src/services/constants/qualifications/attachmentRequirementTypeIds'
import { ResponsiveAlign } from '@intellihr/blueberry/dist/_utils/alignmentProps'
import {
  ICreateQualificationAttachmentParameters,
  IDeleteQualificationAttachmentParameters,
  IUpdateQualificationAttachmentParameters,
  QualificationInstanceAttachment,
} from './QualificationInstanceAttachment'

interface IQualificationInstance {
  id: string
  issuingOrganisation?: string | null
  registrationNumber?: string | null
  issueDate?: string | null
  expiryDate?: string | null
  notes?: string | null
  qualificationTemplate: ISlimQualificationTemplate
  attachments: PersonDocument[]
}

interface ISlimQualificationTemplate {
  id: string
  name?: string
  qualificationAttachmentRequirementType: { id: string }
  qualificationExpiryType: { id: string }
  qualificationRegistrationNumberVisibilityType: { id: string }
  registrationNumberName?: string | null
}

interface IQualificationInstanceFormProps {
  onSubmit: (formData: IOnboardingFormSubmitData, saveAsDraft?: boolean) => void
  submitting: boolean
  qualificationInstance: IQualificationInstance
  createQualificationAttachment: (
    input: ICreateQualificationAttachmentParameters,
  ) => Promise<{ document: PersonDocument; presignedUploadUrl: string } | undefined>
  updateQualificationAttachment: (input: IUpdateQualificationAttachmentParameters) => Promise<void>
  deleteQualificationAttachment: (input: IDeleteQualificationAttachmentParameters) => Promise<void>
  submitButtonLabel?: string
  showDraftButton?: boolean
  isAttachmentLoading?: boolean
  submitButtonAlignment?: ResponsiveAlign
}

interface IOnboardingFormSubmitData {
  qualificationInstanceId: string
  issuingOrganisation: string
  registrationNumber: string | null
  issueDate: DateTime
  expiryDate: DateTime | null
  notes: string | null
}

const observedFields = new Set<string>([
  'issuingOrganisation',
  'registrationNumber',
  'issueDate',
  'expiryDate',
  'notes',
])

const QualificationInstanceForm: React.FC<IQualificationInstanceFormProps> = ({
  onSubmit,
  submitting,
  qualificationInstance,
  submitButtonLabel,
  showDraftButton = false,
  createQualificationAttachment,
  updateQualificationAttachment,
  deleteQualificationAttachment,
  isAttachmentLoading,
  submitButtonAlignment = 'left',
}) => {
  const formName = 'qualification-form'
  const tCommon = useScope('common:components')
  const tFeature = useScope('qualifications:components.QualificationInstancePage')
  const postToast = useToasts()
  const [attachments, setAttachments] = useState<PersonDocument[]>(qualificationInstance.attachments ?? [])

  const validationRules = () => {
    const rules: IRuleSet[] = [
      {
        field: 'issueDate',
        rule: 'required',
      },
      {
        field: 'issuingOrganisation',
        rule: 'required',
      },
    ]

    if (
      qualificationInstance?.qualificationTemplate.qualificationExpiryType.id ===
      ExpiryVisibilityTypeIds.expiryDateRequired
    ) {
      rules.push({
        field: 'expiryDate',
        rule: 'required',
      })
    }

    if (
      qualificationInstance?.qualificationTemplate.qualificationRegistrationNumberVisibilityType.id ===
      RegistrationNumberVisibilityTypeIds.registrationNumberRequired
    ) {
      rules.push({
        field: 'registrationNumber',
        rule: 'required',
      })
    }

    return rules
  }

  const validate = useValidationRules(validationRules)

  const handleSubmit = (formData: IOnboardingFormSubmitData, saveAsDraft = false) => {
    // Validate that expiry date is after the issue date
    if (formData.expiryDate && formData.expiryDate <= formData.issueDate) {
      postToast({
        type: 'alert',
        content: tFeature('validation.errorExpiryDateMustBeAfterIssueDate'),
        logType: 'error',
      })

      return
    }

    // Validate attachments here since we can't validate them on the form itself
    // We only do this check if it's not a draft
    if (
      !saveAsDraft &&
      qualificationInstance.qualificationTemplate.qualificationAttachmentRequirementType.id ===
        AttachmentRequirementTypeIds.attachmentRequired &&
      attachments.length === 0
    ) {
      postToast({
        type: 'alert',
        content: tFeature('validation.errorAtLeastOneAttachmentRequired'),
        logType: 'error',
      })

      return
    }
    onSubmit(formData, saveAsDraft)
  }

  // Wrapper around handleSubmit that the form will use as a submit action
  // to help differenciate between submitting the qual or saving as a draft
  const handleFormSubmit = (formData: IOnboardingFormSubmitData) => {
    handleSubmit(formData, false)
  }

  return (
    <ReduxForm
      form={formName}
      formComponent={VerticalForm}
      destroyOnUnmount={true}
      onSubmit={handleFormSubmit}
      validate={validate}
      observedFields={observedFields}
      initialValues={{
        issueDate: qualificationInstance.issueDate,
        expiryDate: qualificationInstance.expiryDate,
        issuingOrganisation: qualificationInstance.issuingOrganisation,
        registrationNumber: qualificationInstance.registrationNumber,
        notes: qualificationInstance.notes,
      }}
    >
      {({ observedFieldValues }: IFormChildProps<IOnboardingFormSubmitData>) => {
        return (
          <>
            <TextStyle variant="strong">{tCommon('headers.details')}</TextStyle>
            <div style={{ marginTop: '10px' }}>
              <ColumnContainer>
                <Column>
                  <Field
                    name="issuingOrganisation"
                    component={ReduxVerticalFormField}
                    props={{
                      label: tFeature('input.issuingOrganisation'),
                      component: Input,
                      isRequired: true,
                      componentContext: 'issuing-organisation-input',
                    }}
                  />
                </Column>
                {qualificationInstance?.qualificationTemplate.qualificationRegistrationNumberVisibilityType.id !==
                RegistrationNumberVisibilityTypeIds.noRegistrationNumber ? (
                  <Column>
                    <Field
                      name="registrationNumber"
                      component={ReduxVerticalFormField}
                      props={{
                        label:
                          qualificationInstance.qualificationTemplate.registrationNumberName ??
                          tFeature('input.registrationNumber'),
                        isRequired:
                          qualificationInstance.qualificationTemplate.qualificationRegistrationNumberVisibilityType
                            .id === RegistrationNumberVisibilityTypeIds.registrationNumberRequired,
                        component: Input,
                        componentContext: 'registration-number-input',
                      }}
                    />
                  </Column>
                ) : null}
              </ColumnContainer>
              <ColumnContainer>
                <Column>
                  <Field
                    name="issueDate"
                    component={ReduxVerticalFormField}
                    props={{
                      label: tFeature('input.validFrom'),
                      component: SingleDateInputV2,
                      componentContext: 'valid-from-single-date-input',
                      isRequired: true,
                      inputProps: {
                        autoFocus: false,
                      },
                    }}
                  />
                </Column>
                {qualificationInstance.qualificationTemplate.qualificationExpiryType.id !==
                ExpiryVisibilityTypeIds.noExpiryDate ? (
                  <Column>
                    <Field
                      name="expiryDate"
                      component={ReduxVerticalFormField}
                      props={{
                        label: tFeature('input.validTo'),
                        component: SingleDateInputV2,
                        componentContext: 'valid-to-single-date-input',
                        isRequired:
                          qualificationInstance.qualificationTemplate.qualificationExpiryType.id ===
                          ExpiryVisibilityTypeIds.expiryDateRequired,
                        inputProps: {
                          autoFocus: false,
                        },
                      }}
                    />
                  </Column>
                ) : null}
              </ColumnContainer>
            </div>
            <Field
              name="notes"
              component={ReduxVerticalFormField}
              props={{
                label: tFeature('input.notes'),
                component: TextAreaInput,
                componentContext: 'note-input',
              }}
            />
            <QualificationInstanceAttachment
              qualificationInstanceId={qualificationInstance.id}
              attachments={attachments}
              setAttachments={setAttachments}
              createQualificationAttachment={createQualificationAttachment}
              updateQualificationAttachment={updateQualificationAttachment}
              deleteQualificationAttachment={deleteQualificationAttachment}
              isAttachmentLoading={isAttachmentLoading}
            />
            <Inline align={submitButtonAlignment}>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                {submitButtonLabel ?? tCommon('buttons.submit')}
              </Button>
              {showDraftButton && (
                <MangoButton
                  variant="secondary"
                  onClick={() => {
                    handleSubmit(observedFieldValues, true)
                  }}
                  disabled={submitting}
                >
                  {tFeature('input.saveDraftButton')}
                </MangoButton>
              )}
            </Inline>
          </>
        )
      }}
    </ReduxForm>
  )
}

export type { IOnboardingFormSubmitData, IQualificationInstance }
export { QualificationInstanceForm }
