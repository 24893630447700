import { useScope } from 'src/services/i18n/LocalizationProvider'

const useBlueberryI18n = () => {
  const t = useScope('common:blueberry')

  return {
    Avatar: {
      person: t('Avatar.person'),
    },
    CardList: {
      optionsLabel: t('CardList.optionsLabel'),
    },
    Breadcrumbs: {
      showMore: t('Breadcrumbs.showMore'),
      goToPage: t('Breadcrumbs.goToPage'),
    },
    CheckboxGroup: {
      selectAll: t('CheckboxGroup.selectAll'),
      selectNone: t('CheckboxGroup.selectNone'),
    },
    Combobox: {
      selectedLabel: t('Combobox.selectedLabel'),
      nothingFound: t('Combobox.nothingFound'),
      more: (count: number) => t('Combobox.more', { count }),
      clearSelected: t('Combobox.clearSelected'),
      createOption: t('Combobox.createOption'),
    },
    Common: {
      back: t('Common.back'),
      clear: t('Common.clear'),
      close: t('Common.close'),
    },
    DataTable: {
      collapse: t('DataTable.collapse'),
      expand: t('DataTable.expand'),
      dragRow: t('DataTable.dragRow'),
      noItems: t('DataTable.noItems'),
      selectRow: t('DataTable.selectRow'),
      selectAllRows: t('DataTable.selectAllRows'),
      selectedRows: (rows: number) => t('DataTable.selectedRows', { count: rows }),
      sortBy: t('DataTable.sortBy'),
    },
    Date: {
      invalid: t('Date.invalid'),
      hrs: t('Date.hrs'),
      timezoneDisclaimer: t('Date.timezoneDisclaimer'),
    },
    DatePicker: {
      invalidMessage: t('DatePicker.invalidMessage'),
      startDate: t('DatePicker.startDate'),
      endDate: t('DatePicker.endDate'),
      today: t('DatePicker.today'),
    },
    Dialog: {
      cancel: t('Dialog.cancel'),
    },
    DropdownMenu: {
      disclaimerLabel: t('DropdownMenu.disclaimerLabel'),
    },
    Filter: {
      search: t('Filter.search'),
    },
    KeyValueList: {
      fallback: t('KeyValueList.fallback'),
    },
    Label: {
      description: t('Label.description'),
    },
    ModalNavigation: {
      menu: t('ModalNavigation.menu'),
    },
    Page: {
      betaLabel: t('Page.betaLabel'),
    },
    Pagination: {
      details: (itemsStart: number, itemsEnd: number, itemCount: number) =>
        t('Pagination.details', { count: itemCount, itemsStart, itemsEnd }),
      goToPage: t('Pagination.goToPage'),
      next: t('Pagination.next'),
      previous: t('Pagination.previous'),
    },
    Progress: {
      percentageComplete: (progress: number) => t('Progress.percentageComplete', { progress }),
    },
    ProgressTracker: {
      step: t('ProgressTracker.step'),
    },
    Search: {
      clearSearch: t('Search.clearSearch'),
      noResults: (search: string) => t('Search.noResults', { search }),
      includePastStaffLabel: t('Search.searchOption'),
      searchPlaceholder: t('Search.searchPlaceholder'),
    },
    SideNavigation: {
      moreOptions: t('SideNavigation.moreOptions'),
    },
    Skeleton: {
      loading: t('Skeleton.loading'),
    },
    AppSwitcher: {
      switchApp: t('AppSwitcher.switchApp'),
      humanforceApps: t('AppSwitcher.humanforceApps'),
      externalApps: t('AppSwitcher.externalApps'),
    },
  }
}

export { useBlueberryI18n }
