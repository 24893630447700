import { get, isEmpty, snakeCase } from 'lodash'
import * as action from 'src/services/segment/actions.yml'

interface IIdentityInfo {
  userId: string
  tenant: string
  email?: string
  name?: string
  personId?: string
  superUser?: boolean
  systemAdministrator?: boolean
}

const ignoredTenant = (): boolean => {
  if (['internal-intellihr.intellihr.net', 'internal-humanforce.intellihr.net'].includes(location.hostname)) {
    return false
  }
  return (
    location.hostname.endsWith('-intellihr.intellihr.net') || location.hostname.endsWith('-humanforce.intellihr.net')
  )
}

const shouldUseSegment = (): boolean => {
  try {
    return !isEmpty(process.env.REACT_APP_SEGMENT_API_KEY) && !ignoredTenant() && !!analytics
  } catch (e) {
    return false
  }
}

const setupSegment = () => {
  if (shouldUseSegment()) {
    analytics.load(process.env.REACT_APP_SEGMENT_API_KEY!)
  }
}

const segmentPage = () => {
  if (shouldUseSegment()) {
    analytics.page(window.location.href)
  }
}

const segmentIdentify = (identity: IIdentityInfo) => {
  const { userId, tenant } = identity

  if (shouldUseSegment()) {
    if (userId) {
      analytics.identify(userId, identity)
    }
    analytics.group(tenant)
  }
}

const segmentTrack = (actionId: string) => {
  const actionMapping = get(action, actionId, null)

  if (shouldUseSegment() && actionMapping) {
    const event = snakeCase(`${actionMapping.object} ${actionMapping.action}`)
    const eventProperties = {
      category: actionMapping.category,
      action: actionMapping.action,
      label: actionMapping.label,
      value: actionMapping.value,
    }
    analytics.track(event, eventProperties)
  }
}

enum SegmentCategory {
  ANALYTICS = 'Analytics',
  COMPENSATION_PLANS = 'Compensation Plans',
  COMPLIANCE = 'Compliance',
  CONTRACTS = 'Contracts',
  DASHBOARD = 'Dashboard',
  DATA_IMPORT_EXPORT = 'Data Import and Export',
  GOALS = 'Goals',
  INTEGRATIONS = 'Integrations',
  JOB_DATA = 'Job Data',
  NOTIFICATIONS = 'Notifications',
  ONBOARDING = 'Onboarding',
  ORG_CHART = 'Organisation Chart',
  ORG_OBJECTIVES = 'Organisation Objectives',
  PAY_GRADES = 'Pay Grades',
  PEOPLE_DIRECTORY = 'People Directory',
  PERFORMANCE = 'Performance',
  PLATFORM = 'Platform',
  PERSON_PROFILE = 'Person Profile',
  SKILLS = 'Skills',
  TRAINING = 'Training',
  WORKFLOWS = 'Workflows',
  WORKFORCE_PLANNING = 'Workforce Planning',
}

enum SegmentAction {
  BUTTON_CLICK = 'Button Click',
  CARD_CLICK = 'Card Click',
  CTA_CLICK = 'CTA Click',
  DROPDOWN_MENU_CLICK = 'Dropdown Menu Click',
  DROPDOWN_MENU_ITEM_CLICK = 'Dropdown Menu Item Click',
  IMPORT_BUTTON_CLICK = 'Import Button Click',
  EXPORT_BUTTON_CLICK = 'Export Button Click',
  NAVIGATION_ITEM_CLICK = 'Navigation Item Click',
  PROFILE_BUTTON_CLICK = 'Profile Button Click',
  QUICK_CREATE_CLICK = 'Quick Create Button Click',
  SEARCH = 'Search',
  SUBMIT = 'Submit',
  TAB_CHANGE = 'Tab Change',
  TEXT_LINK_CLICK = 'Text Link Click',
  TILE_CLICK = 'Tile Click',
  TOGGLE = 'Toggle',
}

interface ISendSegmentEventArgs {
  eventName: string
  category: SegmentCategory
  action: SegmentAction
  label: string
  value?: number
}

const sendSegmentEvent = ({ eventName, category, action, label, value }: ISendSegmentEventArgs) => {
  if (shouldUseSegment()) {
    analytics.track(eventName, {
      category,
      action,
      label,
      value,
    })
  }
}

export { setupSegment, segmentTrack, segmentIdentify, segmentPage, sendSegmentEvent, SegmentCategory, SegmentAction }
