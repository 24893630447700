import React from 'react'

interface IUserContextStructure {
  timezone: string
  dateFormat: string
  timeFormat: string
  language: string
  person: {
    id: string
    displayName: string
    preferredOrFirstName: string
    lastName: string
    legalName: string
    initials: string
    profilePictureId?: string | null
    profilePictureUrl?: string | null
    primaryEmailAddress?: null | {
      id: string
      email: string
    }
    user: {
      id: string
      userPermissionGroups: ReadonlyArray<{
        id: string
        permissionGroup: {
          id: string
          name: string
        }
      } | null>
      isAssumedAccount: boolean
      isAssumingAccountAsAnotherUser: boolean
      isOnboarding: boolean
      activeFrom: DateTime | null
      mfaEnabled: boolean
      ssoId?: string | null
    }
  }
}

const UserContext = React.createContext<IUserContextStructure>({
  timezone: '',
  dateFormat: '',
  timeFormat: '',
  language: '',
  person: {
    id: '',
    displayName: 'Unknown Person',
    preferredOrFirstName: 'Unknown',
    lastName: 'Person',
    legalName: 'Unknown Name',
    initials: '-',
    profilePictureId: null,
    profilePictureUrl: null,
    primaryEmailAddress: null,
    user: {
      id: '',
      userPermissionGroups: [],
      isAssumedAccount: false,
      isAssumingAccountAsAnotherUser: false,
      isOnboarding: false,
      activeFrom: null,
      mfaEnabled: false,
      ssoId: null,
    },
  },
})

export type { IUserContextStructure }
export { UserContext }
