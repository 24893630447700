import { CachePersistor } from 'apollo3-cache-persist'
import { unset } from 'lodash'
import uuid from 'uuid'
import { ApolloLink, InMemoryCache, NormalizedCacheObject, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { cloudLogger } from 'src/services/error'
import { globalGraphQLErrorHandler } from 'src/services/graphQL/helpers'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { getTypePolicies } from './getTypePolicies'

let client: ApolloClient<NormalizedCacheObject>

interface ILinkGeneratorOptions {
  graphQLEndpoint: string
}

function generateLinks({ graphQLEndpoint }: ILinkGeneratorOptions) {
  const retryLink = new RetryLink()
  const reauthenticateOnAuthErrorsLink = onError(reauthenticateOnAuthErrorsHandler)

  const errorLink = onError(globalGraphQLErrorHandler)

  const authLink = setContext(async (_, { headers }) => {
    const token = await getSession().getAccessToken()
    const traceRequestId = uuid.v4()

    return {
      headers: {
        ...headers,
        authorization: token,
        'trace-request-id': traceRequestId,
      },
    }
  })

  const batchLink = new BatchHttpLink({ uri: graphQLEndpoint })

  return ApolloLink.from([errorLink, authLink, retryLink, reauthenticateOnAuthErrorsLink, batchLink])
}

function reauthenticateOnAuthErrorsHandler({ graphQLErrors }: ErrorResponse) {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            getSession().getAccessToken(true)
            return
        }
      }
    }
  }

  return
}

const APOLLO_CACHE_PERSIST_USER_KEY = 'apollo-cache-persist-user'
const APOLLO_CACHE_PERSIST_DATE_KEY = 'apollo-cache-persist-date'

async function applyApolloPersist(cache: InMemoryCache) {
  const persistor = new CachePersistor({
    cache,
    storage: window.localStorage,
    persistenceMapper: async (data) => {
      const parsed = JSON.parse(data)

      // Delete all keys we don't want to be persisted
      unset(parsed, 'ROOT_QUERY.listOffsetNotifications')

      return JSON.stringify(parsed)
    },
  })

  const now = moment()
  const userId = getSession().userId
  const persistedUserId = window.localStorage.getItem(APOLLO_CACHE_PERSIST_USER_KEY)
  const persistedDate = window.localStorage.getItem(APOLLO_CACHE_PERSIST_DATE_KEY)
  const shouldLoadPersistedData =
    userId && persistedUserId && persistedUserId === userId && persistedDate && now.diff(persistedDate, 'hours') < 24

  if (shouldLoadPersistedData) {
    await persistor.restore()
  } else {
    await persistor.purge()

    if (userId && now) {
      window.localStorage.setItem(APOLLO_CACHE_PERSIST_USER_KEY, userId)
      window.localStorage.setItem(APOLLO_CACHE_PERSIST_DATE_KEY, now.toISOString())
    }
  }

  return cache
}

async function initializeClient() {
  const graphQLEndpoint = process.env.REACT_APP_FEDERATION_GRAPHQL_ENDPOINT

  if (graphQLEndpoint) {
    let cache = new InMemoryCache({
      typePolicies: getTypePolicies(),
    })

    cache = await applyApolloPersist(cache)

    client = new ApolloClient({
      name: 'SPA',
      cache,
      link: generateLinks({
        graphQLEndpoint,
      }),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          // the reason for this is discussed at https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
          nextFetchPolicy: 'cache-first',
        },
      },
    })
  }
}

async function getGraphiteApolloClient(): Promise<ApolloClient<NormalizedCacheObject>> {
  if (!client) {
    await initializeClient()
  }

  if (!client) {
    cloudLogger.critical('Graphite client is still undefined after initialization', {
      userImpact: 'Attempts to call queries/mutations will throw null errors',
    })
  }
  return client
}

export { generateLinks, getGraphiteApolloClient }
