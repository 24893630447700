import React from 'react'
import { Props, Text, Variables } from '@intellihr/ui-components'
import { ButtonIcon, IconArrowToRight, Inline } from '@intellihr/blueberry'
import { LanguageSelectButton } from 'src/domain/EDM/public/components/inputs/LanguageSelectButton/LanguageSelectButton'
import { useBrowserBreakpoints } from 'src/services/reducers/browser/hooks'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService/SessionManagementService'
import { GET_ONBOARDING_PERSON } from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'
import { OnboardingPage } from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/OnboardingPage'
import { AssumedAccountAlert } from '../PageFrame/components'
import { StyledContainer, StyledContentWrapper, StyledHeader, StyledLogo } from './style'
import { useGetIhrLogoUrl } from './hooks/useGetIhrLogoUrl'

const OnboardingApp: React.FC<{}> = () => {
  const { desktop } = useBrowserBreakpoints()
  const t = useScope('edm:features.Onboarding')

  const { data: logo, loading: logoLoading } = useGetIhrLogoUrl()
  const queriesToRefetch = [{ query: GET_ONBOARDING_PERSON }]

  if (logoLoading) {
    return null
  }

  return (
    <StyledContentWrapper desktop={desktop}>
      <StyledContainer>
        <StyledHeader>
          <Inline
            alignY="center"
            space="large"
          >
            <StyledLogo src={logo?.intelliHRLogos.onWhiteRGBUrl} />
            {desktop && <Text type={Props.TypographyType.Heading}>{t('accountSetupHeading')}</Text>}
          </Inline>
          <Inline
            alignY="center"
            space="xSmall"
          >
            <LanguageSelectButton refetchQueries={queriesToRefetch} />
            <ButtonIcon
              icon={IconArrowToRight}
              label={t('logoutButtonLabel')}
              onClick={() => {
                getSession().logout()
              }}
            />
          </Inline>
        </StyledHeader>

        <AssumedAccountAlert />
        <div style={{ marginTop: Variables.Spacing.sXLarge }}>
          <OnboardingPage />
        </div>
      </StyledContainer>
    </StyledContentWrapper>
  )
}

export { OnboardingApp }
