import styled, { css } from 'styled-components'
import { Variables, Props, Margin } from '@intellihr/ui-components'

const ChipWrapper = styled.div<{
  noAvatar: boolean
  noAction: boolean
  margins?: Props.IMargins
}>`
  align-items: center;
  background: ${Variables.Color.n250};
  border-radius: 90px;
  display: inline-flex;
  min-height: 32px;
  padding: 2px;
  vertical-align: middle;
  max-width: 100%;
  min-width: 100px;

  ${({ margins }) => Margin.styleForMargins(margins)}
  ${({ noAvatar }) =>
    noAvatar
      ? css`
          padding-left: 16px;
        `
      : css`
          min-height: 40px;
          padding-bottom: 2px;
          padding-left: 5px;
          padding-top: 2px;
        `}
  ${({ noAction }) =>
    noAction
      ? css`
          padding-right: 16px;
        `
      : css`
          min-height: 40px;
          padding-bottom: 2px;
          padding-top: 2px;
        `}
`

const PreventShrinkWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
`

const TextWrapper = styled.div<{ noAvatar: boolean }>`
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;

  ${({ noAvatar }) =>
    !noAvatar &&
    css`
      margin-left: 8px;
    `}
`

const ActionIconWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  height: 36px;
  justify-content: center;
  width: 36px;
`

export { PreventShrinkWrapper, ActionIconWrapper, TextWrapper, ChipWrapper }
