import { Toast } from '@intellihr/ui-components'
import { Map } from 'immutable'
import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { toJSSafe } from 'src/services/immutable/formatting'
import { IBrowserSelector, isMobileSelector } from 'src/services/reducers/browser/selectors'
import { toastActions } from 'src/services/toasts'
import { ToasterWrapper } from './style'

interface IToast {
  id: string
  type: 'alert' | 'success'
  content: JSX.Element | string
  autoClose: boolean
  autoCloseTimeout: number
}

interface IToasterProps {
  toasts: IToast[]
  actions: {
    removeToast: typeof toastActions.removeToast
  }
}

const BaseToaster: React.FC<IToasterProps & IBrowserSelector> = ({ toasts, actions, isMobile }) => {
  const [internalToasts, setInternalToasts] = useState<IToast[]>([])

  const removeInternalToast = useCallback(
    (removedId: string) => {
      setInternalToasts(internalToasts.filter(({ id }) => id !== removedId))
    },
    [internalToasts, setInternalToasts],
  )

  useEffect(() => {
    setInternalToasts((oldInternalToasts) => {
      const newInternalToasts = [...oldInternalToasts]

      toasts.map((toast) => {
        actions.removeToast(toast.id)

        if (!newInternalToasts.find(({ id }) => id === toast.id)) {
          newInternalToasts.push(toast)
        }
      })

      return newInternalToasts
    })
  }, [actions, toasts])

  return (
    <ToasterWrapper isMobile={isMobile}>
      <TransitionGroup component={null}>
        {internalToasts.map(({ id, type, content, autoClose, autoCloseTimeout }) => {
          return (
            <CSSTransition
              key={id}
              timeout={500}
              classNames="fade"
            >
              <Toast
                handleClose={() => removeInternalToast(id)}
                onMount={() => autoClose && setTimeout(() => removeInternalToast(id), autoCloseTimeout)}
                type={type}
              >
                {content}
              </Toast>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </ToasterWrapper>
  )
}

const mapStateToProps = (state: Map<string, unknown>) => ({
  toasts: toJSSafe(state.getIn(['toastReducer', 'toasts'])),
  isMobile: isMobileSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      removeToast: toastActions.removeToast,
    },
    dispatch,
  ),
})

const Toaster = connect(mapStateToProps, mapDispatchToProps)(BaseToaster)

export { BaseToaster, Toaster }
