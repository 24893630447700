import React, { ImgHTMLAttributes } from 'react'
import { ITenantContext } from 'src/services/user/TenantContext'
import { useTenantContext } from 'src/services/user/TenantContext/helpers/hook'
import { cloudLogger } from 'src/services/error'

const getS3AssetUrl = (assetPath: string, tenantDetails?: ITenantContext): string => {
  const s3AssetPath = process.env.REACT_APP_SHARED_IMAGE_PATH

  if (s3AssetPath === undefined) {
    cloudLogger.error('REACT_APP_SHARED_IMAGE_PATH env has not been set', {
      userImpact: 'Images will be broken',
      meta: { tenantDetails },
    })

    return ''
  }

  return `${s3AssetPath}/${assetPath}`
}

/**
 * @deprecated Use useS3AssetsFunction instead as it's better at handling multiple asset links and dynamic asset links
 */
const useS3Assets = (assetPath: string) => {
  const tenantDetails = useTenantContext()

  return getS3AssetUrl(assetPath, tenantDetails)
}

const useS3AssetsFunction = () => {
  const tenantDetails = useTenantContext()

  return function (assetPath: string) {
    return getS3AssetUrl(assetPath, tenantDetails)
  }
}

const S3AssetImage: React.FC<{
  assetPath: string
  alt?: string
  style?: ImgHTMLAttributes<HTMLImageElement>['style']
}> = ({ assetPath, alt, style }) => {
  const getS3Asset = useS3AssetsFunction()
  const assetUrl = getS3Asset(assetPath)

  if (assetUrl) {
    return (
      <img
        src={assetUrl}
        alt={alt ?? ''}
        style={style}
      />
    )
  }

  return null
}

export { S3AssetImage, useS3Assets, useS3AssetsFunction }
