import React from 'react'
import { Margin, Text, Variables } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { IPeopleDropdownOption, IPeopleDropdownValue, isBusinessUnitOption, isPersonOption } from './types'

const formatOptionForOnChange = (
  option: IPeopleDropdownOption,
  showIndividualJobs: boolean,
): IPeopleDropdownValue[] => {
  if (isBusinessUnitOption(option)) {
    return [option.value]
  }

  if (isPersonOption(option)) {
    if (showIndividualJobs) {
      return [option.value]
    }

    return [
      {
        ...option.value,
        jobId: null,
      },
    ]
  }

  return []
}

const usePeopleDropdownTextTooltip = () => {
  const tFeature = useScope('edm:features.PeopleDropdownInput')

  return (
    <>
      <Text isInline={false}>{tFeature('peopleDropdownSearchTooltip')}</Text>
      <Margin margins={{ top: Variables.Spacing.sXSmall, left: Variables.Spacing.sXSmall }}>
        <Text isInline={false}>&bull; {tFeature('filterPersonsJob')}</Text>
        <Text isInline={false}>&bull; {tFeature('filterPersonsName')}</Text>
        <Text isInline={false}>&bull; {tFeature('filterBusinessEntity')}</Text>
        <Text isInline={false}>&bull; {tFeature('filterBusinessUnit')}</Text>
        <Text isInline={false}>&bull; {tFeature('filterLocation')}</Text>
        <Text isInline={false}>&bull; {tFeature('filterEmployeeNumber')}</Text>
      </Margin>
    </>
  )
}

export { formatOptionForOnChange, usePeopleDropdownTextTooltip }
