import React from 'react'
import { Select } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'

interface IOnboardingMedicalConditionVisibilityProps {
  onChange: () => void
  value: string
}

const OnboardingMedicalConditionVisibilityInput: React.FC<IOnboardingMedicalConditionVisibilityProps> = ({
  onChange,
  value,
}) => {
  const t = useScope('edm:features.Onboarding')
  return (
    <Select
      id="medical-condition-visibility-option-input"
      label=""
      selectedOption={value ?? 'private'}
      onChange={onChange}
      options={[
        {
          label: t('labelOptionPublic'),
          value: 'public',
        },
        {
          label: t('labelOptionPrivate'),
          value: 'private',
        },
      ]}
    />
  )
}

export { OnboardingMedicalConditionVisibilityInput }
