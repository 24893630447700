import { Variables } from '@intellihr/ui-components'
import styled from 'styled-components'

const HeaderContainer = styled.div<{ backgroundUrls: string[] }>`
  background: no-repeat right;
  background-image: ${(props) => props.backgroundUrls.map((url) => `url(${url})`).join(', ')};
  background-position-x: center;
  background-size: cover;

  &:hover {
    transition: ease-out 0.2s;
  }
`

const ActionMenu = styled.div`
  position: absolute;
  top: ${Variables.Spacing.s2XSmall}px;
  right: ${Variables.Spacing.s2XSmall}px;
`

const BodyContainer = styled.div`
  padding: ${Variables.Spacing.sMedium}px;
`

const StyledPrimaryPhoneNumber = styled.div`
  margin-top: -4px;
`

const StyledHeaderOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${Variables.Spacing.sMedium}px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(143, 161, 179, 0.3) 30%,
    rgba(66, 79, 92, 0.7) 100%
  );

  &:hover {
    background-color: rgba(66, 79, 92, 0.5);
    transition: ease-out 0.2s;
  }
`

export { StyledHeaderOverlay, HeaderContainer, BodyContainer, StyledPrimaryPhoneNumber, ActionMenu }
