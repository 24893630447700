import { Variables } from '@intellihr/ui-components'
import styled from 'styled-components'

const StyledPreview = styled.div`
  font-size: ${Variables.FontSize.fzBody}px;
  line-height: ${Variables.LineHeight.lhBody}px;
  color: ${Variables.Color.n700};
`

const StyledDateTimeInfoBlock = styled.div`
  border-radius: ${Variables.Style.borderRadius}px;
  display: inline-block;
  padding: 1px ${Variables.Spacing.s2XSmall}px;
  font-size: ${Variables.FontSize.fzXSmall}px;
  line-height: ${Variables.LineHeight.lhXSmall}px;
  letter-spacing: normal;
  border: 1px solid ${Variables.Color.n250};
  color: ${Variables.Color.n700};
  vertical-align: middle;
`

export { StyledPreview, StyledDateTimeInfoBlock }
