import '@intellihr/ui-components/dist/index.css'
import '@intellihr/ui-components/dist/ui-components.css'

import { setupBlurButtonOnMouseMove } from 'src/services/listeners'
import { setupSegment, segmentPage } from 'src/services/segment/helper'
import { setupSentry } from 'src/services/sentry/helper'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from 'src/serviceWorker'
import { getSession } from './domain/PlatformSetup/public/services/sessionManagementService'
import { getHistory } from './services/history'
import { getGraphiteApolloClient } from './services/graphQL'
import { setupReact } from './services/react/setupReact'

// Sentry setup
setupSentry(getHistory())

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    const proceed = await getSession().initializeSession()
    if (!proceed) {
      return
    }

    // Segment setup
    setupSegment()
    segmentPage()

    // Listeners
    setupBlurButtonOnMouseMove()

    const container = document.getElementById('root') as HTMLElement
    const root = createRoot(container)
    const apolloClient = await getGraphiteApolloClient()

    // Provider Setup
    root.render(setupReact({ apolloClient }))

    serviceWorker.unregister()
  },
  false,
)
