import React from 'react'
import { FontAwesomeIcon, Variables, Text } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'

const FailedContentOverride: React.FC = () => {
  const t = useScope('common:components.DocumentTable')
  return (
    <div>
      <Text
        color={Variables.Color.r400}
        margins={{ right: Variables.Spacing.sXSmall }}
      >
        {t('failedOverrideText', { defaultValue: 'Error' })}
      </Text>
      <FontAwesomeIcon
        type="regular"
        icon="exclamation-triangle"
        color={Variables.Color.r400}
      />
    </div>
  )
}

export { FailedContentOverride }
