import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  OnboardingQualificationInstanceCreate,
  OnboardingQualificationInstanceCreateVariables,
} from './__gql__/useOnboardingQualificationInstanceCreateCodeGen'

type IQualificationInstance = NonNullable<
  OnboardingQualificationInstanceCreate['onboardingQualificationInstanceCreate']
>['qualificationInstance']

const ONBOARDING_QUALIFICATION_INSTANCE_CREATE = gql`
  mutation OnboardingQualificationInstanceCreate($input: OnboardingQualificationInstanceCreateInput!) {
    onboardingQualificationInstanceCreate(input: $input) {
      qualificationInstance {
        id
        person {
          id
        }
        qualificationTemplate {
          id
          name
          qualificationRegistrationNumberVisibilityType {
            id
          }
          qualificationExpiryType {
            id
          }
          qualificationAttachmentRequirementType {
            id
          }
          registrationNumberName
        }
        qualificationStatus {
          id
        }
        issuingOrganisation
        registrationNumber
        issueDate
        expiryDate
        notes
        attachments {
          id
          displayName
          extension
          size
          humanReadableSize
          createdAt
          createdAtDateTime
          downloadUrlAbsolute
          uploadStatus
          personId
          downloadUrl
          folderId
          category
        }
      }
    }
  }
`

const useOnboardingQualificationInstanceCreate = () => {
  return useMutation<OnboardingQualificationInstanceCreate, OnboardingQualificationInstanceCreateVariables>(
    ONBOARDING_QUALIFICATION_INSTANCE_CREATE,
  )
}

export type { IQualificationInstance }
export { useOnboardingQualificationInstanceCreate }
