import { createResponsiveStateReducer } from 'redux-responsive'
import { Variables } from '@intellihr/ui-components'

interface IBrowserBreakpoints {
  xsmall: number
  small: number
  medium: number
  large: number
}
const breakpoints = {
  xsmall: Variables.Breakpoint.breakpointMin,
  small: Variables.Breakpoint.breakpointTablet - 1,
  medium: Variables.Breakpoint.breakpointDesktop - 1,
  large: Variables.Breakpoint.breakpointBigDesktop - 1,
}

/*
  redux-responsive breakpoints define the maximum screen width for that value
  eg. given the following values: small = 639 and medium = 1023
      the medium breakpoint will be a range from 640 to 1023
 */
const browserReducer = createResponsiveStateReducer(breakpoints)

export type { IBrowserBreakpoints }
export { browserReducer }
