import gql from 'graphql-tag'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import { useQuery } from 'src/services/apollo'
import { useMemo } from 'react'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'
import { listCustomFieldDefinitionsFragment } from './listCustomFieldDefinitionsFragment'
import {
  ListCustomFieldDefinitionsWithManagePermissions,
  ListCustomFieldDefinitionsWithManagePermissionsVariables,
} from './__gql__/listDefinitionsWithManagePermissionCodeGen'

interface IListCustomFieldDefinitionsWithPermissionsProps {
  personId?: string
  modelType: CustomFieldDefinitionModelType
  targetType: 'functional' | 'people'
  type?: 'view' | 'manage'
  requiresAllPermission?: boolean
}

const LIST_DEFINITIONS_WITH_MANAGE_PERMISSION = gql`
  ${listCustomFieldDefinitionsFragment}

  query ListCustomFieldDefinitionsWithManagePermissions(
    $personId: ID
    $sourceUserId: ID!
    $modelType: String!
    $targetType: String!
  ) {
    ...AllCustomFieldDefinitions
    performSingleCustomFieldModelAuthCheck(
      input: {
        sourceUserId: $sourceUserId
        customFieldModelType: $modelType
        targetResource: { id: $personId, type: $targetType }
      }
    ) {
      authorisations {
        permissionSlug
        passes
      }
    }
  }
`

const permissionForCustomField = (
  modelType: CustomFieldDefinitionModelType,
  apiName: string,
  type?: 'view' | 'manage',
) => {
  const prefixes = {
    [CustomFieldDefinitionModelType.JOB]: 'people.jobs',
    [CustomFieldDefinitionModelType.PERSON]: 'people',
    [CustomFieldDefinitionModelType.BUSINESS_UNIT]: 'business_units',
    [CustomFieldDefinitionModelType.LOCATION]: 'locations',
    [CustomFieldDefinitionModelType.BUSINESS_ENTITY]: 'business_entities',
    [CustomFieldDefinitionModelType.POSITION_TITLE]: 'position_titles',
    [CustomFieldDefinitionModelType.EMAIL_ADDRESS]: 'people.email_addresses',
    [CustomFieldDefinitionModelType.PHONE_NUMBER]: 'people.phone_numbers',
    [CustomFieldDefinitionModelType.ADDRESS]: 'people.addresses',
  }

  return `${prefixes[modelType]}.custom_fields.${apiName}.${type}`
}

const useListCustomFieldDefinitionsWithPermissions = ({
  personId,
  modelType,
  targetType,
  type = 'manage',
  requiresAllPermission = true,
}: IListCustomFieldDefinitionsWithPermissionsProps) => {
  const { id: loggedInUserId } = useUserContext()
  const query = useQuery<
    ListCustomFieldDefinitionsWithManagePermissions,
    ListCustomFieldDefinitionsWithManagePermissionsVariables
  >(LIST_DEFINITIONS_WITH_MANAGE_PERMISSION, {
    errorBehaviour: 'throw-exception',
    variables: {
      personId,
      targetType,
      modelType,
      sourceUserId: loggedInUserId,
    },
    skip: !personId && targetType === 'people',
  })

  return useMemo(() => {
    if (!query.data?.listAllCustomFieldDefinitions) {
      return {
        loading: query.loading,
        customFieldDefinitions: undefined,
        missingMandatoryCustomFields: [],
      }
    }
    const permissionChecks = query.data.performSingleCustomFieldModelAuthCheck.authorisations
    const manageableCustomFields = query.data.listAllCustomFieldDefinitions.filter((customFieldDefinition) => {
      const { apiName } = customFieldDefinition
      const baseSlug = permissionForCustomField(modelType, apiName, type)
      const slug = targetType === 'functional' && requiresAllPermission ? `${baseSlug}#all` : baseSlug

      for (const permissionCheck of permissionChecks) {
        if (permissionCheck.permissionSlug === slug && permissionCheck.passes) {
          return true
        }
      }
      return false
    })

    const requiredCustomFields = query.data.listAllCustomFieldDefinitions.filter(
      (customFieldDefinition) => customFieldDefinition.isMandatory && customFieldDefinition.modelType === modelType,
    )

    const missingMandatoryCustomFields = requiredCustomFields.filter(
      (requiredCustomField) => !manageableCustomFields.includes(requiredCustomField),
    )

    return {
      loading: query.loading,
      customFieldDefinitions: manageableCustomFields,
      missingMandatoryCustomFields,
    }
  }, [query.data, query.loading, targetType, modelType, type, requiresAllPermission])
}

export { useListCustomFieldDefinitionsWithPermissions }
