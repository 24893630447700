const setupBlurButtonOnMouseMove = () => {
  const body = document.getElementsByTagName('body')[0]

  body.addEventListener('mousemove', () => {
    const activeElement = document.activeElement

    if (activeElement && activeElement instanceof HTMLElement) {
      if (activeElement.getAttribute('data-component-type') === 'button') {
        activeElement.blur()
        return
      }

      if (activeElement.parentElement?.getAttribute('role') === 'tab') {
        activeElement.blur()
        return
      }
    }
  })
}

export { setupBlurButtonOnMouseMove }
