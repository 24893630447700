import { Location, History } from 'history'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Route } from 'react-router'
import { Router } from 'react-router-dom'
import { ErrorBoundaryWithoutUserDetails, PageNotFound } from 'src/services/error'
import { getHistory } from 'src/services/history/history'
import { SessionDetailsProvider } from 'src/services/user'
import { App } from 'src/App'
import { ConfigCatProvider, getConfigCat } from 'src/services/configCat/services/configCat'
import { UIComponentsDefaultsProvider } from 'src/services/ui-components/UIComponentsDefaultsProvider'
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { BlueberryDefaultsProvider } from 'src/services/blueberry/BlueberryDefaultsProvider'
import { Store } from 'redux'
import { IConfigCatClient } from 'configcat-js'
import i18next from 'i18next'
import { getStore } from 'src/services/store'
import { IInitBackendProps, LocalizationProvider, useHttpBackend } from 'src/services/i18n/LocalizationProvider'
import {
  ISession,
  ISessionProvider,
  useSessionProvider,
} from 'src/services/user/SessionDetailsProvider/hooks/useSessionProvider'
import { CompatRouter } from 'react-router-dom-v5-compat'

interface ISetupReact {
  reduxStore?: Store
  apolloClient: ApolloClient<NormalizedCacheObject>
  configCatClient?: IConfigCatClient
  sessionDetails?: (session: ISession) => ISessionProvider
  i18nClient?: (val: IInitBackendProps) => i18next.i18n
  routerHistory?: History
  children?: React.ReactNode
}

const renderApp = ({ location }: { location: Location }) => {
  const state = location.state as { is404?: boolean } | undefined
  if (state?.is404) {
    state.is404 = false
    return <PageNotFound />
  }

  return <App />
}

const setupReact = ({
  reduxStore = getStore(),
  apolloClient,
  configCatClient = getConfigCat(),
  sessionDetails = useSessionProvider,
  i18nClient = useHttpBackend,
  routerHistory = getHistory(),
  children = <Route render={renderApp} />,
}: ISetupReact) => {
  // This const can be deleted when react router is upgraded to latest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const RouterAny: any = Router

  return (
    <ErrorBoundaryWithoutUserDetails>
      <ReduxProvider store={reduxStore}>
        <ApolloProvider client={apolloClient}>
          <ConfigCatProvider value={{ configCatClient }}>
            <SessionDetailsProvider sessionDetails={sessionDetails}>
              <LocalizationProvider initI18nBackend={i18nClient}>
                <UIComponentsDefaultsProvider>
                  <RouterAny history={routerHistory}>
                    <CompatRouter>
                      <BlueberryDefaultsProvider>{children}</BlueberryDefaultsProvider>
                    </CompatRouter>
                  </RouterAny>
                </UIComponentsDefaultsProvider>
              </LocalizationProvider>
            </SessionDetailsProvider>
          </ConfigCatProvider>
        </ApolloProvider>
      </ReduxProvider>
    </ErrorBoundaryWithoutUserDetails>
  )
}

export { setupReact, renderApp }
