import { Props, Text, TextLink, Variables } from '@intellihr/ui-components'
import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { IBrowserSelector, isMobileSelector } from 'src/services/reducers/browser/selectors'
import { IReduxVerticalFormFieldParam, ReduxVerticalFormField } from 'src/services/reduxForm'
import { AutocompleteLocationInput } from 'src/services/googleMaps/components/AutocompleteLocationInput'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { RootState } from 'src/services/store/store'
import { validationCallback } from '../../types'

interface IAutocompleteAddressFieldProps {
  label?: string
  name?: string
  /** the address types of return value. Types can be added to the array. 'geocode' for address, 'regions' for state,  'cities' for  suburb. If it is null, it will return all the value */
  addressTypesIncluded?: string[] | null
  isRequired?: boolean
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuggestSelect?: (suggest: any) => void
  onClickManualOptionButton?: (event: React.MouseEvent<HTMLElement>) => void
  /** If true, sets input to disabled state */
  isDisabled?: boolean
  onResetClick?: () => void
  tenantCountry?: string | null
  initialValue?: string
  validation?: validationCallback
}

const AutocompleteAddressFieldBase: React.FC<IAutocompleteAddressFieldProps & IBrowserSelector> = ({
  label,
  name = 'fullAddress',
  isRequired = false,
  isDisabled = false,
  onClickManualOptionButton,
  addressTypesIncluded,
  placeholder,
  onSuggestSelect,
  onResetClick,
  tenantCountry,
  initialValue,
  isMobile,
  validation,
}) => {
  const tFeature = useScope('edm:features.AddressForm')
  const actionMessage = (): JSX.Element => {
    return (
      <tFeature.Trans i18nKey="inputActionSwitchToManual">
        <Text
          type={Props.TypographyType.Small}
          color={Variables.Color.n600}
        >
          `Can&apos;t find the Address? `
        </Text>
        <TextLink
          onClick={onClickManualOptionButton}
          textType={Props.TypographyType.Small}
        >
          `Enter Manually`
        </TextLink>
      </tFeature.Trans>
    )
  }

  const tooltipMessage = (): JSX.Element => {
    return <span>{tFeature('tooltipUnitLookup')}</span>
  }

  const tooltipWidth = (): number => {
    return isMobile ? 220 : 366
  }

  return (
    <Field<IReduxVerticalFormFieldParam>
      name={name}
      component={ReduxVerticalFormField}
      validate={validation}
      props={{
        label,
        isRequired,
        component: AutocompleteLocationInput,
        actionMessage: actionMessage(),
        tooltipMessage: tooltipMessage(),
        tooltipProps: { width: tooltipWidth() },
        inputProps: {
          placeholder,
          addressTypesIncluded,
          onSuggestSelect,
          isDisabled,
          onResetClick,
          biasCountry: tenantCountry,
          initialValue,
        },
      }}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isMobile: isMobileSelector(state),
  }
}

const AutocompleteAddressField = connect<IBrowserSelector>(mapStateToProps)(AutocompleteAddressFieldBase)

export { AutocompleteAddressField, AutocompleteAddressFieldBase }
