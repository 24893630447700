import styled from 'styled-components'
import { Variables, Utils } from '@intellihr/ui-components'

const StyledPeopleButton = styled.div`
  cursor: pointer;
  width: 100%;
`

const StyledLimitedListHeight = styled.div`
  max-height: 380px;
  min-width: 240px;
  overflow-y: auto;

  ${Utils.mediaQueryBetweenSizes({ maxPx: Variables.Breakpoint.breakpointDesktop })} {
    max-width: 240px;
  }

  ${Utils.mediaQueryBetweenSizes({ minPx: Variables.Breakpoint.breakpointDesktop })} {
    max-width: 380px;
  }
`

export { StyledPeopleButton, StyledLimitedListHeight }
