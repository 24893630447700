import React from 'react'
import { Subtract } from 'src/services/typescript'
import { IUserContextStructure, UserContext } from 'src/services/user/UserContext'

interface IUserDetailsProps {
  userDetails: IUserContextStructure
}

const withUserDetails = <P extends {}>(
  UserDetailsAwareComponent: React.ComponentType<P & Partial<IUserDetailsProps>>,
) =>
  class WithUserDetails extends React.Component<Subtract<P, IUserDetailsProps>> {
    public render(): JSX.Element {
      return (
        <UserContext.Consumer>
          {(userDetails) => (
            <UserDetailsAwareComponent
              {...(this.props as P)}
              userDetails={{
                timezone: userDetails.timezone,
                dateFormat: userDetails.dateFormat,
                timeFormat: userDetails.timeFormat,
                language: userDetails.language,
                person: userDetails.person,
              }}
            />
          )}
        </UserContext.Consumer>
      )
    }
  }

export type { IUserDetailsProps }
export { withUserDetails }
