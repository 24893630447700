import React, { useMemo } from 'react'
import { IconEarthAmericas, DropdownMenu, TDropdownMenu, ButtonIcon } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { useTenantContext } from 'src/services/user/TenantContext/helpers/hook'
import { useGetSupportedLanguagesQuery } from 'src/domain/PlatformSetup/public/services/supportedLanguagesService'
import { PureQueryOptions } from '@apollo/client'
import { useUpdatePreferencesWithRefetch } from './gql'

const LanguageSelectButton: React.FC<{ refetchQueries?: PureQueryOptions[] }> = ({ refetchQueries }) => {
  const t = useScope('platformSetup:features.AccountSettings')
  const { data } = useGetSupportedLanguagesQuery()
  const { language: selectedLanguage } = useTenantContext()
  const [updatePreference] = useUpdatePreferencesWithRefetch(t('messages.updateSuccessLanguage'), refetchQueries ?? [])

  const options = useMemo<TDropdownMenu['items']>(
    () =>
      (data?.listSupportedLanguages ?? []).map((language) => {
        const name =
          language.localisedName === language.nativeName
            ? language.nativeName
            : `${language.localisedName} (${language.nativeName})`

        return {
          type: 'button',
          onClick: async () => {
            await updatePreference({ variables: { language: language.slug } })
          },
          label: name,
        }
      }),
    [data, updatePreference],
  )

  const formatSelectedLanguage = (selected: string) => {
    const lang = (data?.listSupportedLanguages ?? []).find((language) => language.slug === selected)
    return lang ? lang.localisedName : selected
  }

  return (
    <DropdownMenu
      items={options}
      trigger={
        <ButtonIcon
          icon={IconEarthAmericas}
          testId="language-select-button"
          label={formatSelectedLanguage(selectedLanguage)}
        />
      }
    />
  )
}

export { LanguageSelectButton }
