/* eslint-disable @typescript-eslint/no-explicit-any */

const ignoredTenant = (): boolean => {
  if (['internal-intellihr.intellihr.net', 'internal-humanforce.intellihr.net'].includes(location.hostname)) {
    return false
  }

  return (
    location.hostname.endsWith('intellihr.intellihr.net') ||
    location.hostname.endsWith('humanforce.intellihr.net') ||
    location.hostname.endsWith('-demo.intellihr.net') ||
    location.hostname.startsWith('qa-') ||
    location.hostname.startsWith('automated-tests-')
  )
}

type DelightedOptions = {
  email?: string
  name?: string
  properties: {
    [key: string]: string | boolean
  }
}

type Delighted = {
  survey: (options: DelightedOptions) => {}
}

const delighted: Delighted | null = (window as any).delightedNps2 ? (window as any).delightedNps2 : null

const shouldUseDelighted = (): boolean => {
  try {
    return process.env.REACT_APP_STAGE === 'prod' && !ignoredTenant() && !!delighted
  } catch (e) {
    return false
  }
}

const sendDelightedSurvey = (options: DelightedOptions) => {
  if (delighted && shouldUseDelighted()) {
    delighted.survey(options)
  }
}

export { sendDelightedSurvey }
