import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  OnboardingQualificationInstanceAttachmentDelete,
  OnboardingQualificationInstanceAttachmentDeleteVariables,
} from './__gql__/useOnboardingQualificationInstanceAttachmentDeleteCodeGen'

const ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_DELETE = gql`
  mutation OnboardingQualificationInstanceAttachmentDelete($id: ID!) {
    onboardingQualificationInstanceAttachmentDelete(id: $id) {
      documentId
    }
  }
`

const useOnboardingQualificationInstanceAttachmentDelete = (successMessage?: string) => {
  return useMutation<
    OnboardingQualificationInstanceAttachmentDelete,
    OnboardingQualificationInstanceAttachmentDeleteVariables
  >(ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_DELETE, {
    successMessage,
  })
}

export { useOnboardingQualificationInstanceAttachmentDelete }
