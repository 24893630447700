import type { Moment } from 'moment'

const momentToUTCOffsetString = (date: Moment) => {
  const totalMinuteOffset = date.utcOffset()
  const sign = totalMinuteOffset >= 0 ? '+' : '-'
  const timezoneHourFormat = Math.abs(Math.trunc(totalMinuteOffset / 60))
  const timezoneMinuteFormat = Math.abs(totalMinuteOffset % 60) > 0 ? `:${Math.abs(totalMinuteOffset % 60)}` : ''

  return `UTC${sign}${timezoneHourFormat}${timezoneMinuteFormat}`
}

const graphqlDateFormatToMomentFormat = (dateFormat: string) => {
  let dateFormatValue = ''

  switch (dateFormat) {
    case 'DMY':
      dateFormatValue = 'DD/MM/YYYY'
      break
    case 'MDY':
      dateFormatValue = 'MM/DD/YYYY'
      break
    case 'YMD':
      dateFormatValue = 'YYYY-MM-DD'
      break
    case 'VERBOSE':
      dateFormatValue = 'D MMM YYYY'
      break
    default:
      dateFormatValue = 'D MMM YYYY'
      break
  }

  return dateFormatValue
}

const graphqlTimeFormatToMomentFormat = (timeFormat: string) => {
  let timeFormatValue = ''

  switch (timeFormat) {
    case 'HOURS_12':
      timeFormatValue = 'h:mm A'
      break
    case 'HOURS_24':
      timeFormatValue = 'HH:mm [hrs]'
      break
    default:
      timeFormatValue = 'h:mm A'
      break
  }

  return timeFormatValue
}

export { momentToUTCOffsetString, graphqlDateFormatToMomentFormat, graphqlTimeFormatToMomentFormat }
