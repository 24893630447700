import React, { useState } from 'react'
import { cloneDeep } from 'lodash'
import { Button, Stack } from '@intellihr/blueberry'
import { FieldArray } from 'redux-form'
import { FormattedText, Props, SingleDateInputV2, Text, Variables, VerticalForm } from '@intellihr/ui-components'
import { ReduxForm, ReduxFormChange } from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { useBrowserBreakpoints } from 'src/services/reducers/browser/hooks'
import {
  convertCustomFieldFormValuesToApi,
  getCustomFieldsFormFields,
} from 'src/domain/EDM/public/services/customFieldValuesFormGeneratorService'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'
import { useToasts } from 'src/services/toasts'
import { useUserTimezoneDateModifiers } from 'src/services/helpers/dates'
import {
  IOnboardingField,
  IOnboardingFormInstance,
  IOnboardingPerson,
} from './hooks/useGetOnboardingFormInstanceAndPerson'
import { OnboardingCustomFields } from './OnboardingCustomFieldSection/OnboardingCustomFieldSection'
import { TitleSelectInput } from '../../../../forms/PersonCreateForm/sections/PersonalDetailsSection/TitleSelectInput'
import { GenderSelectInput } from '../../../../forms/PersonCreateForm/sections/PersonalDetailsSection/GenderSelectInput'
import { VisibilityOption } from '../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'
import { CountrySelectInput } from '../../../../forms/PersonCreateForm/sections/WorkRightsDetailsSection/CountrySelectInput'
import { WorkRightSelectInput } from '../../../../forms/PersonCreateForm/sections/WorkRightsDetailsSection/WorkRightSelectInput'
import { StyledGrid } from './style'
import { OnboardingFieldArray } from './OnboardingFieldArray/OnboardingFieldArray'
import type { IOnboardingFieldArrayProps } from './OnboardingFieldArray/OnboardingFieldArray'
import { OnboardingEmailField, OnboardingEmailFieldInput } from './OnboardingFieldArray/OnboardingEmailField'
import { OnboardingPhoneField, OnboardingPhoneFieldInput } from './OnboardingFieldArray/OnboardingPhoneField'
import { OnboardingMedicalConditionsField } from './OnboardingFieldArray/OnboardingMedicalConditionsField'
import { OnboardingField } from './OnboardingFieldArray/OnboardingField'
import { OnboardingMedicalConditionVisibilityInput } from './OnboardingFieldInputs/OnboardingMedicalConditionVisibilityInput'
import { OnboardingAddressField } from './OnboardingFieldArray/OnboardingAddressField'
import { useSubmitOnboardingForm } from './hooks/useSubmitOnboardingForm'
import { DOCUMENT_UPLOADS_SECTION } from '../../../../forms/SettingsOnboardingTemplateForm/constants'
import { OnboardingDocumentUploadSection } from './OnboardingDocumentUploadSection/OnboardingDocumentUploadSection'

enum OnboardingFieldType {
  ADDRESS = '89038452-c932-4e93-b44d-8644e65da498',
  ADDRESS_TYPE = '6206386d-3e2b-4bac-b0b8-8c8618bf54df',
  COUNTRY = '5d3aa582-647e-4e7c-a276-835f46b40b61',
  DOB = 'c66d530f-2459-4196-8208-6903292ee44e',
  EXPIRY_DATE = 'c2b2d04a-e742-4fc0-8660-10bad6300ec9',
  EMAIL_ADDRESS = '3a77b164-492a-4083-adcb-772caf6c1119',
  EMERGENCY_CONTACT_EMAIL = '3ad22fcc-d2fa-4c36-9819-c27302a32319',
  EMERGENCY_CONTACT_NAME = 'cdd2a9bf-61f7-46d1-82c9-b0cbaca45948',
  EMERGENCY_CONTACT_PHONE_NUMBER = '33703854-dbe9-4897-81bd-54cd35e728a2',
  FIRST_NAME = '3f38b7a5-6283-45d3-9c76-6e5e35ad58e4',
  GENDER = '898252b6-e5d8-419b-ad2c-7c3d010d30f5',
  LAST_NAME = '0821b026-3a77-468f-a1df-ccffab772352',
  MEDICAL_CONDITION = 'c0372611-a51d-4024-ad1f-a7b98806c11d',
  MEDICAL_CONDITION_VISIBILITY = 'be04d479-9777-4df2-8b0d-a6b7141c95b2',
  MIDDLE_NAME = '766232a1-6697-4c14-9854-4f20bea43ebd',
  PHONE_NUMBER = '27f525fb-438e-46fe-8df2-f360d60cf0c7',
  PREFERRED_NAME = '30711eed-b146-4db8-a82f-48c2cb59f95e',
  RELATIONSHIP = '5f862a47-9991-4c1d-a462-3e1452ed8f50',
  TITLE = 'e88f8063-d708-48ca-b73a-6b9df16d6803',
  WORK_RIGHT = 'ea0fde0a-ed26-40ba-bd92-3cdc4df2e696',
  WORK_RIGHT_COUNTRY = '5d3aa582-647e-4e7c-a276-835f46b40b61',
  WORK_RIGHT_EXPIRY_DATE = 'c2b2d04a-e742-4fc0-8660-10bad6300ec9',
}

const fieldApiNames = [
  'addressType',
  'dateOfBirth',
  'email',
  'emergencyContactEmail',
  'emergencyContactPhone',
  'emergencyContactName',
  'firstName',
  'fullAddress',
  'genderId',
  'lastName',
  'middleName',
  'body',
  'isPublic',
  'number',
  'preferredName',
  'emergencyContactRelationship',
  'titleId',
  'workRightId',
  'workRightCountryId',
  'workRightExpiryDate',
] as const

type IFieldApiName = (typeof fieldApiNames)[number]

const observedFields = new Set(fieldApiNames)

const fieldTypeIdToApiName: { [key in OnboardingFieldType]: IFieldApiName } = {
  [OnboardingFieldType.ADDRESS]: 'fullAddress',
  [OnboardingFieldType.ADDRESS_TYPE]: 'addressType',
  [OnboardingFieldType.DOB]: 'dateOfBirth',
  [OnboardingFieldType.EMAIL_ADDRESS]: 'email',
  [OnboardingFieldType.EMERGENCY_CONTACT_EMAIL]: 'emergencyContactEmail',
  [OnboardingFieldType.EMERGENCY_CONTACT_PHONE_NUMBER]: 'emergencyContactPhone',
  [OnboardingFieldType.EMERGENCY_CONTACT_NAME]: 'emergencyContactName',
  [OnboardingFieldType.FIRST_NAME]: 'firstName',
  [OnboardingFieldType.GENDER]: 'genderId',
  [OnboardingFieldType.LAST_NAME]: 'lastName',
  [OnboardingFieldType.MIDDLE_NAME]: 'middleName',
  [OnboardingFieldType.MEDICAL_CONDITION]: 'body',
  [OnboardingFieldType.MEDICAL_CONDITION_VISIBILITY]: 'isPublic',
  [OnboardingFieldType.PHONE_NUMBER]: 'number',
  [OnboardingFieldType.PREFERRED_NAME]: 'preferredName',
  [OnboardingFieldType.RELATIONSHIP]: 'emergencyContactRelationship',
  [OnboardingFieldType.TITLE]: 'titleId',
  [OnboardingFieldType.WORK_RIGHT]: 'workRightId',
  [OnboardingFieldType.WORK_RIGHT_COUNTRY]: 'workRightCountryId',
  [OnboardingFieldType.WORK_RIGHT_EXPIRY_DATE]: 'workRightExpiryDate',
}

const fieldTypeIdToComponentLookup: Partial<{
  [key in OnboardingFieldType]: { Component: React.FC; props?: unknown }
}> = {
  [OnboardingFieldType.TITLE]: { Component: TitleSelectInput },
  [OnboardingFieldType.GENDER]: { Component: GenderSelectInput },
  [OnboardingFieldType.DOB]: { Component: SingleDateInputV2 },
  [OnboardingFieldType.COUNTRY]: { Component: CountrySelectInput },
  [OnboardingFieldType.WORK_RIGHT]: { Component: WorkRightSelectInput },
  [OnboardingFieldType.EXPIRY_DATE]: { Component: SingleDateInputV2 },
  [OnboardingFieldType.MEDICAL_CONDITION_VISIBILITY]: { Component: OnboardingMedicalConditionVisibilityInput },
  [OnboardingFieldType.EMERGENCY_CONTACT_EMAIL]: { Component: OnboardingEmailFieldInput },
  [OnboardingFieldType.EMERGENCY_CONTACT_PHONE_NUMBER]: {
    Component: OnboardingPhoneFieldInput,
    props: { countryFieldName: 'emergencyContactPhoneCountryId' },
  },
}

const independentFields = [
  OnboardingFieldType.EMERGENCY_CONTACT_EMAIL,
  OnboardingFieldType.EMERGENCY_CONTACT_PHONE_NUMBER,
]

enum AddressType {
  HOME = '5bf5e915-1e41-4d8a-92c6-05ff519ff5b0',
  POSTAL = 'd921895e-4305-4def-9b96-da6c8c8c87dc',
}

const fieldToDisplayCell = {
  [OnboardingFieldType.ADDRESS_TYPE]: (value: string) => {
    const t = useScope('edm:features.Onboarding')
    const type = value === AddressType.HOME ? t('addressTypeHome') : t('addressTypePostal')
    return <Text>{type}</Text>
  },
  [OnboardingFieldType.MEDICAL_CONDITION_VISIBILITY]: (value: string) => {
    const t = useScope('edm:features.Onboarding')
    const label = value === 'private' ? t('labelOptionPrivate') : t('labelOptionPublic')

    return <Text>{label}</Text>
  },
}

enum TableSection {
  EMAIL_ADDRESS = 'dcb00cb4-44d7-4647-a742-7b5d274c0198',
  PHONE_NUMBER = 'de2366f5-bb56-43b0-b30a-343a1a5d7ffc',
  ADDRESS = '8eb77b70-3616-4424-bef5-f066bf51bb57',
  MEDICAL_CONDITIONS = 'b80f3bb6-bf7a-4467-bdca-bdcc4b79af8f',
}

const tableSectionToApiName: { [key: string]: string } = {
  [TableSection.ADDRESS]: 'addresses',
  [TableSection.EMAIL_ADDRESS]: 'emailAddresses',
  [TableSection.MEDICAL_CONDITIONS]: 'medicalConditions',
  [TableSection.PHONE_NUMBER]: 'phoneNumbers',
}

const sectionTypeIdToComponentLookup: Partial<{
  [key in TableSection]: React.FC<{ fields: IOnboardingField[]; values?: { [key: string]: string } }>
}> = {
  [TableSection.ADDRESS]: OnboardingAddressField,
  [TableSection.EMAIL_ADDRESS]: OnboardingEmailField,
  [TableSection.MEDICAL_CONDITIONS]: OnboardingMedicalConditionsField,
  [TableSection.PHONE_NUMBER]: OnboardingPhoneField,
}

const tableSections = Object.values(TableSection)

const sectionTypeToCustomFieldModel = {
  [TableSection.EMAIL_ADDRESS]: CustomFieldDefinitionModelType.EMAIL_ADDRESS,
  [TableSection.PHONE_NUMBER]: CustomFieldDefinitionModelType.PHONE_NUMBER,
  [TableSection.ADDRESS]: CustomFieldDefinitionModelType.ADDRESS,
}

interface IOnboardingFormDataProps {
  onboardingPerson?: IOnboardingPerson
  onboardingFormInstance?: IOnboardingFormInstance
  onSubmit: () => void
}

type CustomFieldCategoryLookup = { [key: string]: { description: string; categoryName: string } }

interface IUploadedDocument {
  documentId: string
  documentFolderId: string
}

const formName = 'onboardingForm'

const PersonalDetailsFormStepContent: React.FC<IOnboardingFormDataProps> = ({
  onboardingPerson,
  onboardingFormInstance,
  onSubmit,
}) => {
  const tCommon = useScope('common:components')
  const tFeature = useScope('edm:features.Onboarding')
  const [uploadedDocuments, setUploadedDocuments] = useState<IUploadedDocument[]>([])
  const postToast = useToasts()
  const [submitOnboardingForm, { loading: savingForm }] = useSubmitOnboardingForm(tFeature('successMessage'))
  const { toYMD } = useUserTimezoneDateModifiers()

  if (!onboardingFormInstance) {
    return null
  }

  const customFieldSectionDescriptionLookup: CustomFieldCategoryLookup = {}
  onboardingFormInstance?.template.customFieldCategories.forEach(
    ({ description, customFieldCategory: { id, name } }) => {
      customFieldSectionDescriptionLookup[id] = { description: description ?? '', categoryName: name }
    },
  )

  const existingEmailAddresses = onboardingPerson?.emailAddresses.map(({ email, customFieldValues }) => {
    return {
      email,
      customFields: customFieldValues,
      existing: true,
    }
  })

  const existingPhoneNumbers = onboardingPerson?.phoneNumbers.map(({ number, customFieldValues }) => {
    return {
      number,
      customFields: customFieldValues,
      existing: true,
    }
  })

  const existingAddresses = onboardingPerson?.addresses.map(({ fullAddress, addressType, customFieldValues }) => {
    return {
      fullAddress,
      addressType: addressType.id,
      customFieldValues,
      existing: true,
    }
  })

  const existingMedicalConditions = onboardingPerson?.medicalConditions.map(({ body, isPublic }) => {
    return {
      body,
      isPublic,
      existing: true,
    }
  })

  const sectionToInitialValuesMap = {
    [TableSection.EMAIL_ADDRESS]: existingEmailAddresses,
    [TableSection.PHONE_NUMBER]: existingPhoneNumbers,
    [TableSection.ADDRESS]: existingAddresses,
    [TableSection.MEDICAL_CONDITIONS]: existingMedicalConditions,
  }

  const { sections, fields, customFields, documentFolders } = onboardingFormInstance.template

  const sectionFieldsLookup: { [key: string]: IOnboardingField[] } = {}

  sections.forEach((section) => {
    sectionFieldsLookup[section.sectionType.id] = []
  })

  fields.forEach((field) => {
    sectionFieldsLookup[field.fieldType.sectionTypeId].push(field)
  })

  Object.values(sectionFieldsLookup).forEach((fields) => {
    fields.sort((a, b) => {
      if (a.fieldType.sortOrder > b.fieldType.sortOrder) {
        return 1
      } else if (a.fieldType.sortOrder < b.fieldType.sortOrder) {
        return -1
      }
      return 0
    })
  })

  const mandatoryFieldOverrides: Parameters<typeof getCustomFieldsFormFields>[0]['mandatoryFieldOverrides'] = {}

  const customFieldDefinitions = customFields
    .filter((customField) => customField.visibility.id !== VisibilityOption.HIDDEN)
    .map((customField) => {
      mandatoryFieldOverrides[customField.customFieldDefinition.id] =
        customField.visibility.id === VisibilityOption.REQUIRED
      return customField.customFieldDefinition
    })

  const sortedSections = (reduxFormChange: ReduxFormChange, observedFieldValues: { [key: string]: string }) =>
    [...sections]
      .sort((a, b) => {
        if (a.sectionType.sortOrder > b.sectionType.sortOrder) {
          return 1
        } else if (a.sectionType.sortOrder < b.sectionType.sortOrder) {
          return -1
        }
        return 0
      })
      .map((section) => {
        const customFieldModel = sectionTypeToCustomFieldModel[section.sectionType.id]
        let customFieldInputs: JSX.Element[] = []
        if (customFieldModel) {
          customFieldInputs = getCustomFieldsFormFields({
            customFields: customFieldDefinitions,
            modelType: sectionTypeToCustomFieldModel[section.sectionType.id],
            mandatoryFieldOverrides,
          })
        }

        if (tableSections.includes(section.sectionType.id as TableSection)) {
          const sectionName = tableSectionToApiName[section.sectionType.id]
          const sectionFields = sectionFieldsLookup[section.sectionType.id]
          const existingValues = sectionToInitialValuesMap[section.sectionType.id]
          return (
            <VerticalForm.Section
              key={section.id}
              name={section.sectionType.name}
            >
              <FieldArray<IOnboardingFieldArrayProps>
                name={sectionName}
                component={OnboardingFieldArray}
                props={{
                  existingValues,
                  sectionFields,
                  sectionId: section.sectionType.id,
                  sectionName,
                  sectionDescription: section.description ?? '',
                  sectionFieldInput: ({ fields, values, reduxFormChange }) => {
                    const Component = sectionTypeIdToComponentLookup[section.sectionType.id]
                    return (
                      <Component
                        fields={fields}
                        values={values}
                        reduxFormChange={reduxFormChange}
                      />
                    )
                  },
                  sectionCustomFields: customFieldInputs,
                  reduxFormChange,
                }}
              />
            </VerticalForm.Section>
          )
        }

        if (section.sectionType.id === DOCUMENT_UPLOADS_SECTION) {
          return (
            <VerticalForm.Section
              key={section.sectionType.id}
              name={section.sectionType.name}
            >
              <Stack space={'medium'}>
                <div data-cy={`description-${section.sectionType.id}`}>
                  <FormattedText
                    allowedTypes="strict"
                    text={section.description ?? ''}
                  />
                </div>
                <OnboardingDocumentUploadSection
                  documentFolders={documentFolders}
                  onboardingPersonId={onboardingPerson!.id}
                  uploadedDocuments={uploadedDocuments}
                  setUploadedDocuments={setUploadedDocuments}
                />
              </Stack>
            </VerticalForm.Section>
          )
        }

        const atLeastOneVisibleField = sectionFieldsLookup[section.sectionType.id].some(
          (field) => field.visibility.id !== VisibilityOption.HIDDEN,
        )
        return (
          atLeastOneVisibleField && (
            <VerticalForm.Section
              key={section.id}
              name={section.sectionType.name}
            >
              <Stack space={'medium'}>
                <div data-cy={`description-${section.sectionType.id}`}>
                  <FormattedText
                    allowedTypes="strict"
                    text={section.description ?? ''}
                  />
                </div>
                <Grid>
                  {sectionFieldsLookup[section.sectionType.id].map((field) => {
                    if (field.visibility.id === VisibilityOption.HIDDEN) {
                      return null
                    }
                    if (independentFields.includes(field.fieldType.id as OnboardingFieldType)) {
                      const { Component, props } = fieldTypeIdToComponentLookup[field.fieldType.id]
                      return (
                        <Component
                          key={field.id}
                          field={field}
                          value={observedFieldValues[fieldTypeIdToApiName[field.fieldType.id]]}
                          {...props}
                        />
                      )
                    }
                    return (
                      <OnboardingField
                        key={field.id}
                        field={field}
                        observedFieldValues={observedFieldValues}
                      />
                    )
                  })}
                  {customFieldInputs}
                </Grid>
              </Stack>
            </VerticalForm.Section>
          )
        )
      })

  const initialCustomFields = {}

  const customFieldType = onboardingPerson?.customFieldValues

  if (customFieldType) {
    customFieldType.forEach((customFieldValue) => {
      if (customFieldValue.valueUnion) {
        if (
          customFieldValue.type === 'MULTI_SELECT' &&
          customFieldValue.valueUnion?.__typename === 'CustomFieldValueMultiSelect'
        ) {
          const { ids } = customFieldValue.valueUnion
          initialCustomFields[customFieldValue.apiName] = ids
        } else if (
          customFieldValue.type === 'SINGLE_SELECT' &&
          customFieldValue.valueUnion?.__typename === 'CustomFieldValueSingleSelect'
        ) {
          const { id } = customFieldValue.valueUnion
          initialCustomFields[customFieldValue.apiName] = id
        } else if (
          customFieldValue.type === 'TEXT' &&
          customFieldValue.valueUnion?.__typename === 'CustomFieldValueText'
        ) {
          const { text } = customFieldValue.valueUnion
          initialCustomFields[customFieldValue.apiName] = text
        } else if (
          customFieldValue.type === 'NUMBER' &&
          customFieldValue.valueUnion?.__typename === 'CustomFieldValueNumber'
        ) {
          const { number } = customFieldValue.valueUnion
          initialCustomFields[customFieldValue.apiName] = number
        } else if (
          customFieldValue.type === 'PEOPLE_DROPDOWN' &&
          customFieldValue.valueUnion?.__typename === 'CustomFieldValuePeopleDropdown'
        ) {
          const { options } = customFieldValue.valueUnion
          const peopleDropdown = options.map((option) => ({
            personId: option.person.id,
            jobId: option.job?.id || null,
            person: option.person,
            job: option.job || null,
          }))
          initialCustomFields[customFieldValue.apiName] = peopleDropdown
        }
      }
    })
  }

  const fieldToInitialValue: Partial<{ [key in OnboardingFieldType]: { key: IFieldApiName; value: unknown } }> = {
    [OnboardingFieldType.TITLE]: {
      key: 'titleId',
      value: onboardingPerson?.title?.id,
    },
    [OnboardingFieldType.DOB]: {
      key: 'dateOfBirth',
      value: onboardingPerson?.dateOfBirth,
    },
    [OnboardingFieldType.EMERGENCY_CONTACT_EMAIL]: {
      key: 'emergencyContactEmail',
      value: onboardingPerson?.emergencyContactEmailAddress,
    },
    [OnboardingFieldType.EMERGENCY_CONTACT_PHONE_NUMBER]: {
      key: 'emergencyContactPhone',
      value: onboardingPerson?.emergencyContactPhoneNumber,
    },
    [OnboardingFieldType.EMERGENCY_CONTACT_NAME]: {
      key: 'emergencyContactName',
      value: onboardingPerson?.emergencyContactName,
    },
    [OnboardingFieldType.FIRST_NAME]: {
      key: 'firstName',
      value: onboardingPerson?.firstName,
    },
    [OnboardingFieldType.GENDER]: {
      key: 'genderId',
      value: onboardingPerson?.gender?.id,
    },
    [OnboardingFieldType.LAST_NAME]: {
      key: 'lastName',
      value: onboardingPerson?.lastName,
    },
    [OnboardingFieldType.MIDDLE_NAME]: {
      key: 'middleName',
      value: onboardingPerson?.middleName,
    },
    [OnboardingFieldType.PREFERRED_NAME]: {
      key: 'preferredName',
      value: onboardingPerson?.preferredName,
    },
    [OnboardingFieldType.RELATIONSHIP]: {
      key: 'emergencyContactRelationship',
      value: onboardingPerson?.emergencyContactRelationship,
    },
    [OnboardingFieldType.WORK_RIGHT]: {
      key: 'workRightId',
      value: onboardingPerson?.workRight?.id,
    },
    [OnboardingFieldType.WORK_RIGHT_COUNTRY]: {
      key: 'workRightCountryId',
      value: onboardingPerson?.workRight?.countryId,
    },
    [OnboardingFieldType.WORK_RIGHT_EXPIRY_DATE]: {
      key: 'workRightExpiryDate',
      value: onboardingPerson?.workRightExpiryDate,
    },
  }

  const initialValues = { customFields: initialCustomFields }
  fields.forEach((field) => {
    if (field.visibility.id !== VisibilityOption.HIDDEN) {
      const entry: { key: IFieldApiName; value: unknown } | undefined = fieldToInitialValue[field.fieldType.id]
      if (entry) {
        initialValues[entry.key] = entry.value
      }
    }
  })

  const validateTableSections = (data: typeof initialValues) => {
    let valid = true
    const toastMessages: string[] = []

    sections.forEach((section) => {
      if (
        tableSections.includes(section.sectionType.id as TableSection) &&
        section.sectionType.id !== TableSection.MEDICAL_CONDITIONS
      ) {
        const sectionApiName = tableSectionToApiName[section.sectionType.id]

        // section data does not exist in form data
        if (!Object.prototype.hasOwnProperty.call(data, sectionApiName)) {
          // existing table data is not included in the form data
          // so we need to specifically check on existing array
          if (sectionApiName === 'emailAddresses' && !existingEmailAddresses?.length) {
            toastMessages.push(tFeature('toastSectionEmailsFail'))
            valid = false
          }

          if (sectionApiName === 'addresses' && !existingAddresses?.length) {
            toastMessages.push(tFeature('toastSectionAddressesFail'))
            valid = false
          }

          if (sectionApiName === 'phoneNumbers' && !existingPhoneNumbers?.length) {
            toastMessages.push(tFeature('toastSectionPhoneFail'))
            valid = false
          }
        }
      }
    })

    documentFolders.forEach((docFolder) => {
      if (docFolder.visibility.id === VisibilityOption.REQUIRED) {
        const uploadedDocument = uploadedDocuments.find((doc) => doc.documentFolderId === docFolder.id)
        if (!uploadedDocument || uploadedDocument.documentId === null) {
          toastMessages.push(tFeature('toastSectionDocumentsFail'))
          valid = false
        }
      }
    })

    if (!valid) {
      postToast({
        content: toastMessages.join(' '),
        type: 'alert',
      })
    }

    return valid
  }

  return (
    <ReduxForm
      form={formName}
      initialValues={initialValues}
      formComponent={VerticalForm}
      observedFields={observedFields}
      onSubmit={async (formData: typeof initialValues) => {
        const requestData: Omit<typeof initialValues, 'customFields'> & {
          customFields?: {}
          customFieldValues?: typeof customFieldValues
          dateOfBirth?: string
          workRightExpiryDate?: string
        } = cloneDeep(formData)

        if (typeof requestData.dateOfBirth === 'string') {
          // Remove time from dob as timezone is not relevant
          requestData.dateOfBirth = toYMD(requestData.dateOfBirth)
        }

        if (typeof requestData.workRightExpiryDate === 'string') {
          // Remove time from work right expiry date as timezone is not relevant
          requestData.workRightExpiryDate = toYMD(requestData.workRightExpiryDate)
        }

        // convert custom fields on main form body
        delete requestData.customFields
        const customFieldValues = convertCustomFieldFormValuesToApi(customFieldDefinitions, formData.customFields)
        requestData.customFieldValues = customFieldValues

        // convert custom fields on each item
        Object.values(requestData).forEach((value) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              if (item.customFields) {
                item.customFieldValues = convertCustomFieldFormValuesToApi(customFieldDefinitions, item.customFields)
                delete item.customFields
              }
            })
          }
        })

        const valid = validateTableSections(formData)

        if (valid) {
          await submitOnboardingForm({
            variables: {
              input: {
                onboardingFormId: onboardingFormInstance.id,
                ...requestData,
              },
            },
          }).then((result) => {
            if (!result.errors) {
              onSubmit()
            }
          })
        }
      }}
    >
      {({ reduxFormChange, observedFieldValues }) => {
        return (
          <Stack space="xLarge">
            <Stack space="small">
              <Text
                isInline={false}
                type={Props.TypographyType.Display}
                weight={Variables.FontWeight.fwSemiBold}
              >
                {tFeature('profileDetailsHeading')}
              </Text>
              <Text isInline={false}>{tFeature('onboardingDescription')}</Text>
            </Stack>
            {sortedSections(reduxFormChange, observedFieldValues)}
            <OnboardingCustomFields
              onboardingCustomFields={customFields}
              categoryLookup={customFieldSectionDescriptionLookup}
            />
            <Button
              type="submit"
              disabled={savingForm}
              loading={savingForm}
            >
              {tCommon('buttons.save')}
            </Button>
          </Stack>
        )
      }}
    </ReduxForm>
  )
}

const Grid: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { mobile } = useBrowserBreakpoints()
  return <StyledGrid mobile={mobile}>{children}</StyledGrid>
}

export {
  IUploadedDocument,
  PersonalDetailsFormStepContent,
  Grid,
  fieldTypeIdToComponentLookup,
  fieldTypeIdToApiName,
  IFieldApiName,
  fieldToDisplayCell,
}
export type { CustomFieldCategoryLookup }
