import type { FetchResult, MutationFunction, MutationFunctionOptions, OperationVariables } from '@apollo/client'
import React from 'react'
import { getErrorMessage } from 'src/services/graphQL'
import { useToasts } from 'src/services/toasts'
import { cloudLogger } from 'src/services/error'
import { Mutation as ApolloMutation, MutationComponentOptions } from '@apollo/client/react/components'

type SuccessMessageCallback<TData> = (response: FetchResult<TData>) => string | null | undefined

interface IMutationErrorHandlingOptions<TData> {
  successMessage?: string | SuccessMessageCallback<TData>
  errorMessage?: string | ((error: Error) => string)
  postToast: ReturnType<typeof useToasts>
}

function mutationActionWithGlobalErrorHandling<TData, TVariables>(
  mutationAction: MutationFunction<TData, TVariables>,
  { successMessage, errorMessage, postToast }: IMutationErrorHandlingOptions<TData>,
) {
  return async (options?: MutationFunctionOptions<TData, TVariables>) => {
    try {
      const response = await mutationAction(options)

      let toastContent

      if (typeof successMessage === 'string') {
        toastContent = successMessage
      }

      if (typeof successMessage === 'function') {
        toastContent = successMessage(response)
      }

      if (toastContent) {
        postToast({
          type: 'success',
          content: toastContent,
        })
      }

      return response
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }

      let content
      if (typeof errorMessage === 'function') {
        content = errorMessage(error)
      } else if (typeof errorMessage === 'string') {
        content = errorMessage
      }

      cloudLogger.info(error.message, {
        userImpact: 'Toast posted',
        meta: { stack: error.stack },
      })

      postToast({
        type: 'alert',
        content: content || getErrorMessage(error),
      })

      throw error
    }
  }
}

interface IMutationProps<TData = unknown, TVariables = OperationVariables>
  extends MutationComponentOptions<TData, TVariables> {
  successMessage?: string
  errorMessage?: string
}

function Mutation<TData = unknown, TVariables = OperationVariables>(props: IMutationProps<TData, TVariables>) {
  const { children, successMessage, errorMessage, ...otherProps } = props

  const postToast = useToasts()

  return (
    <ApolloMutation<TData, TVariables>
      {
        ...(otherProps as any) // eslint-disable-line @typescript-eslint/no-explicit-any
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(mutationAction: any, mutationResult: any) => {
        return children(
          mutationActionWithGlobalErrorHandling(mutationAction, { successMessage, errorMessage, postToast }),
          mutationResult,
        )
      }}
    </ApolloMutation>
  )
}

export type { IMutationProps, MutationFunction, SuccessMessageCallback }
export { mutationActionWithGlobalErrorHandling, Mutation }
