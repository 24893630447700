import React, { useCallback } from 'react'
import { FontAwesomeIcon, Variables } from '@intellihr/ui-components'
import { Date } from './Date'
import type { IDate } from './Date'
import { StyledDateTimeInfoBlock } from './style'

/**
 * Given a date string created on the frontend that needs to be translated, formats it into user-facing
 * components to match our standard style returned by <Date>. This is useful when the frontend needs to have a date
 * inside of a i18n message.
 *
 * Usage:
 * const { convertFrontendDateString } = useFrontendDateStringFormatter()
 *
 * const text = convertFrontendDateString(t('messages.acceptedWithDate', { acceptedTermsDate: user.acceptedTermsDate }))
 *
 * Essentially, when given a string with an ISO date format inside of it, this component will convert it to use
 * a <Date> automatically with some regex.
 *
 * This supports all props from <Date> automatically, which can be passed as the second param to
 * convertFrontendDateString() OR to useFrontendDateStringFormatter() as needed.
 */
const useFrontendDateStringFormatter = (defaultDateValues?: Omit<Partial<IDate>, 'fallback'>) => {
  const convertFrontendDateString = useCallback(
    (dateString: string, dateValues?: Omit<Partial<IDate>, 'fallback'>) => {
      dateValues = { ...defaultDateValues, ...dateValues }

      const regexIsoDateString = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?(\+(\d{2}):(\d{2})|Z)/g

      dateString = dateString.replace(regexIsoDateString, (match) => {
        return `|${match}|`
      })

      const dateStringFormatted = dateString.split('|').map((item, index) => {
        if (item.match(regexIsoDateString)) {
          return (
            <Date
              key={index}
              date={item}
              {...dateValues}
            />
          )
        }
        return <React.Fragment key={index}>{item}</React.Fragment>
      })

      return <>{dateStringFormatted}</>
    },
    [defaultDateValues],
  )

  return { convertFrontendDateString }
}

/**
 * Given a date string returned from the backend that contains dates or format boxes, formats it into user-facing
 * components to match our standard style returned by <Date>. This is useful when the backend returns a string like
 * "This job ends on 2020-02-01 [UTC+10][YMD]", as it will convert this into the styled format boxes for you.
 *
 * It also supports date range string. For example: "01-01-2020 → 02-01-2020" or "01-01-2020 \u2192 02-01-2020"
 * and it converts the unicode right-arrow to Font Awsome Right Arrow sybmol
 *
 * Use cases: Notification subtitles, job callouts, status messages with i18n on the backend.
 *
 * This does NOT do any actual date conversion, but simply passes through the date string as given. Use this in
 * situations where the backend is already handling the rendering of dates and we just need to 'pretty' this up for the
 * frontend.
 */
const useBackendDateStringFormatter = () => {
  const convertBackendDateString = useCallback((dateString: string) => {
    const regexTimezone = /\[UTC(.*?)\]/g
    const regexDateFormat = /\[(DMY|YMD|MDY)\]/g
    const regexDMYOrMDYOnlyDate = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g
    const regexYMDOnlyDate = /[0-9]{4}-[0-9]{2}-[0-9]/g
    const regexVerboseOnlyDate = /[0-9]{1,2} (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ([0-9]{4})/g
    const regexArrow = /(\u2192)/g

    dateString = dateString.replace(regexDMYOrMDYOnlyDate, (match) => {
      return `|${match}|`
    })

    dateString = dateString.replace(regexYMDOnlyDate, (match) => {
      return `|${match}|`
    })

    dateString = dateString.replace(regexVerboseOnlyDate, (match) => {
      return `|${match}|`
    })

    dateString = dateString.replace(regexTimezone, (match) => {
      return `|${match}|`
    })

    dateString = dateString.replace(regexDateFormat, (match) => {
      return `|${match}|`
    })

    dateString = dateString.replace(regexArrow, (match) => {
      return `|${match}|`
    })

    const dateStringFormatted = dateString.split('|').map((item, index) => {
      if (item.match(regexDMYOrMDYOnlyDate) || item.match(regexYMDOnlyDate) || item.match(regexVerboseOnlyDate)) {
        return <time key={index}>{item}</time>
      }

      if (item.match(regexTimezone) || item.match(regexDateFormat)) {
        const trimmedFormatValue = item.replace('[', '').replace(']', '')
        return <StyledDateTimeInfoBlock key={index}>{trimmedFormatValue}</StyledDateTimeInfoBlock>
      }

      if (item.match(regexArrow)) {
        return (
          <FontAwesomeIcon
            icon={'arrow-right'}
            type={'regular'}
            margins={{ left: Variables.Spacing.s3XSmall, right: Variables.Spacing.s3XSmall }}
            key={index}
          />
        )
      }

      return <React.Fragment key={index}>{item}</React.Fragment>
    })
    return <>{dateStringFormatted}</>
  }, [])

  return { convertBackendDateString }
}

export { useBackendDateStringFormatter, useFrontendDateStringFormatter }
