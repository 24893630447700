import React, { useState, useCallback, useEffect } from 'react'
import { Variables, FontAwesomeIcon, Props, Text } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { StyledCompactCensor, StyledDiv } from './style'

const CompactCensor: React.FC<React.PropsWithChildren<{ onShowCensor?: (value: boolean) => void }>> = ({
  children,
  onShowCensor,
}) => {
  const tCommon = useScope('common:components')
  const tCommonValues = useScope('common:values')
  const [showChildrenTemporary, setShowChildrenTemporary] = useState(false)
  const [timeoutComplete, setTimeoutComplete] = useState(false)
  useEffect(() => {
    if (showChildrenTemporary) {
      const timeout = setTimeout(() => setTimeoutComplete(showChildrenTemporary), 300)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [showChildrenTemporary])
  const handleShowingChildrenTemporary = useCallback(() => {
    onShowCensor?.(true)
    setShowChildrenTemporary(true)
  }, [onShowCensor])

  return (
    <>
      <StyledCompactCensor>
        <StyledDiv
          showChildren={showChildrenTemporary}
          timeoutComplete={timeoutComplete}
          onClick={handleShowingChildrenTemporary}
        >
          <Text
            margins={{ right: Variables.Spacing.s2XSmall, left: Variables.Spacing.s2XSmall }}
            color={Variables.Color.n600}
            type={Props.TypographyType.Small}
          >
            <FontAwesomeIcon
              icon="eye"
              type={'solid'}
              margins={{ right: Variables.Spacing.sXSmall }}
              size="small"
              color={Variables.Color.n600}
            />
            {tCommon('CensorOverlay.contentShowTemporary')}
          </Text>
        </StyledDiv>
        {timeoutComplete && (
          <>{children ? children : <Text color={Variables.Color.n500}>{tCommonValues('notProvided')}</Text>}</>
        )}
      </StyledCompactCensor>
    </>
  )
}

export { CompactCensor }
