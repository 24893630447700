import { History, Location, createBrowserHistory } from 'history'
import { matchPath } from 'react-router-dom'
import { segmentPage } from 'src/services/segment/helper'
import { scrollToTop } from 'src/services/helpers'
import { urlForRoute } from 'src/services/routes'

let history: History
let previousLocation: Location

const scrollToTopRouteExceptions = [
  {
    // person profile
    routes: [
      urlForRoute('people.show'),
      urlForRoute('people.jobs'),
      urlForRoute('people.jobs.show'),
      urlForRoute('people.jobs.show.current'),
      urlForRoute('people.jobs.show.timeline'),
      urlForRoute('people.qualifications'),
      urlForRoute('people.trainings'),
      urlForRoute('people.trainings.show'),
      urlForRoute('people.feedback'),
      urlForRoute('people.managers_log'),
      urlForRoute('people.goals'),
      urlForRoute('people.goals.show'),
      urlForRoute('people.onboarding'),
      urlForRoute('people.skills'),
      urlForRoute('people.skills.create'),
      urlForRoute('people.skills.edit'),
      urlForRoute('people.skills.delete'),
      urlForRoute('people.contracts'),
      urlForRoute('people.documents'),
      urlForRoute('people.user'),
      urlForRoute('people.performance_report'),
    ],
    params: ['personId'],
  },
  {
    routes: [
      urlForRoute('settings.turnover_reasons.create'),
      urlForRoute('settings.turnover_reasons.edit'),
      urlForRoute('settings.turnover_reasons'),
    ],
    params: ['turnoverReasonId'],
  },
  {
    routes: [
      urlForRoute('settings.business_entities.create'),
      urlForRoute('settings.business_entities.edit'),
      urlForRoute('settings.business_entities.edit'),
    ],
    params: ['businessEntityId'],
  },
  {
    routes: [
      urlForRoute('settings.default_remuneration_components.create'),
      urlForRoute('settings.default_remuneration_components.edit'),
      urlForRoute('settings.default_remuneration_components'),
    ],
    params: ['defaultRemunerationComponentId'],
  },
  {
    routes: [
      urlForRoute('settings.position_titles.create'),
      urlForRoute('settings.position_titles.edit'),
      urlForRoute('settings.position_titles'),
      urlForRoute('settings.position_titles.merge'),
    ],
    params: ['positionTitleId'],
  },
  {
    routes: [
      urlForRoute('establishments.show'),
      urlForRoute('establishments.show.current'),
      urlForRoute('establishments.show.timeline'),
      urlForRoute('workforce_planning.roles.show'),
      urlForRoute('workforce_planning.roles.show.current'),
      urlForRoute('workforce_planning.establishments.show.timeline'),
    ],
    params: ['establishmentId'],
  },
]

const shouldScrollToTopBetweenRoutes = (pathname: string, previousPathName: string) => {
  return scrollToTopRouteExceptions.every(({ routes, params }) => {
    const matchCurrent = matchPath(pathname, {
      path: routes,
      exact: true,
    })

    const matchPast = matchPath(previousPathName, {
      path: routes,
      exact: true,
    })

    if (!matchCurrent || !matchPast) {
      return true
    }

    // Compare the params - if there are changed params in the list of specified ones, we
    // also need to scroll to top
    return params.some((param) => {
      const currentParam = matchCurrent.params[param]
      const pastParam = matchPast.params[param]
      return currentParam !== pastParam && !!pastParam && !!currentParam
    })
  })
}

const initializeHistory = () => {
  history = createBrowserHistory()
  previousLocation = history.location

  history.listen((location) => {
    segmentPage()
    const state = location.state as { resetScroll?: boolean } | undefined
    if (
      (state?.resetScroll ?? true) &&
      location.pathname !== previousLocation.pathname &&
      shouldScrollToTopBetweenRoutes(location.pathname, previousLocation.pathname)
    ) {
      scrollToTop()
    }

    previousLocation = location
  })
}

const getHistory = () => {
  if (!history) {
    initializeHistory()
  }

  return history
}

export { getHistory }
