import React from 'react'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { urlForLapisRoute } from 'src/services/routes'
import { Alert, Link, Text, TextStyle } from '@intellihr/blueberry'

const AssumedAccountAlert: React.FC = () => {
  const t = useScope('common:components.PageFrame')
  const { person } = useUserContext()

  if (person?.user?.isAssumedAccount) {
    const assumedPermissionGroups = person.user.userPermissionGroups

    return (
      <Alert
        tone="caution"
        testId="pageHeader-assumedAccountAlert"
      >
        <t.Trans
          i18nKey={'assumedPermissionGroupAlert'}
          values={{
            assumedPermissionGroups: assumedPermissionGroups.map((role) => role?.permissionGroup.name).join(', '),
            count: assumedPermissionGroups.length,
          }}
        >
          <Text>
            Translation Missing
            <TextStyle variant="strong">Translation Missing</TextStyle>
            <Link href={urlForLapisRoute('acls.exit_assumed_role')}>Translation Missing</Link>
          </Text>
        </t.Trans>
      </Alert>
    )
  }

  return null
}

const MemoAssumedAccountAlert = React.memo(AssumedAccountAlert)

export { MemoAssumedAccountAlert as AssumedAccountAlert }
