import { OnboardingStep } from './hooks/useGetOnboardingStatus'

export enum OnboardingStepType {
  PersonalDetails = 'f78e2582-a9b5-4447-8363-22dff06edeb2',
  SuperAPIDetails = 'a8ca0675-bc0a-4dee-99c1-19ac60fab185',
  RequiredQualifications = '28859c64-8912-43a6-b9cb-a9121c17135b',
  Completed = '00000000-0000-0000-0000-000000000000',
}

export interface IOnboardingStatus {
  isOnboarding: boolean
  currentStep?: OnboardingStep
  onboardingSteps: OnboardingStep[]
}
