import React, { useCallback } from 'react'
import { Text, Variables } from '@intellihr/ui-components'
import moment from 'moment'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import { graphqlDateFormatToMomentFormat, graphqlTimeFormatToMomentFormat, momentToUTCOffsetString } from './helper'
import { StyledDateTimeInfoBlock, StyledPreview } from './style'

interface IDate {
  date: string | null | undefined
  fallback?: string
  showTimezone?: boolean
  showTime?: boolean
  showFormatBox?: boolean
  subtractSecond?: boolean
  convertTimezone?: boolean
}

interface IPreviewDate {
  date: string
  timezone: string
  dateFormat: string
  timeFormat: string
}

/**
 * @see <Date>
 *
 * This is similar to the <Date> component, but the timezone, dateFormat and time format must be provided manually.
 * This should only be used in situations where these must be provided, such as in settings pages for these values.
 *
 * @param params.date The provided date info
 * @param params.timezone The timezone to use
 */
const PreviewDate: React.FC<IPreviewDate> = ({ date, timezone, dateFormat, timeFormat }) => {
  const dateValue = moment(date)
  const dateFormatValue = graphqlDateFormatToMomentFormat(dateFormat)
  const timeFormatValue = graphqlTimeFormatToMomentFormat(timeFormat)

  const previewText = dateValue.tz(timezone).format(`${dateFormatValue} ${timeFormatValue}`)

  return (
    <StyledPreview>
      <Text>{previewText}</Text>
      <StyledDateTimeInfoBlock>{momentToUTCOffsetString(dateValue)}</StyledDateTimeInfoBlock>
      {dateFormat !== 'VERBOSE' && <StyledDateTimeInfoBlock>{dateFormat}</StyledDateTimeInfoBlock>}
    </StyledPreview>
  )
}

/**
 * Given a iso date string, return a component with the date in the logged in user's timezone, with
 * format boxes for their timezone and date format.
 * This should be used in any instance where a date needs to be returned for a user, such as inside of a <Record>
 *
 * The date string can be provided in any format, but using ISO format from the backend is recommended.
 * If null or invalid dates are provided, the `fallback` value will be returned, defaulting to 'Not Provided'.
 *
 * @param params.date The provided date info
 * @param params.fallback The fallback string if the date is empty/null
 * @param params.showTimezone True by default. Whether the timezone should be shown for this date string. Timezones
 *                            should be shown in all instances unless there is a general disclaimer on a page.
 * @param params.showTime False by default. Shows the time for this datetime. Times should be shown in all instances
 *                        where the time info is relevant (for example, any dates where the time is not midnight)
 * @param params.showFormatBox True by default. Shows the DMY/MDY/YMD format box for this datetime. This must be
 *                             shown unless there is a TimezoneDisclaimer on the page to explain the format in use.
 * @param params.subtractSecond False by default. Subtracts a second from the value before converting. This is useful
 *                              for values like job end dates, where the user-facing value should be one second before
 *                              the exclusive end date.
 * @param params.convertTimezone True by default. Converts the given date to the user's configured timezone. Generally
 *                               you will always want to do this unless the date is something that is complete without
 *                               a timezone (for example Date of birth)
 */
const Date: React.FC<IDate> = ({
  date,
  fallback,
  showTime = false,
  showFormatBox = false,
  subtractSecond = false,
  convertTimezone = true,
}) => {
  const { dateFormat, timeFormat, timezone } = useUserContext()
  const tCommon = useScope('common:values')

  if (!date) {
    return <Text color={Variables.Color.n500}>{fallback ?? tCommon('notProvided')}</Text>
  }

  const dateFormatValue = graphqlDateFormatToMomentFormat(dateFormat)
  const timeFormatValue = graphqlTimeFormatToMomentFormat(timeFormat)

  let dateValue = moment(date)

  if (subtractSecond) {
    dateValue = dateValue.subtract(1, 'seconds')
  }

  if (convertTimezone) {
    dateValue = dateValue.tz(timezone)
  }

  const dateText = dateValue.format(`${dateFormatValue} ${showTime ? timeFormatValue : ''}`)

  return (
    <>
      <time dateTime={dateValue.toISOString()}>{dateText}</time>
      {showFormatBox && dateFormat !== 'VERBOSE' && <StyledDateTimeInfoBlock>{dateFormat}</StyledDateTimeInfoBlock>}
    </>
  )
}

/**
 * @see <Date>
 *
 * This method is identical to `<Date>`, except only returns a string instead of a component. This should only be used
 * in situations where a string is absolutely vital, such as when placed into a text input or passed to a third
 * party library.
 */
const useDateAsAString = () => {
  const { dateFormat, timeFormat, timezone } = useUserContext()
  const tCommon = useScope('common:values')

  const date = useCallback(
    ({
      date,
      fallback,
      showTime = false,
      showFormatBox = false,
      subtractSecond = false,
      convertTimezone = true,
    }: IDate): string => {
      if (!date) {
        return fallback ?? tCommon('notProvided')
      }

      const dateFormatValue = graphqlDateFormatToMomentFormat(dateFormat)
      const timeFormatValue = graphqlTimeFormatToMomentFormat(timeFormat)

      let dateValue = moment(date)

      if (subtractSecond) {
        dateValue = dateValue.subtract(1, 'seconds')
      }

      if (convertTimezone) {
        dateValue = dateValue.tz(timezone)
      }

      const dateText = dateValue.format(`${dateFormatValue} ${showTime ? timeFormatValue : ''}`)
      const formatBox = showFormatBox && dateFormat !== 'VERBOSE' ? `[${dateFormat}]` : ''

      return `${dateText}${formatBox}`
    },
    [dateFormat, timeFormat, timezone, tCommon],
  )

  return { date }
}

export { Date, PreviewDate, useDateAsAString }

export type { IDate }
