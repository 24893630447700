import gql from 'graphql-tag'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { GET_SUPPORTED_LANGUAGES } from 'src/domain/PlatformSetup/public/services/supportedLanguagesService'
import { useMutation } from 'src/services/apollo'
import { GET_PAGE_HEADER_ITEMS } from 'src/services/layout/components/PageFrame/components'
import { GET_NAVIGATION_ITEMS } from 'src/services/layout/components/PageFrame/components/Navigation/gql'
import { SESSION_DETAILS } from 'src/services/user/SessionDetailsProvider/hooks/useSessionProvider'
import type { UpdatePreferences, UpdatePreferencesVariables } from './__gql__/gqlCodeGen'

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($language: String, $timezone: Timezone, $dateFormat: DateFormat, $timeFormat: TimeFormat) {
    updateUserConfig(
      input: { language: $language, timezone: $timezone, dateFormat: $dateFormat, timeFormat: $timeFormat }
    ) {
      language
      timezone
      dateFormat
      timeFormat
    }
  }
`

const useUpdatePreferences = (successMessage: string) => {
  const userId = getSession().userId

  return useMutation<UpdatePreferences, UpdatePreferencesVariables>(UPDATE_PREFERENCES, {
    refetchQueries: [
      { query: SESSION_DETAILS, variables: { userId } },
      { query: GET_SUPPORTED_LANGUAGES },
      { query: GET_PAGE_HEADER_ITEMS },
      { query: GET_NAVIGATION_ITEMS },
    ],
    successMessage,
  })
}

export { useUpdatePreferences, UpdatePreferences, UpdatePreferencesVariables, UPDATE_PREFERENCES }
