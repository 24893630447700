import React, { ComponentType, useEffect, useState } from 'react'
import { IGoogleMapApiParams } from './declaration'
import { urlBuilder } from './lib'

declare global {
  /* prettier-ignore */
  interface Window { // eslint-disable-line @typescript-eslint/naming-convention
    googleMapsAutoCompleteApiLoad?: () => void
    google?: unknown
  }
}

interface IInjectedGoogleMapsApiProps {
  google: unknown
}

const GOOGLE_MAPS_JS_API_URL = 'https://maps.googleapis.com/maps/api/js'

const loadGoogleMapsApi = <P extends {}>(params: IGoogleMapApiParams) => {
  function injectGoogleMapsApi() {
    const script = document.createElement('script')

    script.type = 'text/javascript'

    script.src = urlBuilder({
      base: GOOGLE_MAPS_JS_API_URL,
      callback: 'googleMapsAutoCompleteApiLoad',
      ...params,
      libraries: params.libraries || [],
    })

    document.querySelector('head')!.appendChild(script)
  }

  return (Component: ComponentType<P & IInjectedGoogleMapsApiProps>) => {
    const GoogleMapApiLoader: React.FC<P> = (props) => {
      const [loaded, setLoaded] = useState(false)

      useEffect(() => {
        if (window.google) {
          setLoaded(true)
          return
        }

        window.googleMapsAutoCompleteApiLoad = () => {
          setLoaded(true)
        }

        injectGoogleMapsApi()

        setTimeout(() => {
          if (!window.google) {
            throw new Error('Failed to load Google Maps JavaScript API')
          }
        }, 10000)
      }, [])

      if (!loaded) {
        return <div data-cy="google-maps-api-spinner" />
      }

      return (
        <Component
          {...props}
          google={window.google}
        />
      )
    }
    return GoogleMapApiLoader
  }
}

export type { IInjectedGoogleMapsApiProps }
export { loadGoogleMapsApi }
