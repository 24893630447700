import React from 'react'
import { Stack } from '@intellihr/blueberry'
import { getCustomFieldsFormFields } from 'src/domain/EDM/public/services/customFieldValuesFormGeneratorService'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'
import { FormattedText, VerticalForm } from '@intellihr/ui-components'
import { OnboardingCustomField, OnboardingCustomFieldDefinition } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { CustomFieldCategoryLookup, Grid } from '../PersonalDetailsFormStepContent'
import { VisibilityOption } from '../../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'

interface IOnboardingCustomFieldsProps {
  onboardingCustomFields: OnboardingCustomField[]
  categoryLookup: CustomFieldCategoryLookup
}

const OnboardingCustomFields: React.FC<IOnboardingCustomFieldsProps> = ({ onboardingCustomFields, categoryLookup }) => {
  const customFieldSections: { [key: string]: OnboardingCustomFieldDefinition[] } = {}
  const mandatoryFieldOverrides: Parameters<typeof getCustomFieldsFormFields>[0]['mandatoryFieldOverrides'] = {}

  onboardingCustomFields
    .filter(
      (onboardingCustomField) =>
        onboardingCustomField.visibility.id !== VisibilityOption.HIDDEN &&
        onboardingCustomField.customFieldDefinition.modelType === CustomFieldDefinitionModelType.PERSON,
    )
    .forEach((onboardingCustomField) => {
      const { customFieldDefinition, visibility } = onboardingCustomField
      const { id } = customFieldDefinition.customFieldCategory

      mandatoryFieldOverrides[customFieldDefinition.id] = visibility.id === VisibilityOption.REQUIRED

      if (customFieldSections[id]) {
        customFieldSections[id].push(customFieldDefinition)
      } else {
        customFieldSections[id] = [customFieldDefinition]
      }
    })

  return (
    <Stack space="xLarge">
      {Object.entries(customFieldSections).map(([id, customFieldDefinitions]) => {
        return (
          <VerticalForm.Section
            key={id}
            name={categoryLookup[id]?.categoryName}
          >
            <Stack space={'medium'}>
              <div data-cy={`description-${id}`}>
                <FormattedText
                  allowedTypes="strict"
                  text={categoryLookup[id]?.description}
                />
              </div>
              <Grid>
                <OnboardingCustomFieldSection
                  customFieldDefinitions={customFieldDefinitions}
                  mandatoryFieldOverrides={mandatoryFieldOverrides}
                />
              </Grid>
            </Stack>
          </VerticalForm.Section>
        )
      })}
    </Stack>
  )
}

interface IOnboardingCustomFieldSectionProps {
  customFieldDefinitions: OnboardingCustomFieldDefinition[]
  mandatoryFieldOverrides: Parameters<typeof getCustomFieldsFormFields>[0]['mandatoryFieldOverrides']
}

const OnboardingCustomFieldSection: React.FC<IOnboardingCustomFieldSectionProps> = ({
  customFieldDefinitions,
  mandatoryFieldOverrides,
}) => {
  const customFields = getCustomFieldsFormFields({
    customFields: customFieldDefinitions,
    modelType: CustomFieldDefinitionModelType.PERSON,
    mandatoryFieldOverrides,
  })
  return <>{customFields}</>
}

export { OnboardingCustomFields }
