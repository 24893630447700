import { MutationHookOptions, MutationTuple, useMutation as useApolloMutation } from '@apollo/client'
import { useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { useToasts } from 'src/services/toasts'
import { mutationActionWithGlobalErrorHandling, SuccessMessageCallback } from './Mutation'

interface IUseMutationOptions<TData, TVariables> extends MutationHookOptions<TData, TVariables> {
  successMessage?: string | SuccessMessageCallback<TData>
  errorMessage?: string
}

function useMutation<TData = unknown, TVariables = unknown>(
  mutation: DocumentNode,
  options: IUseMutationOptions<TData, TVariables> = {},
): MutationTuple<TData, TVariables> {
  const { successMessage, errorMessage, ...apolloMutationOptions } = options

  const postToast = useToasts()
  const [mutationAction, mutationResult] = useApolloMutation<TData, TVariables>(mutation, apolloMutationOptions)

  const memoMutationAction = useMemo(
    () =>
      mutationActionWithGlobalErrorHandling<TData, TVariables>(mutationAction, {
        successMessage,
        errorMessage,
        postToast,
      }),
    [mutationAction, successMessage, errorMessage, postToast],
  )

  return [memoMutationAction, mutationResult]
}

export { IUseMutationOptions, useMutation }
