import { WorkflowEventTriggerTriggerType } from 'src/__gql__/globalTypes'
import { IValidSpaRoute } from '../routes'

type ISupportedRedirectChainRoute =
  | {
      route: 'workflow_events.schedule'
      mapping: {
        workflowEventId: string
      }
      params: {
        offsetDate: string
        jobId: string
        triggerId: string
        triggerType: WorkflowEventTriggerTriggerType
      }
    }
  | {
      route: 'people.jobs.reassign_direct_reports'
      mapping: {
        personId: string
        jobId: string
      }
      params: {
        effectiveFrom: string
        effectiveTo?: string
        showAllReports?: boolean
      }
    }
  | {
      route: 'people.jobs.add_mandatory_requirement'
      mapping: {
        personId: string
        jobId: string
      }
      params?: undefined
    }
  | {
      route: 'people.performance_improvement_plan.letter'
      mapping: {
        personId: string
        pipId: string
        diaryNoteId: string
      }
      params?: undefined
    }
  | {
      route: 'pulses.review_filters.locations'
      mapping?: undefined
      params: {
        locationId: string
        oldParentLocationId?: string
        newParentLocationId?: string
        referrer: 'create' | 'update'
      }
    }
  | {
      route: 'people.jobs.cancel_end_of_job'
      mapping: {
        personId: string
        jobId: string
      }
      params?: undefined
    }
  | {
      route: 'people.jobs.add_onboarding_session'
      mapping: {
        personId: string
        jobId: string
      }
      params?: undefined
    }

interface IRedirectChain {
  triggerName: string
  chain: ISupportedRedirectChainRoute[]
  completionRoute: {
    route: IValidSpaRoute
    mapping?: { [key: string]: string }
    params?: { [key: string]: string }
  }
}

interface IRedirectChainInput {
  triggerName: string
  chain: Array<ISupportedRedirectChainRoute | null | false>
  completionRoute: {
    route: IValidSpaRoute
    mapping?: { [key: string]: string }
    params?: { [key: string]: string }
  }
}

interface IRedirectChainUrlParams {
  redirectChainIndex: string
  redirectChainId: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidUrlParams = (params: any): params is IRedirectChainUrlParams => {
  return (
    !!params.redirectChainIndex &&
    !isNaN(Number(params.redirectChainIndex)) &&
    !!params.redirectChainId &&
    !isNaN(Number(params.redirectChainId))
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidRedirectChain = (params: any): params is IRedirectChain => {
  const validTriggerName = typeof params.triggerName === 'string'
  const validCompletionRoute = typeof params.completionRoute === 'object' && !!params.completionRoute.route

  const validChain =
    Array.isArray(params.chain) &&
    params.chain.length > 0 &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params.chain.every((chainItem: any) => {
      return !!chainItem.route
    })

  return validTriggerName && validCompletionRoute && validChain
}

export type { ISupportedRedirectChainRoute, IRedirectChain, IRedirectChainInput }
export { isValidRedirectChain, isValidUrlParams }
