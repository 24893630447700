import { SelectInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import React from 'react'
import { useQuery } from 'src/services/apollo'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { ListAllWorkRights } from './__gql__/WorkRightSelectInputCodeGen'

const LIST_ALL_WORK_RIGHTS = gql`
  query ListAllWorkRights($countryId: String!) {
    listAllWorkRights(filters: { countryId: { equals: $countryId } }) {
      id
      name
    }
  }
`

interface IWorkRightSelectInputProps extends ISelectInputProps {
  countryId?: string
}

const WorkRightSelectInput: React.FC<IWorkRightSelectInputProps> = (props) => {
  const tFeature = useScope('edm:features.PersonCreateForm')
  const { countryId, ...selectInputProps } = props

  const { data } = useQuery<ListAllWorkRights>(LIST_ALL_WORK_RIGHTS, {
    errorBehaviour: 'do-nothing',
    variables: { countryId },
    skip: !countryId,
  })

  const options =
    data?.listAllWorkRights.map((workRight) => ({
      label: workRight.name,
      value: workRight.id,
    })) ?? []

  return (
    <SelectInput
      {...selectInputProps}
      isFetching={!!countryId && !data?.listAllWorkRights}
      options={options}
      isDisabled={!countryId}
      placeholder={!countryId ? tFeature('placeholderSelectWorkRightCountry') : undefined}
    />
  )
}

export { LIST_ALL_WORK_RIGHTS, WorkRightSelectInput }
