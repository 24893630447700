import type { ITranslate } from 'src/services/i18n/LocalizationProvider'
import { PageKey, FocusKey, IDocument, DocumentType } from './types'

interface IPageConfig {
  key: PageKey
  primaryFocus: FocusKey
  focuses?: FocusKey[]
  isBeta?: boolean
  noDimensionFilterPermissionSupport?: boolean
  isNonStandardPage?: boolean
}

const pageConfigs: IPageConfig[] = [
  {
    key: PageKey.anonymousFormData,
    primaryFocus: FocusKey.play,
    isNonStandardPage: true,
  },
  {
    key: PageKey.attrition,
    primaryFocus: FocusKey.riskAndCompliance,
    focuses: [FocusKey.planningAndFinancialDrivers],
  },
  {
    key: PageKey.businessPerformance,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.diaryNotes,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.enps,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
  {
    key: PageKey.expectations,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.formData,
    primaryFocus: FocusKey.play,
  },
  {
    key: PageKey.goalCompliance,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.goalPerformance,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.happiness,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
  {
    key: PageKey.headcount,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.mobility,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
    noDimensionFilterPermissionSupport: true,
  },
  {
    key: PageKey.performanceManagement,
    primaryFocus: FocusKey.riskAndCompliance,
    focuses: [FocusKey.performanceAndProductivity],
  },
  {
    key: PageKey.recruitmentSpend,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.remuneration,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.remunerationSpend,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.safetyConcerns,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.sentimentDiaryNote,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
  {
    key: PageKey.sentimentForm,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
  {
    key: PageKey.sentimentGoal,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.sentimentOverview,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
  {
    key: PageKey.sentimentPerformanceManagement,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.skills,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.spanOfControl,
    primaryFocus: FocusKey.cultureAndCommunity,
    focuses: [FocusKey.planningAndFinancialDrivers],
    noDimensionFilterPermissionSupport: true,
  },
  {
    key: PageKey.startersLeavers,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.survivalAnalysis,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.talentPlanning,
    primaryFocus: FocusKey.performanceAndProductivity,
    focuses: [FocusKey.planningAndFinancialDrivers],
    isBeta: true,
  },
  {
    key: PageKey.taskCompletion,
    primaryFocus: FocusKey.riskAndCompliance,
  },
  {
    key: PageKey.tenure,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
    focuses: [FocusKey.cultureAndCommunity],
  },
  {
    key: PageKey.trainingInvestment,
    primaryFocus: FocusKey.planningAndFinancialDrivers,
  },
  {
    key: PageKey.trainingNeeds,
    primaryFocus: FocusKey.performanceAndProductivity,
  },
  {
    key: PageKey.wellness,
    primaryFocus: FocusKey.cultureAndCommunity,
  },
]

const nonStandardPageKeys = pageConfigs.reduce((acc, { key, isNonStandardPage = false }) => {
  if (isNonStandardPage) {
    acc.add(key)
  }

  return acc
}, new Set<string>())

class Focus {
  private static _all: Record<FocusKey, Focus> | undefined

  public readonly key: FocusKey

  public readonly title: string

  public readonly description: string

  public readonly formDesignIds: string[]

  private readonly _pageKeys: PageKey[]

  public get pages(): Page[] {
    const pages = Page.all()

    return this._pageKeys.reduce((acc, pageKey) => {
      const page = pages[pageKey]

      if (!page) {
        return acc
      }

      return [...acc, page]
    }, [])
  }

  constructor(key: FocusKey, title: string, description: string, pages: PageKey[], formDesignIds: string[]) {
    this.key = key
    this.title = title
    this.description = description
    this._pageKeys = pages
    this.formDesignIds = formDesignIds
  }

  public static init(analyticsT: ITranslate): void {
    Focus._all = Object.values(FocusKey).reduce<Record<FocusKey, Focus>>(
      (acc, key) => ({
        ...acc,
        [key]: new Focus(
          key,
          analyticsT(`focuses.${key}.title`),
          analyticsT(`focuses.${key}.description`),
          pageConfigs
            .filter(({ primaryFocus, focuses: _focuses = [] }) => primaryFocus === key || _focuses.includes(key))
            .map(({ key: pageKey }) => pageKey),
          [],
        ),
      }),
      {} as Record<FocusKey, Focus>,
    )
  }

  public static all(): Record<FocusKey, Focus> {
    if (!Focus._all) {
      throw new Error('Analytics Focus not initialised')
    }
    return Focus._all
  }
}

interface IPageContent {
  title: string
  description: Record<FocusKey, string>
  keywords: Record<FocusKey, string[]>
}

class Page {
  public readonly key: PageKey

  public readonly noDimensionFilterPermissionSupport: boolean

  public readonly isBeta: boolean

  public readonly isNonStandardPage: boolean

  private readonly content: IPageContent

  private static _all: Record<PageKey, Page> | undefined

  private readonly _primaryFocusKey: FocusKey

  private readonly _focusKeys: FocusKey[]

  public get primaryFocus(): Focus {
    const focuses = Focus.all()

    return focuses[this._primaryFocusKey]!
  }

  public get focuses(): Focus[] {
    const focuses = Focus.all()

    return this._focusKeys.reduce((acc, focusKey) => {
      const focus = focuses[focusKey]

      if (!focus) {
        return acc
      }

      return [...acc, focus]
    }, [])
  }

  constructor(
    key: PageKey,
    primaryFocus: FocusKey,
    pageFocuses: FocusKey[],
    content: IPageContent,
    noDimensionFilterPermissionSupport: boolean,
    isBeta: boolean,
    isNonStandardPage: boolean,
  ) {
    this.key = key
    this._primaryFocusKey = primaryFocus
    this._focusKeys = pageFocuses
    this.content = content

    this.noDimensionFilterPermissionSupport = noDimensionFilterPermissionSupport
    this.isBeta = isBeta
    this.isNonStandardPage = isNonStandardPage
  }

  public get title() {
    return this.content.title
  }

  public getDescription(focusKey: FocusKey) {
    return this.content.description[focusKey]
  }

  public getKeywords = (focusKey: FocusKey): string[] => {
    return this.content.keywords[focusKey]
  }

  public hasFocus = (focusKey: FocusKey): boolean =>
    this._primaryFocusKey === focusKey || this._focusKeys.some((_focusKey) => _focusKey === focusKey)

  public toDocument(focus: Focus): IDocument {
    return {
      key: {
        tile: this.key,
        category: focus.key,
        type: DocumentType.Page,
      },
      title: this.title,
      categoryTitle: focus.title,
      keywords: this.getKeywords(focus.key),
    }
  }

  private static buildPageContent = (analyticsT: ITranslate, pageKey: PageKey, focusKeys: FocusKey[]): IPageContent => {
    const description = focusKeys.reduce((acc, focusKey) => {
      acc[focusKey] = analyticsT([`pages.${pageKey}.description.${focusKey}`, `pages.${pageKey}.description`], {
        defaultValue: '',
      })
      return acc
    }, {} as Record<FocusKey, string>)

    const keywords = focusKeys.reduce((acc, focusKey) => {
      acc[focusKey] = analyticsT(`pages.${pageKey}.keywords.${focusKey}`, { defaultValue: '' })
        .split(',')
        .reduce((result: string[], keyword) => {
          keyword = keyword.trim()
          if (keyword.length > 0) {
            result.push(keyword)
          }
          return result
        }, [])
      return acc
    }, {} as Record<FocusKey, string[]>)

    return {
      title: analyticsT(`pages.${pageKey}.title`),
      description,
      keywords,
    }
  }

  public static init(analyticsT: ITranslate): void {
    Page._all = pageConfigs.reduce<Record<PageKey, Page>>(
      (
        idx,
        {
          key,
          primaryFocus,
          focuses: pageFocuses = [],
          noDimensionFilterPermissionSupport = false,
          isBeta = false,
          isNonStandardPage = false,
        },
      ) => ({
        ...idx,
        [key]: new Page(
          key,
          primaryFocus,
          [primaryFocus, ...pageFocuses],
          Page.buildPageContent(analyticsT, key, [primaryFocus, ...pageFocuses]),
          noDimensionFilterPermissionSupport,
          isBeta,
          isNonStandardPage,
        ),
      }),
      {} as Record<PageKey, Page>,
    )
  }

  public static all(): Record<PageKey, Page> {
    if (!Page._all) {
      throw new Error('Analytics Page not initialised')
    }

    return Page._all
  }
}

export { nonStandardPageKeys, Focus, Page }
