import { useContext } from 'react'
import { TenantContext } from 'src/services/user/TenantContext'

const useTenantContext = () => {
  const tenantDetails = useContext(TenantContext)

  return tenantDetails
}

export { useTenantContext }
