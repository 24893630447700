import { Avatar, FontAwesomeIcon, GridLayout, Props, Text, UnstyledLink, Variables } from '@intellihr/ui-components'
import { FontAwesomeIconValue } from '@intellihr/ui-components/types/domain/Icons'
import React, { useEffect, useState } from 'react'
import { IAvatarProps } from '@intellihr/ui-components/types/domain/Avatars'
import { DisplayName, LinkContainer } from './style'

interface IPersonTagProps {
  hovered?: boolean
  inactive?: boolean
  person: {
    id: string
    displayName?: string | null
    initials: string
    profilePictureUrl?: string | null
  }
  href: string
  positionTitle?: string
  avatarSize?: Props.AvatarSize
  avatarIcon?: FontAwesomeIconValue
  openInNewTab: boolean
  statusDot?: IAvatarProps['statusDot']
}

const PersonTag: React.FC<IPersonTagProps> = ({
  hovered: injectedHovered = null,
  inactive,
  person,
  href,
  positionTitle,
  avatarSize = Props.AvatarSize.Medium,
  statusDot,
  avatarIcon,
  openInNewTab,
}) => {
  const [hovered, setHovered] = useState(injectedHovered ?? false)

  useEffect(() => {
    if (injectedHovered !== null) {
      setHovered(injectedHovered)
    }
  }, [injectedHovered])

  return (
    <LinkContainer
      inactive={inactive}
      onMouseEnter={() => {
        if (injectedHovered === null) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (injectedHovered === null) {
          setHovered(false)
        }
      }}
    >
      <UnstyledLink
        href={href}
        openInNewTab={openInNewTab}
      >
        <GridLayout
          verticalAlignment={GridLayout.VerticalAlignment.Middle}
          gutterMarginX={Variables.Spacing.sXSmall}
        >
          <GridLayout.Cell size="shrink">
            <Avatar
              initials={person.initials}
              imageUrl={person.profilePictureUrl ?? undefined}
              size={avatarSize}
              statusDot={statusDot}
              statusIcon={
                avatarIcon && (
                  <FontAwesomeIcon
                    type="solid"
                    icon={avatarIcon}
                    size="small"
                  />
                )
              }
            />
          </GridLayout.Cell>
          <GridLayout.Cell>
            <DisplayName>
              <Text
                color={hovered ? Variables.Color.i400 : Variables.Color.n700}
                tag="p"
                type={avatarSize === Props.AvatarSize.Small ? Props.TypographyType.Small : Props.TypographyType.Body}
                margins={{ top: 0, right: 0, bottom: 0, left: 0 }}
                isTruncated
              >
                {person.displayName}
              </Text>
              {positionTitle && (
                <Text
                  color={hovered ? Variables.Color.i400 : Variables.Color.n600}
                  type={
                    avatarSize === Props.AvatarSize.Small ? Props.TypographyType.XSmall : Props.TypographyType.Small
                  }
                  tag="p"
                  margins={{ top: 2, right: 0, bottom: 0, left: 0 }}
                  isTruncated
                >
                  {positionTitle}
                </Text>
              )}
            </DisplayName>
          </GridLayout.Cell>
        </GridLayout>
      </UnstyledLink>
    </LinkContainer>
  )
}

export { PersonTag }
