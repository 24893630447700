import { SelectInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'src/services/apollo'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'

const LIST_ALL_GENDERS = gql`
  query ListAllGenders {
    listAllGenders(filters: { isEnabled: { equals: true } }) {
      id
      name
    }
  }
`

interface IListAllGendersResponse {
  listAllGenders?: Array<{
    id: string
    name: string
  }>
}

const GenderSelectInput: React.FC<ISelectInputProps> = (props) => {
  return (
    <Query<IListAllGendersResponse, never>
      errorBehaviour="do-nothing"
      query={LIST_ALL_GENDERS}
    >
      {({ data }) => {
        const options =
          data && data.listAllGenders
            ? data.listAllGenders.map((gender) => ({
                label: gender.name,
                value: gender.id,
              }))
            : []

        return (
          <SelectInput
            {...props}
            isFetching={!data?.listAllGenders}
            componentContext="gender-select-input"
            options={options}
          />
        )
      }}
    </Query>
  )
}

export { LIST_ALL_GENDERS, GenderSelectInput }
