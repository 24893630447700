/* eslint-disable @typescript-eslint/no-explicit-any */

import { isEqual } from 'lodash'
import { Store, compose, createStore, AnyAction } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux-immutable'
import { responsiveStoreEnhancer, IBrowser } from 'redux-responsive'
import { Map } from 'immutable'
import { browserReducer, IBrowserBreakpoints } from 'src/services/reducers/browser/reducer'
import { SessionStateLoader } from 'src/services/store/sessionStateLoader'
import { toastReducer } from 'src/services/toasts'
import { TReduxFormStore, IFormStateMap } from '../reduxForm/interface'
import { TReduxToastStore } from '../toasts/services/reducer'

interface IReduxStoreRaw<TFormStateMap extends IFormStateMap = IFormStateMap> {
  form: TReduxFormStore<TFormStateMap>
  toastReducer: TReduxToastStore
  browser: IBrowser<IBrowserBreakpoints>
}

interface ITypedMap<T extends Record<string, any>> extends Map<keyof T, T> {
  toJS(): T
  get<K extends keyof T>(key: K, notSetValue?: T[K]): T[K]
  set<K extends keyof T>(key: K, value: T[K]): this
  equals<TItem extends Record<string, any>>(other: ITypedMap<TItem>): boolean
}

type TReduxStore<
  TFormData extends Record<string, any> = Record<string, any>,
  TFormStateMap extends IFormStateMap<TFormData> = IFormStateMap<TFormData>,
> = ITypedMap<IReduxStoreRaw<TFormStateMap>>

let store: Store<TReduxStore, AnyAction>
let sessionState: object

const reducer = combineReducers<TReduxStore>({
  form: formReducer,
  toastReducer,
  browser: browserReducer,
})

const initializeStore = () => {
  const stateLoader = new SessionStateLoader()

  store = createStore<TReduxStore, AnyAction, unknown, unknown>(
    reducer,
    stateLoader.loadState(),
    compose(
      responsiveStoreEnhancer,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        : (f: any): any => f,
    ),
  )

  store.subscribe(() => {
    const previousValue = sessionState
    sessionState = store.getState().getIn(['toastReducer'])

    if (!isEqual(previousValue, sessionState)) {
      stateLoader.saveState(sessionState)
    }
  })

  return store
}

const getStore = () => {
  if (!store) {
    initializeStore()
  }

  return store
}

export type RootState = ReturnType<typeof store.getState>

export type { TReduxStore, ITypedMap }
export { getStore, reducer, initializeStore }
