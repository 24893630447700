import React, { useCallback } from 'react'
import { OperationVariables, ApolloError } from '@apollo/client'
import { apolloErrorToGraphiteError } from 'src/services/graphQL/helpers'
import { cloudLogger } from 'src/services/error'
import { Query as ApolloQuery, QueryComponentOptions } from '@apollo/client/react/components'

interface IQueryProps<TData, TVariables extends OperationVariables> extends QueryComponentOptions<TData, TVariables> {
  /**
   * Behaviour on errors returned from graphql.
   *  - throw-exception: Throws any errors when a error is encountered. This will result in an error boundary.
   *  - do-nothing: Don't do any special handling on errors. Use for queries which shouldn't break the page when failed.
   */
  errorBehaviour?: 'throw-exception' | 'do-nothing'
}

function Query<TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  props: IQueryProps<TData, TVariables>,
) {
  const { children, errorBehaviour, onError, ...queryProps } = props

  const onErrorCallback = useCallback(
    (error: ApolloError) => {
      if (errorBehaviour === 'do-nothing') {
        cloudLogger.info(error, {
          userImpact: 'Threw exception without displaying error boundary',
          meta: { type: 'graphql' },
        })
      }
      onError?.(error)
    },
    [onError, errorBehaviour],
  )

  return (
    <ApolloQuery<TData, OperationVariables>
      {
        ...(queryProps as any) // eslint-disable-line @typescript-eslint/no-explicit-any
      }
      onError={onErrorCallback}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(params: any) => {
        if (errorBehaviour === 'throw-exception' && params.error) {
          throw apolloErrorToGraphiteError(params)
        }

        return children(params)
      }}
    </ApolloQuery>
  )
}

Query.defaultProps = {
  skip: false,
  fetchPolicy: 'cache-and-network',
}

export { Query }
