import React, { Dispatch, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormAction, WrappedFieldArrayProps, submit } from 'redux-form'
import type { IDataTableColumnProps } from '@intellihr/ui-components/types/domain/Tables/DataTable/DataTable'
import { DataTable, FormattedText, VerticalForm } from '@intellihr/ui-components'
import { Button, ButtonIcon, DropdownMenu, IconEllipsis, Inline, Modal, Stack } from '@intellihr/blueberry'
import { IFormChildProps, ReduxForm, ReduxFormChange, useFormErrors } from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { getCustomFieldValueBricks } from 'src/domain/EDM/public/services/customFieldValuesPresentatonGeneratorService'
import {
  CustomFieldDefinition,
  CustomFieldValue,
  ICustomFieldDefinition,
  ValueUnionMulti,
  ValueUnionNumber,
  ValueUnionPeopleDropdown,
  ValueUnionSingle,
  ValueUnionText,
  isPeopleDropdownType,
  isSelectType,
  isTextTypeWithRegex,
} from 'src/domain/EDM/public/services/customFieldsTypeGuardService'
import { CustomFieldType } from 'src/__gql__/globalTypes'
import { CustomFieldDefinitionFragment } from 'src/domain/EDM/public/services/customFieldsGQLService/__gql__/indexCodeGen'
import { ICustomFieldValue, IOnboardingField } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { fieldToDisplayCell, fieldTypeIdToApiName } from '../PersonalDetailsFormStepContent'

type FormMode = 'CREATE' | 'EDIT'

interface IOnboardingFieldArrayProps {
  existingValues?: Array<{ customFieldValues: ICustomFieldValue[] }>
  sectionFields: IOnboardingField[]
  sectionId: string
  sectionName: string
  sectionDescription?: string
  sectionFieldInput: React.FC<{
    fields: IOnboardingField[]
    values?: { [key: string]: string }
    reduxFormChange: ReduxFormChange
  }>
  sectionCustomFields: JSX.Element[]
  reduxFormChange: ReduxFormChange
}

const OnboardingFieldArray: React.FC<IOnboardingFieldArrayProps & WrappedFieldArrayProps<unknown>> = ({
  fields,
  sectionFields,
  sectionId,
  sectionName,
  sectionFieldInput,
  sectionCustomFields,
  reduxFormChange,
  existingValues,
  sectionDescription,
}) => {
  const tCommon = useScope('common:components')
  const tFeature = useScope('edm:features.Onboarding')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formMode, setFormMode] = useState<FormMode>('CREATE')
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const existingValuesCount = existingValues?.length ?? 0

  const formName = `Onboarding${sectionName}Form`

  const dispatch = useDispatch<Dispatch<FormAction>>()
  const submitForm = () => dispatch(submit(formName))
  const errors = useFormErrors(formName)

  const onSubmit = (formData: unknown) => {
    if (Object.keys(errors).length === 0) {
      if (formMode === 'CREATE') {
        fields.push(formData)
      }
      if (formMode === 'EDIT') {
        reduxFormChange(`${sectionName}.${activeItemIndex}`, formData)
      }

      setIsModalOpen(false)
    }
  }

  const observedFields = new Set<string>()

  const columns: Array<IDataTableColumnProps<{}>> = sectionFields.map((sectionField) => {
    const fieldName = fieldTypeIdToApiName[sectionField.fieldType.id]
    const cell = fieldToDisplayCell[sectionField.fieldType.id]
    observedFields.add(fieldName)
    return {
      accessor: fieldName,
      Header: sectionField.fieldType.name,
      Cell: ({ value }) => {
        if (cell) {
          return cell(value)
        }

        return value
      },
    }
  })

  if (sectionCustomFields.length > 0) {
    columns.push({
      accessor: 'customFields',
      Header: tFeature('additionalDetailsColumnHeader'),
      Cell: ({ value }) => {
        if (value) {
          if (Array.isArray(value)) {
            const bricks = getCustomFieldValueBricks(value)
            return <Inline>{bricks}</Inline>
          }

          const customFieldValues: Array<CustomFieldValue & CustomFieldDefinition> = Object.entries(value).flatMap(
            ([key, value]) => {
              const customField = sectionCustomFields.find((cf) => {
                return cf.props.customField.apiName === key
              })?.props.customField as ICustomFieldDefinition

              if (customField) {
                let valueUnion

                if (customField.type === 'MULTI_SELECT') {
                  if (isSelectType(customField.definition)) {
                    valueUnion = {
                      ids: value as string[],
                      labels: customField.definition.options
                        .filter((option) => (value as string[] | null)?.includes(option.id))
                        .map((option) => option.label),
                    } as ValueUnionMulti
                  }
                }

                if (customField.type === 'SINGLE_SELECT') {
                  if (isSelectType(customField.definition)) {
                    valueUnion = {
                      id: value as string | null,
                      label: customField.definition.options.find((option) => {
                        return option.id === value
                      })?.label,
                    } as ValueUnionSingle
                  }
                }

                if (customField.type === 'PEOPLE_DROPDOWN') {
                  if (isPeopleDropdownType(customField.definition)) {
                    valueUnion = { options: value } as ValueUnionPeopleDropdown
                  }
                }

                if (customField.type === 'NUMBER') {
                  valueUnion = {
                    number: value,
                  } as ValueUnionNumber
                }

                if (customField.type === 'TEXT') {
                  if (isTextTypeWithRegex(customField.definition)) {
                    valueUnion = {
                      text: value,
                    } as ValueUnionText
                  }
                }

                return {
                  apiName: key,
                  definition: customField as unknown as CustomFieldDefinitionFragment,
                  modelId: '',
                  type: customField.type as CustomFieldType,
                  valueUnion,
                }
              }
              return []
            },
          )

          const bricks = getCustomFieldValueBricks(customFieldValues)
          return <Inline>{bricks}</Inline>
        }
        return null
      },
    })
  }

  columns.push({
    accessor: 'existing',
    Header: '',
    columnWidth: 'narrow',
    Cell: ({ row, value }) => {
      const fieldIndex = row.index - existingValuesCount
      if (value === undefined) {
        return (
          <>
            <DropdownMenu
              testId={`${sectionName}-dropdown-menu-${row.index}`}
              items={[
                {
                  label: tCommon('buttons.edit'),
                  onClick: () => {
                    setFormMode('EDIT')
                    setActiveItemIndex(fieldIndex)
                    setIsModalOpen(true)
                  },
                  type: 'button',
                },
                {
                  label: tCommon('buttons.delete'),
                  variant: 'destructive',
                  type: 'button',
                  onClick: () => {
                    fields.remove(fieldIndex)
                  },
                },
              ]}
              trigger={
                <ButtonIcon
                  label={tCommon('buttons.options')}
                  icon={IconEllipsis}
                />
              }
            />
          </>
        )
      }
      return null
    },
  })

  const itemName = sectionFields[0].fieldType.name
  const allFields = fields.getAll()
  const data = [...(existingValues ? existingValues : []), ...((allFields as Array<{}>) ?? [])]

  return (
    <>
      <Stack space="xxSmall">
        <Stack space="medium">
          <div data-cy={`description-${sectionId}`}>
            <FormattedText
              allowedTypes="strict"
              text={sectionDescription ?? ''}
            />
          </div>
          <DataTable
            columns={columns}
            data={data}
          />
        </Stack>
        <Modal
          title={
            formMode === 'CREATE' ? tFeature('modalHeaderAdd', { itemName }) : tFeature('modalHeaderEdit', { itemName })
          }
          subtitle="Profile Details"
          closable
          open={isModalOpen}
          onOpenChange={setIsModalOpen}
          primaryButton={
            <Button
              type="submit"
              onClick={() => {
                submitForm()
                if (Object.keys(errors).length === 0) {
                  setIsModalOpen(false)
                }
              }}
            >
              {formMode === 'CREATE' ? tCommon('buttons.add') : tCommon('buttons.save')}
            </Button>
          }
          testId="default"
          trigger={
            <Button
              onClick={() => {
                setFormMode('CREATE')
                setIsModalOpen(true)
              }}
              variant="secondary"
            >
              {tFeature('modalHeaderAdd', { itemName })}
            </Button>
          }
          variant="form"
        >
          <>
            <ReduxForm
              form={formName}
              observedFields={observedFields}
              formComponent={VerticalForm}
              initialValues={formMode === 'EDIT' ? (fields.get(activeItemIndex) as {}) : undefined}
              onSubmit={onSubmit}
            >
              {({ observedFieldValues, reduxFormChange }: IFormChildProps<{ [key: string]: string }>) => {
                return (
                  <>
                    {sectionFieldInput({ fields: sectionFields, values: observedFieldValues, reduxFormChange })}
                    {sectionCustomFields}
                  </>
                )
              }}
            </ReduxForm>
          </>
        </Modal>
        <div style={{ marginBottom: '20px' }}></div>
      </Stack>
    </>
  )
}

export { OnboardingFieldArray }
export type { IOnboardingFieldArrayProps }
