enum FocusKey {
  cultureAndCommunity = 'cultureAndCommunity',
  performanceAndProductivity = 'performanceAndProductivity',
  planningAndFinancialDrivers = 'planningAndFinancialDrivers',
  play = 'play',
  riskAndCompliance = 'riskAndCompliance',
}

enum PageKey {
  anonymousFormData = 'anonymousFormData',
  attrition = 'attrition',
  businessPerformance = 'businessPerformance',
  diaryNotes = 'diaryNotes',
  enps = 'enps',
  expectations = 'expectations',
  formData = 'formData',
  goalCompliance = 'goalCompliance',
  goalPerformance = 'goalPerformance',
  happiness = 'happiness',
  headcount = 'headcount',
  mobility = 'mobility',
  performanceManagement = 'performanceManagement',
  recruitmentSpend = 'recruitmentSpend',
  remuneration = 'remuneration',
  remunerationSpend = 'remunerationSpend',
  safetyConcerns = 'safetyConcerns',
  sentimentDiaryNote = 'sentimentDiaryNote',
  sentimentForm = 'sentimentForm',
  sentimentGoal = 'sentimentGoal',
  sentimentOverview = 'sentimentOverview',
  sentimentPerformanceManagement = 'sentimentPerformanceManagement',
  skills = 'skills',
  spanOfControl = 'spanOfControl',
  startersLeavers = 'startersLeavers',
  survivalAnalysis = 'survivalAnalysis',
  taskCompletion = 'taskCompletion',
  tenure = 'tenure',
  trainingInvestment = 'trainingInvestment',
  trainingNeeds = 'trainingNeeds',
  wellness = 'wellness',
  talentPlanning = 'talentPlanning',
}

enum DocumentType {
  Page = 'Analytics Page',
  Form = 'Form-based Analytics',
}

interface IBaseDocument {
  key: {
    category: string
    tile: string
    type: DocumentType
  }
  title: string
  categoryTitle: string
  keywords: string[]
}

interface IPageDocument extends IBaseDocument {
  key: {
    type: DocumentType.Page
    category: FocusKey
    tile: PageKey
  }
}

interface IFormDocument extends IBaseDocument {
  key: {
    type: DocumentType.Form
    category: string
    tile: string
  }
}

type IDocument = IPageDocument | IFormDocument

export type { IDocument, IPageDocument, IFormDocument }
export { DocumentType, FocusKey, PageKey }
