import styled, { css } from 'styled-components'
import { Variables } from '@intellihr/ui-components'

const StyledControlWrapper = styled.div`
  position: sticky;
  z-index: 10;
  width: 100%;
  background-color: white;
  height: 48px;
  top: 0;
`

const StyledControlComponent = styled.div<{
  isSet: boolean
  isFocused?: boolean
}>`
  align-content: center;
  transition: background-color 0.25s ease-out;
  background-color: ${Variables.Color.n250};
  border-radius: 90px;
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  margin-left: ${Variables.Spacing.sSmall}px;
  margin-top: ${Variables.Spacing.sSmall}px;
  padding: ${Variables.Spacing.s2XSmall}px ${Variables.Spacing.sXSmall}px ${Variables.Spacing.s2XSmall}px
    ${Variables.Spacing.sXSmall}px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${Variables.Color.n300};
    `}

  ${({ isSet }) =>
    isSet
      ? css`
          background-color: ${Variables.Color.i200};
        `
      : css`
          &:hover {
            background-color: ${Variables.Color.n300};
          }
        `}
`

const StyledBusinessUnitWrapper = styled.div<{ selectable: boolean; isFocused?: boolean }>`
  border-top: 1px solid ${Variables.Color.n200};
  padding: ${Variables.Spacing.sSmall}px ${Variables.Spacing.sXSmall}px ${Variables.Spacing.sXSmall}px
    ${Variables.Spacing.sXSmall}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ selectable, isFocused }) =>
    selectable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${Variables.Color.n200};
      }

      ${isFocused &&
      css`
        background-color: ${Variables.Color.n200};
      `}
    `}
`

const PersonTextWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: ${Variables.Spacing.sSmall}px;
  overflow: hidden;
`

const PersonTextElement = styled.div`
  line-height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PersonTextSubtitle = styled.div`
  flex-wrap: wrap;
  line-height: 1;
`

const StyledPersonWrapper = styled.div<{ isFocused?: boolean }>`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  padding: ${Variables.Spacing.sXSmall}px;
  vertical-align: middle;

  &:hover {
    background-color: ${Variables.Color.n200};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${Variables.Color.n200};
    `}
`

const PreventShrinkWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
`

export {
  PreventShrinkWrapper,
  PersonTextElement,
  PersonTextSubtitle,
  StyledPersonWrapper,
  PersonTextWrapper,
  StyledBusinessUnitWrapper,
  StyledControlWrapper,
  StyledControlComponent,
}
