import i18next from 'i18next'
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment'
import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, I18nextProvider } from 'react-i18next'
import { cloudLogger } from 'src/services/error'
import Backend from 'i18next-http-backend'
import uuid from 'uuid'
import get from 'lodash/get'
import AnalyticsCommon from 'src/services/i18n/defaults/analyticsCommon.yml'
import { isEmpty } from 'lodash'
import Common from './defaults/common.yml'
import Compliance from './defaults/compliance.yml'
import Edm from './defaults/edm.yml'
import Integrations from './defaults/integrations.yml'
import Lpa from './defaults/lpa.yml'
import Performance from './defaults/performance.yml'
import PlatformSetup from './defaults/platformSetup.yml'
import Qualifications from './defaults/qualifications.yml'
import Settings from './defaults/settings.yml'
import { useTenantContext } from '../user/TenantContext/helpers/hook'
import { InitialLoadBrandSpinner } from '../layout/components/InitialLoadBrandSpinner'

const DEFAULTS = {
  analyticsCommon: AnalyticsCommon,
  common: Common,
  compliance: Compliance,
  edm: Edm,
  integrations: Integrations,
  lpa: Lpa,
  performance: Performance,
  platformSetup: PlatformSetup,
  qualifications: Qualifications,
  settings: Settings,
}

const COMMON_NAMESPACES = ['analyticsCommon', 'common']

const NAMESPACES = [
  'compliance',
  'edm',
  'integrations',
  'lpa',
  'performance',
  'platformSetup',
  'qualifications',
  'settings',
]

interface ITranslate {
  (key: string | string[], opts?: i18next.TOptions): string
  withScope: IWithScope
  Trans: React.FC<React.PropsWithChildren<Parameters<typeof Trans>[0]>>
  exists: i18next.ExistsFunction
}

interface IOptions {
  dontErrorOnAccentConnectionFailure?: boolean
}

type IWithScope = (context: string, options?: IOptions) => ITranslate

type IT = (key: string | string[], opts?: i18next.TOptions) => string

interface ILocalizationContext {
  i18n: typeof i18next
  t: (key: string | string[], opts?: i18next.TOptions) => string
  withScope: IWithScope
  setOnLanguageLoadedCallback: (callback: () => void) => void
}
const LocalizationContext = createContext<ILocalizationContext>({
  i18n: i18next.createInstance(),
  t: () => {
    throw new Error('Not Implemented')
  },
  withScope: () => {
    throw new Error('Not Implemented')
  },
  setOnLanguageLoadedCallback: () => {
    return
  },
})

const trimSlashes = (url: string) => url.replace(/\.+$/, '')

const getAccentUrl = () => {
  let accentUrl = process.env.REACT_APP_ACCENT_DIST_URL
  if (!accentUrl) {
    return null
  }

  accentUrl = trimSlashes(accentUrl)

  return accentUrl
}

const getDefaultValue = (key: string | string[]) => {
  if (typeof key === 'string') {
    const [namespace, path] = key.split(':')

    if (namespace in DEFAULTS) {
      return get(DEFAULTS[namespace].en, path, 'Translation Missing')
    }
  }

  return 'Translation Missing'
}

const getDefaultValuePlural = (key: string | string[]) => {
  if (typeof key === 'string') {
    const [namespace, path] = key.split(':')

    if (namespace in DEFAULTS) {
      return get(DEFAULTS[namespace].en, `${path}_plural`, undefined)
    }
  }

  return undefined
}

const useMissingKeyHandler = (isReactTestingLibrary: boolean) => {
  const isReloading = useRef<boolean>(false)
  const lastReloaded = useRef<Moment | null>(null)
  const sentErrorMessages = useRef<{ [key: string]: boolean }>({})

  return useCallback(
    (newi18nInstance: i18next.i18n, language: string) =>
      (languages: string[], namespace: string, key: string, fallbackValue: string) => {
        const resourceBundle = newi18nInstance.getResourceBundle(language, namespace)

        // Do nothing if bundle failed to load or is currently reloading
        if (isReactTestingLibrary || !resourceBundle || isEmpty(resourceBundle) || isReloading.current) {
          return
        }

        // If last loaded 10 minutes ago, reload the translations from scratch first
        if (!lastReloaded.current || moment().diff(lastReloaded.current, 'minutes') >= 10) {
          isReloading.current = true

          newi18nInstance.reloadResources(undefined, undefined, () => {
            isReloading.current = false
            lastReloaded.current = moment()
            sentErrorMessages.current = {}
          })

          return
        }

        // Don't log about messages we've already logged about
        const errorKey = `${language}-${namespace}-${key}`
        if (sentErrorMessages.current[errorKey]) {
          return
        }
        sentErrorMessages.current[errorKey] = true

        cloudLogger.error(`could not find translation for ${key}`, {
          userImpact: `Default value of ${fallbackValue} shown instead`,
          meta: { language, namespace, key, resourceBundle },
          sendToSentry: false,
        })
      },
    [isReactTestingLibrary, isReloading, lastReloaded, sentErrorMessages],
  )
}

interface IInitBackendProps {
  language: string
  setCacheId: (uuid: string) => void
  setInitialized: (init: boolean) => void
  missingKeyHandler: (
    newi18nInstance: i18next.i18n,
    language: string,
  ) => (languages: string[], namespace: string, key: string, fallbackValue: string) => void
}

const useHttpBackend = ({ language, setCacheId, missingKeyHandler }: IInitBackendProps) => {
  const newi18nInstance = i18next.createInstance()
  const accentUrl = getAccentUrl()

  newi18nInstance.use(Backend).init(
    {
      initImmediate: false,
      lng: language,
      fallbackLng: 'en',
      ns: [...COMMON_NAMESPACES, ...NAMESPACES],
      debug: false,
      load: 'currentOnly',
      saveMissing: true,
      missingKeyHandler: missingKeyHandler(newi18nInstance, language),
      backend: {
        loadPath: (lngs: string[], namespaces: string[]) => {
          // namespaces array will only ever have one item as mentioned in the i18next-http-backend docs
          // Quote: "If not used with i18next-multiload-backend-adapter, lngs and namespaces will have only one element each"
          // See: https://github.com/i18next/i18next-http-backend?tab=readme-ov-file#backend-options
          const [ns] = namespaces

          if (COMMON_NAMESPACES.includes(ns)) {
            return `${accentUrl}/{{lng}}/accent/{{ns}}.json`
          }
          return `${accentUrl}/{{lng}}/spa/{{ns}}.json`
        },
        crossDomain: true,
      },
      react: {
        wait: false,
      },
      // React escapes by default
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      setCacheId(uuid.v4())
    },
  )

  return newi18nInstance
}

interface IProps {
  initI18nBackend?: (props: IInitBackendProps) => i18next.i18n
}

const LocalizationProvider = memo<React.PropsWithChildren<IProps>>(
  ({ initI18nBackend = useHttpBackend, children }) => {
    const [onLoad, setOnLoad] = useState<() => void>()
    const { language } = useTenantContext()
    const [cacheId, setCacheId] = useState(uuid.v4())
    const [initialized, setInitialized] = useState(false)
    const missingKeyHandler = useMissingKeyHandler(false)

    const setOnLanguageLoadedCallback = useCallback((callback: () => void) => {
      setOnLoad(() => callback)
    }, [])

    const i18nInstance = useMemo(() => {
      const newi18nInstance = initI18nBackend({ language, setCacheId, setInitialized, missingKeyHandler })

      newi18nInstance.on('languageChanged', () => {
        setCacheId(uuid.v4())
      })

      newi18nInstance.on('loaded', (loaded: Record<string, string[]>) => {
        if (initialized) {
          return
        }

        if (!(language in loaded)) {
          return
        }

        if (!loaded[language] || !Array.isArray(loaded[language])) {
          return
        }

        const loadedNs = new Set(loaded[language])
        if (NAMESPACES.some((ns) => !loadedNs.has(ns))) {
          return
        }

        setInitialized(true)
      })

      return newi18nInstance
      // Doesn't need to listen to language
      // Only need it the first time for initialization
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const onLanguageChanged = () => {
        if (onLoad) {
          onLoad()
        }
      }

      i18nInstance.on('languageChanged', onLanguageChanged)

      return () => {
        i18nInstance.off('languageChanged', onLanguageChanged)
      }
    }, [i18nInstance, onLoad])

    const t = useCallback<IT>(
      (key, opts = {}) => {
        opts = {
          defaultValue: getDefaultValue(key),
          defaultValue_plural: getDefaultValuePlural(key),
          ...opts,
        }

        return i18nInstance.t(key, opts)
        // Need to be recreated every time a new `cacheId` is created
      },
      [i18nInstance, cacheId], // eslint-disable-line react-hooks/exhaustive-deps
    )

    const withScope: IWithScope = useCallback(
      (context) => {
        const scope = (key: string | string[], opts = {}) => {
          if (typeof key === 'string') {
            if (context !== '') {
              key = `${context}.${key}`
            }
            return t(key, opts)
          }
          return t(
            key.map((k) => `${context}.${k}`),
            opts,
          )
        }
        scope.withScope = (subContext: string) => withScope(`${context}.${subContext}`)
        scope.Trans = (props: Parameters<typeof Trans>[0]) => {
          const key = `${context}.${props.i18nKey}`

          return (
            <Trans
              defaults={getDefaultValue(key)}
              {...props}
              i18nKey={key}
              t={t as i18next.TFunction}
            />
          )
        }
        scope.exists = (subContext: string) => i18nInstance.exists(`${context}.${subContext}`)

        return scope
      },
      [i18nInstance, t],
    )

    useEffect(() => {
      moment.locale(language)
      i18nInstance.changeLanguage(language)
    }, [i18nInstance, language])

    if (!initialized) {
      return <InitialLoadBrandSpinner />
    }

    return (
      <I18nextProvider i18n={i18nInstance}>
        <LocalizationContext.Provider value={{ i18n: i18nInstance, t, withScope, setOnLanguageLoadedCallback }}>
          {children}
        </LocalizationContext.Provider>
      </I18nextProvider>
    )
  },
  () => false,
)

const useScope: IWithScope = (context, options) => {
  const { withScope } = useContext(LocalizationContext)
  return useMemo(() => {
    try {
      return withScope(context, options)
    } catch (e) {
      if (options?.dontErrorOnAccentConnectionFailure) {
        return ((key: string, { defaultValue }: { defaultValue: string }) => defaultValue) as ITranslate
      }

      throw e
    }
  }, [withScope, context, options])
}

export type { IT, ILocalizationContext, ITranslate, IWithScope, IInitBackendProps }
export {
  LocalizationContext,
  LocalizationProvider,
  useScope,
  getDefaultValue,
  getDefaultValuePlural,
  useMissingKeyHandler,
  useHttpBackend,
}
