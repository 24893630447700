enum ApprovalStatusIds {
  Draft = '6746ca8c-5c00-414b-a8eb-853ef14ddc31',
  AwaitingApproval = '24b42a72-58b5-47cd-90de-c8d38821ea02',
  Declined = '3c1a432a-c883-48e6-a1b4-9b42fcc6265f',
  Approved = 'af4e9e71-1354-4e0f-b370-08874accb138',
  Revoked = 'a45e9700-c84b-47c9-aa3b-4a87599c0eef',
  Expired = '57cd960d-f260-4ce0-82ac-a86b5c29d932',
  ExpiringSoon = 'e2e074be-3b57-471f-b8c6-335b53e43edc',
  Current = '3e1cb498-8373-4fcc-9f44-ba12c69050e7',
  Future = '42d2be32-5a19-466b-b927-59edf3635eae',
  Renewing = 'c9479527-0199-40b3-8c15-5d5ef6fa554d',
  NotActioned = '00000000-0000-0000-0000-000000000000',
}

export { ApprovalStatusIds }
