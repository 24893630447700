import React from 'react'
import { useBrowserBreakpoints } from 'src/services/reducers/browser/hooks'
import { AssumedAccountAlert, DesktopNavigation, PageHeader } from 'src/services/layout/components/PageFrame/components'
import { ContentWrapper, HeaderWrapper, NavigationWrapper } from './style'

const PageFrame: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { desktop } = useBrowserBreakpoints()

  return (
    <>
      <NavigationWrapper>{desktop ? <DesktopNavigation /> : undefined}</NavigationWrapper>
      <ContentWrapper desktop={desktop}>
        <HeaderWrapper>
          <AssumedAccountAlert />
          <PageHeader />
        </HeaderWrapper>
        <div style={{ minHeight: '1px' }}>{children}</div>
      </ContentWrapper>
    </>
  )
}

const MemoPageFrame = React.memo(PageFrame)

export { MemoPageFrame as PageFrame }
