import styled, { css } from 'styled-components'

/**
 * An issue is caused based on chartjs implementation and at current time a chromium update which causes performance reports when printed
 * to create 600+ pages.
 * Below is believed to be the best solution following these guides:
 * https://stackoverflow.com/questions/74820718/chart-js-printing-a-webpage-containing-chart-js-canvas-prints-out-hundreds-of
 * https://stackoverflow.com/questions/74725673/react-chartjs-2-graph-resulting-in-hundreds-of-pages-when-printing-to-pdf
 */
const StyledChartJsSize = styled.div<{ height?: number }>`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  @media print {
    .chartjs-size-monitor {
      display: none !important;
    }
  }
`

export { StyledChartJsSize }
