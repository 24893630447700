import {
  QueryResult,
  QueryHookOptions,
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
  ApolloError,
  OperationVariables,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { apolloErrorToGraphiteError } from 'src/services/graphQL/helpers'
import { cloudLogger } from 'src/services/error'
import { useCallback } from 'react'

interface IUseQueryOptions<TData, TVariables extends OperationVariables> extends QueryHookOptions<TData, TVariables> {
  /**
   * Behaviour on errors returned from graphql.
   *  - throw-exception: Throws any errors when a error is encountered. This will result in an error boundary.
   *  - do-nothing: Don't do any special handling on errors. Use for queries which shouldn't break the page when failed.
   */
  errorBehaviour: 'throw-exception' | 'do-nothing'
  /**
   * @deprecated This property no longer does anything as of apollo client 3.
   * The new version of apollo client will no longer return cached data under the data key if it does not match
   * the variables provided. If you want to show stale data, you can instead use the previousData key in the response.
   */
  returnCachedDataMatchesVariables?: boolean
}

interface IUseQueryResult<TData, TVariables extends OperationVariables> extends QueryResult<TData, TVariables> {
  /**
   * @deprecated This property no longer does anything as of apollo client 3.
   */
  cachedDataMatchesVariables?: boolean
}

function useQuery<TData = unknown, TVariables extends OperationVariables = {}>(
  query: DocumentNode,
  options: IUseQueryOptions<TData, TVariables>,
): IUseQueryResult<TData, TVariables> {
  const { errorBehaviour, onError, ...apolloQueryOptions } = options

  const onErrorCallback = useCallback(
    (error: ApolloError) => {
      if (errorBehaviour === 'do-nothing') {
        cloudLogger.info(`graphql query thrown exception without displaying error boundary: ${error.message}`, {
          userImpact: 'Possibly unexpected behaviour',
          meta: { type: 'graphql', stack: error.stack },
        })
      }
      onError?.(error)
    },
    [onError, errorBehaviour],
  )

  const queryResult: IUseQueryResult<TData, TVariables> = useApolloQuery<TData, TVariables>(query, {
    ...apolloQueryOptions,
    onError: onErrorCallback,
  })

  if (errorBehaviour === 'throw-exception' && queryResult.error) {
    throw apolloErrorToGraphiteError(queryResult)
  }

  queryResult.cachedDataMatchesVariables = true

  return queryResult
}

function useLazyQuery<TData = unknown, TVariables extends OperationVariables = {}>(
  query: DocumentNode,
  options: IUseQueryOptions<TData, TVariables>,
) {
  const { errorBehaviour, ...apolloQueryOptions } = options

  const queryResult = useApolloLazyQuery<TData, TVariables>(query, apolloQueryOptions)

  if (errorBehaviour === 'throw-exception' && queryResult[1].error) {
    throw apolloErrorToGraphiteError(queryResult[1])
  }

  return queryResult
}

export type { IUseQueryOptions, IUseQueryResult }
export { useQuery, useLazyQuery }
