import { createAction } from 'redux-actions'
import uuid from 'uuid'
import { cloudLogger, IErrorLevels } from 'src/services/error'
import { IToast } from './reducer'

interface IPostToastArgs {
  type?: 'alert' | 'success'
  content: JSX.Element | string | string[]
  autoClose?: boolean
  autoCloseTimeout?: number
  logType?: IErrorLevels
}

const postToast = createAction('POST_TOAST', (postToastArgs: IPostToastArgs): IToast => {
  if (postToastArgs.logType && typeof postToastArgs.content === 'string') {
    cloudLogger[postToastArgs.logType](postToastArgs.content, {
      userImpact: 'Toast posted',
    })
  }

  return {
    id: uuid(),
    type: 'success',
    autoClose: true,
    autoCloseTimeout: postToastArgs.type === 'alert' ? 6000 : 2000,
    ...postToastArgs,
  }
})

const removeToast = createAction('REMOVE_TOAST')

const toastActions = {
  postToast,
  removeToast,
}

export { toastActions, IPostToastArgs }
