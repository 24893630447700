import React from 'react'
import { Fields } from 'redux-form'
import { VerticalForm } from '@intellihr/ui-components'
import { useValidationRulesForField } from 'src/services/reduxForm'
import { VisibilityOption } from '../../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'
import { IOnboardingField } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { CountryTelephoneInputGroup } from '../../../../../inputs/CountryTelephoneInputGroup'
import { fieldTypeIdToApiName } from '../PersonalDetailsFormStepContent'

const OnboardingPhoneField: React.FC<{
  fields: IOnboardingField[]
}> = ({ fields }) => {
  return (
    <OnboardingPhoneFieldInput
      field={fields[0]}
      countryFieldName="countryId"
    />
  )
}

const OnboardingPhoneFieldInput: React.FC<{
  field: IOnboardingField
  countryFieldName: string
}> = ({ field, countryFieldName }) => {
  const fieldName = fieldTypeIdToApiName[field.fieldType.id]
  const isRequired = field.visibility.id === VisibilityOption.REQUIRED

  const validatePhoneNumber = useValidationRulesForField(fieldName, [
    isRequired ? 'required' : undefined,
    {
      rule: 'phoneNumber',
      options: { countryField: countryFieldName },
    },
  ])

  const validateCountryId = useValidationRulesForField(countryFieldName, ['required'])

  return (
    <>
      <Fields<{ props?: { isRequired?: boolean; label?: string; fieldWrapperComponent: typeof VerticalForm.Field } }>
        validate={{
          [fieldName]: validatePhoneNumber,
          [countryFieldName]: validateCountryId,
        }}
        names={[countryFieldName, fieldName]}
        component={CountryTelephoneInputGroup}
        props={{
          isRequired,
          label: field.fieldType.name,
          fieldWrapperComponent: VerticalForm.Field,
        }}
      />
    </>
  )
}

export { OnboardingPhoneField, OnboardingPhoneFieldInput }
