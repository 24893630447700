import { gql } from '@apollo/client'
import { useQuery } from 'src/services/apollo'
import { useEffect } from 'react'
import { segmentIdentify } from 'src/services/segment/helper'
import { DateFormat, TimeFormat } from 'src/__gql__/globalTypes'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'
import { topbarNavFragment } from 'src/services/layout/components/PageFrame/components/PageHeader/gql'
import { sendDelightedSurvey } from 'src/services/delighted/helper'
import { SessionDetails, SessionDetailsVariables } from './__gql__/useSessionProviderCodeGen'

const CINTRA_INTEGRATION_ID = '2e24f317-b9f0-41ef-80b8-960756b0ce46'

const SESSION_DETAILS = gql`
  ${topbarNavFragment}

  query SessionDetails($userId: ID!, $cintraIntegrationId: ID!) {
    ...TopbarNavigation
    getUserConfig {
      language
      timezone
      dateFormat
      timeFormat
    }
    getTenantConfig {
      tenantName
      country {
        id
        name
        isoCode
        dialCode
      }
      currency {
        id
        code
        decimalPlaces
      }
      minPasswordLength
    }
    getUser(id: $userId) {
      id
      isAssumedAccount
      isAssumingAccountAsAnotherUser
      isOnboarding
      userPermissionGroups {
        id
        permissionGroup {
          id
          name
        }
      }
      person {
        id
        displayName
        preferredOrFirstName
        lastName
        legalName
        profilePictureId
        profilePictureUrl(size: SMALL)
        initials
        primaryEmailAddress {
          id
          email
        }
      }
      activeFrom
      mfaEnabled
      ssoId
    }
    listAllModules {
      id
      slug
      isEnabledForCurrentUser
    }
    getIntegration(id: $cintraIntegrationId) {
      id
      configuration {
        id
        isEnabled
      }
    }
  }
`

interface ISession {
  userId: string
  tenant: string
  isSuperUser: boolean
  isSystemAdministrator: boolean
}

interface ISessionProvider {
  data: SessionDetails | undefined
  timezone: string | undefined
  dateFormat: DateFormat | 'VERBOSE' | undefined
  timeFormat: TimeFormat | 'HOURS_12' | null | undefined
}

const useSessionProvider = ({ userId, tenant, isSuperUser, isSystemAdministrator }: ISession): ISessionProvider => {
  const { data } = useQuery<SessionDetails, SessionDetailsVariables>(SESSION_DETAILS, {
    variables: {
      userId,
      cintraIntegrationId: CINTRA_INTEGRATION_ID,
    },
    errorBehaviour: 'throw-exception',
  })

  const timezone = data?.getUserConfig.timezone
  const dateFormat = data?.getUserConfig.dateFormat
  const timeFormat = data?.getUserConfig.timeFormat

  useEffect(
    () => {
      if (data?.getUser && !data.getUser.isAssumedAccount && data?.getIntegration) {
        segmentIdentify({
          userId,
          personId: data.getUser.person.id,
          tenant,
          name: data.getUser.person.displayName ?? undefined,
          email: data.getUser.person.primaryEmailAddress?.email ?? undefined,
          superUser: isSuperUser,
          systemAdministrator: isSystemAdministrator,
        })

        sendDelightedSurvey({
          email: data.getUser.person.primaryEmailAddress?.email ?? undefined,
          name: data.getUser.person.displayName ?? undefined,
          properties: {
            userId,
            personId: data.getUser.person.id,
            tenant,
            tenantType:
              data.getIntegration.configuration === null
                ? 'normal'
                : data.getIntegration.configuration?.isEnabled
                ? 'cintra'
                : 'normal',
            superUser: isSuperUser,
            systemAdministrator: isSystemAdministrator,
          },
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userId,
      tenant,
      isSuperUser,
      isSystemAdministrator,
      data?.getUser?.person.primaryEmailAddress?.email,
      data?.getIntegration?.configuration?.isEnabled,
    ],
  )

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone)
    }
  }, [timezone])

  return {
    data,
    timezone,
    dateFormat,
    timeFormat,
  }
}

export { CINTRA_INTEGRATION_ID, SESSION_DETAILS, ISession, ISessionProvider, useSessionProvider }
