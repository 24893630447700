import React from 'react'
import { Variables, Text } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { IDocument } from './types'

const UploadingContentOverride: React.FC<{
  data: IDocument
}> = ({ data }) => {
  const t = useScope('common:components.DocumentTable')
  return (
    <div>
      <Text
        color={Variables.Color.n500}
        margins={{ right: Variables.Spacing.sXSmall }}
      >
        {t('uploadingOverrideText', { defaultValue: 'Uploading' })}
      </Text>
      <Text color={Variables.Color.n500}>{data.humanReadableSize}</Text>
    </div>
  )
}

export { UploadingContentOverride }
