import { useContext } from 'react'
import { UserContext } from 'src/services/user/UserContext'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'

const useUserContext = () => {
  const userDetails = useContext(UserContext)

  return {
    id: getSession().userId,
    timezone: userDetails.timezone,
    dateFormat: userDetails.dateFormat,
    timeFormat: userDetails.timeFormat,
    language: userDetails.language,
    person: userDetails.person,
    isOnboarding: userDetails.person.user.isOnboarding,
    mfaEnabled: userDetails.person.user.mfaEnabled,
    ssoId: userDetails.person.user.ssoId,
  }
}

export { useUserContext }
