import { get, isArray, merge, set } from 'lodash'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useRuleSet } from './rules'
import { IFieldOptions, IFieldValues, IErrorMessages, IRuleSet, IRule } from './types'

const useValidateMap = () => {
  const { ruleSet } = useRuleSet()

  const validate = useCallback(
    (
      value: unknown,
      rules: IRule | IRule[],
      fieldValues: IFieldValues,
      options: IFieldOptions | null,
    ): { _error: string } | string | null => {
      if (!Array.isArray(rules)) {
        rules = [rules]
      }

      if (value && typeof value === 'object' && !isArray(value) && !moment.isMoment(value)) {
        value = value.toString()
      }

      for (const rule of rules) {
        if (typeof rule !== 'string') {
          continue
        }

        if (!ruleSet[rule]) {
          throw new Error(`Validate rule "${rule}" not found`)
        }

        if (!ruleSet[rule].validate(value, options!, fieldValues)) {
          if (options && options.errorMessage) {
            if (rule === 'requiredFieldArray') {
              return { _error: options.errorMessage }
            }

            return options.errorMessage
          }

          return ruleSet[rule].errorMessage(options!, value, fieldValues)
        }
      }

      return null
    },
    [ruleSet],
  )

  const validateField = useCallback(
    (
      validateValue: unknown,
      fieldValues: IFieldValues,
      validateRules: IRule[] | undefined,
      options: IFieldOptions | null,
    ): { _error: string } | string | null => {
      if (validateRules) {
        for (const rule of validateRules) {
          const errorMessage = validate(validateValue, rule, fieldValues, options)

          if (errorMessage) {
            return errorMessage
          }
        }
      }

      return null
    },
    [validate],
  )

  const validateFieldOrFieldArray = useCallback(
    (
      fieldName: string,
      fieldValues: IFieldValues,
      validateRules: IRule[] | undefined,
      options: IFieldOptions | null,
    ): IErrorMessages => {
      const errors = {}

      if (fieldName.indexOf('.*.') > -1) {
        const fieldNameParts = fieldName.split('.*.')
        const parentFieldName = fieldNameParts.shift() as string
        const childFieldName = fieldNameParts.join('.*.')

        // eslint-disable-next-line no-useless-escape
        if (childFieldName.match(/[^\*]\.[^\*]/)) {
          throw Error('Using objects in field array validation is not currently supported')
        }

        const parentArray = get(fieldValues, parentFieldName)

        if (!parentArray) {
          // When null/undefined, don't validate the contents of the array (treat as empty)
          return errors
        }

        if (!isArray(parentArray)) {
          throw Error(
            `Parent field ${parentFieldName} is not an array even though it is specified as though it is in ${fieldName}`,
          )
        }

        if (childFieldName.indexOf('.*.') > -1) {
          return validateMultiLevelFieldArray(childFieldName, parentArray, parentFieldName, options, validateRules)
        }

        parentArray.forEach((childFields, index) => {
          const validateValue = get(childFields, childFieldName, null)
          const errorMessage = validateField(validateValue, fieldValues, validateRules, {
            ...options,
            fieldArrayName: parentFieldName,
            childFieldName,
          })

          if (errorMessage) {
            set(errors, `${parentFieldName}[${index}]${childFieldName}`, errorMessage)
          }
        })
      } else {
        const validateValue = get(fieldValues, fieldName, null)
        const errorMessage = validateField(validateValue, fieldValues, validateRules, options)

        if (errorMessage) {
          set(errors, fieldName, errorMessage)
        }
      }

      return errors
    },
    [validateField], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validateMultiLevelFieldArray = (
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grandparentArray: any,
    grandparentFieldName: string,
    options: IFieldOptions | null,
    validateRules: IRule[] | undefined,
  ) => {
    const errors = {}

    const fieldNameParts = fieldName.split('.*.')
    const parentFieldName = fieldNameParts.shift() as string
    const childFieldName = fieldNameParts.join('.*.')

    if (childFieldName.indexOf('.*.') > -1) {
      throw Error('Multi-level field array validation currently only supports two levels deep')
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grandparentArray.forEach((parentFields: any, parentIndex: number) => {
      const parentArray = get(parentFields, parentFieldName)

      if (!parentArray) {
        // When null/undefined, don't validate the contents of the array (treat as empty)
        return errors
      }

      if (!isArray(parentArray)) {
        throw Error(
          `Parent field ${parentFieldName} is not an array even though it is specified as though it is in ${fieldName}`,
        )
      }

      parentArray.forEach((childFields, childIndex) => {
        const validateValue = get(childFields, childFieldName, null)
        const errorMessage = validateField(validateValue, grandparentArray, validateRules, {
          ...options,
          fieldArrayName: parentFieldName,
          childFieldName,
        })

        if (errorMessage) {
          set(
            errors,
            `${grandparentFieldName}[${parentIndex}]${parentFieldName}[${childIndex}]${childFieldName}`,
            errorMessage,
          )
        }
      })

      return
    })
    return errors
  }

  const validateMap = useCallback(
    (map: IRuleSet[], fieldValues: IFieldValues): IErrorMessages => {
      const errors = {}

      for (const validation of map) {
        if (!validation || typeof validation !== 'object') {
          continue
        }

        const fields = validation.fields || (validation.field ? [validation.field] : undefined)

        if (fields) {
          for (const fieldName of fields) {
            const validateRules = validation.rules || (validation.rule ? [validation.rule] : undefined)
            const options = validation.options || null

            const errorsForField = validateFieldOrFieldArray(fieldName, fieldValues, validateRules, options)

            merge(errors, errorsForField)
          }
        }
      }

      return errors
    },
    [validateFieldOrFieldArray],
  )

  return {
    validateMap,
  }
}

// Magic handling to use in analytics
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IFieldValidator = (value: any, ...rest: any[]) => string

interface IFieldValidators {
  [key: string]: IFieldValidator
}

const useFieldValidators = () => {
  const { ruleSet } = useRuleSet()

  const fieldValidators = useMemo<IFieldValidators>(
    () =>
      Object.keys(ruleSet).reduce((validators, key) => {
        const { validate, errorMessage } = ruleSet[key]

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validators[key] = (value: any, ...rest: any[]) => {
          if (validate(value, ...rest)) {
            return
          }
          return errorMessage(...rest)
        }

        return validators
      }, {}),
    [ruleSet],
  )

  return fieldValidators
}

export { useValidateMap, useFieldValidators }
