import React from 'react'
import uuid from 'uuid'
import { IUserDetailsProps, withUserDetails } from 'src/services/user/UserContext/helpers/hoc'
import { cloudLogger } from 'src/services/error/services'
import { GraphiteNetworkError, GraphiteServerError } from 'src/services/graphQL/errors'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ErrorBoundaryContent, NetworkErrorContent } from './content'

interface IErrorBoundary {
  children?: JSX.Element | JSX.Element[] | null
  context?: string
}

interface IInfo {
  componentStack: string
}

interface IErrorBoundaryState {
  errorId: string | null
  error: Error | null
}

type IBaseErrorBoundaryProps = IErrorBoundary & Partial<IUserDetailsProps> & Partial<RouteComponentProps>
class BaseErrorBoundary extends React.PureComponent<IBaseErrorBoundaryProps, IErrorBoundaryState> {
  public context: unknown

  public state: IErrorBoundaryState = {
    errorId: null,
    error: null,
  }

  public componentDidUpdate(prevProps: Readonly<IBaseErrorBoundaryProps>): void {
    if (prevProps.context !== this.props.context) {
      this.setState({ errorId: null, error: null })
    }

    if (prevProps.location && prevProps.location.pathname !== this.props.location?.pathname) {
      this.setState({ errorId: null, error: null })
    }
  }

  public componentDidCatch(error: Error, info: IInfo) {
    const { userDetails } = this.props

    let errorId = (error as GraphiteServerError).errorId
    if (!errorId) {
      errorId = uuid.v4()

      cloudLogger.info('Error caught with no ID; generating a new one', {
        errorId,
        userImpact: 'N/A',
        meta: { userDetails, info },
      })
    }

    this.setState({
      errorId,
      error,
    })

    cloudLogger.error(error, {
      errorId,
      userImpact: 'Error boundary hit',
      meta: { userDetails, info },
    })
  }

  public reloadPage = () => {
    window.location.reload()
  }

  public render() {
    const { errorId, error } = this.state

    const { children } = this.props

    if (errorId) {
      if (error instanceof GraphiteNetworkError) {
        return (
          <NetworkErrorContent
            errorId={errorId}
            onReloadPageClick={this.reloadPage}
          />
        )
      }

      return (
        <ErrorBoundaryContent
          errorId={errorId}
          onReloadPageClick={this.reloadPage}
        />
      )
    }

    return children
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorBoundary = withUserDetails(withRouter<any, any>(BaseErrorBoundary)) as unknown as typeof BaseErrorBoundary

const ErrorBoundaryWithoutUserDetails = BaseErrorBoundary

export type { IInfo }
export { ErrorBoundary, ErrorBoundaryWithoutUserDetails }
