import React from 'react'
import { TextInput, Variables } from '@intellihr/ui-components'
import { ITextInputProps } from '@intellihr/ui-components/types/domain/Inputs'
import { OptionList } from 'src/domain/EDM/internal/components/uiComponents/OptionList'
import type { IOptionListProps } from 'src/domain/EDM/internal/components/uiComponents/OptionList'

interface IFilteredOptionListProps extends IOptionListProps {
  textInputProps?: ITextInputProps
}

interface IFilteredOptionListState {
  query: string
}

class FilteredOptionList extends React.PureComponent<IFilteredOptionListProps, IFilteredOptionListState> {
  public state: IFilteredOptionListState = {
    query: '',
  }

  public render(): JSX.Element {
    const { width, options, textInputProps, handleClick, selectedValue, maxHeight, truncatedText } = this.props

    return (
      <>
        <TextInput
          width={width}
          name="filteredOptionListInput"
          value={this.state.query}
          handleChange={this.updateQueryValue}
          margins={{ bottom: Variables.Spacing.sMedium }}
          {...textInputProps}
        />
        <OptionList
          truncatedText={truncatedText}
          selectedValue={selectedValue}
          handleClick={handleClick}
          options={options}
          query={this.state.query}
          maxHeight={maxHeight}
          width={width}
        />
      </>
    )
  }

  private updateQueryValue = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value })
}

export { FilteredOptionList }
