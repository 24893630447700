import { Variables } from '@intellihr/ui-components'
import styled from 'styled-components'

const StyledAutocompleteLocationInput = styled.div`
  position: relative;

  .geosuggest__suggests-wrapper {
    .geosuggest__suggests {
      background-color: ${Variables.Color.n100};
      border: 1px solid ${Variables.Color.n400};
      border-bottom-left-radius: ${Variables.Style.borderRadius}px;
      border-bottom-right-radius: ${Variables.Style.borderRadius}px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: ${Variables.FontSize.fzBody};
      left: 0;
      line-height: 34px;
      list-style-type: none;
      margin-bottom: 1rem;
      margin-left: 0;
      margin-top: -1.5px;
      max-height: 198px;
      overflow-y: auto;
      top: 100%;
      transition: border-color 0.3s ease-in-out, background-color 0.3s ease;
      width: 100%;
      z-index: 1;
    }

    .geosuggest__suggests--hidden {
      display: none;
    }

    .geosuggest__item {
      background-color: ${Variables.Color.n100};
      box-sizing: border-box;
      color: ${Variables.Color.n700};
      cursor: pointer;
      display: block;
      height: 50px;
      overflow: hidden;
      padding: 8px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover,
      &.geosuggest__item--active {
        background-color: ${Variables.Color.n200};
      }
    }
  }

  .geosuggest__input {
    margin-bottom: 0;
  }

  .autocomplete-reset {
    color: ${Variables.Color.n700};
    cursor: pointer;
    line-height: 40px;
    padding: 0 12px;
    position: absolute;
    right: 0;
    vertical-align: top;

    &:hover {
      color: ${Variables.Color.r600};
    }
  }
`

export { StyledAutocompleteLocationInput }
