import { WrappedFieldMetaProps } from 'redux-form'

interface IInputGroupField {
  meta: WrappedFieldMetaProps
}

const getInputGroupErrorMessages = (fields: IInputGroupField[]) =>
  fields.reduce((acc: string[], field) => {
    if (field.meta.touched) {
      acc.push(field.meta.error)
    }

    return acc
  }, [])

export { getInputGroupErrorMessages }
