import {
  IconArrowToRight,
  IconBell,
  IconCog,
  IconPageGoals,
  IconPageMyTasks,
  IconPageOnboarding,
  IconPagePerformanceImprovement,
  IconPagePrivateNotes,
  IconPageTraining,
  IconPlus,
  IconQuestionCircle,
  IconUser,
} from '@intellihr/blueberry'

const pageHeaderIcons = {
  myTasks: IconPageMyTasks,
  notifications: IconBell,
  create: IconPlus,
  'create.note': IconPagePrivateNotes,
  'create.person': IconPageOnboarding,
  'create.diaryNote': IconPagePerformanceImprovement,
  'create.goal': IconPageGoals,
  'create.training': IconPageTraining,
  'user.profile': IconUser,
  'user.settings': IconCog,
  'user.logout': IconArrowToRight,
}

const getIconForPageHeaderItem = (key: string) => {
  return pageHeaderIcons[key] ?? IconQuestionCircle
}

export { getIconForPageHeaderItem }
