import { createSelector } from 'reselect'
import { RootState } from 'src/services/store/store'

interface IBrowserSelector {
  isMobile?: boolean
  isMobileOrTablet?: boolean
  isTablet?: boolean
  isDesktop?: boolean
}

type BreakPointsDefaultNames = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'infinity'

type IBreakPoints<BPNames extends string = BreakPointsDefaultNames> = {
  [k in BPNames]: number | string
}

type IBreakPointResults<BP = IBreakPoints> = { [k in keyof BP]: boolean }

interface IBrowser<BP = IBreakPoints> {
  _responsiveState: boolean
  mediaType: string
  orientation: string
  lessThan: IBreakPointResults<BP>
  greaterThan: IBreakPointResults<BP>
  is: IBreakPointResults<BP>
  breakpoints: BP
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMobileSelector = createSelector<any, any>(
  (state: RootState) => state.get('browser'),
  (browser: IBrowser) => browser.lessThan.medium,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMobileOrTabletSelector = createSelector<any, any>(
  (state: RootState) => state.get('browser'),
  (browser: IBrowser) => browser.lessThan.large,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isTabletSelector = createSelector<any, any>(
  (state: RootState) => state.get('browser'),
  (browser: IBrowser) => browser.lessThan.large && browser.greaterThan.small,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDesktopSelector = createSelector<any, any>(
  (state: RootState) => state.get('browser'),
  (browser: IBrowser) => browser.greaterThan.medium,
)

export type { IBrowserSelector }
export { isMobileSelector, isMobileOrTabletSelector, isTabletSelector, isDesktopSelector }
