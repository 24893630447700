import { useCallback } from 'react'
import { formValueSelector, change, FormAction, getFormSyncErrors, touch, untouch, FormErrors } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { isEqual, get } from 'lodash'
import { getFormState } from './ReduxForm'
import { useValidateMap } from './services/validator'
import { IRuleSet, IFieldValues, IErrorMessages, IRule } from './services/types'

const useReduxFormAction = (
  formName: string,
  action: (formName: string, field: string, ...otherProps: unknown[]) => FormAction,
  sectionName?: string,
) => {
  const dispatch = useDispatch()

  return useCallback(
    (field: string, ...otherProps: unknown[]) => {
      field = sectionName ? `${sectionName}.${field}` : field

      return dispatch(action(formName, field, ...otherProps))
    },
    [action, dispatch, formName, sectionName],
  )
}

const useReduxFormChange = (formName: string, sectionName?: string) => {
  return useReduxFormAction(formName, change, sectionName)
}

const useReduxFormTouch = (formName: string, sectionName?: string) => {
  const dispatch = useDispatch()

  return useCallback(
    (...fields: string[]) => {
      return dispatch(touch(formName, ...fields.map((field) => (sectionName ? `${sectionName}.${field}` : field))))
    },
    [dispatch, formName, sectionName],
  )
}

const useReduxFormUntouch = (formName: string, sectionName?: string) => {
  const dispatch = useDispatch()

  return useCallback(
    (...fields: string[]) => {
      return dispatch(untouch(formName, ...fields.map((field) => (sectionName ? `${sectionName}.${field}` : field))))
    },
    [dispatch, formName, sectionName],
  )
}

const useValidationRules = (
  validationRules: IRuleSet[] | ((fieldValues: IFieldValues) => IRuleSet[]),
  extraCustomValidation?: (fieldValues: IFieldValues) => IErrorMessages,
) => {
  const { validateMap } = useValidateMap()
  return useCallback(
    (values: IFieldValues) => {
      const ruleErrorMessages = validateMap(
        typeof validationRules === 'function' ? validationRules(values) : validationRules,
        values,
      )

      return {
        ...ruleErrorMessages,
        ...extraCustomValidation?.(values),
      }
    },
    [validateMap, validationRules, extraCustomValidation],
  )
}
const useValidationRulesForField = (
  field: string,
  rules: Array<IRule | IRuleSet> | ((fieldValues: IFieldValues) => Array<IRule | IRuleSet>),
  sectionName?: string,
) => {
  field = sectionName ? `${sectionName}.${field}` : field
  const { validateMap } = useValidateMap()

  return useCallback(
    (value: unknown, values: IFieldValues) => {
      const valuesForCallback = sectionName ? (get(values, sectionName, {}) as IFieldValues) : values
      const rulesArray = typeof rules === 'function' ? rules(valuesForCallback) : rules

      const ruleSetArray = rulesArray.map((rule) =>
        rule && typeof rule === 'object'
          ? {
              field,
              ...rule,
            }
          : {
              field,
              rule,
            },
      ) as IRuleSet[]

      const errorMessages = validateMap(ruleSetArray, values)

      return Object.values(sectionName ? get(errorMessages, sectionName, {}) : errorMessages).join(', ')
    },
    // We intentionally don't regenerate on rules changing; rules should always be constant (use values callback if they change)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, sectionName],
  )
}

/**
 * Get the values for an arbitrary form for some observed values
 */
const useFormValues = <TFormValues extends {}>(
  formName: string,
  observedFields: Set<string>,
  sectionName?: string,
): TFormValues => {
  const baseSelector = formValueSelector(formName, getFormState)
  const selectorForFields = useCallback(
    (state: unknown) =>
      baseSelector(
        state,
        ...Array.from(observedFields).map((field) => (sectionName ? `${sectionName}.${field}` : field)),
      ),
    [baseSelector, observedFields, sectionName],
  )

  const formValues = useSelector(selectorForFields, isEqual)

  if (observedFields && observedFields.size === 1) {
    const key = observedFields.values().next().value
    return {
      [key]: formValues,
    } as TFormValues
  }

  if (sectionName) {
    return get(formValues, sectionName, {})
  }

  return formValues
}

/**
 * Get the validation errors for an arbitrary form
 */
const useFormErrors = (formName: string, sectionName?: string): FormErrors<{}, string> => {
  const baseSelector = getFormSyncErrors(formName, getFormState)
  const formValues = useSelector(baseSelector, (left, right) => {
    if (sectionName) {
      left = get(left, sectionName, {})
      right = get(right, sectionName, {})
    }

    return isEqual(left, right)
  })

  if (sectionName) {
    return get(formValues, sectionName, {})
  }

  return formValues
}

export {
  useReduxFormAction,
  useReduxFormChange,
  useReduxFormTouch,
  useReduxFormUntouch,
  useValidationRules,
  useValidationRulesForField,
  useFormValues,
  useFormErrors,
}
