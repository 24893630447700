import { useTenantContext } from 'src/services/user/TenantContext/helpers/hook'

type ModuleSlug =
  | 'APPROVAL_ENGINE'
  | 'MEDICAL_CONDITIONS'
  | 'QUALIFICATIONS_DATA'
  | 'MANAGERS_LOG'
  | 'DIARY_NOTES_PERFORMANCE_IMPROVEMENT'
  | 'PRIVATE_NOTES'
  | 'WORKFORCE_PLANNING'
  | 'CUSTOM_SELF_SERVICE_TILES'
  | 'CUSTOM_SELF_SERVICE_TILES_FILTERING'
  | 'CUSTOM_FIELDS'
  | 'SKILLS'
  | 'GOALS'
  | 'GOAL_TEMPLATES'
  | 'ORGANISATION_OBJECTIVES'
  | 'PERFORMANCE_REPORT'
  | 'COMPLIANCE_QUALIFICATIONS'
  | 'COMPLIANCE_PERFORMANCE_IMPROVEMENT_PLANS'
  | 'FORM_DESIGNS'
  | 'FORM_DESIGNS_CREATE_YOUR_OWN'
  | 'TEMPLATE_DESIGNS'
  | 'PULSE_ENGINE_SINGLE_RECURRING'
  | 'ANONYMOUS_FEEDBACK'
  | 'WORKFLOW_ENGINE'
  | 'CONTRACT_OFFER_MAKING'
  | 'NATIVE_INTEGRATIONS'
  | 'CINTRA_CALENDAR'
  | 'CINTRA_HOLIDAY_BOOKINGS'
  | 'CINTRA_ABSENCE'
  | 'CINTRA_PAYSLIPS'
  | 'CINTRA_BANK_ACCOUNTS'
  | 'CINTRA_MANAGE_ABSENCE'
  | 'CINTRA_MANAGE_HOLIDAYS'
  | 'CINTRA_MANAGE_PAY_CLAIMS'
  | 'CINTRA_P45'
  | 'CINTRA_P60'
  | 'CINTRA_P11D'
  | 'CINTRA_DOCUMENTS'
  | 'CINTRA_PAY_CLAIMS'
  | 'GLOBAL_NOTIFICATIONS'
  | 'FIRST_LEVEL_SUPPORT'
  | 'MULTILINGUAL_SUPPORT'
  | 'COMPLIANCE_TASKS'

/**
 * Given a slug or list of slugs, return a boolean of whether that module is enabled for the current user.
 *
 * When passing multiple slugs, this check will fail if _any_ of the slugs fail to pass the check.
 *
 * @see \Lapis\Domain\Module\Services\ModuleCheckerService in lapis
 *
 * @param moduleSlug a slug or list of slugs as defined by ModuleSlug
 * @returns boolean
 */
const useModuleCheckWithSlug = (moduleSlug: ModuleSlug | ModuleSlug[]): boolean => {
  if (!Array.isArray(moduleSlug)) {
    moduleSlug = [moduleSlug]
  }

  const enabledModules = useTenantContext()
    .modules.filter((module) => module.isEnabledForCurrentUser)
    .flatMap((module) => module.slug)

  return moduleSlug.every((slug) => enabledModules.includes(slug))
}

export { useModuleCheckWithSlug }

export type { ModuleSlug }
