import { SelectInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import { get, map } from 'lodash'
import React, { useCallback } from 'react'
import { Field } from 'redux-form'
import { Query } from 'src/services/apollo'
import {
  IReduxVerticalFormFieldParam,
  ReduxVerticalFormField,
  useValidationRulesForField,
} from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { ListAllAddressTypesForSelect } from './__gql__/AddressTypeSelectInputCodeGen'

interface IAddressType {
  id: string
  name: string
}

interface IAddressTypeSelectInputProps {
  label?: string
  name?: string
  enforceIsRequired?: boolean
}

const LIST_ALL_ADDRESS_TYPES = gql`
  query ListAllAddressTypesForSelect {
    listAllAddressTypes(filters: { isEnabled: { equals: true } }) {
      id
      name
    }
  }
`

const AddressTypeSelectInput: React.FC<IAddressTypeSelectInputProps> = ({
  label,
  name = 'addressTypeId',
  enforceIsRequired = false,
}) => {
  const tTerminology = useScope('edm:terminology')
  const addressTypeOption = useCallback(
    (addressType: IAddressType) => ({ value: addressType.id, label: addressType.name }),
    [],
  )

  const validation = useValidationRulesForField(name, [enforceIsRequired ? 'required' : undefined])

  return (
    <Query<ListAllAddressTypesForSelect>
      errorBehaviour="throw-exception"
      query={LIST_ALL_ADDRESS_TYPES}
    >
      {({ loading, data }) => (
        <Field<IReduxVerticalFormFieldParam>
          name={name}
          component={ReduxVerticalFormField}
          validate={validation}
          props={{
            isRequired: true,
            label: label ?? tTerminology('Address.typeVerbose'),
            component: SelectInput,
            inputProps: {
              componentContext: 'address-type-select-input',
              isFetching: loading,
              options: map(get(data, 'listAllAddressTypes', []), addressTypeOption),
            },
          }}
        />
      )}
    </Query>
  )
}

export type { IAddressType }
export { AddressTypeSelectInput, LIST_ALL_ADDRESS_TYPES }
