import { Props, TextInput, TextLink } from '@intellihr/ui-components'
import React from 'react'
import { Field } from 'redux-form'
import { IReduxVerticalFormFieldParam, ReduxVerticalFormField } from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { validationCallback } from '../../types'

interface IManualAddressFieldsProps {
  onClickManualOptionButton: (event: React.MouseEvent<HTMLElement>) => void
  validation?: validationCallback
}

const ManualAddressField: React.FC<IManualAddressFieldsProps> = ({ onClickManualOptionButton, validation }) => {
  const tTerminology = useScope('edm:terminology')
  const tFeature = useScope('edm:features.AddressForm')

  const actionMessage = (): JSX.Element => {
    return (
      <TextLink
        onClick={onClickManualOptionButton}
        textType={Props.TypographyType.Small}
      >
        {tFeature('inputActionSwitchToAuto')}
      </TextLink>
    )
  }

  return (
    <Field<IReduxVerticalFormFieldParam>
      name="fullAddress"
      component={ReduxVerticalFormField}
      validate={validation}
      props={{
        isRequired: true,
        label: tTerminology('Address.term'),
        component: TextInput,
        actionMessage: actionMessage(),
        inputProps: {
          componentContext: 'input-manual-address',
          placeholder: tFeature('inputPlaceholderManual'),
        },
      }}
    />
  )
}

export type { IManualAddressFieldsProps }
export { ManualAddressField }
