import React from 'react'
import { Field } from 'redux-form'
import { ReduxVerticalFormField, useValidationRulesForField } from 'src/services/reduxForm'
import { TextInput } from '@intellihr/ui-components'
import { useEmailUniquenessCheck } from 'src/domain/EDM/internal/services/emailUniquenessCheckService'
import { IOnboardingField } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { VisibilityOption } from '../../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'
import { fieldTypeIdToApiName } from '../PersonalDetailsFormStepContent'

const OnboardingEmailField: React.FC<{
  fields: IOnboardingField[]
  values?: { [key: string]: string }
}> = ({ fields, values }) => {
  const name = fieldTypeIdToApiName[fields[0].fieldType.id]

  return (
    <OnboardingEmailFieldInput
      field={fields[0]}
      value={values && values[name]}
    />
  )
}

const OnboardingEmailFieldInput: React.FC<{
  field: IOnboardingField
  value?: string
}> = ({ field, value }) => {
  const name = fieldTypeIdToApiName[field.fieldType.id]
  const isRequired = field.visibility.id === VisibilityOption.REQUIRED
  const validateEmail = useValidationRulesForField(name, [isRequired ? 'required' : undefined, 'email'])
  const emailUniquenessCheck = useEmailUniquenessCheck(value)

  return (
    <Field
      name={name}
      component={ReduxVerticalFormField}
      validate={validateEmail}
      onBlur={emailUniquenessCheck.handleBlur}
      props={{
        component: TextInput,
        label: field.fieldType.name,
        isRequired,
        inputProps: {
          name,
          componentContext: `template-field-${name}`,
          type: 'email',
        },
        actionMessage: emailUniquenessCheck.actionMessage,
      }}
    />
  )
}

export { OnboardingEmailField, OnboardingEmailFieldInput }
