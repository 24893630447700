import { get, isArray, isEmpty, isNil, isString, isFunction, isObject, toNumber, toString, isEqual } from 'lodash'
import isDecimal from 'validator/lib/isDecimal'
import isInt from 'validator/lib/isInt'
// eslint-disable-next-line no-restricted-imports
import moment, { isMoment } from 'moment'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { useMemo } from 'react'
import { useUserTimezoneDateModifiers, getCurrentIsoDate } from 'src/services/helpers/dates'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import {
  graphqlDateFormatToMomentFormat,
  graphqlTimeFormatToMomentFormat,
} from 'src/services/uiComponentCandidates/Date/helper'
import { IFieldOptions, IFieldValues } from './types'

const alphaNumericRegex = /^[A-Za-z0-9_-]*$/
const emailRegex = /^(?=.{0,254}$)^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+(@[a-z0-9-]{1,63})(?:\.[a-z0-9-]{1,63})+$/i
const lowerCaseSnakeRegex = /^[a-z0-9_-]*$/

const useRuleSet = () => {
  const t = useScope('common:validation.errorMessage')
  const tIntegrations = useScope('integrations:validation.errorMessage')
  const { isAfter, isBefore, isSameOrAfter, isSameOrBefore, isValid } = useUserTimezoneDateModifiers()
  const currentISODate = getCurrentIsoDate()
  const { dateFormat, timeFormat } = useUserContext()
  const dateFormatValue = graphqlDateFormatToMomentFormat(dateFormat)
  const timeFormatValue = graphqlTimeFormatToMomentFormat(timeFormat)

  const ruleSet = useMemo(
    () => ({
      alphanumeric: {
        validate: (value: unknown) => {
          if (isString(value)) {
            return alphaNumericRegex.test(value)
          }

          return true
        },
        errorMessage: () =>
          t('alphanumeric', {
            defaultValue: 'This must only contain letters, numbers or underscores (e.g. valid_input_1)',
          }),
      },
      atLeastOneCheckboxSelected: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          const otherCheckboxes = options.otherIncludedCheckboxes || []
          const isOneOptionSelected = (selectOption: unknown) => {
            if (typeof selectOption === 'boolean') {
              return selectOption
            } else if (typeof selectOption === 'object' && selectOption) {
              return Object.values(selectOption).includes(true)
            }

            return true
          }

          const selectOneInOtherIncludedCheckboxes = otherCheckboxes.map(
            (checkbox: string) => fieldValues[checkbox] && isOneOptionSelected(fieldValues[checkbox]),
          )

          return isOneOptionSelected(value) || selectOneInOtherIncludedCheckboxes.includes(true)
        },
        errorMessage: () => t('atLeastOneCheckboxSelected'),
      },
      date: {
        validate: (value: unknown) => {
          const valueString = toString(value)

          if (isEmpty(valueString)) {
            return false
          }

          if (typeof value === 'string') {
            return isValid(value)
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!isObject(value) || !isFunction((value as any).isValid)) {
            return true
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (value as any).isValid()
        },
        errorMessage: () => t('date', { defaultValue: 'This date is not in the correct format' }),
      },
      decimal: {
        validate: (value: unknown, options: IFieldOptions) => {
          const valueString = toString(value)

          const maxDecimalPlaces =
            typeof options?.maxDecimalPlaces === 'number' ? `0,${options?.maxDecimalPlaces}` : undefined

          return isEmpty(valueString) || isDecimal(valueString, { decimal_digits: maxDecimalPlaces })
        },

        errorMessage: (options: IFieldOptions) =>
          typeof options?.maxDecimalPlaces === 'number'
            ? t('decimalMaxPlaces', {
                maxDecimalPlaces: options.maxDecimalPlaces,
              })
            : t('decimal'),
      },
      email: {
        validate: (value: unknown) => {
          if (isString(value)) {
            if (value === '') {
              return true
            }

            const res = value.match(emailRegex)

            return res !== null
          }

          return true
        },
        errorMessage: () => t('email'),
      },
      emailList: {
        validate: (value: unknown) => {
          if (isString(value)) {
            const res = value.match(/^(?:(,|,\s+)?([a-z0-9.!#$%&'*+/=?^_`{|}~-]+(@[a-z0-9-]+)(?:\.[a-z0-9-]+)+))+$/i)

            return res !== null
          }

          return true
        },
        errorMessage: () => t('emailList'),
      },
      fieldArrayIncludes: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (!options.includes) {
            throw new Error("Option 'includes' must be provided in the fieldArrayIncludes validator")
          }
          if (!options.childFieldName) {
            throw new Error("Options 'childFieldName' must be provided in the fieldArrayIncludes validator")
          }

          const fieldArray = value

          if (fieldArray && isArray(fieldArray)) {
            for (const field of fieldArray) {
              const value = field[options.childFieldName]
              if (options.deepEquality) {
                if (isEqual(options.includes, value)) {
                  return true
                }
              } else if (options.includes === value) {
                return true
              }
            }
          }

          return false
        },
        errorMessage: (options: IFieldOptions) => options.errorMessage ?? null,
      },
      greaterThan: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (isNil(options.compareValue)) {
            throw new Error("Option 'compareValue' must be provided in the greaterThan validator")
          }

          const numberValue = toNumber(value)
          const comparedNumberValue = toNumber(options.compareValue)

          return !isNil(options.compareValue) && numberValue > comparedNumberValue
        },
        errorMessage: (options: IFieldOptions) =>
          t('greaterThan', { comparison: options.compareText || options.compareValue }),
      },
      greaterThanGroup: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.group || isNil(options.compareValue)) {
            throw new Error("Options 'group' and 'compareValue' must be provided in the greaterThanGroup validator")
          }

          const comparedNumberValue = toNumber(options.compareValue)

          const groupResults = options.group.map((fieldName) => {
            const numberValue = toNumber(fieldValues[fieldName])

            return !isNil(options.compareValue) && numberValue > comparedNumberValue
          })

          return groupResults.includes(true)
        },
        errorMessage: (options: IFieldOptions) =>
          t('greaterThanGroup', { comparison: options.compareText || options.compareValue }),
      },
      greaterThanZero: {
        validate: (value: unknown) => {
          if (isNil(value)) {
            return true
          }

          const numberValue = toNumber(value)

          return numberValue > 0
        },
        errorMessage: () => t('greaterThanZero'),
      },
      greaterThanOrEqualToZero: {
        validate: (value: unknown) => {
          const numberValue = toNumber(value)

          return numberValue >= 0
        },
        errorMessage: () => t('greaterThanOrEqualToZero'),
      },
      in: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (!options.haystack) {
            throw new Error("Options 'haystack' must be provided in the 'in' validator")
          }

          return options.haystack.includes(value)
        },
        errorMessage: () => t('in'),
      },
      integer: {
        validate: (value: unknown) => {
          const valueString = toString(value)

          return isEmpty(valueString) || isInt(valueString)
        },
        errorMessage: () => t('integer'),
      },
      isBeforeDateField: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isBeforeDateField validator")
          }

          const dateFieldValue = fieldValues[options.dateField]

          if (isNil(value) || isNil(dateFieldValue)) {
            return true
          }

          const valueString = isMoment(value) ? value.toISOString() : (value as string)
          const dateFieldValueString = isMoment(dateFieldValue)
            ? dateFieldValue.toISOString()
            : (dateFieldValue as string)

          return isBefore(valueString, dateFieldValueString)
        },
        errorMessage: (options: IFieldOptions, value: unknown, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isBeforeDateField validator")
          }

          const dateFieldValue = fieldValues[options.dateField]
          const comparison = isMoment(dateFieldValue) ? dateFieldValue.format('DD/MM/YYYY') : options.dateFieldName

          return t('isBeforeDateField', { comparison, defaultValue: 'This date must be before {{-comparison}}' })
        },
      },
      isAfterDateField: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isAfterDateField validator")
          }

          const dateFieldValue = fieldValues[options.dateField]

          if (isNil(value) || isNil(dateFieldValue)) {
            return true
          }

          const valueString = isMoment(value) ? value.toISOString() : (value as string)
          const dateFieldValueString = isMoment(dateFieldValue)
            ? dateFieldValue.toISOString()
            : (dateFieldValue as string)

          return isAfter(valueString, dateFieldValueString)
        },
        errorMessage: (options: IFieldOptions, value: unknown, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isAfterDateField validator")
          }

          const dateFieldValue = fieldValues[options.dateField]
          const comparison = isMoment(dateFieldValue) ? dateFieldValue.format('DD/MM/YYYY') : options.dateFieldName

          return t('isAfterDateField', { comparison })
        },
      },
      isSameOrAfterDateField: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isSameOrAfterDateField validator")
          }

          const dateFieldValue = get(fieldValues, options.dateField)

          if (isNil(value) || isNil(dateFieldValue)) {
            return true
          }
          const valueString = isMoment(value) ? value.toISOString() : (value as string)
          const dateFieldValueString = isMoment(dateFieldValue)
            ? dateFieldValue.toISOString()
            : (dateFieldValue as string)

          return isSameOrAfter(valueString, dateFieldValueString)
        },
        errorMessage: (options: IFieldOptions, value: unknown, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isSameOrAfterDateField validator")
          }

          const dateFieldValue = get(fieldValues, options.dateField)
          const comparison = isMoment(dateFieldValue) ? dateFieldValue.format('DD/MM/YYYY') : options.dateFieldName

          return t('isSameOrAfterDateField', { comparison })
        },
      },
      isSameOrBeforeDateField: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isBeforeOrEqualToDateField validator")
          }

          const dateFieldValue = get(fieldValues, options.dateField)

          if (isNil(value) || isNil(dateFieldValue)) {
            return true
          }

          const valueString = isMoment(value) ? value.toISOString() : (value as string)
          const dateFieldValueString = isMoment(dateFieldValue)
            ? dateFieldValue.toISOString()
            : (dateFieldValue as string)

          return isSameOrBefore(valueString, dateFieldValueString)
        },
        errorMessage: (options: IFieldOptions, value: unknown, fieldValues: IFieldValues) => {
          if (!options.dateField) {
            throw new Error("Options 'dateField' must be provided in the isBeforeOrEqualToDateField validator")
          }

          const dateFieldValue = get(fieldValues, options.dateField)
          const comparison = isMoment(dateFieldValue) ? dateFieldValue.format('DD/MM/YYYY') : options.dateFieldName

          return t('isSameOrBeforeDateField', { comparison })
        },
      },
      isSameOrAfterToday: {
        validate: (value: unknown) => {
          return isString(value) && isSameOrAfter(value, currentISODate, 'date')
        },
        errorMessage: () => {
          const comparison = moment().format('DD/MM/YYYY')
          return t('isSameOrAfterDateField', { comparison })
        },
      },
      isAfterNow: {
        validate: (value: unknown) => {
          return isString(value) && isAfter(value, currentISODate)
        },
        errorMessage: () => {
          const comparison = moment().format(`${dateFormatValue} ${timeFormatValue}`)
          return t('isAfterDateField', { comparison })
        },
      },
      lessThan: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (isNil(options.compareValue)) {
            throw new Error("Option 'compareValue' must be provided in the lessThan validator")
          }

          const numberValue = toNumber(value)
          const comparedNumberValue = toNumber(options.compareValue)

          return !isNil(options.compareValue) && numberValue < comparedNumberValue
        },
        errorMessage: (options: IFieldOptions) =>
          t('lessThan', {
            comparison: options.compareText || options.compareValue,
            defaultValue: 'This must be less than {{comparison}}',
          }),
      },
      lessThanOrEqualTo: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (isNil(options.compareValue)) {
            throw new Error("Option 'compareValue' must be provided in the lessThanOrEqualTo validator")
          }

          const numberValue = toNumber(value)
          const comparedNumberValue = toNumber(options.compareValue)

          return (!isNil(options.compareValue) && numberValue <= comparedNumberValue) || numberValue === 0
        },
        errorMessage: (options: IFieldOptions) =>
          t('lessThanOrEqualTo', {
            comparison:
              options.compareText || (options.compareValue && toNumber(options.compareValue) < 0)
                ? 0
                : options.compareValue,
          }),
      },
      lowerCaseSnake: {
        validate: (value: unknown) => {
          if (isString(value)) {
            return lowerCaseSnakeRegex.test(value)
          }

          return true
        },
        errorMessage: () => t('lowerCaseSnake'),
      },
      match: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.matchField || !options.matchFieldErrorName) {
            throw new Error("Option 'matchField' and 'matchFieldErrorName' must be provided in the match validator")
          }

          return value === fieldValues[options.matchField]
        },
        errorMessage: (options: IFieldOptions) => t('match', { comparison: options.matchFieldErrorName }),
      },
      maxLength: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (!options.maxLength) {
            throw new Error("Option 'maxLength' must be provided in the maxLength validator")
          }

          if ((isString(value) || isArray(value)) && !options.commaSeparatedList) {
            return value.length <= options.maxLength
          } else if (isString(value) && options.commaSeparatedList === true) {
            return value.trim().split(/,|,\s+/i).length <= options.maxLength
          }

          return true
        },
        errorMessage: (options: IFieldOptions) => t('maxLength', { count: options.maxLength }),
      },
      minLength: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (!options.minLength) {
            throw new Error("Option 'minLength' must be provided in the minLength validator")
          }

          if (isString(value) || isArray(value)) {
            return value.length >= options.minLength
          }

          return true
        },
        errorMessage: (options: IFieldOptions) =>
          t('minLength', {
            count: options.minLength,
          }),
      },
      notEqual: {
        validate: (value: unknown, options: IFieldOptions) => !isNil(value) && value !== options.notEqualValue,
        errorMessage: (options: IFieldOptions) => t('notEqual', { comparison: options.notEqualValue }),
      },
      notEqualNumber: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (isNil(value) || isNil(options.notEqualValue)) {
            return true
          }

          if (typeof value === 'number' && typeof options.notEqualValue === 'number') {
            return value !== options.notEqualValue
          }

          if (typeof value !== 'string' || typeof options.notEqualValue !== 'string') {
            return true
          }

          if (isNaN(parseFloat(value)) || isNaN(parseFloat(options.notEqualValue))) {
            return true
          }

          return parseFloat(value) !== parseFloat(options.notEqualValue)
        },
        errorMessage: (options: IFieldOptions) => t('notEqualNumber', { comparison: options.notEqualValue }),
      },
      phoneNumber: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.countryField) {
            throw new Error("Option 'countryField' must be provided in the phoneNumber validator")
          }

          const countryId = get(fieldValues, options.countryField)

          if (!isString(countryId) || !isString(value)) {
            return true
          }

          if (value === '') {
            return true
          }

          return value.match(/^\+?[0-9\s()-]*$/)
        },
        errorMessage: () => t('phoneNumber'),
      },
      required: {
        validate: (value: unknown) => {
          if (isString(value)) {
            return !!value.trim()
          }

          if (isArray(value)) {
            return value.length !== 0
          }

          return !isNil(value)
        },
        errorMessage: () => t('required', { defaultValue: 'This is required' }),
      },
      requireGroup: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.group) {
            throw new Error("Option 'group' must be provided in the requireGroup validator")
          }

          const groupResults = options.group.map((fieldName) => {
            const fieldValue = fieldValues[fieldName]

            if (isString(fieldValue)) {
              return !!fieldValue.trim()
            }

            if (isArray(value)) {
              return fieldValue !== 0
            }

            return !isNil(fieldValues[fieldName])
          })

          return groupResults.includes(true)
        },
        errorMessage: () => t('requireGroup'),
      },
      requiredFieldArray: {
        validate: (value: unknown) => value && isArray(value) && value.length > 0,
        errorMessage: () => {
          return { _error: 'At least one is required' }
        },
      },
      uniqueChildInFieldArray: {
        validate: (value: unknown, options: IFieldOptions, fieldValues: IFieldValues) => {
          if (!options.fieldArrayName || !options.childFieldName) {
            throw new Error(
              "Options 'fieldArrayName' and 'childFieldName' must be provided in the uniqueChildInFieldArray validator",
            )
          }

          const fieldArray = fieldValues[options.fieldArrayName]

          if (fieldArray && isArray(fieldArray)) {
            let count = 0

            for (const childField of fieldArray) {
              const childValue = get(childField, options.childFieldName)
              if (typeof childField === 'object' && childField && childValue) {
                if (options.deepEquality) {
                  if (isEqual(childValue, value)) {
                    count++
                  }
                } else if (childValue === value) {
                  count++
                }
              }
            }

            return count <= 1
          }

          return true
        },
        errorMessage: () => t('uniqueChildInFieldArray', { defaultValue: 'This input should be unique' }),
      },
      url: {
        validate: (value: unknown) => {
          if (isString(value)) {
            // See https://mathiasbynens.be/demo/url-regex; uses @stephenhay's version
            // Enforces protocol exists, must be http/https/ftp
            // Please add a separate validator if you want "www.google.com" to pass
            const res = value.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)

            return res !== null
          }

          return true
        },
        errorMessage: () => t('url'),
      },
      tfnIsValid: {
        validate: (value: unknown) => {
          const acceptedTFN = ['000000000', '111111111', '333333333', '444444444']
          const valueString = toString(value)
          if (valueString.length !== 9) {
            return false
          }
          if (acceptedTFN.includes(valueString)) {
            return true
          }
          const weight = [1, 4, 3, 7, 5, 8, 6, 9, 10]
          const digits = [...valueString]
          // check if the modulo of the sum is giving us 11;
          let sum = 0
          digits.forEach((digit, index) => {
            sum = sum + Number.parseInt(digit) * weight[index]
          })
          return sum % 11 === 0
        },
        errorMessage: () =>
          tIntegrations('tfnInvalid', { defaultValue: 'You should use a valid TFN number of 9 digits' }),
      },
      isRegex: {
        validate: (value: unknown) => {
          let isValid = true
          try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new RegExp(value as any)
          } catch (e) {
            isValid = false
          }
          return isValid
        },
        errorMessage: () => t('isRegex'),
      },
      regex: {
        validate: (value: unknown, options: IFieldOptions) => {
          if (!options.regexPattern) {
            throw new Error("Options 'regexPattern' must be provided in the regex validator")
          }
          const match = new RegExp(options.regexPattern)
          return match.test(value as string)
        },
        errorMessage: () => t('failRegex'),
      },
    }),
    [
      t,
      tIntegrations,
      currentISODate,
      isAfter,
      isBefore,
      isSameOrBefore,
      isSameOrAfter,
      isValid,
      dateFormatValue,
      timeFormatValue,
    ],
  )

  return { ruleSet }
}

export { useRuleSet, emailRegex }
