import MiniSearch, { IOptions } from 'minisearch'
import React, { createContext, useMemo, useContext, useEffect } from 'react'
import { LocalizationContext } from 'src/services/i18n/LocalizationProvider'
import { PageKey } from '../entities/types'
import type { IDocument } from '../entities/types'
import { AnalyticsPagesContext } from '../entities'
import { Page } from '../entities/pages'

const SPACE_OR_PUNCTUATION = /[ -#%-*,-/:;?@[-\]_{}]+/g

const STOP_WORDS = {
  en: new Set([
    'a',
    'about',
    'above',
    'after',
    'again',
    'against',
    'all',
    'am',
    'an',
    'and',
    'any',
    'are',
    "aren't",
    'as',
    'at',
    'be',
    'because',
    'been',
    'before',
    'being',
    'below',
    'between',
    'both',
    'but',
    'by',
    "can't",
    'cannot',
    'could',
    "couldn't",
    'did',
    "didn't",
    'do',
    'does',
    "doesn't",
    'doing',
    "don't",
    'down',
    'during',
    'each',
    'few',
    'for',
    'from',
    'further',
    'had',
    "hadn't",
    'has',
    "hasn't",
    'have',
    "haven't",
    'having',
    'he',
    "he'd",
    "he'll",
    "he's",
    'her',
    'here',
    "here's",
    'hers',
    'herself',
    'him',
    'himself',
    'his',
    'how',
    "how's",
    'i',
    "i'd",
    "i'll",
    "i'm",
    "i've",
    'if',
    'in',
    'into',
    'is',
    "isn't",
    'it',
    "it's",
    'its',
    'itself',
    "let's",
    'me',
    'more',
    'most',
    "mustn't",
    'my',
    'myself',
    'no',
    'nor',
    'not',
    'of',
    'off',
    'on',
    'once',
    'only',
    'or',
    'other',
    'ought',
    'our',
    'ours',
    'ourselves',
    'out',
    'over',
    'own',
    'same',
    "shan't",
    'she',
    "she'd",
    "she'll",
    "she's",
    'should',
    "shouldn't",
    'so',
    'some',
    'such',
    'than',
    'that',
    "that's",
    'the',
    'their',
    'theirs',
    'them',
    'themselves',
    'then',
    'there',
    "there's",
    'these',
    'they',
    "they'd",
    "they'll",
    "they're",
    "they've",
    'this',
    'those',
    'through',
    'to',
    'too',
    'under',
    'until',
    'up',
    'very',
    'was',
    "wasn't",
    'we',
    "we'd",
    "we'll",
    "we're",
    "we've",
    'were',
    "weren't",
    'what',
    "what's",
    'when',
    "when's",
    'where',
    "where's",
    'which',
    'while',
    'who',
    "who's",
    'whom',
    'why',
    "why's",
    'with',
    "won't",
    'would',
    "wouldn't",
    'you',
    "you'd",
    "you'll",
    "you're",
    "you've",
    'your',
    'yours',
    'yourself',
    'yourselves',
  ]),
  'fr-CA': new Set([
    'a',
    'abord',
    'absolument',
    'afin',
    'ah',
    'ai',
    'aie',
    'aient',
    'aies',
    'ailleurs',
    'ainsi',
    'ait',
    'allaient',
    'allo',
    'allons',
    'allô',
    'alors',
    'anterieur',
    'anterieure',
    'anterieures',
    'apres',
    'après',
    'as',
    'assez',
    'attendu',
    'au',
    'aucun',
    'aucune',
    'aucuns',
    'aujourd',
    "aujourd'hui",
    'aupres',
    'auquel',
    'aura',
    'aurai',
    'auraient',
    'aurais',
    'aurait',
    'auras',
    'aurez',
    'auriez',
    'aurions',
    'aurons',
    'auront',
    'aussi',
    'autant',
    'autre',
    'autrefois',
    'autrement',
    'autres',
    'autrui',
    'aux',
    'auxquelles',
    'auxquels',
    'avaient',
    'avais',
    'avait',
    'avant',
    'avec',
    'avez',
    'aviez',
    'avions',
    'avoir',
    'avons',
    'ayant',
    'ayez',
    'ayons',
    'b',
    'bah',
    'bas',
    'basee',
    'bat',
    'beau',
    'beaucoup',
    'bien',
    'bigre',
    'bon',
    'boum',
    'bravo',
    'brrr',
    'c',
    'car',
    'ce',
    'ceci',
    'cela',
    'celle',
    'celle-ci',
    'celle-là',
    'celles',
    'celles-ci',
    'celles-là',
    'celui',
    'celui-ci',
    'celui-là',
    'celà',
    'cent',
    'cependant',
    'certain',
    'certaine',
    'certaines',
    'certains',
    'certes',
    'ces',
    'cet',
    'cette',
    'ceux',
    'ceux-ci',
    'ceux-là',
    'chacun',
    'chacune',
    'chaque',
    'cher',
    'chers',
    'chez',
    'chiche',
    'chut',
    'chère',
    'chères',
    'ci',
    'cinq',
    'cinquantaine',
    'cinquante',
    'cinquantième',
    'cinquième',
    'clac',
    'clic',
    'combien',
    'comme',
    'comment',
    'comparable',
    'comparables',
    'compris',
    'concernant',
    'contre',
    'couic',
    'crac',
    'd',
    'da',
    'dans',
    'de',
    'debout',
    'dedans',
    'dehors',
    'deja',
    'delà',
    'depuis',
    'dernier',
    'derniere',
    'derriere',
    'derrière',
    'des',
    'desormais',
    'desquelles',
    'desquels',
    'dessous',
    'dessus',
    'deux',
    'deuxième',
    'deuxièmement',
    'devant',
    'devers',
    'devra',
    'devrait',
    'different',
    'differentes',
    'differents',
    'différent',
    'différente',
    'différentes',
    'différents',
    'dire',
    'directe',
    'directement',
    'dit',
    'dite',
    'dits',
    'divers',
    'diverse',
    'diverses',
    'dix',
    'dix-huit',
    'dix-neuf',
    'dix-sept',
    'dixième',
    'doit',
    'doivent',
    'donc',
    'dont',
    'dos',
    'douze',
    'douzième',
    'dring',
    'droite',
    'du',
    'duquel',
    'durant',
    'dès',
    'début',
    'désormais',
    'e',
    'effet',
    'egale',
    'egalement',
    'egales',
    'eh',
    'elle',
    'elle-même',
    'elles',
    'elles-mêmes',
    'en',
    'encore',
    'enfin',
    'entre',
    'envers',
    'environ',
    'es',
    'essai',
    'est',
    'et',
    'etant',
    'etc',
    'etre',
    'eu',
    'eue',
    'eues',
    'euh',
    'eurent',
    'eus',
    'eusse',
    'eussent',
    'eusses',
    'eussiez',
    'eussions',
    'eut',
    'eux',
    'eux-mêmes',
    'exactement',
    'excepté',
    'extenso',
    'exterieur',
    'eûmes',
    'eût',
    'eûtes',
    'f',
    'fais',
    'faisaient',
    'faisant',
    'fait',
    'faites',
    'façon',
    'feront',
    'fi',
    'flac',
    'floc',
    'fois',
    'font',
    'force',
    'furent',
    'fus',
    'fusse',
    'fussent',
    'fusses',
    'fussiez',
    'fussions',
    'fut',
    'fûmes',
    'fût',
    'fûtes',
    'g',
    'gens',
    'h',
    'ha',
    'haut',
    'hein',
    'hem',
    'hep',
    'hi',
    'ho',
    'holà',
    'hop',
    'hormis',
    'hors',
    'hou',
    'houp',
    'hue',
    'hui',
    'huit',
    'huitième',
    'hum',
    'hurrah',
    'hé',
    'hélas',
    'i',
    'ici',
    'il',
    'ils',
    'importe',
    'j',
    'je',
    'jusqu',
    'jusque',
    'juste',
    'k',
    'l',
    'la',
    'laisser',
    'laquelle',
    'las',
    'le',
    'lequel',
    'les',
    'lesquelles',
    'lesquels',
    'leur',
    'leurs',
    'longtemps',
    'lors',
    'lorsque',
    'lui',
    'lui-meme',
    'lui-même',
    'là',
    'lès',
    'm',
    'ma',
    'maint',
    'maintenant',
    'mais',
    'malgre',
    'malgré',
    'maximale',
    'me',
    'meme',
    'memes',
    'merci',
    'mes',
    'mien',
    'mienne',
    'miennes',
    'miens',
    'mille',
    'mince',
    'mine',
    'minimale',
    'moi',
    'moi-meme',
    'moi-même',
    'moindres',
    'moins',
    'mon',
    'mot',
    'moyennant',
    'multiple',
    'multiples',
    'même',
    'mêmes',
    'n',
    'na',
    'naturel',
    'naturelle',
    'naturelles',
    'ne',
    'neanmoins',
    'necessaire',
    'necessairement',
    'neuf',
    'neuvième',
    'ni',
    'nombreuses',
    'nombreux',
    'nommés',
    'non',
    'nos',
    'notamment',
    'notre',
    'nous',
    'nous-mêmes',
    'nouveau',
    'nouveaux',
    'nul',
    'néanmoins',
    'nôtre',
    'nôtres',
    'o',
    'oh',
    'ohé',
    'ollé',
    'olé',
    'on',
    'ont',
    'onze',
    'onzième',
    'ore',
    'ou',
    'ouf',
    'ouias',
    'oust',
    'ouste',
    'outre',
    'ouvert',
    'ouverte',
    'ouverts',
    'o|',
    'où',
    'p',
    'paf',
    'pan',
    'par',
    'parce',
    'parfois',
    'parle',
    'parlent',
    'parler',
    'parmi',
    'parole',
    'parseme',
    'partant',
    'particulier',
    'particulière',
    'particulièrement',
    'pas',
    'passé',
    'pendant',
    'pense',
    'permet',
    'personne',
    'personnes',
    'peu',
    'peut',
    'peuvent',
    'peux',
    'pff',
    'pfft',
    'pfut',
    'pif',
    'pire',
    'pièce',
    'plein',
    'plouf',
    'plupart',
    'plus',
    'plusieurs',
    'plutôt',
    'possessif',
    'possessifs',
    'possible',
    'possibles',
    'pouah',
    'pour',
    'pourquoi',
    'pourrais',
    'pourrait',
    'pouvait',
    'prealable',
    'precisement',
    'premier',
    'première',
    'premièrement',
    'pres',
    'probable',
    'probante',
    'procedant',
    'proche',
    'près',
    'psitt',
    'pu',
    'puis',
    'puisque',
    'pur',
    'pure',
    'q',
    'qu',
    'quand',
    'quant',
    'quant-à-soi',
    'quanta',
    'quarante',
    'quatorze',
    'quatre',
    'quatre-vingt',
    'quatrième',
    'quatrièmement',
    'que',
    'quel',
    'quelconque',
    'quelle',
    'quelles',
    "quelqu'un",
    'quelque',
    'quelques',
    'quels',
    'qui',
    'quiconque',
    'quinze',
    'quoi',
    'quoique',
    'r',
    'rare',
    'rarement',
    'rares',
    'relative',
    'relativement',
    'remarquable',
    'rend',
    'rendre',
    'restant',
    'reste',
    'restent',
    'restrictif',
    'retour',
    'revoici',
    'revoilà',
    'rien',
    's',
    'sa',
    'sacrebleu',
    'sait',
    'sans',
    'sapristi',
    'sauf',
    'se',
    'sein',
    'seize',
    'selon',
    'semblable',
    'semblaient',
    'semble',
    'semblent',
    'sent',
    'sept',
    'septième',
    'sera',
    'serai',
    'seraient',
    'serais',
    'serait',
    'seras',
    'serez',
    'seriez',
    'serions',
    'serons',
    'seront',
    'ses',
    'seul',
    'seule',
    'seulement',
    'si',
    'sien',
    'sienne',
    'siennes',
    'siens',
    'sinon',
    'six',
    'sixième',
    'soi',
    'soi-même',
    'soient',
    'sois',
    'soit',
    'soixante',
    'sommes',
    'son',
    'sont',
    'sous',
    'souvent',
    'soyez',
    'soyons',
    'specifique',
    'specifiques',
    'speculatif',
    'stop',
    'strictement',
    'subtiles',
    'suffisant',
    'suffisante',
    'suffit',
    'suis',
    'suit',
    'suivant',
    'suivante',
    'suivantes',
    'suivants',
    'suivre',
    'sujet',
    'superpose',
    'sur',
    'surtout',
    't',
    'ta',
    'tac',
    'tandis',
    'tant',
    'tardive',
    'te',
    'tel',
    'telle',
    'tellement',
    'telles',
    'tels',
    'tenant',
    'tend',
    'tenir',
    'tente',
    'tes',
    'tic',
    'tien',
    'tienne',
    'tiennes',
    'tiens',
    'toc',
    'toi',
    'toi-même',
    'ton',
    'touchant',
    'toujours',
    'tous',
    'tout',
    'toute',
    'toutefois',
    'toutes',
    'treize',
    'trente',
    'tres',
    'trois',
    'troisième',
    'troisièmement',
    'trop',
    'très',
    'tsoin',
    'tsouin',
    'tu',
    'té',
    'u',
    'un',
    'une',
    'unes',
    'uniformement',
    'unique',
    'uniques',
    'uns',
    'v',
    'va',
    'vais',
    'valeur',
    'vas',
    'vers',
    'via',
    'vif',
    'vifs',
    'vingt',
    'vivat',
    'vive',
    'vives',
    'vlan',
    'voici',
    'voie',
    'voient',
    'voilà',
    'voire',
    'vont',
    'vos',
    'votre',
    'vous',
    'vous-mêmes',
    'vu',
    'vé',
    'vôtre',
    'vôtres',
    'w',
    'x',
    'y',
    'z',
    'zut',
    'à',
    'â',
    'ça',
    'ès',
    'étaient',
    'étais',
    'était',
    'étant',
    'état',
    'étiez',
    'étions',
    'été',
    'étée',
    'étées',
    'étés',
    'êtes',
    'être',
    'ô',
  ]),
  es: new Set([
    'a',
    'actualmente',
    'acuerdo',
    'adelante',
    'ademas',
    'además',
    'adrede',
    'afirmó',
    'agregó',
    'ahi',
    'ahora',
    'ahí',
    'al',
    'algo',
    'alguna',
    'algunas',
    'alguno',
    'algunos',
    'algún',
    'alli',
    'allí',
    'alrededor',
    'ambos',
    'ampleamos',
    'antano',
    'antaño',
    'ante',
    'anterior',
    'antes',
    'apenas',
    'aproximadamente',
    'aquel',
    'aquella',
    'aquellas',
    'aquello',
    'aquellos',
    'aqui',
    'aquél',
    'aquélla',
    'aquéllas',
    'aquéllos',
    'aquí',
    'arriba',
    'arribaabajo',
    'aseguró',
    'asi',
    'así',
    'atras',
    'aun',
    'aunque',
    'ayer',
    'añadió',
    'aún',
    'b',
    'bajo',
    'bastante',
    'bien',
    'breve',
    'buen',
    'buena',
    'buenas',
    'bueno',
    'buenos',
    'c',
    'cada',
    'casi',
    'cerca',
    'cierta',
    'ciertas',
    'cierto',
    'ciertos',
    'cinco',
    'claro',
    'comentó',
    'como',
    'con',
    'conmigo',
    'conocer',
    'conseguimos',
    'conseguir',
    'considera',
    'consideró',
    'consigo',
    'consigue',
    'consiguen',
    'consigues',
    'contigo',
    'contra',
    'cosas',
    'creo',
    'cual',
    'cuales',
    'cualquier',
    'cuando',
    'cuanta',
    'cuantas',
    'cuanto',
    'cuantos',
    'cuatro',
    'cuenta',
    'cuál',
    'cuáles',
    'cuándo',
    'cuánta',
    'cuántas',
    'cuánto',
    'cuántos',
    'cómo',
    'd',
    'da',
    'dado',
    'dan',
    'dar',
    'de',
    'debajo',
    'debe',
    'deben',
    'debido',
    'decir',
    'dejó',
    'del',
    'delante',
    'demasiado',
    'demás',
    'dentro',
    'deprisa',
    'desde',
    'despacio',
    'despues',
    'después',
    'detras',
    'detrás',
    'dia',
    'dias',
    'dice',
    'dicen',
    'dicho',
    'dieron',
    'diferente',
    'diferentes',
    'dijeron',
    'dijo',
    'dio',
    'donde',
    'dos',
    'durante',
    'día',
    'días',
    'dónde',
    'e',
    'ejemplo',
    'el',
    'ella',
    'ellas',
    'ello',
    'ellos',
    'embargo',
    'empleais',
    'emplean',
    'emplear',
    'empleas',
    'empleo',
    'en',
    'encima',
    'encuentra',
    'enfrente',
    'enseguida',
    'entonces',
    'entre',
    'era',
    'erais',
    'eramos',
    'eran',
    'eras',
    'eres',
    'es',
    'esa',
    'esas',
    'ese',
    'eso',
    'esos',
    'esta',
    'estaba',
    'estabais',
    'estaban',
    'estabas',
    'estad',
    'estada',
    'estadas',
    'estado',
    'estados',
    'estais',
    'estamos',
    'estan',
    'estando',
    'estar',
    'estaremos',
    'estará',
    'estarán',
    'estarás',
    'estaré',
    'estaréis',
    'estaría',
    'estaríais',
    'estaríamos',
    'estarían',
    'estarías',
    'estas',
    'este',
    'estemos',
    'esto',
    'estos',
    'estoy',
    'estuve',
    'estuviera',
    'estuvierais',
    'estuvieran',
    'estuvieras',
    'estuvieron',
    'estuviese',
    'estuvieseis',
    'estuviesen',
    'estuvieses',
    'estuvimos',
    'estuviste',
    'estuvisteis',
    'estuviéramos',
    'estuviésemos',
    'estuvo',
    'está',
    'estábamos',
    'estáis',
    'están',
    'estás',
    'esté',
    'estéis',
    'estén',
    'estés',
    'ex',
    'excepto',
    'existe',
    'existen',
    'explicó',
    'expresó',
    'f',
    'fin',
    'final',
    'fue',
    'fuera',
    'fuerais',
    'fueran',
    'fueras',
    'fueron',
    'fuese',
    'fueseis',
    'fuesen',
    'fueses',
    'fui',
    'fuimos',
    'fuiste',
    'fuisteis',
    'fuéramos',
    'fuésemos',
    'g',
    'general',
    'gran',
    'grandes',
    'gueno',
    'h',
    'ha',
    'haber',
    'habia',
    'habida',
    'habidas',
    'habido',
    'habidos',
    'habiendo',
    'habla',
    'hablan',
    'habremos',
    'habrá',
    'habrán',
    'habrás',
    'habré',
    'habréis',
    'habría',
    'habríais',
    'habríamos',
    'habrían',
    'habrías',
    'habéis',
    'había',
    'habíais',
    'habíamos',
    'habían',
    'habías',
    'hace',
    'haceis',
    'hacemos',
    'hacen',
    'hacer',
    'hacerlo',
    'haces',
    'hacia',
    'haciendo',
    'hago',
    'han',
    'has',
    'hasta',
    'hay',
    'haya',
    'hayamos',
    'hayan',
    'hayas',
    'hayáis',
    'he',
    'hecho',
    'hemos',
    'hicieron',
    'hizo',
    'horas',
    'hoy',
    'hube',
    'hubiera',
    'hubierais',
    'hubieran',
    'hubieras',
    'hubieron',
    'hubiese',
    'hubieseis',
    'hubiesen',
    'hubieses',
    'hubimos',
    'hubiste',
    'hubisteis',
    'hubiéramos',
    'hubiésemos',
    'hubo',
    'i',
    'igual',
    'incluso',
    'indicó',
    'informo',
    'informó',
    'intenta',
    'intentais',
    'intentamos',
    'intentan',
    'intentar',
    'intentas',
    'intento',
    'ir',
    'j',
    'junto',
    'k',
    'l',
    'la',
    'lado',
    'largo',
    'las',
    'le',
    'lejos',
    'les',
    'llegó',
    'lleva',
    'llevar',
    'lo',
    'los',
    'luego',
    'lugar',
    'm',
    'mal',
    'manera',
    'manifestó',
    'mas',
    'mayor',
    'me',
    'mediante',
    'medio',
    'mejor',
    'mencionó',
    'menos',
    'menudo',
    'mi',
    'mia',
    'mias',
    'mientras',
    'mio',
    'mios',
    'mis',
    'misma',
    'mismas',
    'mismo',
    'mismos',
    'modo',
    'momento',
    'mucha',
    'muchas',
    'mucho',
    'muchos',
    'muy',
    'más',
    'mí',
    'mía',
    'mías',
    'mío',
    'míos',
    'n',
    'nada',
    'nadie',
    'ni',
    'ninguna',
    'ningunas',
    'ninguno',
    'ningunos',
    'ningún',
    'no',
    'nos',
    'nosotras',
    'nosotros',
    'nuestra',
    'nuestras',
    'nuestro',
    'nuestros',
    'nueva',
    'nuevas',
    'nuevo',
    'nuevos',
    'nunca',
    'o',
    'ocho',
    'os',
    'otra',
    'otras',
    'otro',
    'otros',
    'p',
    'pais',
    'para',
    'parece',
    'parte',
    'partir',
    'pasada',
    'pasado',
    'paìs',
    'peor',
    'pero',
    'pesar',
    'poca',
    'pocas',
    'poco',
    'pocos',
    'podeis',
    'podemos',
    'poder',
    'podria',
    'podriais',
    'podriamos',
    'podrian',
    'podrias',
    'podrá',
    'podrán',
    'podría',
    'podrían',
    'poner',
    'por',
    'por qué',
    'porque',
    'posible',
    'primer',
    'primera',
    'primero',
    'primeros',
    'principalmente',
    'pronto',
    'propia',
    'propias',
    'propio',
    'propios',
    'proximo',
    'próximo',
    'próximos',
    'pudo',
    'pueda',
    'puede',
    'pueden',
    'puedo',
    'pues',
    'q',
    'qeu',
    'que',
    'quedó',
    'queremos',
    'quien',
    'quienes',
    'quiere',
    'quiza',
    'quizas',
    'quizá',
    'quizás',
    'quién',
    'quiénes',
    'qué',
    'r',
    'raras',
    'realizado',
    'realizar',
    'realizó',
    'repente',
    'respecto',
    's',
    'sabe',
    'sabeis',
    'sabemos',
    'saben',
    'saber',
    'sabes',
    'sal',
    'salvo',
    'se',
    'sea',
    'seamos',
    'sean',
    'seas',
    'segun',
    'segunda',
    'segundo',
    'según',
    'seis',
    'ser',
    'sera',
    'seremos',
    'será',
    'serán',
    'serás',
    'seré',
    'seréis',
    'sería',
    'seríais',
    'seríamos',
    'serían',
    'serías',
    'seáis',
    'señaló',
    'si',
    'sido',
    'siempre',
    'siendo',
    'siete',
    'sigue',
    'siguiente',
    'sin',
    'sino',
    'sobre',
    'sois',
    'sola',
    'solamente',
    'solas',
    'solo',
    'solos',
    'somos',
    'son',
    'soy',
    'soyos',
    'su',
    'supuesto',
    'sus',
    'suya',
    'suyas',
    'suyo',
    'suyos',
    'sé',
    'sí',
    'sólo',
    't',
    'tal',
    'tambien',
    'también',
    'tampoco',
    'tan',
    'tanto',
    'tarde',
    'te',
    'temprano',
    'tendremos',
    'tendrá',
    'tendrán',
    'tendrás',
    'tendré',
    'tendréis',
    'tendría',
    'tendríais',
    'tendríamos',
    'tendrían',
    'tendrías',
    'tened',
    'teneis',
    'tenemos',
    'tener',
    'tenga',
    'tengamos',
    'tengan',
    'tengas',
    'tengo',
    'tengáis',
    'tenida',
    'tenidas',
    'tenido',
    'tenidos',
    'teniendo',
    'tenéis',
    'tenía',
    'teníais',
    'teníamos',
    'tenían',
    'tenías',
    'tercera',
    'ti',
    'tiempo',
    'tiene',
    'tienen',
    'tienes',
    'toda',
    'todas',
    'todavia',
    'todavía',
    'todo',
    'todos',
    'total',
    'trabaja',
    'trabajais',
    'trabajamos',
    'trabajan',
    'trabajar',
    'trabajas',
    'trabajo',
    'tras',
    'trata',
    'través',
    'tres',
    'tu',
    'tus',
    'tuve',
    'tuviera',
    'tuvierais',
    'tuvieran',
    'tuvieras',
    'tuvieron',
    'tuviese',
    'tuvieseis',
    'tuviesen',
    'tuvieses',
    'tuvimos',
    'tuviste',
    'tuvisteis',
    'tuviéramos',
    'tuviésemos',
    'tuvo',
    'tuya',
    'tuyas',
    'tuyo',
    'tuyos',
    'tú',
    'u',
    'ultimo',
    'un',
    'una',
    'unas',
    'uno',
    'unos',
    'usa',
    'usais',
    'usamos',
    'usan',
    'usar',
    'usas',
    'uso',
    'usted',
    'ustedes',
    'v',
    'va',
    'vais',
    'valor',
    'vamos',
    'van',
    'varias',
    'varios',
    'vaya',
    'veces',
    'ver',
    'verdad',
    'verdadera',
    'verdadero',
    'vez',
    'vosotras',
    'vosotros',
    'voy',
    'vuestra',
    'vuestras',
    'vuestro',
    'vuestros',
    'w',
    'x',
    'y',
    'ya',
    'yo',
    'z',
    'él',
    'éramos',
    'ésa',
    'ésas',
    'ése',
    'ésos',
    'ésta',
    'éstas',
    'éste',
    'éstos',
    'última',
    'últimas',
    'último',
    'últimos',
  ]),
}

const createProcessTerm = (language: string) => {
  const stopWords: Set<string> = STOP_WORDS[language] ?? STOP_WORDS.en

  return (term: string) => {
    const cleaned = term.trim().toLowerCase()
    if (stopWords.has(cleaned)) {
      return false
    }

    return cleaned
  }
}

const indexOptions: IOptions<IDocument, 'key'> = {
  idField: 'key',
  fields: ['title', 'categoryTitle', 'keywords'],
  extractField: (document: IDocument, fieldName: string) => {
    if (fieldName === 'keywords') {
      return document.keywords.join(',')
    }
    return document[fieldName]
  },
  tokenize: (doc: string, fieldName: string) => {
    if (fieldName === 'keywords') {
      return doc.split(',').filter((t) => t)
    }

    let tokenize = MiniSearch.getDefault('tokenize')
    if (!tokenize) {
      tokenize = (_doc) => _doc.split(SPACE_OR_PUNCTUATION)
    }

    let tokens = tokenize(doc, fieldName)

    if (fieldName === 'title' || fieldName === 'categoryTitle') {
      tokens = [...tokens, doc.trim()]
    }

    return tokens
  },
}

const searchOptions = {
  combineWith: 'OR',
  prefix: true,
  fuzzy: 0.4,
  boost: {
    title: 1,
    categoryTitle: 0.8,
    keywords: 0.5,
  },
}

type TMiniSearch = MiniSearch<IDocument, 'key'> & {
  deriveOptions: <T>(options: T) => T
}

const createMiniSearch = (language: string, options = indexOptions): TMiniSearch => {
  const processTerm = createProcessTerm(language)
  const search = new MiniSearch<IDocument, 'key'>({
    ...options,
    processTerm,
  })
  ;(search as TMiniSearch).deriveOptions = (originalOptions) => {
    return {
      ...originalOptions,
      processTerm,
    }
  }

  return search as TMiniSearch
}

const AnalyticsSearchContext = createContext<TMiniSearch>(createMiniSearch('en'))

const AnalyticsSearchProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    i18n: { language },
  } = useContext(LocalizationContext)
  const { pages, formDesigns } = useContext(AnalyticsPagesContext)

  const miniSearch = useMemo(() => {
    if (!language) {
      return null
    }
    return createMiniSearch(language)
  }, [language])

  const pageCollection = useMemo(
    () =>
      Object.entries(pages).reduce<IDocument[]>(
        (acc, [, page]: [PageKey, Page]) => [...acc, ...page.focuses.map((focus) => page.toDocument(focus))],
        [],
      ),
    [pages],
  )

  const formCollection = useMemo(
    () => Object.entries(formDesigns).map(([, formDesign]) => formDesign!.toDocument()),
    [formDesigns],
  )

  const collection = useMemo(() => [...pageCollection, ...formCollection], [pageCollection, formCollection])

  useEffect(() => {
    if (!miniSearch) {
      return
    }

    if (miniSearch.documentCount) {
      return
    }

    miniSearch.addAll(collection)
  }, [miniSearch, collection])

  if (!miniSearch) {
    return null
  }

  return <AnalyticsSearchContext.Provider value={miniSearch}>{children}</AnalyticsSearchContext.Provider>
}

export type { TMiniSearch }
export { createMiniSearch, searchOptions, AnalyticsSearchContext, AnalyticsSearchProvider }
