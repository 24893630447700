import React from 'react'
import { Spinner } from '@intellihr/blueberry'
import { IOnboardingPerson, useGetOnboardingFormInstance } from './hooks/useGetOnboardingFormInstanceAndPerson'
import { PersonalDetailsFormStepContent } from './PersonalDetailsFormStepContent'

interface IOnboardingFormDataProps {
  onboardingPerson?: IOnboardingPerson
  onboardingFormId: string
  onSubmit: () => void
}

const PersonalDetailsFormStep: React.FC<IOnboardingFormDataProps> = ({
  onboardingPerson,
  onboardingFormId,
  onSubmit,
}) => {
  const { data: onboardingFormInstance, loading: loadingOnboardingFormInstance } =
    useGetOnboardingFormInstance(onboardingFormId)

  if (loadingOnboardingFormInstance) {
    return <Spinner />
  }
  if (!onboardingFormInstance || !onboardingFormInstance.getOnboardingFormInstanceById) {
    return null
  }

  return (
    <PersonalDetailsFormStepContent
      onboardingPerson={onboardingPerson}
      onboardingFormInstance={onboardingFormInstance?.getOnboardingFormInstanceById}
      onSubmit={onSubmit}
    />
  )
}

export { PersonalDetailsFormStep }
