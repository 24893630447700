import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toastActions } from 'src/services/toasts'

function useToasts() {
  const dispatch = useDispatch()
  const postToast = useCallback(
    (...postToastArgs: Parameters<typeof toastActions.postToast>) => dispatch(toastActions.postToast(...postToastArgs)),
    [dispatch],
  )

  return postToast
}

export { useToasts }
