import React from 'react'
import { ModalNavigation } from '@intellihr/blueberry'
import { useNavigationItems } from 'src/services/layout/components/PageFrame/components/Navigation/useNavigationItems'

const MobileNavigation: React.FC = () => {
  const navigationData = useNavigationItems()

  if (!navigationData) {
    return <ModalNavigation items={[]} />
  }

  return (
    <ModalNavigation
      nativeApps={navigationData.nativeApps}
      externalApps={navigationData.externalApps}
      testId="mobile-navigation"
      logo={navigationData.logoUrl}
      items={[...navigationData.primaryItems, ...navigationData.secondaryItems]}
    />
  )
}

export { MobileNavigation }
