import axios from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { throttle } from 'lodash'
import { getStore } from 'src/services/store'
import { toastActions } from 'src/services/toasts'
import { cloudLogger } from 'src/services/error'

class SessionManagementService {
  private readonly onSessionExpiryLogout: () => void

  // tslint:disable-next-line:variable-name
  private _userId: string | undefined

  // tslint:disable-next-line:variable-name
  private _personId: string | undefined

  // tslint:disable-next-line:variable-name
  private _tenant: string | undefined

  // tslint:disable-next-line:variable-name
  private _sessionId: string | undefined

  // tslint:disable-next-line:variable-name
  private _isSystemAdministrator: boolean | undefined

  // tslint:disable-next-line:variable-name
  private _isSuperUser: boolean | undefined

  private loggingOut = false

  constructor(onSessionExpiryLogout: () => void) {
    this.onSessionExpiryLogout = onSessionExpiryLogout
  }

  public get userId() {
    if (!this._userId) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._userId
  }

  public get personId() {
    if (!this._personId) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._personId
  }

  public get tenant() {
    if (!this._tenant) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._tenant
  }

  public get sessionId() {
    if (!this._sessionId) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._sessionId
  }

  public get isSystemAdministrator() {
    if (this._isSystemAdministrator === undefined) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._isSystemAdministrator
  }

  public get isSuperUser() {
    if (this._isSuperUser === undefined) {
      throw new Error('SessionManagementService was not initialised before use')
    }
    return this._isSuperUser
  }

  public async initializeSession() {
    // short circuit if not logged in
    if (!Cookies.get('ihr_id_token')) {
      this.logout()

      return false
    }

    const didRefresh = await this.refresh()
    if (!didRefresh) {
      return false
    }

    const body = document.getElementsByTagName('body')[0]
    const regenerateAuth = throttle(
      async () => {
        await this.refresh()
      },
      60 * 1000,
      { trailing: false },
    )
    body.addEventListener('mousemove', regenerateAuth)

    return true
  }

  public async getAccessToken(forceRefresh = false): Promise<string | undefined> {
    const accessToken = Cookies.get('ihr_access_token')
    if (forceRefresh || this.checkTokenExpiry(accessToken)) {
      await this.refresh()
    }

    return Cookies.get('ihr_access_token')
  }

  public logout() {
    if (this.loggingOut) {
      return
    }

    const { pathname, search, hash } = window.location
    const redirect = `/auth/logout?redirect_url=${encodeURIComponent(pathname + search + hash)}`
    window.location.replace(redirect)

    this.loggingOut = true
  }

  private logoutSessionExpired() {
    if (this.loggingOut) {
      return
    }

    this.onSessionExpiryLogout()
    this.logout()
  }

  private checkTokenExpiry(token?: string): boolean {
    const currentToken = token ? jwt_decode(token) : undefined
    const now = Math.floor(new Date().getTime() / 1000).toString()

    return !currentToken || typeof currentToken !== 'object' || currentToken.exp < now
  }

  private async refresh() {
    try {
      // This request will set cookies on the response, so the content doesn't need to be read
      await axios.post('/api/auth/refresh')

      const idToken = Cookies.get('ihr_id_token')
      if (!idToken) {
        await cloudLogger.info('Missing ID token', { userImpact: 'Might encounter random error pages' })
        this.logoutSessionExpired()

        return false
      }

      const decodedIdToken = jwt_decode(idToken)
      if (!decodedIdToken || typeof decodedIdToken === 'string') {
        await cloudLogger.info(`Invalid ID token: ${idToken}`, { userImpact: 'Might encounter random error pages' })
        this.logoutSessionExpired()

        return false
      }

      this._userId = decodedIdToken.user_id
      this._personId = decodedIdToken.person_id
      this._tenant = decodedIdToken.tenant
      this._sessionId = decodedIdToken.session_id ?? 'unknown'
      this._isSystemAdministrator = decodedIdToken.system_administrator ?? false
      this._isSuperUser = decodedIdToken.super_user ?? false
    } catch (error) {
      if (axios.isAxiosError(error) && (error.response?.status ?? 400) < 500) {
        this.logoutSessionExpired()

        return false
      }

      throw error
    }
    return true
  }
}

let sessionManagementSingleton: SessionManagementService | undefined

const getSession = () => {
  if (sessionManagementSingleton === undefined) {
    const onLogout = () =>
      getStore().dispatch(
        toastActions.postToast({
          type: 'alert',
          content: 'You have been logged out due to inactivity.',
        }),
      )

    sessionManagementSingleton = new SessionManagementService(onLogout)
  }

  return sessionManagementSingleton
}

export { getSession }
