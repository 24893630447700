import React from 'react'
import { urlForRoute } from 'src/services/routes'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { MangoButton } from '@intellihr/blueberry'

const useErrorFooterButtons: (dontErrorOnAccentConnectionFailure?: boolean) => JSX.Element[] = (
  dontErrorOnAccentConnectionFailure,
) => {
  const tCommon = useScope('common:error', { dontErrorOnAccentConnectionFailure })

  return [
    <MangoButton
      key={'dashboard'}
      href={urlForRoute('dashboard')}
    >
      {tCommon('backToDashboard', { defaultValue: 'Back to Dashboard' })}
    </MangoButton>,
    <MangoButton
      key="help"
      variant="secondary"
      href="https://help.humanforce.com/hc/en-au/categories/8246145242767-intelliHR"
      openNewTab
    >
      {tCommon('helpCentre', { defaultValue: 'Help Centre' })}
    </MangoButton>,
  ]
}

export { useErrorFooterButtons }
