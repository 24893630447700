import React from 'react'
import { Variables } from '@intellihr/ui-components'
import { Chip } from 'src/services/uiComponentCandidates/Chip'
import { useGetJobCountForBusinessUnit } from 'src/domain/EDM/public/components/inputs/PeopleDropdownInput/subcomponents/ValueComponent/gql'

const BusinessUnitValueComponent: React.FC<{
  name: string
  id: string
  onRemove?: () => void
  showJobCountForSelectedItems: boolean
  isDisabled: boolean
}> = ({ onRemove, name, id, showJobCountForSelectedItems, isDisabled }) => {
  const { data } = useGetJobCountForBusinessUnit({ businessUnitId: id }, !showJobCountForSelectedItems)

  const postfixText = showJobCountForSelectedItems ? ` (${data?.getBusinessUnit.jobCount ?? '...'})` : ''

  return (
    <Chip
      margins={{
        top: Variables.Spacing.s2XSmall,
        bottom: Variables.Spacing.s2XSmall,
        left: Variables.Spacing.s2XSmall,
        right: Variables.Spacing.sXSmall,
      }}
      primaryIcon="building"
      primaryIconType="regular"
      primaryText={`${name}${postfixText}`}
      actionIcon={!isDisabled ? 'times' : undefined}
      actionIconType={!isDisabled ? 'light' : undefined}
      onActionClick={!isDisabled ? onRemove : undefined}
    />
  )
}

export { BusinessUnitValueComponent }
