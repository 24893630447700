import queryString from 'query-string'
import { urlForRoute } from 'src/services/routes'
import { IRedirectChain } from './types'

const LOCAL_STORAGE_KEY = 'redirect_chains'

const generateRedirectChainUrl = (redirectChain: IRedirectChain, index: number, chainId: string) => {
  if (index === redirectChain.chain.length) {
    const baseUrl = urlForRoute(redirectChain.completionRoute.route, redirectChain.completionRoute.mapping)
    const extraQueryParams = redirectChain.completionRoute.params
      ? `?${queryString.stringify(redirectChain.completionRoute.params)}`
      : ''
    return `${baseUrl}${extraQueryParams}`
  }

  if (redirectChain.chain[index]) {
    const chainItem = redirectChain.chain[index]
    const baseUrl = urlForRoute(chainItem.route, chainItem.mapping)
    const baseQueryParams = `?redirectChainId=${chainId}&redirectChainIndex=${index}`
    const extraQueryParams = chainItem.params ? `&${queryString.stringify(chainItem.params)}` : ''
    return `${baseUrl}${baseQueryParams}${extraQueryParams}`
  }

  return undefined
}

const getLocalStorage = (): { [key: string]: unknown } | null => {
  const rawLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!rawLocalStorage) {
    return null
  }

  try {
    return JSON.parse(rawLocalStorage)
  } catch {
    return null
  }
}

export { getLocalStorage, generateRedirectChainUrl }
