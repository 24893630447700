import React from 'react'
import { TextInput } from '@intellihr/ui-components'
import { ReduxVerticalFormField, useValidationRulesForField } from 'src/services/reduxForm'
import { Field } from 'redux-form'
import { IOnboardingField } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { IFieldApiName, fieldTypeIdToApiName, fieldTypeIdToComponentLookup } from '../PersonalDetailsFormStepContent'
import { VisibilityOption } from '../../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'

const fieldDependencies: { [key: string]: { dependency: IFieldApiName; propName: string } } = {
  workRightId: {
    dependency: 'workRightCountryId',
    propName: 'countryId',
  },
}

const OnboardingField: React.FC<{ field: IOnboardingField; observedFieldValues: { [key: string]: string } }> = ({
  field,
  observedFieldValues,
}) => {
  const name = fieldTypeIdToApiName[field.fieldType.id]
  const validateRequired = useValidationRulesForField(name, ['required'])
  const isRequired = field.visibility.id === VisibilityOption.REQUIRED

  const dependency = fieldDependencies[name]
  const inputProps = {}
  let value = ''
  if (dependency) {
    value = observedFieldValues[dependency.dependency]
    if (value) {
      inputProps[dependency.propName] = value
    }
  }

  return (
    <Field
      key={field.id}
      name={name}
      component={ReduxVerticalFormField}
      validate={isRequired ? validateRequired : undefined}
      props={{
        component: fieldTypeIdToComponentLookup[field.fieldType.id]?.Component ?? TextInput,
        label: field.fieldType.name,
        isRequired,
        inputProps: {
          ...inputProps,
          componentContext: `template-field-${name}`,
        },
      }}
    />
  )
}

export { OnboardingField }
