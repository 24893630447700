import { createContext } from 'react'
import { PageKey } from 'src/scenes/Analytics/services/entities/types'
import { isEmpty } from 'lodash'
import { PermissionTarget } from './interface'
import type { IAnalyticsPermissionConfig } from './interface'
import { nonStandardPageKeys } from '../entities/pages'

type PagePermissionConfigs = IAnalyticsPermissionConfig[PermissionTarget.Page]
type FormPermissionConfigs = IAnalyticsPermissionConfig[PermissionTarget.Form]
type AnonymousFormPermissionConfigs = IAnalyticsPermissionConfig[PermissionTarget.AnonymousForms]
interface IRawAnalyticsPermission {
  access: boolean
  pages: PagePermissionConfigs
  forms: FormPermissionConfigs
  anonymousForms: AnonymousFormPermissionConfigs
  viewAllPages: boolean
}

interface IPageLegacy {
  key: string
}

const NULL_PERMISSION_RESP: IRawAnalyticsPermission = {
  access: false,
  pages: {},
  forms: {},
  anonymousForms: {
    all: null,
    forms: {},
  },
  viewAllPages: false,
}

class AnalyticsPermission {
  private raw: IRawAnalyticsPermission

  private pageKeys: Set<PageKey>

  private hasAnonymousFormAccess: boolean

  constructor(resp: IRawAnalyticsPermission) {
    if (resp) {
      this.raw = resp
    } else {
      this.raw = NULL_PERMISSION_RESP
    }
    this.pageKeys = new Set((Object.keys(this.pages) as PageKey[]).filter((key) => !nonStandardPageKeys.has(key)))
    this.hasAnonymousFormAccess = !!this.raw.anonymousForms.all || !isEmpty(this.raw.anonymousForms.forms)
  }

  public filterPagesLegacy = (pages: IPageLegacy[]): IPageLegacy[] => {
    if (!this.hasAccess) {
      return []
    }
    if (this.canViewAllPages) {
      return pages
    }
    return pages.filter((page) => this.pageKeys.has(page.key as PageKey))
  }

  public isAllowedPage = (pageKey: PageKey): boolean => {
    if (!this.hasAccess) {
      return false
    }
    if (this.canViewAllPages) {
      return true
    }
    if (pageKey === PageKey.anonymousFormData) {
      return this.hasAnonymousFormAccess
    }
    return this.pageKeys.has(pageKey)
  }

  public hasAccessToAnonymousForm = (formDesignId: string) => {
    return (
      this.hasAnonymousFormAccess && (!!this.raw.anonymousForms.all || !!this.raw.anonymousForms.forms[formDesignId])
    )
  }

  public get canViewAllPages(): boolean {
    return this.raw.viewAllPages
  }

  public get hasAccess(): boolean {
    return this.raw.access
  }

  public get pages(): PagePermissionConfigs {
    return this.raw.pages || {}
  }
}

const AnalyticsPermissionContext = createContext<AnalyticsPermission>(new AnalyticsPermission(NULL_PERMISSION_RESP))

export type { IRawAnalyticsPermission }
export { AnalyticsPermission, NULL_PERMISSION_RESP, AnalyticsPermissionContext }
