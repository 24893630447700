import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { IRowProps, IColumnSorts } from '@intellihr/ui-components/types/domain/Tables/Table/services/types'
import { Text, Table } from '@intellihr/ui-components'
import { IFontAwesomeIconButtonProps } from '@intellihr/ui-components/types/domain/Buttons/FontAwesomeIconButton/FontAwesomeIconButton'
import { IDocument, ICurrentlyUploadingDocumentsStatus } from './types'
import { EditingOverride } from './EditingOverride'
import { UploadingContentOverride } from './UploadingContentOverride'
import { SuccessContentOverride } from './SuccessContentOverride'
import { FailedContentOverride } from './FailedContentOverride'

const getProgress = (uploadingStatus: ICurrentlyUploadingDocumentsStatus[0] | null) => {
  if (!uploadingStatus) {
    return undefined
  }

  if (uploadingStatus.progressEvent) {
    return uploadingStatus.progressEvent.loaded / uploadingStatus.progressEvent.total
  }

  if (uploadingStatus?.state === 'uploaded') {
    return 1
  }

  return undefined
}

const defaultPageSize = 10
const usePaginate = (
  documents: Array<IRowProps<IDocument>>,
  resetOnPageChange: () => void,
  shouldPaginate: boolean,
  pageSize?: number,
) => {
  const paginationPageSize = pageSize ?? defaultPageSize
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    if (shouldPaginate) {
      resetOnPageChange()
    }
  }, [resetOnPageChange, currentPage, shouldPaginate])

  useEffect(() => {
    if (shouldPaginate) {
      setCurrentPage(1)
    }
  }, [setCurrentPage, documents.length, shouldPaginate])

  const page = useMemo(() => {
    const startDocuments = (currentPage - 1) * paginationPageSize
    const endDocuments = currentPage * paginationPageSize
    return documents.slice(startDocuments, endDocuments)
  }, [currentPage, documents, paginationPageSize])

  const latestValidPage = useMemo(() => {
    if (documents.length === 0) {
      return 1
    }
    return Math.floor((documents.length - 1) / paginationPageSize) + 1
  }, [documents.length, paginationPageSize])

  useEffect(() => {
    if (shouldPaginate && page.length === 0) {
      setCurrentPage(latestValidPage)
    }
  }, [page.length, currentPage, setCurrentPage, documents, latestValidPage, shouldPaginate])

  if (!shouldPaginate) {
    return {
      page: documents,
      currentPage: 1,
      setCurrentPage,
    }
  }

  return {
    page,
    currentPage,
    setCurrentPage,
  }
}

interface IGroupedRows {
  uploaded: Array<IRowProps<IDocument> & { uploadingState?: 'uploaded' | 'uploading' | 'failed' }>
  uploading: Array<IRowProps<IDocument> & { uploadingState?: 'uploaded' | 'uploading' | 'failed' }>
  failed: Array<IRowProps<IDocument> & { uploadingState?: 'uploaded' | 'uploading' | 'failed' }>
}

const useRowSort = (
  rows: Array<IRowProps<IDocument> & { uploadingState?: 'uploaded' | 'uploading' | 'failed' }>,
  sort: IColumnSorts,
) => {
  return useMemo(() => {
    const sortedAndGroupedRows = rows
      .sort((rowA, rowB) => {
        const sortColumn = Object.keys(sort)[0]
        const sortDirection = sort[sortColumn]

        if (sortDirection === 'ascending') {
          if (rowA.data[sortColumn] < rowB.data[sortColumn]) {
            return -1
          }

          if (rowA.data[sortColumn] > rowB.data[sortColumn]) {
            return 1
          }

          return 0
        }

        if (rowA.data[sortColumn] > rowB.data[sortColumn]) {
          return -1
        }

        if (rowA.data[sortColumn] < rowB.data[sortColumn]) {
          return 1
        }

        return 0
      })
      .reduce<IGroupedRows>(
        (groupedRows, row) => {
          switch (row.uploadingState) {
            case 'failed':
              groupedRows.failed.push(row)
              return groupedRows
            case 'uploading':
              groupedRows.uploading.push(row)
              return groupedRows
            default:
              groupedRows.uploaded.push(row)
              return groupedRows
          }
        },
        { uploaded: [], uploading: [], failed: [] },
      )
    return [...sortedAndGroupedRows.failed, ...sortedAndGroupedRows.uploading, ...sortedAndGroupedRows.uploaded]
  }, [rows, sort])
}

const useRows = (
  existingDocuments: readonly IDocument[],
  currentlyUploadingDocuments: ICurrentlyUploadingDocumentsStatus,
  onRetry?: (document: IDocument) => void,
  onDownload?: (documents: [IDocument]) => void,
) => {
  return useMemo(() => {
    const uploadedRows = existingDocuments
      .filter(
        (document) => document.uploadStatus === 'SUCCESS' && currentlyUploadingDocuments[document.id] === undefined,
      )
      .map((document) => {
        return {
          id: document.id,
          data: document,
          isSelectable: true,
          onClick: () => onDownload?.([document]),
        }
      })

    const uploadingRows = Object.entries(currentlyUploadingDocuments).flatMap(([id, uploadingDocument]) => {
      if (uploadingDocument) {
        return [
          {
            id: `${id}-${uploadingDocument?.state}`,
            data: uploadingDocument.document,

            isRemovable: uploadingDocument?.state === 'failed' || uploadingDocument?.state === 'uploading',

            variant: uploadingDocument?.state === 'failed' ? Table.RowVariant.Error : Table.RowVariant.Neutral,
            progress: getProgress(uploadingDocument),

            onClick: uploadingDocument?.state === 'failed' ? onRetry : undefined,

            uploadingState: uploadingDocument?.state,
          },
        ]
      }
      return []
    })

    return [...uploadingRows, ...uploadedRows]
  }, [existingDocuments, currentlyUploadingDocuments, onDownload, onRetry])
}

const useRowsWithActions = (
  rows: Array<
    IRowProps<IDocument> & {
      uploadingState?: 'uploaded' | 'uploading' | 'failed'
    }
  >,
  getRowActions: (document: IDocument, setEditingRow: (id: string) => void) => IFontAwesomeIconButtonProps[],
  setEditingRow: (editingRow: string | null) => void,
  getFailedRowAction?: (document: IDocument) => IFontAwesomeIconButtonProps[],
) => {
  return useMemo(() => {
    return rows.map((row) => {
      if (row.uploadingState === 'failed') {
        row.actions = getFailedRowAction?.(row.data)
        return row
      }

      row.actions = getRowActions(row.data, setEditingRow)
      return row
    })
  }, [rows, getRowActions, setEditingRow, getFailedRowAction])
}

const successOverride = (data: IDocument) => [
  <Text
    key={0}
    isTruncated
    isInline={false}
  >
    {data.displayName}
  </Text>,
  <SuccessContentOverride key={1} />,
]

const uploadingOverride = (data: IDocument) => [
  <Text
    key={0}
    isTruncated
    isInline={false}
  >
    {data.displayName}
  </Text>,
  <UploadingContentOverride
    data={data}
    key={1}
  />,
]

const failedOverride = (data: IDocument) => [
  <Text
    key={0}
    isTruncated
    isInline={false}
  >
    {data.displayName}
  </Text>,
  <FailedContentOverride key={1} />,
]

const useRowsWithOverrides = (
  rows: Array<
    IRowProps<IDocument> & {
      uploadingState?: 'uploaded' | 'uploading' | 'failed'
    }
  >,
  editingRow: string | null,
  setEditingRow: (editingRow: string | null) => void,
  onEditSubmit?: (document: IDocument, value: string) => void,
) => {
  const editingOverride = useCallback(
    (data: IDocument) => (
      <EditingOverride
        data={data}
        onCancel={() => setEditingRow(null)}
        onSubmit={(renamedDocument, value) => {
          if (onEditSubmit) {
            onEditSubmit(renamedDocument, value)
          }

          setEditingRow(null)
        }}
      />
    ),
    [onEditSubmit, setEditingRow],
  )

  return useMemo(() => {
    return rows.map((row) => {
      if (row.uploadingState === 'uploaded') {
        return {
          ...row,
          contentOverride: successOverride,
        }
      }

      if (row.uploadingState === 'uploading') {
        return {
          ...row,
          contentOverride: uploadingOverride,
        }
      }

      if (row.uploadingState === 'failed') {
        return {
          ...row,
          contentOverride: failedOverride,
        }
      }

      if (onEditSubmit && editingRow === row.data.id) {
        return {
          ...row,
          contentOverride: editingOverride,
          isSelectable: editingRow !== row.data.id,
        }
      }

      return {
        ...row,
        contentOverride: undefined,

        isSelectable: editingRow !== row.data.id && !row?.uploadingState,
      }
    })
  }, [rows, onEditSubmit, editingRow, editingOverride])
}

const useRowFilter = (
  rows: Array<
    IRowProps<IDocument> & {
      uploadingState?: 'uploaded' | 'uploading' | 'failed'
    }
  >,
  searchText?: string,
) => {
  return useMemo(() => {
    if (!searchText || searchText === '') {
      return rows
    }

    const lowerSearchText = searchText.toLowerCase()
    return rows.filter((row) => {
      const fullName = `${row.data.displayName}.${row.data.extension}`
      return fullName.toLowerCase().includes(lowerSearchText)
    })
  }, [rows, searchText])
}

export { useRows, useRowsWithActions, useRowFilter, useRowsWithOverrides, useRowSort, usePaginate, getProgress }
