import { gql } from '@apollo/client'
import { useQuery } from 'src/services/apollo'
import { GetOnboardingStatus } from './__gql__/useGetOnboardingStatusCodeGen'

type OnboardingStep = NonNullable<GetOnboardingStatus['getOnboardingStatus']>['currentStep']

const fragmentOnboardingStep = gql`
  fragment FragmentOnboardingStep on OnboardingStep {
    onboardingSessionId
    onboardingStepType {
      id
    }
    onboardingFormInstance {
      id
    }
    completedAt
    job {
      id
    }
  }
`

const GET_ONBOARDING_STATUS = gql`
  ${fragmentOnboardingStep}
  query GetOnboardingStatus {
    getOnboardingStatus {
      isOnboarding
      currentStep {
        ...FragmentOnboardingStep
      }
      onboardingSteps {
        ...FragmentOnboardingStep
      }
    }
  }
`

const useGetOnboardingStatus = () => {
  return useQuery<GetOnboardingStatus>(GET_ONBOARDING_STATUS, {
    errorBehaviour: 'do-nothing',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })
}

export { useGetOnboardingStatus }
export type { OnboardingStep }
