import { searchOptions as defaultSearchOptions, TMiniSearch } from 'src/scenes/Analytics/services/search'
import { IAsyncAnalyticsOption, Group } from '../interface'

const constructAnalyticsOptions = (
  miniSearch: TMiniSearch,
  query: string,
  limit = 5,
  searchOptions = defaultSearchOptions,
) =>
  miniSearch
    .search(query, miniSearch.deriveOptions(searchOptions))
    .reduce<IAsyncAnalyticsOption[]>((acc, { id: { tile: entityKey, type } }) => {
      if (acc.length >= limit) {
        return acc
      }

      if (acc.find(({ entityKey: _entityKey }) => _entityKey && _entityKey === entityKey)) {
        return acc
      }

      return [
        ...acc,
        {
          value: entityKey,
          label: entityKey,
          group: Group.Analytics,
          entityKey,
          type,
        },
      ]
    }, [])

export { constructAnalyticsOptions }
