import { Button } from '@intellihr/blueberry'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BrowserBackButtonWrapper } from './style'

interface IBackButtonProps {
  disabled?: boolean
  onClick?: () => void
}

const BrowserBackButton: React.FC<React.PropsWithChildren<IBackButtonProps>> = ({
  disabled = false,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  children,
}) => {
  const history = useHistory()

  const handleClick = (event: React.SyntheticEvent<unknown>) => {
    onClick()
    event.preventDefault()
    history.goBack()
  }

  return (
    <BrowserBackButtonWrapper>
      <Button
        onClick={handleClick}
        variant="tertiary"
        disabled={disabled}
      >
        {children}
      </Button>
    </BrowserBackButtonWrapper>
  )
}

export { BrowserBackButton }
