import { SelectInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'src/services/apollo'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'

const LIST_ALL_TITLES = gql`
  query ListAllTitles {
    listAllTitles(isEnabled: true) {
      id
      name
    }
  }
`

interface IListAllTitlesResponse {
  listAllTitles?: Array<{
    id: string
    name: string
  }>
}

const TitleSelectInput: React.FC<ISelectInputProps> = (props) => {
  return (
    <Query<IListAllTitlesResponse, never>
      errorBehaviour="do-nothing"
      query={LIST_ALL_TITLES}
    >
      {({ data }) => {
        const options =
          data && data.listAllTitles
            ? data.listAllTitles.map((title) => ({
                label: title.name,
                value: title.id,
              }))
            : []

        return (
          <SelectInput
            {...props}
            isFetching={!data?.listAllTitles}
            componentContext="title-select-input"
            options={options}
          />
        )
      }}
    </Query>
  )
}

export { LIST_ALL_TITLES, TitleSelectInput }
