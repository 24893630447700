import React from 'react'
import { CircleSkeleton, Props } from '@intellihr/ui-components'
import { useBrowserBreakpoints } from 'src/services/reducers/browser/hooks'
import { Avatar, Box, ButtonIcon, ButtonLinkIcon, DropdownMenu, Inline } from '@intellihr/blueberry'
import { getIconForPageHeaderItem } from 'src/services/layout/components/PageFrame/components/PageHeader/getIconForPageHeaderItem'
import { MobileNavigation } from 'src/services/layout/components/PageFrame/components/Navigation/MobileNavigation'
import { AnalyticsProvider } from 'src/scenes/Analytics/services/providers/AnalyticsProvider'
import { GlobalSearchBar } from 'src/services/layout/components/PageFrame/components/GlobalSearchBar'
import {
  isAvatarTopbarItem,
  isFontAwesomeTopbarItem,
} from 'src/services/layout/components/PageFrame/components/PageHeader/typeGuards'
import { usePermissionCheck } from 'src/services/permissions/components/PermissionCheck'
import { useConfigCatWithKey } from 'src/services/configCat/services/configCat'
import { useGetPageHeaderItems } from './gql'

const PageHeader: React.FC = () => {
  const { data } = useGetPageHeaderItems()
  const { desktop } = useBrowserBreakpoints()

  const { passedPermissionSlugs, loading: permissionCheckLoading } = usePermissionCheck({
    permissionSlugs: ['people.past_profile.view'],
    targetType: 'functional',
  })

  const { value: isPastPeoplePermissionEnabled, loading: configCatKeyLoading } = useConfigCatWithKey(
    'PAST_PEOPLE_PERMISSION',
    false,
  )

  const allowSearchPastStaff =
    !permissionCheckLoading &&
    !configCatKeyLoading &&
    (!isPastPeoplePermissionEnabled || passedPermissionSlugs?.includes('people.past_profile.view'))

  if (!data) {
    return (
      <Inline>
        <CircleSkeleton size={Props.AvatarSize.Medium} />
        <CircleSkeleton size={Props.AvatarSize.Medium} />
        <CircleSkeleton size={Props.AvatarSize.Medium} />
        <CircleSkeleton size={Props.AvatarSize.Medium} />
      </Inline>
    )
  }

  return (
    <Box
      paddingY="small"
      paddingX="large"
      display="flex"
      justifyContent="spaceBetween"
      alignItems="center"
    >
      {!desktop ? <MobileNavigation /> : <span />}
      <Inline
        alignY="center"
        space={{ mobile: 'none', desktop: 'small' }}
      >
        <AnalyticsProvider>
          <div style={desktop ? { minWidth: '20rem' } : undefined}>
            <GlobalSearchBar
              variant={desktop ? 'bar' : 'icon'}
              showPastStaffFilter={allowSearchPastStaff}
            />
          </div>
        </AnalyticsProvider>
        {data.listNavigationTopbarItems.flatMap((item: unknown) => {
          let component = <></>

          if (!isFontAwesomeTopbarItem(item) && !isAvatarTopbarItem(item)) {
            return []
          }

          if (isFontAwesomeTopbarItem(item) && item.href) {
            component = (
              <ButtonLinkIcon
                key={item.id}
                testId={`pageHeader_${item.id}`}
                label={item.label}
                href={item.href}
                indicatorCount={item.indicatorCount ?? 0}
                icon={getIconForPageHeaderItem(item.id)}
              />
            )
          }

          if (isFontAwesomeTopbarItem(item) && item.items) {
            component = (
              <ButtonIcon
                key={item.id}
                testId={`pageHeader_${item.id}`}
                label={item.label}
                indicatorCount={item.indicatorCount ?? 0}
                icon={getIconForPageHeaderItem(item.id)}
              />
            )
          }

          if (isAvatarTopbarItem(item)) {
            component = (
              <Avatar
                key={item.id}
                testId={`pageHeader_${item.id}`}
                initials={item.initials}
                image={item.avatarUrl ? item.avatarUrl : undefined}
                size="medium"
              />
            )
          }

          if (item.items) {
            return (
              <DropdownMenu
                key={item.id}
                items={[
                  {
                    type: 'group',
                    label: item.label,
                    items: item.items.flatMap((dropdownItem) => {
                      if (dropdownItem.visible) {
                        if (dropdownItem.variant === 'OPTION' && dropdownItem.href) {
                          return {
                            type: 'link',
                            id: `pageHeader_${dropdownItem.id}`,
                            href: dropdownItem.href,
                            label: dropdownItem.label,
                            icon: dropdownItem.icon ? getIconForPageHeaderItem(dropdownItem.id) : undefined,
                          }
                        }
                      }

                      return []
                    }),
                  },
                ]}
                trigger={component}
              />
            )
          }

          return component
        })}
      </Inline>
    </Box>
  )
}

const MemoPageHeader = React.memo(PageHeader)

export { MemoPageHeader as PageHeader }
