import React from 'react'
import { Skeleton } from '@intellihr/blueberry'
import { useGetSuperApiEmbedUrlForOnboardingSession } from './gql'
import { SuperAPIEmbed, SuperAPIEmbedErrorMessage } from './SuperAPIEmbed'

interface IOnboardingSuperAPIDetailStepProps {
  onboardingSessionId?: string
  onSubmit: () => void
}

const SuperAPIDetailStep: React.FC<IOnboardingSuperAPIDetailStepProps> = ({ onboardingSessionId, onSubmit }) => {
  const { data, loading } = useGetSuperApiEmbedUrlForOnboardingSession(onboardingSessionId ?? '')

  const embedUrl = data?.getSuperApiEmbedUrlForOnboardingSession

  if (!data && loading) {
    return <Skeleton variant="form" />
  }

  if (!embedUrl) {
    return <SuperAPIEmbedErrorMessage />
  }

  return (
    <SuperAPIEmbed
      embedUrl={embedUrl}
      onSubmit={onSubmit}
    />
  )
}

export { SuperAPIDetailStep }
