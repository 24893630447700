import { Inline, Tag } from '@intellihr/blueberry'
import React, { useCallback } from 'react'
import { Variables, Props, Text, Avatar, TooltipPopover } from '@intellihr/ui-components'
import { getColorForEmploymentStatus } from 'src/domain/EDM/public/services/personEmploymentStatusService'
import { TextMatch } from 'src/domain/EDM/internal/components/displays/TextMatch'
import { IPeopleDropdownOption, IPersonOption } from '../../../types'
import {
  PersonTextElement,
  PersonTextSubtitle,
  PersonTextWrapper,
  StyledPersonWrapper,
  PreventShrinkWrapper,
} from '../style'
import { IPeopleSearchOtherJobs } from '../../../gql'

interface IPersonOptionProps {
  option: IPersonOption
  inputValue?: string
  isFocused?: boolean
  onSelect?: (option: IPeopleDropdownOption, event: React.MouseEvent) => void
}

const GeneratedJobNames: React.FC<{ jobs: readonly IPeopleSearchOtherJobs[] }> = ({ jobs }) => (
  <>
    {jobs.map((job) => (
      <Text
        key={job.id}
        color={Variables.Color.n200}
        isInline={false}
        isTruncated
        type={Props.TypographyType.XSmall}
        weight={Variables.FontWeight.fwSemiBold}
        margins={{ bottom: Variables.Spacing.s2XSmall }}
      >
        {job.jobName.name}
      </Text>
    ))}
  </>
)

const PersonOption: React.FC<IPersonOptionProps> = ({ option, inputValue, isFocused, onSelect }) => {
  const formatText = useCallback(() => {
    return `${option.job?.jobName.name}${
      option.job?.jobLocation.location?.name ? ` · ${option.job.jobLocation.location.name}` : null
    }${
      option.job?.jobBusinessEntity.businessEntity.name
        ? ` · ${option.job.jobBusinessEntity.businessEntity.name}`
        : null
    }`
  }, [option])

  return (
    <StyledPersonWrapper
      isFocused={isFocused}
      onClick={(event) => onSelect?.(option, event)}
      data-cy={`person-dropdown-person-option-${option.person.displayName}`}
    >
      <PreventShrinkWrapper>
        <Avatar
          initials={option.person.initials}
          imageUrl={option.person.profilePictureUrl ?? undefined}
          statusDot={getColorForEmploymentStatus(
            option.job?.isOnExtendedLeave ?? false,
            option.person.employmentStatusId,
          )}
          size={Props.AvatarSize.Small}
        />
      </PreventShrinkWrapper>
      <PersonTextWrapper>
        <PersonTextElement>
          <TextMatch
            mainText={option.person.displayName}
            searchText={inputValue}
            otherTextProps={{ type: Props.TypographyType.Small, color: Variables.Color.n800 }}
            matchTextProps={{ type: Props.TypographyType.Small, weight: Variables.FontWeight.fwSemiBold }}
          />
        </PersonTextElement>
        {option.job && (
          <PersonTextSubtitle>
            <Inline>
              <TextMatch
                mainText={formatText()}
                searchText={inputValue}
                otherTextProps={{ type: Props.TypographyType.XSmall, color: Variables.Color.n700 }}
                matchTextProps={{ type: Props.TypographyType.XSmall, weight: Variables.FontWeight.fwSemiBold }}
              />
              {option.otherJobs.length > 0 && (
                <TooltipPopover
                  width={450}
                  variant={TooltipPopover.Variant.Dark}
                  toggleComponent={({ openMenu, closeMenu, toggleComponentRef, ariaProps }) => (
                    <span
                      onMouseEnter={openMenu}
                      onMouseLeave={closeMenu}
                      ref={toggleComponentRef}
                      {...ariaProps}
                      style={{ pointerEvents: 'auto' }}
                    >
                      <Tag tone="neutral">+ {option.otherJobs.length}</Tag>
                    </span>
                  )}
                >
                  <GeneratedJobNames jobs={option.otherJobs} />
                </TooltipPopover>
              )}
            </Inline>
          </PersonTextSubtitle>
        )}
      </PersonTextWrapper>
    </StyledPersonWrapper>
  )
}

export { PersonOption }
