import React, { useMemo } from 'react'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { usePerformAuthCheckQuery } from './gql'

interface IPermissionCheckProps {
  errorBehaviour?: 'throw-exception' | 'do-nothing'
  permissionSlugs: string[]
  targetId?: string
  targetType: 'functional' | 'people'
}

const usePermissionCheck = (props: IPermissionCheckProps) => {
  const { errorBehaviour = 'throw-exception', permissionSlugs, targetId, targetType } = props

  const userId = getSession().userId
  const { data, loading } = usePerformAuthCheckQuery(errorBehaviour, {
    sourceUserId: userId,
    permissionSlugs,
    targetId,
    targetType,
  })

  return useMemo(() => {
    if (permissionSlugs.length === 0) {
      return {
        loading: false,
        permissions: [],
        passedPermissionSlugs: [],
        failedPermissionSlugs: [],
        someValid: false,
        allValid: true,
      }
    }

    if (!data) {
      return {
        loading,
        permissions: null,
      }
    }

    const permissions = data.performSingleHierarchicalAuthCheck.authorisations
    const passedPermissionSlugs = permissions.flatMap((permission) =>
      permission.passes ? [permission.permissionSlug] : [],
    )
    const failedPermissionSlugs = permissions.flatMap((permission) =>
      !permission.passes ? [permission.permissionSlug] : [],
    )

    return {
      loading,
      permissions,
      passedPermissionSlugs,
      failedPermissionSlugs,
      someValid: permissions.some((permission) => permission.passes),
      allValid: permissions.every((permission) => permission.passes),
    }
  }, [data, loading, permissionSlugs])
}

interface IPermissionCheckComponentProps {
  children: (props: ReturnType<typeof usePermissionCheck>) => JSX.Element | null
}

const PermissionCheck = (props: IPermissionCheckProps & IPermissionCheckComponentProps) => {
  const { children } = props

  const permissionCheckResult = usePermissionCheck(props)

  if (permissionCheckResult.permissions) {
    return children(permissionCheckResult)
  }

  return <div data-cy="hierarchical-permission-checker-spinner" />
}

interface ILegacyFunctionalPermissionCheckProps {
  permissions: string[]
  render: (renderPropArguments: ILegacyPermissionRenderPropArguments) => JSX.Element | null
}

interface ILegacyPermissionRenderPropArguments {
  allValid: boolean
  someValid: boolean
  checkedPermissions: {
    [name: string]: boolean
  }
}

const LegacyFunctionalPermissionCheck = (props: ILegacyFunctionalPermissionCheckProps) => {
  const { permissions, someValid, allValid } = usePermissionCheck({
    targetType: 'functional',
    permissionSlugs: props.permissions,
  })

  if (permissions) {
    const checkedPermissions = permissions.reduce((acc, permission) => {
      acc[permission.permissionSlug] = permission.passes

      return acc
    }, {})

    return props.render({
      checkedPermissions,
      someValid,
      allValid,
    })
  }

  // This is a hack so that tests can correctly wait for this to disappear
  return <div data-cy="legacy-permission-checker-spinner" />
}

const withLegacyFunctionalPermissionCheck = <P extends object>(BaseComponent: React.ComponentType<P>) =>
  class WithPermissions extends React.PureComponent<P & { permissions: string[] } & React.PropsWithChildren> {
    public static defaultProps = {
      permissions: [],
    }

    public render(): JSX.Element {
      const { permissions } = this.props

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const TypescriptWorkaroundComponent = BaseComponent as any

      return (
        <LegacyFunctionalPermissionCheck
          permissions={permissions}
          render={({ allValid }) => (allValid ? <TypescriptWorkaroundComponent {...this.props} /> : null)}
        />
      )
    }
  }

export type { ILegacyPermissionRenderPropArguments }
export { usePermissionCheck, withLegacyFunctionalPermissionCheck, PermissionCheck, LegacyFunctionalPermissionCheck }
