import React from 'react'
import {
  IconMenuAnalytics,
  IconMenuEngagement,
  IconMenuHelpAndSupport,
  IconMenuHome,
  IconMenuLearning,
  IconMenuOrganisation,
  IconMenuPayroll,
  IconMenuPeople,
  IconMenuPerformance,
  IconMenuSettings,
  IconMenuTimezone,
  IconPageAllSettings,
  IconPageAttritionAnalysis,
  IconPageCompensationPlanner,
  IconPageContracts,
  IconPageDiscover,
  IconPageFormDataAnalysis,
  IconPageFormDesigns,
  IconPageGoals,
  IconPageHelpCenter,
  IconPageIntegrations,
  IconPageJobRequirements,
  IconPageMyProfile,
  IconPageMyTasks,
  IconPageOnboarding,
  IconPageOrganisationChart,
  IconPageOrganisationObjectives,
  IconPagePayrollHolidays,
  IconPagePayrollPayslips,
  IconPagePayrollTimesheets,
  IconPagePeopleCalendar,
  IconPagePeopleDirectory,
  IconPagePerformanceImprovement,
  IconPagePerformanceReport,
  IconPagePermissions,
  IconPagePrivateNotes,
  IconPageProfessionalServices,
  IconPagePulses,
  IconPageQualifications,
  IconPageSkills,
  IconPageSystemSettings,
  IconPageTasks,
  IconPageTraining,
  IconPageWorkflows,
  IconPageWorkforceHeadcount,
  IconPageWorkforcePlanner,
  IconPageWhatsNew,
  IconPageJobUpdates,
  IconQuestionCircle,
  IconPageATS,
  IconPageLMS,
  IconMenuAbsence,
  IconPagePayrollP60,
  IconPagePayrollTeamCalendar,
  IconPagePayrollSickLeave,
  IconPagePayrollBankAccounts,
} from '@intellihr/blueberry'

const navigationItemIcons = {
  formDataAnalysis: <IconPageFormDataAnalysis />,
  analytics: <IconMenuAnalytics />,
  home: <IconMenuHome />,
  myProfile: <IconPageMyProfile />,
  myTasks: <IconPageMyTasks />,
  privateNotes: <IconPagePrivateNotes />,
  payslips: <IconPagePayrollPayslips />,
  people: <IconMenuPeople />,
  onboarding: <IconPageOnboarding />,
  peopleDirectory: <IconPagePeopleDirectory />,
  organisationChart: <IconPageOrganisationChart />,
  peopleCalendar: <IconPagePeopleCalendar />,
  organisation: <IconMenuOrganisation />,
  workforcePlanner: <IconPageWorkforcePlanner />,
  compensationPlanner: <IconPageCompensationPlanner />,
  contracts: <IconPageContracts />,
  jobRequirements: <IconPageJobRequirements />,
  jobUpdates: <IconPageJobUpdates />,
  analyticsDiscover: <IconPageDiscover />,
  workforceHeadcount: <IconPageWorkforceHeadcount />,
  attritionAnalysis: <IconPageAttritionAnalysis />,
  permissions: <IconPagePermissions />,
  engagement: <IconMenuEngagement />,
  tasks: <IconPageTasks />,
  formDesigns: <IconPageFormDesigns />,
  pulses: <IconPagePulses />,
  workflows: <IconPageWorkflows />,
  performance: <IconMenuPerformance />,
  goals: <IconPageGoals />,
  organisationObjectives: <IconPageOrganisationObjectives />,
  performanceReport: <IconPagePerformanceReport />,
  performanceImprovement: <IconPagePerformanceImprovement />,
  learning: <IconMenuLearning />,
  training: <IconPageTraining />,
  qualifications: <IconPageQualifications />,
  skills: <IconPageSkills />,
  cintraPayroll: <IconMenuPayroll />,
  cintraAbsence: <IconMenuAbsence />,
  payrollMyCalendar: <IconPagePayrollTeamCalendar />,
  payrollAbsences: <IconPagePayrollSickLeave />,
  payrollHolidays: <IconPagePayrollHolidays />,
  payrollPayslips: <IconPagePayrollPayslips />,
  payrollTimesheetClaims: <IconPagePayrollTimesheets />,
  payrollP60: <IconPagePayrollP60 />,
  payrollBankAccounts: <IconPagePayrollBankAccounts />,
  settings: <IconMenuSettings />,
  allSettings: <IconPageAllSettings />,
  systemSettings: <IconPageSystemSettings />,
  integrations: <IconPageIntegrations />,
  helpAndSupport: <IconMenuHelpAndSupport />,
  helpCentre: <IconPageHelpCenter />,
  whatsNew: <IconPageWhatsNew />,
  professionalServices: <IconPageProfessionalServices />,
  timezone: <IconMenuTimezone />,
  customAts: <IconPageATS />,
  customLms: <IconPageLMS />,
}

const getIconForNavigationItem = (key: string) => {
  return navigationItemIcons[key] ?? <IconQuestionCircle />
}

export { getIconForNavigationItem }
