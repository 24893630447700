import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import { GetJobCountForBusinessUnit, GetJobCountForBusinessUnitVariables } from './__gql__/gqlCodeGen'

const GET_JOB_COUNT_FOR_BUSINESS_UNIT = gql`
  query GetJobCountForBusinessUnit($businessUnitId: ID!) {
    getBusinessUnit(id: $businessUnitId) {
      id
      jobCount
    }
  }
`

const useGetJobCountForBusinessUnit = (variables: GetJobCountForBusinessUnitVariables, skip: boolean) => {
  return useQuery<GetJobCountForBusinessUnit, GetJobCountForBusinessUnitVariables>(GET_JOB_COUNT_FOR_BUSINESS_UNIT, {
    errorBehaviour: 'do-nothing',
    skip,
    variables,
  })
}

export { useGetJobCountForBusinessUnit }
