import styled, { css } from 'styled-components'
import { Variables } from '@intellihr/ui-components'
import React from 'react'

const StyledInput = styled.input<{ isSingleSelect: boolean }>`
  padding-left: 0;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  flex: 1 1 auto;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  margin-left: ${Variables.Spacing.sXSmall}px;
  outline: none;
  vertical-align: middle;
  width: 100%;

  &:hover {
    background: none transparent;
  }

  ${({ isSingleSelect }) =>
    isSingleSelect &&
    css`
      margin-left: 0;
    `}
`

const InputWrapper = styled.div<{ isSingleSelect: boolean; isDisabled: boolean }>`
  align-items: center;
  display: flex;
  margin: ${Variables.Spacing.sXSmall}px;
  height: 22px;
  flex: 1 1 30%;
  order: 1;

  ${({ isSingleSelect }) =>
    isSingleSelect
      ? css`
          margin-left: 0;
          flex: 1 1 1%;
        `
      : css`
          position: sticky;
          width: 100%;
          margin: 0;
          padding: ${Variables.Spacing.sXSmall}px;
          background-color: ${Variables.Color.n100};
          height: 34px;
          bottom: 0;
          border-radius: ${Variables.Style.borderRadius}px;
        `}

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: ${Variables.Color.n200};
        `
      : undefined}
`

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: ${Variables.Spacing.sXSmall}px;
  height: 22px;
  order: -1;
`

const PlaceholderWrapper = React.memo(styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: ${Variables.Spacing.s2XSmall}px;
`)

const SpinnerWrapper = styled.div`
  bottom: ${Variables.Spacing.sXSmall}px;
  position: absolute;
  right: ${Variables.Spacing.sXSmall}px;
`

const PeopleDropdownStyleWrapper = styled.div<{ isInvalid: boolean; isSingleSelect: boolean }>`
  .Select.is-focused {
    .Select-control {
      border: 1px solid ${Variables.Color.i400};
      box-shadow: none;
    }
  }

  .Select.is-disabled {
    .Select-control {
      background-color: ${Variables.Color.n200};
    }
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      .Select-control {
        border-color: ${Variables.Color.r300};
      }

      &:not(.is-focused) {
        .Select-control {
          background-color: ${Variables.Color.r100};
        }
      }
    `}

  .Select-control {
    display: flex;
    height: auto;
    margin-bottom: ${Variables.Spacing.sMedium}px;
  }

  .Select-multi-value-wrapper {
    width: 100%;
    ${({ isSingleSelect }) =>
      isSingleSelect
        ? css`
            display: flex;
            align-items: center;
          `
        : css`
            overflow: auto;
            max-height: 300px;
            position: relative;
          `}
  }

  .Select-menu-outer {
    max-height: 400px;
  }

  .Select-placeholder {
    display: none;
  }

  .Select-menu {
    max-height: 350px;
    position: relative;
  }
`

export { IconWrapper, PeopleDropdownStyleWrapper, SpinnerWrapper, PlaceholderWrapper, StyledInput, InputWrapper }
