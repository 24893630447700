import React, { memo } from 'react'
import { useAnalyticsFormDesigns } from 'src/scenes/Analytics/services/entities/forms'
import { useConfigCatWithKey } from 'src/services/configCat/services/configCat'
import { AnalyticsPagesProvider } from 'src/scenes/Analytics/services/entities'
import { AnalyticsSearchProvider } from 'src/scenes/Analytics/services/search'
import { AnalyticsPermissionContext } from '../permissions'
import { useAnalyticsPermission } from '../permissions/hooks'
import {
  useAccessibleAnalyticsFeatureInfo,
  AccessibleAnalyticsFeatureInfoContext,
} from '../entities/AnalyticsPlanModule'

const AnalyticsProvider: React.FC<React.PropsWithChildren<unknown>> = memo(({ children }) => {
  const { loading: loadingAccessibleAnalyticsFeatureInfo, accessibleAnalyticsFeatureInfo } =
    useAccessibleAnalyticsFeatureInfo()
  const { loading: loadingAnalyticsPermission, permission } = useAnalyticsPermission()
  const { loading: loadingAnalyticsFormDesigns, formDesigns } = useAnalyticsFormDesigns(permission)
  const { loading: loadingBetaPageAccess, value: enableBetaPageAccess } = useConfigCatWithKey(
    'BETA_ANALYTICS_PAGE_ACCESS',
    false,
  )

  if (
    loadingAnalyticsPermission ||
    loadingAnalyticsFormDesigns ||
    loadingBetaPageAccess ||
    loadingAccessibleAnalyticsFeatureInfo
  ) {
    return null
  }

  return (
    <AccessibleAnalyticsFeatureInfoContext.Provider value={accessibleAnalyticsFeatureInfo}>
      <AnalyticsPermissionContext.Provider value={permission}>
        <AnalyticsPagesProvider
          enableBetaPageAccess={enableBetaPageAccess}
          formDesigns={formDesigns}
        >
          <AnalyticsSearchProvider>{children}</AnalyticsSearchProvider>
        </AnalyticsPagesProvider>
      </AnalyticsPermissionContext.Provider>
    </AccessibleAnalyticsFeatureInfoContext.Provider>
  )
})

export { AnalyticsProvider }
