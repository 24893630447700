import { Variables } from '@intellihr/ui-components'
import React, { RefObject } from 'react'
import Geosuggest, { QueryType, Suggest } from 'react-geosuggest'
import { TextMatch } from 'src/domain/EDM/internal/components/displays/TextMatch'
import { StyledAutocompleteLocationInput } from './style'

interface IAutocompleteLocationInputProps {
  /** The address types of suggestion option values. Types can be added to the array. 'geocode' for address, '(regions)' for state,  '(cities)' for  suburb. If it is null, it will return all the value */
  addressTypesIncluded?: QueryType[]
  /** Placeholder text to display when input is empty */
  placeholder?: string
  /** Event for selected suggestion */
  onSuggestSelect?: (suggest: Suggest) => void
  /** onClick event for clicking the manual Option Button */
  onClickManualOptionButton: (event: React.MouseEvent<HTMLElement>) => void
  /** Event for no result for the suggestion */
  onSuggestNoResults?: (userInput: string) => void
  /** If true, sets input to disabled state */
  isDisabled?: boolean
  /** If true, displays reset button and fires callback when clicked */
  onResetClick?: () => void
  /** Value used as the initial value to start */
  initialValue?: string
  /**
   * The country to bias the search results around.
   * This works by first doing a geocode lookup on the country name, then using the lat/long to bias the search results around,
   * with a hardcoded radius of 2500km.
   */
  biasCountry?: string
  id?: string
  name: string
}

const biasRadius = 2500 * 1000

interface IAutocompleteLocationInputState {
  isDisabled: boolean
  location: google.maps.LatLng | null
}

class AutocompleteLocationInput extends React.PureComponent<
  IAutocompleteLocationInputProps,
  IAutocompleteLocationInputState
> {
  private get resetButton(): JSX.Element | null {
    const { onResetClick } = this.props

    if (onResetClick) {
      return (
        <span
          onClick={this.handleReset}
          className="autocomplete-reset"
        >
          ×
        </span>
      )
    }

    return null
  }

  public state: IAutocompleteLocationInputState = {
    isDisabled: false,
    location: null,
  }

  private geosuggestRef: RefObject<Geosuggest> = React.createRef()

  constructor(props: IAutocompleteLocationInputProps) {
    super(props)

    this.state = {
      isDisabled: !!props.isDisabled,
      location: null,
    }
  }

  public componentDidMount() {
    const { onResetClick } = this.props

    this.generateBiasLocation()

    if (onResetClick) {
      // forceUpdate is required to for the ref to work
      this.forceUpdate()
    }
  }

  public componentDidUpdate(prevProps: IAutocompleteLocationInputProps) {
    const { onResetClick, isDisabled, biasCountry } = this.props

    if (biasCountry !== prevProps.biasCountry) {
      this.generateBiasLocation()
    }

    if (onResetClick && onResetClick !== prevProps.onResetClick) {
      // forceUpdate is required to for the ref to work
      this.forceUpdate()
    }

    if (this.state.isDisabled !== isDisabled) {
      this.setState({
        isDisabled: !!isDisabled,
      })
    }
  }

  public render(): JSX.Element {
    const { addressTypesIncluded, placeholder, onSuggestSelect, onSuggestNoResults, initialValue, id, name } =
      this.props

    const { location } = this.state

    // @types/react-geosuggest has the wrong type for this, so we have to pretend it's something else
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderSuggestItem: any = (suggest: any, userInputText: string) => (
      <TextMatch
        mainText={suggest.description}
        searchText={userInputText}
        matchTextProps={{ weight: Variables.FontWeight.fwSemiBold }}
      />
    )

    return (
      <StyledAutocompleteLocationInput data-cy="input-auto-complete-location">
        {this.resetButton}
        <Geosuggest
          id={id || name}
          ref={this.geosuggestRef}
          initialValue={initialValue}
          placeholder={placeholder}
          types={addressTypesIncluded}
          onSuggestSelect={onSuggestSelect}
          getSuggestLabel={(suggest) => {
            if (suggest.terms.length === 0) {
              return suggest.description
            }

            return suggest.description
              .slice(0, suggest.terms[suggest.terms.length - 1].offset)
              .trim()
              .replace(/,$/, '')
          }}
          renderSuggestItem={renderSuggestItem}
          onSuggestNoResults={onSuggestNoResults}
          location={location ? location : undefined}
          radius={location ? biasRadius : undefined}
          disabled={this.state.isDisabled}
        />
      </StyledAutocompleteLocationInput>
    )
  }

  private generateBiasLocation = () => {
    const { biasCountry } = this.props

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const googleMaps = window.google && window.google.maps
    if (biasCountry && googleMaps) {
      const geocoder = new googleMaps.Geocoder()
      const options: google.maps.GeocoderRequest = {
        address: biasCountry,
      }

      geocoder.geocode(options, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === googleMaps.GeocoderStatus.OK) {
          const gmaps = results[0]
          const location = gmaps.geometry.location
          this.setState({ location })
        }
      })
    }
  }

  private handleReset = () => {
    const { onResetClick } = this.props

    if (onResetClick) {
      onResetClick()
    }

    if (this.geosuggestRef.current) {
      this.geosuggestRef.current.clear()

      this.setState(
        {
          isDisabled: false,
        },
        () => {
          if (this.geosuggestRef.current) {
            this.geosuggestRef.current.focus()
          }
        },
      )
    }
  }
}

export type { IAutocompleteLocationInputProps }
export { AutocompleteLocationInput }
