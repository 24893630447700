export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BulkDataImportActionInformation: unknown;
  /** The raw value of a custom field. This scalar's value depends upon the kind of custom field, but is generally a string. */
  CustomFieldValueValue: unknown;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  DateTime: string;
  JSON: Record<string, unknown>;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Record<string, unknown>;
  PaginationAmount: number;
  /**
   * A date string in the format YYYY-MM-DD. This date will be always interpreted in the tenant's timezone, rather
   * than using the time from the client. This is useful for events that should be backend driven, such as form send
   * dates and so on.
   */
  TenantDate: string;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /**
   * A valid timezone string identifier, such as `Australia/Brisbane`. As an input this forces the timezone to be
   * one of the supported timezones for the backend, or if not supported will use the built-in tenant timezone
   * instead (logging an error message)
   */
  Timezone: string;
  UUID: any;
};

export type AccessKey = {
  __typename?: 'AccessKey';
  /** The date the access key was created on */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The human-readable name of the access key */
  name: Scalars['String'];
};

export type AccessKeyMutation = {
  __typename?: 'AccessKeyMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Creates a access key with the given input */
  createAccessKey?: Maybe<CreateAccessKeyResponse>;
  /** Deletes the given access key */
  deleteAccessKey?: Maybe<DeleteAccessKeyResponse>;
};


export type AccessKeyMutationcreateAccessKeyArgs = {
  input: CreateAccessKeyInput;
};


export type AccessKeyMutationdeleteAccessKeyArgs = {
  input: DeleteAccessKeyInput;
};

export type AccessibleAnalyticsFeatureInfo = {
  __typename?: 'AccessibleAnalyticsFeatureInfo';
  /**
   * individual form data analytics is very different from the standard pages,
   * because it's url patterns are quite different from the standard,
   * especially each form can be accessed differently through individual
   * analytics permissions configuration.
   */
  individualFormDataAnalysis: AccessibleAnalyticsFeatureValue;
  pages: Array<AccessibleAnalyticsPageInfo>;
};

export enum AccessibleAnalyticsFeatureValue {
  /** enabled in the module but not permitted */
  ENABLED = 'ENABLED',
  /** not enabled in the module (also not accessible) */
  NO_ACCESS = 'NO_ACCESS',
  /** both module enabled and permitted by analytics permission */
  PERMITTED = 'PERMITTED'
}

export type AccessibleAnalyticsPageInfo = {
  __typename?: 'AccessibleAnalyticsPageInfo';
  /** page code that is normally used in the backend(normally underscored) */
  code: Scalars['String'];
  /** is this page permitted for access by analytics permission */
  isPermitted: Scalars['Boolean'];
  /**
   * if the page is standard analytics exploration page that share similar
   * settings and permission configurations
   * (anonymous form analytics is not a standard page).
   */
  isStandardPage: Scalars['Boolean'];
  /** page key that is normally used in the frontend (normally camelCased) */
  key: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  /** The address type that the address belongs to */
  addressType: AddressType;
  /** The country the address is in */
  country?: Maybe<Scalars['String']>;
  customFieldValues: Array<CustomFieldValue>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: Maybe<Scalars['String']>;
  /** The full readable address, minus the postcode and country */
  fullAddress: Scalars['String'];
  id: Scalars['ID'];
  /** If this is the primary address */
  isPrimary: Scalars['Boolean'];
  /** The latitude of the address */
  latitude?: Maybe<Scalars['Float']>;
  /** The longitude of the address */
  longitude?: Maybe<Scalars['Float']>;
  /** The postcode of the address */
  postcode?: Maybe<Scalars['String']>;
  /**
   * "
   * The state the address is in.
   * This is australia-specific and may not always be provided.
   */
  state?: Maybe<Scalars['String']>;
  /**
   * The street the address is on.
   * This is australia-specific and may not always be provided.
   */
  street?: Maybe<Scalars['String']>;
  /**
   * "
   * The suburb the address is in.
   * This is australia-specific and may not always be provided.
   */
  suburb?: Maybe<Scalars['String']>;
};


export type AddresscustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateInput = {
  /** The type of address */
  addressTypeId: Scalars['ID'];
  /** The country the address is in */
  country: Scalars['String'];
  /** Custom field values for the address */
  customFieldValues?: InputMaybe<Array<AddressCustomFieldValueInput>>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: InputMaybe<Scalars['String']>;
  /** The full readable address, minus the postcode and country */
  fullAddress?: InputMaybe<Scalars['String']>;
  /** Each person can have only one primary address. If true this will be the address the system defaults to primary. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /**
   * The latitude of the address.
   * This will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  latitude?: InputMaybe<Scalars['Float']>;
  /**
   * The longitude of the address.
   * This will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The ID of the person to whom the address belongs to */
  personId: Scalars['ID'];
  /** The postcode of the address */
  postcode?: InputMaybe<Scalars['String']>;
  /**
   * The state the address is in.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  state?: InputMaybe<Scalars['String']>;
  /**
   * The street the address is on.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  street?: InputMaybe<Scalars['String']>;
  /**
   * The suburb the address is in.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  suburb?: InputMaybe<Scalars['String']>;
};

export type AddressCreateResponse = {
  __typename?: 'AddressCreateResponse';
  address?: Maybe<Address>;
};

export type AddressCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  /** The ID of the address that was deleted */
  id: Scalars['ID'];
};

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  /** The human-readable name of the address type */
  name: Scalars['String'];
};

export type AddressTypeFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type AddressUpdateInput = {
  /** The ID of the person to whom the address belongs to */
  addressId: Scalars['ID'];
  /** The type of address */
  addressTypeId?: InputMaybe<Scalars['ID']>;
  /** The country the address is in */
  country?: InputMaybe<Scalars['String']>;
  /** Custom field values for the address */
  customFieldValues?: InputMaybe<Array<AddressCustomFieldValueInput>>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: InputMaybe<Scalars['String']>;
  /** The full readable address, minus the postcode and country */
  fullAddress?: InputMaybe<Scalars['String']>;
  /** Each person can have only one primary address. If true this will be the address the system defaults to primary. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /**
   * The latitude of the address.
   * This will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  latitude?: InputMaybe<Scalars['Float']>;
  /**
   * The longitude of the address.
   * This will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The postcode of the address */
  postcode?: InputMaybe<Scalars['String']>;
  /**
   * The state the address is in.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  state?: InputMaybe<Scalars['String']>;
  /**
   * The street the address is on.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  street?: InputMaybe<Scalars['String']>;
  /**
   * The suburb the address is in.
   * This is australia-specific and will attempted to be autogenerated using full address.
   * can be overridden by providing this key.
   */
  suburb?: InputMaybe<Scalars['String']>;
};

export type AddressUpdateResponse = {
  __typename?: 'AddressUpdateResponse';
  address?: Maybe<Address>;
  person: Person;
};

export type AlignmentNode = {
  __typename?: 'AlignmentNode';
  goal?: Maybe<AlignmentNodeGoal>;
  hasFullViewPermission: Scalars['Boolean'];
  id: Scalars['ID'];
  isFilled: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  parentGoalId?: Maybe<Scalars['ID']>;
  person: Person;
};

export type AlignmentNodeGoal = {
  __typename?: 'AlignmentNodeGoal';
  approvalStatus: GoalApprovalStatus;
  id: Scalars['ID'];
  name: Scalars['String'];
  progress: Scalars['Float'];
};

export type AllowedDateFormat = {
  __typename?: 'AllowedDateFormat';
  name: Scalars['String'];
  value: DateFormat;
};

export type AllowedTimeFormat = {
  __typename?: 'AllowedTimeFormat';
  name: Scalars['String'];
  value: TimeFormat;
};

export type AllowedTimezone = {
  __typename?: 'AllowedTimezone';
  /** The human readable name of this timezone. */
  name: Scalars['String'];
  /** The offset of this timezone from UTC in seconds */
  offset: Scalars['Int'];
  /** The internal name of the timezone, as supported by the setTenantConfig and setUserConfig mutations. */
  timezone: Scalars['Timezone'];
};

export type AnalyticsConfigurationMutation = {
  __typename?: 'AnalyticsConfigurationMutation';
  setAnalyticsConfigurationValue: SetAnalyticsConfigurationResponse;
  setAnalyticsNavConfiguration: SetAnalyticsConfigurationResponse;
};


export type AnalyticsConfigurationMutationsetAnalyticsConfigurationValueArgs = {
  input: SetAnalyticsConfigurationValueInput;
  userId: Scalars['ID'];
};


export type AnalyticsConfigurationMutationsetAnalyticsNavConfigurationArgs = {
  input: SetAnalyticsNavConfigurationInput;
  userId: Scalars['ID'];
};

export type AnalyticsConfigurationQuery = {
  __typename?: 'AnalyticsConfigurationQuery';
  getUserConfiguration: UserAnalyticsConfiguration;
  getUserConfigurationValue: AnalyticsConfigurationValue;
};


export type AnalyticsConfigurationQuerygetUserConfigurationArgs = {
  userId: Scalars['ID'];
};


export type AnalyticsConfigurationQuerygetUserConfigurationValueArgs = {
  path?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type AnalyticsConfigurationValue = {
  __typename?: 'AnalyticsConfigurationValue';
  data?: Maybe<Scalars['JSONObject']>;
};

export type AnalyticsDomainQuery = {
  __typename?: 'AnalyticsDomainQuery';
  /**
   * Query analytics features the current user has access to, based on the
   * user/tenant modules.
   * Additionally, the query also provide information about accessibility
   * based on the permission setting.
   */
  getAccessibleAnalyticsFeatureInfo?: Maybe<AccessibleAnalyticsFeatureInfo>;
};

export type AnalyticsFormDataFormDesign = {
  __typename?: 'AnalyticsFormDataFormDesign';
  /** The category of the form design */
  category?: Maybe<FormDesignCategory>;
  /** The description of the form design */
  description?: Maybe<Scalars['String']>;
  /** Whether the statistics are estimated */
  estimation: Scalars['Boolean'];
  /** The ID of the form design instance */
  id: Scalars['ID'];
  /** The date which the associated form instance was last completed */
  lastCompletedAt?: Maybe<Scalars['DateTime']>;
  /** The date which the associated form instance was last issued */
  lastIssuedAt?: Maybe<Scalars['DateTime']>;
  /** The human-readable name of the form design */
  name: Scalars['String'];
  /** The number of issued form instances given the form design */
  numAllFormInstances: Scalars['Int'];
  /** The number of completed form instances given the form design */
  numCompletedFormInstances: Scalars['Int'];
};

export type AnalyticsFormDataQuery = {
  __typename?: 'AnalyticsFormDataQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  listFormDesignStats: Array<AnalyticsFormDataStats>;
  listFormDesigns: Array<AnalyticsFormDataFormDesign>;
};


export type AnalyticsFormDataQuerylistFormDesignStatsArgs = {
  confRefreshedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type AnalyticsFormDataQuerylistFormDesignsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type AnalyticsFormDataStats = {
  __typename?: 'AnalyticsFormDataStats';
  /** The category of the form design */
  category?: Maybe<FormDesignCategory>;
  /** Does this form design have a custom user config? */
  customisation: Scalars['Boolean'];
  /** The description of the form design */
  description?: Maybe<Scalars['String']>;
  /** Whether the statistics are estimated */
  estimation: Scalars['Boolean'];
  /** The ID of the form design instance */
  id: Scalars['ID'];
  /** The date which the associated form instance was last completed */
  lastCompletedAt?: Maybe<Scalars['DateTime']>;
  /** The date which the associated form instance was last issued */
  lastIssuedAt?: Maybe<Scalars['DateTime']>;
  /** The human-readable name of the form design */
  name: Scalars['String'];
  /** The number of issued form instances given the form design */
  numAllFormInstances: Scalars['Int'];
  /** The number of completed form instances given the form design */
  numCompletedFormInstances: Scalars['Int'];
};

export type AnalyticsNavConfiguration = {
  __typename?: 'AnalyticsNavConfiguration';
  view: AnalyticsNavViewType;
};

export enum AnalyticsNavViewType {
  category = 'category',
  list = 'list'
}

export type AnalyticsPermissionCheck = {
  __typename?: 'AnalyticsPermissionCheck';
  /** Whether the user has access to any analytics page */
  access: Scalars['Boolean'];
  /** An object showing which anonymous forms the user has permission to view */
  anonymousForms: Scalars['JSONObject'];
  /** A key/value pair object showing whether the user has permission to view a particular form */
  forms: Scalars['JSONObject'];
  /** A key/value pair object showing whether the user has permission to view a particular page */
  pages: Scalars['JSONObject'];
  /** An object showing the sensitive information protection configuration configured on the user's permission */
  sensitiveInfo: Scalars['JSONObject'];
  /** The associated permission group of the user */
  sourcePermissionGroup?: Maybe<PermissionGroup>;
  /** The source of the permission inherited from */
  sourceType?: Maybe<Scalars['String']>;
  /** Whether the user has permission to view all analytics pages */
  viewAllPages: Scalars['Boolean'];
};

export type AnalyticsPermissionMutation = {
  __typename?: 'AnalyticsPermissionMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Delete the specified permission-group-type permission record */
  deleteAnalyticsPermissionPermissionGroupType: DeleteAnalyticsPermissionResponse;
  /** Delete the specified user-type permission record */
  deleteAnalyticsPermissionUserType: DeleteAnalyticsPermissionResponse;
  deleteTenantPermissionConfiguration: DeleteTenantPermissionConfigurationResponse;
  /** Create a new permission-group type permission record or update an existing one with the given input */
  updateAnalyticsPermissionPermissionGroupType: AnalyticsPermissionPermissionGroupType;
  /** Create a new user-type permission record or update an existing one with the given input */
  updateAnalyticsPermissionUserType: AnalyticsPermissionUserType;
  /** Create a new tenant permission configuration */
  updateTenantPermissionConfiguration: TenantPermissionConfigurationRecord;
};


export type AnalyticsPermissionMutationdeleteAnalyticsPermissionPermissionGroupTypeArgs = {
  id: Scalars['ID'];
};


export type AnalyticsPermissionMutationdeleteAnalyticsPermissionUserTypeArgs = {
  id: Scalars['ID'];
};


export type AnalyticsPermissionMutationupdateAnalyticsPermissionPermissionGroupTypeArgs = {
  config: Scalars['JSON'];
  id: Scalars['ID'];
};


export type AnalyticsPermissionMutationupdateAnalyticsPermissionUserTypeArgs = {
  config: Scalars['JSON'];
  id: Scalars['ID'];
};


export type AnalyticsPermissionMutationupdateTenantPermissionConfigurationArgs = {
  config: Scalars['JSON'];
};

export type AnalyticsPermissionPermissionGroupType = {
  __typename?: 'AnalyticsPermissionPermissionGroupType';
  /** The permission configuration for the entity */
  config: Scalars['JSONObject'];
  /** The date when the permission record was created */
  createdAt: Scalars['DateTime'];
  /** The associated permission group */
  permissionGroup: PermissionGroup;
  /** The date when the permission record was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnalyticsPermissionQuery = {
  __typename?: 'AnalyticsPermissionQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** A query to check the analytics permissions of a user */
  checkAnalyticsPermission: AnalyticsPermissionCheck;
  /** A query to check the analytics permissions of the subject of the JWT */
  checkMyAnalyticsPermission: AnalyticsPermissionCheck;
  /** A query to check the tenant level analytics permission setting */
  getTenantPermissionConfiguration: TenantPermissionConfigurationRecord;
};


export type AnalyticsPermissionQuerycheckAnalyticsPermissionArgs = {
  userId: Scalars['ID'];
};

export type AnalyticsPermissionUserType = {
  __typename?: 'AnalyticsPermissionUserType';
  /** The permission configuration for the entity */
  config: Scalars['JSONObject'];
  /** The date when the permission record was created */
  createdAt: Scalars['DateTime'];
  /** The date when the permission record was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The associated user */
  user: User;
};

export type AnalyticsSettingQuery = {
  __typename?: 'AnalyticsSettingQuery';
  defaultBusinessPerformanceScoreScale: DefaultBusinessPerformanceScoreScale;
};

export type AnalyticsSettingsMutation = {
  __typename?: 'AnalyticsSettingsMutation';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  deleteCustomExchangeRates: Scalars['Boolean'];
  deletePersonNameFormat?: Maybe<Scalars['String']>;
  setCustomExchangeRates: CustomExchangeRatesResponse;
  setInsightDigest: Scalars['Boolean'];
  updatePersonNameFormat?: Maybe<Scalars['String']>;
};


export type AnalyticsSettingsMutationsetCustomExchangeRatesArgs = {
  input: CustomExchangeRatesInput;
};


export type AnalyticsSettingsMutationsetInsightDigestArgs = {
  input: InsightDigestInput;
};


export type AnalyticsSettingsMutationupdatePersonNameFormatArgs = {
  input?: InputMaybe<UpdatePersoNameFormatInput>;
};

export type AnalyticsSettingsQuery = {
  __typename?: 'AnalyticsSettingsQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  customExchangeRates?: Maybe<CustomExchangeRatesResponse>;
  defaultBusinessPerformanceScoreScale: DefaultBusinessPerformanceScoreScale;
  insightDigest: InsightDigestResponse;
  insightDigestEmailRecipientUsers: Array<User>;
  personNameFormat?: Maybe<Scalars['String']>;
  tenantUsedCurrencies: Array<TenantUsedCurrency>;
};

export type AnalyticsSupportDataQuery = {
  __typename?: 'AnalyticsSupportDataQuery';
  /**
   * Query form design info (with from design details and
   * compatible version info)
   */
  getFormDesignInfo?: Maybe<FormDesignInfo>;
  /**
   * Query to get anonymous form design stats within a certain
   * date range
   */
  listAnonymousFormDesignStats: Array<AnonymousFormDesignStats>;
  /**
   * Query to get list of form designs that can be used in analytics
   * efficiently. (there is performance issue in the lapis counter part)
   */
  listCompactFormDesigns?: Maybe<Array<CompactFormDesign>>;
};


export type AnalyticsSupportDataQuerygetFormDesignInfoArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  formDesignId: Scalars['UUID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};


export type AnalyticsSupportDataQuerylistAnonymousFormDesignStatsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type AncestorOrganisationObjective = {
  __typename?: 'AncestorOrganisationObjective';
  levelsAboveGoal: Scalars['Int'];
  orgObjective: OrganisationObjective;
};

export type AnonymousFormDataAnalyticsQuery = {
  __typename?: 'AnonymousFormDataAnalyticsQuery';
  /**
   * Query raw text response data and information for
   * a specific anonymous form question
   */
  getRawTextData?: Maybe<AnonymousFormRawTextData>;
};


export type AnonymousFormDataAnalyticsQuerygetRawTextDataArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  fieldId: Scalars['UUID'];
  formDesignId: Scalars['UUID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type AnonymousFormDesign = {
  __typename?: 'AnonymousFormDesign';
  /**
   * Specifies the number of business days after the issue of the form, after which answers cannot be submitted
   * @deprecated autoIgnorePeriod is deprecated. Use cutoffPeriod instead.
   */
  autoIgnorePeriod?: Maybe<Scalars['Int']>;
  /** The category of the form design */
  category?: Maybe<FormDesignCategory>;
  /** When the anonymous form design was created */
  createdAt: Scalars['DateTime'];
  /** The person who created this anonymous form design */
  createdByPerson?: Maybe<Person>;
  /** Specifies the number of business days after the issue of the form, after which answers cannot be submitted */
  cutoffPeriod?: Maybe<Scalars['Int']>;
  /** A short summary of what the anonymous form design is about */
  description?: Maybe<Scalars['String']>;
  /** The form field values as an array */
  formFields: Array<FormFieldValueInterface>;
  /** The form respondent type that the form design belongs to */
  formRespondentType: FormRespondentType;
  /** The form respondent type that the form design belongs to represented as an enum */
  formRespondentTypeEnum: FormRespondentTypeEnum;
  id: Scalars['ID'];
  /** Whether the anonymous form design is archived or not */
  isArchived: Scalars['Boolean'];
  /** Whether the Anonymous Form Design is still editable. It won't be after it's in a pulse that has been queued */
  isEditable?: Maybe<Scalars['Boolean']>;
  /** The human-readable name of the anonymous form design */
  name: Scalars['String'];
  /** The notification method */
  notificationMethod: NotificationMethod;
  /**
   * Specifies the number of business days after the issue date of the form that a
   * reminder can be sent to the respondent. The reminder will be sent at the same
   * time of day that the form was pulsed out to the respondent.
   */
  reminderPeriod?: Maybe<Scalars['Int']>;
  /** When the anonymous form design was last updated */
  updatedAt: Scalars['DateTime'];
};

export type AnonymousFormDesignCreateInput = {
  /** The category of the form design */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** Specifies the number of business days after the issue of the form, after which answers cannot be submitted */
  cutoffPeriod?: InputMaybe<Scalars['Int']>;
  /** A short summary of what the form is about */
  description?: InputMaybe<Scalars['String']>;
  /** The human-readable name of the form design */
  name: Scalars['String'];
  /** The notification method */
  notificationMethodId?: InputMaybe<Scalars['ID']>;
  /**
   * Specifies the number of business days after the issue date of the form that a
   * reminder can be sent to the respondent. The reminder will be sent at the same
   * time of day that the form was pulsed out to the respondent.
   */
  reminderPeriod?: InputMaybe<Scalars['Int']>;
};

export type AnonymousFormDesignDeferred = {
  __typename?: 'AnonymousFormDesignDeferred';
  anonymousFormDesign?: Maybe<AnonymousFormDesign>;
};

export type AnonymousFormDesignDeleteResponse = {
  __typename?: 'AnonymousFormDesignDeleteResponse';
  id: Scalars['ID'];
};

export type AnonymousFormDesignResponse = {
  __typename?: 'AnonymousFormDesignResponse';
  anonymousFormDesign: AnonymousFormDesign;
};

export type AnonymousFormDesignStats = {
  __typename?: 'AnonymousFormDesignStats';
  category?: Maybe<FormDesignCategory>;
  description: Scalars['String'];
  id: Scalars['UUID'];
  isRestricted: Scalars['Boolean'];
  latestPulseCutoffDate: Scalars['DateTime'];
  name: Scalars['String'];
  numIssued: Scalars['Int'];
  numResponses: Scalars['Int'];
  pulseCount: Scalars['Int'];
};

export type AnonymousFormDesignUpdateInput = {
  /** The category of the form design */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** Specifies the number of business days after the issue of the form, after which answers cannot be submitted */
  cutoffPeriod?: InputMaybe<Scalars['Int']>;
  /** A short summary of what the form is about */
  description?: InputMaybe<Scalars['String']>;
  /** The anonymous form design id to update */
  id: Scalars['ID'];
  /** The human-readable name of the form design */
  name?: InputMaybe<Scalars['String']>;
  /** The notification method */
  notificationMethodId?: InputMaybe<Scalars['ID']>;
  /**
   * Specifies the number of business days after the issue date of the form that a
   * reminder can be sent to the respondent. The reminder will be sent at the same
   * time of day that the form was pulsed out to the respondent.
   */
  reminderPeriod?: InputMaybe<Scalars['Int']>;
};

export type AnonymousFormRawTextData = {
  __typename?: 'AnonymousFormRawTextData';
  pulses: Array<AnonymousFormRawTextDataByPulse>;
};

export type AnonymousFormRawTextDataByPulse = {
  __typename?: 'AnonymousFormRawTextDataByPulse';
  id: Scalars['UUID'];
  texts: Array<AnonymousFormRawTextDataEntry>;
};

export type AnonymousFormRawTextDataEntry = {
  __typename?: 'AnonymousFormRawTextDataEntry';
  insight?: Maybe<Scalars['JSON']>;
  raw?: Maybe<Scalars['String']>;
};

export type AnonymousFormResponseAggregatedNumbers = {
  __typename?: 'AnonymousFormResponseAggregatedNumbers';
  anonymousFormResponse: Scalars['Int'];
  emailFailedToSent: Scalars['Int'];
  emailSent: Scalars['Int'];
};

export type AnonymousPulseFilters = {
  /** Whether people on extended leave should be excluded or not */
  excludePeopleOnExtendedLeave: Scalars['Boolean'];
  /** Whether people with probation period should be excluded or not */
  excludePeopleWithinProbationPeriod: Scalars['Boolean'];
  /** Whether people without user account should be excluded or not */
  excludeRespondentsWithoutEnabledUsers: Scalars['Boolean'];
  /** Array of Business Entity ids to include. Null indicates that this filter is not enabled */
  includeBusinessEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Business Unit ids to include. Null indicates that this filter is not enabled */
  includeBusinessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Location ids to include. Null indicates that this filter is not enabled */
  includeLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Pay Grade IDs to include. Null indicates that this filter is not enabled */
  includePayGradeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Work Class ids to include. Null indicates that this filter is not enabled */
  includeWorkClassIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AppLink = {
  __typename?: 'AppLink';
  href: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type AppList = {
  __typename?: 'AppList';
  externalApps: Array<AppLink>;
  nativeApps: Array<AppLink>;
};

export enum ApprovalActionType {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  NO_ACTION = 'NO_ACTION'
}

export type ApproverDetail = {
  __typename?: 'ApproverDetail';
  approvalOrder: JobApprovalOrder;
  person: Person;
};

export enum ApproverType {
  BUSINESS_UNIT_REPRESENTATIVE = 'BUSINESS_UNIT_REPRESENTATIVE',
  LOCATION_REPRESENTATIVE = 'LOCATION_REPRESENTATIVE',
  SUBJECT = 'SUBJECT',
  SUPERVISOR = 'SUPERVISOR',
  SUPERVISORS_SUPERVISOR = 'SUPERVISORS_SUPERVISOR',
  USER_ID = 'USER_ID'
}

export type AssignSkillToPersonInput = {
  personId: Scalars['ID'];
  skillId: Scalars['ID'];
  skillLevelId: Scalars['ID'];
};

export type AssignSkillToPersonResponse = {
  __typename?: 'AssignSkillToPersonResponse';
  assignedSkills: Array<AssignedSkill>;
};

export type AssignedSkill = {
  __typename?: 'AssignedSkill';
  /** Whether this skill has been achieved */
  achieved: Scalars['Boolean'];
  /** When this skill was achieved */
  achievedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The person this skill is assigned to */
  person: Person;
  /** The details of this skill */
  skill: Skill;
  /** The skill level of this skill */
  skillLevel: SkillLevel;
};

export type AssignedSkillFilter = {
  achieved?: InputMaybe<BooleanFilter>;
  personId?: InputMaybe<ValueFilter>;
  skillId?: InputMaybe<ValueFilter>;
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  actioningUser?: Maybe<User>;
  data: Scalars['JSON'];
  date: Scalars['DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  triggeredByBulkDataImport: Scalars['Boolean'];
};

export type AuditLogListResponse = PaginationResponse & {
  __typename?: 'AuditLogListResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  records: Array<AuditLogEntry>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum AuditLogSort {
  ACTIONER_ASCENDING = 'ACTIONER_ASCENDING',
  ACTIONER_DESCENDING = 'ACTIONER_DESCENDING',
  ACTION_ASCENDING = 'ACTION_ASCENDING',
  ACTION_DESCENDING = 'ACTION_DESCENDING',
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING'
}

export type AuthCheckPerformResponse = {
  __typename?: 'AuthCheckPerformResponse';
  /** The list of authorisation results */
  authorisations: Array<AuthCheckResourcePerformResponse>;
};

export type AuthCheckResourcePerformResponse = {
  __typename?: 'AuthCheckResourcePerformResponse';
  /** Whether this check passes for this permission */
  passes: Scalars['Boolean'];
  /** Permission for this check */
  permissionSlug: Scalars['String'];
  /** Id of the resource */
  resourceId: Scalars['ID'];
  /** Type of the resource (e.g. person) */
  resourceType: Scalars['String'];
  /** Id of the source user */
  sourceUserId: Scalars['ID'];
};

export type AuthDeviceMetadata = {
  __typename?: 'AuthDeviceMetadata';
  /** A unique identifier the device, used alongside the refresh token to get a new access token */
  deviceKey?: Maybe<Scalars['ID']>;
};

export type AuthLoginInput = {
  /** The password for the user to authenticate the login */
  password: Scalars['String'];
  /** The user that is logging in */
  username: Scalars['String'];
};

export type AuthLoginResponse = {
  __typename?: 'AuthLoginResponse';
  /** The token that contains what the currently logged in user has access to */
  accessToken?: Maybe<Scalars['String']>;
  /** metadata about the device */
  deviceMetadata?: Maybe<AuthDeviceMetadata>;
  /** The token used to identify who the currently logged in user is */
  idToken?: Maybe<Scalars['String']>;
  /** The token used to refresh the access and id tokens */
  refreshToken?: Maybe<Scalars['String']>;
};

export type AuthLogoutInput = {
  /** The unique device key that belongs to the logged in computer */
  deviceKey: Scalars['String'];
};

export type AuthLogoutResponse = {
  __typename?: 'AuthLogoutResponse';
  deviceKey?: Maybe<Scalars['String']>;
};

export type AuthMutation = {
  __typename?: 'AuthMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  deleteUserPermissionGroup: DeleteUserPermissionGroupResponse;
  login?: Maybe<AuthLoginResponse>;
  logout?: Maybe<AuthLogoutResponse>;
  refresh?: Maybe<AuthRefreshResponse>;
};


export type AuthMutationdeleteUserPermissionGroupArgs = {
  input: AuthUserPermissionGroupInput;
};


export type AuthMutationloginArgs = {
  input: AuthLoginInput;
};


export type AuthMutationlogoutArgs = {
  input: AuthLogoutInput;
};


export type AuthMutationrefreshArgs = {
  input: AuthRefreshInput;
};

export type AuthQuery = {
  __typename?: 'AuthQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Perform a series of hierarchical auth checks against some target resources (usually a person or list of people) */
  performHierarchicalAuthChecks?: Maybe<PerformAuthChecksResponse>;
  /** Perform a series of hierarchical auth checks against some target resources (usually a person or list of people) */
  performHierarchicalAuthChecksForCustomFieldModel?: Maybe<PerformAuthChecksResponse>;
  /** Perform a single hierarchical auth check against one target resource (usually a person) */
  performSingleHierarchicalAuthCheck?: Maybe<PerformAuthChecksResponse>;
  /** Perform a single hierarchical auth check against one target resource (usually a person) */
  performSingleHierarchicalAuthCheckForCustomFieldModel?: Maybe<PerformAuthChecksResponse>;
};


export type AuthQueryperformHierarchicalAuthChecksArgs = {
  input: PerformMultipleAuthChecksInput;
};


export type AuthQueryperformHierarchicalAuthChecksForCustomFieldModelArgs = {
  input: PerformMultipleAuthChecksForCustomFieldModelInput;
};


export type AuthQueryperformSingleHierarchicalAuthCheckArgs = {
  input: PerformSingleAuthCheckInput;
};


export type AuthQueryperformSingleHierarchicalAuthCheckForCustomFieldModelArgs = {
  input: PerformSingleAuthCheckForCustomFieldModelInput;
};

export type AuthRefreshInput = {
  /** The unique device key that belongs to the logged in computer */
  deviceKey: Scalars['String'];
  /** The token used to refresh the access and id tokens */
  refreshToken: Scalars['String'];
};

export type AuthRefreshResponse = {
  __typename?: 'AuthRefreshResponse';
  /** The token that contains what the currently logged in user has access to */
  accessToken?: Maybe<Scalars['String']>;
  /** The token used to identify who the currently logged in user is */
  idToken?: Maybe<Scalars['String']>;
};

export type AuthSftpInboundCredentialInput = {
  password?: InputMaybe<Scalars['String']>;
  sourceIp: Scalars['String'];
  username: Scalars['String'];
};

export type AuthSftpInboundCredentialResponse = {
  __typename?: 'AuthSftpInboundCredentialResponse';
  result: Scalars['Boolean'];
  sshKeys?: Maybe<Array<Scalars['String']>>;
};

export type AuthUserPermissionGroupInput = {
  /** The id of the permission group */
  id: Scalars['ID'];
};

export type AuthenticateForPrivateKeyInput = {
  password: Scalars['String'];
};

export type AvailableLetterTemplate = {
  __typename?: 'AvailableLetterTemplate';
  htmlLetterContent: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String'];
  branch: BankAccountBranch;
  id: Scalars['ID'];
  name: Scalars['String'];
  rank: Scalars['Int'];
  split: BankAccountSlit;
};

export type BankAccountBranch = {
  __typename?: 'BankAccountBranch';
  address: Scalars['String'];
  branch: Scalars['String'];
  bsb: Scalars['String'];
  mnemonic: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
  suburb: Scalars['String'];
};

export type BankAccountCollection = {
  __typename?: 'BankAccountCollection';
  bankAccounts: Array<BankAccount>;
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  outcome: Scalars['String'];
};

export type BankAccountSlit = {
  __typename?: 'BankAccountSlit';
  kind: Scalars['String'];
  value: Scalars['Int'];
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
};

export type BulkDataImport = {
  __typename?: 'BulkDataImport';
  /**
   * Whether the import is allowed to be deleted.
   * Imports can only be deleted in the UNPROCESSED state.
   */
  canDelete: Scalars['Boolean'];
  /**
   * Whether the import can have its actions deleted.
   * Only permitted in the UNPROCESSED, PAUSED or FAILED states.
   */
  canDeletePendingActions: Scalars['Boolean'];
  /**
   * Whether the import can have new actions added
   * Only permitted in the UNPROCESSED, PAUSED or FAILED states.
   */
  canGenerateActions: Scalars['Boolean'];
  /**
   * Whether the import is allowed to pause processing.
   * Only permitted in the PROCESSING state.
   */
  canPauseProcessing: Scalars['Boolean'];
  /**
   * Whether the import is allowed to start processing.
   * Only permitted in the UNPROCESSED, PAUSED or FAILED states, and only when actions have been added.
   */
  canStartProcessing: Scalars['Boolean'];
  /** When the status is COMPLETED, this will contain the exact date/time the import was completed */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** When the import was first created */
  createdAt: Scalars['DateTime'];
  /**
   * The user that created this import
   * @deprecated Will crash if user is deleted
   */
  createdByUser: User;
  /** The user that created this import */
  createdByUserNullable?: Maybe<User>;
  id: Scalars['ID'];
  /** Human readable name for the import. Must be unique */
  name: Scalars['String'];
  /**
   * The list of actions for this data import. This list is paginated so that not too many are returned
   * at once.
   *
   * These actions can be filtered by status
   */
  paginatedActions: PaginatedBulkDataImportAction;
  /**
   * The status of the import.
   *
   * UNPROCESSED: The import has never been started before.
   * PROCESSING: The start button has been pressed and the import has begun. Some actions have been performed.
   * COMPLETED: All actions in the import have completed. No more actions can be added.
   * FAILED: One or more of the actions was unsuccessful in running, so execution was stopped.
   */
  status: BulkDataImportStatus;
};


export type BulkDataImportpaginatedActionsArgs = {
  paginationOptions: PaginationOptions;
  status?: InputMaybe<Array<BulkDataImportStatus>>;
};

export type BulkDataImportAction = {
  __typename?: 'BulkDataImportAction';
  /** The order in which to perform this action, compared to the others. This is indexed from 1. */
  actionIndex: Scalars['Int'];
  /**
   * A raw blob of JSON containing the information for this action that will be used to perform it.
   * If you are not the owner of this bulk data import, this will not be returned, and instead a message
   * about how You don't have permission to see this information will be returned instead.
   * This is unformatted, and the contents will vary based on the action type.
   */
  actionInformation: Scalars['BulkDataImportActionInformation'];
  /** Human readable subtitle for the action, to differentiate it from similar actions */
  actionSubtitle: Scalars['String'];
  /** Human readable name for the action, based on the action type. */
  actionTitle: Scalars['String'];
  /** The type of action this represents. This is defined in code so that new ones can be dynamically added. */
  actionType: Scalars['String'];
  /** The bulk data import this action is for */
  bulkDataImport: BulkDataImport;
  /**
   * Whether this action can be deleted.
   * Actions can only be deleted in the UNPROCESSED and FAILED states, and only when
   * the overall import is in UNPROCESSED, FAILED or PAUSED.
   */
  canDelete: Scalars['Boolean'];
  /** When status is FAILED, this contains a human readable reason for the failure (e.g. a validation error message) */
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The timestamp the action was processed */
  processedAt?: Maybe<Scalars['DateTime']>;
  /**
   * List of actions that rely on this action before they can be completed
   * NOTE: this is not eager loaded. Can cause major slowdowns if performed for many actions.
   */
  requiredByActions: Array<BulkDataImportAction>;
  /** Ids of actions that this action requires before this can be processed */
  requiresActionIds: Array<Scalars['ID']>;
  /** List of actions that this action requires before it can be processed */
  requiresActions: Array<BulkDataImportAction>;
  /**
   * The status of this particular import action.
   *
   * UNPROCESSED: This action has not been performed.
   * PROCESSING: Unused.
   * COMPLETED: This action has been performed successfully.
   * FAILED: This action was attempted to be performed, but an issue prevented it from being completed.
   */
  status: BulkDataImportStatus;
};

export type BulkDataImportActionDeleteResponse = {
  __typename?: 'BulkDataImportActionDeleteResponse';
  /** The bulk data import this was run on */
  bulkDataImport: BulkDataImport;
  /** The ids of all actions that were deleted */
  deletedIds: Array<Scalars['ID']>;
};

export type BulkDataImportCreateInput = {
  /** The name of the data import to create */
  name: Scalars['String'];
};

export type BulkDataImportCreateResponse = {
  __typename?: 'BulkDataImportCreateResponse';
  /** The data import that was created */
  bulkDataImport: BulkDataImport;
};

export type BulkDataImportDeletePendingActionsResponse = {
  __typename?: 'BulkDataImportDeletePendingActionsResponse';
  /** The bulk data import this was run on */
  bulkDataImport: BulkDataImport;
};

export type BulkDataImportDeleteResponse = {
  __typename?: 'BulkDataImportDeleteResponse';
  /** The ID of the data import that was deleted */
  id: Scalars['ID'];
};

export type BulkDataImportGenerateActionsForUploadInput = {
  /** The ID of the import to generate actions for */
  bulkDataImportId: Scalars['ID'];
  /** the configuration used to generate the actions */
  configs?: InputMaybe<Array<BulkDataImportUploadDownloadConfigurationInput>>;
  /** Whether to actually save the action changes (default: false) */
  dryRun?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the presigned url CSV upload (needed to find the upload in s3) */
  uploadId: Scalars['ID'];
  /** The type of CSV upload that this is */
  uploadTypeString?: InputMaybe<Scalars['String']>;
};

export type BulkDataImportGenerateActionsForUploadResponse = {
  __typename?: 'BulkDataImportGenerateActionsForUploadResponse';
  /** The bulk data import this was run on */
  bulkDataImport: BulkDataImport;
  /** Whether this was a dry run */
  dryRun: Scalars['Boolean'];
  /**
   * List of created actions from the CSV (for dry runs, the IDs will be dummy ids).
   * If validation errors occurred, this will always be empty.
   */
  generatedActions: Array<BulkDataImportAction>;
  /** Whether validation errors occurred when processing the CSV */
  hasValidationErrors: Scalars['Boolean'];
  /** Whether warning messages occurred when processing the CSV */
  hasWarningMessages: Scalars['Boolean'];
  /** The number of validation messages returned */
  validationMessageCount: Scalars['Int'];
  /**
   * A list of validation errors that were found in the CSV file. This list can be quite long if
   * the CSV has many issues with it.
   * Note that if there was a general error (e.g. failed to process the CSV as a CSV, was not
   * uploaded etc) this will still be thrown as a GQL error and not returned from this response.
   */
  validationMessages: Array<Scalars['String']>;
  /** The number of warning messages returned */
  warningMessageCount: Scalars['Int'];
  /** A list of warning messages That were found in the CSV file */
  warningMessages: Array<Scalars['String']>;
};

export type BulkDataImportGeneratePresignedUrlInput = {
  /** The type of CSV upload. This is actually unused in this case, but might be important later. */
  typeString?: InputMaybe<Scalars['String']>;
};

export type BulkDataImportGeneratePresignedUrlResponse = {
  __typename?: 'BulkDataImportGeneratePresignedUrlResponse';
  /** The url the CSV can be uploaded to */
  presignedUrl: Scalars['String'];
  /** An id which must be provided when telling the processor which csv to process (to identify it in s3). */
  uploadId: Scalars['ID'];
};

export type BulkDataImportProcessPauseInput = {
  /** The ID of the bulk data import to pause processing */
  bulkDataImportId: Scalars['ID'];
};

export type BulkDataImportProcessPauseResponse = {
  __typename?: 'BulkDataImportProcessPauseResponse';
  /** The bulk data import this was run on */
  bulkDataImport: BulkDataImport;
};

export type BulkDataImportProcessStartInput = {
  /** The ID of the bulk data import to start processing */
  bulkDataImportId: Scalars['ID'];
};

export type BulkDataImportProcessStartResponse = {
  __typename?: 'BulkDataImportProcessStartResponse';
  /** The bulk data import this was run on */
  bulkDataImport: BulkDataImport;
};

export enum BulkDataImportStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PAUSED = 'PAUSED',
  PROCESSING = 'PROCESSING',
  UNPROCESSED = 'UNPROCESSED'
}

export type BulkDataImportSupportedDownload = {
  __typename?: 'BulkDataImportSupportedDownload';
  /** A url that can be visited which downloads the export csv */
  csvUrl: Scalars['String'];
  /** A short paragraph explaining the use of this download */
  description: Scalars['String'];
  /** The human readable name of this download type */
  name: Scalars['String'];
  supportedColumns?: Maybe<Array<Scalars['String']>>;
  /**
   * The configuration options that can be used to customise the export
   * @deprecated Use supportedConfigurations since it is based on a interface and therefore more stable to changes
   */
  supportedConfigs: Array<BulkDataImportUploadDownloadConfigurationUnion>;
  /** The configuration options that can be used to customise the export */
  supportedConfigurations: Array<BulkDataImportUploadDownloadConfigurationInterface>;
  /** The type of download this is */
  typeString: Scalars['String'];
};


export type BulkDataImportSupportedDownloadcsvUrlArgs = {
  columns?: InputMaybe<Array<Scalars['String']>>;
  configs?: InputMaybe<Array<BulkDataImportUploadDownloadConfigurationInput>>;
};

export type BulkDataImportSupportedUpload = {
  __typename?: 'BulkDataImportSupportedUpload';
  /** A short paragraph explaining the use of this upload */
  description: Scalars['String'];
  /** A url that can be visited which downloads an example CSV for this upload */
  exampleCsvUrl: Scalars['String'];
  /** The maximum rows supported by a bulk data upload */
  maxRows: Scalars['Int'];
  /** The human readable name of this upload type */
  name: Scalars['String'];
  /** Any downloads that are related to this upload */
  relatedDownloadsWithConfig: Array<BulkDataImportUploadRelatedDownload>;
  supportedColumns: Array<BulkDataImportUploadColumn>;
  /**
   * The configuration options that can be used to customise the export
   * @deprecated Use supportedConfigurations since it is based on a union and therefore more stable to changes
   */
  supportedConfigs: Array<BulkDataImportUploadDownloadConfigurationUnion>;
  /** The configuration options that can be used to customise the export */
  supportedConfigurations: Array<BulkDataImportUploadDownloadConfigurationInterface>;
  /** The type of upload this is (used when uploading to report which type of csv this is) */
  typeString: Scalars['String'];
  /** In-depth description of the import that includes things such as when to use the import */
  usageGuideText: Scalars['String'];
};


export type BulkDataImportSupportedUploadexampleCsvUrlArgs = {
  configs?: InputMaybe<Array<BulkDataImportUploadDownloadConfigurationInput>>;
};

export type BulkDataImportUploadColumn = {
  __typename?: 'BulkDataImportUploadColumn';
  /** Description of the Bulk Data Import column */
  description: Scalars['String'];
  /** If the column is a required column for import (true) or optional (false) */
  isRequired: Scalars['Boolean'];
  /** Name of the Bulk Data Import column */
  name: Scalars['String'];
  /** The information that is generated from rules, supports markdown */
  ruleInfo: Array<Scalars['String']>;
  /** The calculated input type (e.g. dateTime or Person Lookup) */
  type: Scalars['String'];
};

export type BulkDataImportUploadDownloadConfigurationDateTime = BulkDataImportUploadDownloadConfigurationInterface & {
  __typename?: 'BulkDataImportUploadDownloadConfigurationDateTime';
  /** The unique name the import configuration can be referred to as */
  apiName: Scalars['String'];
  /** The human readable name of the configuration */
  label: Scalars['String'];
};

export type BulkDataImportUploadDownloadConfigurationInput = {
  /** The configuration api name */
  apiName: Scalars['String'];
  /** The chosen multi select value */
  arrayStringValue?: InputMaybe<Array<Scalars['String']>>;
  /** The chosen date. Used for date time configuration (DEPRECATED USE tenantDateTimeValue) */
  dateTimeValue?: InputMaybe<Scalars['DateTime']>;
  /** The chosen value */
  stringValue?: InputMaybe<Scalars['String']>;
  /** The chosen date. Used for date time configuration */
  tenantDateTimeValue?: InputMaybe<Scalars['TenantDate']>;
};

export type BulkDataImportUploadDownloadConfigurationInterface = {
  /** The unique name the import configuration can be referred to as */
  apiName: Scalars['String'];
  /** The human readable name of the configuration */
  label: Scalars['String'];
};

export type BulkDataImportUploadDownloadConfigurationMultiSelect = BulkDataImportUploadDownloadConfigurationInterface & {
  __typename?: 'BulkDataImportUploadDownloadConfigurationMultiSelect';
  /** The unique name the import configuration can be referred to as */
  apiName: Scalars['String'];
  /** The default value that will be prefilled for this configuration. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The human readable name of the configuration */
  label: Scalars['String'];
  /** The human readable options that can be selected */
  optionLabels: Array<Scalars['String']>;
  /**
   * The value that should be provided for each option.
   * These should have the same count as `optionLabels`
   * and are returned in the same order.
   */
  optionValues: Array<Scalars['String']>;
};

export type BulkDataImportUploadDownloadConfigurationSingleSelect = BulkDataImportUploadDownloadConfigurationInterface & {
  __typename?: 'BulkDataImportUploadDownloadConfigurationSingleSelect';
  /** The unique name the import configuration can be referred to as */
  apiName: Scalars['String'];
  /** The default value that will be prefilled for this configuration. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The human readable name of the configuration */
  label: Scalars['String'];
  /** The human readable options that can be selected */
  optionLabels: Array<Scalars['String']>;
  /**
   * The value that should be provided for each option.
   * These should have the same count as `optionLabels`
   * and are returned in the same order.
   */
  optionValues: Array<Scalars['String']>;
};

export type BulkDataImportUploadDownloadConfigurationUnion = BulkDataImportUploadDownloadConfigurationDateTime | BulkDataImportUploadDownloadConfigurationMultiSelect | BulkDataImportUploadDownloadConfigurationSingleSelect;

export type BulkDataImportUploadRelatedDownload = {
  __typename?: 'BulkDataImportUploadRelatedDownload';
  csvUrl: Scalars['String'];
  name: Scalars['String'];
};

export type BulkDeleteFormResponse = {
  __typename?: 'BulkDeleteFormResponse';
  formIds: Array<Scalars['ID']>;
};

export type BulkDeleteScheduledFormResponse = {
  __typename?: 'BulkDeleteScheduledFormResponse';
  scheduledFormIds: Array<Scalars['ID']>;
};

export type BulkDeleteTaskResponse = {
  __typename?: 'BulkDeleteTaskResponse';
  taskIds: Array<Scalars['ID']>;
};

export type BulkIgnoreFormInput = {
  comments?: InputMaybe<Scalars['String']>;
  formIds: Array<Scalars['ID']>;
  formIgnoreReasonId: Scalars['ID'];
};

export type BulkIgnoreFormResponse = {
  __typename?: 'BulkIgnoreFormResponse';
  formIds: Array<Scalars['ID']>;
  status: Scalars['String'];
};

export type BulkIgnoreTaskInput = {
  comments?: InputMaybe<Scalars['String']>;
  formIgnoreReasonId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};

export type BulkIgnoreTaskResponse = {
  __typename?: 'BulkIgnoreTaskResponse';
  status: Scalars['String'];
  taskIds: Array<Scalars['ID']>;
};

export type BulkResendFormResponse = {
  __typename?: 'BulkResendFormResponse';
  formIds: Array<Scalars['ID']>;
  status: Scalars['String'];
};

export type BulkResendTaskResponse = {
  __typename?: 'BulkResendTaskResponse';
  status: Scalars['String'];
  taskIds: Array<Scalars['ID']>;
};

export type BulkUserCreateInput = {
  /**
   * When the users move from restricted to full access.
   * If null the users gain full access immediately.
   */
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  /** Whether the user accounts should be enabled */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Hide the users from onboarding overview */
  hideFromOnboarding?: InputMaybe<Scalars['Boolean']>;
  /**
   * The steps to be completed as part of onboarding
   *
   * Note that validation will fail if the PERSONAL_DETAILS and REQUIRED_QUALIFICATIONS steps are not provided
   */
  onboardingSteps?: InputMaybe<Array<OnboardingSteps>>;
  /**
   * The onboarding form that users need to complete
   * Deprecate when removing the ONBOARDING_FORM
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
  /** The password method for the created users. */
  passwordMethod?: InputMaybe<UserCreatePasswordMethod>;
  /** The people that will have user accounts created */
  people: Array<BulkUserCreatePerson>;
  /** The permission groups that the users will be members of */
  permissionGroups: Array<BulkUserCreatePermissionGroup>;
  /** Whether to send welcome emails to the created users. Defaults to true if not provided. */
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
};

export type BulkUserCreatePermissionGroup = {
  /** The ID of the permission group that the new user will be a member of */
  id: Scalars['ID'];
};

export type BulkUserCreatePerson = {
  /** The ID of the person who will have a user account created */
  id: Scalars['ID'];
};

export type BulkUserCreateResponse = {
  __typename?: 'BulkUserCreateResponse';
  status?: Maybe<Scalars['String']>;
};

export type BulkUserCreationCandidate = {
  __typename?: 'BulkUserCreationCandidate';
  /** Whether the person can have a user account bulk created */
  canHaveUserAccountCreated: Scalars['Boolean'];
  /** Human readable reason why a uesr cannot be created */
  cannotCreateReason: Scalars['String'];
  /** The reason why a uesr cannot be created */
  cannotCreateReasonId: Scalars['ID'];
  id: Scalars['ID'];
  /** The standard Person type */
  person: Person;
};

export type BulkUserCreationCandidates = {
  __typename?: 'BulkUserCreationCandidates';
  /** Human readable reason why a user cannot be created */
  cannotCreateReason?: Maybe<Scalars['String']>;
  /** The reason why a user cannot be created id */
  cannotCreateReasonId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** The user creation candidate */
  person: Person;
};

export type BusinessDays = {
  __typename?: 'BusinessDays';
  friday: Scalars['Boolean'];
  monday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
};

export type BusinessDaysInput = {
  friday: Scalars['Boolean'];
  monday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
};

export type BusinessEntity = {
  __typename?: 'BusinessEntity';
  /** An optional code that relates to the business entity */
  code?: Maybe<Scalars['String']>;
  /** Country the Business Entity operates out of */
  country?: Maybe<Country>;
  customFieldValues: Array<CustomFieldValue>;
  id: Scalars['ID'];
  /** Whether the entity is currently enabled (for dropdown lists) */
  isEnabled: Scalars['Boolean'];
  /** If this business unit is currently being used by an undeleted job */
  isInUse: Scalars['Boolean'];
  /** The count of jobs currently using this business entity */
  jobCount: Scalars['Int'];
  /** The name of the entity for legal purposes */
  legalName?: Maybe<Scalars['String']>;
  /** The display name of the business entity */
  name: Scalars['String'];
  /** Any extra notes and description for the entity */
  notes?: Maybe<Scalars['String']>;
  /** The business number for the entity */
  number?: Maybe<Scalars['String']>;
  /** The SuperAPI Employer details for this entity */
  superApiEmployer?: Maybe<SuperApiEmployer>;
};


export type BusinessEntitycustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessEntityCreateInput = {
  /** An optional code that relates to the business entity */
  code?: InputMaybe<Scalars['String']>;
  /** The ID of the country where this business entity is based */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for business entity */
  customFieldValues?: InputMaybe<Array<InputMaybe<BusinessEntityCustomFieldValueInput>>>;
  /** The intended ID of the business entity - optional */
  id?: InputMaybe<Scalars['ID']>;
  /** optional specifier for if the field is enabled or not */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The legal name of the business entity */
  legalName?: InputMaybe<Scalars['String']>;
  /** The display name of the business entity */
  name: Scalars['String'];
  /** Extra notes to store about the business entity */
  notes?: InputMaybe<Scalars['String']>;
  /** The number (e.g. ABN) of the entity */
  number?: InputMaybe<Scalars['String']>;
};

export type BusinessEntityCreateResponse = {
  __typename?: 'BusinessEntityCreateResponse';
  /** The business entity that has been created. */
  businessEntity: BusinessEntity;
};

export type BusinessEntityCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type BusinessEntityDeleteResponse = {
  __typename?: 'BusinessEntityDeleteResponse';
  /** The ID of the buisiness entity that was deleted */
  id: Scalars['ID'];
};

export type BusinessEntityFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type BusinessEntitySearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  legalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type BusinessEntityUpdateInput = {
  /** An optional code that relates to the business entity */
  code?: InputMaybe<Scalars['String']>;
  /** The ID of the country where this business entity is based */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for business entity */
  customFieldValues?: InputMaybe<Array<InputMaybe<BusinessEntityCustomFieldValueInput>>>;
  /** The ID of the business entity to update */
  id: Scalars['ID'];
  /** Whether the entity is currently enabled (for dropdown lists) */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The legal name of the business entity */
  legalName?: InputMaybe<Scalars['String']>;
  /** The display name of the business entity */
  name?: InputMaybe<Scalars['String']>;
  /** Extra notes to store about the business entity */
  notes?: InputMaybe<Scalars['String']>;
  /** The number (e.g. ABN) of the entity */
  number?: InputMaybe<Scalars['String']>;
};

export type BusinessEntityUpdateResponse = {
  __typename?: 'BusinessEntityUpdateResponse';
  /** the business entity that has been updated */
  businessEntity: BusinessEntity;
};

export type BusinessPerformanceConfigurationConfigurationInput = {
  scale?: InputMaybe<Array<BusinessPerformanceScoreScaleConfigurationConfigurationIntervalInput>>;
};

export type BusinessPerformanceConfigurationInterface = {
  key: BusinessPerformanceConfigurationKey;
};

export enum BusinessPerformanceConfigurationKey {
  SCORE_SCALE = 'SCORE_SCALE'
}

export type BusinessPerformanceConfigurationSetInput = {
  configuration?: InputMaybe<BusinessPerformanceConfigurationConfigurationInput>;
  key: BusinessPerformanceConfigurationKey;
};

export type BusinessPerformanceConfigurationSetResponse = {
  __typename?: 'BusinessPerformanceConfigurationSetResponse';
  businessPerformanceConfiguration: BusinessPerformanceConfigurationInterface;
};

export type BusinessPerformanceMetric = {
  __typename?: 'BusinessPerformanceMetric';
  id: Scalars['ID'];
  name: Scalars['String'];
  peerDefinitions: Array<BusinessPerformanceMetricResponseDefinitionsUnion>;
  sortOrder: Scalars['Int'];
  subjectDefinitions: Array<BusinessPerformanceMetricResponseDefinitionsUnion>;
  supervisorDefinitions: Array<BusinessPerformanceMetricResponseDefinitionsUnion>;
};

export type BusinessPerformanceMetricBulkSetInput = {
  businessPerformanceMetrics: Array<BusinessPerformanceMetricBulkSetMetricInput>;
};

export type BusinessPerformanceMetricBulkSetMetricInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  peerDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
  subjectDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
  supervisorDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
};

export type BusinessPerformanceMetricBulkSetResponse = {
  __typename?: 'BusinessPerformanceMetricBulkSetResponse';
  businessPerformanceMetrics: Array<BusinessPerformanceMetric>;
};

export type BusinessPerformanceMetricCreateInput = {
  name: Scalars['String'];
  peerDefinitions: Array<BusinessPerformanceMetricDefinitionsInput>;
  subjectDefinitions: Array<BusinessPerformanceMetricDefinitionsInput>;
  supervisorDefinitions: Array<BusinessPerformanceMetricDefinitionsInput>;
};

export type BusinessPerformanceMetricCreateResponse = {
  __typename?: 'BusinessPerformanceMetricCreateResponse';
  businessPerformanceMetric: BusinessPerformanceMetric;
};

export type BusinessPerformanceMetricDefinitionsInput = {
  formDesignId?: InputMaybe<Scalars['ID']>;
  formFieldId?: InputMaybe<Scalars['ID']>;
  formItemId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type BusinessPerformanceMetricDeleteResponse = {
  __typename?: 'BusinessPerformanceMetricDeleteResponse';
  id: Scalars['ID'];
};

export type BusinessPerformanceMetricFormDesignFieldResponseType = {
  __typename?: 'BusinessPerformanceMetricFormDesignFieldResponseType';
  formDesignId: Scalars['ID'];
  formFieldId: Scalars['ID'];
  type: Scalars['String'];
};

export type BusinessPerformanceMetricFormLibraryFieldResponseType = {
  __typename?: 'BusinessPerformanceMetricFormLibraryFieldResponseType';
  formItemId: Scalars['ID'];
  type: Scalars['String'];
};

export type BusinessPerformanceMetricResponseDefinitionsUnion = BusinessPerformanceMetricFormDesignFieldResponseType | BusinessPerformanceMetricFormLibraryFieldResponseType;

export type BusinessPerformanceMetricUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  peerDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
  subjectDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
  supervisorDefinitions?: InputMaybe<Array<BusinessPerformanceMetricDefinitionsInput>>;
};

export type BusinessPerformanceMetricUpdateResponse = {
  __typename?: 'BusinessPerformanceMetricUpdateResponse';
  businessPerformanceMetric: BusinessPerformanceMetric;
};

export type BusinessPerformanceScoreScaleConfiguration = BusinessPerformanceConfigurationInterface & {
  __typename?: 'BusinessPerformanceScoreScaleConfiguration';
  configuration: BusinessPerformanceScoreScaleConfigurationConfiguration;
  key: BusinessPerformanceConfigurationKey;
};

export type BusinessPerformanceScoreScaleConfigurationConfiguration = {
  __typename?: 'BusinessPerformanceScoreScaleConfigurationConfiguration';
  scale: Array<BusinessPerformanceScoreScaleConfigurationConfigurationInterval>;
};

export type BusinessPerformanceScoreScaleConfigurationConfigurationInterval = {
  __typename?: 'BusinessPerformanceScoreScaleConfigurationConfigurationInterval';
  label: Scalars['String'];
};

export type BusinessPerformanceScoreScaleConfigurationConfigurationIntervalInput = {
  label: Scalars['String'];
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  /** An optional code that relates to the business unit */
  code?: Maybe<Scalars['String']>;
  customFieldValues: Array<CustomFieldValue>;
  /** How deep in the tree is the business unit */
  depth: Scalars['Int'];
  id: Scalars['ID'];
  /** The unique identifier for the business unit */
  identifier: Scalars['String'];
  /** Whether the unit is currently enabled (for dropdown lists) */
  isEnabled: Scalars['Boolean'];
  /** How many jobs have been assigned to this business unit */
  jobCount: Scalars['Int'];
  /** The display name of the business unit */
  name: Scalars['String'];
  /** Any extra notes and description for the unit */
  notes?: Maybe<Scalars['String']>;
  /** The business unit which is above this unit in the hierarchy, or null if this is a top-level unit. */
  parentBusinessUnit?: Maybe<BusinessUnit>;
  /** Full path to the business unit */
  path: Scalars['String'];
  representatives: Array<Representative>;
  /** Sorting order for the business unit */
  sortOrder: Scalars['Int'];
};


export type BusinessUnitcustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessUnitCreateInput = {
  /** An optional code that relates to the business unit */
  code?: InputMaybe<Scalars['String']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<BusinessUnitCustomFieldValueInput>>>;
  /** The identifier of this business unit */
  identifier?: InputMaybe<Scalars['String']>;
  /** If this business unit starts off disabled or disabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of this business unit */
  name: Scalars['String'];
  /** General notes on this business unit */
  notes?: InputMaybe<Scalars['String']>;
  /** The ID of the parent business unit */
  parentId?: InputMaybe<Scalars['ID']>;
  /** An array of the representatives on the business unit and their associated person */
  representatives?: InputMaybe<Array<BusinessUnitRepresentativeInput>>;
};

export type BusinessUnitCreateResponse = {
  __typename?: 'BusinessUnitCreateResponse';
  /** The business unit that has been created. */
  businessUnit: BusinessUnit;
};

export type BusinessUnitCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type BusinessUnitDeleteResponse = {
  __typename?: 'BusinessUnitDeleteResponse';
  /** The ID of the business unit that was deleted */
  id: Scalars['ID'];
};

export type BusinessUnitFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type BusinessUnitRepresentativeInput = {
  personId?: InputMaybe<Scalars['ID']>;
  representativeTypeId: Scalars['ID'];
};

export type BusinessUnitSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BusinessUnitUpdateInput = {
  /** An optional code that relates to the business unit */
  code?: InputMaybe<Scalars['String']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<BusinessUnitCustomFieldValueInput>>>;
  /** The ID of the business unit that is updated. */
  id: Scalars['ID'];
  /** The identifier of this business unit */
  identifier?: InputMaybe<Scalars['String']>;
  /** If this business unit starts off disabled or disabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of this business unit */
  name: Scalars['String'];
  /** General notes on this business unit */
  notes?: InputMaybe<Scalars['String']>;
  /** The ID of the parent business unit */
  parentId?: InputMaybe<Scalars['ID']>;
  /** An array of the representatives on the business unit and their associated person */
  representatives?: InputMaybe<Array<BusinessUnitRepresentativeInput>>;
};

export type BusinessUnitUpdateResponse = {
  __typename?: 'BusinessUnitUpdateResponse';
  /** The business unit that has been created. */
  businessUnit: BusinessUnit;
};

export type CalculateRemunerationInput = {
  /** Array of additions being applied to this rem schedule */
  additionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Array of additions to total being applied to this rem schedule */
  additionsToTotal?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Base pay per unit of work (required if type is RATE) */
  baseAmountPerUnit?: InputMaybe<Scalars['Float']>;
  /** Base annual amount of pay (required if type is ANNUAL) */
  baseAnnualAmount?: InputMaybe<Scalars['Float']>;
  /** Array of breakdowns being applied to this rem schedule */
  breakdowns?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The currency id used for the base and total amounts on this rem schedule */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** Array of deductions being applied to this rem schedule */
  deductionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The full time equivalent used to calculate the prorated salary */
  fte?: InputMaybe<Scalars['Float']>;
  /** The pay cycle id being used for this rem schedule (i.e. monthly, weekly etc) */
  payCycleId?: InputMaybe<Scalars['ID']>;
  /** The start date of the rem schedule, or null for start of job */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** The schedule type; i.e. Paid annually, or using an hourly rate. */
  type: RemunerationScheduleType;
  /**
   * How many units (i.e. hours worked) there are in this schedule's pay cycle.
   * Typically ~38 for fortnightly paid pay cycles, as an example.
   */
  unitsPerCycle?: InputMaybe<Scalars['Float']>;
};

export type CalculateRemunerationResponse = {
  __typename?: 'CalculateRemunerationResponse';
  /** The remuneration schedule with calculated data */
  remunerationSchedule: JobRemunerationSchedule;
};

export type CascadedGoal = {
  __typename?: 'CascadedGoal';
  /** The person this goal was cascaded down from */
  cascadedBy: Person;
  /** The category of this cascaded goal */
  category?: Maybe<GoalCategory>;
  /** The description of this cascaded goal */
  description?: Maybe<Scalars['String']>;
  /** When this cascaded goal is due */
  dueDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether or not this cascaded goal has already had a goal created for this job */
  isFulfilled: Scalars['Boolean'];
  /** The list of measurements for the cascaded goal */
  measurements: Array<CascadedGoalMeasurement>;
  /** The name of this cascaded goal */
  name: Scalars['String'];
  /** The progress to completion of the goal */
  progress?: Maybe<Scalars['Float']>;
  /** When the cascaded goal started */
  startDate: Scalars['DateTime'];
};

export type CascadedGoalMeasurement = {
  __typename?: 'CascadedGoalMeasurement';
  /** The ID of the goal measurement */
  id: Scalars['ID'];
  /** The ID of the type of measurement */
  measurementTypeId: Scalars['String'];
  /** The name of the measurement */
  name: Scalars['String'];
  /** The starting value of the measurement */
  startingValue: Scalars['Float'];
  /** The target value of the measurement */
  targetValue: Scalars['Float'];
};

export type ChangePasswordInput = {
  confirmNewPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CompactFormDesign = {
  __typename?: 'CompactFormDesign';
  fields: Array<CompactFormField>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type CompactFormField = {
  __typename?: 'CompactFormField';
  definition: Scalars['JSON'];
  fieldType: Scalars['String'];
  id: Scalars['UUID'];
  isAnswerable: Scalars['Boolean'];
  label: Scalars['String'];
  status: Scalars['String'];
};

export type CompensationPlan = {
  __typename?: 'CompensationPlan';
  budgetAllocated?: Maybe<Scalars['Float']>;
  budgetAmount?: Maybe<Scalars['Float']>;
  budgetRemaining?: Maybe<Scalars['Float']>;
  /** The subordinates that existed under the creator at the time of this Compensation Plans creation */
  compensationPlanJobs?: Maybe<Array<CompensationPlanJob>>;
  /** When the Compensation Plan was created */
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  currencyDetails?: Maybe<Currency>;
  currentTotalSalary?: Maybe<Scalars['Float']>;
  /** When the Compensation Plan was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isOwner: Scalars['Boolean'];
  numberOfSubordinates?: Maybe<Scalars['Int']>;
  ownerPerson: Person;
  /** The People this plan was shared with.  These people will have read-only access */
  sharedWith?: Maybe<Array<CompensationPlanSharedWithPerson>>;
  title: Scalars['String'];
  /** When the Compensation Plan was last updated */
  updatedAt: Scalars['DateTime'];
};

export type CompensationPlanJob = {
  __typename?: 'CompensationPlanJob';
  adjustmentAmount?: Maybe<Scalars['Float']>;
  adjustmentPercent?: Maybe<Scalars['Float']>;
  compensationPlan: CompensationPlan;
  /** When the Compensation Plan Job was created */
  createdAt: Scalars['DateTime'];
  currentProratedSalary?: Maybe<Scalars['Float']>;
  currentSalary?: Maybe<Scalars['Float']>;
  /** When the Compensation Plan Job was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  fte?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  job: Job;
  newSalary?: Maybe<Scalars['Float']>;
  payPackageId?: Maybe<Scalars['String']>;
  /** When the Compensation Plan Job was last updated */
  updatedAt: Scalars['DateTime'];
};

export type CompensationPlanSharedWithPerson = {
  __typename?: 'CompensationPlanSharedWithPerson';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  person: Person;
  updatedAt: Scalars['DateTime'];
};

export enum CompensationPlanSortDirection {
  OWNER_ASCENDING = 'OWNER_ASCENDING',
  OWNER_DESCENDING = 'OWNER_DESCENDING',
  SUBORDINATES_ASCENDING = 'SUBORDINATES_ASCENDING',
  SUBORDINATES_DESCENDING = 'SUBORDINATES_DESCENDING',
  TITLE_ASCENDING = 'TITLE_ASCENDING',
  TITLE_DESCENDING = 'TITLE_DESCENDING',
  UPDATED_AT_ASCENDING = 'UPDATED_AT_ASCENDING',
  UPDATED_AT_DESCENDING = 'UPDATED_AT_DESCENDING'
}

export type CompensationPlannerBudget = {
  __typename?: 'CompensationPlannerBudget';
  /** @deprecated Moved to V2 model structure */
  amountBase?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  amountBonus?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  amountTotal?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  businessUnitId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['ID'];
  /** @deprecated Moved to V2 model structure */
  isSplit: Scalars['Boolean'];
  /** @deprecated Moved to V2 model structure */
  jobs: Array<Maybe<CompensationPlannerJob>>;
  /** @deprecated Moved to V2 model structure */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Moved to V2 model structure */
  ownerPerson?: Maybe<Person>;
  /** @deprecated Moved to V2 model structure */
  parentId?: Maybe<Scalars['String']>;
};


export type CompensationPlannerBudgetjobsArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
};

export type CompensationPlannerBudgetAmount = {
  __typename?: 'CompensationPlannerBudgetAmount';
  /** @deprecated Moved to V2 model structure */
  base: Scalars['Float'];
  /** @deprecated Moved to V2 model structure */
  bonus: Scalars['Float'];
};

export type CompensationPlannerBudgetAmounts = {
  __typename?: 'CompensationPlannerBudgetAmounts';
  /** base amount */
  base: Scalars['Float'];
  /** bonus amount */
  bonus: Scalars['Float'];
};

export type CompensationPlannerBudgetGroup = {
  __typename?: 'CompensationPlannerBudgetGroup';
  /** @deprecated Moved to V2 model structure */
  amount: CompensationPlannerBudgetAmount;
  /** @deprecated Moved to V2 model structure */
  budgeted?: Maybe<CompensationPlannerBudgetAmount>;
  /** @deprecated Moved to V2 model structure */
  children?: Maybe<Array<CompensationPlannerBudgetGroup>>;
  /** @deprecated Moved to V2 model structure */
  directChildId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  jobCount: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  name: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  ownerPerson?: Maybe<Person>;
  /** @deprecated Moved to V2 model structure */
  ownerPersonId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to V2 model structure */
  split?: Maybe<Scalars['Boolean']>;
};

export type CompensationPlannerBudgetGroupBudgetV2 = {
  __typename?: 'CompensationPlannerBudgetGroupBudgetV2';
  /** Budget allocated amounts */
  allocated: CompensationPlannerBudgetGroupsBudgetAmountsV2;
  /** Budget id */
  id: Scalars['String'];
  /** Budget name */
  name: Scalars['String'];
};

export type CompensationPlannerBudgetGroupPlanV2 = {
  __typename?: 'CompensationPlannerBudgetGroupPlanV2';
  /** Plan id */
  id: Scalars['String'];
  /** Plan name */
  name: Scalars['String'];
};

export type CompensationPlannerBudgetGroupResponseV2 = {
  __typename?: 'CompensationPlannerBudgetGroupResponseV2';
  /** The budget groups budget */
  budget: CompensationPlannerBudgetGroupBudgetV2;
  /** The configured column configuration for jobs datagrid */
  jobDatagridColumns: Array<CompensationPlannerConfigJobDatagridColumn>;
  /** The owner person */
  ownerPerson?: Maybe<Person>;
  /** The owner person id */
  ownerPersonId?: Maybe<Scalars['String']>;
  /** The budget groups plan */
  plan: CompensationPlannerBudgetGroupPlanV2;
};

export type CompensationPlannerBudgetGroupsBudgetAmountsV2 = {
  __typename?: 'CompensationPlannerBudgetGroupsBudgetAmountsV2';
  /** Base amount */
  base: Scalars['Float'];
  /** Bonus amount */
  bonus: Scalars['Float'];
};

export type CompensationPlannerBudgetGroupsBudgetV2 = {
  __typename?: 'CompensationPlannerBudgetGroupsBudgetV2';
  /** Budget allocated amounts */
  allocated: CompensationPlannerBudgetGroupsBudgetAmountsV2;
  /** Budget apportioned amounts */
  apportioned?: Maybe<CompensationPlannerBudgetGroupsBudgetAmountsV2>;
  /** Budget business unit id */
  businessUnitId?: Maybe<Scalars['String']>;
  /** Budget children */
  children?: Maybe<Array<CompensationPlannerBudgetGroupsBudgetV2>>;
  /** Budget direct child id */
  directChildId?: Maybe<Scalars['String']>;
  /** Budget id */
  id: Scalars['ID'];
  /** Is the budget split */
  isSplit?: Maybe<Scalars['Boolean']>;
  /** Number of jobs for budget and all children */
  jobCount: Scalars['Int'];
  /** Budget name */
  name: Scalars['String'];
  /** Budget owner */
  ownerPerson?: Maybe<Person>;
  /** Budget owner id */
  ownerPersonId?: Maybe<Scalars['String']>;
};

export type CompensationPlannerBudgetGroupsResponse = {
  __typename?: 'CompensationPlannerBudgetGroupsResponse';
  /** @deprecated Moved to V2 model structure */
  children: Array<CompensationPlannerBudgetGroup>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  planId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  total: CompensationPlannerBudgetTotal;
};

export type CompensationPlannerBudgetGroupsResponseV2 = {
  __typename?: 'CompensationPlannerBudgetGroupsResponseV2';
  /** Budget group children */
  children: Array<CompensationPlannerBudgetGroupsBudgetV2>;
  /** Budget group master id */
  id: Scalars['String'];
  /** Plan id */
  planId: Scalars['String'];
  /** Budget group totals */
  total: CompensationPlannerBudgetGroupsTotalsV2;
};

export type CompensationPlannerBudgetGroupsTotalsV2 = {
  __typename?: 'CompensationPlannerBudgetGroupsTotalsV2';
  /** Total base amount */
  base?: Maybe<Scalars['Float']>;
  /** Total bonus amount */
  bonus?: Maybe<Scalars['Float']>;
};

export type CompensationPlannerBudgetTotal = {
  __typename?: 'CompensationPlannerBudgetTotal';
  /** @deprecated Moved to V2 model structure */
  base?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  bonus?: Maybe<Scalars['Float']>;
};

export type CompensationPlannerBudgetV2 = {
  __typename?: 'CompensationPlannerBudgetV2';
  /** Budgets allocated base amount */
  amountBase?: Maybe<Scalars['Float']>;
  /** Budgets allocated base amount */
  amountBonus?: Maybe<Scalars['Float']>;
  /** Budget business unit id */
  businessUnitId?: Maybe<Scalars['String']>;
  /** Child budget id */
  childId?: Maybe<Scalars['String']>;
  /** Budget depth */
  depth?: Maybe<Scalars['Int']>;
  /** Budget id */
  id: Scalars['ID'];
  /** Is the budget split */
  isSplit?: Maybe<Scalars['Boolean']>;
  /** Budget name */
  name: Scalars['String'];
  /** Budget owner person id */
  ownerPersonId?: Maybe<Scalars['String']>;
  /** arent budget id */
  parentId: Scalars['String'];
};

export type CompensationPlannerConfig = {
  __typename?: 'CompensationPlannerConfig';
  /** The effective start used to generate the plans source */
  effectiveStartDate: Scalars['DateTime'];
  /** The eligibility rules used to determine the eligible state for all jobs under the plan */
  eligibilityRules: CompensationPlannerConfigEligibilityRules;
  /** Config id */
  id: Scalars['ID'];
  /** The jobs datagrid columns configuration */
  jobDatagridColumns: Array<CompensationPlannerConfigJobDatagridColumn>;
};

export type CompensationPlannerConfigEligibilityRules = {
  __typename?: 'CompensationPlannerConfigEligibilityRules';
  /** Job start date */
  jobStartDate?: Maybe<Scalars['DateTime']>;
  /** Job work class */
  jobWorkClass?: Maybe<Array<Scalars['String']>>;
};

export type CompensationPlannerConfigEligibilityRulesInput = {
  /** Job start date */
  jobStartDate?: InputMaybe<Scalars['DateTime']>;
  /** Job work class */
  jobWorkClass?: InputMaybe<Array<Scalars['String']>>;
};

export type CompensationPlannerConfigJobDatagridColumn = {
  __typename?: 'CompensationPlannerConfigJobDatagridColumn';
  /** Column key */
  key: Scalars['String'];
  /** Column label */
  label: Scalars['String'];
  /** Can this column be reordered */
  reorder: Scalars['Boolean'];
  /** Is this column enabled */
  state: Scalars['Boolean'];
  /** Can this column's state be toggle */
  toggle: Scalars['Boolean'];
  /** Column type */
  type: Scalars['String'];
};

export type CompensationPlannerEligibilityRulesInput = {
  jobStartDate?: InputMaybe<Scalars['String']>;
  jobWorkClass?: InputMaybe<Array<Scalars['String']>>;
};

export type CompensationPlannerJob = {
  __typename?: 'CompensationPlannerJob';
  /** @deprecated Moved to V2 model structure */
  adjustmentBaseAnnual?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  adjustmentBaseHourly?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  adjustmentBonus?: Maybe<Scalars['Float']>;
  /** @deprecated Moved to V2 model structure */
  baseAmountPerUnit: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  baseAnnualAmount: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  budgetId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  businessUnit?: Maybe<BusinessUnit>;
  /** @deprecated Moved to V2 model structure */
  createdAt: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['ID'];
  /** @deprecated Moved to V2 model structure */
  isEligible: Scalars['Boolean'];
  /** @deprecated Moved to V2 model structure */
  jobId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  person?: Maybe<Person>;
  /** @deprecated Moved to V2 model structure */
  positionTitle: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  updatedAt: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  workClass?: Maybe<WorkClass>;
  /** @deprecated Moved to V2 model structure */
  workType?: Maybe<WorkType>;
};

export type CompensationPlannerJobV2 = {
  __typename?: 'CompensationPlannerJobV2';
  /** The adjusted dollar value of the base annual amount */
  adjustmentBaseAnnual?: Maybe<Scalars['Float']>;
  /** The adjusted dollar value of the base hourly amount */
  adjustmentBaseHourly?: Maybe<Scalars['Float']>;
  /** The adjusted dollar value of the bonus amount */
  adjustmentBonus?: Maybe<Scalars['Float']>;
  /** Job budget id */
  budgetId: Scalars['String'];
  /** Job business unit from source */
  businessUnit?: Maybe<CompensationPlannerSourceDataBusinessUnit>;
  /** The current base annual amount */
  currentBaseAnnual?: Maybe<Scalars['Float']>;
  /** The current base hourly amount */
  currentBaseHourly?: Maybe<Scalars['Float']>;
  /** The current bonus amount */
  currentBonus?: Maybe<Scalars['Float']>;
  /** Job id */
  id: Scalars['ID'];
  /** Is the job eligible for adjustment */
  isEligible: Scalars['Boolean'];
  /** Job id from source */
  jobId: Scalars['String'];
  /** Job pay cycle from source */
  payCycle?: Maybe<CompensationPlannerSourceDataPayCycle>;
  /** Job pay grade value from source */
  payGradeValue?: Maybe<CompensationPlannerSourceDataPayGradeValue>;
  /** Job pay package from source */
  payPackage?: Maybe<CompensationPlannerSourceDataPayPackage>;
  /** Job person from source */
  person?: Maybe<CompensationPlannerSourceDataPerson>;
  /** Job position title from source */
  positionTitle?: Maybe<CompensationPlannerSourceDataPositionTitle>;
  /** When the job was last updated */
  updatedAt: Scalars['DateTime'];
  /** Job work class from source */
  workClass?: Maybe<CompensationPlannerSourceDataWorkClass>;
  /** Job work type from source */
  workType?: Maybe<CompensationPlannerSourceDataWorkType>;
};

export type CompensationPlannerPlan = {
  __typename?: 'CompensationPlannerPlan';
  /** @deprecated Moved to V2 model structure */
  budgetId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to V2 model structure */
  columns?: Maybe<Scalars['JSON']>;
  /** @deprecated Moved to V2 model structure */
  config?: Maybe<Scalars['JSON']>;
  /** @deprecated Moved to V2 model structure */
  createdAt: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Moved to V2 model structure */
  effectiveStartDate: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  eligibilityRules?: Maybe<Scalars['JSON']>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['ID'];
  /** @deprecated Moved to V2 model structure */
  isCurrentVersion: Scalars['Boolean'];
  /** @deprecated Moved to V2 model structure */
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Moved to V2 model structure */
  modelId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  name: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  sourceId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  updatedAt: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  version: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  workClasses?: Maybe<Scalars['JSON']>;
};

export type CompensationPlannerPlanV2 = {
  __typename?: 'CompensationPlannerPlanV2';
  /** The plan's config */
  config: CompensationPlannerConfig;
  /** When the plan was created */
  createdAt: Scalars['DateTime'];
  /** Plan id */
  id: Scalars['ID'];
  /** When the plan was last synced */
  lastSyncedAt: Scalars['DateTime'];
  /** Plan name */
  name: Scalars['String'];
  /** Plans current source */
  source: CompensationPlannerSourceV2;
  /** When the plan was updated */
  updatedAt: Scalars['DateTime'];
};

export type CompensationPlannerSource = {
  __typename?: 'CompensationPlannerSource';
  /** @deprecated Moved to V2 model structure */
  createdAt: Scalars['DateTime'];
  /** @deprecated Moved to V2 model structure */
  data?: Maybe<Scalars['JSON']>;
  /** @deprecated Moved to V2 model structure */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Moved to V2 model structure */
  eligibilityRules?: Maybe<Scalars['JSON']>;
  /** @deprecated Moved to V2 model structure */
  id: Scalars['ID'];
  /** @deprecated Moved to V2 model structure */
  planId: Scalars['String'];
  /** @deprecated Moved to V2 model structure */
  updatedAt: Scalars['DateTime'];
};

export type CompensationPlannerSourceDataBusinessUnit = {
  __typename?: 'CompensationPlannerSourceDataBusinessUnit';
  /** Business unit id */
  id: Scalars['String'];
  /** Business unit name */
  name: Scalars['String'];
};

export type CompensationPlannerSourceDataPayCycle = {
  __typename?: 'CompensationPlannerSourceDataPayCycle';
  /** Pay cycle id */
  id: Scalars['String'];
  /** Pay cycle name */
  name: Scalars['String'];
};

export type CompensationPlannerSourceDataPayGradeValue = {
  __typename?: 'CompensationPlannerSourceDataPayGradeValue';
  /** Pay grade value annual salary */
  annualSalary: Scalars['String'];
  /** Pay grade value id */
  id: Scalars['String'];
};

export type CompensationPlannerSourceDataPayPackage = {
  __typename?: 'CompensationPlannerSourceDataPayPackage';
  /** Pay package type */
  baseAnnual: Scalars['Float'];
  /** Pay package type */
  baseHourly: Scalars['Float'];
  /** Pay package type */
  bonus: Scalars['Float'];
  /** Pay package currency id */
  currencyId: Scalars['String'];
  /** Pay package id */
  id: Scalars['String'];
  /** Pay package type */
  type: Scalars['String'];
  /** Pay package type */
  unitsPerCycle: Scalars['Float'];
};

export type CompensationPlannerSourceDataPerson = {
  __typename?: 'CompensationPlannerSourceDataPerson';
  /** Persons display name */
  displayName: Scalars['String'];
  /** Person id */
  id: Scalars['String'];
  /** Persons initials */
  initials: Scalars['String'];
  /** Persons profile picture id */
  profilePictureId?: Maybe<Scalars['String']>;
  /** Persons profile picture url */
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type CompensationPlannerSourceDataPositionTitle = {
  __typename?: 'CompensationPlannerSourceDataPositionTitle';
  /** Position title id */
  id: Scalars['String'];
  /** Position title name */
  name: Scalars['String'];
};

export type CompensationPlannerSourceDataWorkClass = {
  __typename?: 'CompensationPlannerSourceDataWorkClass';
  /** Work class id */
  id: Scalars['String'];
  /** Work class name */
  name: Scalars['String'];
};

export type CompensationPlannerSourceDataWorkType = {
  __typename?: 'CompensationPlannerSourceDataWorkType';
  /** Work type id */
  id: Scalars['String'];
  /** Work type name */
  name: Scalars['String'];
};

export type CompensationPlannerSourceV2 = {
  __typename?: 'CompensationPlannerSourceV2';
  /** When the source was created */
  createdAt: Scalars['DateTime'];
  /** Source id */
  id: Scalars['ID'];
  /** Is this the current version of the source */
  isCurrentVersion: Scalars['Boolean'];
  /** When the source was updated */
  updatedAt: Scalars['DateTime'];
  /** Source version */
  version: Scalars['Int'];
  /** Source work classes */
  workClasses: Array<CompensationPlannerSourceDataWorkClass>;
};

export type CompletedFormInstance = {
  __typename?: 'CompletedFormInstance';
  completedAt: Scalars['DateTime'];
  formDesign: FormDesign;
  formInstanceAnswers: Array<FormInstanceAnswer>;
  id: Scalars['ID'];
  issueDate: Scalars['DateTime'];
  respondent?: Maybe<Person>;
  subject: Job;
  submitter: Person;
};

export type CompletedFormInstanceResponse = {
  __typename?: 'CompletedFormInstanceResponse';
  formInstance?: Maybe<CompletedFormInstance>;
  /** Borrowing answers from the global notification code instead of an array of answers with corresponding types */
  markdown?: Maybe<Scalars['String']>;
};

export type ComplianceContractor = {
  __typename?: 'ComplianceContractor';
  businessUnit: BusinessUnit;
  contractExclusiveEndDate?: Maybe<Scalars['DateTime']>;
  contractStartDate: Scalars['DateTime'];
  isEndDateConfirmed: Scalars['Boolean'];
  job: Job;
  person: Person;
  status: ComplianceContractorStatus;
  workClass: WorkClass;
};

export type ComplianceContractorStatistics = {
  __typename?: 'ComplianceContractorStatistics';
  changingWorkType: Scalars['Int'];
  expiringSoon: Scalars['Int'];
  missingEndDate: Scalars['Int'];
  noImmediateAction: Scalars['Int'];
  overdue: Scalars['Int'];
};

export enum ComplianceContractorStatus {
  CHANGING_WORK_TYPE = 'CHANGING_WORK_TYPE',
  EXPIRING_SOON = 'EXPIRING_SOON',
  MISSING_END_DATE = 'MISSING_END_DATE',
  NO_IMMEDIATE_ACTION = 'NO_IMMEDIATE_ACTION',
  OVERDUE = 'OVERDUE'
}

export type ComplianceContractorsResponse = {
  __typename?: 'ComplianceContractorsResponse';
  /** The actual records of contractors returned by the query */
  contractors: Array<ComplianceContractor>;
  /** The number of rows shown on the current page */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** The maximum number of rows per page */
  perPage: Scalars['Int'];
  /** The statistics for the contractor status */
  statistics: ComplianceContractorStatistics;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type ComplianceQuery = {
  __typename?: 'ComplianceQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** List all of the contractors records */
  listContractorsForCompliance: ComplianceContractorsResponse;
};


export type ComplianceQuerylistContractorsForComplianceArgs = {
  filters?: InputMaybe<ContractorsFilters>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type CompositeQualificationV2 = {
  __typename?: 'CompositeQualificationV2';
  compositeCompliance?: Maybe<QualificationCompositeCompliance>;
  currentAttachments: Array<PersonDocument>;
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  currentNotes?: Maybe<Scalars['String']>;
  currentQualificationInstanceId: Scalars['ID'];
  currentStatusId: Scalars['ID'];
  currentStatusLastChangedAt: Scalars['DateTime'];
  currentStatusName: Scalars['String'];
  id: Scalars['ID'];
  isCompliant?: Maybe<Scalars['Boolean']>;
  issuingOrganisation?: Maybe<Scalars['String']>;
  personId: Scalars['ID'];
  qualificationTemplate: QualificationTemplate;
  qualificationTemplateId: Scalars['ID'];
  registrationNumber?: Maybe<Scalars['String']>;
  renewalAttachments: Array<PersonDocument>;
  renewalCreatedAt?: Maybe<Scalars['DateTime']>;
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  renewalNotes?: Maybe<Scalars['String']>;
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  renewalStatusId?: Maybe<Scalars['ID']>;
  renewalStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  renewalStatusName?: Maybe<Scalars['String']>;
  renewalUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompositeStandaloneQualification = {
  __typename?: 'CompositeStandaloneQualification';
  compositeQualificationId?: Maybe<Scalars['ID']>;
  /** The current qualifications approval status */
  currentApprovalStatus?: Maybe<QualificationStatuses>;
  currentApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the current qualification */
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the current qualification */
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  /** The current qualification instance */
  currentQualificationInstance?: Maybe<QualificationInstanceV2>;
  currentQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** When the current qualification was last changed */
  currentStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  /** Compliance status of the qualification (boolean) */
  isCompliant?: Maybe<Scalars['Boolean']>;
  /** The job assigned to the standalone compliance qualification overview */
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['ID']>;
  /** The job requirement associated with this qualification */
  jobRequirement?: Maybe<JobRequirement>;
  /** The notes on the current qualification instance */
  notes?: Maybe<Scalars['String']>;
  /** The person assigned to the standalone compliance qualification overview */
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['ID']>;
  /** The effective end date of the qualification restricted period */
  qualificationRestrictedPeriodEndDate?: Maybe<Scalars['DateTime']>;
  /** The effective start date of the qualification restricted period */
  qualificationRestrictedPeriodStartDate?: Maybe<Scalars['DateTime']>;
  qualificationRestrictedPeriods?: Maybe<Scalars['String']>;
  /** The qualification template */
  qualificationTemplate?: Maybe<QualificationTemplate>;
  qualificationTemplateId?: Maybe<Scalars['ID']>;
  /** The renewal qualification approval status */
  renewalApprovalStatus?: Maybe<QualificationStatuses>;
  renewalApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the renewal qualification */
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the renewal qualification */
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  /** The notes on the renewal qualification instance */
  renewalNotes?: Maybe<Scalars['String']>;
  /** The renewal qualification instance */
  renewalQualificationInstance?: Maybe<QualificationInstanceV2>;
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  standaloneRequirementId?: Maybe<Scalars['ID']>;
};

export type CompositeStandaloneQualificationForQualificationTemplateStats = {
  __typename?: 'CompositeStandaloneQualificationForQualificationTemplateStats';
  total?: Maybe<Scalars['Int']>;
  totalAwaitingApproval?: Maybe<Scalars['Int']>;
  totalCompliant?: Maybe<Scalars['Int']>;
  totalCompliantPercentage?: Maybe<Scalars['Int']>;
  totalExpiringSoon?: Maybe<Scalars['Int']>;
  totalNonCompliant?: Maybe<Scalars['Int']>;
};

export type CompositeStandaloneQualificationV2 = {
  __typename?: 'CompositeStandaloneQualificationV2';
  currentApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the current qualification */
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the current qualification */
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  currentQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** When the current qualification was last changed */
  currentStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  currentUpdatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  /** Compliance status of the qualification (boolean) */
  isCompliant?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  /** The job assigned to the standalone compliance qualification overview */
  jobId?: Maybe<Scalars['ID']>;
  person?: Maybe<Person>;
  /** The person assigned to the standalone compliance qualification overview */
  personId?: Maybe<Scalars['ID']>;
  /** The effective end date of the qualification restricted period */
  qualificationRestrictedPeriodEndDate?: Maybe<Scalars['DateTime']>;
  /** The effective start date of the qualification restricted period */
  qualificationRestrictedPeriodStartDate?: Maybe<Scalars['DateTime']>;
  qualificationRestrictedPeriods?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  renewalApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the renewal qualification */
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the renewal qualification */
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** When the current qualification was last changed */
  renewalStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  renewalUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type Contract = {
  __typename?: 'Contract';
  /** The date the contract was closed */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** The contract text */
  contractBody: Scalars['String'];
  /** A list of people to send the completed contract to, and the date it was sent */
  contractRecipients: Array<ContractRecipient>;
  /** The status of the contract */
  contractStatus: ContractStatus;
  /** The template this contract is based on */
  contractTemplate?: Maybe<ContractTemplate>;
  /** The date the contract was deleted at (if any) */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The date the contract takes effect */
  effectiveFrom: Scalars['DateTime'];
  /** Indicates whether the signers must sign in the order they appear in the contract */
  hasSignOrder: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The date the contract was issued */
  issuedAt?: Maybe<Scalars['DateTime']>;
  /** The person who issued the contract */
  issuedByPerson: Person;
  /** The user who issued the contract */
  issuedByUser: User;
  /** The name of the contract */
  name: Scalars['String'];
  /** The required signers */
  signers: Array<ContractSigner>;
  /** The job the contract is about */
  subjectJob: Job;
  /** The person the contract is about */
  subjectPerson: Person;
};


export type ContractcontractTemplateArgs = {
  trashed?: InputMaybe<Trashed>;
};

export enum ContractAction {
  REJECT = 'REJECT',
  SIGN = 'SIGN'
}

export type ContractCreateInput = {
  /**
   * A list of people who will be sent a copy of the contract (in addition to the
   * subject and signers) via email when the contract has been signed by all
   */
  additionalContractRecipients: Array<Scalars['ID']>;
  /** The the body of the contract */
  body: Scalars['String'];
  /** The contract template this contract is based on */
  contractTemplateId: Scalars['ID'];
  /** The data the terms of the contract becomes effective */
  effectiveFrom: Scalars['DateTime'];
  /** Indicates whether the signers must sign in the order they appear in the contract */
  hasSignOrder?: InputMaybe<Scalars['Boolean']>;
  /** The name of the contract */
  name: Scalars['String'];
  /** A list of job ids of the people who need to sign the contract */
  signers: Array<Scalars['ID']>;
  /** The ID of the job of the person that the contract is about */
  subjectJobId: Scalars['ID'];
};

export type ContractCreateResponse = {
  __typename?: 'ContractCreateResponse';
  contract?: Maybe<Contract>;
};

export type ContractIssueBatch = {
  __typename?: 'ContractIssueBatch';
  /** When the batch was completed */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The template of all contracts issued in this batch */
  contractTemplate: ContractTemplateV2;
  /** When the batch was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The user who created this contract issue batch */
  createdByUser?: Maybe<User>;
  /** When the batch was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The date when all contracts issued in this batch takes effect */
  effectiveFrom?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The items in this batch */
  items: Array<Maybe<ContractIssueBatchItem>>;
  /** The name of all contracts issued in this batch */
  name?: Maybe<Scalars['String']>;
  /** The required signers of all contracts issued in this batch */
  signers: Scalars['JSON'];
  /**
   * The status of the issue batch
   *
   * UNPROCESSED: The batch has not been processed yet
   * COMPLETED: All contracts in this batch have been issued successfully
   * FAILED: Some or all contracts in this batch have failed to be issued
   * PAUSED: The issuing process has been paused
   * PROCESSING: The batch is in process of issuing its contracts
   */
  status: ContractIssueBatchStatus;
  /** When the batch was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The variables that will be used in all contracts issued in this batch */
  variables: Scalars['JSON'];
  /** The recipients of all contracts issued in this batch */
  viewers: Scalars['JSON'];
};

export type ContractIssueBatchCreateInput = {
  /** The template id of the contract template to use */
  contractTemplateId: Scalars['ID'];
  /** The start date of all contracts issued in from this batch */
  effectiveFrom: Scalars['DateTime'];
  /** The name of the contract issue batch */
  name: Scalars['String'];
};

export type ContractIssueBatchCreateResponse = {
  __typename?: 'ContractIssueBatchCreateResponse';
  /** The ID of the contract template to be used by all contracts issued from this batch */
  contractIssueBatch: ContractIssueBatch;
};

export type ContractIssueBatchItem = {
  __typename?: 'ContractIssueBatchItem';
  /** The issue batch that this item belongs to */
  batch: ContractIssueBatch;
  /** The contract issued from this item */
  contract?: Maybe<Contract>;
  /** When the item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** When the item was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The reason why the contract was failed to be issued from this item, if applicable */
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The order in which this item is processed, compared to the others. This is indexed from 1. */
  order: Scalars['Int'];
  /** When the item was processed */
  processedAt?: Maybe<Scalars['DateTime']>;
  /** The required signers of contract issued from this item */
  signers: Scalars['JSON'];
  /**
   * The status of the item
   *
   * UNPROCESSED: The item has not been processed yet
   * COMPLETED: The contract from this item has been issued successfully
   * FAILED: The contract from this item failed to be issued
   * PAUSED: The issuing process has been paused for this item
   * PROCESSING: The batch is issuing contract from this item
   */
  status: ContractIssueBatchStatus;
  /** The job which the contract issued from this item is about */
  subjectJob: Job;
  /** The person whom the contract issued from this item is about */
  subjectPerson: Person;
  /** When the item was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The variables used in contract issued from this item */
  variables: Scalars['JSON'];
  /** The recipients of contract issued from this item */
  viewers: Scalars['JSON'];
};

export type ContractIssueBatchItemDeleteResponse = {
  __typename?: 'ContractIssueBatchItemDeleteResponse';
  id: Scalars['ID'];
};

export type ContractIssueBatchItemUpdateInput = {
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
  signers?: InputMaybe<Scalars['JSON']>;
  variables?: InputMaybe<Scalars['JSON']>;
  viewers?: InputMaybe<Scalars['JSON']>;
};

export type ContractIssueBatchItemUpdateResponse = {
  __typename?: 'ContractIssueBatchItemUpdateResponse';
  contractIssueBatchItem?: Maybe<ContractIssueBatchItem>;
};

export type ContractIssueBatchItemsInput = {
  id: Scalars['ID'];
};

export enum ContractIssueBatchStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PAUSED = 'PAUSED',
  PROCESSING = 'PROCESSING',
  UNPROCESSED = 'UNPROCESSED'
}

export type ContractIssueBatchUpdateInput = {
  /** The template id of the contract template to use */
  contractTemplateId?: InputMaybe<Scalars['ID']>;
  /** The start date of all contracts issued in from this batch */
  effectiveFrom: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The items of the contract issue batch */
  items?: InputMaybe<Array<InputMaybe<ContractIssueBatchItemsInput>>>;
  /** The name of the contract issue batch */
  name?: InputMaybe<Scalars['String']>;
  /** The signers of the contract issue batch */
  signers?: InputMaybe<Scalars['JSON']>;
  /** The variables of the contract issue batch */
  variables?: InputMaybe<Scalars['JSON']>;
  /** The viewers of the contract issue batch */
  viewers?: InputMaybe<Scalars['JSON']>;
};

export type ContractIssueBatchUpdateResponse = {
  __typename?: 'ContractIssueBatchUpdateResponse';
  /** The contract batch that has been updated */
  contractIssueBatch: ContractIssueBatch;
};

export type ContractPersonSignature = {
  __typename?: 'ContractPersonSignature';
  /** Type of signature */
  contractSignatureType: ContractSignatureType;
  id: Scalars['ID'];
  /** Name to use for signing instead of the system derived legal name. Only used when the signature type is generated */
  legalNameOverride?: Maybe<Scalars['String']>;
  /** The person who the signature belongs to */
  person: Person;
  /** The signature of the signer (base64 encoded png) */
  signature?: Maybe<Scalars['String']>;
};

export type ContractPersonSignatureCreateInput = {
  contractSignatureTypeId: Scalars['ID'];
  legalNameOverride?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  signature?: InputMaybe<Scalars['String']>;
};

export type ContractPersonSignatureDeleteResponse = {
  __typename?: 'ContractPersonSignatureDeleteResponse';
  id: Scalars['ID'];
};

export type ContractPersonSignatureResponse = {
  __typename?: 'ContractPersonSignatureResponse';
  contractPersonSignature: ContractPersonSignature;
};

export type ContractPersonSignatureUpdateInput = {
  contractSignatureTypeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  legalNameOverride?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};

export type ContractRecipient = {
  __typename?: 'ContractRecipient';
  /** The contract to send */
  contract: Contract;
  /** The date the contract was sent via email */
  emailSentAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The person to receive the completed contract */
  person: Person;
};

export type ContractSignatureStatus = {
  __typename?: 'ContractSignatureStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ContractSignatureType = {
  __typename?: 'ContractSignatureType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ContractSignatureUpdateInput = {
  /** Either SIGN or REJECT */
  action: ContractAction;
  /** Contract Signer ID */
  id: Scalars['ID'];
  /** A base64 encoded PNG */
  signature?: InputMaybe<Scalars['String']>;
};

export type ContractSignatureUpdateResponse = {
  __typename?: 'ContractSignatureUpdateResponse';
  contractSigner: ContractSigner;
};

export type ContractSigner = {
  __typename?: 'ContractSigner';
  /** A unique string that is used to authenticate the signer when they are not logged in */
  accessCode: Scalars['String'];
  /** The contract that is to be signed */
  contract: Contract;
  /** The date after which the access code can no longer be used to authenticate the signer */
  expiryDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The job of the signing person */
  job: Job;
  /** The signer person */
  person: Person;
  /** The signature of the signer (base64 encoded png) */
  signatureData?: Maybe<Scalars['String']>;
  /** The position of the signature in the contract */
  signaturePosition: Scalars['Int'];
  /** The status of the signature */
  signatureStatus: ContractSignatureStatus;
  /** The date the contract was signed or rejected by the signer */
  submittedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractStatus = {
  __typename?: 'ContractStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ContractStatusEnum {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED'
}

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  /** The body of the contract template */
  body: Scalars['String'];
  /** The contract template status */
  contractTemplateStatus: ContractTemplateStatus;
  /** The contract template status ID */
  contractTemplateStatusId: ContractTemplateStatusId;
  /** The create date of the contract template */
  createdAt: Scalars['DateTime'];
  /** The date the contract template was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates whether the signers must sign in the order they appear in the contract */
  hasSignOrder: Scalars['Boolean'];
  /** The ID of the contract template */
  id: Scalars['ID'];
  /** The name of the contract template */
  name: Scalars['String'];
  /** The number of signers of the contract */
  signerCount: Scalars['Int'];
};

export type ContractTemplateCreateInput = {
  body: Scalars['String'];
  contractTemplateStatusId: Scalars['ID'];
  hasSignOrder?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  signerCount: Scalars['Int'];
};

export type ContractTemplateCreateResponse = {
  __typename?: 'ContractTemplateCreateResponse';
  contractTemplate?: Maybe<ContractTemplate>;
};

export type ContractTemplateDeleteResponse = {
  __typename?: 'ContractTemplateDeleteResponse';
  id: Scalars['ID'];
};

export type ContractTemplateSigner = {
  __typename?: 'ContractTemplateSigner';
  details?: Maybe<ContractTemplateSignerDetailsUnion>;
  id: Scalars['ID'];
  signaturePosition: Scalars['Int'];
  signerType: SignerType;
};

export type ContractTemplateSignerDetailsBusinessUnitRepresentative = {
  __typename?: 'ContractTemplateSignerDetailsBusinessUnitRepresentative';
  businessUnitRepresentative: RepresentativeType;
};

export type ContractTemplateSignerDetailsLocationRepresentative = {
  __typename?: 'ContractTemplateSignerDetailsLocationRepresentative';
  locationRepresentative: RepresentativeType;
};

export type ContractTemplateSignerDetailsPerson = {
  __typename?: 'ContractTemplateSignerDetailsPerson';
  person: Person;
};

export type ContractTemplateSignerDetailsUndefined = {
  __typename?: 'ContractTemplateSignerDetailsUndefined';
  _empty?: Maybe<Scalars['String']>;
};

export type ContractTemplateSignerDetailsUnion = ContractTemplateSignerDetailsBusinessUnitRepresentative | ContractTemplateSignerDetailsLocationRepresentative | ContractTemplateSignerDetailsPerson | ContractTemplateSignerDetailsUndefined;

export type ContractTemplateSignerInput = {
  businessUnitRepresentativeId?: InputMaybe<Scalars['ID']>;
  locationRepresentativeId?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['ID']>;
  signerType: SignerType;
};

export type ContractTemplateSignerType = {
  __typename?: 'ContractTemplateSignerType';
  /** The ID of the contract template signer type */
  id: Scalars['ID'];
  /** The name of the contract template signer type */
  name: Scalars['String'];
};

export type ContractTemplateStatus = {
  __typename?: 'ContractTemplateStatus';
  /** The ID of the contract template status */
  id: Scalars['ID'];
  /** The name of the contract template status */
  name: Scalars['String'];
};

export enum ContractTemplateStatusId {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT'
}

export type ContractTemplateUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  contractTemplateStatusId?: InputMaybe<Scalars['ID']>;
  hasSignOrder?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  signerCount?: InputMaybe<Scalars['Int']>;
};

export type ContractTemplateUpdateResponse = {
  __typename?: 'ContractTemplateUpdateResponse';
  contractTemplate?: Maybe<ContractTemplate>;
};

export type ContractTemplateV2 = {
  __typename?: 'ContractTemplateV2';
  /** The body of the contract template */
  body: Scalars['String'];
  /** The contract template status */
  contractTemplateStatus: ContractTemplateStatus;
  /** The contract template status ID */
  contractTemplateStatusId: ContractTemplateStatusId;
  /** The create date of the contract template */
  createdAt: Scalars['DateTime'];
  /** The date the contract template was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Indicates whether the signers must sign in the order they appear in the contract */
  hasSignOrder: Scalars['Boolean'];
  /** The ID of the contract template */
  id: Scalars['ID'];
  /** The name of the contract template */
  name: Scalars['String'];
  /** The signers for any contract created by this template */
  signers: Array<ContractTemplateSigner>;
};

export type ContractTemplateV2CreateInput = {
  body: Scalars['String'];
  contractTemplateStatusId: Scalars['ID'];
  hasSignOrder?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  signers: Array<ContractTemplateSignerInput>;
};

export type ContractTemplateV2CreateResponse = {
  __typename?: 'ContractTemplateV2CreateResponse';
  contractTemplate?: Maybe<ContractTemplateV2>;
};

export type ContractTemplateV2UpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  contractTemplateStatusId?: InputMaybe<Scalars['ID']>;
  hasSignOrder?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  signers?: InputMaybe<Array<ContractTemplateSignerInput>>;
};

export type ContractTemplateV2UpdateResponse = {
  __typename?: 'ContractTemplateV2UpdateResponse';
  contractTemplate?: Maybe<ContractTemplateV2>;
};

export type ContractorsFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContractorsFilters = {
  businessUnit?: InputMaybe<ContractorsFilter>;
  status?: InputMaybe<ContractorsFilter>;
  workClass?: InputMaybe<ContractorsFilter>;
};

export type Country = {
  __typename?: 'Country';
  /** The dial code of the country */
  dialCode: Scalars['String'];
  id: Scalars['ID'];
  isoCode: Scalars['String'];
  /** The name of the country */
  name: Scalars['String'];
  /** The count of people who have work rights belonging to this country */
  workRightPersonCount: Scalars['Int'];
};

export type CountryOrderBy = {
  field: CountryOrderFields;
  order: CountrySortOrder;
};

export enum CountryOrderFields {
  NAME = 'NAME',
  WORK_RIGHT_USAGE = 'WORK_RIGHT_USAGE'
}

export enum CountrySortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type CreateAccessKeyInput = {
  /** The display name of the access key */
  name: Scalars['String'];
};

export type CreateAccessKeyResponse = {
  __typename?: 'CreateAccessKeyResponse';
  accessKey?: Maybe<AccessKey>;
  accessKeyString: Scalars['String'];
};

export type CreateAnonymousPulseModelInput = {
  /** The id of anonymous form design */
  anonymousFormDesignId: Scalars['ID'];
  /** The new set of pulse filters */
  filters?: InputMaybe<AnonymousPulseFilters>;
  /** The frequency of pulse recurrence */
  frequency?: InputMaybe<RecurringPulseFrequencyInput>;
  /** The name for the pulse model */
  name: Scalars['String'];
  /** The send date time for pulse, the start date time for pulse recurrence */
  sendAt: Scalars['DateTime'];
};

export type CreateCompensationPlanV2Input = {
  /** The proposed Budget for the Compensation Plan */
  budgetAmount?: InputMaybe<Scalars['Float']>;
  /** The name for the Compensation Plan */
  name: Scalars['String'];
};

export type CreateCompensationPlannerPlanInput = {
  /** Plans effective start date */
  effectiveStartDate: Scalars['DateTime'];
  /** Plan name */
  name: Scalars['String'];
};

export type CreateCompensationPlannerPlanResponse = {
  __typename?: 'CreateCompensationPlannerPlanResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
};

export type CreateCompensationPlannerPlanResponseV2 = {
  __typename?: 'CreateCompensationPlannerPlanResponseV2';
  /** Compensation Planner Plan */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlanV2>;
};

export type CreateCustomFieldResponse = {
  __typename?: 'CreateCustomFieldResponse';
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
  /** An array of permissions that were created or empty if none created */
  permissionSlugsCreated: Array<Scalars['String']>;
};

export type CreateCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type CreateFormDesignInput = {
  autoIgnorePeriod?: InputMaybe<Scalars['Int']>;
  defaultNotificationMethodId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  duePeriod?: InputMaybe<Scalars['Int']>;
  escalationPeriod?: InputMaybe<Scalars['Int']>;
  formCompletedRecipientIds: Array<Scalars['ID']>;
  formCompletedRecipientPeopleIds: Array<Scalars['ID']>;
  formDesignCategoryId?: InputMaybe<Scalars['ID']>;
  formRespondentTypeId: Scalars['ID'];
  formTypeId: Scalars['ID'];
  jobRespondentIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  reminderPeriod?: InputMaybe<Scalars['Int']>;
  requiresLogin?: InputMaybe<Scalars['Boolean']>;
  respondentEmailAddresses: Array<Scalars['String']>;
  showTriggeredBy?: InputMaybe<Scalars['Boolean']>;
  triggerOnCompleteFormDesignId?: InputMaybe<Scalars['ID']>;
};

export type CreateFormDesignResponse = {
  __typename?: 'CreateFormDesignResponse';
  formDesign: FormDesign;
};

export type CreateGoalCommentInput = {
  comment: Scalars['String'];
  goalId: Scalars['ID'];
};

export type CreateGoalCommentResponse = {
  __typename?: 'CreateGoalCommentResponse';
  goalComment: GoalComment;
};

export type CreatePulseModelInput = {
  /** Pulse filters - Supporting custom fields */
  combinedFilters?: InputMaybe<Array<InputMaybe<Filter>>>;
  /** The pre-custom_fields supported pulse filters */
  filters?: InputMaybe<PulseFilters>;
  /** The id of form design */
  formDesignId: Scalars['ID'];
  /** The frequency of pulse recurrence */
  frequency?: InputMaybe<RecurringPulseFrequencyInput>;
  /** The name for the pulse model */
  name: Scalars['String'];
  /** The send date time for pulse, the start date time for pulse recurrence */
  sendAt: Scalars['DateTime'];
};

export type CreateQualificationInstanceRenewalInputV2 = {
  /** Person ID of Qualification Instance to renew */
  personId: Scalars['ID'];
  /** Qualification template ID of the library item to renew */
  qualificationTemplateId: Scalars['ID'];
};

export type CreateQualificationInstanceRenewalResponseV2 = {
  __typename?: 'CreateQualificationInstanceRenewalResponseV2';
  qualificationInstance: QualificationInstanceV2;
};

export type CreateSkillDisciplineInput = {
  name: Scalars['String'];
};

export type CreateSkillInput = {
  description: Scalars['String'];
  isBusinessCritical: Scalars['Boolean'];
  name: Scalars['String'];
  /** Deprecated due to rename to skill discipline */
  skillCategoryId?: InputMaybe<Scalars['ID']>;
  skillDisciplineId?: InputMaybe<Scalars['ID']>;
};

export type CreateWorkflowInput = {
  /** The description of the workflow */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the workflow */
  name: Scalars['String'];
  /** The workflow event id that belong to this workflow */
  workflowEventId: Scalars['ID'];
};

export type CreateWorkflowInstanceInput = {
  /** The event date that this workflow event occurred */
  eventDate: Scalars['DateTime'];
  /** Array of Scheduled Forms to be created */
  scheduledForms: Array<WorkflowInstanceScheduledFormInput>;
  /** The Job that the form is about */
  subjectJobId: Scalars['ID'];
  /** The Person that the form is about */
  subjectPersonId: Scalars['ID'];
  /** The ID of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is the training id. */
  triggerId?: InputMaybe<Scalars['ID']>;
  /** The type of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is TRAINING. */
  triggerType?: InputMaybe<WorkflowEventTriggerTriggerType>;
  /** The ID of the workflow event to trigger */
  workflowEventId: Scalars['ID'];
  /** The ID of the workflow to schedule forms for */
  workflowId: Scalars['ID'];
};

export type CreateWorkflowResponse = {
  __typename?: 'CreateWorkflowResponse';
  workflow?: Maybe<Workflow>;
};

export type CreateXeroEmployeeInput = {
  /** The name of the bank account associated with the payroll. */
  accountName: Scalars['String'];
  /** The account number of the bank account associated with the payroll. */
  accountNumber: Scalars['String'];
  /** Addr"ess line 1 for employee home address. */
  addressLine1: Scalars['String'];
  /** Addres""s line 2 for employee home address. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** The BSB number "of the bank account associated with the payroll. */
  bsb: Scalars['String'];
  /** Suburb for employee home address. */
  city: Scalars['String'];
  /** The basis of the persons employment (e.g. FULLTIME, PARTTIME). */
  employmentBasis: EmploymentBasis;
  /** The type of the persons employment (EMPLOYEE or CONTRACTOR). */
  employmentType?: InputMaybe<EmploymentType>;
  /** If employee has HECS or HELP debt. */
  hasHELPDebt: Scalars['Boolean'];
  /** If employee has a loan or student debt. */
  hasLoanOrStudentDebt?: InputMaybe<Scalars['Boolean']>;
  /** If employee has financial supplement debt. */
  hasSFSSDebt: Scalars['Boolean'];
  /** If employee has trade support loan. */
  hasTradeSupportLoanDebt: Scalars['Boolean'];
  /** The id in lapis for the person. */
  personId: Scalars['String'];
  /** PostCode for employee home address. */
  postalCode: Scalars['String'];
  /** State abbreviation for employee home address. */
  region: StateAbbreviation;
  /** Residency status for tax purposes. */
  residencyStatus: ResidencyStatus;
  /** The account name of the superanuation if they have either a regulated or unlisted super fund. */
  superAccountName?: InputMaybe<Scalars['String']>;
  /** The abn of the super fund if they have a self managed super fund. */
  superFundAbn?: InputMaybe<Scalars['String']>;
  /** The bank accuount name for the super fund if they have a self managed super fund. */
  superFundBankAccountName?: InputMaybe<Scalars['String']>;
  /** The bank account number for if they have a self managed super fund. */
  superFundBankAccountNumber?: InputMaybe<Scalars['String']>;
  /** The bank bsb for if they have a self managed super fund. */
  superFundBankBsb?: InputMaybe<Scalars['String']>;
  /** An electronic service address to receive data messages associated with employer contributions sent if they have a self managed super fund. */
  superFundEsa?: InputMaybe<Scalars['String']>;
  /** Xero identifier for if they have a regulated super fund. */
  superFundId?: InputMaybe<Scalars['String']>;
  /** The name of the super fund if they have an unlisted or self managed super fund. */
  superFundName?: InputMaybe<Scalars['String']>;
  /** The type of super fund */
  superFundType: SuperFundType;
  /** The membership number for the superanuation account if they have a regulated or unlisted super fund. */
  superMembershipNumber?: InputMaybe<Scalars['String']>;
  /** The Unique Superannuation Identifier if they have an unlisted super fund */
  superUsi?: InputMaybe<Scalars['String']>;
  /** The type of super fund */
  superannuationFundType?: InputMaybe<SuperannuationFundType>;
  /** The users Tax file number. */
  taxFileNumber?: InputMaybe<Scalars['String']>;
  /** If tax free threshold claimed. */
  taxFreeThresholdClaimed: Scalars['Boolean'];
  /** The persons tax scale (e.g. REGULAR, FOREIGN). */
  taxScaleType?: InputMaybe<TaxScaleType>;
  /** Tenant timezone */
  tenantTimezone?: InputMaybe<Scalars['String']>;
  /** If a user is TFN exempt this is the type of exemption. */
  tfnExemptionType?: InputMaybe<TfnExemptionType>;
};

export type Currency = {
  __typename?: 'Currency';
  /** Currency code */
  code: Scalars['String'];
  /** Number of decimal places according to ISO 4217 */
  decimalPlaces?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Whether the currency is enabled for displaying in the system */
  isEnabled: Scalars['Boolean'];
  /** Long name for the currency */
  name: Scalars['String'];
  /** Symbol for the currency */
  symbol: Scalars['String'];
};

export type CurrencyFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type CustomExchangeRatesInput = {
  /** The base currency code for the exchange rate conversion */
  baseCurrency: Scalars['String'];
  /** If the exchange rates settings is enabled for the analytics pages */
  enabled: Scalars['Boolean'];
  /** The exchange rate records to the base currency */
  rates: Scalars['JSONObject'];
};

export type CustomExchangeRatesResponse = {
  __typename?: 'CustomExchangeRatesResponse';
  /** The base currency code for the exchange rate conversion */
  baseCurrency: Scalars['String'];
  /** If the exchange rates settings is enabled for the analytics pages */
  enabled: Scalars['Boolean'];
  /** The exchange rate records to the base currency */
  rates: Scalars['JSONObject'];
};

export type CustomFieldAnalyticsCategory = {
  __typename?: 'CustomFieldAnalyticsCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CustomFieldCategory = {
  __typename?: 'CustomFieldCategory';
  customFieldCount: Scalars['Int'];
  customFieldDefinitions: Array<CustomFieldDefinition>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  isInUse: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  /** The model type the custom field category belongs to */
  modelType: CustomFieldCategoryModelType;
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  templateCategory?: Maybe<CustomFieldTemplateCategory>;
};


export type CustomFieldCategorycustomFieldCountArgs = {
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type CustomFieldCategorycustomFieldDefinitionsArgs = {
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};

export type CustomFieldCategoryCreateInput = {
  customFieldIds?: InputMaybe<Array<Scalars['ID']>>;
  customFieldTemplateDefinitions?: InputMaybe<Array<CustomFieldTemplateDefinitions>>;
  /** The model type for the custom field category, defaults to person if not provided */
  modelType?: InputMaybe<CustomFieldCategoryModelType>;
  name: Scalars['String'];
  templateCategoryId?: InputMaybe<Scalars['ID']>;
};

export type CustomFieldCategoryCreateResponse = {
  __typename?: 'CustomFieldCategoryCreateResponse';
  customFieldCategory: CustomFieldCategory;
};

export type CustomFieldCategoryDeleteResponse = {
  __typename?: 'CustomFieldCategoryDeleteResponse';
  id: Scalars['ID'];
};

export type CustomFieldCategoryEditInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomFieldCategoryEditResponse = {
  __typename?: 'CustomFieldCategoryEditResponse';
  customFieldCategory: CustomFieldCategory;
};

export type CustomFieldCategoryFromToInput = {
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
};

export type CustomFieldCategoryFromToResponse = {
  __typename?: 'CustomFieldCategoryFromToResponse';
  customFieldCategories: Array<CustomFieldCategory>;
};

export enum CustomFieldCategoryModelType {
  PERSON = 'PERSON',
  POSITION_TITLE = 'POSITION_TITLE',
  REMUNERATION = 'REMUNERATION'
}

export type CustomFieldCategoryReorderInput = {
  categoryIds: Array<Scalars['ID']>;
};

export type CustomFieldCategoryReorderResponse = {
  __typename?: 'CustomFieldCategoryReorderResponse';
  customFieldCategories: Array<CustomFieldCategory>;
};

export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  /** The api name of the custom field definition */
  apiName: Scalars['String'];
  /** If the user can delete the custom field definition, e.g it is not in use or they are a super user */
  canDelete: Scalars['Boolean'];
  /** the category of this custom field definition */
  customFieldAnalyticsCategory: CustomFieldAnalyticsCategory;
  /** the category of this custom field definition */
  customFieldCategory: CustomFieldCategory;
  /** the category of this custom field definition */
  customFieldCategoryNullable?: Maybe<CustomFieldCategory>;
  /** the category of this custom field definition */
  customFieldTemplate?: Maybe<CustomFieldTemplate>;
  /** The definition details of the custom field definition, or null if no definition is needed for this type */
  definition?: Maybe<CustomFieldDefinitionDefinition>;
  /** The description of the custom field definition */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If the custom field should be displayed in the system */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** If the custom field is required */
  isMandatory: Scalars['Boolean'];
  /** If the custom field definition contains sensitive data */
  isSensitive: Scalars['Boolean'];
  /** The model type of the custom field definition */
  modelType: CustomFieldDefinitionModelType;
  /** The name of the custom field definition */
  name: Scalars['String'];
  /** The order in which custom field definition is sorted within categories */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The type of the custom field definition */
  type: CustomFieldType;
};

export type CustomFieldDefinitionDefinition = CustomFieldDefinitionPeopleDropdownTypeDefinition | CustomFieldDefinitionSelectTypeDefinition | CustomFieldDefinitionTextTypeDefinition | CustomFieldDefinitionUndefinedDefinition;

export enum CustomFieldDefinitionModelType {
  ADDRESS = 'ADDRESS',
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  JOB = 'JOB',
  LOCATION = 'LOCATION',
  PERSON = 'PERSON',
  PHONE_NUMBER = 'PHONE_NUMBER',
  POSITION_TITLE = 'POSITION_TITLE',
  REMUNERATION = 'REMUNERATION',
  TRAINING = 'TRAINING'
}

export type CustomFieldDefinitionPeopleDropdownTypeDefinition = {
  __typename?: 'CustomFieldDefinitionPeopleDropdownTypeDefinition';
  allowSelectingIndividualJobs: Scalars['Boolean'];
};

export type CustomFieldDefinitionReorderInput = {
  /** From current */
  fromId: Scalars['ID'];
  /** Place after */
  toId: Scalars['ID'];
};

export type CustomFieldDefinitionReorderResponse = {
  __typename?: 'CustomFieldDefinitionReorderResponse';
  customFieldDefinitions: Array<CustomFieldDefinition>;
};

export type CustomFieldDefinitionSelectOption = {
  __typename?: 'CustomFieldDefinitionSelectOption';
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
};

export type CustomFieldDefinitionSelectOptionInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
};

export type CustomFieldDefinitionSelectTypeDefinition = {
  __typename?: 'CustomFieldDefinitionSelectTypeDefinition';
  options: Array<CustomFieldDefinitionSelectOption>;
};


export type CustomFieldDefinitionSelectTypeDefinitionoptionsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type CustomFieldDefinitionTextTypeDefinition = {
  __typename?: 'CustomFieldDefinitionTextTypeDefinition';
  regex?: Maybe<Scalars['String']>;
  validationError?: Maybe<Scalars['String']>;
};

export type CustomFieldDefinitionUndefinedDefinition = {
  __typename?: 'CustomFieldDefinitionUndefinedDefinition';
  _empty?: Maybe<Scalars['String']>;
};

export type CustomFieldInputCreate = {
  /** The api name of the custom field definition */
  apiName: Scalars['String'];
  /** The category of the custom field definition */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** The custom field analytics category this definition belongs to */
  customFieldAnalyticsCategoryId?: InputMaybe<Scalars['ID']>;
  /** The custom field template this definition belongs to */
  customFieldTemplateId?: InputMaybe<Scalars['ID']>;
  /** The description of the custom field definition */
  description?: InputMaybe<Scalars['String']>;
  /** The custom field is required */
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  /** Identify if the custom field definition have sensitive information */
  isSensitive?: InputMaybe<Scalars['Boolean']>;
  /** The model type of the custom field definition */
  modelType: CustomFieldDefinitionModelType;
  /** The name of the custom field definition */
  name: Scalars['String'];
  /** The people dropdown definition of the custom field definition */
  peopleDropdownDefinition?: InputMaybe<CustomFieldPeopleDropdownDefinitionInput>;
  /** The select definition of the custom field definition */
  selectDefinition?: InputMaybe<CustomFieldSelectDefinitionInput>;
  /** The text validation of the custom field definition */
  textDefinition?: InputMaybe<CustomFieldTextDefinitionInput>;
  /** The data type that belong to this custom field definition */
  type: CustomFieldType;
};

export type CustomFieldPeopleDropdownDefinitionInput = {
  allowSelectingIndividualJobs: Scalars['Boolean'];
};

export type CustomFieldSelectDefinitionInput = {
  options?: InputMaybe<Array<InputMaybe<CustomFieldDefinitionSelectOptionInput>>>;
};

export type CustomFieldTemplate = {
  __typename?: 'CustomFieldTemplate';
  /** the category of this custom field template */
  customFieldAnalyticsCategory?: Maybe<CustomFieldAnalyticsCategory>;
  /** The name that should be populated into the custom field definition */
  customFieldName: Scalars['String'];
  /** The definition details of the custom field definition, or null if no definition is needed for this type */
  definition?: Maybe<CustomFieldDefinitionDefinition>;
  /** The description of the custom field template */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** The model type of the custom field template */
  modelType: CustomFieldDefinitionModelType;
  /** The name of the custom field template */
  name: Scalars['String'];
  templateCategory?: Maybe<CustomFieldTemplateCategory>;
  /** The type of the custom field template */
  type: CustomFieldType;
};

export type CustomFieldTemplateCategory = {
  __typename?: 'CustomFieldTemplateCategory';
  definitionTemplates: Array<CustomFieldTemplate>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  /** The model type of the custom field category template */
  modelType: CustomFieldCategoryModelType;
  name: Scalars['String'];
};

export type CustomFieldTemplateCategoryCreateInput = {
  customFieldTemplateIds?: InputMaybe<Array<Scalars['ID']>>;
  modelType: CustomFieldCategoryModelType;
  name: Scalars['String'];
};

export type CustomFieldTemplateCategoryCreateResponse = {
  __typename?: 'CustomFieldTemplateCategoryCreateResponse';
  customFieldTemplateCategory: CustomFieldTemplateCategory;
};

export type CustomFieldTemplateCategoryEditInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type CustomFieldTemplateCategoryEditResponse = {
  __typename?: 'CustomFieldTemplateCategoryEditResponse';
  customFieldTemplateCategory: CustomFieldTemplateCategory;
};

export type CustomFieldTemplateCreateInput = {
  /** The analytics category that the custom field template belongs to */
  analyticsCategoryId?: InputMaybe<Scalars['ID']>;
  /** The template category that the custom field template belongs to */
  categoryTemplateId?: InputMaybe<Scalars['ID']>;
  /** The name that should be populated into the custom field definition */
  customFieldName: Scalars['String'];
  /** The description of the custom field template */
  description?: InputMaybe<Scalars['String']>;
  /** The model type of the custom field template */
  modelType: CustomFieldDefinitionModelType;
  /** The name of the custom field template */
  name: Scalars['String'];
  /** The people dropdown definition of the custom field template */
  peopleDropdownDefinition?: InputMaybe<CustomFieldPeopleDropdownDefinitionInput>;
  /** The select template of the custom field template */
  selectDefinition?: InputMaybe<CustomFieldSelectDefinitionInput>;
  /** The api name of the custom field template */
  type: CustomFieldType;
};

export type CustomFieldTemplateCreateResponse = {
  __typename?: 'CustomFieldTemplateCreateResponse';
  customFieldTemplate: CustomFieldTemplate;
};

export type CustomFieldTemplateDefinitions = {
  apiName: Scalars['String'];
  customFieldTemplateId: Scalars['ID'];
};

export type CustomFieldTemplateUpdateInput = {
  /** ID for custom field templates */
  id: Scalars['ID'];
  /** Enabled status to see if it enabled in tenant */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type CustomFieldTemplateUpdateResponse = {
  __typename?: 'CustomFieldTemplateUpdateResponse';
  customFieldTemplate: CustomFieldTemplate;
};

export type CustomFieldTextDefinitionInput = {
  regex?: InputMaybe<Scalars['String']>;
  validationError?: InputMaybe<Scalars['String']>;
};

export enum CustomFieldType {
  MULTI_SELECT = 'MULTI_SELECT',
  NUMBER = 'NUMBER',
  PEOPLE_DROPDOWN = 'PEOPLE_DROPDOWN',
  SINGLE_SELECT = 'SINGLE_SELECT',
  TEXT = 'TEXT'
}

export type CustomFieldUpdateInput = {
  /** The category of the custom field definition */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** The custom field analytics category this definition belongs to */
  customFieldAnalyticsCategoryId?: InputMaybe<Scalars['ID']>;
  /** The custom field template this definition belongs to */
  customFieldTemplateId?: InputMaybe<Scalars['ID']>;
  /** The description of the custom field definition */
  description?: InputMaybe<Scalars['String']>;
  /** The custom field definition id */
  id: Scalars['ID'];
  /** If the custom field should be displayed in the system */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The custom field is required */
  isMandatory?: InputMaybe<Scalars['Boolean']>;
  /** Identify if the custom field definition have sensitive information */
  isSensitive?: InputMaybe<Scalars['Boolean']>;
  /** The name of the custom field definition */
  name?: InputMaybe<Scalars['String']>;
  /** The people dropdown definition of the custom field definition */
  peopleDropdownDefinition?: InputMaybe<CustomFieldPeopleDropdownDefinitionInput>;
  /** The select definition of the custom field definition (for multi select/single select fields) */
  selectDefinition?: InputMaybe<UpdateCustomFieldSelectDefinitionInput>;
  /** The text validation of the custom field definition */
  textDefinition?: InputMaybe<CustomFieldTextDefinitionInput>;
};

export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  /** The api name the custom field is using */
  apiName: Scalars['String'];
  /** The definition of the custom field value */
  definition: CustomFieldDefinition;
  endDate?: Maybe<Scalars['DateTime']>;
  /** The model id the custom field belongs to. E.g., if modelType is TRAINING, this is a training id. */
  modelId: Scalars['ID'];
  /** The model type the custom field value belongs to, such as TRAINING, PERSON etc. */
  modelType: CustomFieldDefinitionModelType;
  startDate?: Maybe<Scalars['DateTime']>;
  /** The type of the custom field, such as TEXT, NUMBER etc. */
  type: CustomFieldType;
  /**
   * The actual raw value of the custom field as a json object.
   * @deprecated You likely want to fetch the exact values using valueUnion
   */
  value?: Maybe<Scalars['CustomFieldValueValue']>;
  /** The actual value of the custom field, as a union of potential values. Null when no value selected. */
  valueUnion?: Maybe<CustomFieldValueUnion>;
};

export type CustomFieldValueMultiSelect = {
  __typename?: 'CustomFieldValueMultiSelect';
  /** The list of option ids the user selected for this custom field */
  ids: Array<Scalars['ID']>;
  /** The list of option labels the user selected for this custom field */
  labels: Array<Scalars['String']>;
};

export type CustomFieldValueNumber = {
  __typename?: 'CustomFieldValueNumber';
  /** The number the user inputted for this custom field, as a string */
  number: Scalars['String'];
};

export type CustomFieldValuePeopleDropdown = {
  __typename?: 'CustomFieldValuePeopleDropdown';
  /** The list of people options the user selected for this custom field */
  options: Array<CustomFieldValuePeopleDropdownOption>;
};

export type CustomFieldValuePeopleDropdownOption = {
  __typename?: 'CustomFieldValuePeopleDropdownOption';
  /** The job the uesr selected for this custom field, or null if no specific job was selected (i.e. default to primary job) */
  job?: Maybe<Job>;
  /** The person the user selected for this custom field */
  person: Person;
};

export type CustomFieldValueSingleSelect = {
  __typename?: 'CustomFieldValueSingleSelect';
  /** The ID of the option the user selected for this custom field */
  id: Scalars['ID'];
  /** The label of the option the user selected for this custom field */
  label: Scalars['String'];
};

export type CustomFieldValueText = {
  __typename?: 'CustomFieldValueText';
  regexValid?: Maybe<Scalars['Boolean']>;
  /** The text the user inputted for this custom field */
  text: Scalars['String'];
};

export type CustomFieldValueUnion = CustomFieldValueMultiSelect | CustomFieldValueNumber | CustomFieldValuePeopleDropdown | CustomFieldValueSingleSelect | CustomFieldValueText;

export type DashboardDirectReport = {
  __typename?: 'DashboardDirectReport';
  /** The job */
  job: Job;
};

export type DashboardQuote = {
  __typename?: 'DashboardQuote';
  quote: Scalars['String'];
};

export type DateFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  greaterThan?: InputMaybe<Scalars['DateTime']>;
  greaterThanOrEquals?: InputMaybe<Scalars['DateTime']>;
  lessThan?: InputMaybe<Scalars['DateTime']>;
  lessThanOrEquals?: InputMaybe<Scalars['DateTime']>;
};

export enum DateFormat {
  DMY = 'DMY',
  MDY = 'MDY',
  VERBOSE = 'VERBOSE',
  YMD = 'YMD'
}

export type DefaultBusinessPerformanceScoreScale = {
  __typename?: 'DefaultBusinessPerformanceScoreScale';
  /** A scale is made up of a list of intervals */
  scale?: Maybe<Array<DefaultBusinessPerformanceScoreScaleInterval>>;
};

export type DefaultBusinessPerformanceScoreScaleInterval = {
  __typename?: 'DefaultBusinessPerformanceScoreScaleInterval';
  /** label of the scale interval, e.g. 0~20% */
  label: Scalars['String'];
};

export type DefaultRemunerationComponent = {
  __typename?: 'DefaultRemunerationComponent';
  /** Whether the default remuneration component should be added to newly created/updated jobs */
  automaticallyAddToJobs: Scalars['Boolean'];
  /** The currency for this default remuneration component. For multiples, this is always the same as the base currency. */
  currency?: Maybe<Currency>;
  id: Scalars['ID'];
  /** If the default remuneration component is enabled for use in the system */
  isEnabled: Scalars['Boolean'];
  /** Whether the default remuneration component is used as any remuneration components */
  isInUse: Scalars['Boolean'];
  /** Human-readable name for this addition/deduction */
  name: Scalars['String'];
  /** The type of this default remuneration component (Addition, Deduction, etc) */
  type: RemunerationComponentType;
  /** The value of the addition/deduction. */
  value: Scalars['String'];
  /** The type of the value of this default remuneration component (FIXED_AMOUNT, for example) */
  valueType?: Maybe<RemunerationComponentValueType>;
};

export type DefaultRemunerationComponentCreateInput = {
  /** Whether to automatically apply this remuneration component to newly created jobs */
  automaticallyAddToJobs?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the currency of this default remuneration component. For multiple of base value types, this should be null. */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** The display name of the default remuneration component */
  name: Scalars['String'];
  /** The type of remuneration component (e.g. Addition on Base) */
  type: RemunerationComponentType;
  /**
   * The value of the remuneration component, for multiple of base amounts, this is
   * represented as a decimal percentage (e.g. 0.095 for 9.5%)
   */
  value: Scalars['String'];
  /** The type of value for the remuneration component (e.g. FixedAmount) */
  valueType?: InputMaybe<RemunerationComponentValueType>;
};

export type DefaultRemunerationComponentCreateResponse = {
  __typename?: 'DefaultRemunerationComponentCreateResponse';
  /** The defaultRemunerationComponent that has been created. */
  defaultRemunerationComponent: DefaultRemunerationComponent;
};

export type DefaultRemunerationComponentDeleteResponse = {
  __typename?: 'DefaultRemunerationComponentDeleteResponse';
  /** The ID of the defaultRemunerationComponent that was deleted */
  id: Scalars['ID'];
};

export type DefaultRemunerationComponentFilters = {
  automaticallyAddToJobs?: InputMaybe<BooleanFilter>;
  isEnabled?: InputMaybe<BooleanFilter>;
  type?: InputMaybe<ValueFilter>;
};

export type DefaultRemunerationComponentUpdateInput = {
  /** Whether to automatically apply this remuneration component to newly created jobs */
  automaticallyAddToJobs?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the currency of this default remuneration component. For multiple of base value types, this should be null. */
  currencyId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Whether the default remuneration component is enabled for use in the system */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * The value of the remuneration component, for multiple of base amounts, this is
   * represented as a decimal percentage (e.g. 0.095 for 9.5%)
   */
  value?: InputMaybe<Scalars['String']>;
};

export type DefaultRemunerationComponentUpdateResponse = {
  __typename?: 'DefaultRemunerationComponentUpdateResponse';
  /** The defaultRemunerationComponent that has been created. */
  defaultRemunerationComponent: DefaultRemunerationComponent;
};

export type DeleteAccessKeyInput = {
  /** The access key to delete by ID */
  id: Scalars['ID'];
};

export type DeleteAccessKeyResponse = {
  __typename?: 'DeleteAccessKeyResponse';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteAnalyticsPermissionResponse = {
  __typename?: 'DeleteAnalyticsPermissionResponse';
  /** Whether the deletion was successful */
  wasSuccessful: Scalars['Boolean'];
};

export type DeleteAssignedSkillForPersonInput = {
  personId: Scalars['ID'];
  skillId: Scalars['ID'];
};

export type DeleteAssignedSkillForPersonResponse = {
  __typename?: 'DeleteAssignedSkillForPersonResponse';
  personId: Scalars['ID'];
  skillId: Scalars['ID'];
};

export type DeleteCompensationPlanResponse = {
  __typename?: 'DeleteCompensationPlanResponse';
  compensationPlan: CompensationPlan;
};

export type DeleteCompensationPlannerPlanResponse = {
  __typename?: 'DeleteCompensationPlannerPlanResponse';
  id: Scalars['ID'];
};

export type DeleteContractResponse = {
  __typename?: 'DeleteContractResponse';
  id: Scalars['ID'];
};

export type DeleteCustomFieldResponse = {
  __typename?: 'DeleteCustomFieldResponse';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteFormResponse = {
  __typename?: 'DeleteFormResponse';
  id: Scalars['ID'];
};

export type DeleteGoalCommentInput = {
  /** The id of the comment */
  commentId: Scalars['ID'];
  /** The id of the goal on which the comment was made */
  goalId: Scalars['ID'];
  /** The id of the person who the comment belongs to */
  personId: Scalars['ID'];
};

export type DeleteGoalCommentResponse = {
  __typename?: 'DeleteGoalCommentResponse';
  commentId: Scalars['ID'];
  goalId: Scalars['ID'];
};

export type DeleteOnboardingSessionResponse = {
  __typename?: 'DeleteOnboardingSessionResponse';
  success: Scalars['Boolean'];
};

export type DeletePersonProfileCoverPictureResponse = {
  __typename?: 'DeletePersonProfileCoverPictureResponse';
  person?: Maybe<Person>;
};

export type DeletePersonProfilePictureResponse = {
  __typename?: 'DeletePersonProfilePictureResponse';
  person?: Maybe<Person>;
};

export type DeletePulseRecurrenceResponse = {
  __typename?: 'DeletePulseRecurrenceResponse';
  id: Scalars['ID'];
};

export type DeletePulseResponse = {
  __typename?: 'DeletePulseResponse';
  id: Scalars['ID'];
};

export type DeleteScheduledFormResponse = {
  __typename?: 'DeleteScheduledFormResponse';
  id: Scalars['ID'];
};

export type DeleteSkillDisciplineInput = {
  id: Scalars['ID'];
};

export type DeleteSkillDisciplineResponse = {
  __typename?: 'DeleteSkillDisciplineResponse';
  id: Scalars['ID'];
};

export type DeleteSkillInput = {
  id: Scalars['ID'];
};

export type DeleteSkillResponse = {
  __typename?: 'DeleteSkillResponse';
  id: Scalars['ID'];
};

export type DeleteTaskResponse = {
  __typename?: 'DeleteTaskResponse';
  id: Scalars['ID'];
};

export type DeleteTenantPermissionConfigurationResponse = {
  __typename?: 'DeleteTenantPermissionConfigurationResponse';
  /** Whether the deletion was successful */
  wasSuccessful: Scalars['Boolean'];
};

export type DeleteUserPermissionGroupResponse = {
  __typename?: 'DeleteUserPermissionGroupResponse';
  userPermissionGroupId: Scalars['ID'];
};

export type DeleteWorkflowInput = {
  /** The workflow to delete by ID */
  id: Scalars['ID'];
};

export type DeleteWorkflowResponse = {
  __typename?: 'DeleteWorkflowResponse';
  id?: Maybe<Scalars['ID']>;
};

export type DiaryNote = {
  __typename?: 'DiaryNote';
  /** The action plan action to be taken by business of the diary note */
  actionByBusiness?: Maybe<Scalars['String']>;
  /** The action plan remedial action to be taken by staff member of the diary note */
  actionByEmployee?: Maybe<Scalars['String']>;
  /** Due date for action plan */
  actionPlanDueDate?: Maybe<Scalars['DateTime']>;
  /** List of all performance letter templates with prefilled variables for the current diary note */
  availableLetterTemplates?: Maybe<Array<AvailableLetterTemplate>>;
  category: DiaryNoteCategory;
  /** The cost of the diary note */
  cost?: Maybe<Scalars['Float']>;
  /** The cost currency for this diary note */
  costCurrency: Currency;
  /** The cost of the diary note in the tenant's currency */
  costInTenantCurrency?: Maybe<Scalars['Float']>;
  /** The person who created this diary note in the system */
  createdByPerson?: Maybe<Person>;
  /** The date which this diary note pertains to */
  date: Scalars['DateTime'];
  /** The description of the diary note */
  description: Scalars['String'];
  /** The category with the corresponding category ID of the diary note */
  diaryNoteCategory: DiaryNoteCategory;
  /** Lists the documents uploaded to this diary note */
  diaryNoteDocuments: Array<Document>;
  /** The diary note type this diary note has */
  diaryNoteType: DiaryNoteType;
  /** Lists the documents uploaded to this diary note */
  documents: Array<DiaryNoteDocument>;
  /** The person who follow up this diary note in the system */
  followUpByPerson?: Maybe<Person>;
  /** Follow up date for action plan */
  followUpDate?: Maybe<Scalars['DateTime']>;
  /** The follow up action required by business of the diary note */
  followUpRequiredByBusiness?: Maybe<Scalars['String']>;
  /** Follow up email sent date */
  followUpSentDate?: Maybe<Scalars['DateTime']>;
  /** Has Follow up email been sent */
  hasFollowUpBeenSent?: Maybe<Scalars['Boolean']>;
  /** Boolean to check if a diary note has a saved HTML letter template without pulling the entire template */
  hasHTMLLetterContent: Scalars['Boolean'];
  /** The hour with employee of the diary note */
  hoursWithEmployee?: Maybe<Scalars['Float']>;
  /** The default or saved HTML letter template for this diary note */
  htmlLetterContent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Is the Diary Note only accessible to those with the Restricted Diary Notes permission */
  isRestricted: Scalars['Boolean'];
  /** The job of the subject of the diary note */
  job: Job;
  /** The ID of the job of the subject of the diary note */
  jobId: Scalars['ID'];
  /** The access code for the letter */
  letterContentAccessCode?: Maybe<Scalars['String']>;
  /** Performance improvement plan this diary note has converted to */
  performanceImprovementPlan?: Maybe<PerformanceImprovementPlan>;
  /** Performance Improvement Plan Status Id */
  performanceImprovementPlanStatus?: Maybe<PerformanceImprovementPlanStatus>;
  /** The subject of the diary note */
  person: Person;
  /** The ID of the subject of the diary note */
  personId: Scalars['ID'];
  /** The job of the person who physically reported this incident */
  reportedByJob?: Maybe<Job>;
  /** Require Follow Up option */
  requiresFollowUp?: Maybe<Scalars['Boolean']>;
  /** Send Action Plan option */
  shouldSendActionPlan?: Maybe<Scalars['Boolean']>;
  /** The summary of the diary note */
  summary: Scalars['String'];
  /** The total cost of the diary note in the tenant's currency */
  totalCostInTenantCurrency?: Maybe<Scalars['Float']>;
  /** The hour of the diary note */
  totalHours?: Maybe<Scalars['Float']>;
  type: DiaryNoteType;
};

export type DiaryNoteCategory = {
  __typename?: 'DiaryNoteCategory';
  /** The number of diary notes that uses the diary note category */
  diaryNoteUsageCount: Scalars['Int'];
  id: Scalars['ID'];
  /** If the diary note category is of type enabled */
  isEnabled: Scalars['Boolean'];
  /** The human-readable name of the diary note category */
  name: Scalars['String'];
  /** The number of performance improvement plans that uses the diary note category */
  performanceImprovementPlanUsageCount: Scalars['Int'];
};

export type DiaryNoteCategoryCreateInput = {
  /** If the diary note category is of type enabled. Defaults to true */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The human-readable name of the diary note category */
  name: Scalars['String'];
};

export type DiaryNoteCategoryCreateResponse = {
  __typename?: 'DiaryNoteCategoryCreateResponse';
  diaryNoteCategory?: Maybe<DiaryNoteCategory>;
};

export type DiaryNoteCategoryDeleteResponse = {
  __typename?: 'DiaryNoteCategoryDeleteResponse';
  diaryNoteCategoryId: Scalars['ID'];
};

export type DiaryNoteCategoryUpdateInput = {
  /** The diary note category ID */
  id: Scalars['ID'];
  /** If the diary note category is of type enabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The human-readable name of the diary note category */
  name?: InputMaybe<Scalars['String']>;
};

export type DiaryNoteCategoryUpdateResponse = {
  __typename?: 'DiaryNoteCategoryUpdateResponse';
  diaryNoteCategory?: Maybe<DiaryNoteCategory>;
};

export type DiaryNoteCreateStandaloneInput = {
  categoryId: Scalars['ID'];
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  description: Scalars['String'];
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  reportedByJobId: Scalars['ID'];
  requiresFollowUp: Scalars['Boolean'];
  subjectHours?: InputMaybe<Scalars['Float']>;
  subjectJobId: Scalars['ID'];
  summary: Scalars['String'];
};

export type DiaryNoteCreateStandaloneResponse = {
  __typename?: 'DiaryNoteCreateStandaloneResponse';
  diaryNote: DiaryNote;
};

export type DiaryNoteDeleteResponse = {
  __typename?: 'DiaryNoteDeleteResponse';
  diaryNoteId: Scalars['ID'];
};

export type DiaryNoteDocument = {
  __typename?: 'DiaryNoteDocument';
  /**
   * The date at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   */
  createdAt: Scalars['ID'];
  /** The readable name of this document (including extension) */
  displayName: Scalars['String'];
  /**
   * The url the document can be downloaded from
   * @deprecated Use downloadUrlAbsolute instead to avoid needing to reconstruct the URL in the client
   */
  downloadUrl: Scalars['String'];
  /** The absolute url the document can be downloaded from */
  downloadUrlAbsolute: Scalars['String'];
  /** The extension of the document (e.g. .jpg, .pdf) */
  extension: Scalars['String'];
  /** The size of the document as a human readable measurement */
  humanReadableSize: Scalars['String'];
  id: Scalars['ID'];
  /** The size of the document in bytes */
  size: Scalars['Int'];
  /** The current status of uploading this document */
  uploadStatus: DiaryNoteDocumentUploadStatus;
};

export type DiaryNoteDocumentCreateInput = {
  /** The ID of the Diary Note to create the document for */
  diaryNoteId: Scalars['ID'];
  /** The extension of the document */
  extension: Scalars['String'];
  /** The name of the document */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type DiaryNoteDocumentCreateResponse = {
  __typename?: 'DiaryNoteDocumentCreateResponse';
  document: Document;
  /** A URL to upload the document to */
  presignedUrl: Scalars['String'];
};

export type DiaryNoteDocumentDeleteResponse = {
  __typename?: 'DiaryNoteDocumentDeleteResponse';
  /** The ID of the deleted goal document */
  id: Scalars['ID'];
};

export type DiaryNoteDocumentUpdateInput = {
  /** The name of the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document */
  id: Scalars['ID'];
  /** The current status of the upload of this document */
  uploadStatus?: InputMaybe<DocumentUploadStatus>;
};

export type DiaryNoteDocumentUpdateResponse = {
  __typename?: 'DiaryNoteDocumentUpdateResponse';
  document: Document;
};

export enum DiaryNoteDocumentUploadStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export type DiaryNoteType = {
  __typename?: 'DiaryNoteType';
  id: Scalars['ID'];
  /** If the diary note type is of type enabled */
  isEnabled: Scalars['Boolean'];
  /** The human-readable name of the diary note type */
  name: Scalars['String'];
};

export type DiaryNoteUpdateStandaloneInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  reportedByJobId?: InputMaybe<Scalars['ID']>;
  requiresFollowUp?: InputMaybe<Scalars['Boolean']>;
  subjectHours?: InputMaybe<Scalars['Float']>;
  subjectJobId?: InputMaybe<Scalars['ID']>;
  summary?: InputMaybe<Scalars['String']>;
};

export type DiaryNoteUpdateStandaloneResponse = {
  __typename?: 'DiaryNoteUpdateStandaloneResponse';
  diaryNote: DiaryNote;
};

export type DirectReportsSupervisionPeriod = {
  __typename?: 'DirectReportsSupervisionPeriod';
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DisableMFAResponse = {
  __typename?: 'DisableMFAResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Document = {
  __typename?: 'Document';
  /**
   * The date at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   */
  createdAt: Scalars['ID'];
  /**
   * The date at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   */
  createdAtTimestamp: Scalars['DateTime'];
  /** The readable name of this document (including extension) */
  displayName: Scalars['String'];
  /** The absolute url the document can be downloaded from */
  downloadUrlAbsolute: Scalars['String'];
  /** The extension of the document (e.g. .jpg, .pdf) */
  extension: Scalars['String'];
  /** The size of the document as a human readable measurement */
  humanReadableSize: Scalars['String'];
  id: Scalars['ID'];
  /** The size of the document in bytes */
  size: Scalars['Int'];
  /** The current status of uploading this document */
  uploadStatus: DocumentUploadStatus;
};

export type DocumentFolder = {
  __typename?: 'DocumentFolder';
  /** The api name of the folder */
  apiName: Scalars['String'];
  /** The date time of the folder is created */
  createdAt: Scalars['DateTime'];
  /** the description of the folder */
  description?: Maybe<Scalars['String']>;
  /** The ID of the folder */
  id: Scalars['ID'];
  /** Whether the document folder is in use */
  isInUse: Scalars['Boolean'];
  /** Whether this folder is an internal system plan. Only general folder at this time */
  isSystem: Scalars['Boolean'];
  /** The name of the folder */
  name: Scalars['String'];
  /** A list of Permission Group names that have access to this folder, organised based on the permission type */
  roleNamesWithPermission: DocumentFolderPermissionGroups;
  /** The date time of the folder is updated */
  updatedAt: Scalars['DateTime'];
};

export type DocumentFolderCreateInput = {
  /** The api name of the folder */
  apiName: Scalars['String'];
  /** The description of the folder */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the folder */
  name: Scalars['String'];
};

export type DocumentFolderCreateResponse = {
  __typename?: 'DocumentFolderCreateResponse';
  documentFolder: DocumentFolder;
  /** An array of permissions that were created */
  permissionSlugsCreated: Array<Scalars['String']>;
};

export type DocumentFolderDeleteInput = {
  /** The ID of the document folder to delete */
  id: Scalars['ID'];
};

export type DocumentFolderDeleteResponse = {
  __typename?: 'DocumentFolderDeleteResponse';
  /** The ID of the document folder that was deleted */
  id: Scalars['ID'];
};

export type DocumentFolderPermissionGroups = {
  __typename?: 'DocumentFolderPermissionGroups';
  delete: Array<Maybe<Scalars['String']>>;
  edit: Array<Maybe<Scalars['String']>>;
  view: Array<Maybe<Scalars['String']>>;
};

export type DocumentFolderUpdateInput = {
  /** The description of the folder */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the folder */
  id: Scalars['String'];
  /** The name of the folder */
  name?: InputMaybe<Scalars['String']>;
};

export type DocumentFolderUpdateResponse = {
  __typename?: 'DocumentFolderUpdateResponse';
  documentFolder: DocumentFolder;
};

export enum DocumentUploadStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export type DraftQualificationInstanceUpdateInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  expiryNotificationQueuedAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganisation?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type DuplicateWorkflowInput = {
  /** The description of the workflow */
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** The number of the workflow */
  name: Scalars['String'];
  /** The workflow event id that belong to this workflow */
  workflowEventId: Scalars['ID'];
};

export type DuplicateWorkflowResponse = {
  __typename?: 'DuplicateWorkflowResponse';
  workflow?: Maybe<Workflow>;
};

export type EditFormInput = {
  /** The job id to assign the new form to */
  expectedRespondentJobId?: InputMaybe<Scalars['ID']>;
  /** The ID of the form to edit */
  id: Scalars['ID'];
};

export type EditFormResponse = {
  __typename?: 'EditFormResponse';
  /** The form that was edited */
  form: Form;
};

export type EditTaskInput = {
  /** The job id to assign the new task to */
  expectedRespondentJobId?: InputMaybe<Scalars['ID']>;
  /** The ID of the task to edit */
  id: Scalars['ID'];
};

export type EditTaskResponse = {
  __typename?: 'EditTaskResponse';
  /** The task that was edited */
  task: Task;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  customFieldValues: Array<CustomFieldValue>;
  /** The email address */
  email: Scalars['String'];
  id: Scalars['ID'];
  /** If the email address is personal */
  isPersonal: Scalars['Boolean'];
  /** If the email address is primary */
  isPrimary: Scalars['Boolean'];
  /** The name of the email address */
  name: Scalars['String'];
  /** The person who owns the email address */
  person: Person;
  /**
   * The person who the email address belongs to
   * @deprecated User person instead
   */
  personId: Scalars['ID'];
};


export type EmailAddresscustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type EmailAddressCreateInput = {
  /** Custom field values for the email address */
  customFieldValues?: InputMaybe<Array<EmailAddressCustomFieldValueInput>>;
  /** The actual email address of the person. */
  email: Scalars['String'];
  /**
   * If the email address should be classified as work or personal, the permissions
   * required will depend on if this is true or false.
   */
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  /** Each person can have only one primary address. If true this will be the email the system defaults to primary. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the person whom the email address belongs to. */
  personId: Scalars['ID'];
};

export type EmailAddressCreateResponse = {
  __typename?: 'EmailAddressCreateResponse';
  /** The email address that has been created for the person. */
  emailAddress: EmailAddress;
  /** The person who owns the email address that has been created. */
  person: Person;
};

export type EmailAddressCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type EmailAddressDeleteResponse = {
  __typename?: 'EmailAddressDeleteResponse';
  /** The ID of the email address that was deleted */
  id: Scalars['ID'];
  /** The person who owned the email address that was deleted. Used to reload the list of emails. */
  person: Person;
};

export type EmailAddressUpdateInput = {
  /** Custom field values for the email address */
  customFieldValues?: InputMaybe<Array<EmailAddressCustomFieldValueInput>>;
  /** The email address as string */
  email?: InputMaybe<Scalars['String']>;
  /** The ID of the email address that is updated. */
  id: Scalars['ID'];
  /** Boolean value checking if email is personal email address of person. Can be more than one */
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  /** Boolean value checking if email is primary email address of person. Can only be one. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type EmailAddressUpdateResponse = {
  __typename?: 'EmailAddressUpdateResponse';
  /** The email address that is updated. */
  emailAddress: EmailAddress;
  /** The person who owns the email address that is updated. */
  person: Person;
};

export type EmailRecipients = {
  __typename?: 'EmailRecipients';
  exclude: Array<Scalars['ID']>;
  include: Array<Scalars['ID']>;
};

export type EmailRecipientsInput = {
  /** List of user ids to be excluded for email recipients */
  exclude: Array<Scalars['ID']>;
  /** List of user ids to be included for email recipients */
  include: Array<Scalars['ID']>;
};

export enum EmploymentBasis {
  CASUAL = 'CASUAL',
  FULLTIME = 'FULLTIME',
  LABOURHIRE = 'LABOURHIRE',
  PARTTIME = 'PARTTIME',
  SUPERINCOMESTREAM = 'SUPERINCOMESTREAM'
}

export type EmploymentCondition = {
  __typename?: 'EmploymentCondition';
  /** Legal name for which award this condition represents */
  awardName?: Maybe<Scalars['String']>;
  /** Returns the amount of active jobs */
  currentJobCount: Scalars['Int'];
  /** Extra details about this employment condition */
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Whether the condition is enabled to be seen in the system */
  isEnabled: Scalars['Boolean'];
  /** Whether the condition is in use */
  isInUse: Scalars['Boolean'];
  /** Whether the employment condition is no employment condition */
  isNoEmploymentCondition: Scalars['Boolean'];
  /** Human readable name for the condition */
  name: Scalars['String'];
  /** Returns the number of pay grades belonging to the employment condition at the given date, or now if null */
  payGradeCount: Scalars['Int'];
  /** Returns a list of pay grades belonging to the employment condition at the given date, or now if null */
  payGrades: Array<PayGrade>;
  /** Returns the amount of total jobs */
  totalJobCount: Scalars['Int'];
};


export type EmploymentConditionpayGradeCountArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EmploymentConditionpayGradesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmploymentConditionCreateInput = {
  /** Legal name for which award this condition represents */
  awardName?: InputMaybe<Scalars['String']>;
  /** Extra details about this employment condition */
  details?: InputMaybe<Scalars['String']>;
  /** Whether the condition is enabled to be seen in the system */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Human readable name for the condition */
  name: Scalars['String'];
  payGradeAsAt?: InputMaybe<Scalars['DateTime']>;
  payGradeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EmploymentConditionCreateResponse = {
  __typename?: 'EmploymentConditionCreateResponse';
  employmentCondition: EmploymentCondition;
};

export type EmploymentConditionDeleteResponse = {
  __typename?: 'EmploymentConditionDeleteResponse';
  /** The ID of the employment Condition that was deleted */
  id: Scalars['ID'];
};

export type EmploymentConditionFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type EmploymentConditionUpdateInput = {
  /** Legal name for which award this condition represents */
  awardName?: InputMaybe<Scalars['String']>;
  /** Extra details about this employment condition */
  details?: InputMaybe<Scalars['String']>;
  /** The id of employment Condition to update */
  id: Scalars['ID'];
  /** Whether the condition is enabled to be seen in the system */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Human readable name for the condition */
  name?: InputMaybe<Scalars['String']>;
  payGradeAsAt?: InputMaybe<Scalars['DateTime']>;
  payGradeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EmploymentConditionUpdateResponse = {
  __typename?: 'EmploymentConditionUpdateResponse';
  /** The employment condition that was updated */
  employmentCondition: EmploymentCondition;
};

export enum EmploymentType {
  CONTRACTOR = 'CONTRACTOR',
  EMPLOYEE = 'EMPLOYEE'
}

export type Establishment = {
  __typename?: 'Establishment';
  /** If the establishment has active children */
  activeChildrenCount?: Maybe<Scalars['Int']>;
  /** @deprecated Establishments no longer support effective dates */
  allEstablishmentChildren: Array<EstablishmentSupervisor>;
  /** @deprecated Establishments no longer support effective dates */
  allEstablishmentSupervisors: Array<EstablishmentSupervisor>;
  /**
   * Gets all requirements over all time
   * @deprecated Establishments no longer support effective dates
   */
  allRequirements: Array<EstablishmentRequirementInterface>;
  /**
   * The start date of the establishment, or null for start of time
   * @deprecated Establishments no longer support effective dates
   */
  availableFrom?: Maybe<Scalars['DateTime']>;
  /**
   * The end date of the establishment, or null for end of time
   * @deprecated Establishments no longer support effective dates
   */
  availableTo?: Maybe<Scalars['DateTime']>;
  /** The unique identifier used by other systems */
  code?: Maybe<Scalars['String']>;
  /** A brief description of the position */
  description?: Maybe<Scalars['String']>;
  establishmentChildren: Array<EstablishmentSupervisor>;
  establishmentSupervisor?: Maybe<EstablishmentSupervisor>;
  id: Scalars['ID'];
  /** The count of jobs currently assigned to this establishment that have at least one invalid requirement */
  invalidJobCount: Scalars['Int'];
  /** If this establishment is enabled or disabled */
  isEnabled: Scalars['Boolean'];
  /** If this establishment is currently being used by an undeleted job */
  isInUse: Scalars['Boolean'];
  /**
   * If the avaliable to is in the past
   * @deprecated Effective dates are deprecated use isEnabled
   */
  isPast?: Maybe<Scalars['Boolean']>;
  /** If this establishment is system defined and therefore cannot be modified */
  isSystem: Scalars['Boolean'];
  /** The count of jobs currently assigned to this establishment */
  jobCount: Scalars['Int'];
  /** The jobs belonging to this establishment */
  jobs: Array<Job>;
  /** If the establishment has active children */
  jobsOnLeaveCount?: Maybe<Scalars['Int']>;
  /** The name of the position */
  name: Scalars['String'];
  requirements: Array<EstablishmentRequirementInterface>;
  /** The number of total fte minus the current value. null getJobCount there is no requirement */
  vacantFtes?: Maybe<Scalars['Float']>;
  /** The number of total jobs minus the current value. null if there is no requirement */
  vacantJobs?: Maybe<Scalars['Int']>;
  /**
   * The number of total jobs or total fte minus the current value. null if there is no requirement
   * @deprecated separated into vacant jobs and ftes
   */
  vacantPositions?: Maybe<Scalars['Float']>;
};


export type EstablishmentestablishmentChildrenArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentestablishmentSupervisorArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentinvalidJobCountArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentjobCountArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentjobsArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentjobsOnLeaveCountArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentrequirementsArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentvacantFtesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type EstablishmentvacantJobsArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};

export type EstablishmentBusinessEntityRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentBusinessEntityRequirement';
  acceptableBusinessEntities: Array<BusinessEntity>;
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentBusinessUnitRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentBusinessUnitRequirement';
  acceptableBusinessUnits: Array<BusinessUnit>;
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentCloseInput = {
  availableTo: Scalars['DateTime'];
  /** If the jobs effective at the closing of the establishment should also have their end dates finalised. Defaults to true. */
  finaliseJobs?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type EstablishmentCloseResponse = {
  __typename?: 'EstablishmentCloseResponse';
  affectedJobs: Array<Job>;
  establishment: Establishment;
};

export type EstablishmentCreateInput = {
  availableFrom?: InputMaybe<Scalars['DateTime']>;
  availableTo?: InputMaybe<Scalars['DateTime']>;
  businessEntityRequirements?: InputMaybe<Array<Scalars['ID']>>;
  businessUnitRequirements?: InputMaybe<Array<Scalars['ID']>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  establishmentSupervisorId?: InputMaybe<Scalars['ID']>;
  locationRequirements?: InputMaybe<Array<Scalars['ID']>>;
  maxFte?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  payGradeRequirements?: InputMaybe<Array<Scalars['ID']>>;
  positionTitleRequirements?: InputMaybe<Array<Scalars['ID']>>;
  totalFte?: InputMaybe<Scalars['Float']>;
  totalJobs?: InputMaybe<Scalars['Int']>;
  workClassRequirements?: InputMaybe<Array<Scalars['ID']>>;
};

export type EstablishmentCreateResponse = {
  __typename?: 'EstablishmentCreateResponse';
  establishment: Establishment;
};

export type EstablishmentDeleteResponse = {
  __typename?: 'EstablishmentDeleteResponse';
  id: Scalars['ID'];
};

export type EstablishmentLocationRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentLocationRequirement';
  acceptableLocations: Array<Location>;
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentMaxFteRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentMaxFteRequirement';
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  maxFte: Scalars['Float'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentPayGradeRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentPayGradeRequirement';
  acceptablePayGrades: Array<PayGrade>;
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentPositionTitleRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentPositionTitleRequirement';
  acceptablePositionTitles: Array<PositionTitle>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentReopenInput = {
  /** an array of ids to cancel the end date of */
  cancelJobEndIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type EstablishmentReopenResponse = {
  __typename?: 'EstablishmentReopenResponse';
  affectedJobs: Array<Job>;
  establishment: Establishment;
};

export type EstablishmentRequirementInterface = {
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EstablishmentStartDateUpdateInput = {
  /** The ID of the Establishment to update */
  id: Scalars['ID'];
  /** The Establishment start date to update to */
  newStartDate: Scalars['DateTime'];
};

export type EstablishmentStartDateUpdateResponse = {
  __typename?: 'EstablishmentStartDateUpdateResponse';
  /** The Establishment that was updated */
  establishment: Establishment;
};

export type EstablishmentSupervisor = {
  __typename?: 'EstablishmentSupervisor';
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
  subordinateEstablishment: Establishment;
  supervisorEstablishment?: Maybe<Establishment>;
};

export type EstablishmentTotalFteRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentTotalFteRequirement';
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
  totalFte: Scalars['Float'];
};

export type EstablishmentTotalJobsRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentTotalJobsRequirement';
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
  totalJobs: Scalars['Int'];
};

export type EstablishmentUpdateInput = {
  businessEntityRequirements?: InputMaybe<Array<Scalars['ID']>>;
  businessUnitRequirements?: InputMaybe<Array<Scalars['ID']>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  establishmentSupervisorId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  locationRequirements?: InputMaybe<Array<Scalars['ID']>>;
  maxFte?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  payGradeRequirements?: InputMaybe<Array<Scalars['ID']>>;
  positionTitleRequirements?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  totalFte?: InputMaybe<Scalars['Float']>;
  totalJobs?: InputMaybe<Scalars['Int']>;
  workClassRequirements?: InputMaybe<Array<Scalars['ID']>>;
};

export type EstablishmentUpdateResponse = {
  __typename?: 'EstablishmentUpdateResponse';
  establishment: Establishment;
};

export type EstablishmentWorkClassRequirement = EstablishmentRequirementInterface & {
  __typename?: 'EstablishmentWorkClassRequirement';
  acceptableWorkClasses: Array<WorkClass>;
  acceptableWorkTypes: Array<WorkType>;
  /** @deprecated Establishments no longer support effective dates */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requirementType: RequirementType;
  /** @deprecated Establishments no longer support effective dates */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EventCalendarEvent = {
  __typename?: 'EventCalendarEvent';
  /** Day of event occurrence */
  day: Scalars['Int'];
  /** Display title of Calendar Event */
  displayName: Scalars['String'];
  /** Month of event occurrence */
  month: Scalars['Int'];
  /** People attending/associated with the event */
  people: Array<EventCalendarPerson>;
  /** Calendar Event Type */
  type: Scalars['String'];
  /** Year of event occurrence */
  year?: Maybe<Scalars['Int']>;
};

export type EventCalendarPerson = {
  __typename?: 'EventCalendarPerson';
  /** This returns e.g. the current anniversary year for the person */
  eventDescription?: Maybe<Scalars['String']>;
  /** The Person attending the EventCalendarEvent, as a set of skinny person info */
  skinnyPerson: PersonSkinny;
};

export type EventCalendarPersonFilters = {
  /** Filter events to specific EventCalendarTypes */
  eventType?: InputMaybe<Array<Scalars['String']>>;
  /** Filter People by hieratchy, Not yet implemented */
  hierarchyScope?: InputMaybe<EventCalendarPersonFiltersHierarchyScope>;
};

export enum EventCalendarPersonFiltersHierarchyScope {
  ALL = 'ALL',
  OWN = 'OWN',
  SUBORDINATE = 'SUBORDINATE'
}

export type EventCalendarType = {
  __typename?: 'EventCalendarType';
  /** Display title of Calendar Event */
  displayName: Scalars['String'];
  /** Calendar Event Type */
  type: Scalars['String'];
};

export type ExcludeJobsOnExtendedLeave = {
  __typename?: 'ExcludeJobsOnExtendedLeave';
  /** This field is always true, its only needed because Union types cant return Scalars. */
  alwaysTrue: Scalars['Boolean'];
};

export type ExcludeJobsOnProbation = {
  __typename?: 'ExcludeJobsOnProbation';
  /** This field is always true, its only needed because Union types cant return Scalars. */
  alwaysTrue: Scalars['Boolean'];
};

export type ExcludeRespondentsWithoutEnabledUsers = {
  __typename?: 'ExcludeRespondentsWithoutEnabledUsers';
  /** This field is always true, its only needed because Union types cant return Scalars. */
  alwaysTrue: Scalars['Boolean'];
};

export type ExcludeSubjects = {
  __typename?: 'ExcludeSubjects';
  /** The subject jobs to exclude in this (recurring) pulse. The job will be null if it has been deleted. */
  jobs: Array<Maybe<Job>>;
};

export type ExcludedPerson = {
  jobId?: InputMaybe<Scalars['ID']>;
  personId: Scalars['ID'];
};

export type ExpectedRespondentInput = {
  /** The job id of the expected respondent */
  jobId: Scalars['ID'];
  /** The person id of the expected respondent */
  personId: Scalars['ID'];
};

export type ExternalEventLog = {
  __typename?: 'ExternalEventLog';
  createdAt: Scalars['DateTime'];
  /** The webhook this event was fired to. */
  externalWebhook?: Maybe<ExternalWebhook>;
  /** If this webhook event has been resent due to a failure. */
  hasBeenReplayed: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The status of this webhook event. */
  previousLogId?: Maybe<Scalars['ID']>;
  /** The request of this event log. */
  request?: Maybe<Scalars['JSON']>;
  /** The response of this event log. */
  response?: Maybe<Scalars['JSON']>;
  /** The status of this webhook event. */
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The event this webhook was fired for. */
  webhookEvent?: Maybe<WebhookEvent>;
};

export type ExternalWebhook = {
  __typename?: 'ExternalWebhook';
  createdAt: Scalars['DateTime'];
  /** The external event logs for this particular event. */
  externalEventLogs?: Maybe<Array<Maybe<ExternalEventLog>>>;
  id: Scalars['ID'];
  /** Specifies whether users can select this Webhook. When disabled, this Webhook will not be sent. */
  isEnabled: Scalars['Boolean'];
  /** The saved settings for this external webhook */
  settings?: Maybe<Scalars['JSON']>;
  /** A customizable string which can be used to identify what system created this. */
  source: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The Webhook endpoint which the request will be sent to when the subscribed Webhook Event is triggered. */
  url: Scalars['String'];
  /** The event that triggered this webhook. */
  webhookEvent?: Maybe<WebhookEvent>;
};

export type ExternalWebhookCreateInput = {
  /** Specifies whether users can select this Webhook. When disabled, this Webhook will not be sent. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The saved settings for this external webhook */
  settings?: InputMaybe<Scalars['JSON']>;
  /** A customizable string which can be used to identify what system created this. */
  source: Scalars['String'];
  /** The Webhook endpoint which the request will be sent to when the subscribed Webhook Event is triggered. */
  url: Scalars['String'];
  /** The ID of the event that triggered this webhook. */
  webhookEventId: Scalars['ID'];
};

export type ExternalWebhookCreateResponse = {
  __typename?: 'ExternalWebhookCreateResponse';
  /** The external webhook that has been created. */
  externalWebhook: ExternalWebhook;
};

export type ExternalWebhookDeleteResponse = {
  __typename?: 'ExternalWebhookDeleteResponse';
  /** The ID of the external webhook that was deleted. */
  id: Scalars['ID'];
};

export type ExternalWebhookUpdateInput = {
  /** The ID of the external webhook to update */
  id: Scalars['ID'];
  /** Specifies whether users can select this Webhook. When disabled, this Webhook will not be sent. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The saved settings for this external webhook */
  settings?: InputMaybe<Scalars['JSON']>;
  /** A customizable string which can be used to identify what system created this. */
  source?: InputMaybe<Scalars['String']>;
  /** The Webhook endpoint which the request will be sent to when the subscribed Webhook Event is triggered. */
  url?: InputMaybe<Scalars['String']>;
  /** The ID of the event that triggered this webhook. */
  webhookEventId?: InputMaybe<Scalars['ID']>;
};

export type ExternalWebhookUpdateResponse = {
  __typename?: 'ExternalWebhookUpdateResponse';
  /** The external webhook that has been updated. */
  externalWebhook: ExternalWebhook;
};

export type FetchLatestJobsForCompensationPlanResponse = {
  __typename?: 'FetchLatestJobsForCompensationPlanResponse';
  compensationPlan: CompensationPlan;
};

export type Filter = {
  booleanFilter?: InputMaybe<Scalars['Boolean']>;
  customFieldOptions?: InputMaybe<PulseSingleSelectCustomFieldsInput>;
  filters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<FilterType>;
};

export enum FilterType {
  excludeJobIds = 'excludeJobIds',
  excludePeopleOnExtendedLeave = 'excludePeopleOnExtendedLeave',
  excludePeopleWithinProbationPeriod = 'excludePeopleWithinProbationPeriod',
  excludeRespondentsWithoutEnabledUsers = 'excludeRespondentsWithoutEnabledUsers',
  includeBusinessEntityIds = 'includeBusinessEntityIds',
  includeBusinessUnitIds = 'includeBusinessUnitIds',
  includeJobIds = 'includeJobIds',
  includeLocationIds = 'includeLocationIds',
  includePayGradeIds = 'includePayGradeIds',
  includeWorkClassIds = 'includeWorkClassIds',
  singleSelectCustomFieldsOnPeople = 'singleSelectCustomFieldsOnPeople'
}

export type FlattenedPeopleJobSearchResult = {
  __typename?: 'FlattenedPeopleJobSearchResult';
  /** the position title as at now for this job */
  currentPositionTitle?: Maybe<Scalars['String']>;
  /** The formatted display name of a person as configured by the system */
  displayName?: Maybe<Scalars['String']>;
  /** The employee number for the person */
  employeeNumber?: Maybe<Scalars['String']>;
  /** the employment status of the persons jobs */
  employmentStatusId?: Maybe<PersonEmploymentStatusId>;
  /** if the job has any pending leave */
  hasPendingLeave?: Maybe<Scalars['Boolean']>;
  /** the calculated initials for this record */
  initials?: Maybe<Scalars['String']>;
  /** if the person was preloaded or included in the search result */
  isPreloaded?: Maybe<Scalars['Boolean']>;
  /** the job include for this record */
  job?: Maybe<Job>;
  /** the id for the resulting job */
  jobId: Scalars['ID'];
  /** the status id for this job */
  jobStatusId?: Maybe<JobStatusId>;
  /** the name of the current job status */
  jobStatusName?: Maybe<Scalars['String']>;
  /** The last name for this person */
  lastName?: Maybe<Scalars['String']>;
  /** the id for the current pending leave if there is any */
  pendingLeaveId?: Maybe<Scalars['ID']>;
  /** The person include for this record */
  person?: Maybe<Person>;
  /** the person id for the search result */
  personId: Scalars['ID'];
  /** The preferred name if it exists otherwise the first name */
  preferredOrFirstName?: Maybe<Scalars['String']>;
  /** The id that references a person's cover picture */
  profileCoverPictureId?: Maybe<Scalars['ID']>;
  /** The id that references a person's profile picture */
  profilePictureId?: Maybe<Scalars['ID']>;
};

export type Form = {
  __typename?: 'Form';
  /** A unique code used to gain access to the form */
  accessCode: Scalars['String'];
  /** The date the form was completed */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The date the form was deleted at (if any) */
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentUploads?: Maybe<Array<Maybe<FormDocumentUpload>>>;
  /** The date the form is due */
  dueDate?: Maybe<Scalars['DateTime']>;
  /**
   * The Job that is expected to fill out this form
   * @deprecated use respondent instead
   */
  expectedRespondent?: Maybe<Job>;
  /** The Fields on this Form, also includes answers if the Form has been filled out */
  fields: Array<FormField>;
  /** The form design this form was based on */
  formDesign: FormDesign;
  id: Scalars['ID'];
  /** The date the form was ignored */
  ignoredAt?: Maybe<Scalars['DateTime']>;
  /** The date the form will be or was issued */
  issueDate: Scalars['DateTime'];
  /** The most recent Form Issued Email Notification related to this Form */
  latestFormIssuedNotification?: Maybe<MessageSchedule>;
  /** The date a reminder notification will be sent to the respondent if it has not yet been completed */
  reminderDate?: Maybe<Scalars['DateTime']>;
  /** The Job that is expected to fill out this Form */
  respondent?: Maybe<Job>;
  /** The Person that is expected to fill out this Form */
  respondentPerson?: Maybe<Person>;
  scheduledForm?: Maybe<ScheduledForm>;
  /** The current status of the form */
  status: FormStatus;
  /** The Job that the form is about */
  subject: Job;
  /** The person who submitted the form */
  submitterPerson?: Maybe<Person>;
  workflowInstance?: Maybe<WorkflowInstance>;
};

export type FormCompletedRecipient = {
  __typename?: 'FormCompletedRecipient';
  id: Scalars['ID'];
  /** The human-readable name of the form completed recipient */
  name: Scalars['String'];
};

export type FormCompletedRecipientsFormDesign = {
  __typename?: 'FormCompletedRecipientsFormDesign';
  formCompletedRecipientId: Scalars['ID'];
  id: Scalars['ID'];
  people: Array<Person>;
};

export type FormCompletionRecipientQuery = {
  __typename?: 'FormCompletionRecipientQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  calculate?: Maybe<Array<Maybe<FormCompletionRecipientsCalculateResponse>>>;
};


export type FormCompletionRecipientQuerycalculateArgs = {
  input: FormCompletionRecipientsCalculateInput;
};

export type FormCompletionRecipientsCalculateInput = {
  formDesignId: Scalars['ID'];
  subjectJobId: Scalars['ID'];
  subjectPersonId: Scalars['ID'];
};

export type FormCompletionRecipientsCalculateResponse = {
  __typename?: 'FormCompletionRecipientsCalculateResponse';
  email?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['String']>;
};

export type FormComplianceFormDesign = {
  __typename?: 'FormComplianceFormDesign';
  defaultNotificationMethodId: Scalars['ID'];
  formRespondentEmailAddresses?: Maybe<Scalars['String']>;
  formRespondentTypeId: Scalars['ID'];
  formRespondentTypeName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FormComplianceFormInstance = {
  __typename?: 'FormComplianceFormInstance';
  accessCode?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  formDesign: FormComplianceFormDesign;
  hasBeenCompleted?: Maybe<Scalars['Boolean']>;
  hasNotificationBeenResent: Scalars['Boolean'];
  id: Scalars['ID'];
  ignoredAt?: Maybe<Scalars['DateTime']>;
  isIgnored?: Maybe<Scalars['Boolean']>;
  issueDate: Scalars['DateTime'];
  latestDraft?: Maybe<Scalars['DateTime']>;
  latestMessageSchedule?: Maybe<FormComplianceMessageSchedule>;
  /** @deprecated Use latestMessageSchedule.id instead */
  messageScheduleId?: Maybe<Scalars['ID']>;
  respondentEmailAddress?: Maybe<EmailAddress>;
  respondentJob?: Maybe<Job>;
  /** @deprecated This can be accessed via the respondent job */
  respondentPerson?: Maybe<Person>;
  /** @deprecated Use latestMessageSchedule.sentTo instead */
  sentTo?: Maybe<Scalars['String']>;
  subjectJob: Job;
  submitterPerson?: Maybe<Person>;
};

export type FormComplianceMessageSchedule = {
  __typename?: 'FormComplianceMessageSchedule';
  /** Message schedule id */
  id: Scalars['ID'];
  /** Form issued notification sentTo */
  notificationSentTo?: Maybe<Scalars['String']>;
  /** Time sent */
  sentAt?: Maybe<Scalars['DateTime']>;
};

export type FormComplianceQuery = {
  __typename?: 'FormComplianceQuery';
  /** query to get a list of form instances for the form compliance page */
  listPaginatedFormComplianceForStatus?: Maybe<FormComplianceResponse>;
};


export type FormComplianceQuerylistPaginatedFormComplianceForStatusArgs = {
  formComplianceStatus: FormComplianceStatus;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<FormComplianceSortDirection>>;
};

export type FormComplianceResponse = {
  __typename?: 'FormComplianceResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  formComplianceStatus: FormComplianceStatus;
  formInstances: Array<FormComplianceFormInstance>;
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  sort?: Maybe<Array<FormComplianceSortDirection>>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type FormComplianceScheduledForm = {
  __typename?: 'FormComplianceScheduledForm';
  expectedNotificationEmailAddress?: Maybe<Scalars['String']>;
  expectedNotificationEmailAddressSource?: Maybe<Scalars['String']>;
  expectedRespondentJob?: Maybe<Job>;
  expectedRespondentJobSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  scheduledForm?: Maybe<ScheduledForm>;
};

export enum FormComplianceSortDirection {
  COMPLETED_AT_ASCENDING = 'COMPLETED_AT_ASCENDING',
  COMPLETED_AT_DESCENDING = 'COMPLETED_AT_DESCENDING',
  DUE_DATE_ASCENDING = 'DUE_DATE_ASCENDING',
  DUE_DATE_DESCENDING = 'DUE_DATE_DESCENDING',
  FORM_DESIGN_NAME_ASCENDING = 'FORM_DESIGN_NAME_ASCENDING',
  FORM_DESIGN_NAME_DESCENDING = 'FORM_DESIGN_NAME_DESCENDING',
  IGNORED_AT_ASCENDING = 'IGNORED_AT_ASCENDING',
  IGNORED_AT_DESCENDING = 'IGNORED_AT_DESCENDING',
  ISSUE_DATE_ASCENDING = 'ISSUE_DATE_ASCENDING',
  ISSUE_DATE_DESCENDING = 'ISSUE_DATE_DESCENDING',
  RESPONDENT_ASCENDING = 'RESPONDENT_ASCENDING',
  RESPONDENT_DESCENDING = 'RESPONDENT_DESCENDING',
  SUBJECT_ASCENDING = 'SUBJECT_ASCENDING',
  SUBJECT_DESCENDING = 'SUBJECT_DESCENDING',
  SUBMITTER_ASCENDING = 'SUBMITTER_ASCENDING',
  SUBMITTER_DESCENDING = 'SUBMITTER_DESCENDING'
}

export enum FormComplianceStatus {
  COMPLETED = 'COMPLETED',
  IGNORED = 'IGNORED',
  ISSUED = 'ISSUED',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING'
}

export type FormDataAnalyticsQuery = {
  __typename?: 'FormDataAnalyticsQuery';
  /**
   * Query raw text response data and information for
   * a specific form question
   */
  getTextFieldData?: Maybe<FormFieldTextData>;
};


export type FormDataAnalyticsQuerygetTextFieldDataArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  fieldId: Scalars['UUID'];
  formDesignId: Scalars['UUID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type FormDesign = {
  __typename?: 'FormDesign';
  /** Specifies the number of business days after the issue of the form, that it will be ignored automatically */
  autoIgnorePeriod?: Maybe<Scalars['Int']>;
  /** The people for the 'people' form recipient */
  completionRecipients?: Maybe<Array<FormCompletedRecipientsFormDesign>>;
  /** When the form design was created */
  createdAt: Scalars['DateTime'];
  /** The notification method to default to */
  defaultNotificationMethod: NotificationMethod;
  /** A short summary of what the form is about */
  description?: Maybe<Scalars['String']>;
  /**
   * Specifies the number of business days before the form is due to be completed.
   * The form will become overdue at 11:59pm on the final business day. A zero
   * specifies that the form will become overdue at 11:59pm on the issue date.
   */
  duePeriod?: Maybe<Scalars['Int']>;
  /**
   * Specifies the number of business days after the issue of the form, that an
   * escalation notification is sent to the respondent's manager advising the
   * respondent has not actioned the Form. The notification will be sent at the
   * same time of the day the the form was pulsed out to the respondent.
   */
  escalationPeriod?: Maybe<Scalars['Int']>;
  /** The recipients of the completed form */
  formCompletedRecipients?: Maybe<Array<FormCompletedRecipient>>;
  /** The category of the form design */
  formDesignCategory?: Maybe<FormDesignCategory>;
  /** The form field values as an array */
  formFields: Array<FormFieldValueInterface>;
  /** The form respondent job that the form design belongs to */
  formRespondentJobs: Array<Job>;
  /** The form respondent type that the form design belongs to */
  formRespondentType: FormRespondentType;
  /** The form respondent type that the form design belongs to represented as an enum */
  formRespondentTypeEnum: FormRespondentTypeEnum;
  /** The form type that the form design belongs to */
  formType: FormType;
  id: Scalars['ID'];
  /** Whether the form design is archived or not */
  isArchived: Scalars['Boolean'];
  /** The human-readable name of the form design */
  name: Scalars['String'];
  /** Performance Frameworks where the Form Design is used */
  performanceFrameworks?: Maybe<Array<PerformanceFramework>>;
  /** Performance Metrics where the Form Design is used */
  performanceMetrics?: Maybe<Array<PerformanceMetric>>;
  /**
   * Specifies the number of business days after the issue date of the form that a
   * reminder can be sent to the respondent. The reminder will be sent at the same
   * time of day that the form was pulsed out to the respondent.
   */
  reminderPeriod?: Maybe<Scalars['Int']>;
  /** Require a user to log in before being allowed to complete for form */
  requiresLogin: Scalars['Boolean'];
  /** Email Address Respondents list */
  respondentEmailAddresses: Array<Scalars['String']>;
  /** Show the compted form instance that triggered the form instance of the form design */
  showTriggeredBy: Scalars['Boolean'];
  /** The trigger on complete form design that the form design belongs to */
  triggerOnCompleteFormDesign?: Maybe<FormDesign>;
  /** When the form design was last updated */
  updatedAt: Scalars['DateTime'];
};

export type FormDesignCategory = {
  __typename?: 'FormDesignCategory';
  /** The form designs under the category */
  formDesigns: Array<FormDesign>;
  id: Scalars['ID'];
  /** Whether the form design category is enabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the form design category */
  name: Scalars['String'];
  /** The sorting order of the form design category */
  sortOrder: Scalars['Int'];
};

export type FormDesignCategoryFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type FormDesignDeferred = {
  __typename?: 'FormDesignDeferred';
  formDesign?: Maybe<FormDesign>;
};

export type FormDesignDeferrerdUnion = AnonymousFormDesignDeferred | FormDesignDeferred;

export type FormDesignFieldResponseType = {
  __typename?: 'FormDesignFieldResponseType';
  formDesignId: Scalars['ID'];
  formFieldId: Scalars['ID'];
  type: Scalars['String'];
};

export type FormDesignFilters = {
  respondentJobs?: InputMaybe<ValueFilter>;
};

export type FormDesignInfo = {
  __typename?: 'FormDesignInfo';
  candidateVersions: Array<Scalars['JSON']>;
  formDesign: Scalars['JSON'];
};

export type FormDesignable = AnonymousFormDesign | FormDesign;

export type FormDocumentUpload = {
  __typename?: 'FormDocumentUpload';
  id?: Maybe<Scalars['ID']>;
  /**
   * An API url to download the attachment
   * Requires hierarchical permission to download - people.documents.view
   * Please be advised this url will only work on the environment that the form was completed on
   */
  url?: Maybe<Scalars['String']>;
};

export type FormField = {
  __typename?: 'FormField';
  /**
   * The Answer to this Field, this field will be null if the field is unanswered
   * or if it isn't possible to be answered (e.g. a HTML field)
   */
  answer?: Maybe<FormFieldAnswer>;
  /** The Field itself */
  field: FormFieldValueInterface;
};

export type FormFieldAnswer = {
  __typename?: 'FormFieldAnswer';
  /** The Answer label for this field */
  label: Array<Scalars['String']>;
  /** The Answer value for this field */
  value: Array<Scalars['String']>;
};

export enum FormFieldStatusEnum {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN'
}

export type FormFieldTextData = {
  __typename?: 'FormFieldTextData';
  texts: Array<FormFieldTextDataEntry>;
};

export type FormFieldTextDataEntry = {
  __typename?: 'FormFieldTextDataEntry';
  id: Scalars['UUID'];
  insight?: Maybe<Scalars['JSON']>;
};

export type FormFieldType = {
  __typename?: 'FormFieldType';
  /** The date when the form field type is created */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether this Type of Form Field is able to be answered */
  isAnswerable: Scalars['Boolean'];
  /** Whether the form field type is enabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the form field type */
  name: Scalars['String'];
  /** The slug of the form field type */
  slug: Scalars['String'];
};

export type FormFieldValueCheckboxGroup = FormFieldValueInterface & {
  __typename?: 'FormFieldValueCheckboxGroup';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueDate = FormFieldValueInterface & {
  __typename?: 'FormFieldValueDate';
  allowedDays?: Maybe<Scalars['JSON']>;
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxDate?: Maybe<Scalars['String']>;
  maxOffsetDays?: Maybe<Scalars['String']>;
  minDate?: Maybe<Scalars['String']>;
  minOffsetDays?: Maybe<Scalars['String']>;
  offsetDefault?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueEmail = FormFieldValueInterface & {
  __typename?: 'FormFieldValueEmail';
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueHeading = FormFieldValueInterface & {
  __typename?: 'FormFieldValueHeading';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  label: Scalars['String'];
  size: HeadingSizeEnum;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueHtmlContent = FormFieldValueInterface & {
  __typename?: 'FormFieldValueHtmlContent';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  htmlContent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  noLabel: Scalars['Boolean'];
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueInterface = {
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  label: Scalars['String'];
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueNumber = FormFieldValueInterface & {
  __typename?: 'FormFieldValueNumber';
  allowDecimals: Scalars['Boolean'];
  allowNegative: Scalars['Boolean'];
  default?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
  step?: Maybe<Scalars['String']>;
};

/** Just in case the form design is using deprecated form field types */
export type FormFieldValueOthers = FormFieldValueInterface & {
  __typename?: 'FormFieldValueOthers';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  label: Scalars['String'];
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValuePerformanceReportLink = FormFieldValueInterface & {
  __typename?: 'FormFieldValuePerformanceReportLink';
  endDateDaysOffset?: Maybe<Scalars['String']>;
  endDateMonthsOffset?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isFixedIssueDate?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  slug: Scalars['String'];
  startDateMonthsOffset?: Maybe<Scalars['String']>;
  status: FormFieldStatusEnum;
  view: PerformanceReportLinkViewEnum;
};

export type FormFieldValueRadioGroup = FormFieldValueInterface & {
  __typename?: 'FormFieldValueRadioGroup';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueRating = FormFieldValueInterface & {
  __typename?: 'FormFieldValueRating';
  /** @deprecated Data type changed. Use endInt instead. */
  end: Scalars['String'];
  endInt: Scalars['Int'];
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  labelsLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelsValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug: Scalars['String'];
  /** @deprecated Data type changed. Use startInt instead. */
  start: Scalars['String'];
  startInt: Scalars['Int'];
  status: FormFieldStatusEnum;
  /** @deprecated Data type changed. Use stepInt instead. */
  step?: Maybe<Scalars['String']>;
  stepInt?: Maybe<Scalars['Int']>;
};

export type FormFieldValueSelect = FormFieldValueInterface & {
  __typename?: 'FormFieldValueSelect';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueText = FormFieldValueInterface & {
  __typename?: 'FormFieldValueText';
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxLengthChar?: Maybe<Scalars['String']>;
  maxLengthWords?: Maybe<Scalars['String']>;
  minLengthChar?: Maybe<Scalars['String']>;
  minLengthWords?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueUpload = FormFieldValueInterface & {
  __typename?: 'FormFieldValueUpload';
  destination: Scalars['String'];
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueUrl = FormFieldValueInterface & {
  __typename?: 'FormFieldValueUrl';
  default?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormFieldValueYesNo = FormFieldValueInterface & {
  __typename?: 'FormFieldValueYesNo';
  formFieldType: FormFieldType;
  formLibraryItem?: Maybe<FormLibraryItem>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  labelForNo: Scalars['String'];
  labelForYes: Scalars['String'];
  rowOrList: RowOrListEnum;
  slug: Scalars['String'];
  status: FormFieldStatusEnum;
};

export type FormIgnoreReason = {
  __typename?: 'FormIgnoreReason';
  id: Scalars['ID'];
  /** The human-readable name of the form ignore reason */
  name: Scalars['String'];
};

export type FormInstanceAnswer = {
  __typename?: 'FormInstanceAnswer';
  id: Scalars['ID'];
  submitter: Person;
};

export type FormLibraryFieldResponseType = {
  __typename?: 'FormLibraryFieldResponseType';
  formItemId: Scalars['ID'];
  type: Scalars['String'];
};

export type FormLibraryItem = {
  __typename?: 'FormLibraryItem';
  /** The date when the form library item is created */
  createdAt: Scalars['DateTime'];
  /** The item value as a union */
  definition: FormLibraryItemDefinitionInterface;
  id: Scalars['ID'];
  /** Whether the form library item is enabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the form library item */
  name: Scalars['String'];
};

export type FormLibraryItemDefinitionCheckboxGroup = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionCheckboxGroup';
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionDate = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionDate';
  allowedDays?: Maybe<Scalars['JSON']>;
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxDate?: Maybe<Scalars['String']>;
  maxOffsetDays?: Maybe<Scalars['String']>;
  minDate?: Maybe<Scalars['String']>;
  minOffsetDays?: Maybe<Scalars['String']>;
  offsetDefault?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionEmail = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionEmail';
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionHeading = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionHeading';
  formFieldType: FormFieldType;
  label: Scalars['String'];
  size: HeadingSizeEnum;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionHtmlContent = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionHtmlContent';
  formFieldType: FormFieldType;
  htmlContent?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  noLabel: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionInterface = {
  formFieldType: FormFieldType;
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionNumber = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionNumber';
  allowDecimals: Scalars['Boolean'];
  allowNegative: Scalars['Boolean'];
  default?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  step?: Maybe<Scalars['String']>;
};

/** Just in case the form design is using deprecated form field types */
export type FormLibraryItemDefinitionOthers = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionOthers';
  formFieldType: FormFieldType;
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionPerformanceReportLink = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionPerformanceReportLink';
  endDateDaysOffset?: Maybe<Scalars['String']>;
  endDateMonthsOffset?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isFixedIssueDate?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  slug: Scalars['String'];
  startDateMonthsOffset?: Maybe<Scalars['String']>;
  view: PerformanceReportLinkViewEnum;
};

export type FormLibraryItemDefinitionRadioGroup = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionRadioGroup';
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionRating = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionRating';
  /** @deprecated Data type changed. Use endInt instead. */
  end: Scalars['String'];
  endInt: Scalars['Int'];
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  labelsLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelsValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug: Scalars['String'];
  /** @deprecated Data type changed. Use startInt instead. */
  start: Scalars['String'];
  startInt: Scalars['Int'];
  /** @deprecated Data type changed. Use stepInt instead. */
  step?: Maybe<Scalars['String']>;
  stepInt?: Maybe<Scalars['Int']>;
};

export type FormLibraryItemDefinitionSelect = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionSelect';
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options: Array<OptionValue>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionText = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionText';
  defaultValue?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxLengthChar?: Maybe<Scalars['String']>;
  maxLengthWords?: Maybe<Scalars['String']>;
  minLengthChar?: Maybe<Scalars['String']>;
  minLengthWords?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionUpload = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionUpload';
  destination: Scalars['String'];
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionUrl = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionUrl';
  default?: Maybe<Scalars['String']>;
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type FormLibraryItemDefinitionYesNo = FormLibraryItemDefinitionInterface & {
  __typename?: 'FormLibraryItemDefinitionYesNo';
  formFieldType: FormFieldType;
  isRequired?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  labelForNo: Scalars['String'];
  labelForYes: Scalars['String'];
  rowOrList: RowOrListEnum;
  slug: Scalars['String'];
};

export type FormPreview = {
  __typename?: 'FormPreview';
  key: Scalars['ID'];
  notificationMethodStatus?: Maybe<NotificationMethodStatus>;
  notificationMethodStatusMessage?: Maybe<Scalars['String']>;
  respondent?: Maybe<FormRespondentUnion>;
  subjectJob: Job;
};

export type FormRespondentType = {
  __typename?: 'FormRespondentType';
  id: Scalars['ID'];
  /** The human-readable name of the form respondent type */
  name: Scalars['String'];
};

export enum FormRespondentTypeEnum {
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  EMPLOYEE = 'EMPLOYEE',
  JOB = 'JOB',
  SUPERVISOR = 'SUPERVISOR'
}

export type FormRespondentUnion = RespondentEmail | RespondentJob;

export enum FormStatus {
  COMPLETED = 'COMPLETED',
  IGNORED = 'IGNORED',
  ISSUED = 'ISSUED',
  OVERDUE = 'OVERDUE',
  UNKNOWN = 'UNKNOWN',
  UPCOMING = 'UPCOMING'
}

export type FormType = {
  __typename?: 'FormType';
  id: Scalars['ID'];
  /** The human-readable name of the form type */
  name: Scalars['String'];
};

export type Gender = {
  __typename?: 'Gender';
  id: Scalars['ID'];
  /** If the gender is enabled or not */
  isEnabled: Scalars['Boolean'];
  /** The name of the gender */
  name: Scalars['String'];
  /** The sort order of the gender */
  sortOrder: Scalars['Int'];
};

export type GenderFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type GeneratePersonPictureUploadUrlInput = {
  /** The extension type of the file being uploaded */
  extension: Scalars['String'];
  /** The type of image that is being uploaded */
  imageType: PersonProfileImageTypes;
  /** The id of the person to whom the picture belongs to */
  personId: Scalars['ID'];
};

export type GeneratePersonPictureUploadUrlResponse = {
  __typename?: 'GeneratePersonPictureUploadUrlResponse';
  fields: Scalars['JSON'];
  url: Scalars['String'];
};

export type GetContractTemplatePopulatedInput = {
  contractTemplateId: Scalars['ID'];
  effectiveFrom: Scalars['DateTime'];
  subjectJobId: Scalars['ID'];
};

export type GetContractTemplatePopulatedInputV2 = {
  contractTemplateId: Scalars['ID'];
  effectiveFrom: Scalars['DateTime'];
  overrideValues?: InputMaybe<Scalars['JSON']>;
  subjectJobId: Scalars['ID'];
};

export type GetCreateJobDetailsFromSearchObjectsInput = {
  asAt?: InputMaybe<Scalars['DateTime']>;
  businessEntitySearchObject: BusinessEntitySearchObjectInput;
  businessUnitSearchObject: BusinessUnitSearchObjectInput;
  defaultAdditionToBaseSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  defaultAdditionToTotalSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  defaultBreakdownSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  employmentConditionSearchObject?: InputMaybe<employmentConditionSearchObject>;
  locationSearchObject?: InputMaybe<locationSearchObjectInput>;
  payCycleSearchObject?: InputMaybe<payCycleSearchObjectInput>;
  payGradeSearchObject?: InputMaybe<PayGradeSearchObjectInput>;
  personSearchObject: PersonSearchObjectInput;
  recruitmentSourceSearchObject?: InputMaybe<recruitmentSourceSearchObject>;
  supervisorJobSearchObject?: InputMaybe<JobSearchObjectInput>;
  supervisorPersonSearchObject?: InputMaybe<PersonSearchObjectInput>;
  workClassSearchObject: workClassSearchObjectInput;
  workTypeSearchObject?: InputMaybe<workTypeSearchObjectInput>;
};

export type GetCreateJobDetailsFromSearchObjectsResponse = {
  __typename?: 'GetCreateJobDetailsFromSearchObjectsResponse';
  businessEntityId: Scalars['ID'];
  businessUnitId: Scalars['ID'];
  defaultAdditionToBaseIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  defaultAdditionToTotalIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  defaultBreakdownIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  employmentConditionId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  payCycleId?: Maybe<Scalars['ID']>;
  payGradeId?: Maybe<Scalars['ID']>;
  personId: Scalars['ID'];
  recruitmentSourceId?: Maybe<Scalars['ID']>;
  supervisorJobId?: Maybe<Scalars['ID']>;
  workClassId: Scalars['ID'];
};

export type GetCreatePersonDetailsInput = {
  gender: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type GetCreatePersonDetailsResponse = {
  __typename?: 'GetCreatePersonDetailsResponse';
  genderId: Scalars['ID'];
  titleId?: Maybe<Scalars['ID']>;
};

export type GetEndJobDetailsFromSearchObjectsInput = {
  turnoverReasonSearchObject?: InputMaybe<TurnoverReasonSearchObject>;
};

export type GetEndJobDetailsFromSearchObjectsResponse = {
  __typename?: 'GetEndJobDetailsFromSearchObjectsResponse';
  turnoverReasonId?: Maybe<Scalars['ID']>;
};

export type GetLeaveDetailsInput = {
  leaveTypeSearchObject?: InputMaybe<LeaveTypeSearchObjectInput>;
};

export type GetLeaveDetailsResponse = {
  __typename?: 'GetLeaveDetailsResponse';
  leaveTypeId?: Maybe<Scalars['ID']>;
};

/** Which category this integration belongs to */
export enum GetLinkTokenArgsCategories {
  ACCOUNTING = 'ACCOUNTING',
  ATS = 'ATS',
  HRIS = 'HRIS'
}

export type GetMyStatsForDashboardResponse = {
  __typename?: 'GetMyStatsForDashboardResponse';
  completedFormsCountInPastQuarter: Scalars['Int'];
  /** @deprecated Use completedFormsCountInPastQuarter instead */
  completedTasksCountInPastQuarter: Scalars['Int'];
  outstandingFormsCount: MyOutStandingFormsCount;
  /** @deprecated Use outstandingFormsCount instead */
  outstandingTasksCount: MyOutStandingTasksCount;
  overdueFormsCount: Scalars['Int'];
  /** @deprecated Use overdueFormsCount instead */
  overdueTasksCount: Scalars['Int'];
};

export type GetMyTeamStatsForDashboardResponse = {
  __typename?: 'GetMyTeamStatsForDashboardResponse';
  completedFormsAvgDaysPerCount: MyTeamCompletedFormsAvgDaysPerCount;
  /** @deprecated Use completedFormsAvgDaysPerCount instead */
  completedTasksAvgDaysPerCount: MyTeamCompletedTasksAvgDaysPerCount;
  outstandingFormsCount: Scalars['Int'];
  /** @deprecated Use outstandingFormsCount instead */
  outstandingTasksCount: Scalars['Int'];
  overdueFormsCount: Scalars['Int'];
  /** @deprecated Use overdueFormsCount instead */
  overdueTasksCount: Scalars['Int'];
};

export type GetPaginatedHiddenOnboardingSessionsResponse = PaginationResponse & {
  __typename?: 'GetPaginatedHiddenOnboardingSessionsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  hiddenOnboardingSessions: Array<OnboardingExclusion>;
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type GetPersonDetailsInput = {
  gender?: InputMaybe<Scalars['String']>;
  peopleDropdownSearchObjects?: InputMaybe<Array<InputMaybe<PeopleDropdownSearchObjectArrayInput>>>;
  title?: InputMaybe<Scalars['String']>;
  workRightsSearchObject?: InputMaybe<workRightsSearchObjectInput>;
};

export type GetPersonDetailsResponse = {
  __typename?: 'GetPersonDetailsResponse';
  genderId?: Maybe<Scalars['ID']>;
  peopleDropdownValues?: Maybe<Array<Maybe<PeopleDropdownValues>>>;
  titleId?: Maybe<Scalars['ID']>;
  workRightId?: Maybe<Scalars['ID']>;
};

export type GetPersonPictureTemporaryUploadUrlInput = {
  /** The extension type of the file being uploaded */
  extension: Scalars['String'];
  /** The type of image that is being uploaded */
  imageType: PersonProfileImageTypes;
  /** The id of the person to whom the picture belongs to */
  personId: Scalars['ID'];
};

export type GetPersonPictureTemporaryUploadUrlResponse = {
  __typename?: 'GetPersonPictureTemporaryUploadUrlResponse';
  url: Scalars['String'];
};

export type GetUpdateJobDetailsFromSearchObjectsInput = {
  asAt?: InputMaybe<Scalars['DateTime']>;
  businessEntitySearchObject?: InputMaybe<BusinessEntitySearchObjectInput>;
  businessUnitSearchObject?: InputMaybe<BusinessUnitSearchObjectInput>;
  defaultAdditionToBaseSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  defaultAdditionToTotalSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  defaultBreakdownSearchObjects?: InputMaybe<Array<InputMaybe<defaultRemunerationComponentSearchObjectInput>>>;
  employmentConditionSearchObject?: InputMaybe<employmentConditionSearchObject>;
  locationSearchObject?: InputMaybe<locationSearchObjectInput>;
  payCycleSearchObject?: InputMaybe<payCycleSearchObjectInput>;
  payGradeSearchObject?: InputMaybe<PayGradeSearchObjectInput>;
  supervisorJobSearchObject?: InputMaybe<JobSearchObjectInput>;
  supervisorPersonSearchObject?: InputMaybe<PersonSearchObjectInput>;
  workClassSearchObject?: InputMaybe<workClassSearchObjectInput>;
  workTypeSearchObject?: InputMaybe<workTypeSearchObjectInput>;
};

export type GetUpdateJobDetailsFromSearchObjectsResponse = {
  __typename?: 'GetUpdateJobDetailsFromSearchObjectsResponse';
  businessEntityId?: Maybe<Scalars['ID']>;
  businessUnitId?: Maybe<Scalars['ID']>;
  defaultAdditionToBaseIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  defaultAdditionToTotalIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  defaultBreakdownIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  employmentConditionId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  payCycleId?: Maybe<Scalars['ID']>;
  payGradeId?: Maybe<Scalars['ID']>;
  supervisorJobId?: Maybe<Scalars['ID']>;
  workClassId?: Maybe<Scalars['ID']>;
};

export type GlobalNotificationConfiguration = {
  __typename?: 'GlobalNotificationConfiguration';
  /** A list of filters for whether an affected person should be notified */
  affectedPersonFilters: GlobalNotificationConfigurationAffectedPersonFilters;
  /** The event types for this global notification configuration */
  eventTypes: Array<NotificationEventType>;
  id: Scalars['ID'];
  /** The email template to be used as the subject when generating the immediate email notification. */
  immediateEmailSubjectTemplate: Template;
  /** The email template to be used when generating the immediate email notification. */
  immediateEmailTemplate?: Maybe<Template>;
  /**
   * The list of details to include in the global notification. Included details
   * will be shown in the immediate email that gets sent, as well as in some
   * subtitles.
   */
  includeDetails: GlobalNotificationConfigurationIncludeDetails;
  /** Whether the configuration is enabled. */
  isEnabled: Scalars['Boolean'];
  /** The name of the global notification */
  name?: Maybe<Scalars['String']>;
  /** Whether to notify for bulk data imports. Defaults to false. */
  sendForBulkDataImports: Scalars['Boolean'];
  /** Whether to notify the person who triggered the notification. Defaults to false. */
  sendToActioningPerson: Scalars['Boolean'];
  /**
   * Whether the configuration will send an immediate email upon generating
   * a notification. Defaults to false.
   */
  sendsImmediateEmail: Scalars['Boolean'];
  /** The setting values for this global notification configuration. */
  settingValues: Array<GlobalNotificationConfigurationSettingValue>;
  /**
   * Whether to send a notification summary email at a set interval.
   * This will currently only allow a setting for 'DAILY' but can easily
   * be modified to include WEEKLY and/or MONTHLY if the need arises.
   * The field is optional for backward compatibility.
   */
  summaryEmails?: Maybe<Array<GlobalNotificationConfigurationSummaryEmail>>;
  targetBusinessUnitRepresentativeTypes: Array<RepresentativeType>;
  /** The target email address(es) of the notification configuration. */
  targetEmails: Array<Scalars['String']>;
  /** Whether to notify the target employee of the notification configuration. */
  targetEmployee: Scalars['Boolean'];
  /**
   * The list of target location representatives that the notification will send to.
   * When adding more representative types, simply expand the scope.
   */
  targetLocationRepresentativeTypes: Array<RepresentativeType>;
  /** The target people of the notification configuration. */
  targetPeople: Array<Person>;
  /** Whether to notify target supervisor of the notification configuration. */
  targetSupervisor: Scalars['Boolean'];
  /** Whether to notify the target supervisor's supervisor of the notification configuration. */
  targetSupervisorSupervisor: Scalars['Boolean'];
};

export type GlobalNotificationConfigurationAffectedPersonFilters = {
  __typename?: 'GlobalNotificationConfigurationAffectedPersonFilters';
  /** Whether the affected person has a user account in the platform. */
  hasUserAccount: GlobalNotificationFilterHasUserAccount;
};

export type GlobalNotificationConfigurationAffectedPersonFiltersInput = {
  /** Whether the affected person has a user account in the platform. */
  hasUserAccount?: InputMaybe<GlobalNotificationFilterHasUserAccount>;
};

export type GlobalNotificationConfigurationCreateInput = {
  /** A list of filters for whether an affected person should be notified */
  affectedPersonFilters?: InputMaybe<GlobalNotificationConfigurationAffectedPersonFiltersInput>;
  /** The event types for this global notification configuration */
  eventTypeIds: Array<Scalars['ID']>;
  /**
   * The subject email template to be used when generating the immidiate email notification.
   * If it is not provided, system.notifications.immediate_email.subject is set as default value.
   * This will not do anything if sendsImmediateEmail is set to false
   */
  immediateEmailSubjectTemplateId?: InputMaybe<Scalars['ID']>;
  /**
   * The email template to be used when generating the immediate email notification.
   * This will not do anything if sendsImmediateEmail is set to false.
   */
  immediateEmailTemplateId?: InputMaybe<Scalars['ID']>;
  /**
   * The list of details to include in the global notification. Included details
   * will be shown in the immediate email that gets sent, as well as in some
   * subtitles.
   */
  includeDetails?: InputMaybe<GlobalNotificationConfigurationIncludeDetailsInput>;
  /** Whether the Configuration is enabled or not. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of the global notification */
  name?: InputMaybe<Scalars['String']>;
  /** If a bulk data import should trigger the notification. */
  sendForBulkDataImports?: InputMaybe<Scalars['Boolean']>;
  /** If the person doing the action should receive the notification. */
  sendToActioningPerson?: InputMaybe<Scalars['Boolean']>;
  /**
   * Whether the configuration will send an immediate email upon generating
   * a notification. Does not do anything by default.
   */
  sendsImmediateEmail?: InputMaybe<Scalars['Boolean']>;
  /** The setting values that this global notification configuration will use. */
  settingValues: Array<GlobalNotificationConfigurationSettingValueInput>;
  /**
   * Whether to send a notification summary email at a set interval.
   * This will currently only allow a setting for 'DAILY' but can easily
   * be modified to include WEEKLY and/or MONTHLY if the need arises.
   * The field is optional for backward compatibility.
   */
  summaryEmails?: InputMaybe<Array<GlobalNotificationConfigurationSummaryEmailInput>>;
  /** Which business unit representatives should receive the notification. */
  targetBusinessUnitRepresentativeTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which emails the notification should target (send to). */
  targetEmails: Array<Scalars['String']>;
  /** If the notification should target the employee. */
  targetEmployee?: InputMaybe<Scalars['Boolean']>;
  /** Which location representatives should receive the notification. */
  targetLocationRepresentativeTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which people should receive the notification. */
  targetPeopleIds: Array<Scalars['ID']>;
  /** If the notification should target the supervisor. */
  targetSupervisor?: InputMaybe<Scalars['Boolean']>;
  /** If the notification should target the supervisor's supervisor. */
  targetSupervisorSupervisor?: InputMaybe<Scalars['Boolean']>;
};

export type GlobalNotificationConfigurationCreateResponse = {
  __typename?: 'GlobalNotificationConfigurationCreateResponse';
  globalNotificationConfiguration: GlobalNotificationConfiguration;
};

export type GlobalNotificationConfigurationDeleteInput = {
  /** The ID of the Global Notification Configuration to delete. */
  id: Scalars['ID'];
};

export type GlobalNotificationConfigurationDeleteResponse = {
  __typename?: 'GlobalNotificationConfigurationDeleteResponse';
  /** The ID of the Global Notification Configuration that was deleted. */
  id: Scalars['ID'];
};

export type GlobalNotificationConfigurationIncludeDetails = {
  __typename?: 'GlobalNotificationConfigurationIncludeDetails';
  /** The list of details being included about the actioning person in immediate emails and similar */
  actioningPerson: Array<NotificationIncludeDetail>;
  /** The list of details being included about the affected person in immediate emails and similar */
  affectedPerson: Array<NotificationIncludeDetail>;
  /** The list of details being included about the event itself in immediate emails and similar */
  event: Array<NotificationIncludeDetail>;
  /**
   * The list of details being included about the event settings in immediate emails and similiar
   *     A JSON object has been used so we can store dynamic keys in the following structure
   *     {
   *         '[key]' : [String!]!
   *     }
   */
  eventSettings: Scalars['JSON'];
};

export type GlobalNotificationConfigurationIncludeDetailsInput = {
  /** A list of keys from listNotificationSupportedActioningPersonIncludeDetails to include for affected people info */
  actioningPerson?: InputMaybe<Array<Scalars['String']>>;
  /** A list of keys from listNotificationSupportedAffectedPersonIncludeDetails to include for affected people info */
  affectedPerson: Array<Scalars['String']>;
  /** A list of keys from the notification event types used in this global notification->supportedEventIncludeDetails */
  event: Array<Scalars['String']>;
  /**
   * A list of keys from the notification event settings used in this global notification->supportedEventSettingsIncludeDetails
   *     A JSON object is used so we can store dynamic keys with the following structure:
   *     {
   *         '[key]' : [String!]!
   *     }
   */
  eventSettings?: InputMaybe<Scalars['JSON']>;
};

export type GlobalNotificationConfigurationOrderBy = {
  field: GlobalNotificationConfigurationOrderFields;
  order: GlobalNotificationConfigurationSortOrder;
};

export enum GlobalNotificationConfigurationOrderFields {
  EVENT_TYPE = 'EVENT_TYPE',
  NAME = 'NAME'
}

export type GlobalNotificationConfigurationSettingValue = {
  __typename?: 'GlobalNotificationConfigurationSettingValue';
  /** The details for this setting */
  setting: NotificationEventTypeSetting;
  /** The id string for the setting this value is for */
  settingId: Scalars['String'];
  /** The value selected for this setting. This is dynamic and depends on the setting type. */
  value: Scalars['JSON'];
};

export type GlobalNotificationConfigurationSettingValueInput = {
  /** The id string for the setting this value is for */
  settingId: Scalars['String'];
  /** The value selected for this setting. This is dynamic and depends on the setting type. */
  value: Scalars['JSON'];
};

export enum GlobalNotificationConfigurationSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type GlobalNotificationConfigurationSummaryEmail = {
  __typename?: 'GlobalNotificationConfigurationSummaryEmail';
  /** The call-to-action message that will be displayed in the summary email. */
  callToAction?: Maybe<Scalars['String']>;
  /** The interval for sending the Global Notification summary email. */
  period: GlobalNotificationConfigurationSummaryEmailPeriod;
};

export type GlobalNotificationConfigurationSummaryEmailInput = {
  /**
   * The call-to-action message that will be displayed in the summary email.
   * This can be null or empty to exclude the custom message from the summary email.
   */
  callToAction?: InputMaybe<Scalars['String']>;
  /**
   * The interval for sending the Global Notification summary email.
   * This field is required when the parent field 'summary_emails' are provided
   */
  period: GlobalNotificationConfigurationSummaryEmailPeriod;
};

export enum GlobalNotificationConfigurationSummaryEmailPeriod {
  DAILY = 'DAILY'
}

export type GlobalNotificationConfigurationUpdateInput = {
  /** A list of filters for whether an affected person should be notified */
  affectedPersonFilters?: InputMaybe<GlobalNotificationConfigurationAffectedPersonFiltersInput>;
  /** The ID of the Global Notification Configuration that will be updated. */
  id: Scalars['ID'];
  /**
   * The subject email template to be used when generating the immidiate email notification.
   * If it is not provided, system.notifications.immediate_email.subject is set as default value.
   * This will not do anything if sendsImmediateEmail is set to false
   */
  immediateEmailSubjectTemplateId?: InputMaybe<Scalars['ID']>;
  /**
   * The email template to be used when generating the immediate email notification.
   * This will not do anything if sendsImmediateEmail is set to false.
   */
  immediateEmailTemplateId?: InputMaybe<Scalars['ID']>;
  /**
   * The list of details to include in the global notification. Included details
   * will be shown in the immediate email that gets sent, as well as in some
   * subtitles.
   */
  includeDetails?: InputMaybe<GlobalNotificationConfigurationIncludeDetailsInput>;
  /** Whether the Configuration is enabled or not. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of the global notification */
  name?: InputMaybe<Scalars['String']>;
  /** If a bulk data import should trigger the notification. */
  sendForBulkDataImports?: InputMaybe<Scalars['Boolean']>;
  /** If the person doing the action should receive the notification. */
  sendToActioningPerson?: InputMaybe<Scalars['Boolean']>;
  /**
   * Whether the configuration will send an immediate email upon generating
   * a notification. Does not do anything by default.
   */
  sendsImmediateEmail?: InputMaybe<Scalars['Boolean']>;
  /**
   * The setting values that this global notification configuration will use.
   * This behaves as a PUT, not a PATCH (all settings must be specified)
   */
  settingValues?: InputMaybe<Array<GlobalNotificationConfigurationSettingValueInput>>;
  /**
   * Whether to send a notification summary email at a set interval.
   * This will currently only allow a setting for 'DAILY' but can easily
   * be modified to include WEEKLY and/or MONTHLY if the need arises.
   * The field is optional for backward compatibility.
   */
  summaryEmails?: InputMaybe<Array<GlobalNotificationConfigurationSummaryEmailInput>>;
  /** which business unit representatives should receive the notification. */
  targetBusinessUnitRepresentativeTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which emails the notification should target (send to). */
  targetEmails?: InputMaybe<Array<Scalars['String']>>;
  /** If the notification should target the employee. */
  targetEmployee?: InputMaybe<Scalars['Boolean']>;
  /** Which location representatives should receive the notification. */
  targetLocationRepresentativeTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which people should receive the notification. */
  targetPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** If the notification should target the supervisor. */
  targetSupervisor?: InputMaybe<Scalars['Boolean']>;
  /** If the notification should target the supervisor's supervisor. */
  targetSupervisorSupervisor?: InputMaybe<Scalars['Boolean']>;
};

export type GlobalNotificationConfigurationUpdateResponse = {
  __typename?: 'GlobalNotificationConfigurationUpdateResponse';
  /** The ID of the Global Notification Configuration that was updated. */
  globalNotificationConfiguration: GlobalNotificationConfiguration;
};

export enum GlobalNotificationFilterHasUserAccount {
  EITHER = 'EITHER',
  HAS_ACCOUNT = 'HAS_ACCOUNT',
  NO_ACCOUNT = 'NO_ACCOUNT'
}

export type Goal = {
  __typename?: 'Goal';
  /** The goals that are directly aligned to this goal */
  alignedGoals: Array<Goal>;
  /**
   * A list of alignments that pertain to this goal - this includes hierarchical
   * information, and details where this goal was cascaded to a job but has not
   */
  alignments?: Maybe<Array<GoalAlignment>>;
  /** The org objective this goal is indirectly aligned to through parent goal alignments */
  ancestorOrgObjective?: Maybe<AncestorOrganisationObjective>;
  /** The approval status of this goal that is linked to lighthouse goalStatus */
  approvalStatus: GoalApprovalStatus;
  /** The category of this goal */
  category?: Maybe<GoalCategory>;
  /** All comments made on this goal */
  comments: Array<GoalComment>;
  /** The date this goal was completed (or null if not completed) */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** When the goal was created */
  createdAt: Scalars['DateTime'];
  /** The description of the goal */
  description?: Maybe<Scalars['String']>;
  /** Lists the documents uploaded to this goal */
  documents: Array<GoalDocument>;
  /** When the goal is due */
  dueDate: Scalars['DateTime'];
  /** The feedback that has been requested for this goal */
  feedback: Array<GoalFeedback>;
  goalDocuments: Array<Document>;
  /** A list of measurements that are required to be done to consider this goal complete */
  goalMeasurements: Array<GoalMeasurement>;
  /** The template the goal was built from (or null if no template) */
  goalTemplate?: Maybe<GoalTemplate>;
  id: Scalars['ID'];
  /** The job this goal is associated with */
  job: Job;
  /** A list of jobs that have been cascaded by this goal */
  jobsCasacadedTo?: Maybe<Array<Job>>;
  /** The name of the goal */
  name: Scalars['String'];
  /** The parent goal of this goal */
  parentGoal?: Maybe<Goal>;
  /** The parent organisation objective of this goal */
  parentOrgObjective?: Maybe<OrganisationObjective>;
  /** A list of possible actions for the goal and whether or not the user has permission for them */
  permissions: GoalPermissions;
  /** The person this goal is associated with */
  person: Person;
  /** The progress to completion of the goal */
  progress?: Maybe<Scalars['Float']>;
  /** The progress status of this goal */
  progressStatus: GoalProgressStatus;
  /** When the goal was started */
  startDate: Scalars['DateTime'];
  /**
   * The approval status of this goal that has been used in SPA
   * @deprecated use approvalStatus instead
   */
  status: GoalStatus;
  /** What weight the goal is worth */
  weight?: Maybe<Scalars['Float']>;
};


export type GoalalignmentsArgs = {
  depth: Scalars['Int'];
};

export type GoalAlignment = {
  __typename?: 'GoalAlignment';
  /** The goal this alignment is related to */
  goal?: Maybe<Goal>;
  /** The ID of the goal this alignment is related to */
  goalId?: Maybe<Scalars['ID']>;
  /** Whether the logged in person are permission to view the full goal */
  hasFullViewPermission: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Whether there is a goal created for this alignment */
  isFilled: Scalars['Boolean'];
  /** The job this alignment is related to */
  job?: Maybe<Job>;
  /** The ID of the job this alignment is related to */
  jobId: Scalars['ID'];
  /** The goal that was cascaded down to this goal */
  parentGoal?: Maybe<Goal>;
  /** The ID of the goal that was cascaded down to this goal */
  parentGoalId?: Maybe<Scalars['ID']>;
  /** The person this alignment is related to */
  person: Person;
};

export type GoalApprovalStatus = {
  __typename?: 'GoalApprovalStatus';
  /** The ID of the goal approval status */
  id: Scalars['ID'];
  /** The name of the status */
  name: Scalars['String'];
  /** The order to display the status in */
  sortOrder: Scalars['Int'];
};

export type GoalCategory = {
  __typename?: 'GoalCategory';
  /** The count of related goals */
  goalCount: Scalars['Int'];
  /** The count of related goal templates */
  goalTemplateCount: Scalars['Int'];
  /** The ID of the goal category */
  id: Scalars['ID'];
  /** If the category has been disabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the category */
  name: Scalars['String'];
  /** The order to display the category in */
  sortOrder: Scalars['Int'];
};

export type GoalCategoryCreateInput = {
  /** If the category has been disabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the category */
  name: Scalars['String'];
  /** The order to display the category in */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type GoalCategoryCreateResponse = {
  __typename?: 'GoalCategoryCreateResponse';
  goalCategory: GoalCategory;
};

export type GoalCategoryDeleteResponse = {
  __typename?: 'GoalCategoryDeleteResponse';
  id: Scalars['ID'];
};

export type GoalCategoryFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type GoalCategoryUpdateInput = {
  id: Scalars['ID'];
  /** If the category has been disabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of the category */
  name?: InputMaybe<Scalars['String']>;
  /** The order to display the category in */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type GoalCategoryUpdateResponse = {
  __typename?: 'GoalCategoryUpdateResponse';
  goalCategory: GoalCategory;
};

export type GoalComment = {
  __typename?: 'GoalComment';
  /** The body text of the comment */
  comment: Scalars['String'];
  /** The date the comment was created */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The person who created this comment */
  person?: Maybe<Person>;
};

export type GoalCreateMultipleCreateForIdsInput = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  jobIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GoalCreateMultipleInput = {
  /** The jobs this goal is being cascaded down to */
  cascadeTo?: InputMaybe<Array<Scalars['ID']>>;
  /** The category of this goal */
  categoryId: Scalars['ID'];
  /** The IDs to create goals for */
  createForIds?: InputMaybe<GoalCreateMultipleCreateForIdsInput>;
  /** The user who is creating this goal */
  createdByUserId: Scalars['ID'];
  /** The description of this goal */
  description: Scalars['String'];
  /** When this goal is due to be finished by */
  dueDate: Scalars['String'];
  /** The template this goal is based off of (or null if none selected) */
  goalTemplateId?: InputMaybe<Scalars['ID']>;
  /** The job this goal relates to */
  jobIds?: InputMaybe<Array<Scalars['ID']>>;
  /** A list of measurements that are required to be done to consider this goal complete */
  measurements?: InputMaybe<Array<GoalCreateMultipleMeasurementInput>>;
  /** The name of this goal */
  name: Scalars['String'];
  /** The parent goal of this goal if there is one */
  parentGoalId?: InputMaybe<Scalars['ID']>;
  /** The parent goal of this goal if there is one */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The parent organisation objective of this goal if there is one */
  parentOrgObjectiveId?: InputMaybe<Scalars['ID']>;
  /** The progress of this goal */
  progressStatusId: Scalars['ID'];
  /** The date this goal is expected to be started */
  startDate: Scalars['String'];
  /** The weight of this goal */
  weight?: InputMaybe<Scalars['Float']>;
};

export type GoalCreateMultipleMeasurementInput = {
  action?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the goal template measurement this was created from (if applicable) */
  goalTemplateMeasurementId?: InputMaybe<Scalars['ID']>;
  startingValue?: InputMaybe<Scalars['Float']>;
  target?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type GoalCreateMultipleResponse = {
  __typename?: 'GoalCreateMultipleResponse';
  createdGoals: Array<Goal>;
  createdGoalsCount: Scalars['Int'];
};

export type GoalDeleteResponse = {
  __typename?: 'GoalDeleteResponse';
  id: Scalars['ID'];
};

export type GoalDocument = {
  __typename?: 'GoalDocument';
  /**
   * The date at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   */
  createdAt: Scalars['ID'];
  createdAtDateTime: Scalars['DateTime'];
  /** The readable name of this document (including extension) */
  displayName: Scalars['String'];
  /**
   * The url the document can be downloaded from
   * @deprecated Use downloadUrlAbsolute instead to avoid needing to reconstruct the URL in the client
   */
  downloadUrl: Scalars['String'];
  /** The absolute url the document can be downloaded from */
  downloadUrlAbsolute: Scalars['String'];
  /** The extension of the document (e.g. .jpg, .pdf) */
  extension: Scalars['String'];
  /** The size of the document as a human readable measurement */
  humanReadableSize: Scalars['String'];
  id: Scalars['ID'];
  /** The size of the document in bytes */
  size: Scalars['Int'];
  /** The current status of uploading this document */
  uploadStatus: GoalDocumentUploadStatus;
};

export type GoalDocumentCreateInput = {
  /** The extension of the document */
  extension: Scalars['String'];
  /** The name of the document */
  filename: Scalars['String'];
  /** The ID of the goal to create the document for */
  goalId: Scalars['ID'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type GoalDocumentCreateResponse = {
  __typename?: 'GoalDocumentCreateResponse';
  document: Document;
  /** @deprecated Use the document field instead */
  goalDocument: GoalDocument;
  /** A URL to upload the document to */
  presignedUrl: Scalars['String'];
};

export type GoalDocumentDeleteResponse = {
  __typename?: 'GoalDocumentDeleteResponse';
  /** The ID of the deleted goal document */
  id: Scalars['ID'];
};

export type GoalDocumentUpdateInput = {
  /** The name of the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document */
  id: Scalars['ID'];
  /** The current status of the upload of this document */
  uploadStatus?: InputMaybe<GoalDocumentUploadStatus>;
};

export type GoalDocumentUpdateResponse = {
  __typename?: 'GoalDocumentUpdateResponse';
  document: Document;
  /** @deprecated Use the document field instead */
  goalDocument: GoalDocument;
};

export enum GoalDocumentUploadStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export type GoalFeedback = {
  __typename?: 'GoalFeedback';
  /** The date the feedback was completed */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** Who is expected to respond to the feedback */
  expectedRespondent: Person;
  /** Has the feedback been completed? */
  hasBeenCompleted: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The date the feedback was requested */
  issueDate: Scalars['DateTime'];
  /** The name of the form design for this feedback */
  name: Scalars['String'];
  /** Who responded to the feedback */
  respondent?: Maybe<Person>;
};

export type GoalMeasurement = {
  __typename?: 'GoalMeasurement';
  /** The date the measurement targetValue was equal to the currentValue */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The current value of the measurement */
  currentValue: Scalars['Float'];
  /** The goal this measurement is associated with */
  goal: Goal;
  /** The goal template measurement this measurement was based off */
  goalTemplateMeasurement?: Maybe<GoalTemplateMeasurement>;
  /** The ID of the goal measurement */
  id: Scalars['ID'];
  /** The type of measurement */
  measurementType: GoalMeasurementType;
  /** The name of the measurement */
  name: Scalars['String'];
  /** The progress to completion of the goal measurement - this includes overachievement for target measurements */
  progress: Scalars['Float'];
  /** The sort order of the measurement */
  sortOrder: Scalars['Int'];
  /** The starting value of the measurement */
  startingValue: Scalars['Float'];
  /** The target value of the measurement */
  targetValue: Scalars['Float'];
  /** The unit of measurement */
  unit?: Maybe<Scalars['String']>;
};

export type GoalMeasurementType = {
  __typename?: 'GoalMeasurementType';
  /** The ID of the goal measurement type */
  id: Scalars['ID'];
  /** Whether the measure type is enabled in the system */
  isEnabled: Scalars['Boolean'];
  /** The name of the measurement type */
  name: Scalars['String'];
};

export type GoalMutation = {
  __typename?: 'GoalMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  createGoalComment: CreateGoalCommentResponse;
  deleteGoalComment: DeleteGoalCommentResponse;
};


export type GoalMutationcreateGoalCommentArgs = {
  input: CreateGoalCommentInput;
};


export type GoalMutationdeleteGoalCommentArgs = {
  input: DeleteGoalCommentInput;
};

export type GoalPermissions = {
  __typename?: 'GoalPermissions';
  approve: Scalars['Boolean'];
  comment: Scalars['Boolean'];
  decline: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  edit: Scalars['Boolean'];
};

export type GoalProgressStatus = {
  __typename?: 'GoalProgressStatus';
  /** The ID of the goal progress status */
  id: Scalars['ID'];
  /** If the category has been disabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the progress status */
  name: Scalars['String'];
  /** The order to display the category in */
  sortOrder: Scalars['Int'];
};

export type GoalProgressStatusFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export enum GoalProgressStatusType {
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  NEEDS_SUPPORT = 'NEEDS_SUPPORT',
  NOT_STARTED = 'NOT_STARTED',
  ON_TARGET = 'ON_TARGET'
}

export type GoalProgressStatusUpdateInput = {
  /** The goal to update the progress status for */
  id: Scalars['ID'];
  /** The progress status the goal is being updated to */
  progressStatusId: Scalars['ID'];
};

export type GoalProgressStatusUpdateResponse = {
  __typename?: 'GoalProgressStatusUpdateResponse';
  goal: Goal;
};

export type GoalQuery = {
  __typename?: 'GoalQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
};

export type GoalStatus = {
  __typename?: 'GoalStatus';
  /** The ID of the goal status */
  id: Scalars['ID'];
  /** The name of the status */
  name: Scalars['String'];
  /** The order to display the status in */
  sortOrder: Scalars['Int'];
};

export type GoalTemplate = {
  __typename?: 'GoalTemplate';
  /** The archive date of the goal template */
  archivedAt?: Maybe<Scalars['DateTime']>;
  /** The category of this goal template */
  category: GoalCategory;
  /** The user who created the goal template */
  createdByUser?: Maybe<User>;
  /** The description of the goal */
  description: Scalars['String'];
  /** A list of measurements that are required to be done to consider this goal complete */
  goalTemplateMeasurements: Array<GoalTemplateMeasurement>;
  /** The ID of the goal template */
  id: Scalars['ID'];
  /** If this goal template can be applied to new goals */
  isEnabled: Scalars['Boolean'];
  /** The name of the goal */
  name: Scalars['String'];
  /** Count of Goals created from this template */
  numberOfGoalsCreated: Scalars['Int'];
  /** The modified date of the goal template */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalTemplateCreateInput = {
  /** The category of goals created from this template */
  categoryId: Scalars['ID'];
  /** The user who is creating this goal template */
  createdByUserId: Scalars['ID'];
  /** The description of goals created from this template */
  description: Scalars['String'];
  /** The measurements of goals created from this template */
  measurements?: InputMaybe<Array<GoalTemplateMeasurementInput>>;
  /** The name of goals created from this template */
  name: Scalars['String'];
};

export type GoalTemplateCreateResponse = {
  __typename?: 'GoalTemplateCreateResponse';
  goalTemplate: GoalTemplate;
};

export type GoalTemplateDeleteInput = {
  /** The ID of the goal template */
  id: Scalars['ID'];
};

export type GoalTemplateDeleteResponse = {
  __typename?: 'GoalTemplateDeleteResponse';
  goalTemplateId: Scalars['ID'];
};

export type GoalTemplateFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type GoalTemplateMeasurement = {
  __typename?: 'GoalTemplateMeasurement';
  /** The measurements based on this template */
  goalMeasurements: Array<GoalMeasurement>;
  /** The goal template that this template measurement belongs to */
  goalTemplate: GoalTemplate;
  /** The ID of the goal template measurement */
  id: Scalars['ID'];
  /** The type of measurement */
  measurementType: GoalMeasurementType;
  /** The name of the template measurement */
  name: Scalars['String'];
  /** The sort order of the template measurement type */
  sortOrder: Scalars['Int'];
  /** The starting value of the template measurement */
  startingValue: Scalars['Float'];
  /** The target value of the template measurement */
  targetValue: Scalars['Float'];
  /** The unit of measurement */
  unit?: Maybe<Scalars['String']>;
};

export type GoalTemplateMeasurementInput = {
  /** The action that needs to be done if it is an action measurement */
  action?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  startingValue?: InputMaybe<Scalars['Float']>;
  /** The target value for the unit if it is a quantity measurement */
  target?: InputMaybe<Scalars['Float']>;
  /** The type of measurement - quantity or checkbox */
  type: Scalars['String'];
  /** What unit is being measured if it is a quantity measurement */
  unit?: InputMaybe<Scalars['String']>;
};

export type GoalTemplateUpdateInput = {
  /** The category of goals created from this template */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** The description of goals created from this template */
  description?: InputMaybe<Scalars['String']>;
  /** The goal template id */
  id: Scalars['ID'];
  /** Whether a goal template is archived or not */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Whether a goal template is enabled or not */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The measurements of goals created from this template */
  measurements?: InputMaybe<Array<GoalTemplateMeasurementInput>>;
  /** The name of goals created from this template */
  name?: InputMaybe<Scalars['String']>;
};

export type GoalTemplateUpdateResponse = {
  __typename?: 'GoalTemplateUpdateResponse';
  goalTemplate: GoalTemplate;
};

export type GoalUpdateInput = {
  /** An action that a person who edit this goal takes. */
  approvalAction?: InputMaybe<ApprovalActionType>;
  /** The jobs this goal is being cascaded down to */
  cascadeTo?: InputMaybe<Array<Scalars['ID']>>;
  /** The category of this goal */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** A comment that is provided to this goal */
  comment?: InputMaybe<Scalars['String']>;
  /** The description of this goal */
  description?: InputMaybe<Scalars['String']>;
  /** When this goal is due to be finished by */
  dueDate?: InputMaybe<Scalars['String']>;
  /** The goal to edit */
  id: Scalars['ID'];
  /** The job this goal relates to */
  jobId?: InputMaybe<Scalars['ID']>;
  /** A list of measurements that are required to be done to consider this goal complete */
  measurements?: InputMaybe<Array<GoalUpdateMeasurementInput>>;
  /** The name of this goal */
  name?: InputMaybe<Scalars['String']>;
  /** The parent goal of this goal if there is one */
  parentGoalId?: InputMaybe<Scalars['ID']>;
  /** The parent goal of this goal if there is one */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The parent organisation objective of this goal if there is one */
  parentOrgObjectiveId?: InputMaybe<Scalars['ID']>;
  /** The date this goal is expected to be started */
  startDate?: InputMaybe<Scalars['String']>;
  /** The weight of this goal */
  weight?: InputMaybe<Scalars['Float']>;
};

export type GoalUpdateMeasurementInput = {
  action?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the goal template measurement this was created from (if applicable) */
  goalTemplateMeasurementId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  startingValue?: InputMaybe<Scalars['Float']>;
  target?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type GoalUpdateMeasurementValueInput = {
  /** The current value of the goal measurement */
  currentValue: Scalars['Float'];
  /** The goal measurement to edit the value of */
  id: Scalars['ID'];
};

export type GoalUpdateMeasurementValueResponse = {
  __typename?: 'GoalUpdateMeasurementValueResponse';
  goalMeasurement: GoalMeasurement;
};

export type GoalUpdateResponse = {
  __typename?: 'GoalUpdateResponse';
  goal: Goal;
};

export type GroupComplianceOverview = {
  __typename?: 'GroupComplianceOverview';
  jobRequirementGroupId: Scalars['ID'];
  jobRequirementGroupName: Scalars['String'];
  qualificationTemplates: Array<QualificationComplianceOverview>;
  total: Scalars['Int'];
  totalAwaitingApproval: Scalars['Int'];
  totalCompliant: Scalars['Int'];
  totalExpiringSoon: Scalars['Int'];
  totalNonCompliant: Scalars['Int'];
};

export type GroupCompositeQualificationStatsV2 = {
  __typename?: 'GroupCompositeQualificationStatsV2';
  compositeQualifications: Array<GroupCompositeQualificationV2>;
  id: Scalars['ID'];
  job?: Maybe<Job>;
  jobId: Scalars['ID'];
  jobRestrictedMandatoryQualificationEffectiveDates: Array<Maybe<Scalars['DateTime']>>;
  person?: Maybe<Person>;
  personId: Scalars['ID'];
  totalAwaitingApproval: Scalars['Int'];
  totalCompliant: Scalars['Int'];
  totalExpiringSoon: Scalars['Int'];
  totalNonCompliant: Scalars['Int'];
};

export type GroupCompositeQualificationV2 = {
  __typename?: 'GroupCompositeQualificationV2';
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  /** The Current Qualification */
  currentQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** The Current Qualification Status */
  currentStatusId?: Maybe<Scalars['ID']>;
  currentStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  currentUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The Qualification Type */
  isCompliant: Scalars['Boolean'];
  /** The Qualification Template */
  qualificationTemplateId: Scalars['ID'];
  qualificationTemplateName: Scalars['String'];
  /** The Qualification Type */
  qualificationTypeName: Scalars['String'];
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  /** The Renewal Qualification */
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** The Renewal Qualification Status */
  renewalStatusId?: Maybe<Scalars['ID']>;
  renewalStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  renewalUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedPeopleSearchJobSummary = {
  __typename?: 'GroupedPeopleSearchJobSummary';
  /** the position title as at now for this job */
  currentPositionTitle?: Maybe<Scalars['String']>;
  /** if the job has any pending leave */
  hasPendingLeave?: Maybe<Scalars['Boolean']>;
  /** the job include for this record */
  job?: Maybe<Job>;
  /** the id for the resulting job */
  jobId?: Maybe<Scalars['ID']>;
  /** the status id for this job */
  jobStatusId?: Maybe<JobStatusId>;
  /** the name of the current job status */
  jobStatusName?: Maybe<Scalars['String']>;
  /** the id for the current pending leave if there is any */
  pendingLeaveId?: Maybe<Scalars['ID']>;
};

export type GroupedPeopleSearchResult = {
  __typename?: 'GroupedPeopleSearchResult';
  /** The formatted display name of a person as configured by the system */
  displayName?: Maybe<Scalars['String']>;
  /** The employee number for the person */
  employeeNumber?: Maybe<Scalars['String']>;
  /** the employment status of the persons jobs */
  employmentStatusId?: Maybe<PersonEmploymentStatusId>;
  /** the calculated initials for this record */
  initials?: Maybe<Scalars['String']>;
  /** if the person was preloaded or included in the search result */
  isPreloaded?: Maybe<Scalars['Boolean']>;
  /** a list of jobs that a user has */
  jobSummary?: Maybe<Array<Maybe<GroupedPeopleSearchJobSummary>>>;
  /** The last name for this person */
  lastName?: Maybe<Scalars['String']>;
  /** The person include for this record */
  person?: Maybe<Person>;
  /** the person id for the search result */
  personId: Scalars['ID'];
  /** The preferred name if it exists otherwise the first name */
  preferredOrFirstName?: Maybe<Scalars['String']>;
  /** The id that references a person's cover picture */
  profileCoverPictureId?: Maybe<Scalars['ID']>;
  /** The id that references a person's profile picture */
  profilePictureId?: Maybe<Scalars['ID']>;
};

export enum HeadingSizeEnum {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5'
}

export type HideFromOnboardingInput = {
  hide: Scalars['Boolean'];
  /** Job to be hidden. If not provided the person is hidden from onboarding entirely */
  jobId?: InputMaybe<Scalars['ID']>;
};

export type HideFromOnboardingResult = {
  __typename?: 'HideFromOnboardingResult';
  success: Scalars['Boolean'];
};

export type HourlyRateType = {
  __typename?: 'HourlyRateType';
  id: Scalars['ID'];
  /** The display name of the hourly rate type */
  name: Scalars['String'];
};

export type IXeroPersonPayrollCompletedInput = {
  /** The id in lapis for the person that added the subject person to Xero */
  onboarderId?: InputMaybe<Scalars['String']>;
  /** The id in lapis for the person that is being flagged as having completed their payroll */
  personId: Scalars['String'];
  /** The Xero identifier for the person that was added to Xero */
  xeroIdentifier?: InputMaybe<Scalars['String']>;
};

export type IncludeBusinessEntities = {
  __typename?: 'IncludeBusinessEntities';
  /** The business entities to include in this (recurring) pulse. The business entity will be null if it has been deleted. */
  businessEntities: Array<Maybe<BusinessEntity>>;
};

export type IncludeBusinessUnits = {
  __typename?: 'IncludeBusinessUnits';
  /** The business units to include in this (recurring) pulse. The business unit will be null if it has been deleted. */
  businessUnits: Array<Maybe<BusinessUnit>>;
};

export type IncludeLocations = {
  __typename?: 'IncludeLocations';
  /** The locations to include in this (recurring) pulse. The location will be null if it has been deleted. */
  locations: Array<Maybe<Location>>;
};

export type IncludePayGrades = {
  __typename?: 'IncludePayGrades';
  /** The pay grades to include in this (recurring) pulse. The pay grade will be null if it has been deleted. */
  payGrades: Array<Maybe<PayGrade>>;
};

export type IncludeSubjects = {
  __typename?: 'IncludeSubjects';
  /** The subject jobs to include in this (recurring) pulse. The job will be null if it has been deleted. */
  jobs: Array<Maybe<Job>>;
};

export type IncludeWorkClasses = {
  __typename?: 'IncludeWorkClasses';
  /** The work classes to include in this (recurring) pulse. The work class will be null if it has been deleted. */
  workClasses: Array<Maybe<WorkClass>>;
};

export type InsightDigestInput = {
  /** The email recipient configuration for insight digest */
  emailRecipients: EmailRecipientsInput;
  /** If the insight digest notification is enabled for the tenant */
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type InsightDigestResponse = {
  __typename?: 'InsightDigestResponse';
  emailRecipients: EmailRecipients;
  enabled: Scalars['Boolean'];
};

export type Integration = {
  __typename?: 'Integration';
  /** The tenant specific configuration */
  configuration?: Maybe<IntegrationConfiguration>;
  id: Scalars['ID'];
  /** The name of the integration. */
  name: Scalars['String'];
  /** The slug of the integration. */
  slug: Scalars['String'];
};

export type IntegrationCentralIframeResponse = {
  __typename?: 'IntegrationCentralIframeResponse';
  iframeUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type IntegrationConfiguration = {
  __typename?: 'IntegrationConfiguration';
  id: Scalars['ID'];
  /** If this integration is currently enabled */
  isEnabled: Scalars['Boolean'];
  /** The saved settings for this configuration */
  settings?: Maybe<Scalars['JSON']>;
};

export type IntegrationConfigurationCreateInput = {
  /** The ID of the integration to create a configuration for */
  integrationId: Scalars['ID'];
  /** Whether the integration configuration is currently enabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The saved settings for this configuration */
  settings?: InputMaybe<Scalars['JSON']>;
};

export type IntegrationConfigurationCreateResponse = {
  __typename?: 'IntegrationConfigurationCreateResponse';
  /** The integration configuration that has been created. */
  integrationConfiguration: IntegrationConfiguration;
};

export type IntegrationConfigurationDeleteResponse = {
  __typename?: 'IntegrationConfigurationDeleteResponse';
  /** The ID of the integration configuration that was deleted */
  id: Scalars['ID'];
};

export type IntegrationConfigurationUpdateInput = {
  /** The ID of the integration to update a configuration for */
  id: Scalars['ID'];
  /** Whether the integration configuration is currently enabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The saved settings for this configuration */
  settings: Scalars['JSON'];
};

export type IntegrationConfigurationUpdateResponse = {
  __typename?: 'IntegrationConfigurationUpdateResponse';
  /** The integration configuration that has been created. */
  integrationConfiguration: IntegrationConfiguration;
};

export type IntegrationLog = {
  __typename?: 'IntegrationLog';
  /** The DateTime (ISO8601) that this log was saved with intelliHR */
  createdAt: Scalars['DateTime'];
  /** The saved data for this log */
  data?: Maybe<Scalars['JSON']>;
  /** The event code */
  event: Scalars['String'];
  id: Scalars['ID'];
  /** The integration this log is for */
  integration: Integration;
  /** The integration configuration this log is for. */
  integrationConfiguration?: Maybe<IntegrationConfiguration>;
  /** The DateTime (ISO8601) that this event occurred at */
  occurredAt: Scalars['DateTime'];
  /** The type of log that has been stored */
  type: IntegrationLogType;
};

export type IntegrationLogCreateInput = {
  /** The saved data for this log */
  data: Scalars['JSON'];
  /** The event code */
  event: Scalars['String'];
  /** The ID of the integration configuration to create a log for */
  integrationConfigurationId: Scalars['ID'];
  /** The DateTime (ISO8601) (2020-05-12T13:15:32+00:00) that this event occurred at */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  /** The type of log that has been stored */
  type: IntegrationLogType;
};

export type IntegrationLogCreateResponse = {
  __typename?: 'IntegrationLogCreateResponse';
  /** The integration log that has been created. */
  IntegrationLog: IntegrationLog;
};

export enum IntegrationLogType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export type IntegrationMapping = {
  __typename?: 'IntegrationMapping';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The configuration this mapping is for */
  integrationConfiguration: IntegrationConfiguration;
  /** The type of relation this is, attributed to each individual integration */
  relationType: Scalars['Int'];
  /** Internal id */
  tenantRelatedId: Scalars['ID'];
  /** External id */
  thirdPartyClientId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type IntegrationMappingCreateInput = {
  /** The ID of the integration configuration to create a mapping for */
  integrationConfigurationId: Scalars['ID'];
  /** The type of relation this is, attributed to each individual integration */
  relationType: Scalars['Int'];
  /** Internal id */
  tenantRelatedId: Scalars['ID'];
  /** External id */
  thirdPartyClientId: Scalars['String'];
};

export type IntegrationMappingCreateResponse = {
  __typename?: 'IntegrationMappingCreateResponse';
  /** The integration mapping that has been created. */
  integrationMapping: IntegrationMapping;
};

export type IntegrationMappingDeleteResponse = {
  __typename?: 'IntegrationMappingDeleteResponse';
  /** The ID of the integration mapping that was deleted. */
  id: Scalars['ID'];
};

export type IntegrationMappingUpdateInput = {
  /** The ID of the integration mapping to update */
  id: Scalars['ID'];
  /** The type of relation this is, attributed to each individual integration */
  relationType: Scalars['Int'];
  /** Internal id */
  tenantRelatedId: Scalars['ID'];
  /** External id */
  thirdPartyClientId: Scalars['String'];
};

export type IntegrationMappingUpdateResponse = {
  __typename?: 'IntegrationMappingUpdateResponse';
  /** The integration mapping that has been updated. */
  integrationMapping: IntegrationMapping;
};

export type IntegrationsCintraFeatureMenuItems = {
  __typename?: 'IntegrationsCintraFeatureMenuItems';
  /** ID of the Cintra Feature Menu Item */
  id: Scalars['String'];
  /** Name of the Cintra Feature Menu Item */
  name: Scalars['String'];
};

/** Contains all mutations relating to the cintra integration */
export type IntegrationsCintraMutation = {
  __typename?: 'IntegrationsCintraMutation';
  _empty?: Maybe<Scalars['String']>;
  /** Starts a manual sync of the data between the iHR and Cintra platforms */
  sync: IntegrationsCintraSyncResponse;
};

/** Contains all queries relating to the cintra integration */
export type IntegrationsCintraQuery = {
  __typename?: 'IntegrationsCintraQuery';
  _empty?: Maybe<Scalars['String']>;
  /** Gets Cintra Feature Menu Items */
  getFeatureMenuItems?: Maybe<Array<IntegrationsCintraFeatureMenuItems>>;
  /** Creates a one time-link for Cintra iFrame for a specified feature */
  getLoginLink: Scalars['String'];
  /** Check the status of the cintra integration */
  getStatus: IntegrationsCintraStatusResponse;
};


/** Contains all queries relating to the cintra integration */
export type IntegrationsCintraQuerygetLoginLinkArgs = {
  selfServiceFeatureMenuItemId?: InputMaybe<Scalars['String']>;
};

export type IntegrationsCintraStatusResponse = {
  __typename?: 'IntegrationsCintraStatusResponse';
  /** The email list for the notifications */
  email: Array<Scalars['String']>;
  /** If cintra is currently linked */
  isEnabled: Scalars['Boolean'];
};

export type IntegrationsCintraSyncResponse = {
  __typename?: 'IntegrationsCintraSyncResponse';
  /** If the manual sync was a success, or if a failure occurred */
  result: Scalars['Boolean'];
};

/** Contains all queries relating to the merge integration */
export type IntegrationsMergeQuery = {
  __typename?: 'IntegrationsMergeQuery';
  _empty?: Maybe<Scalars['String']>;
  /** Converts the provided data to a Merge Link token for the frontend to consume */
  getLinkToken: Scalars['String'];
  /** Check the status of the merge integration */
  getStatus: IntegrationsMergeStatusResponse;
};


/** Contains all queries relating to the merge integration */
export type IntegrationsMergeQuerygetLinkTokenArgs = {
  categories: Array<GetLinkTokenArgsCategories>;
  endUserEmailAddress?: InputMaybe<Scalars['String']>;
  endUserOrganizationName: Scalars['String'];
  endUserOriginId: Scalars['String'];
  integration?: InputMaybe<Scalars['String']>;
};

export type IntegrationsMergeStatusResponse = {
  __typename?: 'IntegrationsMergeStatusResponse';
  /** The email list for the notifications */
  email: Array<Scalars['String']>;
  /** Which integration merge is linked too */
  integration?: Maybe<Scalars['String']>;
  /** If merge is currently linked */
  isEnabled: Scalars['Boolean'];
};

export type IntegrationsMutation = {
  __typename?: 'IntegrationsMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Contains all mutations relating to the cintra integration */
  cintra?: Maybe<IntegrationsCintraMutation>;
  /** Contains all mutations relating to the Sftp integration */
  sftp?: Maybe<SftpMutation>;
  /** Xero domain contains mutations relating to the integrations with Xero payroll system */
  xero?: Maybe<IntegrationsXeroMutation>;
};

export type IntegrationsQuery = {
  __typename?: 'IntegrationsQuery';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Contains all queries relating to the cintra integration */
  cintra?: Maybe<IntegrationsCintraQuery>;
  /** Contains all queries relating to the merge integration */
  merge?: Maybe<IntegrationsMergeQuery>;
  /** Contains all queries relating to the Sftp integration */
  sftp?: Maybe<SftpQuery>;
  /** Xero domain contains queries relating to the integrations with Xero payroll system */
  xero?: Maybe<IntegrationsXeroQuery>;
  /** Contains all queries relating to the Xero integration */
  xeroV2?: Maybe<XeroQuery>;
};

export type IntegrationsXeroMutation = {
  __typename?: 'IntegrationsXeroMutation';
  /** Generate a new Xero Request Token and store it against the current tenant */
  _empty?: Maybe<Scalars['String']>;
  /** Creates the configuration for the users tenant */
  createConfiguration?: Maybe<XeroConfiguration>;
  /** Deletes the configuration for the users tenant */
  deleteConfiguration?: Maybe<Scalars['Boolean']>;
  /** The first step in the oauth handshake where it Generates a url required to continue */
  generateRequestToken?: Maybe<XeroAuthenticationToken>;
  payrollCompleted?: Maybe<Scalars['Boolean']>;
  processXeroPayrollOnboarding?: Maybe<Scalars['Boolean']>;
  /** revokes the ouath token */
  revokeAuthentication?: Maybe<Scalars['Boolean']>;
  /** The final step in an oauth handshake where it is given the valid token details generated from the auth url */
  verifyRequestToken?: Maybe<XeroAuthenticationToken>;
};


export type IntegrationsXeroMutationcreateConfigurationArgs = {
  input?: InputMaybe<XeroConfigurationCreateInput>;
};


export type IntegrationsXeroMutationpayrollCompletedArgs = {
  input: IXeroPersonPayrollCompletedInput;
};


export type IntegrationsXeroMutationprocessXeroPayrollOnboardingArgs = {
  input: XeroPayrollOnboardingInput;
};


export type IntegrationsXeroMutationverifyRequestTokenArgs = {
  input?: InputMaybe<XeroAuthenticationTokenVerification>;
};

export type IntegrationsXeroQuery = {
  __typename?: 'IntegrationsXeroQuery';
  /** Get the Xero Authentication Token */
  _empty?: Maybe<Scalars['String']>;
  /** Authentication related queries */
  authentication?: Maybe<XeroAuthenticationToken>;
  /** Gets the configuration for the users tenant */
  getConfiguration?: Maybe<XeroConfiguration>;
  /** Payroll related queries */
  person?: Maybe<XeroPerson>;
  /** if the provided person should be forced to fill in payroll */
  shouldForcePayrollForPerson: Scalars['Boolean'];
  /** Payroll related queries */
  superfunds?: Maybe<Array<Maybe<XeroSuperfund>>>;
};


export type IntegrationsXeroQuerypersonArgs = {
  id: Scalars['ID'];
};


export type IntegrationsXeroQueryshouldForcePayrollForPersonArgs = {
  id: Scalars['ID'];
};

export type IntelliHRLogos = {
  __typename?: 'IntelliHRLogos';
  /** Small inconspicuous logo used on the login screen */
  bwSmallUrl: Scalars['String'];
  /** Main logo used on the top-left of the app. */
  onWhiteRGBUrl: Scalars['String'];
};

export type IntellihrIdStartingValue = {
  __typename?: 'IntellihrIdStartingValue';
  /** The starting value of auto increment intelliHR IDs */
  startingValue: Scalars['Int'];
};

export type IntellihrIdStartingValueInput = {
  /** The starting value of auto increment intelliHR IDs */
  startingValue: Scalars['Int'];
};

export type IntellihrIdStartingValueResponse = {
  __typename?: 'IntellihrIdStartingValueResponse';
  /** The starting value of auto increment intelliHR IDs */
  startingValue: Scalars['Int'];
};

export type Job = {
  __typename?: 'Job';
  actions: Array<JobAction>;
  /** All historical links between the job and its business entity */
  allJobBusinessEntities: Array<JobBusinessEntity>;
  /** All historical links between the job and its business unit */
  allJobBusinessUnits: Array<JobBusinessUnit>;
  allJobCustomFieldValues: Array<CustomFieldValue>;
  allJobEmploymentConditions?: Maybe<Array<JobEmploymentCondition>>;
  /** All historical links between the job and its establishments */
  allJobEstablishments: Array<JobEstablishment>;
  /** All historical job FTEs */
  allJobFTEs?: Maybe<Array<JobFTE>>;
  /** All historical job leave records */
  allJobLeave?: Maybe<Array<JobLeave>>;
  /** All historical links between the job and its location */
  allJobLocations: Array<JobLocation>;
  /** All historical job names */
  allJobNames: Array<JobName>;
  /** All historical links between the job and the pay grade for the job */
  allJobPayGrades?: Maybe<Array<JobPayGrade>>;
  allJobPaySteps?: Maybe<Array<JobPayStep>>;
  /** All historical links between the job and its performance frameworks */
  allJobPerformanceFrameworks: Array<JobPerformanceFramework>;
  /** All historical remuneration schedules for a job */
  allJobRemunerationSchedules?: Maybe<Array<JobRemunerationSchedule>>;
  /** All historical links between the job and its work type */
  allJobSubordinates: Array<JobSupervisor>;
  /** All historical links between the job and their supervisor */
  allJobSupervisors: Array<JobSupervisor>;
  /** All historical links between the job and its work class */
  allJobWorkClasses?: Maybe<Array<JobWorkClass>>;
  /** All historical links between the job and its work type */
  allJobWorkTypes?: Maybe<Array<JobWorkType>>;
  /**
   * The approval order for the pending update on the job (if any) or the generic
   * approval order. This does not support access for approvers
   * @deprecated This field does not support access for approvers. Please use approvalDetailsV2
   */
  approvalDetails: Array<ApproverDetail>;
  /** The approval order for the pending update on the job (if any) or the generic approval order. Support access for approvers */
  approvalDetailsV2: Array<ApproverDetail>;
  /** The timeline of all bank accounts collected from superAPI for the given job */
  bankAccountTimeline: Array<BankAccountCollection>;
  comments: Array<JobComment>;
  commentsAsAt: Array<JobComment>;
  /**
   * The date/time the job ends, if it has an end date. This date is exclusive, meaning that the real last day of employment is
   * the 24 hours before this date. When displaying to the user, please be careful
   * to subtract a second before stripping the time info.
   */
  companyExclusiveEndDate?: Maybe<Scalars['DateTime']>;
  /** The start date of job. I.E when the person joined the company */
  companyStartDate?: Maybe<Scalars['DateTime']>;
  /** When the job was created in the system. Not to be confused with start date/end date. */
  createdAt: Scalars['DateTime'];
  employmentConditionPayGradeMismatch?: Maybe<Scalars['Boolean']>;
  /**
   * The date that the job ends, if it has an end date. This is an inclusive date,
   * and will be 24 hours before the real end date
   * @deprecated This is inclusive and is very confusing. Please use @companyExclusiveEndDate for a more explicit usage of dates
   */
  endDate?: Maybe<Scalars['DateTime']>;
  /**
   * The date/time the job ends, if it has an end date. This date is exclusive, meaning that the real last day of employment is
   * the 24 hours before this date. When displaying to the user, please be careful
   * to subtract a second before stripping the time info.
   * @deprecated Ambiguous. Use companyExclusiveEndDate instead for clarity.
   */
  exclusiveEndDate?: Maybe<Scalars['DateTime']>;
  /** The goals that belong to this job */
  goals: Array<Goal>;
  /** If the job belongs to establishment and supervisor on job matches jobs on parent establishment */
  hasInvalidSupervisor: Scalars['Boolean'];
  hasUnactionedPayGradeChange?: Maybe<Scalars['Boolean']>;
  /** If the job has unconfirmed extended leave end dates */
  hasUnconfirmedExtendedLeave: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A list of establishment requirements that are invalid on a job */
  invalidEstablishmentRequirements: Array<EstablishmentRequirementInterface>;
  /** If the job's end date is finalised. */
  isEndDateConfirmed: Scalars['Boolean'];
  /** If the job is currently on extended leave */
  isOnExtendedLeave: Scalars['Boolean'];
  /** Whether this job is the primary job on the person. Only one job on a person can be primary at a time. */
  isPrimaryJob: Scalars['Boolean'];
  /** The link between the job and its business entity */
  jobBusinessEntity: JobBusinessEntity;
  /** The link between the job and its business unit */
  jobBusinessUnit: JobBusinessUnit;
  /** Created By */
  jobCreatedBy?: Maybe<User>;
  jobCustomFieldValues: Array<CustomFieldValue>;
  jobEmploymentCondition?: Maybe<JobEmploymentCondition>;
  /** The link between the job and its establishment */
  jobEstablishment: JobEstablishment;
  /** The FTE attribute for the job */
  jobFTE?: Maybe<JobFTE>;
  /** The leave record for a This will return a dummy record if they are not on leave with a null leave reason. */
  jobLeave?: Maybe<JobLeave>;
  /** The link between the job and its location */
  jobLocation: JobLocation;
  /** The position title attribute for the job */
  jobName: JobName;
  /** The link between the job and the pay grade for the job */
  jobPayGrade?: Maybe<JobPayGrade>;
  jobPayStep?: Maybe<JobPayStep>;
  /** The link between the job and its performance framework */
  jobPerformanceFramework: JobPerformanceFramework;
  /** The current remuneration schedule, or null if no remuneration */
  jobRemunerationSchedule?: Maybe<JobRemunerationSchedule>;
  /** the job requirements that belong to this job */
  jobRequirements: Array<Maybe<JobRequirement>>;
  /** the job requirements that belong to this job as a given date */
  jobRequirementsAsAt: Array<JobRequirement>;
  /** the job requirements that belong to this job at the jobs start date */
  jobRequirementsAsAtJobStart: Array<JobRequirement>;
  /** Current status for this job */
  jobStatus: JobStatus;
  /**
   * Current status for this job
   * @deprecated use jobStatus
   */
  jobStatusId: JobStatusId;
  jobSubordinates?: Maybe<Array<JobSupervisor>>;
  /** The link between the job and their supervisor */
  jobSupervisor: JobSupervisor;
  /** Updated By */
  jobUpdatedBy?: Maybe<User>;
  /** The link between the job and its work class */
  jobWorkClass?: Maybe<JobWorkClass>;
  /** The link between the job and its work type */
  jobWorkType?: Maybe<JobWorkType>;
  /** Length of service based on company start date */
  lengthOfService?: Maybe<Scalars['String']>;
  /**
   * List of notices for a job to display to the user. These are useful
   * to let the user know when a job is about to end or if it is on leave.
   * These are generally displayed as callouts in the job tab on the person profile.
   */
  notices: Array<JobNotice>;
  /**
   * The content of a pending update if a job has one. This does not support access for approvers
   * @deprecated This field does not support access for approvers. Please use pendingUpdateV2
   */
  pendingUpdate?: Maybe<JobPendingUpdate>;
  /** The content of a pending update if a job has one. Support access for approvers */
  pendingUpdateV2?: Maybe<JobPendingUpdate>;
  /** The person that will have, have had or currently has the the job */
  person: Person;
  /**
   * The start date of the current position the person is in.
   * This is the date they last changed position titles or business units.
   */
  positionStartDate?: Maybe<Scalars['DateTime']>;
  /** The start date of job. I.E when the person joined the company */
  probationEndDate?: Maybe<Scalars['DateTime']>;
  /** The total cost of recruitment for the job */
  recruitmentCost?: Maybe<Scalars['Float']>;
  /** The recruitment cost currency for the job */
  recruitmentCostCurrency?: Maybe<Currency>;
  /** The recruitment source for the job */
  recruitmentSource?: Maybe<RecruitmentSource>;
  /**
   * The start date of the job
   * @deprecated Ambiguous and can be confused with positionStartDate. Use companyStartDate instead for clarity.
   */
  startDate: Scalars['DateTime'];
  superAPIEmployeeOnboardingSessions?: Maybe<Array<SuperAPIEmployeeOnboardingSession>>;
  /** The timeline of all super fund selection collected from superAPI for the given job */
  superFundSelectionTimeline: Array<SuperFundSelectionCollection>;
  /** The timeline of all TFN declarations collected from superAPI for the given job */
  tfnDeclarationTimeline: Array<TFNDeclarationCollection>;
  /** The entire job timeline for the given job */
  timeline: Array<JobGroupedTimelineEvent>;
  /** The job timeline for the given job on a specific day */
  timelineEventAtDateTime: JobGroupedTimelineEvent;
  /** The reason why this job has turnover */
  turnoverReason?: Maybe<TurnoverReason>;
  /** Turnover type for this job */
  turnoverType?: Maybe<TurnoverType>;
  /** The upcoming jobBusinessEntity after the current business entity, or null if no upcoming */
  upcomingJobBusinessEntity?: Maybe<JobBusinessEntity>;
  /** The upcoming jobBusinessUnit after the current business unit, or null if no upcoming */
  upcomingJobBusinessUnit?: Maybe<JobBusinessUnit>;
  upcomingJobCustomFieldValues: Array<CustomFieldValue>;
  upcomingJobEmploymentCondition?: Maybe<JobEmploymentCondition>;
  /** The link between the job and its upcoming establishment */
  upcomingJobEstablishment?: Maybe<JobEstablishment>;
  /** The upcoming jobFTE after the current fte, or null if no upcoming */
  upcomingJobFTE?: Maybe<JobFTE>;
  /** The upcoming jobLeave after the current leave record, or null if no upcoming */
  upcomingJobLeave?: Maybe<JobLeave>;
  /** The upcoming jobLocation after the current location, or null if no upcoming */
  upcomingJobLocation?: Maybe<JobLocation>;
  /** The upcoming jobName after the current name, or null if no upcoming */
  upcomingJobName?: Maybe<JobName>;
  /** The upcoming jobPayGrade after the current pay grade, or null if no upcoming */
  upcomingJobPayGrade?: Maybe<JobPayGrade>;
  upcomingJobPayStep?: Maybe<JobPayStep>;
  /** The link between the job and its upcoming performance framework, or null if no upcoming */
  upcomingJobPerformanceFramework?: Maybe<JobPerformanceFramework>;
  /** The upcoming jobRemunerationSchedule after the current remuneration schedule, or null if no upcoming */
  upcomingJobRemunerationSchedule?: Maybe<JobRemunerationSchedule>;
  /** The upcoming jobSupervisor after the current supervisor, or null if no upcoming */
  upcomingJobSupervisor?: Maybe<JobSupervisor>;
  /** The upcoming jobWorkClass after the current work class, or null if no upcoming */
  upcomingJobWorkClass?: Maybe<JobWorkClass>;
  /** The upcoming jobWorkType after the current work type, or null if no upcoming */
  upcomingJobWorkType?: Maybe<JobWorkType>;
};


export type JoballJobCustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JoballJobSupervisorsArgs = {
  dateRange?: InputMaybe<JobDateRange>;
};


export type JobcommentsAsAtArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobemploymentConditionPayGradeMismatchArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobisOnExtendedLeaveArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobBusinessEntityArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobBusinessUnitArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobCustomFieldValuesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobjobEmploymentConditionArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobEstablishmentArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobFTEArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobLeaveArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobLocationArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobNameArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobPayGradeArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobPayStepArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobPerformanceFrameworkArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobRemunerationScheduleArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type JobjobRequirementsAsAtArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobSubordinatesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobSupervisorArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobWorkClassArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobjobWorkTypeArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobnoticesArgs = {
  timezone?: InputMaybe<Scalars['Timezone']>;
};


export type JobpositionStartDateArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobtimelineEventAtDateTimeArgs = {
  dateTime: Scalars['DateTime'];
};


export type JobupcomingJobBusinessEntityArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobBusinessUnitArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobCustomFieldValuesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobupcomingJobEmploymentConditionArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobEstablishmentArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobFTEArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobLeaveArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobLocationArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobNameArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobPayGradeArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobPayStepArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobPerformanceFrameworkArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobRemunerationScheduleArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobSupervisorArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobWorkClassArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type JobupcomingJobWorkTypeArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobAction = {
  __typename?: 'JobAction';
  /** Where this action should redirect to in order to perform it */
  href: Scalars['String'];
  /** Whether this action is destructive. Destructive actions have a different red style to them. */
  isDestructive: Scalars['Boolean'];
  /**
   * Whether this action is a primary action we want users to perform.
   * In the UI this is used to determine if "Update Job" should be pushed outside
   * the dropdown, for example.
   */
  isPrimary: Scalars['Boolean'];
  /** The human readable name of this action */
  name: Scalars['String'];
};

export type JobApprovalDetailsBusinessUnitRepresentative = {
  __typename?: 'JobApprovalDetailsBusinessUnitRepresentative';
  businessUnitRepresentative: RepresentativeType;
};

export type JobApprovalDetailsLocationRepresentative = {
  __typename?: 'JobApprovalDetailsLocationRepresentative';
  locationRepresentative: RepresentativeType;
};

export type JobApprovalDetailsUndefined = {
  __typename?: 'JobApprovalDetailsUndefined';
  _empty?: Maybe<Scalars['String']>;
};

export type JobApprovalDetailsUnion = JobApprovalDetailsBusinessUnitRepresentative | JobApprovalDetailsLocationRepresentative | JobApprovalDetailsUndefined | JobApprovalDetailsUser;

export type JobApprovalDetailsUser = {
  __typename?: 'JobApprovalDetailsUser';
  user: User;
};

export type JobApprovalOrder = {
  __typename?: 'JobApprovalOrder';
  approverType: ApproverType;
  details?: Maybe<JobApprovalDetailsUnion>;
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type JobAttribute = {
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobBusinessEntity = JobAttribute & {
  __typename?: 'JobBusinessEntity';
  /** The business entity of the job */
  businessEntity: BusinessEntity;
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobBusinessUnit = JobAttribute & {
  __typename?: 'JobBusinessUnit';
  /** The business unit of the job */
  businessUnit: BusinessUnit;
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobChangeApproval = {
  __typename?: 'JobChangeApproval';
  id: Scalars['ID'];
  personId: Scalars['ID'];
  status?: Maybe<JobPendingStatus>;
};

export type JobChangeReason = {
  __typename?: 'JobChangeReason';
  /** How many job comments the job change reason is used by */
  commentCount: Scalars['Int'];
  id: Scalars['ID'];
  /** If the job change reason should be enabled or disabled */
  isEnabled: Scalars['Boolean'];
  /** If this job change reason is currently being used by an undeleted job */
  isInUse: Scalars['Boolean'];
  /** Determine if the job change reason is system or user created */
  isSystem: Scalars['Boolean'];
  /** The name for the job change reason */
  name: Scalars['String'];
};

export type JobChangeReasonCreateInput = {
  /** optional flag to make the reason as enabled/disabled (defaults to true) */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type JobChangeReasonCreateResponse = {
  __typename?: 'JobChangeReasonCreateResponse';
  jobChangeReason: JobChangeReason;
};

export type JobChangeReasonDeleteInput = {
  id: Scalars['ID'];
};

export type JobChangeReasonDeleteResponse = {
  __typename?: 'JobChangeReasonDeleteResponse';
  id: Scalars['ID'];
};

export type JobChangeReasonUpdateInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type JobChangeReasonUpdateResponse = {
  __typename?: 'JobChangeReasonUpdateResponse';
  jobChangeReason: JobChangeReason;
};

export type JobComment = {
  __typename?: 'JobComment';
  /** @deprecated people can be null when deleted, use createdByNullable */
  createdBy: Person;
  createdByNullable?: Maybe<Person>;
  /** @deprecated date can be null, use nullableDate */
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job: Job;
  jobChangeReason: JobChangeReason;
  nullableDate?: Maybe<Scalars['DateTime']>;
};

export type JobCommentDeleteInput = {
  id: Scalars['ID'];
};

export type JobCommentDeleteResponse = {
  __typename?: 'JobCommentDeleteResponse';
  id: Scalars['ID'];
  job: Job;
};

export type JobCommentEditInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobChangeReasonId?: InputMaybe<Scalars['ID']>;
};

export type JobCommentEditResponse = {
  __typename?: 'JobCommentEditResponse';
  jobComment?: Maybe<JobComment>;
};

export type JobCreateInput = {
  /** The business entity for the job */
  businessEntityId: Scalars['ID'];
  /** The business unit for the job */
  businessUnitId: Scalars['ID'];
  /**
   * The date/time the job ends, or null if the job has no planned end date.
   * This is exclusive, and is the start of day after the last day a person will be in the office.
   */
  companyExclusiveEndDate?: InputMaybe<Scalars['DateTime']>;
  /** The date the job starts from. I.E when the person joined the company */
  companyStartDate?: InputMaybe<Scalars['DateTime']>;
  customFieldValues?: InputMaybe<Array<JobCustomFieldValueInput>>;
  /** The ID of the employment condition */
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  /** The ID of the establishment position this job belongs to */
  establishmentId?: InputMaybe<Scalars['ID']>;
  /** Deprecated: Ambiguous. Use companyExclusiveEndDate instead for clarity. */
  exclusiveEndDate?: InputMaybe<Scalars['DateTime']>;
  /** The fte for the job */
  fte?: InputMaybe<Scalars['String']>;
  /**
   * Sets this job as the primary job on this person.
   * If this is the only job on the person, this is forced to be true (input is ignored).
   * If this is not provided, then this will be true if all jobs are past jobs on the person,
   * or false otherwise.
   */
  isPrimaryJob?: InputMaybe<Scalars['Boolean']>;
  /** Whether a probation period is applicable for this job */
  isProbationApplicable?: InputMaybe<Scalars['Boolean']>;
  /** The location for the job */
  locationId?: InputMaybe<Scalars['ID']>;
  /**
   * The position title for the job, as a string.
   * If no matching position title, then a new one will be created if the user has the
   * settings.position_titles.manage permission.
   */
  name?: InputMaybe<Scalars['String']>;
  /** The pay grade for the job */
  payGradeId?: InputMaybe<Scalars['ID']>;
  /** The pay step for the job */
  payStepId?: InputMaybe<Scalars['ID']>;
  /**
   * The performance framework for the job.
   * If no ID is provided, the tenant only has one performance framework which will be assigned to the job automatically.
   */
  performanceFrameworkId?: InputMaybe<Scalars['ID']>;
  /** The person who the job belongs to */
  personId: Scalars['ID'];
  /**
   * The start date of the current position the person is in.
   * The gap between the companyStartDate and positionStartDate will be filled with "Previous position title"
   * historical records, to correctly depict the person as having no information recorded for old job positions.
   */
  positionStartDate?: InputMaybe<Scalars['DateTime']>;
  /** The position title for the job, as an id */
  positionTitleId?: InputMaybe<Scalars['ID']>;
  /** The date/time the job leaves probation, or null if it should use the system default. */
  probationEndDate?: InputMaybe<Scalars['DateTime']>;
  /** Recruitment cost for the job */
  recruitmentCost?: InputMaybe<Scalars['Float']>;
  /** Recruitment cost currency */
  recruitmentCostCurrencyId?: InputMaybe<Scalars['ID']>;
  /** Recruitment source for the job */
  recruitmentSourceId?: InputMaybe<Scalars['ID']>;
  /** The remuneration schedule for the job */
  remunerationSchedule?: InputMaybe<JobCreateRemunerationInput>;
  /** Deprecated: Ambiguous and can be confused with positionStartDate. Use companyStartDate instead for clarity. */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** The supervisor for the job, or null for no supervisor */
  supervisorJobId?: InputMaybe<Scalars['ID']>;
  /** The work class for the job */
  workClassId: Scalars['ID'];
  /** Unused. This has no effect and has been replaced by workClassId. */
  workTypeId?: InputMaybe<Scalars['ID']>;
};

export type JobCreateRemunerationInput = {
  /** Array of additions being applied to this rem schedule */
  additionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Array of additions tot total being applied to this rem schedule */
  additionsToTotal?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Base pay per unit of work (required if type is RATE) */
  baseAmountPerUnit?: InputMaybe<Scalars['Float']>;
  /** Base annual amount of pay (required if type is ANNUAL) */
  baseAnnualAmount?: InputMaybe<Scalars['Float']>;
  /** Array of breakdowns being applied to this rem schedule */
  breakdowns?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The currency id used for the base and total amounts on this rem schedule */
  currencyId?: InputMaybe<Scalars['ID']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<RemunerationCustomFieldValueInput>>>;
  /** Array of deductions being applied to this rem schedule */
  deductionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The employment condition id for this rem schedule */
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  /** The pay cycle id being used for this rem schedule (i.e. monthly, weekly etc) */
  payCycleId?: InputMaybe<Scalars['ID']>;
  /** The schedule type; i.e. Paid annually, or using an hourly rate. */
  type: RemunerationScheduleType;
  /**
   * How many units (i.e. hours worked) there are in this schedule's pay cycle.
   * Typically ~38 for fortnightly paid pay cycles, as an example.
   */
  unitsPerCycle?: InputMaybe<Scalars['Float']>;
};

export type JobCreateResponse = {
  __typename?: 'JobCreateResponse';
  job: Job;
  /** The person who owns the created job */
  person: Person;
  /** The ID of the workflow event to trigger */
  workflowEventId?: Maybe<Scalars['ID']>;
  /**
   * Workflow events triggered from creating this job
   * @deprecated use frontend redirect chain and workflowEventId
   */
  workflowEventTriggers: Array<Maybe<WorkflowEventTrigger>>;
};

export type JobCustomFieldValueInput = {
  apiName: Scalars['String'];
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type JobDateRange = {
  /** The end date of the date range, or null for end of job/time */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** The start date of the date range, or null for start of job */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type JobDirectReportsReassignInput = {
  /** the job to asssign the subordinates away from */
  currentSupervisorJobId: Scalars['ID'];
  /** when this change will take place */
  effectiveFrom: Scalars['DateTime'];
  /** the date this change will revert to the old value, optional */
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
  /** he direct report details used to reassign */
  reassignments: Array<JobDirectReportsReassignment>;
};

export type JobDirectReportsReassignResponse = {
  __typename?: 'JobDirectReportsReassignResponse';
  /** the job that had it's subordinates assigned away from */
  currentSupervisorJob: Job;
  /** the jobs of the reassigned jobs */
  directReportJobs?: Maybe<Array<Job>>;
};

export type JobDirectReportsReassignment = {
  /** The job to reassign the supervisor for */
  directReportJobId: Scalars['ID'];
  /** The new supervisor of the job */
  newSupervisorJobId?: InputMaybe<Scalars['ID']>;
};

export type JobEmploymentCondition = JobAttribute & {
  __typename?: 'JobEmploymentCondition';
  /** The employment condition of the job */
  employmentCondition?: Maybe<EmploymentCondition>;
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobEndDateCancelInput = {
  /**
   * Whether the users account will be disabled upon the job ending, if user
   * doesn't exist the Boolean has no effect yet the request still succeeds.
   */
  cancelDisablingUserAccount: Scalars['Boolean'];
  /** The ID of the job to end */
  jobId: Scalars['ID'];
};

export type JobEndDateCancelResponse = {
  __typename?: 'JobEndDateCancelResponse';
  /** The job whose end date was canceled */
  job: Job;
  /** The user that relates to the job that is canceled */
  user?: Maybe<User>;
};

export type JobEndDateFinaliseInput = {
  /** Whether the users account will be disabled upon the job ending */
  disableUserAccountAfterJobEnds: Scalars['Boolean'];
  /**
   * The date/time the job ends, if it has an end date.
   * This is exclusive, and is start of day after the last day a person will be in the office.
   */
  exclusiveEndDate: Scalars['DateTime'];
  /** The ID of the job to end */
  jobId: Scalars['ID'];
  /**
   * This is an optional field to reassign all direct reports to a specific job id.
   * It is recomended to use the job reassign direct reports page since it allows assigning different jobs to
   * different supervisors but where this isn't possible (e.g. public api) this field can be used.
   */
  reassignDirectReportsTo?: InputMaybe<Scalars['ID']>;
  /** How many days before the job ends the end date should be displayed to everyone in the system */
  showEndDateDays?: InputMaybe<Scalars['Int']>;
  /** When true, no events will be fired from this action (defaults to false) */
  suppressEvents?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the turnover reason for the end of the job. Must be consistent with the turnoverType */
  turnoverReasonId?: InputMaybe<Scalars['ID']>;
  /** The ID of the turnover type for the end of the job */
  turnoverType: TurnoverType;
};

export type JobEndDateFinaliseResponse = {
  __typename?: 'JobEndDateFinaliseResponse';
  /** The job whose end date was finalised */
  job: Job;
  /** The ID of the workflow event to trigger */
  workflowEventId?: Maybe<Scalars['ID']>;
  /**
   * Workflow events triggered from finalising the end date of this job
   * @deprecated use frontend redirect chain and workflowEventId
   */
  workflowEventTriggers?: Maybe<Array<WorkflowEventTrigger>>;
};

export type JobEstablishment = JobAttribute & {
  __typename?: 'JobEstablishment';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The establishment of the job */
  establishment?: Maybe<Establishment>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobExpectedEndDateAdjustRequest = {
  /** The exclusive expected end date of the job */
  exclusiveExpectedEndDate: Scalars['DateTime'];
  /** The ID of the job to update */
  jobId: Scalars['ID'];
};

export type JobExpectedEndDateAdjustResponse = {
  __typename?: 'JobExpectedEndDateAdjustResponse';
  /** The job that was updated */
  job: Job;
};

export type JobExpectedEndDateRemoveRequest = {
  /** The ID of the job to update */
  jobId: Scalars['ID'];
};

export type JobExpectedEndDateRemoveResponse = {
  __typename?: 'JobExpectedEndDateRemoveResponse';
  /** The job that was updated */
  job: Job;
};

export type JobExpectedEndDateSetRequest = {
  /** The exclusive expected end date of the job */
  exclusiveExpectedEndDate: Scalars['DateTime'];
  /** The ID of the job to update */
  jobId: Scalars['ID'];
};

export type JobExpectedEndDateSetResponse = {
  __typename?: 'JobExpectedEndDateSetResponse';
  /** The job that was updated */
  job: Job;
};

export type JobExtendedLeave = {
  __typename?: 'JobExtendedLeave';
  /** The ID of the extended leave that this event applies to */
  id: Scalars['ID'];
  /** If this leave events end date has been confirmed */
  isEndDateConfirmed: Scalars['Boolean'];
  /** The ID of the leave type of this event */
  leaveTypeId: Scalars['ID'];
  /** The human readable name of this event */
  leaveTypeName: Scalars['ID'];
};

export type JobFTE = JobAttribute & {
  __typename?: 'JobFTE';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The current FTE value, or null for no FTE */
  value?: Maybe<Scalars['Float']>;
};

export type JobFilters = {
  isSupervisor?: InputMaybe<BooleanFilter>;
};

export type JobGroupedTimelineEvent = {
  __typename?: 'JobGroupedTimelineEvent';
  /** The comments made when updating jobs */
  comments?: Maybe<Array<JobComment>>;
  /** The timestamp of the group of timeline events */
  groupTime: Scalars['DateTime'];
  /** If this event group is the end of the given job */
  isEndOfJob: Scalars['Boolean'];
  /** If this event group is the end of the jobs probation period */
  isEndOfProbation: Scalars['Boolean'];
  /** If this event group contains the first change of job attributes (e.g. when a job starts) */
  isFirstChange: Scalars['Boolean'];
  /** If this event group is the start of the given job */
  isStartOfJob: Scalars['Boolean'];
  /**
   * The business entity changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobBusinessEntities?: Maybe<Array<Maybe<BusinessEntity>>>;
  /**
   * The business unit changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobBusinessUnits?: Maybe<Array<Maybe<BusinessUnit>>>;
  jobCustomFields?: Maybe<Array<JobTimelineCustomFieldEvent>>;
  /**
   * The current list of direct reports active on at the time of the group timeline
   * event (including changes which occur in the timeline event)
   */
  jobDirectReports: Array<Maybe<JobTimelineJobPerson>>;
  /** An array of direct reports that have been assigned in this event group */
  jobDirectReportsAssigned?: Maybe<Array<Maybe<JobTimelineJobPerson>>>;
  /** An array of direct reports that have been removed in this event group */
  jobDirectReportsRemoved?: Maybe<Array<Maybe<JobTimelineJobPerson>>>;
  /**
   * The employment condition changes made in this event group. Formatted [<from>,
   * <to>, <from>, <to>, etc] to represent the before and after value of the
   * changed attribute
   */
  jobEmploymentConditions: Array<Maybe<EmploymentCondition>>;
  /** The details of this extended leave ended event (null if not an extended leave ended event) */
  jobExtendedLeaveEnded?: Maybe<JobExtendedLeave>;
  /** The details of this extended leave started event (null if not an extended leave started event) */
  jobExtendedLeaveStarted?: Maybe<JobExtendedLeave>;
  /**
   * The fte changes made in this event group. Formatted [<from>, <to>, <from>,
   * <to>, etc] to represent the before and after value of the changed attribute
   */
  jobFtes?: Maybe<Array<Maybe<JobFTE>>>;
  /**
   * The job location changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobLocations?: Maybe<Array<Maybe<Location>>>;
  /**
   * The position title changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobNames?: Maybe<Array<Maybe<JobName>>>;
  /**
   * The pay grade changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobPayGrades?: Maybe<Array<Maybe<PayGrade>>>;
  /**
   * PayStep changes made in this event group. Formatted [<from>, <to>, <from>,
   * <to>, etc] to represent the before and after value of the changed attribute
   */
  jobPaySteps?: Maybe<Array<Maybe<PayStep>>>;
  /**
   * The Performance Framework changes made in this event group. Formatted [<from>,
   * <to>, <from>, <to>, etc] to represent the before and after value of the
   * changed attribute
   */
  jobPerformanceFrameworks?: Maybe<Array<Maybe<PerformanceFramework>>>;
  /**
   * The remuneration schedule changes made in this event group. Formatted [<from>,
   * <to>, <from>, <to>, etc] to represent the before and after value of the
   * changed attribute
   */
  jobRemunerationSchedules?: Maybe<Array<Maybe<JobRemunerationSchedule>>>;
  /**
   * The supervisor changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobSupervisors?: Maybe<Array<Maybe<JobTimelineJobPerson>>>;
  /** The turnover reason of this job ended event (null if not an end of job event or not recorded) */
  jobTurnoverReason?: Maybe<TurnoverReason>;
  /** The turnover type of this job ended event (null if not an end of job event) */
  jobTurnoverType?: Maybe<TurnoverType>;
  /**
   * The work class changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobWorkClasses?: Maybe<Array<Maybe<WorkClass>>>;
  /**
   * The work type changes made in this event group. Formatted [<from>, <to>,
   * <from>, <to>, etc] to represent the before and after value of the changed
   */
  jobWorkTypes?: Maybe<Array<Maybe<WorkType>>>;
  /** The previous Performance Framework, calculated by going over all the previous events up to this event */
  perviousPerformanceFramework?: Maybe<PerformanceFramework>;
  /** The previous EmploymentCondition, calculated by going over all the previous events up to this event */
  previousEmploymentCondition?: Maybe<EmploymentCondition>;
  /** The previous JobBusinessEntity, calculated by going over all the previous events up to this event */
  previousJobBusinessEntity?: Maybe<BusinessEntity>;
  /** The previous JobBusinessUnit, calculated by going over all the previous events up to this event */
  previousJobBusinessUnit?: Maybe<BusinessUnit>;
  previousJobCustomFields?: Maybe<Array<CustomFieldValue>>;
  /** The previous JobFTE, calculated by going over all the previous events up to this event */
  previousJobFte?: Maybe<JobFTE>;
  /** The previous JobLocation, calculated by going over all the previous events up to this event */
  previousJobLocation?: Maybe<Location>;
  /** The previous JobName, calculated by going over all the previous events up to this event */
  previousJobName?: Maybe<JobName>;
  /** The previous JobPayGrade, calculated by going over all the previous events up to this event */
  previousJobPayGrade?: Maybe<PayGrade>;
  /** The previous JobRemunerationSchedule, calculated by going over all the previous events up to this event */
  previousJobRemunerationSchedule?: Maybe<JobRemunerationSchedule>;
  /** The previous JobSupervisor, calculated by going over all the previous events up to this event */
  previousJobSupervisor?: Maybe<JobTimelineJobPerson>;
  /** The previous JobWorkClass, calculated by going over all the previous events up to this event */
  previousJobWorkClass?: Maybe<WorkClass>;
  /** The previous JobWorkType, calculated by going over all the previous events up to this event */
  previousJobWorkType?: Maybe<WorkType>;
  /** The previous job pay step, calculated by going over all the previous events up to this event */
  previousPayStep?: Maybe<PayStep>;
  /** This is populated when a pay grade changes it's definition */
  renamedPayGrades?: Maybe<Array<Maybe<PayGradeValue>>>;
};

export type JobLeave = JobAttribute & {
  __typename?: 'JobLeave';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** If the end date is the expected or actual return date */
  isEndDateConfirmed: Scalars['Boolean'];
  /** The leave type for this leave record, or null if the person was not on leave for these dates */
  leaveType?: Maybe<LeaveType>;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobLeaveCreateInput = {
  /** Also known as the endDate; sets the date the leave ends and the person returns to their job */
  expectedReturnDate: Scalars['DateTime'];
  /** FTE to set for the period of the leave */
  fte?: InputMaybe<Scalars['Float']>;
  /** Also known as the startDate; sets the date the leave is considered started */
  goOnLeaveDate: Scalars['DateTime'];
  jobId: Scalars['ID'];
  leaveTypeId: Scalars['ID'];
};

export type JobLeaveCreateResponse = {
  __typename?: 'JobLeaveCreateResponse';
  /** The job that relates to the created leave */
  job: Job;
  /** The created job leave record */
  jobLeave: JobLeave;
  /** The event which occurred from creating this leave */
  workflowEventId?: Maybe<Scalars['ID']>;
};

export type JobLeaveDeleteResponse = {
  __typename?: 'JobLeaveDeleteResponse';
  /** The ID of the job leave that was deleted */
  id: Scalars['ID'];
  /** The job that relates to the deleted leave */
  job: Job;
};

export type JobLeaveFinaliseInput = {
  /** FTE to set for after they return from leave */
  fte?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Also known as the endDate; sets the date the leave ends and the person returns to their job */
  returnDate: Scalars['DateTime'];
};

export type JobLeaveFinaliseResponse = {
  __typename?: 'JobLeaveFinaliseResponse';
  /** The job that relates to the finalised leave */
  job: Job;
  /** The finalised job leave record */
  jobLeave: JobLeave;
  /** The event which occurred from finalising this leave */
  workflowEventId?: Maybe<Scalars['ID']>;
};

export type JobLeaveUpdateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  leaveTypeId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type JobLeaveUpdateResponse = {
  __typename?: 'JobLeaveUpdateResponse';
  /** The job that relates to the updated leave */
  job: Job;
  /** The job leave that was updated */
  jobLeave: JobLeave;
};

export type JobLocation = JobAttribute & {
  __typename?: 'JobLocation';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The location of the job, or null for no location */
  location?: Maybe<Location>;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobName = JobAttribute & {
  __typename?: 'JobName';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The position title of the job. This is shorthand for positionTitle { name } */
  name: Scalars['String'];
  /** The standardised position title of the job */
  positionTitle: PositionTitle;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobNotice = {
  __typename?: 'JobNotice';
  /** An optional action for the user to perform in order to handle this notice */
  jobAction?: Maybe<JobAction>;
  /**
   * The human-readable content of this job notice. Multiple messages can be
   * returned with more details (e.g. with the turnover type & reason)
   */
  messages: Array<Scalars['String']>;
  /** The human-readable title of this notice */
  title: Scalars['String'];
  /** The type of notice; used for changing the colour */
  type: JobNoticeType;
};

export enum JobNoticeType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  NEUTRAL = 'NEUTRAL',
  WARNING = 'WARNING'
}

export type JobPayGrade = JobAttribute & {
  __typename?: 'JobPayGrade';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The pay grade for this record, or null for not provided */
  payGrade?: Maybe<PayGrade>;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobPayStep = JobAttribute & {
  __typename?: 'JobPayStep';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The pay step of the job */
  payStep?: Maybe<PayStep>;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobPendingCustomFieldInput = {
  __typename?: 'JobPendingCustomFieldInput';
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: Maybe<Scalars['CustomFieldValueValue']>;
};

export type JobPendingRemunerationComponent = {
  __typename?: 'JobPendingRemunerationComponent';
  /** The currency to use for this addition/deduction. For multiples, this is always the same as the base currency. */
  currencyId?: Maybe<Scalars['ID']>;
  /** The id of the default this remuneration component is based on if it is based on one */
  defaultRemunerationComponentId?: Maybe<Scalars['ID']>;
  /** Human-readable name for this addition/deduction */
  name?: Maybe<Scalars['String']>;
  /** Whether the addition/deduction is a multiple of the base or a fixed value */
  type?: Maybe<RemunerationComponentValueType>;
  /** The value of the addition/deduction. For multiples, this is a percent (9.5 for superannuation, for example). */
  value?: Maybe<Scalars['String']>;
};

export type JobPendingRemunerationCustomFieldInput = {
  __typename?: 'JobPendingRemunerationCustomFieldInput';
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: Maybe<Scalars['CustomFieldValueValue']>;
};

export type JobPendingRemunerationInput = {
  __typename?: 'JobPendingRemunerationInput';
  /** Array of additions being applied to this rem schedule */
  additionsToBase?: Maybe<Array<JobPendingRemunerationComponent>>;
  /** Array of additions tot total being applied to this rem schedule */
  additionsToTotal?: Maybe<Array<JobPendingRemunerationComponent>>;
  /** Base pay per unit of work (required if type is RATE) */
  baseAmountPerUnit?: Maybe<Scalars['Float']>;
  /** Base annual amount of pay (required if type is ANNUAL) */
  baseAnnualAmount?: Maybe<Scalars['Float']>;
  /** Array of breakdowns being applied to this rem schedule */
  breakdowns?: Maybe<Array<JobPendingRemunerationComponent>>;
  /** The currency id used for the base and total amounts on this rem schedule */
  currencyId?: Maybe<Scalars['ID']>;
  customFieldValues?: Maybe<Array<Maybe<JobPendingRemunerationCustomFieldInput>>>;
  /** The employment condition id for this rem schedule */
  employmentConditionId?: Maybe<Scalars['ID']>;
  /** The pay cycle id being used for this rem schedule (i.e. monthly, weekly etc) */
  payCycleId?: Maybe<Scalars['ID']>;
  /** The schedule type; i.e. Paid annually, or using an hourly rate. */
  type: RemunerationScheduleType;
  /**
   * How many units (i.e. hours worked) there are in this schedule's pay cycle.
   * Typically ~38 for fortnightly paid pay cycles, as an example.
   */
  unitsPerCycle?: Maybe<Scalars['Float']>;
};

export type JobPendingStatus = {
  __typename?: 'JobPendingStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type JobPendingUpdate = {
  __typename?: 'JobPendingUpdate';
  currentApprovals?: Maybe<Array<JobChangeApproval>>;
  details: JobPendingUpdateDetails;
  id: Scalars['ID'];
  status?: Maybe<JobPendingStatus>;
};

export type JobPendingUpdateApplyInput = {
  /** The ID of the job to update */
  jobId: Scalars['ID'];
};

export type JobPendingUpdateApproveInput = {
  jobPendingUpdateId: Scalars['ID'];
};

export type JobPendingUpdateApproveResponse = {
  __typename?: 'JobPendingUpdateApproveResponse';
  jobPendingUpdate: JobPendingUpdate;
};

export type JobPendingUpdateDetails = {
  __typename?: 'JobPendingUpdateDetails';
  /** The business entity to change to */
  businessEntityId?: Maybe<Scalars['ID']>;
  /** The business unit to change to */
  businessUnitId?: Maybe<Scalars['ID']>;
  customFieldValues?: Maybe<Array<JobPendingCustomFieldInput>>;
  /** A description of why this job change was made */
  description?: Maybe<Scalars['String']>;
  /** If the changes are temporary, the date they are effective too */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The id of the establishment position this job belongs to */
  establishmentId?: Maybe<Scalars['ID']>;
  /** The fte to change to */
  fte?: Maybe<Scalars['String']>;
  /** An id matching a predetermined list of job change reasons */
  jobChangeReasonId?: Maybe<Scalars['ID']>;
  /** The id of the job to update */
  jobId: Scalars['ID'];
  /** The location to change to */
  locationId?: Maybe<Scalars['ID']>;
  /** The pay grade to change to */
  payGradeId?: Maybe<Scalars['ID']>;
  /** The id of the performance framework this job belongs to */
  performanceFrameworkId?: Maybe<Scalars['ID']>;
  /** The new position title for the job, as an id */
  positionTitleId?: Maybe<Scalars['ID']>;
  /** The remuneration schedule to change to */
  remunerationSchedule?: Maybe<JobPendingRemunerationInput>;
  /** The date the changes are effective from, (will set to start of time if null) */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The supervisor to change to */
  supervisorJobId?: Maybe<Scalars['ID']>;
  /** The work class to change to */
  workClassId?: Maybe<Scalars['ID']>;
  /** Unused. This has no effect and has been replaced by workClassId. */
  workTypeId?: Maybe<Scalars['ID']>;
};

export type JobPendingUpdateRejectResponse = {
  __typename?: 'JobPendingUpdateRejectResponse';
  jobPendingUpdate: JobPendingUpdate;
};

export type JobPerformanceFramework = JobAttribute & {
  __typename?: 'JobPerformanceFramework';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The performance framework of the job */
  performanceFramework: PerformanceFramework;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobProbationDateUpdateRequest = {
  id: Scalars['ID'];
  newProbationDate?: InputMaybe<Scalars['DateTime']>;
  probationApplicable?: InputMaybe<Scalars['Boolean']>;
};

export type JobProbationDateUpdateResponse = {
  __typename?: 'JobProbationDateUpdateResponse';
  job: Job;
};

export type JobRemunerationComponent = {
  __typename?: 'JobRemunerationComponent';
  /** The currency to use for this addition/deduction. For multiples, this is always the same as the base currency. */
  currency: Currency;
  /** the default remuneration component this remuneration component is based on */
  defaultRemunerationComponent?: Maybe<DefaultRemunerationComponent>;
  id: Scalars['ID'];
  /** Human-readable name for this addition/deduction */
  name: Scalars['String'];
  /** Whether the addition/deduction is a multiple of the base or a fixed value */
  type: RemunerationComponentValueType;
  /** The value of the addition/deduction. For multiples, this is a percent (9.5 for superannuation, for example). */
  value: Scalars['String'];
};

export type JobRemunerationSchedule = JobAttribute & {
  __typename?: 'JobRemunerationSchedule';
  /** Array of additions being applied to this rem schedule */
  additionsToBase: Array<JobRemunerationComponent>;
  /** Array of additions to total being applied to this rem schedule */
  additionsToTotal: Array<JobRemunerationComponent>;
  /**
   * Base amount of pay per a given unit (typically 1 unit = 1 hour). For salaried
   * employees, this is an estimate from their pay cycle.
   */
  baseAmountPerUnit: Scalars['Float'];
  /** Base annual amount of pay. For rate employees, this is an estimate from their pay cycle. */
  baseAnnualAmount: Scalars['Float'];
  /** Array of breakdowns being applied to this rem schedule */
  breakdowns: Array<JobRemunerationComponent>;
  /** The currency used for the base and total amounts on this rem schedule */
  currency: Currency;
  /**
   * If a currency conversion was done to calculate the totalAnnualPackage. This is
   * usually because of additions or deductions being stored in a diffferent
   */
  currencyConversionOccurred: Scalars['Boolean'];
  customFieldValues: Array<CustomFieldValue>;
  /** Array of deductions being applied to this rem schedule */
  deductionsToBase: Array<JobRemunerationComponent>;
  /**
   * Employment condition this rem schedule is being worked under
   * @deprecated employment condition has been moved to it's own attribute on job, use that instead
   */
  employmentCondition: EmploymentCondition;
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The pay cycle being used for this rem schedule (i.e. monthly, weekly etc) */
  payCycle: PayCycle;
  /** The total annual package multiplied by the full time equivalent of the job */
  proratedSalary?: Maybe<Scalars['Float']>;
  /** The total annual package multiplied by the full time equivalent of the job, as at the end date of the pay package */
  proratedSalaryAsAtEndDate?: Maybe<Scalars['Float']>;
  /** The total annual package multiplied by the full time equivalent of the job, as at the start date of the pay package */
  proratedSalaryAsAtStartDate?: Maybe<Scalars['Float']>;
  /** The schedule type; i.e. Paid annually, or using an hourly rate. */
  scheduleType: RemunerationScheduleType;
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
  /**
   * Calculated estimated total package amount for this employee. This will be
   * after exchange rates and handling of multiple currencies are applied.
   */
  totalAnnualPackage: Scalars['Float'];
  /**
   * Calculated estimated total per unit for this employee. This will be after
   * exchange rates and handling of multiple currencies are applied.
   */
  totalHourlyPackage: Scalars['Float'];
  /**
   * How many units (i.e. hours worked) there are in this schedule's pay cycle.
   * Typically ~38 for fortnightly paid pay cycles, as an example.
   */
  unitsPerCycle: Scalars['Float'];
};


export type JobRemunerationSchedulecustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobRemunerationScheduleproratedSalaryArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobRequirement = {
  __typename?: 'JobRequirement';
  /** The effective end date of the job requirement */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The job of the job requirement */
  job?: Maybe<Job>;
  /** The job requirementable object */
  jobRequirementable: JobRequirementable;
  /** The ID of the linked job requirement group */
  jobRequirementableId?: Maybe<Scalars['String']>;
  /** The Type of the linked job requirement group */
  jobRequirementableType?: Maybe<Scalars['String']>;
  /** Should the employee be notified of this job requirement? (Optional) */
  notifyEmployee?: Maybe<Scalars['Boolean']>;
  /** The owner of the job requirement */
  person?: Maybe<Person>;
  /** The effective start date of the job requirement */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type JobRequirementCreateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  jobId: Scalars['ID'];
  jobRequirementableId: Scalars['ID'];
  jobRequirementableType: Scalars['String'];
  notifyEmployee: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type JobRequirementCreateOrUpdateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  jobId: Scalars['ID'];
  jobRequirementableId?: InputMaybe<Scalars['ID']>;
  jobRequirementableType?: InputMaybe<Scalars['String']>;
  notifyEmployee?: InputMaybe<Scalars['Boolean']>;
  personId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type JobRequirementCreateResponse = {
  __typename?: 'JobRequirementCreateResponse';
  jobRequirement: JobRequirement;
};

export type JobRequirementDeleteResponse = {
  __typename?: 'JobRequirementDeleteResponse';
  id: Scalars['ID'];
};

export type JobRequirementGroup = {
  __typename?: 'JobRequirementGroup';
  id: Scalars['ID'];
  /** The count of how many times this job requirement group is being used in job requirements */
  jobRequirementUsageCount: Scalars['Int'];
  /** The human-readable name of the job requirement group */
  name: Scalars['String'];
  /** The qualification_template items */
  qualificationTemplates: Array<Maybe<QualificationTemplate>>;
};

export type JobRequirementGroupCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  qualificationTemplates: Array<Scalars['ID']>;
};

export type JobRequirementGroupCreateResponse = {
  __typename?: 'JobRequirementGroupCreateResponse';
  jobRequirementGroup: JobRequirementGroup;
};

export type JobRequirementGroupDeleteResponse = {
  __typename?: 'JobRequirementGroupDeleteResponse';
  id: Scalars['ID'];
};

export type JobRequirementGroupUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  qualificationTemplates: Array<Scalars['ID']>;
};

export type JobRequirementGroupUpdateResponse = {
  __typename?: 'JobRequirementGroupUpdateResponse';
  jobRequirementGroup: JobRequirementGroup;
};

export type JobRequirementUpdateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notifyEmployee?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type JobRequirementUpdateResponse = {
  __typename?: 'JobRequirementUpdateResponse';
  jobRequirement: JobRequirement;
};

export type JobRequirementable = JobRequirementGroup | QualificationTemplate;

export type JobRequirementsUpdateInput = {
  jobId: Scalars['ID'];
  jobRequirements: Array<InputMaybe<JobRequirementCreateOrUpdateInput>>;
  personId: Scalars['ID'];
};

export type JobRequirementsUpdateResponse = {
  __typename?: 'JobRequirementsUpdateResponse';
  job: Job;
};

export type JobSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type JobStartDateUpdateRequest = {
  /** The ID of the job to update */
  jobId: Scalars['ID'];
  /** The job start date to update to */
  newStartDate: Scalars['DateTime'];
};

export type JobStartDateUpdateResponse = {
  __typename?: 'JobStartDateUpdateResponse';
  /** The job that was updated */
  job: Job;
};

export enum JobStatus {
  JOB_STATUS_CURRENT_JOB = 'JOB_STATUS_CURRENT_JOB',
  JOB_STATUS_ENDING_JOB = 'JOB_STATUS_ENDING_JOB',
  JOB_STATUS_FUTURE_JOB = 'JOB_STATUS_FUTURE_JOB',
  JOB_STATUS_PAST_JOB = 'JOB_STATUS_PAST_JOB'
}

export enum JobStatusId {
  JOB_STATUS_CURRENT_JOB = 'JOB_STATUS_CURRENT_JOB',
  JOB_STATUS_ENDING_JOB = 'JOB_STATUS_ENDING_JOB',
  JOB_STATUS_FUTURE_JOB = 'JOB_STATUS_FUTURE_JOB',
  JOB_STATUS_PAST_JOB = 'JOB_STATUS_PAST_JOB'
}

export type JobSupervisor = JobAttribute & {
  __typename?: 'JobSupervisor';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The subordinates job for the job supervisor relationship */
  subordinateJob: Job;
  /** The supervisors job for the job supervisor relationship, or null for no supervisor */
  supervisorJob?: Maybe<Job>;
};

export type JobTimelineCustomFieldEvent = {
  __typename?: 'JobTimelineCustomFieldEvent';
  from?: Maybe<Array<CustomFieldValue>>;
  to?: Maybe<Array<CustomFieldValue>>;
};

export type JobTimelineJobPerson = {
  __typename?: 'JobTimelineJobPerson';
  /** The display name of the direct report */
  displayName: Scalars['String'];
  /** Calculated initials for this person */
  initials: Scalars['String'];
  /** The job id of the direct report */
  jobId: Scalars['ID'];
  /** The last name of the direct report */
  lastName: Scalars['String'];
  /** The person id of the direct report */
  personId: Scalars['ID'];
  /** The position title of the direct report */
  positionTitle: Scalars['String'];
  /** The preferred of first name of the direct report */
  preferredOrFirstName: Scalars['String'];
  /** The profile picture id of the direct report */
  profilePictureId?: Maybe<Scalars['ID']>;
};

export type JobTurnoverUpdateInput = {
  jobId: Scalars['ID'];
  turnoverReasonId?: InputMaybe<Scalars['ID']>;
  turnoverType: TurnoverType;
};

export type JobTurnoverUpdateResponse = {
  __typename?: 'JobTurnoverUpdateResponse';
  job?: Maybe<Job>;
};

export type JobUpdateInput = {
  /** The business entity to change to */
  businessEntityId?: InputMaybe<Scalars['ID']>;
  /** The business unit to change to */
  businessUnitId?: InputMaybe<Scalars['ID']>;
  customFieldValues?: InputMaybe<Array<JobCustomFieldValueInput>>;
  /** A description of why this job change was made */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the employment condition */
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  /** If the changes are temporary, the date they are effective too */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** The ID of the establishment position this job belongs to */
  establishmentId?: InputMaybe<Scalars['ID']>;
  /** The fte to change to */
  fte?: InputMaybe<Scalars['String']>;
  /** An id matching a predetermined list of job change reasons */
  jobChangeReasonId?: InputMaybe<Scalars['ID']>;
  /** The ID of the job to update */
  jobId: Scalars['ID'];
  /** The location to change to */
  locationId?: InputMaybe<Scalars['ID']>;
  /**
   * The new position title to change to, as a string.
   * If no matching position title, then a new one will be created if the user has the
   * settings.position_titles.manage permission.
   */
  name?: InputMaybe<Scalars['String']>;
  /** The pay grade to change to */
  payGradeId?: InputMaybe<Scalars['ID']>;
  /** The pay step that is being applied */
  payStepId?: InputMaybe<Scalars['ID']>;
  /** The ID of the performance framework this job belongs to */
  performanceFrameworkId?: InputMaybe<Scalars['ID']>;
  /** Deprecated. This field is unused. */
  personId?: InputMaybe<Scalars['ID']>;
  /** The new position title for the job, as an id */
  positionTitleId?: InputMaybe<Scalars['ID']>;
  /** The remuneration schedule to change to */
  remunerationSchedule?: InputMaybe<JobUpdateRemunerationInput>;
  /** Save job update as a draft if true */
  saveAsDraft?: InputMaybe<Scalars['Boolean']>;
  /** The date the changes are effective from, (will set to start of time if null) */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** The supervisor to change to */
  supervisorJobId?: InputMaybe<Scalars['ID']>;
  /** When true, the job_update_scheduled events will not be fired for this job update (defaults to false) */
  suppressEvents?: InputMaybe<Scalars['Boolean']>;
  /** The work class to change to */
  workClassId?: InputMaybe<Scalars['ID']>;
  /** Unused. This has no effect and has been replaced by workClassId. */
  workTypeId?: InputMaybe<Scalars['ID']>;
};

export type JobUpdateRemunerationInput = {
  /** Array of additions being applied to this rem schedule */
  additions?: InputMaybe<Array<InputMaybe<RemunerationComponentInput>>>;
  /** Array of additions being applied to this rem schedule */
  additionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Array of additions tot total being applied to this rem schedule */
  additionsToTotal?: InputMaybe<Array<RemunerationComponentInput>>;
  /** Base pay per unit of work (required if type is RATE) */
  baseAmountPerUnit?: InputMaybe<Scalars['Float']>;
  /** Base annual amount of pay (required if type is ANNUAL) */
  baseAnnualAmount?: InputMaybe<Scalars['Float']>;
  /** Array of breakdowns being applied to this rem schedule */
  breakdowns?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The currency id used for the base and total amounts on this rem schedule */
  currencyId?: InputMaybe<Scalars['ID']>;
  customFieldValues?: InputMaybe<Array<InputMaybe<RemunerationCustomFieldValueInput>>>;
  /** Array of deductions being applied to this rem schedule */
  deductions?: InputMaybe<Array<InputMaybe<RemunerationComponentInput>>>;
  /** Array of deductions being applied to this rem schedule */
  deductionsToBase?: InputMaybe<Array<RemunerationComponentInput>>;
  /** The employment condition id for this rem schedule */
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  /** The pay cycle id being used for this rem schedule (i.e. monthly, weekly etc) */
  payCycleId?: InputMaybe<Scalars['ID']>;
  /** The schedule type; i.e. Paid annually, or using an hourly rate. */
  type: RemunerationScheduleType;
  /**
   * How many units (i.e. hours worked) there are in this schedule's pay cycle.
   * Typically ~38 for fortnightly paid pay cycles, as an example.
   */
  unitsPerCycle?: InputMaybe<Scalars['Float']>;
};

export type JobUpdateRequestListInstance = {
  __typename?: 'JobUpdateRequestListInstance';
  approvalCount: Scalars['Int'];
  effectiveFrom: Scalars['DateTime'];
  id: Scalars['ID'];
  myApprovalStatus: JobUpdateRequestMyApprovalStatus;
  reason?: Maybe<Scalars['String']>;
  subjectJob: Job;
};

export enum JobUpdateRequestMyApprovalStatus {
  HAVE_APPROVED = 'HAVE_APPROVED',
  NOT_AN_APPROVER = 'NOT_AN_APPROVER',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  TURN_TO_APPROVE = 'TURN_TO_APPROVE',
  WAITING_FOR_OTHERS_TO_APPROVE = 'WAITING_FOR_OTHERS_TO_APPROVE'
}

export enum JobUpdateRequestSortDirection {
  APPROVAL_COUNT_ASCENDING = 'APPROVAL_COUNT_ASCENDING',
  APPROVAL_COUNT_DESCENDING = 'APPROVAL_COUNT_DESCENDING',
  EFFECTIVE_FROM_ASCENDING = 'EFFECTIVE_FROM_ASCENDING',
  EFFECTIVE_FROM_DESCENDING = 'EFFECTIVE_FROM_DESCENDING',
  POSITION_TITLE_ASCENDING = 'POSITION_TITLE_ASCENDING',
  POSITION_TITLE_DESCENDING = 'POSITION_TITLE_DESCENDING',
  SUBJECT_ASCENDING = 'SUBJECT_ASCENDING',
  SUBJECT_DESCENDING = 'SUBJECT_DESCENDING'
}

export enum JobUpdateRequestStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type JobUpdateResponse = {
  __typename?: 'JobUpdateResponse';
  /** Job business entities created through this process */
  createdJobBusinessEntities: Array<JobBusinessEntity>;
  /** Job business units created through this process */
  createdJobBusinessUnits: Array<JobBusinessUnit>;
  createdJobCustomFields: Array<CustomFieldValue>;
  /** Job employment conditions created through this process */
  createdJobEmploymentConditions: Array<JobEmploymentCondition>;
  /** Job establishment positions created through this process */
  createdJobEstablishments: Array<JobEstablishment>;
  /** Job ftes created through this process */
  createdJobFtes: Array<JobFTE>;
  /** Job locations created through this process */
  createdJobLocations: Array<JobLocation>;
  /** Job names created through this process */
  createdJobNames: Array<JobName>;
  /** Job pay grades created through this process */
  createdJobPayGrades: Array<JobPayGrade>;
  /** Job pay steps created through this process */
  createdJobPaySteps: Array<JobPayStep>;
  /** Job performance frameworks created through this process */
  createdJobPerformanceFrameworks: Array<JobPerformanceFramework>;
  /** Job rem schedules created through this process */
  createdJobRemunerationSchedules: Array<JobRemunerationSchedule>;
  /** Job supervisors created through this process */
  createdJobSupervisors: Array<JobSupervisor>;
  /** Job work classes created through this process */
  createdJobWorkClasses: Array<JobWorkClass>;
  /** Job work types created through this process */
  createdJobWorkTypes: Array<JobWorkType>;
  /** Job business entities deleted through this process */
  deletedJobBusinessEntities: Array<JobBusinessEntity>;
  /** Job business units deleted through this process */
  deletedJobBusinessUnits: Array<JobBusinessUnit>;
  deletedJobCustomFields: Array<CustomFieldValue>;
  /** Job employment conditions deleted through this process */
  deletedJobEmploymentConditions: Array<JobEmploymentCondition>;
  /** Job establishment positions deleted through this process */
  deletedJobEstablishments: Array<JobEstablishment>;
  /** Job ftes deleted through this process */
  deletedJobFtes: Array<JobFTE>;
  /** Job locations deleted through this process */
  deletedJobLocations: Array<JobLocation>;
  /** Job names deleted through this process */
  deletedJobNames: Array<JobName>;
  /** Job pay grades deleted through this process */
  deletedJobPayGrades: Array<JobPayGrade>;
  /** Job pay steps deleted through this process */
  deletedJobPaySteps: Array<JobPayStep>;
  /** Job performance frameworks deleted through this process */
  deletedJobPerformanceFrameworks: Array<JobPerformanceFramework>;
  /** Job rem schedules deleted through this process */
  deletedJobRemunerationSchedules: Array<JobRemunerationSchedule>;
  /** Job supervisors deleted through this process */
  deletedJobSupervisors: Array<JobSupervisor>;
  /** Job work classes deleted through this process */
  deletedJobWorkClasses: Array<JobWorkClass>;
  /** Job work types deleted through this process */
  deletedJobWorkTypes: Array<JobWorkType>;
  /** if the Job Business Entity after the changes is different to the one from before */
  hasJobBusinessEntityChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Business Unit after the changes is different to the one from before */
  hasJobBusinessUnitChanged?: Maybe<Scalars['Boolean']>;
  hasJobCustomFieldsChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Employment Condition after the changes is different to the one from before */
  hasJobEmploymentConditionChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Establishment Position after the changes is different to the one from before */
  hasJobEstablishmentChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Fte after the changes is different to the one from before */
  hasJobFteChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Location after the changes is different to the one from before */
  hasJobLocationChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Name after the changes is different to the one from before */
  hasJobNameChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Pay Grade after the changes is different to the one from before */
  hasJobPayGradeChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Pay Step after the changes is different to the one from before */
  hasJobPayStepChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Performance Framework after the changes is different to the one from before */
  hasJobPerformanceFrameworkChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Remuneration Schedule after the changes is different to the one from before */
  hasJobRemunerationScheduleChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Supervisor after the changes is different to the one from before */
  hasJobSupervisorChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Work Class after the changes is different to the one from before */
  hasJobWorkClassChanged?: Maybe<Scalars['Boolean']>;
  /** if the Job Work Type after the changes is different to the one from before */
  hasJobWorkTypeChanged?: Maybe<Scalars['Boolean']>;
  job: Job;
  /** The Job Business Entity after the changes */
  jobBusinessEntityAfter?: Maybe<JobBusinessEntity>;
  /** The Job Business Entity before the changes */
  jobBusinessEntityBefore?: Maybe<JobBusinessEntity>;
  /** The Job Business Unit after the changes */
  jobBusinessUnitAfter?: Maybe<JobBusinessUnit>;
  /** The Job Business Unit before the changes */
  jobBusinessUnitBefore?: Maybe<JobBusinessUnit>;
  jobCustomFieldsAfter: Array<CustomFieldValue>;
  jobCustomFieldsBefore: Array<CustomFieldValue>;
  /** The Job Employment Condition after the changes */
  jobEmploymentConditionAfter?: Maybe<JobEmploymentCondition>;
  /** The Job Employment Condition before the changes */
  jobEmploymentConditionBefore?: Maybe<JobEmploymentCondition>;
  /** The Job Establishment Position after the changes */
  jobEstablishmentAfter?: Maybe<JobEstablishment>;
  /** The Job Establishment Position before the changes */
  jobEstablishmentBefore?: Maybe<JobEstablishment>;
  /** The Job Fte after the changes */
  jobFteAfter?: Maybe<JobFTE>;
  /** The Job Fte before the changes */
  jobFteBefore?: Maybe<JobFTE>;
  /** The Job Location after the changes */
  jobLocationAfter?: Maybe<JobLocation>;
  /** The Job Location before the changes */
  jobLocationBefore?: Maybe<JobLocation>;
  /** The Job Name after the changes */
  jobNameAfter?: Maybe<JobName>;
  /** The Job Name before the changes */
  jobNameBefore?: Maybe<JobName>;
  /** The Job Pay Grade after the changes */
  jobPayGradeAfter?: Maybe<JobPayGrade>;
  /** The Job Pay Grade before the changes */
  jobPayGradeBefore?: Maybe<JobPayGrade>;
  /** The Job Pay Step after the changes */
  jobPayStepAfter?: Maybe<JobPayStep>;
  /** The Job Pay Step before the changes */
  jobPayStepBefore?: Maybe<JobPayStep>;
  /** The Job Performance Framework after the changes */
  jobPerformanceFrameworkAfter?: Maybe<JobPerformanceFramework>;
  /** The Job Performance Framework before the changes */
  jobPerformanceFrameworkBefore?: Maybe<JobPerformanceFramework>;
  /** The Job Remuneration Schedule after the changes */
  jobRemunerationScheduleAfter?: Maybe<JobRemunerationSchedule>;
  /** The Job Remuneration Schedule before the changes */
  jobRemunerationScheduleBefore?: Maybe<JobRemunerationSchedule>;
  /** The Job Supervisor after the changes */
  jobSupervisorAfter?: Maybe<JobSupervisor>;
  /** The Job Supervisor before the changes */
  jobSupervisorBefore?: Maybe<JobSupervisor>;
  /** The Job Work Class after the changes */
  jobWorkClassAfter?: Maybe<JobWorkClass>;
  /** The Job Work Class before the changes */
  jobWorkClassBefore?: Maybe<JobWorkClass>;
  /** The Job Work Type after the changes */
  jobWorkTypeAfter?: Maybe<JobWorkType>;
  /** The Job Work Type before the changes */
  jobWorkTypeBefore?: Maybe<JobWorkType>;
  /** Job business entities updated through this process */
  updatedJobBusinessEntities: Array<JobBusinessEntity>;
  /** Job business units updated through this process */
  updatedJobBusinessUnits: Array<JobBusinessUnit>;
  updatedJobCustomFields: Array<CustomFieldValue>;
  /** Job employment conditions updated through this process */
  updatedJobEmploymentConditions: Array<JobEmploymentCondition>;
  /** Job establishment positions updated through this process */
  updatedJobEstablishments: Array<JobEstablishment>;
  /** Job ftes updated through this process */
  updatedJobFtes: Array<JobFTE>;
  /** Job locations updated through this process */
  updatedJobLocations: Array<JobLocation>;
  /** Job names updated through this process */
  updatedJobNames: Array<JobName>;
  /** Job pay grades updated through this process */
  updatedJobPayGrades: Array<JobPayGrade>;
  /** Job pay steps updated through this process */
  updatedJobPaySteps: Array<JobPayStep>;
  /** Job performance frameworks updated through this process */
  updatedJobPerformanceFrameworks: Array<JobPerformanceFramework>;
  /** Job rem schedules updated through this process */
  updatedJobRemunerationSchedules: Array<JobRemunerationSchedule>;
  /** Job supervisors updated through this process */
  updatedJobSupervisors: Array<JobSupervisor>;
  /** Job work classes created through this process */
  updatedJobWorkClasses: Array<JobWorkClass>;
  /** Job work types created through this process */
  updatedJobWorkTypes: Array<JobWorkType>;
  /** The ID of the workflow event to trigger */
  workflowEventId?: Maybe<Scalars['ID']>;
  /** The ids of the workflow event to trigger */
  workflowEventIds?: Maybe<Array<Scalars['ID']>>;
  /**
   * Redirect url for performing workflows; temporary solution until this is entirely ported to SPA
   * @deprecated use frontend redirect chain and workflowEventId
   */
  workflowRedirectUrl?: Maybe<Scalars['String']>;
};


export type JobUpdateResponsecreatedJobCustomFieldsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobUpdateResponsedeletedJobCustomFieldsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobUpdateResponsejobCustomFieldsAfterArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobUpdateResponsejobCustomFieldsBeforeArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type JobUpdateResponseupdatedJobCustomFieldsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type JobWorkClass = JobAttribute & {
  __typename?: 'JobWorkClass';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The work class of the job */
  workClass: WorkClass;
};

export type JobWorkType = JobAttribute & {
  __typename?: 'JobWorkType';
  /** The end date of the attribute, or null for end of job/time */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The start date of the attribute, or null for start of job */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The work type of the job */
  workType: WorkType;
};

export type JsonPulseFilterResponse = {
  __typename?: 'JsonPulseFilterResponse';
  json?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

/**
 * This type should not exist, as it\'s the same as 'JobLeave'. It was created in error and is now being relied upon
 * for some of the compliance leave features. Once those features are ported to use `Leave`, this type can be removed.
 */
export type Leave = {
  __typename?: 'Leave';
  /** The end date of the attribute, or null for end of job/time */
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** If the end date has been confirmed - if this is false, the end date is the expected end date */
  isEndDateConfirmed: Scalars['Boolean'];
  /** The job associated with leave record */
  job: Job;
  /** The job id associated with leave record */
  jobId: Scalars['ID'];
  /** The associated leave type this leave falls under */
  leaveType: LeaveType;
  /** The id of associated leave type for this leave record */
  leaveTypeId: Scalars['String'];
  /** The start date of the attribute, or null for start of job */
  startDate: Scalars['DateTime'];
};

export type LeaveFilters = {
  isEndDateConfirmed?: InputMaybe<BooleanFilter>;
  leaveTypeId?: InputMaybe<ValueFilter>;
};

export type LeaveType = {
  __typename?: 'LeaveType';
  id: Scalars['ID'];
  /** Whether the leave type is enabled or not */
  isEnabled: Scalars['Boolean'];
  /** If the leave type is enabled or not */
  isInUse: Scalars['Boolean'];
  /** How many leave records are using this leave type */
  leaveCount: Scalars['Int'];
  /** Name of the leaveType */
  name: Scalars['String'];
  /** Order to display the leaveType */
  sortOrder: Scalars['Int'];
};

export type LeaveTypeCreateInput = {
  name: Scalars['String'];
};

export type LeaveTypeCreateResponse = {
  __typename?: 'LeaveTypeCreateResponse';
  leaveType: LeaveType;
};

export type LeaveTypeDeleteInput = {
  id: Scalars['ID'];
};

export type LeaveTypeDeleteResponse = {
  __typename?: 'LeaveTypeDeleteResponse';
  id: Scalars['ID'];
};

export type LeaveTypeSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LeaveTypeUpdateInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LeaveTypeUpdateResponse = {
  __typename?: 'LeaveTypeUpdateResponse';
  leaveType: LeaveType;
};

export enum LevityLevel {
  FUN = 'FUN',
  STANDARD = 'STANDARD'
}

/** Allowed column names for the `orderBy` argument on the query `listAllContracts`. */
export enum ListAllContractsOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  DERIVED_DISPLAY_NAME = 'DERIVED_DISPLAY_NAME',
  EFFECTIVE_FROM = 'EFFECTIVE_FROM',
  NAME = 'NAME'
}

/** Order by clause for the `orderBy` argument on the query `listAllContracts`. */
export type ListAllContractsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListAllContractsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on the query `listAllJobs`. */
export enum ListAllJobsOrderByColumn {
  CREATED_AT = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listAllJobs`. */
export type ListAllJobsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListAllJobsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on the query `listAllTrainings`. */
export enum ListAllTrainingsOrderByColumn {
  CREATED_AT = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listAllTrainings`. */
export type ListAllTrainingsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListAllTrainingsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListCompensationPlannerPlanV2 = {
  __typename?: 'ListCompensationPlannerPlanV2';
  /** The effective start date of the plan */
  effectiveStartDate: Scalars['DateTime'];
  /** Plan id */
  id: Scalars['ID'];
  /** When the plan was last synced */
  lastSyncedAt: Scalars['DateTime'];
  /** Plan name */
  name: Scalars['String'];
};

export enum ListCompensationPlannerPlansOrderOptions {
  EFFECTIVE_START_DATE_ASCENDING = 'EFFECTIVE_START_DATE_ASCENDING',
  EFFECTIVE_START_DATE_DESCENDING = 'EFFECTIVE_START_DATE_DESCENDING',
  LAST_SYNCED_AT_ASCENDING = 'LAST_SYNCED_AT_ASCENDING',
  LAST_SYNCED_AT_DESCENDING = 'LAST_SYNCED_AT_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING'
}

export type ListCompensationPlannerPlansResponseV2 = PaginationResponse & {
  __typename?: 'ListCompensationPlannerPlansResponseV2';
  /** The paginated records */
  compensationPlannerPlans: Array<ListCompensationPlannerPlanV2>;
  /** Count of records on current page */
  count: Scalars['Int'];
  /** Current page */
  currentPage: Scalars['Int'];
  /** Are there more pages from the current page */
  hasMore: Scalars['Boolean'];
  /** Number of record per page */
  perPage: Scalars['Int'];
  /** Total records */
  total: Scalars['Int'];
  /** Total number of pages */
  totalPages: Scalars['Int'];
};

export type ListEventCalendarForMonthInput = {
  filters?: InputMaybe<EventCalendarPersonFilters>;
  month: Scalars['Int'];
  year: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on the query `listIntegrationLogs`. */
export enum ListIntegrationLogsOrderByColumn {
  OCCURRED_AT = 'OCCURRED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listIntegrationLogs`. */
export type ListIntegrationLogsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListIntegrationLogsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListJobUpdateRequestResponse = {
  __typename?: 'ListJobUpdateRequestResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  jobUpdateRequestStatus: JobUpdateRequestStatus;
  jobUpdateRequests: Array<JobUpdateRequestListInstance>;
  perPage: Scalars['Int'];
  sort?: Maybe<Array<JobUpdateRequestSortDirection>>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ListMyDirectReportsForDashboardResponse = {
  __typename?: 'ListMyDirectReportsForDashboardResponse';
  activeDirectReports: Array<DashboardDirectReport>;
  directReports: Array<DashboardDirectReport>;
  leavingDirectReports: Array<DashboardDirectReport>;
  leftDirectReports: Array<DashboardDirectReport>;
  startingDirectReports: Array<DashboardDirectReport>;
};

export type ListPaginatedCompensationPlannerPlansResponse = PaginationResponse & {
  __typename?: 'ListPaginatedCompensationPlannerPlansResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlans: Array<CompensationPlannerPlan>;
  /** @deprecated Moved to V2 model structure */
  count: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  currentPage: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  hasMore: Scalars['Boolean'];
  /** @deprecated Moved to V2 model structure */
  perPage: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  total: Scalars['Int'];
  /** @deprecated Moved to V2 model structure */
  totalPages: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedContracts`. */
export enum ListPaginatedContractsOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  DERIVED_DISPLAY_NAME = 'DERIVED_DISPLAY_NAME',
  EFFECTIVE_FROM = 'EFFECTIVE_FROM',
  NAME = 'NAME'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedContracts`. */
export type ListPaginatedContractsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedContractsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedFormDesignsResponse = {
  __typename?: 'ListPaginatedFormDesignsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  formDesignModels: Array<FormDesignDeferrerdUnion>;
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum ListPaginatedFormDesignsSortDirection {
  CREATED_AT_ASCENDING = 'CREATED_AT_ASCENDING',
  CREATED_AT_DESCENDING = 'CREATED_AT_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
  UPDATED_AT_ASCENDING = 'UPDATED_AT_ASCENDING',
  UPDATED_AT_DESCENDING = 'UPDATED_AT_DESCENDING'
}

/** Allowed column names for the `orderBy` argument on the query `listPaginatedIntegrationLogs`. */
export enum ListPaginatedIntegrationLogsOrderByColumn {
  OCCURRED_AT = 'OCCURRED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedIntegrationLogs`. */
export type ListPaginatedIntegrationLogsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedIntegrationLogsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedJobsJobNameAsAt = {
  /** Date to filter to; defaults to the current time */
  asAt?: InputMaybe<Scalars['DateTime']>;
  /** List of names to filter by */
  jobName: Array<Scalars['String']>;
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedJobs`. */
export enum ListPaginatedJobsOrderByColumn {
  CREATED_AT = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedJobs`. */
export type ListPaginatedJobsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedJobsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedOnboardingSessionsResponse = PaginationResponse & {
  __typename?: 'ListPaginatedOnboardingSessionsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  onboardingSessions: Array<OnboardingSession>;
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ListPaginatedOnboardingTemplateResponse = PaginationResponse & {
  __typename?: 'ListPaginatedOnboardingTemplateResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  onboardingTemplates: Array<OnboardingTemplate>;
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ListPaginatedPeopleAndJobsWithoutOnboardingResponse = PaginationResponse & {
  __typename?: 'ListPaginatedPeopleAndJobsWithoutOnboardingResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  result: Array<PeopleJobsWithoutOnboarding>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedPeople`. */
export enum ListPaginatedPeopleOrderByColumn {
  CREATED_AT = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedPeople`. */
export type ListPaginatedPeopleOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedPeopleOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedPermissionsResponse = {
  __typename?: 'ListPaginatedPermissionsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  permissions: Array<Permission>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedQueuedPulses`. */
export enum ListPaginatedQueuedPulsesOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  SEND_DATE = 'SEND_DATE'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedQueuedPulses`. */
export type ListPaginatedQueuedPulsesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedQueuedPulsesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedScheduledFormsResponse = {
  __typename?: 'ListPaginatedScheduledFormsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  scheduledForms: Array<FormComplianceScheduledForm>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedTrainings`. */
export enum ListPaginatedTrainingsOrderByColumn {
  CREATED_AT = 'CREATED_AT'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedTrainings`. */
export type ListPaginatedTrainingsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedTrainingsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type ListPaginatedUpcomingPulseModelsResponse = PaginationResponse & {
  __typename?: 'ListPaginatedUpcomingPulseModelsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  pulseModels: Array<PulseModelUnion>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ListPaginatedUsersForPermissionGroupResponse = {
  __typename?: 'ListPaginatedUsersForPermissionGroupResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
  users: Array<UserDetails>;
};

/** Allowed column names for the `orderBy` argument on the query `listPaginatedUsers`. */
export enum ListPaginatedUsersOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  LAST_LOGGED_IN_AT = 'LAST_LOGGED_IN_AT',
  USERNAME = 'USERNAME'
}

/** Order by clause for the `orderBy` argument on the query `listPaginatedUsers`. */
export type ListPaginatedUsersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  field: ListPaginatedUsersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type Location = {
  __typename?: 'Location';
  /** The address of the location */
  address?: Maybe<Scalars['String']>;
  /** The count of direct children this location has */
  childCount: Scalars['Int'];
  /** An optional code that relates to the location */
  code?: Maybe<Scalars['String']>;
  customFieldValues: Array<CustomFieldValue>;
  id: Scalars['ID'];
  /** Whether the location is currently enabled (for dropdown lists) */
  isEnabled: Scalars['Boolean'];
  /** Whether the location is no location */
  isNoLocation: Scalars['Boolean'];
  /** The count of job currently using this location */
  jobCount: Scalars['Int'];
  /** The display name of the location */
  name: Scalars['String'];
  /** The business location which is above this location in the hierarchy, or null if this is a top-level location. */
  parentLocation?: Maybe<Location>;
  representatives: Array<Representative>;
};


export type LocationcustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type LocationCreateInput = {
  /** The address of the location */
  address?: InputMaybe<Scalars['String']>;
  /** An optional code that relates to the location */
  code?: InputMaybe<Scalars['String']>;
  /** Custom field values for location */
  customFieldValues?: InputMaybe<Array<InputMaybe<LocationCustomFieldValueInput>>>;
  /** optional flag to mark the location as enabled/disabled (defaults to true) */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The display name of the location */
  name: Scalars['String'];
  /** The location which is above this location in the hierarchy, or null if this is a top-level location. */
  parentLocation?: InputMaybe<Scalars['ID']>;
  /** An array of the representatives on the location and their associated person */
  representatives?: InputMaybe<Array<LocationRepresentativeInput>>;
};

export type LocationCreateResponse = {
  __typename?: 'LocationCreateResponse';
  /** The location that has been created. */
  location: Location;
};

export type LocationCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type LocationDeleteResponse = {
  __typename?: 'LocationDeleteResponse';
  /** The ID of the location that was deleted */
  id: Scalars['ID'];
};

export type LocationFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type LocationRepresentativeInput = {
  personId?: InputMaybe<Scalars['ID']>;
  representativeTypeId: Scalars['ID'];
};

export type LocationUpdateInput = {
  /** The address of the location */
  address?: InputMaybe<Scalars['String']>;
  /** An optional code that relates to the location */
  code?: InputMaybe<Scalars['String']>;
  /** Custom field values for location */
  customFieldValues?: InputMaybe<Array<InputMaybe<LocationCustomFieldValueInput>>>;
  id: Scalars['ID'];
  /** Whether the location is currently enabled (for dropdown lists) */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The display name of the location */
  name?: InputMaybe<Scalars['String']>;
  /** The location which is above this location in the hierarchy, or null if this is a top-level location. */
  parentLocation?: InputMaybe<Scalars['ID']>;
  /** An array of the representatives on the location and their associated person */
  representatives?: InputMaybe<Array<LocationRepresentativeInput>>;
};

export type LocationUpdateResponse = {
  __typename?: 'LocationUpdateResponse';
  /** The location that has been created. */
  location: Location;
};

export type ManagerAccess = {
  __typename?: 'ManagerAccess';
  excludedUsers: Array<User>;
  /** A dimension/ids pair specify the filters applied to the manager list */
  filters: Scalars['JSONObject'];
  /** A key/value pair object showing whether the user has permission to view a particular page */
  pages: Scalars['JSONObject'];
};

export type MarkOnboardingStepAsCompleteInput = {
  /** The job of the step to mark as complete. This is to support the case of multiple jobs */
  jobId?: InputMaybe<Scalars['ID']>;
  /** The onboarding step type to be marked as complete */
  onboardingStepTypeId: Scalars['ID'];
  /**
   * Flag to skip validation for checking if the requirements to complete a step are met.
   * Useful when skipping a step. If not provided validation is performed
   */
  skipRequirementValidation?: InputMaybe<Scalars['Boolean']>;
};

export type MarkOnboardingStepAsCompleteResponse = {
  __typename?: 'MarkOnboardingStepAsCompleteResponse';
  success: Scalars['Boolean'];
};

export type MedicalCondition = {
  __typename?: 'MedicalCondition';
  /** The name/description of the medical condition */
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If the medical condition is public or private */
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type MedicalConditionCreateInput = {
  /** The name/description of the medical condition */
  body?: InputMaybe<Scalars['String']>;
  /** If the medical condition is public or private */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the person whom the medical condition belongs to */
  personId: Scalars['ID'];
};

export type MedicalConditionCreateResponse = {
  __typename?: 'MedicalConditionCreateResponse';
  /** The medical condition that has been created */
  medicalCondition: MedicalCondition;
  /** The person for whom the medical condition is created */
  person: Person;
};

export type MedicalConditionDeleteResponse = {
  __typename?: 'MedicalConditionDeleteResponse';
  /** The ID of the medical condition that was deleted */
  id: Scalars['ID'];
  /** The person who owned the medical condition that was deleted. Used to reload the list of medical conditions */
  person: Person;
};

export type MedicalConditionUpdateInput = {
  /** The name/description of the medical condition to be updated */
  body?: InputMaybe<Scalars['String']>;
  /** The ID of the medical condition to be updated. */
  id: Scalars['ID'];
  /** The medical condition to be updated is public or private */
  isPublic?: InputMaybe<Scalars['Boolean']>;
};

export type MedicalConditionUpdateResponse = {
  __typename?: 'MedicalConditionUpdateResponse';
  /** The medical condition that is updated */
  medicalCondition: MedicalCondition;
  /** The person for whom the medical condition is updated */
  person: Person;
};

export type MessageSchedule = {
  __typename?: 'MessageSchedule';
  /** Body of message */
  body: Scalars['String'];
  /** Time bounced */
  bouncedAt?: Maybe<Scalars['DateTime']>;
  /** Time complained */
  complaintAt?: Maybe<Scalars['DateTime']>;
  /** Time delivered */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  /** Specific errors that occurred sending the message */
  errors?: Maybe<Array<Scalars['String']>>;
  /** If there is any issue sending the scheduled message */
  hasError: Scalars['Boolean'];
  /** Message schedule id */
  id: Scalars['ID'];
  /** Time added to queue */
  queuedAt: Scalars['DateTime'];
  /** The Time to send this Notification */
  sendAt: Scalars['DateTime'];
  /** Time sent */
  sentAt?: Maybe<Scalars['DateTime']>;
  /** SES errors that occurred when sending the message */
  sesErrors?: Maybe<SesErrors>;
  /** Subject of message */
  subject: Scalars['String'];
  /** Recipient of message */
  to?: Maybe<Scalars['String']>;
  /** Time last updated */
  updatedAt: Scalars['DateTime'];
};

export type MetricResponseDefinitionInput = {
  /** form_design */
  formDesignId?: InputMaybe<Scalars['ID']>;
  formFieldId?: InputMaybe<Scalars['ID']>;
  /** library */
  formItemId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type MetricResponseDefinitionsUnion = FormDesignFieldResponseType | FormLibraryFieldResponseType;

export type Module = {
  __typename?: 'Module';
  /** the id of the module */
  id: Scalars['ID'];
  /**
   * this is used by the SPA to tell which modules are enabled for the current logged in user
   * this can be different to isEnabledForTenant as superusers have access to modules that normal users may not
   */
  isEnabledForCurrentUser: Scalars['Boolean'];
  /** this is used by the plans page to list out which modules are enabled/disabled for the tenant */
  isEnabledForTenant: Scalars['Boolean'];
  /** the human-readable name of the module, for example: Workflow Engine */
  name: Scalars['String'];
  /** the slug of the module, for example WORKFLOW_ENGINE */
  slug: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  accessKey?: Maybe<AccessKeyMutation>;
  addressCreate?: Maybe<AddressCreateResponse>;
  addressDelete: AddressDeleteResponse;
  addressUpdate?: Maybe<AddressUpdateResponse>;
  analyticsConfiguration?: Maybe<AnalyticsConfigurationMutation>;
  analyticsPermission?: Maybe<AnalyticsPermissionMutation>;
  analyticsSettings?: Maybe<AnalyticsSettingsMutation>;
  archiveAnonymousFormDesign?: Maybe<FormDesign>;
  archiveFormDesign?: Maybe<FormDesign>;
  auth?: Maybe<AuthMutation>;
  /**
   * IMPORTANT NOTE
   * This resolver should not be used in production until the caching
   * implementation in the PrivateKeyAuthenticationService has been resolved.
   * See PrivateKeyAuthenticationService->storeInCache for more details.
   */
  authenticateForPrivateKey?: Maybe<PrivateKeyStatusResponse>;
  /**
   * Removes this action as well as any sub actions that require this action.
   *
   * This occurs recursively, so all actions that require those actions will be deleted too.
   * Actions can\'t be deleted if they are not UNPROCESSED or FAILED (so completed actions can\'t be deleted).
   * Actions can\'t be deleted if the overall import is not UNPROCESSED, PAUSED or FAILED.
   * All actions from this action onward will be reindexed.
   */
  bulkDataImportActionDelete: BulkDataImportActionDeleteResponse;
  /** Creates a new bulk data import with UNPROCESSED status, null completed, no actions. */
  bulkDataImportCreate: BulkDataImportCreateResponse;
  /**
   * Sets a bulk data import as deleted.
   * Imports can\'t be deleted if they are not UNPROCESSED.
   */
  bulkDataImportDelete: BulkDataImportDeleteResponse;
  /**
   * Deletes all the actions on an import that are UNPROCESSED or FAILED.
   * Can only be performed if the overall import is UNPROCESSED, PAUSED or FAILED.
   */
  bulkDataImportDeletePendingActions: BulkDataImportDeletePendingActionsResponse;
  /**
   * Given a previously uploaded CSV id for a data import, validate that it is correct and if so,
   * list actions that will be generated.
   * Returns the actions that were added to the overall list, or any validation errors if they occurred.
   * Can only be performed on imports in UNPROCESSED, PAUSED or FAILED states.
   *
   * Note: This can be slow as all existing actions may have their indexes updated to change order, in addition to
   * having their requirements updated. Note that no existing actions will be removed.
   *
   * When in dry run mode, the actions will not actually be saved.
   */
  bulkDataImportGenerateActionsForUpload: BulkDataImportGenerateActionsForUploadResponse;
  /** Generate a presigned url which CSVs can be uploaded to */
  bulkDataImportGeneratePresignedUrl: BulkDataImportGeneratePresignedUrlResponse;
  /**
   * Pause a currently processing bulk data import.
   * Can only be performed on PROCESSING data imports.
   * This will set the status to PAUSED, then fire a BulkDataImportProcessingPaused event.
   *
   * Because the actual processing is occurring on the queue worker separate to the main request, this will not pause
   * instantly. There may be an action or two performed in between this occurring and the processor checking the
   * bulk data import status.
   */
  bulkDataImportProcessPause: BulkDataImportProcessPauseResponse;
  /**
   * Begin/continue an bulk data import process.
   * Can only be performed for FAILED/PAUSED/UNPROCESSED data imports.
   * Will set the import to PROCESSING, then fire a BulkDataImportProcessingNeeded event.
   *
   * The listener for these events will first check if the data import is in the processing state, then will
   * pull the next pending action, perform it, then check again. It will repeat this for a certain amount of actions
   * (around 50 or so) and then fire another BulkDataImportProcessingNeeded event.
   *
   * Once no pending actions remain, the bulk import will be marked as COMPLETED. and a BulkDataImportProcessingCompleted
   * event will be fired.
   *
   * On the other hand, if an action fails, the action will have its failure message set, and the overall import
   * will be set to FAILED, with a BulkDataImportProcessingFailed event being fired.
   */
  bulkDataImportProcessStart: BulkDataImportProcessStartResponse;
  bulkDeleteForm?: Maybe<BulkDeleteFormResponse>;
  bulkDeleteScheduledForm?: Maybe<BulkDeleteScheduledFormResponse>;
  /** @deprecated Use bulkDeleteForm instead */
  bulkDeleteTask?: Maybe<BulkDeleteTaskResponse>;
  bulkIgnoreForm?: Maybe<BulkIgnoreFormResponse>;
  /** @deprecated Use bulkIgnoreForm instead */
  bulkIgnoreTask?: Maybe<BulkIgnoreTaskResponse>;
  bulkResendForm?: Maybe<BulkResendFormResponse>;
  /** @deprecated Use bulkResendForm instead */
  bulkResendTask?: Maybe<BulkResendTaskResponse>;
  bulkUserCreate?: Maybe<BulkUserCreateResponse>;
  /** Creates a new business entity */
  businessEntityCreate: BusinessEntityCreateResponse;
  /** Creates a new business entity */
  businessEntityCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes the provided business entity */
  businessEntityDelete: BusinessEntityDeleteResponse;
  /** Deletes the provided business entity */
  businessEntityDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing business entity */
  businessEntityUpdate: BusinessEntityUpdateResponse;
  /** Updates an existing business entity */
  businessEntityUpdateQueued?: Maybe<QueuedMutationResponse>;
  businessPerformanceConfigurationSet: BusinessPerformanceConfigurationSetResponse;
  businessPerformanceMetricBulkSet: BusinessPerformanceMetricBulkSetResponse;
  businessPerformanceMetricCreate: BusinessPerformanceMetricCreateResponse;
  businessPerformanceMetricDelete: BusinessPerformanceMetricDeleteResponse;
  businessPerformanceMetricUpdate: BusinessPerformanceMetricUpdateResponse;
  /** Creates a new business unit */
  businessUnitCreate: BusinessUnitCreateResponse;
  /** Creates a new business unit */
  businessUnitCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes the provided business unit */
  businessUnitDelete: BusinessUnitDeleteResponse;
  /** Deletes the provided business unit */
  businessUnitDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing business unit */
  businessUnitUpdate: BusinessUnitUpdateResponse;
  /** Updates an existing business unit */
  businessUnitUpdateQueued?: Maybe<QueuedMutationResponse>;
  changePassword?: Maybe<ChangePasswordResponse>;
  closeAnonymousPulseSubmissions?: Maybe<Pulse>;
  contractCreate?: Maybe<ContractCreateResponse>;
  /** creates a new contract issue batch */
  contractIssueBatchCreate: ContractIssueBatchCreateResponse;
  contractIssueBatchItemDelete: ContractIssueBatchItemDeleteResponse;
  contractIssueBatchItemUpdate: ContractIssueBatchItemUpdateResponse;
  /** updates a contract issue batch */
  contractIssueBatchUpdate: ContractIssueBatchUpdateResponse;
  contractPersonSignatureCreate: ContractPersonSignatureResponse;
  contractPersonSignatureDelete?: Maybe<ContractPersonSignatureDeleteResponse>;
  contractPersonSignatureUpdate: ContractPersonSignatureResponse;
  contractSignatureUpdate?: Maybe<ContractSignatureUpdateResponse>;
  contractTemplateCreate?: Maybe<ContractTemplateCreateResponse>;
  contractTemplateDelete?: Maybe<ContractTemplateDeleteResponse>;
  contractTemplateUpdate?: Maybe<ContractTemplateUpdateResponse>;
  contractTemplateV2Create?: Maybe<ContractTemplateV2CreateResponse>;
  contractTemplateV2Update?: Maybe<ContractTemplateV2UpdateResponse>;
  createAnonymousFormDesign?: Maybe<AnonymousFormDesignResponse>;
  createAnonymousPulseModel?: Maybe<PulseModelUnion>;
  /** @deprecated Use CreateCompensationPlanV2Input instead */
  createCompensationPlan?: Maybe<CompensationPlan>;
  /** Create Compensation Planner with Name and Budget */
  createCompensationPlanV2?: Maybe<CompensationPlan>;
  /** @deprecated Moved to V2 model structure */
  createCompensationPlannerPlan?: Maybe<CreateCompensationPlannerPlanResponse>;
  /** Create a new plan */
  createCompensationPlannerPlanV2?: Maybe<CreateCompensationPlannerPlanResponseV2>;
  /** Creates a custom field definition with the given input */
  createCustomFieldDefinition?: Maybe<CreateCustomFieldResponse>;
  /** Create a new folder for documentation */
  createDocumentFolder?: Maybe<DocumentFolderCreateResponse>;
  createFormDesign?: Maybe<CreateFormDesignResponse>;
  createOnboardingSession: OnboardingSession;
  createPulseModel?: Maybe<PulseModelUnion>;
  /** Creates a renewal instance for a given qualification template */
  createQualificationInstanceRenewal: CreateQualificationInstanceRenewalResponseV2;
  createWorkflowInstance?: Maybe<WorkflowInstance>;
  customFieldCategoryCreate: CustomFieldCategoryCreateResponse;
  customFieldCategoryCreateQueued?: Maybe<QueuedMutationResponse>;
  customFieldCategoryDelete: CustomFieldCategoryDeleteResponse;
  customFieldCategoryDeleteQueued?: Maybe<QueuedMutationResponse>;
  customFieldCategoryEdit: CustomFieldCategoryEditResponse;
  customFieldCategoryEditQueued?: Maybe<QueuedMutationResponse>;
  customFieldCategoryFromTo: CustomFieldCategoryFromToResponse;
  /** @deprecated does not work with anything but person, use customFieldCategoryFromTo instead */
  customFieldCategoryReorder: CustomFieldCategoryReorderResponse;
  /** Reorder custom field definitions */
  customFieldDefinitionReorder: CustomFieldDefinitionReorderResponse;
  customFieldTemplateCategoryCreate: CustomFieldTemplateCategoryCreateResponse;
  customFieldTemplateCategoryCreateQueued?: Maybe<QueuedMutationResponse>;
  customFieldTemplateCategoryEdit: CustomFieldTemplateCategoryEditResponse;
  customFieldTemplateCategoryEditQueued?: Maybe<QueuedMutationResponse>;
  customFieldTemplateCreate: CustomFieldTemplateCreateResponse;
  customFieldTemplateCreateQueued?: Maybe<QueuedMutationResponse>;
  customFieldTemplateUpdate: CustomFieldTemplateUpdateResponse;
  customFieldTemplateUpdateQueued?: Maybe<QueuedMutationResponse>;
  /** Creates a new defaultRemunerationComponent */
  defaultRemunerationComponentCreate: DefaultRemunerationComponentCreateResponse;
  /** Creates a new defaultRemunerationComponent */
  defaultRemunerationComponentCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes the provided defaultRemunerationComponent */
  defaultRemunerationComponentDelete: DefaultRemunerationComponentDeleteResponse;
  /** Deletes the provided defaultRemunerationComponent */
  defaultRemunerationComponentDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing defaultRemunerationComponent */
  defaultRemunerationComponentUpdate: DefaultRemunerationComponentUpdateResponse;
  /** Updates an existing defaultRemunerationComponent */
  defaultRemunerationComponentUpdateQueued?: Maybe<QueuedMutationResponse>;
  deleteAnonymousFormDesign?: Maybe<AnonymousFormDesignDeleteResponse>;
  deleteCompensationPlan?: Maybe<CompensationPlan>;
  /** @deprecated Moved to V2 model structure */
  deleteCompensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
  /** Delete a plan */
  deleteCompensationPlannerPlanV2?: Maybe<DeleteCompensationPlannerPlanResponse>;
  deleteContract?: Maybe<DeleteContractResponse>;
  /** Deletes a custom field definition with the given input */
  deleteCustomFieldDefinition?: Maybe<DeleteCustomFieldResponse>;
  /** Delete a folder */
  deleteDocumentFolder?: Maybe<DocumentFolderDeleteResponse>;
  deleteForm?: Maybe<DeleteFormResponse>;
  deleteOnboardingSession: DeleteOnboardingSessionResponse;
  deletePermissionGroup?: Maybe<PermissionGroupDeleteResponse>;
  deletePersonProfileCoverPicture?: Maybe<DeletePersonProfileCoverPictureResponse>;
  deletePersonProfilePicture?: Maybe<DeletePersonProfilePictureResponse>;
  deletePulse?: Maybe<DeletePulseResponse>;
  deletePulseRecurrence?: Maybe<DeletePulseRecurrenceResponse>;
  deleteScheduledForm?: Maybe<DeleteScheduledFormResponse>;
  /** @deprecated Use deleteForm instead */
  deleteTask?: Maybe<DeleteTaskResponse>;
  diaryNoteCategoryCreate?: Maybe<DiaryNoteCategoryCreateResponse>;
  diaryNoteCategoryDelete?: Maybe<DiaryNoteCategoryDeleteResponse>;
  diaryNoteCategoryUpdate?: Maybe<DiaryNoteCategoryUpdateResponse>;
  diaryNoteCreateStandalone?: Maybe<DiaryNoteCreateStandaloneResponse>;
  diaryNoteDelete?: Maybe<DiaryNoteDeleteResponse>;
  diaryNoteDocumentCreate: DiaryNoteDocumentCreateResponse;
  diaryNoteDocumentDelete: DiaryNoteDocumentDeleteResponse;
  diaryNoteDocumentUpdate: DiaryNoteDocumentUpdateResponse;
  diaryNoteUpdateStandalone?: Maybe<DiaryNoteUpdateStandaloneResponse>;
  disableMFA?: Maybe<DisableMFAResponse>;
  /** Mutation for updating with the submit permission to enforce status conditions */
  draftQualificationInstanceUpdate?: Maybe<QualificationInstanceUpdateResponse>;
  duplicateAnonymousFormDesign?: Maybe<AnonymousFormDesign>;
  duplicateCompensationPlan?: Maybe<DeleteCompensationPlanResponse>;
  duplicateFormDesign?: Maybe<FormDesign>;
  editForm?: Maybe<EditFormResponse>;
  /** @deprecated Use editForm instead */
  editTask?: Maybe<EditTaskResponse>;
  /** Creates a new email address for the user */
  emailAddressCreate: EmailAddressCreateResponse;
  /** Deletes the provided email address */
  emailAddressDelete: EmailAddressDeleteResponse;
  /** Update a new email address for the user */
  emailAddressUpdate: EmailAddressUpdateResponse;
  /** Create an employment condition */
  employmentConditionCreate: EmploymentConditionCreateResponse;
  /** Create an employment condition */
  employmentConditionCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Delete an existing employment Condition. cannot be performed if the employment condition is in use. */
  employmentConditionDelete: EmploymentConditionDeleteResponse;
  /** Delete an existing employment Condition. cannot be performed if the employment condition is in use. */
  employmentConditionDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** update an existing employment condition */
  employmentConditionUpdate: EmploymentConditionUpdateResponse;
  /** update an existing employment condition */
  employmentConditionUpdateQueued?: Maybe<QueuedMutationResponse>;
  establishmentClose: EstablishmentCloseResponse;
  /** Creates a new establishment position */
  establishmentCreate: EstablishmentCreateResponse;
  establishmentDelete: EstablishmentDeleteResponse;
  establishmentReopen: EstablishmentReopenResponse;
  establishmentStartDateUpdate: EstablishmentStartDateUpdateResponse;
  /** Update existing establishment position */
  establishmentUpdate: EstablishmentUpdateResponse;
  /** Replays an external event */
  externalEventReplay: Scalars['Boolean'];
  /** Creates a new external webhook */
  externalWebhookCreate: ExternalWebhookCreateResponse;
  /** Deletes an external webhook */
  externalWebhookDelete: ExternalWebhookDeleteResponse;
  /** Updates an external webhook */
  externalWebhookUpdate: ExternalWebhookUpdateResponse;
  fetchLatestJobsForCompensationPlan?: Maybe<FetchLatestJobsForCompensationPlanResponse>;
  generatePersonPictureUploadUrl: GeneratePersonPictureUploadUrlResponse;
  getPersonPictureTemporaryUploadedUrl: GetPersonPictureTemporaryUploadUrlResponse;
  /** Creates a new global notification configuration */
  globalNotificationConfigurationCreate?: Maybe<GlobalNotificationConfigurationCreateResponse>;
  /** Creates a new global notification configuration */
  globalNotificationConfigurationCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes an existing Global Notification Configuration */
  globalNotificationConfigurationDelete?: Maybe<GlobalNotificationConfigurationDeleteResponse>;
  /** Deletes an existing Global Notification Configuration */
  globalNotificationConfigurationDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing Global Notification Configuration */
  globalNotificationConfigurationUpdate?: Maybe<GlobalNotificationConfigurationUpdateResponse>;
  /** Updates an existing Global Notification Configuration */
  globalNotificationConfigurationUpdateQueued?: Maybe<QueuedMutationResponse>;
  /**
   * Update whether notifications are enabled for this tenant.
   * This is a separate mutation as the permission checks are different
   * (settings.global_notifications.manage rather than
   * settings.organisation.manage)
   */
  globalNotificationsEnabledUpdate: Scalars['Boolean'];
  goal?: Maybe<GoalMutation>;
  goalCategoryCreate?: Maybe<GoalCategoryCreateResponse>;
  goalCategoryDelete?: Maybe<GoalCategoryDeleteResponse>;
  goalCategoryUpdate?: Maybe<GoalCategoryUpdateResponse>;
  goalCreateMultiple?: Maybe<GoalCreateMultipleResponse>;
  goalDelete?: Maybe<GoalDeleteResponse>;
  goalDocumentCreate: GoalDocumentCreateResponse;
  goalDocumentDelete: GoalDocumentDeleteResponse;
  goalDocumentUpdate: GoalDocumentUpdateResponse;
  goalProgressStatusUpdate?: Maybe<GoalProgressStatusUpdateResponse>;
  goalTemplateCreate?: Maybe<GoalTemplateCreateResponse>;
  goalTemplateDelete?: Maybe<GoalTemplateDeleteResponse>;
  /** Updates a goal template with the given input */
  goalTemplateUpdate?: Maybe<GoalTemplateUpdateResponse>;
  goalUpdate?: Maybe<GoalUpdateResponse>;
  goalUpdateMeasurementValue?: Maybe<GoalUpdateMeasurementValueResponse>;
  hideFromOnboarding?: Maybe<HideFromOnboardingResult>;
  /** Creates a new integration configuration */
  integrationConfigurationCreate: IntegrationConfigurationCreateResponse;
  /** Deletes an integration configuration */
  integrationConfigurationDelete: IntegrationConfigurationDeleteResponse;
  /** Updates an integration configuration */
  integrationConfigurationUpdate: IntegrationConfigurationUpdateResponse;
  /** Creates a new integration log */
  integrationLogCreate: IntegrationLogCreateResponse;
  /** Creates a new integration mapping */
  integrationMappingCreate: IntegrationMappingCreateResponse;
  /** Deletes an integration mapping */
  integrationMappingDelete: IntegrationMappingDeleteResponse;
  /** Updates an integration mapping */
  integrationMappingUpdate: IntegrationMappingUpdateResponse;
  integrations?: Maybe<IntegrationsMutation>;
  /** Links the merge account to intelliHR */
  integrationsMergeLink: Scalars['Boolean'];
  /** Unlinks the merge account from intelliHR */
  integrationsMergeUnlink: Scalars['Boolean'];
  /** Creates a Employee in Xero */
  integrationsXeroCreateEmployee: Scalars['Boolean'];
  /**
   * Update whether job approvals are enabled for this tenant.
   * This is a separate mutation as the permission checks are different
   * (settings.jobs.approvals.manage rather than settings.organisation.manage)
   */
  jobApprovalsEnabledUpdate: TenantConfiguration;
  jobChangeReasonCreate: JobChangeReasonCreateResponse;
  jobChangeReasonCreateQueued?: Maybe<QueuedMutationResponse>;
  jobChangeReasonDelete: JobChangeReasonDeleteResponse;
  jobChangeReasonDeleteQueued?: Maybe<QueuedMutationResponse>;
  jobChangeReasonUpdate: JobChangeReasonUpdateResponse;
  jobChangeReasonUpdateQueued?: Maybe<QueuedMutationResponse>;
  jobCommentDelete?: Maybe<JobCommentDeleteResponse>;
  jobCommentDeleteQueued?: Maybe<QueuedMutationResponse>;
  jobCommentEdit?: Maybe<JobCommentEditResponse>;
  jobCommentEditQueued?: Maybe<QueuedMutationResponse>;
  /** Mutation for updating a job */
  jobCreate: JobCreateResponse;
  jobDirectReportsReassign: JobDirectReportsReassignResponse;
  jobEndDateCancel: JobEndDateCancelResponse;
  jobEndDateFinalise: JobEndDateFinaliseResponse;
  jobExpectedEndDateAdjust: JobExpectedEndDateAdjustResponse;
  jobExpectedEndDateRemove: JobExpectedEndDateRemoveResponse;
  jobExpectedEndDateSet: JobExpectedEndDateSetResponse;
  /** Creates a new job leave record */
  jobLeaveCreate: JobLeaveCreateResponse;
  /** Mutation for deleting a leave record */
  jobLeaveDelete: JobLeaveDeleteResponse;
  /** Finalises a new job leave record */
  jobLeaveFinalise: JobLeaveFinaliseResponse;
  /** Mutation for updating a leave record */
  jobLeaveUpdate: JobLeaveUpdateResponse;
  /** Mutation for applying a pending update to a job */
  jobPendingUpdateApply: JobUpdateResponse;
  /**
   * Approve a job pending update. This does not support approver access without other job permissions like people.jobs.edit
   * @deprecated This mutation does not support access for approvers without other job permissions like people.jobs.edit. Please use jobPendingUpdateApproveV2
   */
  jobPendingUpdateApprove: JobPendingUpdateApproveResponse;
  /** Approve a job pending update. Support approver access without other job permissions like people.jobs.edit */
  jobPendingUpdateApproveV2: JobPendingUpdateApproveResponse;
  /**
   * Reject a job pending update. This does not support approver access without other job permissions like people.jobs.edit
   * @deprecated This mutation does not support access for approvers without other job permissions like people.jobs.edit. Please use jobPendingUpdateRejectV2
   */
  jobPendingUpdateReject: JobPendingUpdateRejectResponse;
  /** Reject a job pending update. Support approver access without other job permissions like people.jobs.edit */
  jobPendingUpdateRejectV2: JobPendingUpdateRejectResponse;
  jobProbationDateUpdate: JobProbationDateUpdateResponse;
  jobProbationDateUpdateQueued?: Maybe<QueuedMutationResponse>;
  jobRequirementCreate?: Maybe<JobRequirementCreateResponse>;
  jobRequirementDelete?: Maybe<JobRequirementDeleteResponse>;
  jobRequirementGroupCreate?: Maybe<JobRequirementGroupCreateResponse>;
  jobRequirementGroupDelete?: Maybe<JobRequirementGroupDeleteResponse>;
  jobRequirementGroupUpdate?: Maybe<JobRequirementGroupUpdateResponse>;
  jobRequirementUpdate?: Maybe<JobRequirementUpdateResponse>;
  jobRequirementsUpdate: JobRequirementsUpdateResponse;
  jobStartDateUpdate: JobStartDateUpdateResponse;
  /** Updates job turnover information to the following */
  jobTurnoverUpdate: JobTurnoverUpdateResponse;
  /** Mutation for updating a job */
  jobUpdate: JobUpdateResponse;
  leaveTypeCreate: LeaveTypeCreateResponse;
  leaveTypeCreateQueued?: Maybe<QueuedMutationResponse>;
  leaveTypeDelete: LeaveTypeDeleteResponse;
  leaveTypeDeleteQueued?: Maybe<QueuedMutationResponse>;
  leaveTypeUpdate: LeaveTypeUpdateResponse;
  leaveTypeUpdateQueued?: Maybe<QueuedMutationResponse>;
  /** Creates a new location */
  locationCreate: LocationCreateResponse;
  /** Creates a new location */
  locationCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes the provided location */
  locationDelete: LocationDeleteResponse;
  /** Deletes the provided location */
  locationDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing location */
  locationUpdate: LocationUpdateResponse;
  /** Updates an existing location */
  locationUpdateQueued?: Maybe<QueuedMutationResponse>;
  markOnboardingStepAsComplete: MarkOnboardingStepAsCompleteResponse;
  /** Creates a new medical condition */
  medicalConditionCreate: MedicalConditionCreateResponse;
  /** Deletes existing medical condition */
  medicalConditionDelete: MedicalConditionDeleteResponse;
  /** Updates existing medical condition */
  medicalConditionUpdate: MedicalConditionUpdateResponse;
  noteCreate?: Maybe<NoteCreateResponse>;
  noteDelete?: Maybe<NoteDeleteResponse>;
  noteUpdate?: Maybe<NoteUpdateResponse>;
  /**
   * Deletes a notification from showing up in your notifications page.
   * Notifications can only be deleted if they belong to you.
   */
  notificationDelete: NotificationDeleteResponse;
  /** set all notifications as read for a user */
  notificationsSetAllReadStatus: NotificationsSetAllReadStatusResponse;
  /** set individual notifications as read for a user */
  notificationsSetReadStatus: NotificationsSetReadStatusResponse;
  onboardingPersonDocumentCreate: OnboardingPersonDocumentCreateResponse;
  onboardingPersonDocumentDelete: OnboardingPersonDocumentDeleteResponse;
  onboardingPersonDocumentUpdate: OnboardingPersonDocumentUpdateResponse;
  onboardingQualificationInstanceAttachmentCreate: OnboardingQualificationInstanceAttachmentCreateResponse;
  onboardingQualificationInstanceAttachmentDelete: OnboardingQualificationInstanceAttachmentDeleteResponse;
  onboardingQualificationInstanceAttachmentUpdate: OnboardingQualificationInstanceAttachmentUpdateResponse;
  /** Mutation for creating qualification instances during onboarding to bypass auth check */
  onboardingQualificationInstanceCreate?: Maybe<QualificationInstanceCreateResponse>;
  /** Mutation for updating qualification instances during onboarding to bypass auth check */
  onboardingQualificationInstanceUpdate?: Maybe<QualificationInstanceUpdateResponse>;
  onboardingTemplateCreate: OnboardingTemplateCreateResponse;
  onboardingTemplateCreateQueued?: Maybe<QueuedMutationResponse>;
  onboardingTemplateCreateV2: OnboardingTemplateCreateResponse;
  onboardingTemplateCreateV2Queued?: Maybe<QueuedMutationResponse>;
  onboardingTemplateDelete: OnboardingTemplateDeleteResponse;
  onboardingTemplateDeleteQueued?: Maybe<QueuedMutationResponse>;
  onboardingTemplateUpdate: OnboardingTemplateUpdateResponse;
  onboardingTemplateUpdateQueued?: Maybe<QueuedMutationResponse>;
  orgObjectiveCreate?: Maybe<OrgObjectiveCreateResponse>;
  orgObjectiveDelete?: Maybe<OrgObjectiveDeleteResponse>;
  orgObjectiveUpdate?: Maybe<OrgObjectiveUpdateResponse>;
  orgObjectiveUpdateMeasurementValue?: Maybe<OrgObjectiveUpdateMeasurementValueResponse>;
  payGradeCreate: PayGradeCreateResponse;
  payGradeCreateQueued?: Maybe<QueuedMutationResponse>;
  payGradeDelete: PayGradeDeleteResponse;
  payGradeReorder: PayGradeReorderResponse;
  payGradeUpdate: PayGradeUpdateResponse;
  payGradeUpdateQueued?: Maybe<QueuedMutationResponse>;
  /** Performs form fill in for self service actions */
  performFillInForm: SelfServiceActionPerformFillInFormResponse;
  performanceFrameworkCreate?: Maybe<PerformanceFrameworkCreateResponse>;
  performanceFrameworkDelete?: Maybe<PerformanceFrameworkDeleteResponse>;
  performanceFrameworkUpdate?: Maybe<PerformanceFrameworkUpdateResponse>;
  performanceImprovementPlanCreateEscalated?: Maybe<PerformanceImprovementPlanCreateEscalatedResponse>;
  performanceImprovementPlanCreateInitiated?: Maybe<PerformanceImprovementPlanCreateInitiatedResponse>;
  performanceImprovementPlanCreateRelated?: Maybe<PerformanceImprovementPlanCreateRelatedResponse>;
  performanceImprovementPlanCreateResolved?: Maybe<PerformanceImprovementPlanCreateResolvedResponse>;
  performanceImprovementPlanUpdateEscalated?: Maybe<PerformanceImprovementPlanUpdateEscalatedResponse>;
  performanceImprovementPlanUpdateInitiated?: Maybe<PerformanceImprovementPlanUpdateInitiatedResponse>;
  performanceImprovementPlanUpdateLetterContent?: Maybe<PerformanceImprovementPlanUpdateLetterContentResponse>;
  performanceImprovementPlanUpdateRelated?: Maybe<PerformanceImprovementPlanUpdateRelatedResponse>;
  performanceImprovementPlanUpdateResolved?: Maybe<PerformanceImprovementPlanUpdateResolvedResponse>;
  performanceMetricGroupCreate?: Maybe<PerformanceMetricGroupCreateResponse>;
  performanceMetricGroupDelete?: Maybe<PerformanceMetricGroupDeleteResponse>;
  performanceMetricGroupUpdate?: Maybe<PerformanceMetricGroupUpdateResponse>;
  personCreate?: Maybe<PersonCreateResponse>;
  personCreateQueued?: Maybe<QueuedMutationResponse>;
  personDelete: PersonDeleteResponse;
  personDeleteQueued?: Maybe<QueuedMutationResponse>;
  personDocumentCreate: PersonDocumentCreateResponse;
  personDocumentCreateV2: PersonDocumentCreateResponse;
  personDocumentDelete: PersonDocumentDeleteResponse;
  personDocumentDeleteV2: PersonDocumentDeleteResponse;
  personDocumentUpdate: PersonDocumentUpdateResponse;
  personDocumentUpdateV2: PersonDocumentUpdateResponse;
  personPrimaryJobUpdate: PersonPrimaryJobUpdateResponse;
  personProfile: PersonProfileMutation;
  personUpdate: PersonUpdateResponse;
  personUpdateQueued?: Maybe<QueuedMutationResponse>;
  /** Creates a new phone number for the user */
  phoneNumberCreate: PhoneNumberCreateResponse;
  /** Deletes the provided phone number */
  phoneNumberDelete: PhoneNumberDeleteResponse;
  /** Update a new phone number for the user */
  phoneNumberUpdate: PhoneNumberUpdateResponse;
  /** Create a new plan with a set of modules. */
  planCreate: PlanCreateResponse;
  /** Deletes a plan that is not in use. */
  planDelete: PlanDeleteResponse;
  /** Update an existing plan. */
  planUpdate: PlanUpdateResponse;
  /** Create a position title within the system for selection on jobs */
  positionTitleCreate: PositionTitleCreateResponse;
  /** Delete an existing position title. Cannot be performed if the title is in use. */
  positionTitleDelete: PositionTitleDeleteResponse;
  /** Replaces all instances of a position title with another and deletes the old position title */
  positionTitleMerge: PositionTitleMergeResponse;
  /** Update an existing position title */
  positionTitleUpdate: PositionTitleUpdateResponse;
  qualificationInstanceAttachmentCreate: QualificationInstanceAttachmentCreateResponse;
  qualificationInstanceAttachmentDelete: QualificationInstanceAttachmentDeleteResponse;
  qualificationInstanceAttachmentUpdate: QualificationInstanceAttachmentUpdateResponse;
  qualificationInstanceCommentCreate?: Maybe<QualificationInstanceCommentCreateResponse>;
  qualificationInstanceCommentDelete: QualificationInstanceCommentDeleteResponse;
  qualificationInstanceCreate?: Maybe<QualificationInstanceCreateResponse>;
  qualificationInstanceDelete?: Maybe<QualificationInstanceDeleteResponse>;
  qualificationInstanceSubmit?: Maybe<QualificationInstanceSubmitResponse>;
  qualificationInstanceUpdate?: Maybe<QualificationInstanceUpdateResponse>;
  qualificationTemplateCreate?: Maybe<QualificationTemplateCreateResponse>;
  qualificationTemplateDelete?: Maybe<QualificationTemplateDeleteResponse>;
  qualificationTemplateUpdate?: Maybe<QualificationTemplateUpdateResponse>;
  qualificationTemplateUpdateQueued?: Maybe<QueuedMutationResponse>;
  qualificationTypeCreate?: Maybe<QualificationTypeCreateResponse>;
  qualificationTypeDelete?: Maybe<QualificationTypeDeleteResponse>;
  qualificationTypeUpdate?: Maybe<QualificationTypeUpdateResponse>;
  qualificationTypeUpdateQueued?: Maybe<QueuedMutationResponse>;
  recruitmentInfoUpdate: RecruitmentInfoUpdateResponse;
  rejectContract?: Maybe<rejectContractResponse>;
  rejectTerms?: Maybe<RejectTermsResponse>;
  representativeTypeUpdate: RepresentativeTypeEditResponse;
  representativeTypeUpdateQueued?: Maybe<QueuedMutationResponse>;
  resendForm?: Maybe<ResendFormResponse>;
  resendMessage?: Maybe<ResendMessageResponse>;
  resendSignatureRequestEmail?: Maybe<ResendSignatureRequestEmailResponse>;
  /** @deprecated Use resendForm instead */
  resendTask?: Maybe<ResendTaskResponse>;
  /** Creates a self service action tile */
  selfServiceActionCreate: SelfServiceActionCreateResponse;
  /** Creates a self service action tile */
  selfServiceActionCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes a self service action tile */
  selfServiceActionDelete: SelfServiceActionDeleteResponse;
  /** Deletes a self service action tile */
  selfServiceActionDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Reorders self service action list */
  selfServiceActionFromTo: SelfServiceActionFromToResponse;
  /** Reorders self service action list */
  selfServiceActionFromToQueued?: Maybe<QueuedMutationResponse>;
  /** Updates a self service action tile */
  selfServiceActionUpdate: SelfServiceActionUpdateResponse;
  /** Updates a self service action tile */
  selfServiceActionUpdateQueued?: Maybe<QueuedMutationResponse>;
  sendFormsViaSelfServiceAction?: Maybe<sendFormsViaSelfServiceActionResponse>;
  /** Sends a new integration notification */
  sendIntegrationNotification: SendIntegrationNotificationResponse;
  /** A mutation to set the auto increment intelliHR ID starting value */
  setIntellihrIdStartingValue: IntellihrIdStartingValueResponse;
  /** Creates an inbound credential */
  sftpInboundCredentialsCreate: SftpInboundCredentialResponse;
  /** Deletes an inbound credential */
  sftpInboundCredentialsDelete: Scalars['ID'];
  /** Rerolls inbound credentials - i.e. removes all and creates a new one with the info provided */
  sftpInboundCredentialsReroll: SftpInboundCredentialResponse;
  /** Updates an inbound credential */
  sftpInboundCredentialsUpdate: SftpInboundCredential;
  /** Updates an outbound credential */
  sftpOutboundCredentialUpdate: SftpOutboundCredential;
  /** Creates an outbound credential */
  sftpOutboundCredentialsCreate: SftpOutboundCredential;
  /** Deletes an outbound credential */
  sftpOutboundCredentialsDelete: Scalars['ID'];
  shareCompensationPlan?: Maybe<ShareCompensationPlanResponse>;
  skill?: Maybe<SkillMutation>;
  skillDiscipline?: Maybe<SkillDisciplineMutation>;
  /** @deprecated This mutation makes too many assumptions about what form you're trying to submit. Please use submitOnboardingFormInstanceV2 instead */
  submitOnboardingFormInstance: SubmitOnboardingFormResponse;
  /** @deprecated This mutation makes too many assumptions about what form you're trying to submit. Please use submitOnboardingFormInstanceV2 instead */
  submitOnboardingFormInstanceQueued?: Maybe<QueuedMutationResponse>;
  submitOnboardingFormInstanceV2: SubmitOnboardingFormResponse;
  submitOnboardingFormInstanceV2Queued?: Maybe<QueuedMutationResponse>;
  superAPIEmployeeOnboardingSessionSubmit?: Maybe<SuperAPIEmployeeOnboardingSessionSubmitResponse>;
  tempAdminCreate?: Maybe<TempAdminCreateResponse>;
  tempAdminDelete?: Maybe<TempAdminDeleteResponse>;
  /** Create a title within the system for selection on people */
  titleCreate: TitleCreateResponse;
  /** Delete an existing title. Cannot be performed if the title is in use. */
  titleDelete: TitleDeleteResponse;
  /** Update an existing title */
  titleUpdate: TitleUpdateResponse;
  trainingCreate?: Maybe<TrainingCreateResponse>;
  trainingCreateV2?: Maybe<TrainingCreateResponse>;
  trainingDelete?: Maybe<TrainingDeleteResponse>;
  trainingDocumentCreate: TrainingDocumentCreateResponse;
  trainingDocumentDelete: TrainingDocumentDeleteResponse;
  trainingDocumentUpdate: TrainingDocumentUpdateResponse;
  trainingProviderCreate?: Maybe<TrainingProviderCreateResponse>;
  trainingProviderDelete: TrainingProviderDeleteResponse;
  trainingProviderUpdate?: Maybe<TrainingProviderUpdateResponse>;
  trainingTypeCreate?: Maybe<TrainingTypeCreateResponse>;
  trainingTypeDelete?: Maybe<TrainingTypeDeleteResponse>;
  trainingTypeUpdate?: Maybe<TrainingTypeUpdateResponse>;
  trainingTypeUpdateQueued?: Maybe<QueuedMutationResponse>;
  trainingUpdate?: Maybe<TrainingUpdateResponse>;
  /** Issues the Scheduled Form's Form Instances now */
  triggerScheduledForm?: Maybe<TriggerScheduledFormResponse>;
  turnoverReasonCreate: TurnoverReasonCreateResponse;
  turnoverReasonDelete: TurnoverReasonDeleteResponse;
  turnoverReasonUpdate: TurnoverReasonUpdateResponse;
  unarchiveAnonymousFormDesign?: Maybe<FormDesign>;
  unarchiveFormDesign?: Maybe<FormDesign>;
  unhideFromOnboarding?: Maybe<HideFromOnboardingResult>;
  unsuppressAndRescheduleMessage?: Maybe<UnsuppressAndRescheduleMessageResponse>;
  updateAnonymousFormDesign?: Maybe<AnonymousFormDesignResponse>;
  updateAnonymousPulseModel?: Maybe<PulseModelUnion>;
  updateCompensationPlan?: Maybe<UpdateCompensationPlanResponse>;
  /** Update all budget groups */
  updateCompensationPlannerBudgetGroupsV2?: Maybe<UpdateCompensationPlannerBudgetGroupsResponseV2>;
  /** @deprecated Moved to V2 model structure */
  updateCompensationPlannerBudgets?: Maybe<UpdateCompensationPlannerBudgetsResponse>;
  /** @deprecated Moved to V2 model structure */
  updateCompensationPlannerConfig?: Maybe<UpdateCompensationPlannerConfigResponse>;
  /** Update compensation planner config eligibility rules */
  updateCompensationPlannerConfigEligibilityRules?: Maybe<UpdateCompensationPlannerConfigEligibilityRulesResponse>;
  /** Update compensation planner config job datagrid columns */
  updateCompensationPlannerConfigJobDatagridColumns?: Maybe<UpdateCompensationPlannerConfigJobDatagridColumnsResponse>;
  /** @deprecated Moved to V2 model structure */
  updateCompensationPlannerEligibilityRules?: Maybe<UpdateCompensationPlannerEligibilityRulesResponse>;
  /** @deprecated Moved to V2 model structure */
  updateCompensationPlannerJob?: Maybe<UpdateCompensationPlannerJobResponse>;
  /** Update compensation planner job */
  updateCompensationPlannerJobV2?: Maybe<UpdateCompensationPlannerJobResponseV2>;
  /** @deprecated Moved to V2 model structure */
  updateCompensationPlannerPlan?: Maybe<UpdateCompensationPlannerPlanResponse>;
  /** Update a plan */
  updateCompensationPlannerPlanV2?: Maybe<UpdateCompensationPlannerPlanResponseV2>;
  /** Updates a custom field definition with the given input */
  updateCustomFieldDefinition?: Maybe<UpdateCustomFieldResponse>;
  updateDocumentFolder?: Maybe<DocumentFolderUpdateResponse>;
  updateFormDesignSettings?: Maybe<UpdateFormDesignSettingsResponse>;
  updateJobApprovalOrders?: Maybe<UpdateJobApprovalOrdersResponse>;
  updateOnboardingSession: OnboardingSession;
  updatePersonProfileCoverPictureFromTemporary: UpdatePersonProfileCoverPictureFromTemporaryResponse;
  updatePersonProfilePictureFromTemporary: UpdatePersonProfilePictureFromTemporaryResponse;
  updatePulseModel?: Maybe<PulseModelUnion>;
  updateScheduledForm?: Maybe<ScheduledForm>;
  /** Updates the tenant configuration for the current tenant. Requires the organisation settings manage permission. */
  updateTenantConfig: TenantConfiguration;
  /** Updates the user configuration for the currently logged in user. */
  updateUserConfig: UserConfiguration;
  /** Creates a new user */
  userCreate?: Maybe<UserCreateResponse>;
  /** Creates a new user */
  userCreateQueued?: Maybe<QueuedMutationResponse>;
  /** Deletes an existing user */
  userDelete?: Maybe<UserDeleteResponse>;
  /** Deletes an existing user */
  userDeleteQueued?: Maybe<QueuedMutationResponse>;
  /** Updates an existing user */
  userUpdate?: Maybe<UserUpdateResponse>;
  /** Updates an existing user */
  userUpdateQueued?: Maybe<QueuedMutationResponse>;
  workClassCreate: WorkClassCreateResponse;
  workClassDelete: WorkClassDeleteResponse;
  workClassUpdate: WorkClassUpdateResponse;
  workflow?: Maybe<WorkflowMutation>;
  /** Schedule a series of forms aligning to a workflow */
  workflowSchedule: WorkflowScheduleResponse;
};


export type MutationaddressCreateArgs = {
  input: AddressCreateInput;
};


export type MutationaddressDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationaddressUpdateArgs = {
  input: AddressUpdateInput;
};


export type MutationarchiveAnonymousFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationarchiveFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationauthenticateForPrivateKeyArgs = {
  input: AuthenticateForPrivateKeyInput;
};


export type MutationbulkDataImportActionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationbulkDataImportCreateArgs = {
  input: BulkDataImportCreateInput;
};


export type MutationbulkDataImportDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationbulkDataImportDeletePendingActionsArgs = {
  bulkDataImportId: Scalars['ID'];
};


export type MutationbulkDataImportGenerateActionsForUploadArgs = {
  input: BulkDataImportGenerateActionsForUploadInput;
};


export type MutationbulkDataImportGeneratePresignedUrlArgs = {
  input: BulkDataImportGeneratePresignedUrlInput;
};


export type MutationbulkDataImportProcessPauseArgs = {
  input: BulkDataImportProcessPauseInput;
};


export type MutationbulkDataImportProcessStartArgs = {
  input: BulkDataImportProcessStartInput;
};


export type MutationbulkDeleteFormArgs = {
  formIds: Array<Scalars['ID']>;
};


export type MutationbulkDeleteScheduledFormArgs = {
  scheduledFormIds: Array<Scalars['ID']>;
};


export type MutationbulkDeleteTaskArgs = {
  taskIds: Array<Scalars['ID']>;
};


export type MutationbulkIgnoreFormArgs = {
  input: BulkIgnoreFormInput;
};


export type MutationbulkIgnoreTaskArgs = {
  input: BulkIgnoreTaskInput;
};


export type MutationbulkResendFormArgs = {
  formIds: Array<Scalars['ID']>;
};


export type MutationbulkResendTaskArgs = {
  taskIds: Array<Scalars['ID']>;
};


export type MutationbulkUserCreateArgs = {
  input: BulkUserCreateInput;
};


export type MutationbusinessEntityCreateArgs = {
  input: BusinessEntityCreateInput;
};


export type MutationbusinessEntityCreateQueuedArgs = {
  input: BusinessEntityCreateInput;
};


export type MutationbusinessEntityDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationbusinessEntityDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationbusinessEntityUpdateArgs = {
  input: BusinessEntityUpdateInput;
};


export type MutationbusinessEntityUpdateQueuedArgs = {
  input: BusinessEntityUpdateInput;
};


export type MutationbusinessPerformanceConfigurationSetArgs = {
  input: BusinessPerformanceConfigurationSetInput;
};


export type MutationbusinessPerformanceMetricBulkSetArgs = {
  input: BusinessPerformanceMetricBulkSetInput;
};


export type MutationbusinessPerformanceMetricCreateArgs = {
  input: BusinessPerformanceMetricCreateInput;
};


export type MutationbusinessPerformanceMetricDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationbusinessPerformanceMetricUpdateArgs = {
  input: BusinessPerformanceMetricUpdateInput;
};


export type MutationbusinessUnitCreateArgs = {
  input: BusinessUnitCreateInput;
};


export type MutationbusinessUnitCreateQueuedArgs = {
  input: BusinessUnitCreateInput;
};


export type MutationbusinessUnitDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationbusinessUnitDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationbusinessUnitUpdateArgs = {
  input: BusinessUnitUpdateInput;
};


export type MutationbusinessUnitUpdateQueuedArgs = {
  input: BusinessUnitUpdateInput;
};


export type MutationchangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationcloseAnonymousPulseSubmissionsArgs = {
  id: Scalars['ID'];
};


export type MutationcontractCreateArgs = {
  input: ContractCreateInput;
};


export type MutationcontractIssueBatchCreateArgs = {
  input: ContractIssueBatchCreateInput;
};


export type MutationcontractIssueBatchItemDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationcontractIssueBatchItemUpdateArgs = {
  input: ContractIssueBatchItemUpdateInput;
};


export type MutationcontractIssueBatchUpdateArgs = {
  input: ContractIssueBatchUpdateInput;
};


export type MutationcontractPersonSignatureCreateArgs = {
  input: ContractPersonSignatureCreateInput;
};


export type MutationcontractPersonSignatureDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationcontractPersonSignatureUpdateArgs = {
  input: ContractPersonSignatureUpdateInput;
};


export type MutationcontractSignatureUpdateArgs = {
  input: ContractSignatureUpdateInput;
};


export type MutationcontractTemplateCreateArgs = {
  input: ContractTemplateCreateInput;
};


export type MutationcontractTemplateDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationcontractTemplateUpdateArgs = {
  input: ContractTemplateUpdateInput;
};


export type MutationcontractTemplateV2CreateArgs = {
  input: ContractTemplateV2CreateInput;
};


export type MutationcontractTemplateV2UpdateArgs = {
  input: ContractTemplateV2UpdateInput;
};


export type MutationcreateAnonymousFormDesignArgs = {
  input: AnonymousFormDesignCreateInput;
};


export type MutationcreateAnonymousPulseModelArgs = {
  input: CreateAnonymousPulseModelInput;
};


export type MutationcreateCompensationPlanV2Args = {
  input: CreateCompensationPlanV2Input;
};


export type MutationcreateCompensationPlannerPlanArgs = {
  input: CreateCompensationPlannerPlanInput;
};


export type MutationcreateCompensationPlannerPlanV2Args = {
  input: CreateCompensationPlannerPlanInput;
};


export type MutationcreateCustomFieldDefinitionArgs = {
  input: CustomFieldInputCreate;
};


export type MutationcreateDocumentFolderArgs = {
  input: DocumentFolderCreateInput;
};


export type MutationcreateFormDesignArgs = {
  input: CreateFormDesignInput;
};


export type MutationcreateOnboardingSessionArgs = {
  onboardingFormInstanceId?: InputMaybe<Scalars['ID']>;
  onboardingSetup: OnboardingSetupInput;
};


export type MutationcreatePulseModelArgs = {
  input: CreatePulseModelInput;
};


export type MutationcreateQualificationInstanceRenewalArgs = {
  input: CreateQualificationInstanceRenewalInputV2;
};


export type MutationcreateWorkflowInstanceArgs = {
  input: CreateWorkflowInstanceInput;
};


export type MutationcustomFieldCategoryCreateArgs = {
  input: CustomFieldCategoryCreateInput;
};


export type MutationcustomFieldCategoryCreateQueuedArgs = {
  input: CustomFieldCategoryCreateInput;
};


export type MutationcustomFieldCategoryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationcustomFieldCategoryDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationcustomFieldCategoryEditArgs = {
  input: CustomFieldCategoryEditInput;
};


export type MutationcustomFieldCategoryEditQueuedArgs = {
  input: CustomFieldCategoryEditInput;
};


export type MutationcustomFieldCategoryFromToArgs = {
  input: CustomFieldCategoryFromToInput;
};


export type MutationcustomFieldCategoryReorderArgs = {
  input: CustomFieldCategoryReorderInput;
};


export type MutationcustomFieldDefinitionReorderArgs = {
  input: CustomFieldDefinitionReorderInput;
};


export type MutationcustomFieldTemplateCategoryCreateArgs = {
  input: CustomFieldTemplateCategoryCreateInput;
};


export type MutationcustomFieldTemplateCategoryCreateQueuedArgs = {
  input: CustomFieldTemplateCategoryCreateInput;
};


export type MutationcustomFieldTemplateCategoryEditArgs = {
  input: CustomFieldTemplateCategoryEditInput;
};


export type MutationcustomFieldTemplateCategoryEditQueuedArgs = {
  input: CustomFieldTemplateCategoryEditInput;
};


export type MutationcustomFieldTemplateCreateArgs = {
  input: CustomFieldTemplateCreateInput;
};


export type MutationcustomFieldTemplateCreateQueuedArgs = {
  input: CustomFieldTemplateCreateInput;
};


export type MutationcustomFieldTemplateUpdateArgs = {
  input: CustomFieldTemplateUpdateInput;
};


export type MutationcustomFieldTemplateUpdateQueuedArgs = {
  input: CustomFieldTemplateUpdateInput;
};


export type MutationdefaultRemunerationComponentCreateArgs = {
  input: DefaultRemunerationComponentCreateInput;
};


export type MutationdefaultRemunerationComponentCreateQueuedArgs = {
  input: DefaultRemunerationComponentCreateInput;
};


export type MutationdefaultRemunerationComponentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationdefaultRemunerationComponentDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationdefaultRemunerationComponentUpdateArgs = {
  input: DefaultRemunerationComponentUpdateInput;
};


export type MutationdefaultRemunerationComponentUpdateQueuedArgs = {
  input: DefaultRemunerationComponentUpdateInput;
};


export type MutationdeleteAnonymousFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteCompensationPlannerPlanArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteCompensationPlannerPlanV2Args = {
  id: Scalars['ID'];
};


export type MutationdeleteContractArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteCustomFieldDefinitionArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteDocumentFolderArgs = {
  input: DocumentFolderDeleteInput;
};


export type MutationdeleteFormArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteOnboardingSessionArgs = {
  id: Scalars['ID'];
};


export type MutationdeletePermissionGroupArgs = {
  permissionGroupId: Scalars['ID'];
};


export type MutationdeletePersonProfileCoverPictureArgs = {
  personId: Scalars['ID'];
};


export type MutationdeletePersonProfilePictureArgs = {
  personId: Scalars['ID'];
};


export type MutationdeletePulseArgs = {
  id: Scalars['ID'];
};


export type MutationdeletePulseRecurrenceArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteScheduledFormArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationdiaryNoteCategoryCreateArgs = {
  input: DiaryNoteCategoryCreateInput;
};


export type MutationdiaryNoteCategoryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationdiaryNoteCategoryUpdateArgs = {
  input: DiaryNoteCategoryUpdateInput;
};


export type MutationdiaryNoteCreateStandaloneArgs = {
  input: DiaryNoteCreateStandaloneInput;
};


export type MutationdiaryNoteDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationdiaryNoteDocumentCreateArgs = {
  input: DiaryNoteDocumentCreateInput;
};


export type MutationdiaryNoteDocumentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationdiaryNoteDocumentUpdateArgs = {
  input: DiaryNoteDocumentUpdateInput;
};


export type MutationdiaryNoteUpdateStandaloneArgs = {
  input: DiaryNoteUpdateStandaloneInput;
};


export type MutationdraftQualificationInstanceUpdateArgs = {
  input: DraftQualificationInstanceUpdateInput;
};


export type MutationduplicateAnonymousFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationduplicateCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type MutationduplicateFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationeditFormArgs = {
  input: EditFormInput;
};


export type MutationeditTaskArgs = {
  input: EditTaskInput;
};


export type MutationemailAddressCreateArgs = {
  input: EmailAddressCreateInput;
};


export type MutationemailAddressDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationemailAddressUpdateArgs = {
  input: EmailAddressUpdateInput;
};


export type MutationemploymentConditionCreateArgs = {
  input: EmploymentConditionCreateInput;
};


export type MutationemploymentConditionCreateQueuedArgs = {
  input: EmploymentConditionCreateInput;
};


export type MutationemploymentConditionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationemploymentConditionDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationemploymentConditionUpdateArgs = {
  input: EmploymentConditionUpdateInput;
};


export type MutationemploymentConditionUpdateQueuedArgs = {
  input: EmploymentConditionUpdateInput;
};


export type MutationestablishmentCloseArgs = {
  input: EstablishmentCloseInput;
};


export type MutationestablishmentCreateArgs = {
  input: EstablishmentCreateInput;
};


export type MutationestablishmentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationestablishmentReopenArgs = {
  input: EstablishmentReopenInput;
};


export type MutationestablishmentStartDateUpdateArgs = {
  input: EstablishmentStartDateUpdateInput;
};


export type MutationestablishmentUpdateArgs = {
  input: EstablishmentUpdateInput;
};


export type MutationexternalEventReplayArgs = {
  externalEventLogId: Scalars['ID'];
  externalWebhookId: Scalars['ID'];
};


export type MutationexternalWebhookCreateArgs = {
  input: ExternalWebhookCreateInput;
};


export type MutationexternalWebhookDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationexternalWebhookUpdateArgs = {
  input: ExternalWebhookUpdateInput;
};


export type MutationfetchLatestJobsForCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type MutationgeneratePersonPictureUploadUrlArgs = {
  input: GeneratePersonPictureUploadUrlInput;
};


export type MutationgetPersonPictureTemporaryUploadedUrlArgs = {
  input: GetPersonPictureTemporaryUploadUrlInput;
};


export type MutationglobalNotificationConfigurationCreateArgs = {
  input: GlobalNotificationConfigurationCreateInput;
};


export type MutationglobalNotificationConfigurationCreateQueuedArgs = {
  input: GlobalNotificationConfigurationCreateInput;
};


export type MutationglobalNotificationConfigurationDeleteArgs = {
  input?: InputMaybe<GlobalNotificationConfigurationDeleteInput>;
};


export type MutationglobalNotificationConfigurationDeleteQueuedArgs = {
  input?: InputMaybe<GlobalNotificationConfigurationDeleteInput>;
};


export type MutationglobalNotificationConfigurationUpdateArgs = {
  input?: InputMaybe<GlobalNotificationConfigurationUpdateInput>;
};


export type MutationglobalNotificationConfigurationUpdateQueuedArgs = {
  input?: InputMaybe<GlobalNotificationConfigurationUpdateInput>;
};


export type MutationglobalNotificationsEnabledUpdateArgs = {
  isEnabled: Scalars['Boolean'];
};


export type MutationgoalCategoryCreateArgs = {
  input: GoalCategoryCreateInput;
};


export type MutationgoalCategoryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationgoalCategoryUpdateArgs = {
  input: GoalCategoryUpdateInput;
};


export type MutationgoalCreateMultipleArgs = {
  input: GoalCreateMultipleInput;
};


export type MutationgoalDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationgoalDocumentCreateArgs = {
  input: GoalDocumentCreateInput;
};


export type MutationgoalDocumentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationgoalDocumentUpdateArgs = {
  input: GoalDocumentUpdateInput;
};


export type MutationgoalProgressStatusUpdateArgs = {
  input: GoalProgressStatusUpdateInput;
};


export type MutationgoalTemplateCreateArgs = {
  input: GoalTemplateCreateInput;
};


export type MutationgoalTemplateDeleteArgs = {
  input: GoalTemplateDeleteInput;
};


export type MutationgoalTemplateUpdateArgs = {
  input: GoalTemplateUpdateInput;
};


export type MutationgoalUpdateArgs = {
  input: GoalUpdateInput;
};


export type MutationgoalUpdateMeasurementValueArgs = {
  input: GoalUpdateMeasurementValueInput;
};


export type MutationhideFromOnboardingArgs = {
  input: ExcludedPerson;
};


export type MutationintegrationConfigurationCreateArgs = {
  input: IntegrationConfigurationCreateInput;
};


export type MutationintegrationConfigurationDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationintegrationConfigurationUpdateArgs = {
  input: IntegrationConfigurationUpdateInput;
};


export type MutationintegrationLogCreateArgs = {
  input: IntegrationLogCreateInput;
};


export type MutationintegrationMappingCreateArgs = {
  input: IntegrationMappingCreateInput;
};


export type MutationintegrationMappingDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationintegrationMappingUpdateArgs = {
  input: IntegrationMappingUpdateInput;
};


export type MutationintegrationsMergeLinkArgs = {
  notificationEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicToken?: InputMaybe<Scalars['String']>;
};


export type MutationintegrationsXeroCreateEmployeeArgs = {
  input?: InputMaybe<CreateXeroEmployeeInput>;
};


export type MutationjobApprovalsEnabledUpdateArgs = {
  isEnabled: Scalars['Boolean'];
};


export type MutationjobChangeReasonCreateArgs = {
  input: JobChangeReasonCreateInput;
};


export type MutationjobChangeReasonCreateQueuedArgs = {
  input: JobChangeReasonCreateInput;
};


export type MutationjobChangeReasonDeleteArgs = {
  input: JobChangeReasonDeleteInput;
};


export type MutationjobChangeReasonDeleteQueuedArgs = {
  input: JobChangeReasonDeleteInput;
};


export type MutationjobChangeReasonUpdateArgs = {
  input: JobChangeReasonUpdateInput;
};


export type MutationjobChangeReasonUpdateQueuedArgs = {
  input: JobChangeReasonUpdateInput;
};


export type MutationjobCommentDeleteArgs = {
  input: JobCommentDeleteInput;
};


export type MutationjobCommentDeleteQueuedArgs = {
  input: JobCommentDeleteInput;
};


export type MutationjobCommentEditArgs = {
  input: JobCommentEditInput;
};


export type MutationjobCommentEditQueuedArgs = {
  input: JobCommentEditInput;
};


export type MutationjobCreateArgs = {
  input: JobCreateInput;
};


export type MutationjobDirectReportsReassignArgs = {
  input: JobDirectReportsReassignInput;
};


export type MutationjobEndDateCancelArgs = {
  input: JobEndDateCancelInput;
};


export type MutationjobEndDateFinaliseArgs = {
  input: JobEndDateFinaliseInput;
};


export type MutationjobExpectedEndDateAdjustArgs = {
  input: JobExpectedEndDateAdjustRequest;
};


export type MutationjobExpectedEndDateRemoveArgs = {
  input: JobExpectedEndDateRemoveRequest;
};


export type MutationjobExpectedEndDateSetArgs = {
  input: JobExpectedEndDateSetRequest;
};


export type MutationjobLeaveCreateArgs = {
  input: JobLeaveCreateInput;
};


export type MutationjobLeaveDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationjobLeaveFinaliseArgs = {
  input: JobLeaveFinaliseInput;
};


export type MutationjobLeaveUpdateArgs = {
  input: JobLeaveUpdateInput;
};


export type MutationjobPendingUpdateApplyArgs = {
  input: JobPendingUpdateApplyInput;
};


export type MutationjobPendingUpdateApproveArgs = {
  input: JobPendingUpdateApproveInput;
};


export type MutationjobPendingUpdateApproveV2Args = {
  input: JobPendingUpdateApproveInput;
};


export type MutationjobPendingUpdateRejectArgs = {
  id: Scalars['ID'];
};


export type MutationjobPendingUpdateRejectV2Args = {
  id: Scalars['ID'];
};


export type MutationjobProbationDateUpdateArgs = {
  input: JobProbationDateUpdateRequest;
};


export type MutationjobProbationDateUpdateQueuedArgs = {
  input: JobProbationDateUpdateRequest;
};


export type MutationjobRequirementCreateArgs = {
  input: JobRequirementCreateInput;
};


export type MutationjobRequirementDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationjobRequirementGroupCreateArgs = {
  input: JobRequirementGroupCreateInput;
};


export type MutationjobRequirementGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationjobRequirementGroupUpdateArgs = {
  input: JobRequirementGroupUpdateInput;
};


export type MutationjobRequirementUpdateArgs = {
  input: JobRequirementUpdateInput;
};


export type MutationjobRequirementsUpdateArgs = {
  input: JobRequirementsUpdateInput;
};


export type MutationjobStartDateUpdateArgs = {
  input: JobStartDateUpdateRequest;
};


export type MutationjobTurnoverUpdateArgs = {
  input?: InputMaybe<JobTurnoverUpdateInput>;
};


export type MutationjobUpdateArgs = {
  input: JobUpdateInput;
};


export type MutationleaveTypeCreateArgs = {
  input: LeaveTypeCreateInput;
};


export type MutationleaveTypeCreateQueuedArgs = {
  input: LeaveTypeCreateInput;
};


export type MutationleaveTypeDeleteArgs = {
  input: LeaveTypeDeleteInput;
};


export type MutationleaveTypeDeleteQueuedArgs = {
  input: LeaveTypeDeleteInput;
};


export type MutationleaveTypeUpdateArgs = {
  input: LeaveTypeUpdateInput;
};


export type MutationleaveTypeUpdateQueuedArgs = {
  input: LeaveTypeUpdateInput;
};


export type MutationlocationCreateArgs = {
  input: LocationCreateInput;
};


export type MutationlocationCreateQueuedArgs = {
  input: LocationCreateInput;
};


export type MutationlocationDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationlocationDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationlocationUpdateArgs = {
  input: LocationUpdateInput;
};


export type MutationlocationUpdateQueuedArgs = {
  input: LocationUpdateInput;
};


export type MutationmarkOnboardingStepAsCompleteArgs = {
  input: MarkOnboardingStepAsCompleteInput;
};


export type MutationmedicalConditionCreateArgs = {
  input: MedicalConditionCreateInput;
};


export type MutationmedicalConditionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationmedicalConditionUpdateArgs = {
  input: MedicalConditionUpdateInput;
};


export type MutationnoteCreateArgs = {
  input: NoteCreateInput;
};


export type MutationnoteDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationnoteUpdateArgs = {
  input?: InputMaybe<NoteUpdateInput>;
};


export type MutationnotificationDeleteArgs = {
  input: NotificationDeleteInput;
};


export type MutationnotificationsSetAllReadStatusArgs = {
  input: NotificationsSetAllReadStatusInput;
};


export type MutationnotificationsSetReadStatusArgs = {
  input: NotificationsSetReadStatusInput;
};


export type MutationonboardingPersonDocumentCreateArgs = {
  input: OnboardingPersonDocumentCreateInput;
};


export type MutationonboardingPersonDocumentDeleteArgs = {
  input: OnboardingPersonDocumentDeleteInput;
};


export type MutationonboardingPersonDocumentUpdateArgs = {
  input: OnboardingPersonDocumentUpdateInput;
};


export type MutationonboardingQualificationInstanceAttachmentCreateArgs = {
  input: OnboardingQualificationInstanceAttachmentCreateInput;
};


export type MutationonboardingQualificationInstanceAttachmentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationonboardingQualificationInstanceAttachmentUpdateArgs = {
  input: OnboardingQualificationInstanceAttachmentUpdateInput;
};


export type MutationonboardingQualificationInstanceCreateArgs = {
  input: OnboardingQualificationInstanceCreateInput;
};


export type MutationonboardingQualificationInstanceUpdateArgs = {
  input: QualificationInstanceUpdateInput;
};


export type MutationonboardingTemplateCreateArgs = {
  input: OnboardingTemplateCreateInput;
};


export type MutationonboardingTemplateCreateQueuedArgs = {
  input: OnboardingTemplateCreateInput;
};


export type MutationonboardingTemplateCreateV2Args = {
  input: OnboardingTemplateCreateInputV2;
};


export type MutationonboardingTemplateCreateV2QueuedArgs = {
  input: OnboardingTemplateCreateInputV2;
};


export type MutationonboardingTemplateDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationonboardingTemplateDeleteQueuedArgs = {
  id: Scalars['ID'];
};


export type MutationonboardingTemplateUpdateArgs = {
  input: OnboardingTemplateUpdateInput;
};


export type MutationonboardingTemplateUpdateQueuedArgs = {
  input: OnboardingTemplateUpdateInput;
};


export type MutationorgObjectiveCreateArgs = {
  input: OrgObjectiveCreateInput;
};


export type MutationorgObjectiveDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationorgObjectiveUpdateArgs = {
  input: OrgObjectiveUpdateInput;
};


export type MutationorgObjectiveUpdateMeasurementValueArgs = {
  input: OrgObjectiveUpdateMeasurementValueInput;
};


export type MutationpayGradeCreateArgs = {
  input: PayGradeCreateInput;
};


export type MutationpayGradeCreateQueuedArgs = {
  input: PayGradeCreateInput;
};


export type MutationpayGradeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationpayGradeReorderArgs = {
  input: PayGradeReorderInput;
};


export type MutationpayGradeUpdateArgs = {
  input: PayGradeUpdateInput;
};


export type MutationpayGradeUpdateQueuedArgs = {
  input: PayGradeUpdateInput;
};


export type MutationperformFillInFormArgs = {
  input: SelfServiceActionPerformFillInFormInput;
};


export type MutationperformanceFrameworkCreateArgs = {
  input: PerformanceFrameworkCreateInput;
};


export type MutationperformanceFrameworkDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationperformanceFrameworkUpdateArgs = {
  input: PerformanceFrameworkUpdateInput;
};


export type MutationperformanceImprovementPlanCreateEscalatedArgs = {
  input: PerformanceImprovementPlanCreateEscalatedInput;
};


export type MutationperformanceImprovementPlanCreateInitiatedArgs = {
  input: PerformanceImprovementPlanCreateInitiatedInput;
};


export type MutationperformanceImprovementPlanCreateRelatedArgs = {
  input: PerformanceImprovementPlanCreateRelatedInput;
};


export type MutationperformanceImprovementPlanCreateResolvedArgs = {
  input: PerformanceImprovementPlanCreateResolvedInput;
};


export type MutationperformanceImprovementPlanUpdateEscalatedArgs = {
  input: PerformanceImprovementPlanUpdateEscalatedInput;
};


export type MutationperformanceImprovementPlanUpdateInitiatedArgs = {
  input: PerformanceImprovementPlanUpdateInitiatedInput;
};


export type MutationperformanceImprovementPlanUpdateLetterContentArgs = {
  input: PerformanceImprovementPlanUpdateLetterContentInput;
};


export type MutationperformanceImprovementPlanUpdateRelatedArgs = {
  input: PerformanceImprovementPlanUpdateRelatedInput;
};


export type MutationperformanceImprovementPlanUpdateResolvedArgs = {
  input: PerformanceImprovementPlanUpdateResolvedInput;
};


export type MutationperformanceMetricGroupCreateArgs = {
  input: PerformanceMetricGroupCreateInput;
};


export type MutationperformanceMetricGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationperformanceMetricGroupUpdateArgs = {
  input: PerformanceMetricGroupUpdateInput;
};


export type MutationpersonCreateArgs = {
  input: PersonCreateInput;
};


export type MutationpersonCreateQueuedArgs = {
  input: PersonCreateInput;
};


export type MutationpersonDeleteArgs = {
  input: PersonDeleteInput;
};


export type MutationpersonDeleteQueuedArgs = {
  input: PersonDeleteInput;
};


export type MutationpersonDocumentCreateArgs = {
  input: PersonDocumentCreateInput;
};


export type MutationpersonDocumentCreateV2Args = {
  input: PersonDocumentCreateInput;
};


export type MutationpersonDocumentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationpersonDocumentDeleteV2Args = {
  id: Scalars['ID'];
};


export type MutationpersonDocumentUpdateArgs = {
  input: PersonDocumentUpdateInput;
};


export type MutationpersonDocumentUpdateV2Args = {
  input: PersonDocumentUpdateInput;
};


export type MutationpersonPrimaryJobUpdateArgs = {
  input: PersonPrimaryJobUpdateInput;
};


export type MutationpersonUpdateArgs = {
  input: PersonUpdateInput;
};


export type MutationpersonUpdateQueuedArgs = {
  input: PersonUpdateInput;
};


export type MutationphoneNumberCreateArgs = {
  input: PhoneNumberCreateInput;
};


export type MutationphoneNumberDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationphoneNumberUpdateArgs = {
  input: PhoneNumberUpdateInput;
};


export type MutationplanCreateArgs = {
  input: PlanCreateInput;
};


export type MutationplanDeleteArgs = {
  input: PlanDeleteInput;
};


export type MutationplanUpdateArgs = {
  input: PlanUpdateInput;
};


export type MutationpositionTitleCreateArgs = {
  input: PositionTitleCreateInput;
};


export type MutationpositionTitleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationpositionTitleMergeArgs = {
  input: PositionTitleMergeInput;
};


export type MutationpositionTitleUpdateArgs = {
  input: PositionTitleUpdateInput;
};


export type MutationqualificationInstanceAttachmentCreateArgs = {
  input: QualificationInstanceAttachmentCreateInput;
};


export type MutationqualificationInstanceAttachmentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationInstanceAttachmentUpdateArgs = {
  input: QualificationInstanceAttachmentUpdateInput;
};


export type MutationqualificationInstanceCommentCreateArgs = {
  input: QualificationInstanceCommentCreateInput;
};


export type MutationqualificationInstanceCommentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationInstanceCreateArgs = {
  input: QualificationInstanceCreateInput;
};


export type MutationqualificationInstanceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationInstanceSubmitArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationInstanceUpdateArgs = {
  input: QualificationInstanceUpdateInput;
};


export type MutationqualificationTemplateCreateArgs = {
  input: QualificationTemplateCreateInput;
};


export type MutationqualificationTemplateDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationTemplateUpdateArgs = {
  input: QualificationTemplateUpdateInput;
};


export type MutationqualificationTemplateUpdateQueuedArgs = {
  input: QualificationTemplateUpdateInput;
};


export type MutationqualificationTypeCreateArgs = {
  input: QualificationTypeCreateInput;
};


export type MutationqualificationTypeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationqualificationTypeUpdateArgs = {
  input: QualificationTypeUpdateInput;
};


export type MutationqualificationTypeUpdateQueuedArgs = {
  input: QualificationTypeUpdateInput;
};


export type MutationrecruitmentInfoUpdateArgs = {
  input: RecruitmentInfoUpdateInput;
};


export type MutationrejectContractArgs = {
  id: Scalars['ID'];
};


export type MutationrepresentativeTypeUpdateArgs = {
  input?: InputMaybe<RepresentativeTypeEditInput>;
};


export type MutationrepresentativeTypeUpdateQueuedArgs = {
  input?: InputMaybe<RepresentativeTypeEditInput>;
};


export type MutationresendFormArgs = {
  input: ResendFormInput;
};


export type MutationresendMessageArgs = {
  input: ResendMessageInput;
};


export type MutationresendSignatureRequestEmailArgs = {
  id: Scalars['ID'];
};


export type MutationresendTaskArgs = {
  input: ResendTaskInput;
};


export type MutationselfServiceActionCreateArgs = {
  input: SelfServiceActionCreateInput;
};


export type MutationselfServiceActionCreateQueuedArgs = {
  input: SelfServiceActionCreateInput;
};


export type MutationselfServiceActionDeleteArgs = {
  input: SelfServiceActionDeleteInput;
};


export type MutationselfServiceActionDeleteQueuedArgs = {
  input: SelfServiceActionDeleteInput;
};


export type MutationselfServiceActionFromToArgs = {
  input: SelfServiceActionFromToInput;
};


export type MutationselfServiceActionFromToQueuedArgs = {
  input: SelfServiceActionFromToInput;
};


export type MutationselfServiceActionUpdateArgs = {
  input: SelfServiceActionUpdateInput;
};


export type MutationselfServiceActionUpdateQueuedArgs = {
  input: SelfServiceActionUpdateInput;
};


export type MutationsendFormsViaSelfServiceActionArgs = {
  input: SelfServiceActionFormCompletionRequestInput;
};


export type MutationsendIntegrationNotificationArgs = {
  input: SendIntegrationNotificationInput;
};


export type MutationsetIntellihrIdStartingValueArgs = {
  input: IntellihrIdStartingValueInput;
};


export type MutationsftpInboundCredentialsCreateArgs = {
  input: SftpInboundCredentialCreateInput;
};


export type MutationsftpInboundCredentialsDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationsftpInboundCredentialsRerollArgs = {
  input: SftpInboundCredentialCreateInput;
};


export type MutationsftpInboundCredentialsUpdateArgs = {
  input: SftpInboundCredentialUpdateInput;
};


export type MutationsftpOutboundCredentialUpdateArgs = {
  input: SftpOutboundCredentialUpdateInput;
};


export type MutationsftpOutboundCredentialsCreateArgs = {
  input: SftpOutboundCredentialCreateInput;
};


export type MutationsftpOutboundCredentialsDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationshareCompensationPlanArgs = {
  input: ShareCompensationPlanInput;
};


export type MutationsubmitOnboardingFormInstanceArgs = {
  input: SubmitOnboardingFormInput;
};


export type MutationsubmitOnboardingFormInstanceQueuedArgs = {
  input: SubmitOnboardingFormInput;
};


export type MutationsubmitOnboardingFormInstanceV2Args = {
  input: SubmitOnboardingFormInputV2;
};


export type MutationsubmitOnboardingFormInstanceV2QueuedArgs = {
  input: SubmitOnboardingFormInputV2;
};


export type MutationsuperAPIEmployeeOnboardingSessionSubmitArgs = {
  id: Scalars['ID'];
};


export type MutationtempAdminCreateArgs = {
  input: TempAdminCreateInput;
};


export type MutationtempAdminDeleteArgs = {
  input: TempAdminDeleteInput;
};


export type MutationtitleCreateArgs = {
  input: TitleCreateInput;
};


export type MutationtitleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationtitleUpdateArgs = {
  input: TitleUpdateInput;
};


export type MutationtrainingCreateArgs = {
  input: TrainingCreateInput;
};


export type MutationtrainingCreateV2Args = {
  input: TrainingCreateInputV2;
};


export type MutationtrainingDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationtrainingDocumentCreateArgs = {
  input: TrainingDocumentCreateInput;
};


export type MutationtrainingDocumentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationtrainingDocumentUpdateArgs = {
  input: TrainingDocumentUpdateInput;
};


export type MutationtrainingProviderCreateArgs = {
  input: TrainingProviderCreateInput;
};


export type MutationtrainingProviderDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationtrainingProviderUpdateArgs = {
  input: TrainingProviderUpdateInput;
};


export type MutationtrainingTypeCreateArgs = {
  input: TrainingTypeCreateInput;
};


export type MutationtrainingTypeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationtrainingTypeUpdateArgs = {
  input: TrainingTypeUpdateInput;
};


export type MutationtrainingTypeUpdateQueuedArgs = {
  input: TrainingTypeUpdateInput;
};


export type MutationtrainingUpdateArgs = {
  input: TrainingUpdateInput;
};


export type MutationtriggerScheduledFormArgs = {
  id: Scalars['ID'];
};


export type MutationturnoverReasonCreateArgs = {
  input: TurnoverReasonCreateInput;
};


export type MutationturnoverReasonDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationturnoverReasonUpdateArgs = {
  input: TurnoverReasonUpdateInput;
};


export type MutationunarchiveAnonymousFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationunarchiveFormDesignArgs = {
  id: Scalars['ID'];
};


export type MutationunhideFromOnboardingArgs = {
  input: ExcludedPerson;
};


export type MutationunsuppressAndRescheduleMessageArgs = {
  input: UnsuppressAndRescheduleMessageInput;
};


export type MutationupdateAnonymousFormDesignArgs = {
  input: AnonymousFormDesignUpdateInput;
};


export type MutationupdateAnonymousPulseModelArgs = {
  input: UpdateAnonymousPulseModelInput;
};


export type MutationupdateCompensationPlanArgs = {
  input: UpdateCompensationPlanInput;
};


export type MutationupdateCompensationPlannerBudgetGroupsV2Args = {
  input: UpdateCompensationPlannerBudgetGroupsInput;
};


export type MutationupdateCompensationPlannerBudgetsArgs = {
  input: UpdateBudgetGroups;
};


export type MutationupdateCompensationPlannerConfigArgs = {
  input: UpdateCompensationPlannerConfigInput;
};


export type MutationupdateCompensationPlannerConfigEligibilityRulesArgs = {
  input: UpdateCompensationPlannerConfigEligibilityRulesInput;
};


export type MutationupdateCompensationPlannerConfigJobDatagridColumnsArgs = {
  input: UpdateCompensationPlannerConfigJobDatagridColumnsInput;
};


export type MutationupdateCompensationPlannerEligibilityRulesArgs = {
  input: UpdateCompensationPlannerEligibilityRulesInput;
};


export type MutationupdateCompensationPlannerJobArgs = {
  input: UpdateCompensationPlannerJobInput;
};


export type MutationupdateCompensationPlannerJobV2Args = {
  input: UpdateCompensationPlannerJobInputV2;
};


export type MutationupdateCompensationPlannerPlanArgs = {
  input: UpdateCompensationPlannerPlanInput;
};


export type MutationupdateCompensationPlannerPlanV2Args = {
  input: UpdateCompensationPlannerPlanInput;
};


export type MutationupdateCustomFieldDefinitionArgs = {
  input: CustomFieldUpdateInput;
};


export type MutationupdateDocumentFolderArgs = {
  input: DocumentFolderUpdateInput;
};


export type MutationupdateFormDesignSettingsArgs = {
  input: UpdateFormDesignSettingsInput;
};


export type MutationupdateJobApprovalOrdersArgs = {
  input: UpdateJobApprovalOrdersInput;
};


export type MutationupdateOnboardingSessionArgs = {
  id: Scalars['ID'];
  onboardingSetup: OnboardingSetupInput;
};


export type MutationupdatePersonProfileCoverPictureFromTemporaryArgs = {
  input: UpdatePersonProfileCoverPictureFromTemporaryInput;
};


export type MutationupdatePersonProfilePictureFromTemporaryArgs = {
  input: UpdatePersonProfilePictureFromTemporaryInput;
};


export type MutationupdatePulseModelArgs = {
  input: UpdatePulseModelInput;
};


export type MutationupdateScheduledFormArgs = {
  input: UpdateScheduledFormInput;
};


export type MutationupdateTenantConfigArgs = {
  input: UpdateTenantConfigurationInput;
};


export type MutationupdateUserConfigArgs = {
  input: UpdateUserConfigurationInput;
};


export type MutationuserCreateArgs = {
  input?: InputMaybe<UserCreateInput>;
};


export type MutationuserCreateQueuedArgs = {
  input?: InputMaybe<UserCreateInput>;
};


export type MutationuserDeleteArgs = {
  input?: InputMaybe<UserDeleteInput>;
};


export type MutationuserDeleteQueuedArgs = {
  input?: InputMaybe<UserDeleteInput>;
};


export type MutationuserUpdateArgs = {
  input?: InputMaybe<UserUpdateInput>;
};


export type MutationuserUpdateQueuedArgs = {
  input?: InputMaybe<UserUpdateInput>;
};


export type MutationworkClassCreateArgs = {
  input: WorkClassCreateInput;
};


export type MutationworkClassDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationworkClassUpdateArgs = {
  input: WorkClassUpdateInput;
};


export type MutationworkflowScheduleArgs = {
  input: WorkflowScheduleInput;
};

export type MyFormComplianceQuery = {
  __typename?: 'MyFormComplianceQuery';
  /** query to get my form compliance stats for the dashboard page */
  getMyStatsForDashboard: GetMyStatsForDashboardResponse;
  /** query to get my team's form compliance stats for the dashboard page */
  getMyTeamStatsForDashboard: GetMyTeamStatsForDashboardResponse;
};

export type MyOutStandingFormsCount = {
  __typename?: 'MyOutStandingFormsCount';
  atLeastThreeWeeksOld: Scalars['Int'];
  oneWeekOld: Scalars['Int'];
  total: Scalars['Int'];
  twoWeeksOld: Scalars['Int'];
  zeroWeekOld: Scalars['Int'];
};

export type MyOutStandingTasksCount = {
  __typename?: 'MyOutStandingTasksCount';
  atLeastThreeWeeksOld: Scalars['Int'];
  oneWeekOld: Scalars['Int'];
  total: Scalars['Int'];
  twoWeeksOld: Scalars['Int'];
  zeroWeekOld: Scalars['Int'];
};

export type MyOverview = {
  __typename?: 'MyOverview';
  totalAwaitingApproval?: Maybe<Scalars['Int']>;
  totalCompliant?: Maybe<Scalars['Int']>;
  totalExpiringSoon?: Maybe<Scalars['Int']>;
  totalNonCompliant?: Maybe<Scalars['Int']>;
};

export type MyTaskComplianceQuery = {
  __typename?: 'MyTaskComplianceQuery';
  /** query to get my task compliance stats for the dashboard page */
  getMyStatsForDashboard: GetMyStatsForDashboardResponse;
  /** query to get my team's task compliance stats for the dashboard page */
  getMyTeamStatsForDashboard: GetMyTeamStatsForDashboardResponse;
};

export type MyTeamCompletedFormsAvgDaysPerCount = {
  __typename?: 'MyTeamCompletedFormsAvgDaysPerCount';
  oneQuarterAgo: Scalars['Float'];
  thisQuarter: Scalars['Float'];
  threeQuartersAgo: Scalars['Float'];
  twoQuartersAgo: Scalars['Float'];
};

export type MyTeamCompletedTasksAvgDaysPerCount = {
  __typename?: 'MyTeamCompletedTasksAvgDaysPerCount';
  oneQuarterAgo: Scalars['Float'];
  thisQuarter: Scalars['Float'];
  threeQuartersAgo: Scalars['Float'];
  twoQuartersAgo: Scalars['Float'];
};

export type NavigationItem = {
  accentColor: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type NavigationItemLabel = NavigationItem & {
  __typename?: 'NavigationItemLabel';
  accentColor: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type NavigationItemLink = NavigationItem & {
  __typename?: 'NavigationItemLink';
  accentColor: Scalars['String'];
  href: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  openNewTab: Scalars['Boolean'];
  type: Scalars['String'];
};

export type NavigationItemPrimaryOption = {
  __typename?: 'NavigationItemPrimaryOption';
  description: Scalars['String'];
  href: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  openNewTab: Scalars['Boolean'];
};

export type NavigationItemSecondaryOption = {
  __typename?: 'NavigationItemSecondaryOption';
  href: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  openNewTab: Scalars['Boolean'];
};

export type NavigationItemSubMenu = NavigationItem & {
  __typename?: 'NavigationItemSubMenu';
  accentColor: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  primaryOptions: Array<NavigationItemPrimaryOption>;
  secondaryOptions: Array<NavigationItemSecondaryOption>;
  type: Scalars['String'];
};

export type NavigationList = {
  __typename?: 'NavigationList';
  primaryItems: Array<NavigationItem>;
  secondaryItems: Array<NavigationItem>;
};

export type NavigationQuery = {
  __typename?: 'NavigationQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** List of items to display on the sidebar */
  sidebar: Array<NavigationSidebarItem>;
};

export type NavigationSidebarItem = {
  __typename?: 'NavigationSidebarItem';
  /** Font awesome icon name for the sidebar item, or null for no icon */
  icon?: Maybe<Scalars['String']>;
  /** List of sub items to show under this main item */
  items: Array<NavigationSidebarSubItem>;
  /** Unique identifier for items in the sidebar */
  key: Scalars['ID'];
  /** Human readable title for this item */
  title: Scalars['String'];
  /** Translation key to use with i18n service */
  translationKey?: Maybe<Scalars['String']>;
  /** Url for this navigation item, or null for no link */
  url?: Maybe<Scalars['String']>;
  /** Whether the currently logged in user has permission to see this item */
  visible: Scalars['Boolean'];
};

export type NavigationSidebarSubItem = {
  __typename?: 'NavigationSidebarSubItem';
  /** Font awesome icon name for the sidebar item, or null for no icon */
  icon?: Maybe<Scalars['String']>;
  /** Unique identifier for items in the sidebar */
  key: Scalars['ID'];
  /** Human readable title for this item */
  title: Scalars['String'];
  /** Translation key to use with i18n service */
  translationKey?: Maybe<Scalars['String']>;
  /** Url for this navigation item, or null for no link */
  url?: Maybe<Scalars['String']>;
  /** Whether the currently logged in user has permission to see this item */
  visible: Scalars['Boolean'];
};

export type NavigationTopbarAvatarItem = {
  __typename?: 'NavigationTopbarAvatarItem';
  avatarUrl?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  indicatorCount?: Maybe<Scalars['Int']>;
  initials: Scalars['String'];
  items?: Maybe<Array<NavigationTopbarDropdownOption>>;
  label: Scalars['String'];
  statusDot?: Maybe<NavigationTopbarStatusDot>;
  type: Scalars['String'];
};

export type NavigationTopbarDropdownOption = {
  __typename?: 'NavigationTopbarDropdownOption';
  description?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  variant: NavigationTopbarItemVariant;
  visible: Scalars['Boolean'];
};

export type NavigationTopbarFontAwesomeItem = {
  __typename?: 'NavigationTopbarFontAwesomeItem';
  href?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  iconType: Scalars['String'];
  id: Scalars['String'];
  indicatorCount?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<NavigationTopbarDropdownOption>>;
  label: Scalars['String'];
  statusDot?: Maybe<NavigationTopbarStatusDot>;
  type: Scalars['String'];
};

export type NavigationTopbarItem = NavigationTopbarAvatarItem | NavigationTopbarFontAwesomeItem;

export enum NavigationTopbarItemVariant {
  HEADING = 'HEADING',
  OPTION = 'OPTION'
}

export enum NavigationTopbarStatusDot {
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['String'];
  createdAtDateTime: Scalars['DateTime'];
  createdByLoggedInUser: Scalars['Boolean'];
  createdByPerson: Person;
  createdByPersonId: Scalars['ID'];
  createdByUser: User;
  createdByUserId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  noteSharedWithPeople: Array<NoteSharedWithPerson>;
  subjectPeople: Array<Person>;
  summary?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedAtDateTime: Scalars['DateTime'];
};

export type NoteCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  sharedWithPeopleIds?: InputMaybe<Array<Scalars['String']>>;
  subjectPeopleIds?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
};

export type NoteCreateResponse = {
  __typename?: 'NoteCreateResponse';
  note?: Maybe<Note>;
};

export type NoteDeleteResponse = {
  __typename?: 'NoteDeleteResponse';
  noteId: Scalars['ID'];
};

export type NoteSharedWithPerson = {
  __typename?: 'NoteSharedWithPerson';
  id: Scalars['ID'];
  noteId: Scalars['ID'];
  person: Person;
  personId: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type NoteUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sharedWithPeopleIds?: InputMaybe<Array<Scalars['String']>>;
  subjectPeopleIds?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
};

export type NoteUpdateResponse = {
  __typename?: 'NoteUpdateResponse';
  note: Note;
};

export type Notification = {
  __typename?: 'Notification';
  /**
   * The person who performed the action that caused this notification.
   * For events that are actioned by the passing of time, this is the same as the affectedPerson.
   */
  actioningPerson?: Maybe<Person>;
  /**
   * The person who was affected by the change in this notification.
   * Generally all notifications have an affected person currently, but
   * there may be types in future that do not (e.g. an anniversary for using the system or so on)
   */
  affectedPerson?: Maybe<Person>;
  /** The type of this notification. */
  eventType: NotificationEventType;
  id: Scalars['ID'];
  /** Whether this notification has been marked as read by the user. */
  isReadInPlatform: Scalars['Boolean'];
  /**
   * The human readable description of the notification.
   * E.g., "Updated email address, first name"
   */
  subtitle?: Maybe<Scalars['String']>;
  /**
   * The email who this notification is sent to.
   * Email target notifications are not visible in the platform itself,
   * only when compiled into a daily email to send to an address.
   */
  targetEmail?: Maybe<Scalars['String']>;
  /**
   * The person who this notification is sent to.
   * You typically can only see notifications intended for yourself.
   * When null, this notification has a targetEmail instead.
   */
  targetPerson?: Maybe<Person>;
  /**
   * The human readable title of the notification.
   * E.g., "Lyanna Moreton updated Greg Taylor's profile."
   */
  title: Scalars['String'];
  /**
   * Similar to title, except with special placeholders to help with rendering
   * people in special ways in the UI.
   *
   * E.g., "{{actioningPerson}} updated {{affectedPerson}}'s profile."
   *
   * Potential placeholders:
   *     - actioningPerson
   *     - affectedPerson
   *     - targetPerson
   */
  titleTemplate: Scalars['String'];
  /** When this notification was fired. */
  triggeredAt: Scalars['DateTime'];
  /** Whether this notification was triggered via a bulk data import process. */
  triggeredByBulkDataImport: Scalars['Boolean'];
  /** Whether the actioningPerson for this notification was a superuser. */
  triggeredBySuperuser: Scalars['Boolean'];
  /**
   * A related url to link to for this notification.
   * E.g for an updated person profile this would link to the updated profile.
   * This can be null if the notification has no obvious location to link to.
   */
  url?: Maybe<Scalars['String']>;
};

export type NotificationDeleteInput = {
  /** The ID of the Notification to delete. */
  id: Scalars['ID'];
  /** The ID of the person who this notification belongs to. This should always be the currently logged in person. */
  targetPersonId: Scalars['ID'];
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  /** whether the user has unread notifications */
  hasUnreadNotifications: Scalars['Boolean'];
  /** The Notification id that was deleted */
  id: Scalars['ID'];
};

export type NotificationEventSettingsIncludeDetail = {
  __typename?: 'NotificationEventSettingsIncludeDetail';
  /** The key name of the included detail on the affected person. */
  key: Scalars['String'];
  /** The list of options for the checkbox */
  options: Array<NotificationEventSettingsIncludeDetailOption>;
  /** The section label used for the include details of this setting */
  sectionLabel?: Maybe<Scalars['String']>;
  /** The select all call out text */
  selectAllCallOut?: Maybe<Scalars['String']>;
  /** The label for the select all radio button */
  selectAllLabel: Scalars['String'];
  /** The manual selection call out text */
  selectManualCallOut?: Maybe<Scalars['String']>;
  /** The label for the manual selection radio button */
  selectManualLabel: Scalars['String'];
  /** The label for the select none radio button */
  selectNoneLabel: Scalars['String'];
  /**
   * The warning text for the checkbox options
   * @deprecated Please use selectManualCallOut field instead
   */
  warningText?: Maybe<Scalars['String']>;
};

export type NotificationEventSettingsIncludeDetailOption = {
  __typename?: 'NotificationEventSettingsIncludeDetailOption';
  identifier: Scalars['String'];
  label: Scalars['String'];
};

export type NotificationEventType = {
  __typename?: 'NotificationEventType';
  /** The human readable description for this event type */
  description: Scalars['String'];
  /** The icon that represents this event type when rendering using fontawesome */
  fontawesomeIcon: Scalars['String'];
  id: Scalars['ID'];
  /**
   * Returns true if the configured configcat key for this notification event type
   * is turned on OR if there is no configcat key.
   * When false, this notification will show in the UI as a 'coming soon' notification type.
   */
  isImplemented: Scalars['Boolean'];
  /** The human readable name for this event type */
  name: Scalars['String'];
  /**
   * The list of configurable settings for this event type, shown as multi selects
   * and other inputs when setting up the global notification
   */
  settings: Array<NotificationEventTypeSetting>;
  /** The include details that are supported for this event type. */
  supportedEventIncludeDetails: Array<NotificationIncludeDetail>;
  /** The event settings include details that are supported for this event type. */
  supportedEventSettingsIncludeDetails: Array<Maybe<NotificationIncludeDetail>>;
  /** The terminology used for this event type. */
  terminology: NotificationEventTypeTerminology;
};

export type NotificationEventTypeSetting = {
  __typename?: 'NotificationEventTypeSetting';
  /** An identifier string for this setting for use in the backend e.g. UPCOMING_NOTIFICATION_PERIOD */
  id: Scalars['String'];
  /** The human readable name of the setting to display for this condition when selecting this event type */
  name: Scalars['String'];
  /** The list of options to display in this select input (labels) */
  optionLabels: Array<Scalars['String']>;
  /** The list of options to display in this select input (values) */
  optionValues: Array<Scalars['String']>;
  /** The type of setting this represents. e.g. MULTI_SELECT */
  settingType: Scalars['String'];
};

export type NotificationEventTypeTerminology = {
  __typename?: 'NotificationEventTypeTerminology';
  /** The terminology to use for the actioning person field. */
  actioningPerson: NotificationPersonTerminology;
  /** The terminology to use for the affected person field. */
  affectedPerson: NotificationPersonTerminology;
};

export type NotificationIncludeDetail = {
  __typename?: 'NotificationIncludeDetail';
  /** Whether the included detail is required. */
  isRequired: Scalars['Boolean'];
  /** Whether the included detail is sensitive. */
  isSensitive: Scalars['Boolean'];
  /** The key name of the included detail on the affected person. */
  key: Scalars['String'];
  /** The human readable name of the included detail. */
  name: Scalars['String'];
};

export type NotificationMethod = {
  __typename?: 'NotificationMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum NotificationMethodStatus {
  PARTICIPANT_ERROR_NO_EMAILS = 'PARTICIPANT_ERROR_NO_EMAILS',
  PARTICIPANT_ERROR_NO_PRIMARY = 'PARTICIPANT_ERROR_NO_PRIMARY',
  PARTICIPANT_FALLBACK_PRIMARY = 'PARTICIPANT_FALLBACK_PRIMARY',
  PARTICIPANT_PERSONAL_FALLBACK_PRIMARY = 'PARTICIPANT_PERSONAL_FALLBACK_PRIMARY',
  PARTICIPANT_PERSONAL_OK = 'PARTICIPANT_PERSONAL_OK',
  PARTICIPANT_PRIMARY_OK = 'PARTICIPANT_PRIMARY_OK',
  PARTICIPANT_WORK_FALLBACK_PRIMARY = 'PARTICIPANT_WORK_FALLBACK_PRIMARY',
  PARTICIPANT_WORK_OK = 'PARTICIPANT_WORK_OK'
}

export enum NotificationPersonTerminology {
  DEFAULT = 'DEFAULT',
  EXPECTED_RESPONDENT = 'EXPECTED_RESPONDENT',
  FORM_ISSUER = 'FORM_ISSUER',
  SUBJECT = 'SUBJECT',
  SUBMITTER = 'SUBMITTER'
}

export type NotificationsSetAllReadStatusInput = {
  /** The read status to set for all the user's notifications' */
  readStatus: Scalars['Boolean'];
  /** The user id to set all notifications as read for */
  targetPersonId: Scalars['ID'];
};

export type NotificationsSetAllReadStatusResponse = {
  __typename?: 'NotificationsSetAllReadStatusResponse';
  /** whether the user has unread notifications */
  hasUnreadNotifications: Scalars['Boolean'];
  /** the read status of all notifications */
  readStatus: Scalars['Boolean'];
  /** whether setting all notifications as read succeeded */
  success: Scalars['Boolean'];
};

export type NotificationsSetReadStatusInput = {
  /** The notification ids to set the read status of */
  notificationIds: Array<Scalars['ID']>;
  /** The read status to set for the notification ids */
  readStatus: Scalars['Boolean'];
  /** The user id to set notifications as read for */
  targetPersonId: Scalars['ID'];
};

export type NotificationsSetReadStatusResponse = {
  __typename?: 'NotificationsSetReadStatusResponse';
  /** whether the user has unread notifications */
  hasUnreadNotifications: Scalars['Boolean'];
  /** the notification ids that were set as read or unread */
  notifications: Array<Notification>;
  /** the read status the notifications were set to */
  readStatus: Scalars['Boolean'];
};

export type NumberFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  greaterThan?: InputMaybe<Scalars['Int']>;
  greaterThanOrEquals?: InputMaybe<Scalars['Int']>;
  lessThan?: InputMaybe<Scalars['Int']>;
  lessThanOrEquals?: InputMaybe<Scalars['Int']>;
};

export type OccurredAtDateRange = {
  /** The start date of the date range, or null for start of job */
  from: Scalars['DateTime'];
  /** The end date of the date range, or null for end of job/time */
  to: Scalars['DateTime'];
};

export type OffsetPaginatedNotifications = {
  __typename?: 'OffsetPaginatedNotifications';
  /** The afterId that was passed in. */
  afterId?: Maybe<Scalars['ID']>;
  /** How many notifications are on the current page. */
  curPage: Scalars['Int'];
  /** Whether there are more notifications after this page. */
  hasMore: Scalars['Boolean'];
  /** The limit that was passed in. */
  limit: Scalars['Int'];
  /** The current page of notifications. */
  notifications: Array<Notification>;
  /** The oldest ID that was returned in this page of results. Use this param in afterId to get the next page. */
  oldestId?: Maybe<Scalars['ID']>;
};

export type OnboardingAddress = {
  __typename?: 'OnboardingAddress';
  addressType: AddressType;
  customFieldValues: Array<CustomFieldValue>;
  fullAddress: Scalars['String'];
  id: Scalars['ID'];
};


export type OnboardingAddresscustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type OnboardingAddressInput = {
  /** The ID of the address type */
  addressTypeId: Scalars['ID'];
  /** The country the address is in */
  country: Scalars['String'];
  /** Custom field values for the address */
  customFieldValues?: InputMaybe<Array<AddressCustomFieldValueInput>>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: InputMaybe<Scalars['String']>;
  /** The full readable address, minus the postcode and country */
  fullAddress?: InputMaybe<Scalars['String']>;
  /**
   * Whether it is the person's primary address.
   * At least one of the addresses provided must be a primary address.
   * When not provided, will default to false.
   */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The latitude of the address */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the address */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The postcode of the address */
  postcode?: InputMaybe<Scalars['String']>;
  /**
   * The state the address is in.
   * This is australia-specific and may not always be provided.
   */
  state?: InputMaybe<Scalars['String']>;
  /**
   * The street the address is on.
   * This is australia-specific and may not always be provided.
   */
  street?: InputMaybe<Scalars['String']>;
  /**
   * The suburb the address is in.
   * This is australia-specific and may not always be provided.
   */
  suburb?: InputMaybe<Scalars['String']>;
};

export type OnboardingCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type OnboardingEmailAddress = {
  __typename?: 'OnboardingEmailAddress';
  customFieldValues: Array<CustomFieldValue>;
  email: Scalars['String'];
  id: Scalars['ID'];
};


export type OnboardingEmailAddresscustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type OnboardingEmailInput = {
  /** Custom values associated with this email address */
  customFieldValues?: InputMaybe<Array<EmailAddressCustomFieldValueInput>>;
  /** An email address associated with this person */
  email: Scalars['String'];
};

export type OnboardingExclusion = {
  __typename?: 'OnboardingExclusion';
  id: Scalars['ID'];
  job?: Maybe<Job>;
  person: Person;
};

export type OnboardingFieldVisibilityType = {
  __typename?: 'OnboardingFieldVisibilityType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OnboardingFormInstance = {
  __typename?: 'OnboardingFormInstance';
  id: Scalars['ID'];
  person: Person;
  submittedAt?: Maybe<Scalars['DateTime']>;
  template: OnboardingTemplate;
};

export type OnboardingMedicalCondition = {
  __typename?: 'OnboardingMedicalCondition';
  body: Scalars['String'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
};

export type OnboardingMedicalConditionInput = {
  /** The name and description of the medical condition */
  body: Scalars['String'];
  /**
   * If the medical condition is visible to anyone with permission to view their profile.
   * This is either 'private' or 'public'
   */
  isPublic?: InputMaybe<Scalars['String']>;
};

export type OnboardingPerson = {
  __typename?: 'OnboardingPerson';
  addresses: Array<OnboardingAddress>;
  /** Composite Qualifications this person currently has (job requirements and non requirement qualifications) */
  compositeQualifications: Array<CompositeQualificationV2>;
  /** Custom fields for this person */
  customFieldValues: Array<CustomFieldValue>;
  /** The date the person was born */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** The personal email addresses that belong to this person */
  emailAddresses: Array<OnboardingEmailAddress>;
  /** The emergency contact email address for the person */
  emergencyContactEmailAddress?: Maybe<Scalars['String']>;
  /** The emergency contact name for the person */
  emergencyContactName?: Maybe<Scalars['String']>;
  /** The emergency contact phone number for the person */
  emergencyContactPhoneNumber?: Maybe<Scalars['String']>;
  /** The emergency contact phone number country for the person */
  emergencyContactPhoneNumberCountry?: Maybe<Country>;
  /** The emergency contact relationship for the person */
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** The first name for the person */
  firstName?: Maybe<Scalars['String']>;
  /** The gender for the person */
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  /**
   * The jobs that the person will have, have had or currently has.
   * Jobs are returned in order of isPrimaryJob true first, and then ordering by their
   * start dates in descending order (so past jobs will be last, unless they are the primary
   * job on this person).
   */
  jobs: Array<Job>;
  /** The last name for the person */
  lastName?: Maybe<Scalars['String']>;
  medicalConditions: Array<OnboardingMedicalCondition>;
  /** The middle name for the person */
  middleName?: Maybe<Scalars['String']>;
  onboardingDocuments: Array<OnboardingPersonDocument>;
  phoneNumbers: Array<OnboardingPhoneNumber>;
  /** The persons preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The title for the person */
  title?: Maybe<Title>;
  workRight?: Maybe<WorkRight>;
  workRightExpiryDate?: Maybe<Scalars['DateTime']>;
};


export type OnboardingPersoncustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type OnboardingPersonjobsArgs = {
  whereJobIsPrimaryOrActive?: InputMaybe<Scalars['Boolean']>;
};

export type OnboardingPersonDocument = {
  __typename?: 'OnboardingPersonDocument';
  document: PersonDocument;
  onboardingTemplateDocumentFolderId: Scalars['ID'];
};

export type OnboardingPersonDocumentCreateInput = {
  /** The extension of the document, not including the dot. */
  extension: Scalars['String'];
  /** The original filename of the document. This will be used for display name. Includes extension */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The ID of the onboarding template document folder which this document is for */
  onboardingTemplateDocumentFolderId: Scalars['ID'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type OnboardingPersonDocumentCreateResponse = {
  __typename?: 'OnboardingPersonDocumentCreateResponse';
  document: Document;
  /** A URL to upload the document to */
  presignedUrl: Scalars['String'];
};

export type OnboardingPersonDocumentDeleteInput = {
  /** The ID of the document to delete */
  documentId: Scalars['ID'];
  /** The ID of the onboarding template document folder which this document is for */
  onboardingTemplateDocumentFolderId: Scalars['String'];
};

export type OnboardingPersonDocumentDeleteResponse = {
  __typename?: 'OnboardingPersonDocumentDeleteResponse';
  documentId: Scalars['ID'];
};

export type OnboardingPersonDocumentUpdateInput = {
  /** New display name to give the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document to update */
  documentId: Scalars['ID'];
  /** The ID of the onboarding template document folder which this document is for */
  onboardingTemplateDocumentFolderId: Scalars['String'];
  /** New upload status to set the document to */
  uploadStatus?: InputMaybe<PersonDocumentUploadStatus>;
};

export type OnboardingPersonDocumentUpdateResponse = {
  __typename?: 'OnboardingPersonDocumentUpdateResponse';
  document: PersonDocument;
};

export type OnboardingPhoneNumber = {
  __typename?: 'OnboardingPhoneNumber';
  country?: Maybe<Country>;
  customFieldValues: Array<CustomFieldValue>;
  id: Scalars['ID'];
  number: Scalars['String'];
};


export type OnboardingPhoneNumbercustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type OnboardingPhoneNumberInput = {
  /** The country that belongs to this phone number */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for the phone number */
  customFieldValues?: InputMaybe<Array<PhoneNumberCustomFieldValueInput>>;
  /** The number of the phone number */
  number: Scalars['String'];
};

export type OnboardingQualificationInstanceAttachmentCreateInput = {
  /** The extension of the document, not including the dot. */
  extension: Scalars['String'];
  /** The original filename of the document. This will be used for display name. Includes extension */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The ID of the qualification instance the document will be attached to */
  qualificationInstanceId: Scalars['ID'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type OnboardingQualificationInstanceAttachmentCreateResponse = {
  __typename?: 'OnboardingQualificationInstanceAttachmentCreateResponse';
  document: PersonDocument;
  presignedUploadUrl: Scalars['String'];
};

export type OnboardingQualificationInstanceAttachmentDeleteResponse = {
  __typename?: 'OnboardingQualificationInstanceAttachmentDeleteResponse';
  documentId: Scalars['ID'];
};

export type OnboardingQualificationInstanceAttachmentUpdateInput = {
  /** New display name to give the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document to update */
  documentId: Scalars['ID'];
  /** The ID of the folder where the document will be stored */
  folderId?: InputMaybe<Scalars['String']>;
  /** New upload status to set the document to */
  uploadStatus?: InputMaybe<PersonDocumentUploadStatus>;
};

export type OnboardingQualificationInstanceAttachmentUpdateResponse = {
  __typename?: 'OnboardingQualificationInstanceAttachmentUpdateResponse';
  document: PersonDocument;
};

export type OnboardingQualificationInstanceCreateInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  expiryNotificationQueuedAt?: InputMaybe<Scalars['DateTime']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganisation?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  qualificationStatusId: Scalars['ID'];
  qualificationTemplateId: Scalars['ID'];
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type OnboardingSession = {
  __typename?: 'OnboardingSession';
  businessEntity: BusinessEntity;
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  issuedAt: Scalars['DateTime'];
  job: Job;
  onboardingSteps: Array<OnboardingStep>;
  person: Person;
};

export enum OnboardingSessionStatusOption {
  ALL = 'ALL',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export type OnboardingSetupInput = {
  /** Job to be onboarded. If not provided the primary job is used */
  jobId?: InputMaybe<Scalars['ID']>;
  /**
   * The steps to be completed as part of onboarding
   *
   * Note that validation will fail if the PERSONAL_DETAILS and REQUIRED_QUALIFICATIONS steps are not provided
   */
  onboardingSteps: Array<OnboardingSteps>;
  /**
   * The onboarding form that the user should complete during the personal details onboarding step
   *
   * Required if the person details step is included in the onboardingSteps field
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
};

export type OnboardingStatus = {
  __typename?: 'OnboardingStatus';
  /** The current onboarding step. nullable for cases when the user is not in onboarding */
  currentStep?: Maybe<OnboardingStep>;
  /** Convenience boolean check to mark onboarding completion */
  isOnboarding: Scalars['Boolean'];
  /** All Onboarding steps required for this user to complete */
  onboardingSteps: Array<OnboardingStep>;
};

export type OnboardingStep = {
  __typename?: 'OnboardingStep';
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  job?: Maybe<Job>;
  metadata?: Maybe<Scalars['JSON']>;
  onboardingFormInstance?: Maybe<OnboardingFormInstance>;
  onboardingSessionId: Scalars['ID'];
  onboardingStepType: OnboardingStepType;
  person: Person;
  sortOrder: Scalars['Int'];
};

export type OnboardingStepType = {
  __typename?: 'OnboardingStepType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum OnboardingSteps {
  /** Update the person's details. Requires an  onboarding form template to be provided */
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  /**
   * Provide Qualifications for job requirements attached to the job being onboarded.
   *
   * Skipped if the job has no job requirements
   */
  REQUIRED_QUALIFICATIONS = 'REQUIRED_QUALIFICATIONS',
  /** Update the person's bank/tax/super for Australians */
  SUPER_API = 'SUPER_API'
}

export type OnboardingTemplate = {
  __typename?: 'OnboardingTemplate';
  createdAt: Scalars['DateTime'];
  customFieldCategories: Array<OnboardingTemplateCustomFieldCategory>;
  /** The custom form fields in the template */
  customFields: Array<OnboardingTemplateCustomField>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The document folders in the template */
  documentFolders: Array<OnboardingTemplateDocumentFolder>;
  /** The form fields in the template */
  fields: Array<OnboardingTemplateField>;
  /** The number of form instances based on this template */
  formInstanceCount: Scalars['Int'];
  /** The number of form instances based on this template that have not yet been completed */
  formInstancePendingCount: Scalars['Int'];
  /** The form instances based on this template */
  formInstances: Array<OnboardingFormInstance>;
  id: Scalars['ID'];
  /** Whether this template is enabled */
  isEnabled: Scalars['Boolean'];
  /** The name of the template */
  name: Scalars['String'];
  /** The form sections in the template */
  sections: Array<OnboardingTemplateSection>;
  updatedAt: Scalars['DateTime'];
};

export type OnboardingTemplateCreateInput = {
  customFieldSections?: InputMaybe<Array<OnboardingTemplateCustomFieldSectionCreateInput>>;
  customFields: Array<OnboardingTemplateCustomFieldCreateInput>;
  fields: Array<OnboardingTemplateFieldCreateInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sections: Array<OnboardingTemplateSectionCreateInput>;
};

export type OnboardingTemplateCreateInputV2 = {
  customFieldSections?: InputMaybe<Array<OnboardingTemplateCustomFieldSectionCreateInput>>;
  customFields: Array<OnboardingTemplateCustomFieldCreateInput>;
  documentFolders: Array<OnboardingTemplateDocumentFolderCreateInput>;
  fields: Array<OnboardingTemplateFieldCreateInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sections: Array<OnboardingTemplateSectionCreateInput>;
};

export type OnboardingTemplateCreateResponse = {
  __typename?: 'OnboardingTemplateCreateResponse';
  onboardingTemplate: OnboardingTemplate;
};

export type OnboardingTemplateCustomField = {
  __typename?: 'OnboardingTemplateCustomField';
  customFieldDefinition: CustomFieldDefinition;
  id: Scalars['ID'];
  sectionType?: Maybe<OnboardingTemplateSectionType>;
  sortOrder: Scalars['Int'];
  templateId: Scalars['ID'];
  visibility: OnboardingFieldVisibilityType;
};

export type OnboardingTemplateCustomFieldCategory = {
  __typename?: 'OnboardingTemplateCustomFieldCategory';
  customFieldCategory: CustomFieldCategory;
  customFieldCategoryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  onboardingTemplate: OnboardingTemplate;
};

export type OnboardingTemplateCustomFieldCreateInput = {
  customFieldDefinitionId: Scalars['ID'];
  sortOrder: Scalars['Int'];
  visibilityTypeId: Scalars['ID'];
};

export type OnboardingTemplateCustomFieldSectionCreateInput = {
  customFieldCategoryId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type OnboardingTemplateCustomFieldSectionUpdateInput = {
  customFieldCategoryId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type OnboardingTemplateCustomFieldUpdateInput = {
  customFieldDefinitionId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
  visibilityTypeId: Scalars['ID'];
};

export type OnboardingTemplateDeleteResponse = {
  __typename?: 'OnboardingTemplateDeleteResponse';
  id: Scalars['ID'];
};

export type OnboardingTemplateDocumentFolder = {
  __typename?: 'OnboardingTemplateDocumentFolder';
  documentFolderId: Scalars['ID'];
  id: Scalars['ID'];
  label: Scalars['String'];
  visibility: OnboardingFieldVisibilityType;
};

export type OnboardingTemplateDocumentFolderCreateInput = {
  documentFolderId: Scalars['ID'];
  label: Scalars['String'];
  visibilityId: Scalars['ID'];
};

export type OnboardingTemplateDocumentFolderUpdateInput = {
  documentFolderId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  visibilityId: Scalars['ID'];
};

export type OnboardingTemplateField = {
  __typename?: 'OnboardingTemplateField';
  fieldType: OnboardingTemplateFieldType;
  id: Scalars['ID'];
  templateId: Scalars['ID'];
  visibility: OnboardingFieldVisibilityType;
};

export type OnboardingTemplateFieldCreateInput = {
  fieldTypeId: Scalars['ID'];
  visibilityTypeId: Scalars['ID'];
};

export type OnboardingTemplateFieldType = {
  __typename?: 'OnboardingTemplateFieldType';
  id: Scalars['ID'];
  modelField?: Maybe<Scalars['String']>;
  modelType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sectionTypeId: Scalars['ID'];
  sortOrder: Scalars['Int'];
  visibilityIds: Scalars['JSON'];
  visibilityOptions: Array<OnboardingFieldVisibilityType>;
};

export type OnboardingTemplateFieldUpdateInput = {
  fieldTypeId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  visibilityTypeId: Scalars['ID'];
};

export type OnboardingTemplateSection = {
  __typename?: 'OnboardingTemplateSection';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sectionType: OnboardingTemplateSectionType;
  templateId: Scalars['ID'];
};

export type OnboardingTemplateSectionCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  sectionTypeId: Scalars['ID'];
};

export type OnboardingTemplateSectionType = {
  __typename?: 'OnboardingTemplateSectionType';
  customFields: Array<CustomFieldDefinition>;
  /** All document folders, including system folders */
  documentFolders: Array<DocumentFolder>;
  fields: Array<OnboardingTemplateFieldType>;
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type OnboardingTemplateSectionUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  sectionTypeId: Scalars['ID'];
};

export type OnboardingTemplateUpdateInput = {
  customFieldSections?: InputMaybe<Array<OnboardingTemplateCustomFieldSectionUpdateInput>>;
  customFields?: InputMaybe<Array<OnboardingTemplateCustomFieldUpdateInput>>;
  documentFolders?: InputMaybe<Array<OnboardingTemplateDocumentFolderUpdateInput>>;
  fields?: InputMaybe<Array<OnboardingTemplateFieldUpdateInput>>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sections?: InputMaybe<Array<OnboardingTemplateSectionUpdateInput>>;
};

export type OnboardingTemplateUpdateResponse = {
  __typename?: 'OnboardingTemplateUpdateResponse';
  onboardingTemplate: OnboardingTemplate;
};

export type OptionValue = {
  __typename?: 'OptionValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum OrgObjectiveBannerColor {
  blue = 'blue',
  green = 'green',
  grey = 'grey',
  lightblue = 'lightblue',
  orange = 'orange',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  yellow = 'yellow'
}

export type OrgObjectiveCreateInput = {
  bannerColor: OrgObjectiveBannerColor;
  endDate: Scalars['DateTime'];
  measurements?: InputMaybe<Array<OrgObjectiveMeasurementInput>>;
  name: Scalars['String'];
  publishDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  undrawBannerId: Scalars['ID'];
};

export type OrgObjectiveCreateResponse = {
  __typename?: 'OrgObjectiveCreateResponse';
  organisationObjective?: Maybe<OrganisationObjective>;
};

export type OrgObjectiveDeleteResponse = {
  __typename?: 'OrgObjectiveDeleteResponse';
  id: Scalars['ID'];
};

export type OrgObjectiveMeasurementInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  startingValue?: InputMaybe<Scalars['Float']>;
  targetValue?: InputMaybe<Scalars['Float']>;
  type: Scalars['ID'];
  unit?: InputMaybe<Scalars['String']>;
};

export enum OrgObjectiveStatus {
  active = 'active',
  archived = 'archived',
  past = 'past',
  upcoming = 'upcoming'
}

export type OrgObjectiveUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  bannerColor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  measurements?: InputMaybe<Array<OrgObjectiveMeasurementInput>>;
  name?: InputMaybe<Scalars['String']>;
  publishDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  undrawBannerId?: InputMaybe<Scalars['ID']>;
};

export type OrgObjectiveUpdateMeasurementValueInput = {
  /** The current value of the organisation objective measurement */
  currentValue: Scalars['Float'];
  /** The organisation objective measurement to edit the value of */
  id: Scalars['ID'];
};

export type OrgObjectiveUpdateMeasurementValueResponse = {
  __typename?: 'OrgObjectiveUpdateMeasurementValueResponse';
  organisationObjectiveMeasurement: OrganisationObjectiveMeasurement;
};

export type OrgObjectiveUpdateResponse = {
  __typename?: 'OrgObjectiveUpdateResponse';
  organisationObjective?: Maybe<OrganisationObjective>;
};

export type OrganisationObjective = {
  __typename?: 'OrganisationObjective';
  /** The organisation objective was archived */
  archivedAt?: Maybe<Scalars['DateTime']>;
  /** The banner image of the organisation objective */
  banner: UndrawBanner;
  /** The color theme of the organisation objective banner */
  bannerColor: OrgObjectiveBannerColor;
  /** The date when all measurements were completed at */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The number of goals that are directly aligned to this organisation objective */
  directlyAlignedGoalsCount: Scalars['Int'];
  /** The date the organisation objective will end */
  endDate: Scalars['DateTime'];
  /** The ID of the organisation objective. */
  id: Scalars['ID'];
  /** The measurements of the organisation objective */
  measurements: Array<OrganisationObjectiveMeasurement>;
  /** The name of the organisation objective. */
  name: Scalars['String'];
  /** The progress to completion of the org objective */
  progress: Scalars['Float'];
  /** The date and time when the organisation objective will be viewable */
  publishDate: Scalars['DateTime'];
  /** The date the organisation objective will begin */
  startDate: Scalars['DateTime'];
  /** The status of the org objective */
  status: OrgObjectiveStatus;
};

export type OrganisationObjectiveMeasurement = {
  __typename?: 'OrganisationObjectiveMeasurement';
  /** The date the measurement targetValue was equal to the currentValue */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** The current value of the measurement */
  currentValue: Scalars['Float'];
  /** The ID of the org objective measurement */
  id: Scalars['ID'];
  /** The type of measurement */
  measurementType: GoalMeasurementType;
  /** The name of the measurement */
  name: Scalars['String'];
  /** The organisation objective the measurement belongs to */
  orgObjective: OrganisationObjective;
  /** The progress to completion of the org objective measurement */
  progress: Scalars['Float'];
  /** The sort order of the measurement */
  sortOrder: Scalars['Int'];
  /** The starting value of the measurement */
  startingValue: Scalars['Float'];
  /** The target value of the measurement */
  targetValue: Scalars['Float'];
  /** The unit of measurement */
  unit?: Maybe<Scalars['String']>;
};

/** A paginated list of BulkDataImportAction items. */
export type PaginatedBulkDataImportAction = PaginationResponse & {
  __typename?: 'PaginatedBulkDataImportAction';
  /** A list of BulkDataImportAction items. */
  actions: Array<BulkDataImportAction>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedBulkUserCreationCandidateResponse = PaginationResponse & {
  __typename?: 'PaginatedBulkUserCreationCandidateResponse';
  /** List of people and thier reason for not being able to have a user account created if relevant */
  candidates: Array<Maybe<BulkUserCreationCandidates>>;
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** A paginated list of BusinessEntity items. */
export type PaginatedBusinessEntities = PaginationResponse & {
  __typename?: 'PaginatedBusinessEntities';
  /** A list of BusinessEntity items. */
  businessEntities: Array<BusinessEntity>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of BusinessUnit items. */
export type PaginatedBusinessUnits = PaginationResponse & {
  __typename?: 'PaginatedBusinessUnits';
  /** A list of BusinessUnit items. */
  businessUnits: Array<BusinessUnit>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of CompensationPlan items. */
export type PaginatedCompensationPlans = PaginationResponse & {
  __typename?: 'PaginatedCompensationPlans';
  /** A list of CompensationPlan items. */
  compensationPlans: Array<CompensationPlan>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of CompositeStandaloneQualification items. */
export type PaginatedCompositeStandaloneQualification = PaginationResponse & {
  __typename?: 'PaginatedCompositeStandaloneQualification';
  /** A list of CompositeStandaloneQualification items. */
  CompositeStandaloneQualifications: Array<CompositeStandaloneQualification>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedCompositeStandaloneQualificationV2 = PaginationResponse & {
  __typename?: 'PaginatedCompositeStandaloneQualificationV2';
  /** the composite qualifications for the current page */
  compositeStandaloneQualifications?: Maybe<Array<CompositeStandaloneQualificationV2>>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Contract items. */
export type PaginatedContracts = PaginationResponse & {
  __typename?: 'PaginatedContracts';
  /** A list of Contract items. */
  contracts: Array<Contract>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Country items. */
export type PaginatedCountries = PaginationResponse & {
  __typename?: 'PaginatedCountries';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** A list of Country items. */
  countries: Array<Country>;
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Currency items. */
export type PaginatedCurrencies = PaginationResponse & {
  __typename?: 'PaginatedCurrencies';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** A list of Currency items. */
  currencies: Array<Currency>;
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of CustomFieldDefinition items. */
export type PaginatedCustomFieldDefinitions = PaginationResponse & {
  __typename?: 'PaginatedCustomFieldDefinitions';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of CustomFieldDefinition items. */
  customFieldDefinitions: Array<CustomFieldDefinition>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of CustomFieldTemplateCategory items. */
export type PaginatedCustomFieldTemplateCategories = PaginationResponse & {
  __typename?: 'PaginatedCustomFieldTemplateCategories';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of CustomFieldTemplateCategory items. */
  customFieldTemplateCategories: Array<CustomFieldTemplateCategory>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of DefaultRemunerationComponent items. */
export type PaginatedDefaultRemunerationComponents = PaginationResponse & {
  __typename?: 'PaginatedDefaultRemunerationComponents';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of DefaultRemunerationComponent items. */
  defaultRemunerationComponents: Array<DefaultRemunerationComponent>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of PersonDocument items. */
export type PaginatedDocuments = PaginationResponse & {
  __typename?: 'PaginatedDocuments';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of PersonDocument items. */
  documents: Array<PersonDocument>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of EmploymentCondition items. */
export type PaginatedEmploymentConditions = PaginationResponse & {
  __typename?: 'PaginatedEmploymentConditions';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of EmploymentCondition items. */
  employmentConditions: Array<EmploymentCondition>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Establishment items. */
export type PaginatedEstablishments = PaginationResponse & {
  __typename?: 'PaginatedEstablishments';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of Establishment items. */
  establishments: Array<Establishment>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of ExternalEventLog items. */
export type PaginatedExternalEventLogs = PaginationResponse & {
  __typename?: 'PaginatedExternalEventLogs';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of ExternalEventLog items. */
  externalEventLogs: Array<ExternalEventLog>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of ExternalWebhook items. */
export type PaginatedExternalWebhooks = PaginationResponse & {
  __typename?: 'PaginatedExternalWebhooks';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of ExternalWebhook items. */
  externalWebhooks: Array<ExternalWebhook>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Form items. */
export type PaginatedForms = PaginationResponse & {
  __typename?: 'PaginatedForms';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of Form items. */
  forms: Array<Form>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of GlobalNotificationConfiguration items. */
export type PaginatedGlobalNotificationConfigurations = PaginationResponse & {
  __typename?: 'PaginatedGlobalNotificationConfigurations';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of GlobalNotificationConfiguration items. */
  globalNotificationConfigurations: Array<GlobalNotificationConfiguration>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of GoalCategory items. */
export type PaginatedGoalCategories = PaginationResponse & {
  __typename?: 'PaginatedGoalCategories';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** A list of GoalCategory items. */
  goalCategories: Array<GoalCategory>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedGroupCompositeQualificationsV2 = PaginationResponse & {
  __typename?: 'PaginatedGroupCompositeQualificationsV2';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** the composite qualifications for the current page */
  groupCompositeQualifications?: Maybe<Array<GroupCompositeQualificationStatsV2>>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedGroupQualificationComplianceOverviewsV2 = PaginationResponse & {
  __typename?: 'PaginatedGroupQualificationComplianceOverviewsV2';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** the composite qualifications for the current page */
  groupOverviews?: Maybe<Array<GroupComplianceOverview>>;
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of HourlyRateType items. */
export type PaginatedHourlyRateTypes = PaginationResponse & {
  __typename?: 'PaginatedHourlyRateTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of HourlyRateType items. */
  hourlyRateTypes: Array<HourlyRateType>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of IntegrationLog items. */
export type PaginatedIntegrationLogs = PaginationResponse & {
  __typename?: 'PaginatedIntegrationLogs';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of IntegrationLog items. */
  integrationLogs: Array<IntegrationLog>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of IntegrationMapping items. */
export type PaginatedIntegrationMappings = PaginationResponse & {
  __typename?: 'PaginatedIntegrationMappings';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of IntegrationMapping items. */
  integrationMappings: Array<IntegrationMapping>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of JobChangeReason items. */
export type PaginatedJobChangeReason = PaginationResponse & {
  __typename?: 'PaginatedJobChangeReason';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of JobChangeReason items. */
  jobChangeReasons: Array<JobChangeReason>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of JobRequirementGroup items. */
export type PaginatedJobRequirementGroups = PaginationResponse & {
  __typename?: 'PaginatedJobRequirementGroups';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of JobRequirementGroup items. */
  jobRequirementGroups: Array<JobRequirementGroup>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of JobRequirement items. */
export type PaginatedJobRequirements = PaginationResponse & {
  __typename?: 'PaginatedJobRequirements';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of JobRequirement items. */
  jobRequirements: Array<JobRequirement>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Job items. */
export type PaginatedJobs = PaginationResponse & {
  __typename?: 'PaginatedJobs';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of Job items. */
  jobs: Array<Job>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of LeaveType items. */
export type PaginatedLeaveTypes = PaginationResponse & {
  __typename?: 'PaginatedLeaveTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of LeaveType items. */
  leaveTypes: Array<LeaveType>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Location items. */
export type PaginatedLocations = PaginationResponse & {
  __typename?: 'PaginatedLocations';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of Location items. */
  locations: Array<Location>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of MessageSchedule items. */
export type PaginatedMessageSchedules = PaginationResponse & {
  __typename?: 'PaginatedMessageSchedules';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of MessageSchedule items. */
  messageSchedules: Array<MessageSchedule>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Note items. */
export type PaginatedNotes = PaginationResponse & {
  __typename?: 'PaginatedNotes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of Note items. */
  notes: Array<Note>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of OnboardingFormInstance items. */
export type PaginatedOnboardingFormInstances = PaginationResponse & {
  __typename?: 'PaginatedOnboardingFormInstances';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of OnboardingFormInstance items. */
  onboardingFormInstances: Array<OnboardingFormInstance>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of PayCycle items. */
export type PaginatedPayCycles = PaginationResponse & {
  __typename?: 'PaginatedPayCycles';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of PayCycle items. */
  payCycles: Array<PayCycle>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of PayGrade items. */
export type PaginatedPayGrades = PaginationResponse & {
  __typename?: 'PaginatedPayGrades';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of PayGrade items. */
  payGrades: Array<PayGrade>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Person items. */
export type PaginatedPeople = PaginationResponse & {
  __typename?: 'PaginatedPeople';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** A list of Person items. */
  people: Array<Person>;
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of PermissionGroup items. */
export type PaginatedPermissionGroups = PaginationResponse & {
  __typename?: 'PaginatedPermissionGroups';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of PermissionGroup items. */
  permissionGroups: Array<PermissionGroup>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedPermissionsForPermissionGroupResponse = PaginationResponse & {
  __typename?: 'PaginatedPermissionsForPermissionGroupResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  permissions: Array<PermissionEntry>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** A paginated list of PositionTitle items. */
export type PaginatedPositionTitles = PaginationResponse & {
  __typename?: 'PaginatedPositionTitles';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of PositionTitle items. */
  positionTitles: Array<PositionTitle>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Pulse items. */
export type PaginatedPulses = PaginationResponse & {
  __typename?: 'PaginatedPulses';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of Pulse items. */
  pulses: Array<Pulse>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of QualificationCompositeCompliance items. */
export type PaginatedQualificationCompositeCompliance = PaginationResponse & {
  __typename?: 'PaginatedQualificationCompositeCompliance';
  /** A list of QualificationCompositeCompliance items. */
  compositeCompliance: Array<QualificationCompositeCompliance>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedQualificationCompositeComplianceV2 = PaginationResponse & {
  __typename?: 'PaginatedQualificationCompositeComplianceV2';
  /** the composite qualifications for the current page */
  compositeCompliance?: Maybe<Array<QualificationCompositeComplianceV2>>;
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of QualificationInstanceV2 items. */
export type PaginatedQualificationInstances = PaginationResponse & {
  __typename?: 'PaginatedQualificationInstances';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of QualificationInstanceV2 items. */
  qualificationInstances: Array<QualificationInstanceV2>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of QualificationStatuses items. */
export type PaginatedQualificationStatuses = PaginationResponse & {
  __typename?: 'PaginatedQualificationStatuses';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of QualificationStatuses items. */
  qualificationStatuses: Array<QualificationStatuses>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of QualificationTemplate items. */
export type PaginatedQualificationTemplates = PaginationResponse & {
  __typename?: 'PaginatedQualificationTemplates';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of QualificationTemplate items. */
  qualificationTemplates: Array<QualificationTemplate>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of QualificationTypeV2 items. */
export type PaginatedQualificationTypes = PaginationResponse & {
  __typename?: 'PaginatedQualificationTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of QualificationTypeV2 items. */
  qualificationTypes: Array<QualificationTypeV2>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of RecruitmentSource items. */
export type PaginatedRecruitmentSources = PaginationResponse & {
  __typename?: 'PaginatedRecruitmentSources';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of RecruitmentSource items. */
  recruitmentSources: Array<RecruitmentSource>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of RepresentativeType items. */
export type PaginatedRepresentativeTypes = PaginationResponse & {
  __typename?: 'PaginatedRepresentativeTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of RepresentativeType items. */
  representativeTypes: Array<RepresentativeType>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedSettingsPaginatedPermissionGroup = PaginationResponse & {
  __typename?: 'PaginatedSettingsPaginatedPermissionGroup';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  settingsPermissionGroupEntries: Array<SettingsPermissionGroupEntry>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** A paginated list of SkillDiscipline items. */
export type PaginatedSkillDiscipline = PaginationResponse & {
  __typename?: 'PaginatedSkillDiscipline';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of SkillDiscipline items. */
  skillDisciplines: Array<SkillDiscipline>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

/** A paginated list of Skill items. */
export type PaginatedSkills = PaginationResponse & {
  __typename?: 'PaginatedSkills';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** A list of Skill items. */
  skills: Array<Skill>;
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PaginatedTenantHeadcounts = {
  __typename?: 'PaginatedTenantHeadcounts';
  /** The afterTenant that was passed in. */
  afterTenant?: Maybe<Scalars['String']>;
  /** How many tenant headcounts are on the current page. */
  curPage: Scalars['Int'];
  /** Whether there are more tenant headcounts after this page. */
  hasMore: Scalars['Boolean'];
  /** The list of headcounts. */
  headcounts: Array<TenantHeadcount>;
  /** The last tenant that was returned in this page of results. Use this param in afterTenant to get the next page. */
  lastTenant?: Maybe<Scalars['String']>;
  /** The limit that was passed in. */
  limit?: Maybe<Scalars['Int']>;
  /** The total amount of tenants that headcounts exist for */
  total?: Maybe<Scalars['Int']>;
};

/** A paginated list of TrainingProvider items. */
export type PaginatedTrainingProviders = PaginationResponse & {
  __typename?: 'PaginatedTrainingProviders';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of TrainingProvider items. */
  trainingProviders: Array<TrainingProvider>;
};

/** A paginated list of TrainingType items. */
export type PaginatedTrainingTypes = PaginationResponse & {
  __typename?: 'PaginatedTrainingTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of TrainingType items. */
  trainingTypes: Array<TrainingType>;
};

/** A paginated list of Training items. */
export type PaginatedTrainings = PaginationResponse & {
  __typename?: 'PaginatedTrainings';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of Training items. */
  trainings: Array<Training>;
};

/** A paginated list of UndrawBanner items. */
export type PaginatedUndrawBanners = PaginationResponse & {
  __typename?: 'PaginatedUndrawBanners';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of UndrawBanner items. */
  undrawBanners: Array<UndrawBanner>;
};

/** A paginated list of User items. */
export type PaginatedUsers = PaginationResponse & {
  __typename?: 'PaginatedUsers';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of User items. */
  users: Array<User>;
};

/** A paginated list of WebhookEvent items. */
export type PaginatedWebhookEvents = PaginationResponse & {
  __typename?: 'PaginatedWebhookEvents';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of WebhookEvent items. */
  webhookEvents: Array<WebhookEvent>;
};

/** A paginated list of WorkClass items. */
export type PaginatedWorkClasses = PaginationResponse & {
  __typename?: 'PaginatedWorkClasses';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of WorkClass items. */
  workClasses: Array<WorkClass>;
};

/** A paginated list of WorkRight items. */
export type PaginatedWorkRights = PaginationResponse & {
  __typename?: 'PaginatedWorkRights';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of WorkRight items. */
  workRights: Array<WorkRight>;
};

/** A paginated list of WorkType items. */
export type PaginatedWorkTypes = PaginationResponse & {
  __typename?: 'PaginatedWorkTypes';
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
  /** A list of WorkType items. */
  workTypes: Array<WorkType>;
};

export type PaginationOptions = {
  limit?: InputMaybe<Scalars['PaginationAmount']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type PaginationResponse = {
  /** The count of rows returned by the current query */
  count: Scalars['Int'];
  /** The current page that is being displayed */
  currentPage: Scalars['Int'];
  /** If there are any more pages after the current one */
  hasMore: Scalars['Boolean'];
  /** The maximum number of rows requested for the current query */
  perPage: Scalars['Int'];
  /** The total number of rows across all pages */
  total: Scalars['Int'];
  /** The total number of pages */
  totalPages: Scalars['Int'];
};

export type PayCycle = {
  __typename?: 'PayCycle';
  /** How many cycles this represents per year */
  cyclesPerYear: Scalars['Float'];
  id: Scalars['ID'];
  /** Human readable name for the pay cycle */
  name: Scalars['String'];
};

export type PayGrade = {
  __typename?: 'PayGrade';
  /** Number of Jobs that have been assigned this Pay Grade */
  activeJobs: Scalars['Int'];
  allPayGradeValues: Array<PayGradeValue>;
  /** Stores the annual salary */
  annualSalary?: Maybe<Scalars['Float']>;
  /** Stores the currency for annual salary */
  annualSalaryCurrency?: Maybe<Currency>;
  /** Stores the hourly rate for casual jobs */
  casualHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for casual jobs */
  casualHourlyRateCurrency?: Maybe<Currency>;
  /** The code for the payGrade */
  code?: Maybe<Scalars['String']>;
  /** When the payGrade was created */
  createdAt: Scalars['DateTime'];
  /** The description of the pay grade */
  description?: Maybe<Scalars['String']>;
  /** The Employment Condition linked to the pay grade */
  employmentCondition?: Maybe<EmploymentCondition>;
  id: Scalars['ID'];
  /** Is this Pay Grade enabled */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** Allow the pay grade value to be overridden */
  isOverridable?: Maybe<Scalars['Boolean']>;
  jobs: Array<Job>;
  /** Name of the payGrade */
  name: Scalars['String'];
  payGradeType: PayGradeType;
  payGradeValues: PayGradeValue;
  paySteps?: Maybe<Array<PayStep>>;
  /** Stores the hourly rate for permanent jobs */
  permanentHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for permanent jobs */
  permanentHourlyRateCurrency?: Maybe<Currency>;
  /** Order to display the payGrade */
  sortOrder: Scalars['Int'];
  /** The current Pay Grade Value ID */
  valueId: Scalars['ID'];
};


export type PayGradepayGradeValuesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};

export type PayGradeAudit = {
  actionType: PayGradeAuditActionType;
  changeUser?: Maybe<User>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  modelType: PayGradeAuditModelType;
  payGradeDeleted: Scalars['Boolean'];
  payGradeId: Scalars['ID'];
};

export enum PayGradeAuditActionType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED'
}

export type PayGradeAuditCreate = PayGradeAudit & {
  __typename?: 'PayGradeAuditCreate';
  actionType: PayGradeAuditActionType;
  changeUser?: Maybe<User>;
  date: Scalars['DateTime'];
  entityName: Scalars['String'];
  id: Scalars['ID'];
  modelType: PayGradeAuditModelType;
  payGradeDeleted: Scalars['Boolean'];
  payGradeId: Scalars['ID'];
};

export type PayGradeAuditDelete = PayGradeAudit & {
  __typename?: 'PayGradeAuditDelete';
  actionType: PayGradeAuditActionType;
  changeUser?: Maybe<User>;
  date: Scalars['DateTime'];
  entityName: Scalars['String'];
  id: Scalars['ID'];
  modelType: PayGradeAuditModelType;
  payGradeDeleted: Scalars['Boolean'];
  payGradeId: Scalars['ID'];
};

export type PayGradeAuditExportUrlResponse = {
  __typename?: 'PayGradeAuditExportUrlResponse';
  csvUrl: Scalars['String'];
};

export type PayGradeAuditListResponse = PaginationResponse & {
  __typename?: 'PayGradeAuditListResponse';
  auditRecords: Array<PayGradeAudit>;
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum PayGradeAuditModelType {
  PAY_GRADE = 'PAY_GRADE',
  PAY_GRADE_VALUE = 'PAY_GRADE_VALUE'
}

export enum PayGradeAuditSort {
  ACTION_ASCENDING = 'ACTION_ASCENDING',
  ACTION_DESCENDING = 'ACTION_DESCENDING',
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
  DISPLAY_NAME_ASCENDING = 'DISPLAY_NAME_ASCENDING',
  DISPLAY_NAME_DESCENDING = 'DISPLAY_NAME_DESCENDING',
  FROM_ASCENDING = 'FROM_ASCENDING',
  FROM_DESCENDING = 'FROM_DESCENDING',
  PAY_GRADE_ID_ASCENDING = 'PAY_GRADE_ID_ASCENDING',
  PAY_GRADE_ID_DESCENDING = 'PAY_GRADE_ID_DESCENDING',
  TO_ASCENDING = 'TO_ASCENDING',
  TO_DESCENDING = 'TO_DESCENDING'
}

export type PayGradeAuditUpdate = PayGradeAudit & {
  __typename?: 'PayGradeAuditUpdate';
  actionType: PayGradeAuditActionType;
  changeUser?: Maybe<User>;
  columnName: Scalars['String'];
  date: Scalars['DateTime'];
  fromValue: Scalars['String'];
  id: Scalars['ID'];
  modelType: PayGradeAuditModelType;
  payGradeDeleted: Scalars['Boolean'];
  payGradeId: Scalars['ID'];
  toValue: Scalars['String'];
};

export type PayGradeCreateInput = {
  annualSalary?: InputMaybe<Scalars['Float']>;
  annualSalaryCurrency?: InputMaybe<Scalars['ID']>;
  casualHourlyRate?: InputMaybe<Scalars['Float']>;
  casualHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  isOverridable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  payGradeType?: InputMaybe<PayGradeType>;
  paySteps?: InputMaybe<Array<PayStepCreateInput>>;
  permanentHourlyRate?: InputMaybe<Scalars['Float']>;
  permanentHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type PayGradeCreateResponse = {
  __typename?: 'PayGradeCreateResponse';
  payGrade: PayGrade;
};

export type PayGradeDeleteResponse = {
  __typename?: 'PayGradeDeleteResponse';
  id: Scalars['ID'];
};

export type PayGradeFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type PayGradeReorderInput = {
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
};

export type PayGradeReorderResponse = {
  __typename?: 'PayGradeReorderResponse';
  payGrades: Array<PayGrade>;
};

export type PayGradeSearchObjectInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum PayGradeType {
  FIXED = 'FIXED',
  STEP = 'STEP'
}

export type PayGradeUpdateInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  payGradeType?: InputMaybe<PayGradeType>;
  payGradeValues?: InputMaybe<PayGradeValueUpdateInput>;
  paySteps?: InputMaybe<Array<PayStepUpdateInput>>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type PayGradeUpdateResponse = {
  __typename?: 'PayGradeUpdateResponse';
  payGrade: PayGrade;
};

export type PayGradeValue = {
  __typename?: 'PayGradeValue';
  /** Stores the annual salary */
  annualSalary?: Maybe<Scalars['Float']>;
  /** Stores the currency for annual salary */
  annualSalaryCurrency?: Maybe<Currency>;
  /** Stores the hourly rate for casual jobs */
  casualHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for casual jobs */
  casualHourlyRateCurrency?: Maybe<Currency>;
  changeUser?: Maybe<User>;
  /** The code for the payGrade */
  code?: Maybe<Scalars['String']>;
  /** The description of the pay grade */
  description?: Maybe<Scalars['String']>;
  /** The Employment Condition linked to the pay grade */
  employmentCondition?: Maybe<EmploymentCondition>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Allow the pay grade value to be overridden */
  isOverridable?: Maybe<Scalars['Boolean']>;
  /** Name of the payGrade */
  name: Scalars['String'];
  payGradeType: PayGradeType;
  /** Stores the hourly rate for permanent jobs */
  permanentHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for permanent jobs */
  permanentHourlyRateCurrency?: Maybe<Currency>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PayGradeValueUpdateInput = {
  annualSalary?: InputMaybe<Scalars['Float']>;
  annualSalaryCurrency?: InputMaybe<Scalars['ID']>;
  casualHourlyRate?: InputMaybe<Scalars['Float']>;
  casualHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  effectiveFrom?: InputMaybe<Scalars['DateTime']>;
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
  employmentConditionId?: InputMaybe<Scalars['ID']>;
  isOverridable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  permanentHourlyRate?: InputMaybe<Scalars['Float']>;
  permanentHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
};

export type PayStep = {
  __typename?: 'PayStep';
  /** Number of Jobs that have been assigned this Pay Step */
  activeJobs: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  payGrade: PayGrade;
  value?: Maybe<PayStepValue>;
  values: Array<PayStepValue>;
};


export type PayStepvalueArgs = {
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type PayStepCreateInput = {
  annualSalary?: InputMaybe<Scalars['Float']>;
  annualSalaryCurrency?: InputMaybe<Scalars['ID']>;
  casualHourlyRate?: InputMaybe<Scalars['Float']>;
  casualHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  permanentHourlyRate?: InputMaybe<Scalars['Float']>;
  permanentHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
};

export type PayStepUpdateInput = {
  annualSalary?: InputMaybe<Scalars['Float']>;
  annualSalaryCurrency?: InputMaybe<Scalars['ID']>;
  casualHourlyRate?: InputMaybe<Scalars['Float']>;
  casualHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  permanentHourlyRate?: InputMaybe<Scalars['Float']>;
  permanentHourlyRateCurrency?: InputMaybe<Scalars['ID']>;
};

export type PayStepValue = {
  __typename?: 'PayStepValue';
  /** Stores the annual salary */
  annualSalary?: Maybe<Scalars['Float']>;
  /** Stores the currency for annual salary */
  annualSalaryCurrency?: Maybe<Currency>;
  /** Stores the hourly rate for casual jobs */
  casualHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for casual jobs */
  casualHourlyRateCurrency?: Maybe<Currency>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Stores the hourly rate for permanent jobs */
  permanentHourlyRate?: Maybe<Scalars['Float']>;
  /** Stores the currency for permanent jobs */
  permanentHourlyRateCurrency?: Maybe<Currency>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum PayrollDetailType {
  BANK = 'BANK',
  SUPERANNUATION = 'SUPERANNUATION',
  TAX = 'TAX'
}

export enum PeopleDirectoryEmploymentStatusFilter {
  CURRENT = 'CURRENT',
  CURRENT_OR_EXITING = 'CURRENT_OR_EXITING',
  EXITING = 'EXITING',
  NO_JOB_RECORDS = 'NO_JOB_RECORDS',
  PAST = 'PAST',
  UPCOMING_OR_RETURNING = 'UPCOMING_OR_RETURNING'
}

export type PeopleDirectoryListResponse = PaginationResponse & {
  __typename?: 'PeopleDirectoryListResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  people: Array<PeopleDirectoryResult>;
  perPage: Scalars['Int'];
  /**
   * If filters were provided that could potentially return different results
   * based on the users permission, they will be returned in this array as a string
   * referencing the filter that is permission scoped.
   *
   * For people custom filters, this will be the api_name of the filter
   * prepended with "people.custom_fields.".
   * E.g. for a custom field with apiName of "favourite_food", if it is provided in the
   * list of fields to filter on, then it will appear in this array as the string
   * "people.custom_fields.favourite_food".
   */
  permissionScopedFilters: Array<Scalars['String']>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PeopleDirectoryPeopleCustomFieldFilter = {
  /** The api name for the custom field */
  apiName: Scalars['String'];
  /** Used for multi select custom fields, checks if the result contains at least one of the selected */
  multiSelectContainsOneOf?: InputMaybe<Array<Scalars['String']>>;
  /** Used for people dropdowns, checks if the result contains at least one of the (personId, jobId) selected */
  peopleDropdownContainsOneOf?: InputMaybe<Array<PeopleDirectoryPeopleCustomFieldFilterPeopleDropdownContains>>;
  /** Used for single selects, checks if the result equals the given string */
  singleSelectEquals?: InputMaybe<Array<Scalars['String']>>;
};

export type PeopleDirectoryPeopleCustomFieldFilterPeopleDropdownContains = {
  /** Optionally, the job this filter can contain, to constrain further */
  jobId?: InputMaybe<Scalars['String']>;
  /** The person id this filter must contain */
  personId: Scalars['String'];
};

export type PeopleDirectoryResult = {
  __typename?: 'PeopleDirectoryResult';
  /** The person */
  person: Person;
  /** When the person started working at the company */
  tenureStartDate?: Maybe<Scalars['DateTime']>;
};

export enum PeopleDirectorySortDirection {
  DISPLAY_NAME_ASCENDING = 'DISPLAY_NAME_ASCENDING',
  DISPLAY_NAME_DESCENDING = 'DISPLAY_NAME_DESCENDING',
  TENURE_START_DATE_ASCENDING = 'TENURE_START_DATE_ASCENDING',
  TENURE_START_DATE_DESCENDING = 'TENURE_START_DATE_DESCENDING'
}

export enum PeopleDropdownSearchNoResultsReason {
  /**
   * hierarchicalPermissionsFilter resulted in no people you have permission over
   * in the entire system, regardless of search term
   */
  NO_PERMISSION = 'NO_PERMISSION',
  /** There were no people matching your search terms */
  NO_RESULTS = 'NO_RESULTS',
  /** Your search terms were too short and not restrictive enough */
  SEARCH_TERM_TOO_SHORT = 'SEARCH_TERM_TOO_SHORT'
}

export type PeopleDropdownSearchObjectArrayInput = {
  apiName?: InputMaybe<Scalars['String']>;
  personAndJobSearchObjects?: InputMaybe<Array<InputMaybe<PersonAndJobSearchObjectInput>>>;
};

export type PeopleDropdownSearchResponse = {
  __typename?: 'PeopleDropdownSearchResponse';
  /** The reason why no results were returned from this search */
  noResultsReason?: Maybe<PeopleDropdownSearchNoResultsReason>;
  /** Results of the people dropdown search */
  results: Array<PeopleDropdownSearchResult>;
};

export type PeopleDropdownSearchResult = {
  __typename?: 'PeopleDropdownSearchResult';
  /** The business unit this person/job belongs to, if they have one. For no job records, this is null. */
  businessUnit?: Maybe<BusinessUnit>;
  /**
   * The job which matched for this result.
   * If this job isn't matched for the text they search, this will be the primary job.
   * For no job records, this is null.
   */
  job?: Maybe<Job>;
  /** Other jobs the person has which aren't provided by 'job' (for ease of displaying in the frontend) */
  otherJobs: Array<Job>;
  /** The person who was matched for this result */
  person: Person;
};

export type PeopleDropdownValues = {
  __typename?: 'PeopleDropdownValues';
  apiName?: Maybe<Scalars['String']>;
  jobIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  personIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PeopleJobSearchFlattenedInput = {
  filterInactive?: InputMaybe<Scalars['Boolean']>;
  filterNoJobRecords?: InputMaybe<Scalars['Boolean']>;
  filterPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['PaginationAmount']>;
  preloadedPeople?: InputMaybe<Array<InputMaybe<PeopleJobSearchFlattenedPreloadedPeopleInput>>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type PeopleJobSearchFlattenedPreloadedPeopleInput = {
  jobId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['String']>;
};

export type PeopleJobSearchGroupedInput = {
  filterInactive?: InputMaybe<Scalars['Boolean']>;
  filterNoJobRecords?: InputMaybe<Scalars['Boolean']>;
  filterPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['PaginationAmount']>;
  preloadedPeople?: InputMaybe<Array<InputMaybe<PeopleJobSearchGroupedPreloadedPeopleInput>>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type PeopleJobSearchGroupedPreloadedPeopleInput = {
  jobId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['String']>;
};

export type PeopleJobSearchQuery = {
  __typename?: 'PeopleJobSearchQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  flattenedPeopleJobSearch: Array<FlattenedPeopleJobSearchResult>;
  groupedPeopleJobSearch: Array<GroupedPeopleSearchResult>;
};


export type PeopleJobSearchQueryflattenedPeopleJobSearchArgs = {
  input?: InputMaybe<PeopleJobSearchFlattenedInput>;
};


export type PeopleJobSearchQuerygroupedPeopleJobSearchArgs = {
  input?: InputMaybe<PeopleJobSearchGroupedInput>;
};

export type PeopleJobsWithoutOnboarding = {
  __typename?: 'PeopleJobsWithoutOnboarding';
  job?: Maybe<Job>;
  person: Person;
};

export type PerformAuthChecksResource = {
  /** Id of the resource */
  id?: InputMaybe<Scalars['ID']>;
  /** Type of the resource (e.g. person) */
  type: Scalars['String'];
};

export type PerformAuthChecksResourceResponse = {
  __typename?: 'PerformAuthChecksResourceResponse';
  /** Whether this check passes for this permission */
  passes: Scalars['Boolean'];
  /** Permission for this check */
  permissionSlug: Scalars['String'];
  /** Id of the resource */
  resourceId: Scalars['ID'];
  /** Type of the resource (e.g. person) */
  resourceType: Scalars['String'];
  /** Id of the source user */
  sourceUserId: Scalars['ID'];
};

export type PerformAuthChecksResponse = {
  __typename?: 'PerformAuthChecksResponse';
  /** The list of authorisation results */
  authorisations: Array<Maybe<PerformAuthChecksResourceResponse>>;
};

export type PerformMultipleAuthChecksForCustomFieldModelInput = {
  /** A list of permissions to check */
  customFieldModelType: Scalars['String'];
  /** Source user to test from */
  sourceUserId: Scalars['ID'];
  /** A list of target resources to check against */
  targetResources: Array<PerformAuthChecksResource>;
};

export type PerformMultipleAuthChecksInput = {
  /** A list of permissions to check */
  permissionSlugs: Array<Scalars['String']>;
  /** Source user to test from */
  sourceUserId: Scalars['ID'];
  /** A list of target resources to check against */
  targetResources: Array<PerformAuthChecksResource>;
};

export type PerformSingleAuthCheckForCustomFieldModelInput = {
  /** A list of permissions to check */
  customFieldModelType: Scalars['String'];
  /** Source user to test from */
  sourceUserId: Scalars['ID'];
  /** A target resources to check against */
  targetResource: PerformAuthChecksResource;
};

export type PerformSingleAuthCheckInput = {
  /** A list of permissions to check */
  permissionSlugs: Array<Scalars['String']>;
  /** Source user to test from */
  sourceUserId: Scalars['ID'];
  /** A target resources to check against */
  targetResource: PerformAuthChecksResource;
};

export type PerformanceFramework = {
  __typename?: 'PerformanceFramework';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  /** The number of Jobs that use the Performance Framework */
  jobUsageCount: Scalars['Int'];
  name: Scalars['String'];
  reportPeriodEndDate?: Maybe<Scalars['String']>;
  reportPeriodRangeType: ReportPeriodRangeType;
  reportPeriodStartDate?: Maybe<Scalars['String']>;
  reportSections: Array<PerformanceFrameworkSectionInterface>;
  updatedAt: Scalars['String'];
};

export type PerformanceFrameworkCreateInput = {
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  reportPeriodEndDate?: InputMaybe<Scalars['String']>;
  reportPeriodRangeType: ReportPeriodRangeType;
  reportPeriodStartDate?: InputMaybe<Scalars['String']>;
  reportSections: Array<PerformanceFrameworkReportSectionsCreateInput>;
};

export type PerformanceFrameworkCreateResponse = {
  __typename?: 'PerformanceFrameworkCreateResponse';
  performanceFramework?: Maybe<PerformanceFramework>;
};

export type PerformanceFrameworkDeleteResponse = {
  __typename?: 'PerformanceFrameworkDeleteResponse';
  performanceFrameworkId: Scalars['ID'];
};

export type PerformanceFrameworkDiaryNotesSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkDiaryNotesSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  showRestrictedDiaryNotes: Scalars['Boolean'];
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkDirectReportsSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkDirectReportsSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkFormResponseDefinitionGroup = {
  __typename?: 'PerformanceFrameworkFormResponseDefinitionGroup';
  definitions: Array<PerformanceFrameworkFormResponseDefinitions>;
  name: Scalars['String'];
};

export type PerformanceFrameworkFormResponseDefinitionGroupInput = {
  definitions: Array<PerformanceFrameworkMetricResponseDefinitionsInput>;
  name: Scalars['String'];
};

export type PerformanceFrameworkFormResponseDefinitions = PerformanceFrameworkFormResponseFormDesignDefinition | PerformanceFrameworkFormResponseLibraryDefinition;

export type PerformanceFrameworkFormResponseFormDesignDefinition = {
  __typename?: 'PerformanceFrameworkFormResponseFormDesignDefinition';
  formDesignId: Scalars['ID'];
  formFieldId: Scalars['ID'];
  type: Scalars['ID'];
};

export type PerformanceFrameworkFormResponseLibraryDefinition = {
  __typename?: 'PerformanceFrameworkFormResponseLibraryDefinition';
  formItemId: Scalars['ID'];
  type: Scalars['ID'];
};

export type PerformanceFrameworkFormResponsesSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkFormResponsesSection';
  anonymiseResponses: Scalars['Boolean'];
  definitionGroups: Array<PerformanceFrameworkFormResponseDefinitionGroup>;
  definitions: Array<PerformanceFrameworkFormResponseDefinitions>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkGoalsSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkGoalsSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  showArchivedGoals: Scalars['Boolean'];
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkHappinessSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkHappinessSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkMetricRadarSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkMetricRadarSection';
  id: Scalars['ID'];
  metricGroups: Array<Scalars['ID']>;
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  showPeerResponsesToEmployee: Scalars['Boolean'];
  showSupervisorResponsesToEmployee: Scalars['Boolean'];
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkMetricResponseDefinitionsInput = {
  /** required input if type is 'form_design' */
  formDesignId?: InputMaybe<Scalars['ID']>;
  /** required input if type is 'form_design' */
  formFieldId?: InputMaybe<Scalars['ID']>;
  /** required input if type is 'library' */
  formItemId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type PerformanceFrameworkMetricsOverTimeSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkMetricsOverTimeSection';
  id: Scalars['ID'];
  metricGroups: Array<Scalars['ID']>;
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  showAverageChart: Scalars['Boolean'];
  showPeerResponsesToEmployee: Scalars['Boolean'];
  showSupervisorResponsesToEmployee: Scalars['Boolean'];
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkPerformanceImprovementSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkPerformanceImprovementSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkReportSectionsCreateInput = {
  anonymiseResponses?: InputMaybe<Scalars['Boolean']>;
  definitionGroups?: InputMaybe<Array<PerformanceFrameworkFormResponseDefinitionGroupInput>>;
  /** cannot pass empty array for now */
  definitions?: InputMaybe<Array<PerformanceFrameworkMetricResponseDefinitionsInput>>;
  /** cannot pass empty array for now */
  metricGroups?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  showArchivedGoals?: InputMaybe<Scalars['Boolean']>;
  showAverageChart?: InputMaybe<Scalars['Boolean']>;
  showPeerResponsesToEmployee?: InputMaybe<Scalars['Boolean']>;
  showRestrictedDiaryNotes?: InputMaybe<Scalars['Boolean']>;
  showSupervisorResponsesToEmployee?: InputMaybe<Scalars['Boolean']>;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkReportSectionsUpdateInput = {
  anonymiseResponses?: InputMaybe<Scalars['Boolean']>;
  definitionGroups?: InputMaybe<Array<PerformanceFrameworkFormResponseDefinitionGroupInput>>;
  /** cannot pass an empty array for now */
  definitions?: InputMaybe<Array<PerformanceFrameworkMetricResponseDefinitionsInput>>;
  id?: InputMaybe<Scalars['ID']>;
  /** cannot pass an empty array for now */
  metricGroups?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  showArchivedGoals?: InputMaybe<Scalars['Boolean']>;
  showAverageChart?: InputMaybe<Scalars['Boolean']>;
  showPeerResponsesToEmployee?: InputMaybe<Scalars['Boolean']>;
  showRestrictedDiaryNotes?: InputMaybe<Scalars['Boolean']>;
  showSupervisorResponsesToEmployee?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<PerformanceFrameworkSectionType>;
  visibility?: InputMaybe<PerformanceFrameworkSectionVisibility>;
};

export type PerformanceFrameworkSectionInterface = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export enum PerformanceFrameworkSectionType {
  DIARY_NOTES = 'DIARY_NOTES',
  DIRECT_REPORTS = 'DIRECT_REPORTS',
  FORM_RESPONSES = 'FORM_RESPONSES',
  GOALS = 'GOALS',
  HAPPINESS = 'HAPPINESS',
  METRICS_OVER_TIME = 'METRICS_OVER_TIME',
  METRIC_RADAR = 'METRIC_RADAR',
  PERFORMANCE_IMPROVEMENT = 'PERFORMANCE_IMPROVEMENT',
  TRAINING = 'TRAINING',
  WELLNESS = 'WELLNESS'
}

export enum PerformanceFrameworkSectionVisibility {
  ALL = 'ALL',
  HIDDEN = 'HIDDEN',
  SUPERVISOR_ONLY = 'SUPERVISOR_ONLY'
}

export type PerformanceFrameworkTrainingSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkTrainingSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceFrameworkUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  reportPeriodEndDate?: InputMaybe<Scalars['String']>;
  reportPeriodRangeType?: InputMaybe<ReportPeriodRangeType>;
  reportPeriodStartDate?: InputMaybe<Scalars['String']>;
  reportSections?: InputMaybe<Array<PerformanceFrameworkReportSectionsUpdateInput>>;
};

export type PerformanceFrameworkUpdateResponse = {
  __typename?: 'PerformanceFrameworkUpdateResponse';
  performanceFramework?: Maybe<PerformanceFramework>;
};

export type PerformanceFrameworkWellnessSection = PerformanceFrameworkSectionInterface & {
  __typename?: 'PerformanceFrameworkWellnessSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use 'type' instead since typing for 'type' now has been fixed */
  sectionType: PerformanceFrameworkSectionType;
  /** @deprecated use 'visibility' instead since typing for 'visibility' now has been fixed */
  sectionVisibility: PerformanceFrameworkSectionVisibility;
  type: PerformanceFrameworkSectionType;
  visibility: PerformanceFrameworkSectionVisibility;
};

export type PerformanceImprovementPlan = {
  __typename?: 'PerformanceImprovementPlan';
  aggregateCostInTenantCurrency: Scalars['Float'];
  aggregateCreatorHours: Scalars['Float'];
  aggregateSubjectHours: Scalars['Float'];
  aggregateTotalCostInTenantCurrency: Scalars['Float'];
  aggregateTotalCostInTenantCurrencyV2?: Maybe<Scalars['Float']>;
  /** The diary notes of the performance improvement plan */
  diaryNotes: Array<DiaryNote>;
  /** The end date of the performance improvement plan */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The first diary note of the performance improvement plan */
  initiatingDiaryNote?: Maybe<DiaryNote>;
  /** The latest diary note of the performance improvement plan */
  latestDiaryNote?: Maybe<DiaryNote>;
  /** The human-readable name of the performance improvement plan */
  name: Scalars['String'];
  /** The start date of the performance improvement plan */
  startDate: Scalars['DateTime'];
  /** The status of the performance improvement plan */
  status: PerformanceImprovementPlanStatus;
  /** The timeline for the performance improvement plan */
  timeline: Array<PerformanceImprovementPlanTimelineEvent>;
};

export type PerformanceImprovementPlanCreateEscalatedInput = {
  actionByBusiness: Scalars['String'];
  actionByEmployee: Scalars['String'];
  actionPlanDueDate?: InputMaybe<Scalars['String']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  escalatedPerformanceImprovementPlanId: Scalars['ID'];
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  reportedByJobId: Scalars['ID'];
  requiresFollowUp: Scalars['Boolean'];
  statusId: Scalars['ID'];
  subjectHours?: InputMaybe<Scalars['Float']>;
};

export type PerformanceImprovementPlanCreateEscalatedResponse = {
  __typename?: 'PerformanceImprovementPlanCreateEscalatedResponse';
  diaryNote: DiaryNote;
  workflowEventId?: Maybe<Scalars['ID']>;
};

export type PerformanceImprovementPlanCreateInitiatedInput = {
  actionByBusiness: Scalars['String'];
  actionByEmployee: Scalars['String'];
  actionPlanDueDate?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  description: Scalars['String'];
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  reportedByJobId: Scalars['ID'];
  requiresFollowUp: Scalars['Boolean'];
  statusId: Scalars['ID'];
  subjectHours?: InputMaybe<Scalars['Float']>;
  subjectJobId: Scalars['ID'];
  summary: Scalars['String'];
};

export type PerformanceImprovementPlanCreateInitiatedResponse = {
  __typename?: 'PerformanceImprovementPlanCreateInitiatedResponse';
  diaryNote: DiaryNote;
  workflowEventId?: Maybe<Scalars['ID']>;
};

export type PerformanceImprovementPlanCreateRelatedInput = {
  categoryId: Scalars['ID'];
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  description: Scalars['String'];
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  /** Performance Improvement Plan id needed when this diary note is attached to a Performance Improvement Plan */
  relatedPerformanceImprovementPlanId: Scalars['ID'];
  reportedByJobId: Scalars['ID'];
  requiresFollowUp: Scalars['Boolean'];
  subjectHours?: InputMaybe<Scalars['Float']>;
  subjectJobId: Scalars['ID'];
};

export type PerformanceImprovementPlanCreateRelatedResponse = {
  __typename?: 'PerformanceImprovementPlanCreateRelatedResponse';
  diaryNote: DiaryNote;
};

export type PerformanceImprovementPlanCreateResolvedInput = {
  actionByBusiness?: InputMaybe<Scalars['String']>;
  actionByEmployee?: InputMaybe<Scalars['String']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  reportedByJobId: Scalars['ID'];
  requiresFollowUp: Scalars['Boolean'];
  resolvedPerformanceImprovementPlanId: Scalars['ID'];
  subjectHours?: InputMaybe<Scalars['Float']>;
};

export type PerformanceImprovementPlanCreateResolvedResponse = {
  __typename?: 'PerformanceImprovementPlanCreateResolvedResponse';
  diaryNote: DiaryNote;
};

export enum PerformanceImprovementPlanEventTypeSlug {
  ESCALATED = 'ESCALATED',
  FOLLOW_UP = 'FOLLOW_UP',
  INITIATED = 'INITIATED',
  RELATED = 'RELATED'
}

export type PerformanceImprovementPlanStatus = {
  __typename?: 'PerformanceImprovementPlanStatus';
  /** The required number of follow up days for the performance improvement plan status */
  followUpDays: Scalars['Int'];
  id: Scalars['ID'];
  /** If the performance improvement plan status is of type resolved */
  isResolved: Scalars['Boolean'];
  /** If the performance improvement plan status is of type terminated */
  isTerminated: Scalars['Boolean'];
  /** The ID of the letter template associated with this status */
  letterTemplateId: Scalars['ID'];
  /** The human-readable name of the performance improvement plan status */
  name: Scalars['String'];
  /** If the performance improvement plan status requires follow up */
  requiresFollowUp: Scalars['Boolean'];
  /** The sort order of the performance improvement plan status */
  sortOrder: Scalars['Int'];
};

export type PerformanceImprovementPlanTimelineEvent = {
  __typename?: 'PerformanceImprovementPlanTimelineEvent';
  diaryNote: DiaryNote;
  eventTime: Scalars['DateTime'];
  eventType: PerformanceImprovementPlanEventTypeSlug;
};

export type PerformanceImprovementPlanUpdateEscalatedInput = {
  actionByBusiness?: InputMaybe<Scalars['String']>;
  actionByEmployee?: InputMaybe<Scalars['String']>;
  actionPlanDueDate?: InputMaybe<Scalars['String']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reportedByJobId?: InputMaybe<Scalars['ID']>;
  requiresFollowUp?: InputMaybe<Scalars['Boolean']>;
  subjectHours?: InputMaybe<Scalars['Float']>;
};

export type PerformanceImprovementPlanUpdateEscalatedResponse = {
  __typename?: 'PerformanceImprovementPlanUpdateEscalatedResponse';
  diaryNote: DiaryNote;
};

export type PerformanceImprovementPlanUpdateInitiatedInput = {
  actionByBusiness?: InputMaybe<Scalars['String']>;
  actionByEmployee?: InputMaybe<Scalars['String']>;
  actionPlanDueDate?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reportedByJobId?: InputMaybe<Scalars['ID']>;
  requiresFollowUp?: InputMaybe<Scalars['Boolean']>;
  subjectHours?: InputMaybe<Scalars['Float']>;
  summary?: InputMaybe<Scalars['String']>;
};

export type PerformanceImprovementPlanUpdateInitiatedResponse = {
  __typename?: 'PerformanceImprovementPlanUpdateInitiatedResponse';
  diaryNote: DiaryNote;
};

export type PerformanceImprovementPlanUpdateLetterContentInput = {
  htmlLetterContent?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type PerformanceImprovementPlanUpdateLetterContentResponse = {
  __typename?: 'PerformanceImprovementPlanUpdateLetterContentResponse';
  diaryNote: DiaryNote;
};

export type PerformanceImprovementPlanUpdateRelatedInput = {
  /** still supports actionByEmployee for legacy data */
  actionByEmployee?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reportedByJobId?: InputMaybe<Scalars['ID']>;
  requiresFollowUp?: InputMaybe<Scalars['Boolean']>;
  subjectHours?: InputMaybe<Scalars['Float']>;
  summary?: InputMaybe<Scalars['String']>;
};

export type PerformanceImprovementPlanUpdateRelatedResponse = {
  __typename?: 'PerformanceImprovementPlanUpdateRelatedResponse';
  diaryNote: DiaryNote;
};

export type PerformanceImprovementPlanUpdateResolvedInput = {
  actionByBusiness?: InputMaybe<Scalars['String']>;
  actionByEmployee?: InputMaybe<Scalars['String']>;
  creatorHours?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  directCost?: InputMaybe<Scalars['Float']>;
  followUpDate?: InputMaybe<Scalars['String']>;
  followUpPersonId?: InputMaybe<Scalars['ID']>;
  followUpRequiredByBusiness?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reportedByJobId?: InputMaybe<Scalars['ID']>;
  requiresFollowUp?: InputMaybe<Scalars['Boolean']>;
  subjectHours?: InputMaybe<Scalars['Float']>;
};

export type PerformanceImprovementPlanUpdateResolvedResponse = {
  __typename?: 'PerformanceImprovementPlanUpdateResolvedResponse';
  diaryNote: DiaryNote;
};

export type PerformanceManagement = {
  __typename?: 'PerformanceManagement';
  /** The end date of the performance management issue */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  initiatingDiaryNote?: Maybe<DiaryNote>;
  /** The diary note of the performance management issue */
  latestDiaryNote?: Maybe<DiaryNote>;
  /** The human-readable name of the performance management */
  name: Scalars['String'];
  /** The start date of the performance management issue */
  startDate: Scalars['DateTime'];
  /** The status of the performance management issue */
  status: PerformanceManagementStatus;
};

export type PerformanceManagementStatus = {
  __typename?: 'PerformanceManagementStatus';
  /** The required number of follow up days for the performance management status */
  followUpDays: Scalars['Int'];
  id: Scalars['ID'];
  /** If the performance management status is of type resolved */
  isResolved: Scalars['Boolean'];
  /** If the performance management status is of type terminated */
  isTerminated: Scalars['Boolean'];
  /** The human-readable name of the performance management status */
  name: Scalars['String'];
  /** If the performance management status requires follow up */
  requiresFollowUp: Scalars['Boolean'];
  /** The sort order of the performance management status */
  sortOrder: Scalars['Int'];
};

export type PerformanceMetric = {
  __typename?: 'PerformanceMetric';
  id: Scalars['ID'];
  name: Scalars['String'];
  peerDefinitions: Array<MetricResponseDefinitionsUnion>;
  performanceMetricGroups?: Maybe<Array<PerformanceMetricGroup>>;
  subjectDefinitions: Array<MetricResponseDefinitionsUnion>;
  supervisorDefinitions: Array<MetricResponseDefinitionsUnion>;
};

export type PerformanceMetricGroup = {
  __typename?: 'PerformanceMetricGroup';
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  performanceMetrics: Array<PerformanceMetric>;
};

export type PerformanceMetricGroupCreateInput = {
  /** If this performance metric group is the default used for analytics */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** The name of the performance metric group */
  name: Scalars['String'];
  /** The performance metrics associated with this group */
  performanceMetrics: Array<PerformanceMetricInput>;
};

export type PerformanceMetricGroupCreateResponse = {
  __typename?: 'PerformanceMetricGroupCreateResponse';
  performanceMetricGroup?: Maybe<PerformanceMetricGroup>;
};

export type PerformanceMetricGroupDeleteResponse = {
  __typename?: 'PerformanceMetricGroupDeleteResponse';
  performanceMetricGroupId: Scalars['ID'];
};

export type PerformanceMetricGroupUpdateInput = {
  /** The ID of the performance metric group */
  id: Scalars['String'];
  /** If this performance metric group is the default used for analytics */
  isDefault?: InputMaybe<Scalars['Boolean']>;
  /** The name of the performance metric group */
  name?: InputMaybe<Scalars['String']>;
  /** The performance metrics associated with this group */
  performanceMetrics?: InputMaybe<Array<PerformanceMetricInput>>;
};

export type PerformanceMetricGroupUpdateResponse = {
  __typename?: 'PerformanceMetricGroupUpdateResponse';
  performanceMetricGroup?: Maybe<PerformanceMetricGroup>;
};

export type PerformanceMetricInput = {
  /** The ID of the performance metric */
  id?: InputMaybe<Scalars['String']>;
  /** The name of the performance metric */
  name: Scalars['String'];
  /** The form design and form library fields that capture the peer responses for this metric */
  peerDefinitions: Array<MetricResponseDefinitionInput>;
  /** The form design and form library fields that capture the subject responses for this metric */
  subjectDefinitions: Array<MetricResponseDefinitionInput>;
  /** The form design and form library fields that capture the supervisor responses for this metric */
  supervisorDefinitions: Array<MetricResponseDefinitionInput>;
};

export type PerformanceReportConfiguration = {
  __typename?: 'PerformanceReportConfiguration';
  performanceFramework: PerformanceFramework;
  subjectJob: Job;
};

export enum PerformanceReportConfigurationViewType {
  EMPLOYEE = 'EMPLOYEE',
  SUPERVISOR = 'SUPERVISOR'
}

export type PerformanceReportDiaryNoteSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportDiaryNoteSection';
  diaryNotes: Array<DiaryNote>;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  statistics: PerformanceReportDiaryNoteSectionStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportDiaryNoteSectionStatistics = {
  __typename?: 'PerformanceReportDiaryNoteSectionStatistics';
  cost: Scalars['Float'];
  creatorHours: Scalars['Float'];
  subjectHours: Scalars['Float'];
  totalCost?: Maybe<Scalars['Float']>;
  totalDiaryNotes: Scalars['Int'];
};

export type PerformanceReportDirectReportsSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportDirectReportsSection';
  chartStatistics: PerformanceReportDirectReportsSectionChartStatistics;
  directReports: Array<PerformanceReportDirectReportsSectionDirectReport>;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  statistics: PerformanceReportDirectReportsSectionStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportDirectReportsSectionChartStatistics = {
  __typename?: 'PerformanceReportDirectReportsSectionChartStatistics';
  data: Array<PerformanceReportDirectReportsSectionChartStatisticsResponse>;
  labels: Array<Scalars['String']>;
  maxOfLatestScale?: Maybe<Scalars['Float']>;
  minOfLatestScale?: Maybe<Scalars['Float']>;
  stepOfLatestScale?: Maybe<Scalars['Float']>;
};

export type PerformanceReportDirectReportsSectionChartStatisticsResponse = {
  __typename?: 'PerformanceReportDirectReportsSectionChartStatisticsResponse';
  average?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type PerformanceReportDirectReportsSectionDirectReport = {
  __typename?: 'PerformanceReportDirectReportsSectionDirectReport';
  averageHappiness?: Maybe<Scalars['Float']>;
  goalCompletion?: Maybe<Scalars['Float']>;
  job: Job;
  supervisionPeriods: Array<DirectReportsSupervisionPeriod>;
  trainingCostInTenantCurrency?: Maybe<Scalars['Float']>;
  trainingHours?: Maybe<Scalars['Float']>;
};

export type PerformanceReportDirectReportsSectionStatistics = {
  __typename?: 'PerformanceReportDirectReportsSectionStatistics';
  averageGoalCompletion?: Maybe<Scalars['Float']>;
  averageHappiness?: Maybe<Scalars['Float']>;
  totalResponses: Scalars['Int'];
  trainingCostInTenantCurrency?: Maybe<Scalars['Float']>;
  trainingHours?: Maybe<Scalars['Float']>;
};

export type PerformanceReportFormResponse = {
  __typename?: 'PerformanceReportFormResponse';
  answer: Scalars['String'];
  canViewCompletedForm: Scalars['Boolean'];
  formDesignName: Scalars['String'];
  formField: FormFieldValueInterface;
  formFieldLabel: Scalars['String'];
  formInstanceId: Scalars['ID'];
  issueDate: Scalars['DateTime'];
  respondentPerson?: Maybe<Person>;
};

export type PerformanceReportFormResponseGroup = {
  __typename?: 'PerformanceReportFormResponseGroup';
  name: Scalars['String'];
  responses: Array<PerformanceReportFormResponse>;
};

export type PerformanceReportFormResponsesSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportFormResponsesSection';
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  responseGroups?: Maybe<Array<PerformanceReportFormResponseGroup>>;
  responses?: Maybe<Array<PerformanceReportFormResponse>>;
  totalResponses: Scalars['Int'];
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportGoalStatistics = {
  __typename?: 'PerformanceReportGoalStatistics';
  completedAfterDue: Scalars['Float'];
  completedOnTime: Scalars['Float'];
  completionRate?: Maybe<Scalars['Float']>;
  overdue: Scalars['Float'];
  totalGoals: Scalars['Int'];
};

export type PerformanceReportGoalsSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportGoalsSection';
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  goals: Array<Goal>;
  id: Scalars['ID'];
  statistics: PerformanceReportGoalStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportHappinessSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportHappinessSection';
  chartStatistics: PerformanceReportHappinessSectionChartStatistics;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  responses: Array<PerformanceReportFormResponse>;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportHappinessSectionChartStatistics = {
  __typename?: 'PerformanceReportHappinessSectionChartStatistics';
  labels: Array<Scalars['String']>;
  maxOfLatestScale?: Maybe<Scalars['Float']>;
  minOfLatestScale?: Maybe<Scalars['Float']>;
  responses: Array<PerformanceReportHappinessSectionChartStatisticsResponse>;
  stepOfLatestScale?: Maybe<Scalars['Float']>;
};

export type PerformanceReportHappinessSectionChartStatisticsResponse = {
  __typename?: 'PerformanceReportHappinessSectionChartStatisticsResponse';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export enum PerformanceReportLinkViewEnum {
  EMPLOYEE = 'EMPLOYEE',
  SUPERVISOR = 'SUPERVISOR'
}

export type PerformanceReportMetricRadarChartStatistics = {
  __typename?: 'PerformanceReportMetricRadarChartStatistics';
  metricName: Scalars['String'];
  peerRating?: Maybe<Scalars['Float']>;
  subjectRating?: Maybe<Scalars['Float']>;
  supervisorRating?: Maybe<Scalars['Float']>;
};

export type PerformanceReportMetricRadarSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportMetricRadarSection';
  chartStatistics: Array<PerformanceReportMetricRadarChartStatistics>;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  statistics: PerformanceReportMetricRadarStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportMetricRadarStatistics = {
  __typename?: 'PerformanceReportMetricRadarStatistics';
  totalResponses: Scalars['Int'];
};

export type PerformanceReportMetricsOverTimeChartStatisticsAverages = {
  __typename?: 'PerformanceReportMetricsOverTimeChartStatisticsAverages';
  peerResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
  subjectResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
  supervisorResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
};

export type PerformanceReportMetricsOverTimeChartStatisticsMetric = {
  __typename?: 'PerformanceReportMetricsOverTimeChartStatisticsMetric';
  metricName: Scalars['String'];
  peerResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
  subjectResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
  supervisorResponses: Array<PerformanceReportMetricsOverTimeChartStatisticsResponse>;
};

export type PerformanceReportMetricsOverTimeChartStatisticsResponse = {
  __typename?: 'PerformanceReportMetricsOverTimeChartStatisticsResponse';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export type PerformanceReportMetricsOverTimeSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportMetricsOverTimeSection';
  averagesChartStatistics: PerformanceReportMetricsOverTimeChartStatisticsAverages;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  metricsChartStatistics: Array<PerformanceReportMetricsOverTimeChartStatisticsMetric>;
  statistics: PerformanceReportMetricsOverTimeStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportMetricsOverTimeStatistics = {
  __typename?: 'PerformanceReportMetricsOverTimeStatistics';
  totalResponses: Scalars['Int'];
};

export type PerformanceReportPerformanceImprovementSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportPerformanceImprovementSection';
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  performanceImprovementPlans: Array<PerformanceImprovementPlan>;
  statistics: PerformanceReportPerformanceImprovementSectionStatistics;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportPerformanceImprovementSectionStatistics = {
  __typename?: 'PerformanceReportPerformanceImprovementSectionStatistics';
  cost: Scalars['Float'];
  creatorHours: Scalars['Float'];
  subjectHours: Scalars['Float'];
  totalCost?: Maybe<Scalars['Float']>;
  totalPerformanceImprovementPlans: Scalars['Int'];
};

export type PerformanceReportSectionInterface = {
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  /** equal to ID used in corresponding PerformanceFrameworkSectionInterface in Performance Framework */
  id: Scalars['ID'];
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportTrainingSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportTrainingSection';
  chartStatistics: Array<PerformanceReportTrainingSectionChartStatistics>;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  statistics: PerformanceReportTrainingSectionStatistics;
  trainings: Array<Training>;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportTrainingSectionChartStatistics = {
  __typename?: 'PerformanceReportTrainingSectionChartStatistics';
  date: Scalars['String'];
  monthlyAggregateTotalCost?: Maybe<Scalars['Float']>;
  monthlyHours: Scalars['Float'];
};

export type PerformanceReportTrainingSectionStatistics = {
  __typename?: 'PerformanceReportTrainingSectionStatistics';
  customFieldStatistics: Array<TrainingCustomFieldNumberStatistic>;
  hours: Scalars['Float'];
  totalCost?: Maybe<Scalars['Float']>;
  totalTrainings: Scalars['Int'];
  trainingCost: Scalars['Float'];
};

export type PerformanceReportWellnessSection = PerformanceReportSectionInterface & {
  __typename?: 'PerformanceReportWellnessSection';
  chartStatistics: PerformanceReportWellnessSectionChartStatistics;
  frameworkConfiguration: PerformanceFrameworkSectionInterface;
  id: Scalars['ID'];
  responses: Array<PerformanceReportFormResponse>;
  type: PerformanceFrameworkSectionType;
};

export type PerformanceReportWellnessSectionChartStatistics = {
  __typename?: 'PerformanceReportWellnessSectionChartStatistics';
  labels: Array<Scalars['String']>;
  maxOfLatestScale?: Maybe<Scalars['Float']>;
  minOfLatestScale?: Maybe<Scalars['Float']>;
  responses: Array<PerformanceReportWellnessSectionChartStatisticsResponse>;
  stepOfLatestScale?: Maybe<Scalars['Float']>;
};

export type PerformanceReportWellnessSectionChartStatisticsResponse = {
  __typename?: 'PerformanceReportWellnessSectionChartStatisticsResponse';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export type Permission = {
  __typename?: 'Permission';
  /** Wheather the permission is a system or custom field permission */
  dataType: Scalars['String'];
  /** Number of groups using this permission */
  groupTotal: Scalars['Int'];
  id: Scalars['ID'];
  /** Whether the permission is enabled */
  isEnabled: Scalars['Boolean'];
  /** The name/description of the permission */
  name: Scalars['String'];
  /** Wheather the permission is a functional or hierachical permission */
  permissionType: Scalars['String'];
  /** The slug of the permission */
  slug: Scalars['String'];
  /** Number of users using this permission */
  userTotal: Scalars['Int'];
};

export type PermissionEntry = {
  __typename?: 'PermissionEntry';
  createdAt: Scalars['DateTime'];
  dataType: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permissionType: Scalars['String'];
  slug: Scalars['String'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  analyticsPermission?: Maybe<AnalyticsPermissionPermissionGroupType>;
  id: Scalars['ID'];
  /** Whether the permission group is enabled */
  isEnabled: Scalars['Boolean'];
  /** Whether the permission group is a system permission roup */
  isSystem: Scalars['Boolean'];
  /** The name of the permission group */
  name: Scalars['String'];
  /** The junction entity between the permission group and the associated users */
  userPermissionGroups: Array<Maybe<UserPermissionGroup>>;
};


export type PermissionGroupuserPermissionGroupsArgs = {
  filters?: InputMaybe<UserPermissionGroupFilters>;
};

export type PermissionGroupDeleteResponse = {
  __typename?: 'PermissionGroupDeleteResponse';
  id: Scalars['ID'];
};

export type PermissionGroupFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
  isSystem?: InputMaybe<BooleanFilter>;
};

export type Person = {
  __typename?: 'Person';
  /** The addresses that belong to this person */
  addresses: Array<Address>;
  /** The skills assigned to this person */
  assignedSkills: Array<AssignedSkill>;
  /** The auto increment intellihr id for the person */
  autoIncrementIntellihrId?: Maybe<Scalars['String']>;
  /** Composite Qualifications this person currently has (job requirements and non requirement qualifications) */
  compositeQualifications: Array<CompositeQualificationV2>;
  /** When the person was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Custom fields for this person */
  customFieldValues: Array<CustomFieldValue>;
  /** The date the person was born */
  dateOfBirth?: Maybe<Scalars['String']>;
  /** When the person was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The diary notes assigned to this person. */
  diaryNotes: Array<DiaryNote>;
  /** The name formatted to be displayed for the person */
  displayName?: Maybe<Scalars['String']>;
  /** Lists the documents uploaded to this person */
  documents: Array<PersonDocument>;
  /** Lists the documents uploaded to this person */
  documentsV2: Array<PersonDocument>;
  /** The email addresses that belong to this person */
  emailAddresses: Array<EmailAddress>;
  /** The emergency contact email address for the person */
  emergencyContactEmailAddress?: Maybe<Scalars['String']>;
  /** The emergency contact name for the person */
  emergencyContactName?: Maybe<Scalars['String']>;
  /** The emergency contact phone number for the person */
  emergencyContactPhoneNumber?: Maybe<Scalars['String']>;
  /** The emergency contact phone number country for the person */
  emergencyContactPhoneNumberCountry?: Maybe<Country>;
  /** The emergency contact relationship for the person */
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** The employee number for the person */
  employeeNumber?: Maybe<Scalars['String']>;
  /** Current employment status id for the person */
  employmentStatusId?: Maybe<PersonEmploymentStatusId>;
  /** The list of feedback completed with this person as a subject by other people */
  feedbackAboutPersonByOthers?: Maybe<Array<PersonFeedback>>;
  /** The list of feedback completed with this person as a subject by themselves */
  feedbackAboutPersonByPerson?: Maybe<Array<PersonFeedback>>;
  /** The list of feedback completed with this person as a subject by other people which are shared with the subject */
  feedbackSharedToPersonByOthers?: Maybe<Array<PersonFeedback>>;
  /** The first name for the person */
  firstName?: Maybe<Scalars['String']>;
  /** The persons gender */
  gender?: Maybe<Scalars['String']>;
  /** The gender for the person */
  genderDetails?: Maybe<Gender>;
  /** the goals that belong to this person */
  goals: Array<Goal>;
  id: Scalars['ID'];
  /** Calculated initials for this person */
  initials: Scalars['String'];
  /** If this person is a supervisor or not */
  isSupervisor: Scalars['Boolean'];
  /**
   * The jobs that the person will have, have had or currently has.
   * Jobs are returned in order of isPrimaryJob true first, and then ordering by their
   * start dates in descending order (so past jobs will be last, unless they are the primary
   * job on this person).
   */
  jobs: Array<Job>;
  /** The last name for the person */
  lastName?: Maybe<Scalars['String']>;
  /** The legal name for the person */
  legalName?: Maybe<Scalars['String']>;
  /** query to get a paginated list of documents on a person */
  listPaginatedDocuments: PaginatedDocuments;
  /** The medical conditions that this person has */
  medicalConditions: Array<MedicalCondition>;
  /** The middle name for the person */
  middleName?: Maybe<Scalars['String']>;
  /** The onboarding form assigned to this person */
  onboardingFormInstance?: Maybe<OnboardingFormInstance>;
  /** All onboarding forms assigned to this person */
  onboardingFormInstances?: Maybe<Array<OnboardingFormInstance>>;
  /** The onboarding steps assigned to this person */
  onboardingSteps: Array<OnboardingStep>;
  /** The count of issued Forms which this Person has yet to complete */
  outstandingFormCount: Scalars['Int'];
  /**
   * The count of issued form instance tasks which this person has yet to complete
   * @deprecated Use outstandingFormCount instead
   */
  outstandingTaskCount: Scalars['Int'];
  /** The performance managements assigned to this person. */
  performanceManagements: Array<PerformanceManagement>;
  /** The email addresses that belong to this person */
  phoneNumbers: Array<PhoneNumber>;
  /** The persons preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The preferred name if it exists otherwise the first name */
  preferredOrFirstName?: Maybe<Scalars['String']>;
  /** The primary email address that belongs to this person */
  primaryEmailAddress?: Maybe<EmailAddress>;
  /**
   * The job which is closest to being considered the 'main' job this person has.
   * Will prefer current jobs > future jobs > past jobs. Null for no job.
   */
  primaryJob?: Maybe<Job>;
  /** The primary email address that belongs to this person */
  primaryPhoneNumber?: Maybe<PhoneNumber>;
  /** The id that references a person's profile cover picture */
  profileCoverPictureId?: Maybe<Scalars['ID']>;
  /** The url used to access a person's profile cover picture */
  profileCoverPictureUrl?: Maybe<Scalars['String']>;
  /** The id that references a person's profile picture */
  profilePictureId?: Maybe<Scalars['ID']>;
  /** The url used to access a person's profile picture */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** The title for the person */
  title?: Maybe<Scalars['String']>;
  /** The title for the person */
  titleDetails?: Maybe<Title>;
  /** the training that this person has completed */
  training: Array<Training>;
  /**
   * When the person was last updated. This will not change when changing custom
   * fields, phone number, addresses, email, and medical conditions
   */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user that is associated with the person */
  user?: Maybe<User>;
  /** If person has a user account then returns the userId, if not it returns null */
  userId?: Maybe<Scalars['String']>;
  /** The work right of the person */
  workRight?: Maybe<WorkRight>;
  /** The date the person work right expire */
  workRightExpiryDate?: Maybe<Scalars['DateTime']>;
};


export type PersonassignedSkillsArgs = {
  filters?: InputMaybe<AssignedSkillFilter>;
};


export type PersoncustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type PersondiaryNotesArgs = {
  typeIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type PersonisSupervisorArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type PersonjobsArgs = {
  whereJobIsPrimaryOrActive?: InputMaybe<Scalars['Boolean']>;
};


export type PersonlistPaginatedDocumentsArgs = {
  paginationOptions: PaginationOptions;
};


export type PersonprofileCoverPictureUrlArgs = {
  size: PersonCoverPictureSize;
};


export type PersonprofilePictureUrlArgs = {
  size: PersonProfilePictureSize;
};

export type PersonAndJobSearchObjectInput = {
  jobSearchObject?: InputMaybe<JobSearchObjectInput>;
  personSearchObject: PersonSearchObjectInput;
};

export enum PersonCoverPictureSize {
  PROFILE_COVER_HOVER_CARD = 'PROFILE_COVER_HOVER_CARD',
  PROFILE_COVER_MAIN = 'PROFILE_COVER_MAIN'
}

export type PersonCreateAddressInput = {
  /** The ID of the address type */
  addressTypeId: Scalars['ID'];
  /** The country the address is in */
  country: Scalars['String'];
  /** Custom field values for the address */
  customFieldValues?: InputMaybe<Array<AddressCustomFieldValueInput>>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: InputMaybe<Scalars['String']>;
  /** The full address excluding postcode and country */
  fullAddress?: InputMaybe<Scalars['String']>;
  /** Determines if address is primary */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The latitude of the address */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the address */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The postcode of the address */
  postcode?: InputMaybe<Scalars['String']>;
  /** The state the address is in */
  state?: InputMaybe<Scalars['String']>;
  /** The street the address is on */
  street?: InputMaybe<Scalars['String']>;
  /** The suburb the address is in */
  suburb?: InputMaybe<Scalars['String']>;
};

export type PersonCreateCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PersonCreateEmailInput = {
  /** Custom values associated with this email address */
  customFieldValues?: InputMaybe<Array<EmailAddressCustomFieldValueInput>>;
  /** An email address associated with this person */
  email: Scalars['String'];
  /** Wether it is a personal email address */
  isPersonal: Scalars['Boolean'];
  /** Wether is it the person's primary email address */
  isPrimary: Scalars['Boolean'];
};

export type PersonCreateInput = {
  /** The physical addresses associated with this person */
  addresses?: InputMaybe<Array<InputMaybe<PersonCreateAddressInput>>>;
  /** Custom values associated with this person */
  customFieldValues?: InputMaybe<Array<InputMaybe<PersonCreateCustomFieldValueInput>>>;
  /** The person's date of birth */
  dateOfBirth?: InputMaybe<Scalars['String']>;
  /** The person's email address (DEPRECATED use emailAddresses) */
  email?: InputMaybe<Scalars['String']>;
  /** Wether the email for this person is personal (DEPRECATED use emailAddresses) */
  emailAddressIsPersonal?: InputMaybe<Scalars['Boolean']>;
  /** The email addresses associated with this person */
  emailAddresses?: InputMaybe<Array<InputMaybe<PersonCreateEmailInput>>>;
  /** The email of the person's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']>;
  /** The name of the person's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']>;
  /** The phone number of the person's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  /** The country ID of the phone number of the person's emergency contact */
  emergencyContactPhoneCountryId?: InputMaybe<Scalars['ID']>;
  /** The relationship between the person and emergency contact */
  emergencyContactRelationship?: InputMaybe<Scalars['String']>;
  /** The person's employee number */
  employeeNumber?: InputMaybe<Scalars['String']>;
  /** The person's first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the gender of the person */
  genderId?: InputMaybe<Scalars['ID']>;
  /** The person's last name */
  lastName: Scalars['String'];
  /** The medical conditions that this person has */
  medicalConditions?: InputMaybe<Array<PersonCreateMedicalConditionInput>>;
  /** The person's middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** When provided, also create a user account with some configuration, supporting password setup */
  newUser?: InputMaybe<PersonCreateUserInputV2>;
  /** The person's phone number (DEPRECATED use phoneNumbers) */
  phone?: InputMaybe<Scalars['String']>;
  /** Wether the phone number is personal (DEPRECATED use phoneNumbers) */
  phoneNumberIsPersonal?: InputMaybe<Scalars['Boolean']>;
  /** The phone numbers associated with this person */
  phoneNumbers?: InputMaybe<Array<InputMaybe<PersonCreatePhoneNumberInput>>>;
  /** The person's preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** The title ID for this person */
  titleId?: InputMaybe<Scalars['ID']>;
  /** When provided, also create a user account with some configuration */
  user?: InputMaybe<PersonCreateUserInput>;
  /** Deprecated. This field is unused. */
  workRightCountryId?: InputMaybe<Scalars['ID']>;
  /** The expiry date of the person's work right */
  workRightExpiryDate?: InputMaybe<Scalars['String']>;
  /** The workright ID */
  workRightId?: InputMaybe<Scalars['ID']>;
};

export type PersonCreateMedicalConditionInput = {
  /** The name and description of the medical condition */
  body: Scalars['String'];
  /**
   * If the medical condition is visible to anyone with permission to view their profile.
   * Defaults to false.
   */
  isPublic?: InputMaybe<Scalars['Boolean']>;
};

export type PersonCreatePhoneNumberInput = {
  /** The country that belongs to this phone number */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for the phone number */
  customFieldValues?: InputMaybe<Array<PhoneNumberCustomFieldValueInput>>;
  /** If the phone number is a personal phone number */
  isPersonal: Scalars['Boolean'];
  /** If the phone number is a primary phone number */
  isPrimary: Scalars['Boolean'];
  /** The number of the phone number */
  number: Scalars['String'];
};

export type PersonCreateResponse = {
  __typename?: 'PersonCreateResponse';
  person?: Maybe<Person>;
};

export type PersonCreateUserInput = {
  /**
   * When the user's account moves from restricted to full access.
   * Used during onboarding. If null the account gains full access immediately.
   */
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  /** Whether the user is enabled */
  isEnabled: Scalars['Boolean'];
  /**
   * The onboarding form that the user should complete ahead of their active from date
   * Deprecate when removing the ONBOARDING_FORM
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
  /** The permission groups assigned to this user */
  permissionGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether a welcome email should be sent */
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
  /** The username for this user */
  username: Scalars['String'];
};

export type PersonCreateUserInputV2 = {
  /**
   * When the user's account moves from restricted to full access.
   * Used during onboarding. If null the account gains full access immediately.
   */
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  /** Whether the user is enabled */
  isEnabled: Scalars['Boolean'];
  /**
   * The onboarding form that the user should complete ahead of their active from date
   * Deprecate when removing the ONBOARDING_FORM
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
  /** The user's password. Required if passwordMethod set to MANUAL. */
  password?: InputMaybe<Scalars['String']>;
  /** The password method for the created user. */
  passwordMethod: UserCreatePasswordMethod;
  /** The permission groups assigned to this user */
  permissionGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether a welcome email should be sent */
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
  /** The username for this user */
  username: Scalars['String'];
};

export type PersonDeleteInput = {
  /** The ID of the person to delete */
  id: Scalars['ID'];
};

export type PersonDeleteResponse = {
  __typename?: 'PersonDeleteResponse';
  id: Scalars['ID'];
};

export type PersonDocument = {
  __typename?: 'PersonDocument';
  /** The general category the file extension belongs to */
  category: Scalars['String'];
  /**
   * The date at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   * @deprecated Use createdAtDateTime instead
   */
  createdAt: Scalars['ID'];
  /**
   * The date and time (in ISO8601 format) at which the document was created in the system
   * Note: not the actual upload finished date or the original created date of the file
   */
  createdAtDateTime: Scalars['DateTime'];
  /** The readable name of this document */
  displayName: Scalars['String'];
  /**
   * The url the document can be downloaded from
   * @deprecated Use downloadUrlAbsolute instead to avoid needing to reconstruct the URL in the client
   */
  downloadUrl: Scalars['String'];
  /** The absolute url the document can be downloaded from */
  downloadUrlAbsolute: Scalars['String'];
  /** The extension of the document (e.g. .jpg, .pdf) */
  extension: Scalars['String'];
  /** The ID of the folder where the document is stored */
  folderId?: Maybe<Scalars['String']>;
  /** The size of the document as a human readable measurement */
  humanReadableSize: Scalars['String'];
  id: Scalars['ID'];
  /** The ID of the person this document belongs to */
  personId: Scalars['ID'];
  /** The size of the document in bytes */
  size: Scalars['Int'];
  /** The current status of uploading this document */
  uploadStatus: PersonDocumentUploadStatus;
};

export type PersonDocumentCreateInput = {
  /** The extension of the document, not including the dot. */
  extension: Scalars['String'];
  /** The original filename of the document. This will be used for display name. Includes extension */
  filename: Scalars['String'];
  /** The ID of the folder where the document will be stored */
  folderId?: InputMaybe<Scalars['String']>;
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The ID of the person to whom the document belongs to */
  personId: Scalars['ID'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type PersonDocumentCreateResponse = {
  __typename?: 'PersonDocumentCreateResponse';
  document: PersonDocument;
  folderId?: Maybe<Scalars['String']>;
  presignedUploadUrl: Scalars['String'];
};

export type PersonDocumentDeleteResponse = {
  __typename?: 'PersonDocumentDeleteResponse';
  documentId: Scalars['ID'];
};

export type PersonDocumentUpdateInput = {
  /** New display name to give the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document to update */
  documentId: Scalars['ID'];
  /** The ID of the folder where the document will be stored */
  folderId?: InputMaybe<Scalars['String']>;
  /** New upload status to set the document to */
  uploadStatus?: InputMaybe<PersonDocumentUploadStatus>;
};

export type PersonDocumentUpdateResponse = {
  __typename?: 'PersonDocumentUpdateResponse';
  document: PersonDocument;
};

export enum PersonDocumentUploadStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export enum PersonEmploymentStatusId {
  EMPLOYMENT_STATUS_CURRENT_STAFF = 'EMPLOYMENT_STATUS_CURRENT_STAFF',
  EMPLOYMENT_STATUS_EXITING_STAFF = 'EMPLOYMENT_STATUS_EXITING_STAFF',
  EMPLOYMENT_STATUS_NO_JOB_RECORDS = 'EMPLOYMENT_STATUS_NO_JOB_RECORDS',
  EMPLOYMENT_STATUS_PAST_STAFF = 'EMPLOYMENT_STATUS_PAST_STAFF',
  EMPLOYMENT_STATUS_RETURNING_STAFF = 'EMPLOYMENT_STATUS_RETURNING_STAFF',
  EMPLOYMENT_STATUS_UPCOMING_STAFF = 'EMPLOYMENT_STATUS_UPCOMING_STAFF'
}

export type PersonFeedback = {
  __typename?: 'PersonFeedback';
  /** The date/time this feedback was completed */
  completedAt: Scalars['DateTime'];
  /** The ID of the form design used in the instance represented by this feedback */
  formDesignId: Scalars['ID'];
  /** The ID of the form instance represented by this feedback */
  formInstanceId: Scalars['ID'];
  id: Scalars['ID'];
  /** The name of the form design used for this feedback */
  name: Scalars['String'];
  /** The person who filled in this feedback */
  respondentPerson: Person;
  /** The person who actually submitted the form for the feedback (generally identical to respondentPerson) */
  submitterPerson: Person;
};

export type PersonImageCropCoordinates = {
  /** The multiple to crop the height of the image to (e.g. 0.5 will half the height) */
  height: Scalars['Float'];
  /** The multiple to crop the width of the image to (e.g. 0.5 will half the width) */
  width: Scalars['Float'];
  /** The multiple to offset the x crop (e.g. 0.5 will crop to show the right side of the image if the width is also 0.5) */
  x: Scalars['Float'];
  /** The multiple to offset the y crop (e.g. 0.5 will crop to show the bottom of the image if the height is also 0.5) */
  y: Scalars['Float'];
};

export type PersonPrimaryJobUpdateInput = {
  /** The ID of the job to update to new Primary Job */
  jobId: Scalars['ID'];
  /** The ID of the person being updated */
  personId: Scalars['ID'];
};

export type PersonPrimaryJobUpdateResponse = {
  __typename?: 'PersonPrimaryJobUpdateResponse';
  /** The new Primary Job */
  job: Job;
  person: Person;
};

export type PersonProfileCreateDocumentInput = {
  /** The extension of the document, not including the dot. */
  extension: Scalars['String'];
  /** The original filename of the document. This will be used for display name. Includes extension */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The id of the person to whom the document belongs to */
  personId: Scalars['ID'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type PersonProfileCreateDocumentResponse = {
  __typename?: 'PersonProfileCreateDocumentResponse';
  document: PersonDocument;
  presignedUploadUrl: Scalars['String'];
};

export type PersonProfileCreateTrainingAdditionalPersonJobsInput = {
  jobId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type PersonProfileCreateTrainingCustomFieldsInput = {
  apiName: Scalars['String'];
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PersonProfileCreateTrainingInput = {
  /** A list of extra people/jobs to create the training on */
  additionalPersonJobIds?: InputMaybe<Array<InputMaybe<PersonProfileCreateTrainingAdditionalPersonJobsInput>>>;
  /** The completion date of the training */
  completionDate: Scalars['DateTime'];
  /** The training coordinator person id that belongs to this training */
  coordinatorPersonId?: InputMaybe<Scalars['ID']>;
  /** The cost of the training */
  cost?: InputMaybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrencyId: Scalars['ID'];
  /** Custom field values to save for this training */
  customFieldValues?: InputMaybe<Array<InputMaybe<PersonProfileCreateTrainingCustomFieldsInput>>>;
  /** The hours the training took */
  hours?: InputMaybe<Scalars['String']>;
  /** The id of the job to which the training belongs to */
  jobId: Scalars['ID'];
  /** The name of the training */
  name: Scalars['String'];
  /** The id of the person to whom the training belongs to */
  personId: Scalars['ID'];
  /** The training provider id that belongs to this training */
  providerId: Scalars['ID'];
  /** The training type id that belongs to this training */
  typeId: Scalars['ID'];
};

export type PersonProfileCreateTrainingResponse = {
  __typename?: 'PersonProfileCreateTrainingResponse';
  trainings?: Maybe<Array<Maybe<Training>>>;
  workflowEventId?: Maybe<Scalars['ID']>;
  workflowEventTriggers?: Maybe<Array<Maybe<WorkflowEventTriggerGraphite>>>;
};

export type PersonProfileDeleteDocumentInput = {
  /** The id of the document to delete */
  documentId: Scalars['ID'];
  /** The id of the person to whom the document belongs to */
  personId: Scalars['ID'];
};

export type PersonProfileDeleteDocumentResponse = {
  __typename?: 'PersonProfileDeleteDocumentResponse';
  documentId: Scalars['ID'];
  personId: Scalars['ID'];
};

export type PersonProfileDeleteTrainingInput = {
  /** The training to delete by ID */
  id: Scalars['ID'];
};

export type PersonProfileDeleteTrainingResponse = {
  __typename?: 'PersonProfileDeleteTrainingResponse';
  id?: Maybe<Scalars['ID']>;
};

export enum PersonProfileImageTypes {
  PERSON_PROFILE = 'PERSON_PROFILE',
  PROFILE_COVER = 'PROFILE_COVER'
}

export type PersonProfileMutation = {
  __typename?: 'PersonProfileMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Assigned a skill with a specific skill level to a person */
  assignSkillToPerson: AssignSkillToPersonResponse;
  createDocument: PersonProfileCreateDocumentResponse;
  /** Creates a training scoped to a person from the person profile or so on. */
  createTraining: PersonProfileCreateTrainingResponse;
  /** Deletes an assigned skill of a person */
  deleteAssignedSkillForPerson: DeleteAssignedSkillForPersonResponse;
  deleteDocument: PersonProfileDeleteDocumentResponse;
  /** Deletes the given training */
  deleteTraining?: Maybe<PersonProfileDeleteTrainingResponse>;
  /** Updates an assigned skill of a person */
  updateAssignedSkillForPerson: UpdateAssignedSkillForPersonResponse;
  updateDocument: PersonProfileUpdateDocumentResponse;
  /** Updates a training with the given input */
  updateTraining: PersonProfileUpdateTrainingResponse;
};


export type PersonProfileMutationassignSkillToPersonArgs = {
  input: AssignSkillToPersonInput;
};


export type PersonProfileMutationcreateDocumentArgs = {
  input: PersonProfileCreateDocumentInput;
};


export type PersonProfileMutationcreateTrainingArgs = {
  input: PersonProfileCreateTrainingInput;
};


export type PersonProfileMutationdeleteAssignedSkillForPersonArgs = {
  input: DeleteAssignedSkillForPersonInput;
};


export type PersonProfileMutationdeleteDocumentArgs = {
  input: PersonProfileDeleteDocumentInput;
};


export type PersonProfileMutationdeleteTrainingArgs = {
  input: PersonProfileDeleteTrainingInput;
};


export type PersonProfileMutationupdateAssignedSkillForPersonArgs = {
  input: UpdateAssignedSkillForPersonInput;
};


export type PersonProfileMutationupdateDocumentArgs = {
  input: PersonProfileUpdateDocumentInput;
};


export type PersonProfileMutationupdateTrainingArgs = {
  input: PersonProfileUpdateTrainingInput;
};

export enum PersonProfilePictureSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XLARGE = 'XLARGE'
}

export type PersonProfileQuery = {
  __typename?: 'PersonProfileQuery';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
};

export type PersonProfileUpdateDocumentInput = {
  /** New display name to give the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The id of the document to update */
  documentId: Scalars['ID'];
  /** The id of the person to whom the document belongs to */
  personId: Scalars['ID'];
  /** New upload status to set the document to */
  uploadStatus?: InputMaybe<PersonDocumentUploadStatus>;
};

export type PersonProfileUpdateDocumentResponse = {
  __typename?: 'PersonProfileUpdateDocumentResponse';
  document: PersonDocument;
};

export type PersonProfileUpdateTrainingCustomFieldsInput = {
  apiName: Scalars['String'];
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PersonProfileUpdateTrainingInput = {
  /** The completion date of the training */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** The training coordinator person id that belongs to this training */
  coordinatorPersonId?: InputMaybe<Scalars['ID']>;
  /** The cost of the training */
  cost?: InputMaybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** Custom field values to update for this training */
  customFieldValues?: InputMaybe<Array<InputMaybe<PersonProfileUpdateTrainingCustomFieldsInput>>>;
  /** The hours the training took */
  hours?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The id of the job to which the training belongs to */
  jobId?: InputMaybe<Scalars['ID']>;
  /** The name of the training */
  name?: InputMaybe<Scalars['String']>;
  /** The id of the person to whom the training belongs to; needed with job id */
  personId?: InputMaybe<Scalars['ID']>;
  /** The training provider id that belongs to this training */
  providerId?: InputMaybe<Scalars['ID']>;
  /** The status of the training */
  statusId?: InputMaybe<Scalars['ID']>;
  /** The training type id that belongs to this training */
  typeId?: InputMaybe<Scalars['ID']>;
};

export type PersonProfileUpdateTrainingResponse = {
  __typename?: 'PersonProfileUpdateTrainingResponse';
  training?: Maybe<Training>;
};

export type PersonSearchObjectInput = {
  autoIncrementIntellihrId?: InputMaybe<Scalars['String']>;
  autoIncrementingIntellihrId?: InputMaybe<Scalars['String']>;
  employeeNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  primaryEmailAddress?: InputMaybe<Scalars['String']>;
};

export type PersonSkinny = {
  __typename?: 'PersonSkinny';
  /** The ID of the job related to this event, if present */
  jobId?: Maybe<Scalars['ID']>;
  /** The human readable position title of the job */
  jobPositionTitle?: Maybe<Scalars['String']>;
  /** The display name of the person for this event */
  personDisplayName: Scalars['String'];
  /** The ID of the person for this event */
  personId: Scalars['ID'];
  /** Initials to display on the frontend for this person */
  personInitials?: Maybe<Scalars['String']>;
  /** URL of profile pictures */
  personProfilePictureUrl?: Maybe<Scalars['String']>;
};


export type PersonSkinnypersonProfilePictureUrlArgs = {
  size: PersonProfilePictureSize;
};

export type PersonUpdateAddressInput = {
  /** The ID of the address type */
  addressTypeId: Scalars['ID'];
  /** The country the address is in */
  country: Scalars['String'];
  /** Custom field values for the address */
  customFieldValues?: InputMaybe<Array<AddressCustomFieldValueInput>>;
  /**
   * A free text field to be used by other platforms integrating with us.
   * Will not be displayed on the frontend.
   */
  externalId?: InputMaybe<Scalars['String']>;
  /** The full readable address, minus the postcode and country */
  fullAddress?: InputMaybe<Scalars['String']>;
  /**
   * Whether it is the person's primary address.
   * At least one of the addresses provided must be a primary address.
   * When not provided, will default to false.
   */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The latitude of the address */
  latitude?: InputMaybe<Scalars['Float']>;
  /** The longitude of the address */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The postcode of the address */
  postcode?: InputMaybe<Scalars['String']>;
  /**
   * The state the address is in.
   * This is australia-specific and may not always be provided.
   */
  state?: InputMaybe<Scalars['String']>;
  /**
   * The street the address is on.
   * This is australia-specific and may not always be provided.
   */
  street?: InputMaybe<Scalars['String']>;
  /**
   * The suburb the address is in.
   * This is australia-specific and may not always be provided.
   */
  suburb?: InputMaybe<Scalars['String']>;
};

export type PersonUpdateCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PersonUpdateEmailInput = {
  /** Custom values associated with this email address */
  customFieldValues?: InputMaybe<Array<EmailAddressCustomFieldValueInput>>;
  /** An email address associated with this person */
  email: Scalars['String'];
  /** Wether it is a personal email address */
  isPersonal: Scalars['Boolean'];
  /**
   * Whether it is the person's primary email address.
   * At least one of the emails provided must be a primary email.
   * When not provided, will default to false.
   */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type PersonUpdateInput = {
  /**
   * The physical addresses associated with this person.
   * This will replace all of their current addresses.
   */
  addresses?: InputMaybe<Array<InputMaybe<PersonUpdateAddressInput>>>;
  /** Custom values associated with this person */
  customFieldValues?: InputMaybe<Array<PersonUpdateCustomFieldValueInput>>;
  /** The person's date of birth */
  dateOfBirth?: InputMaybe<Scalars['String']>;
  /**
   * The email addresses associated with this person.
   * This will replace all of their current emails.
   */
  emailAddresses?: InputMaybe<Array<PersonUpdateEmailInput>>;
  /** The email of the person's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']>;
  /** The name of the person's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']>;
  /** The phone number of the person's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  /** The country ID of the phone number of the person's emergency contact */
  emergencyContactPhoneCountryId?: InputMaybe<Scalars['ID']>;
  /** The relationship between the person and emergency contact */
  emergencyContactRelationship?: InputMaybe<Scalars['String']>;
  /** The employee number for the person */
  employeeNumber?: InputMaybe<Scalars['String']>;
  /** The person's first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the gender of the person */
  genderId?: InputMaybe<Scalars['ID']>;
  /** The ID of the person being updated */
  id: Scalars['ID'];
  /** The person's last name */
  lastName?: InputMaybe<Scalars['String']>;
  /**
   * The medical conditions that this person has.
   * This will replace all of their current medical conditions.
   */
  medicalConditions?: InputMaybe<Array<PersonCreateMedicalConditionInput>>;
  /** The person's middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /**
   * The phone numbers associated with t11his person.
   * This will replace all of their current phone numbers.
   */
  phoneNumbers?: InputMaybe<Array<PersonUpdatePhoneNumberInput>>;
  /** The person's preffered name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** The title ID for this person */
  titleId?: InputMaybe<Scalars['ID']>;
  /** Deprecated. This field is unused. */
  workRightCountryId?: InputMaybe<Scalars['ID']>;
  /** The expiry date of the person's work right */
  workRightExpiryDate?: InputMaybe<Scalars['String']>;
  /** The workright ID */
  workRightId?: InputMaybe<Scalars['ID']>;
};

export type PersonUpdatePhoneNumberInput = {
  /** The country that belongs to this phone number */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for the phone number */
  customFieldValues?: InputMaybe<Array<PhoneNumberCustomFieldValueInput>>;
  /** If the phone number is a personal phone number */
  isPersonal: Scalars['Boolean'];
  /**
   * Whether it is the person's primary phone number.
   * At least one of the phone numbers provided must be a primary number.
   * When not provided, will default to false.
   */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The number of the phone number */
  number: Scalars['String'];
};

export type PersonUpdateResponse = {
  __typename?: 'PersonUpdateResponse';
  person: Person;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /** The country that belong to this phone number, or null for old phone numbers with no country */
  country?: Maybe<Country>;
  /** The custom fields on phone number */
  customFieldValues: Array<CustomFieldValue>;
  /**
   * The full number for the phone including the dial code for the country.
   * If the country is not set, then this will be the same as 'number'.
   */
  fullNumber: Scalars['String'];
  id: Scalars['ID'];
  /** If the phone number is a personal phone number */
  isPersonal: Scalars['Boolean'];
  /** If the phone number is a primary phone number */
  isPrimary: Scalars['Boolean'];
  /** The number of the phone number */
  number: Scalars['String'];
};


export type PhoneNumbercustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type PhoneNumberCreateInput = {
  /** The country that belong to this phone number */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for the phone number */
  customFieldValues?: InputMaybe<Array<PhoneNumberCustomFieldValueInput>>;
  /** If the phone number is a personal phone number */
  isPersonal: Scalars['Boolean'];
  /** If the phone number is a primary phone number */
  isPrimary: Scalars['Boolean'];
  /** The number of the phone number */
  number: Scalars['String'];
  /** The ID of the person to whom the number belongs to */
  personId: Scalars['ID'];
};

export type PhoneNumberCreateResponse = {
  __typename?: 'PhoneNumberCreateResponse';
  /** The person who owns the phone number that has been created. */
  person: Person;
  /** The phone number that has been created for the person. */
  phoneNumber: PhoneNumber;
};

export type PhoneNumberCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PhoneNumberDeleteResponse = {
  __typename?: 'PhoneNumberDeleteResponse';
  /** The ID of the phone number that was deleted */
  id: Scalars['ID'];
  /** The person who owned the phone number that was deleted. Used to reload the list of phone numbers. */
  person: Person;
};

export type PhoneNumberUpdateInput = {
  /** The country that belong to this phone number */
  countryId?: InputMaybe<Scalars['ID']>;
  /** Custom field values for the phone number */
  customFieldValues?: InputMaybe<Array<PhoneNumberCustomFieldValueInput>>;
  /** The ID of the phone number */
  id: Scalars['ID'];
  /** If the phone number is a personal phone number */
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  /** If the phone number is a primary phone number */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The number of the phone number */
  number?: InputMaybe<Scalars['String']>;
};

export type PhoneNumberUpdateResponse = {
  __typename?: 'PhoneNumberUpdateResponse';
  /** The person who owns the phone number that is updated. */
  person: Person;
  /** The phone number that is updated. */
  phoneNumber: PhoneNumber;
};

export type Plan = {
  __typename?: 'Plan';
  /** The date time of plan was created */
  createdAt: Scalars['DateTime'];
  /** Description of the plan */
  description: Scalars['String'];
  /** the id of the plan */
  id: Scalars['ID'];
  /** Whether this plan is an internal system plan. Only full feature suite at this time */
  isSystem: Scalars['Boolean'];
  /** the name of the plan */
  name: Scalars['String'];
  /** The list of modules available on this plan. */
  planModules: Array<PlanModule>;
  /** The list of tenants that are using this plan */
  tenantUsages: Array<PlanTenantUsage>;
  /** The date time of plan was updated */
  updatedAt: Scalars['DateTime'];
};

export type PlanCreateInput = {
  /** Description of the plan */
  description?: InputMaybe<Scalars['String']>;
  /** What modules are enabled for this plan */
  moduleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Name of the plan */
  name: Scalars['String'];
};

export type PlanCreateResponse = {
  __typename?: 'PlanCreateResponse';
  /** The plan that was created. */
  plan: Plan;
};

export type PlanDeleteInput = {
  /** The ID of the plan being deleted */
  id: Scalars['ID'];
};

export type PlanDeleteResponse = {
  __typename?: 'PlanDeleteResponse';
  /** The ID of the plan  that was deleted. */
  id: Scalars['ID'];
};

export type PlanModule = {
  __typename?: 'PlanModule';
  /** Ihe id of the pivot between plans and modules */
  id: Scalars['ID'];
  /** The ID of the module this pivot is for */
  module: Module;
  /** The ID of the plan this pivot is for */
  plan: Plan;
};

export type PlanTenantUsage = {
  __typename?: 'PlanTenantUsage';
  /** The name of the tenant that is using this plan */
  tenantName: Scalars['String'];
};

export type PlanUpdateInput = {
  /** Description of the plan */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the plan being updated */
  id: Scalars['ID'];
  /** What modules are enabled for this plan */
  moduleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Name of the plan */
  name?: InputMaybe<Scalars['String']>;
};

export type PlanUpdateResponse = {
  __typename?: 'PlanUpdateResponse';
  /** The plan that was updated. */
  plan: Plan;
};

export type PopulatedContract = {
  __typename?: 'PopulatedContract';
  /** The contract template body with placeholders filled by their respective resolved values */
  body: Scalars['String'];
};

export type PopulatedContractV2 = {
  __typename?: 'PopulatedContractV2';
  /** Placeholder values that were filled by the system, and their respective values */
  autoFilledValues: Scalars['JSON'];
  /** The contract template body with placeholders filled by their respective resolved values */
  body: Scalars['String'];
  /** Placeholder values that have not been filled */
  missingValues?: Maybe<Array<Scalars['String']>>;
  /** Placeholder values that were filled by the contract issuer and their respective values */
  overrideValues: Scalars['JSON'];
};

export type PositionTitle = {
  __typename?: 'PositionTitle';
  /** An optional code that relates to the position title */
  code?: Maybe<Scalars['String']>;
  customFieldValues: Array<CustomFieldValue>;
  id: Scalars['ID'];
  /** If the position title has been archived or not (all archived titles should be isEnabled = false) */
  isArchived: Scalars['Boolean'];
  /** If this position title is enabled for being selected */
  isEnabled: Scalars['Boolean'];
  /** If this position is currently in use by a undeleted job or is a system title that can't be deleted */
  isInUse: Scalars['Boolean'];
  /** If this position title is a system title (like the special Previous position titles) */
  isSystem: Scalars['Boolean'];
  /** How many unique jobs are using this position title (not job_names records, but jobs records) */
  jobCount: Scalars['Int'];
  /** Job names that use this position title */
  jobNames: Array<JobName>;
  /** The name of the position title */
  name: Scalars['String'];
};


export type PositionTitlecustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type PositionTitlejobCountArgs = {
  jobStatuses?: InputMaybe<Array<JobStatus>>;
};

export type PositionTitleCreateInput = {
  /** An optional code to  reference the position title, This must be unique */
  code?: InputMaybe<Scalars['String']>;
  /** Custom field values for the position title */
  customFieldValues?: InputMaybe<Array<PositionTitleCustomFieldValueInput>>;
  /** The name of position title to create. This must be unique among all lowercase names */
  name: Scalars['String'];
};

export type PositionTitleCreateResponse = {
  __typename?: 'PositionTitleCreateResponse';
  /** The position title that was created */
  positionTitle: PositionTitle;
};

export type PositionTitleCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type PositionTitleDeleteResponse = {
  __typename?: 'PositionTitleDeleteResponse';
  /** The ID of the position title that was deleted */
  id: Scalars['ID'];
};

export type PositionTitleMergeInput = {
  /** The position title to merge from */
  fromPositionTitleId: Scalars['ID'];
  /** The position title to merge into */
  toPositionTitleId: Scalars['ID'];
};

export type PositionTitleMergeResponse = {
  __typename?: 'PositionTitleMergeResponse';
  /** The position title that was deleted */
  deletedPositionTitleId: Scalars['ID'];
  /** The position title that was merged into */
  positionTitle: PositionTitle;
};

export type PositionTitleUpdateInput = {
  /** An optional code to  reference the position title, This must be unique */
  code?: InputMaybe<Scalars['String']>;
  /** Custom field values for the position title */
  customFieldValues?: InputMaybe<Array<PositionTitleCustomFieldValueInput>>;
  /** The id of position title to update */
  id: Scalars['ID'];
  /** Whether the position title is archived or not */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Whether the position title is enabled or not */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name to update to. This must be unique among all lowercase names */
  name?: InputMaybe<Scalars['String']>;
};

export type PositionTitleUpdateResponse = {
  __typename?: 'PositionTitleUpdateResponse';
  /** The position title that was updated */
  positionTitle: PositionTitle;
};

export type PrivateKeyStatusResponse = {
  __typename?: 'PrivateKeyStatusResponse';
  id: Scalars['String'];
  privateKeyStatus: Scalars['String'];
};

export type Pulse = {
  __typename?: 'Pulse';
  /** When the pulse was created */
  createdAt: Scalars['DateTime'];
  /** The date when the anonymous pulse is closed. Returns null for non-anonymous pulse */
  cutoffDate?: Maybe<Scalars['DateTime']>;
  /** Additional data for pulse recurrence */
  data?: Maybe<Scalars['JSON']>;
  /** The form design sent in the pulse */
  formDesign: FormDesign;
  /** The form design sent in the pulse (Anonymous/Non-anonymous) */
  formDesignable: FormDesignable;
  /** Pulse id */
  id: Scalars['ID'];
  /** Pulse name */
  name: Scalars['String'];
  /** The pulse recurrence this pulse spawned from */
  pulseRecurrence?: Maybe<PulseRecurrence>;
  /** Whether this pulse has been queued to send */
  queued: Scalars['Boolean'];
  /** When the pulse was sent to its respondents */
  sendDate: Scalars['DateTime'];
  /** The status of this pulse */
  status: PulseStatusEnum;
  /** When the pulse was last updated */
  updatedAt: Scalars['DateTime'];
};


export type PulsepulseRecurrenceArgs = {
  trashed?: InputMaybe<Trashed>;
};

export type PulseDeferred = {
  __typename?: 'PulseDeferred';
  pulse?: Maybe<Pulse>;
};

export type PulseFilter = {
  __typename?: 'PulseFilter';
  /** The filter that is being applied */
  filter: PulseFilterUnion;
  id: Scalars['ID'];
  /**
   * The (recurring) pulse that this filter is applied to. Note that if the
   * (recurring) pulse has been deleted, this will return null.
   */
  pulseModel?: Maybe<PulseModelUnion>;
};

export type PulseFilterType = {
  __typename?: 'PulseFilterType';
  id: Scalars['ID'];
  /** Pulse filter type name */
  name: Scalars['String'];
};

export type PulseFilterUnion = ExcludeJobsOnExtendedLeave | ExcludeJobsOnProbation | ExcludeRespondentsWithoutEnabledUsers | ExcludeSubjects | IncludeBusinessEntities | IncludeBusinessUnits | IncludeLocations | IncludePayGrades | IncludeSubjects | IncludeWorkClasses | JsonPulseFilterResponse;

export type PulseFilters = {
  /** Array of Job ids to exclude. Null indicates that this filter is not enabled */
  excludeJobIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Whether people on extended leave should be excluded or not */
  excludePeopleOnExtendedLeave: Scalars['Boolean'];
  /** Whether people with probation period should be excluded or not */
  excludePeopleWithinProbationPeriod: Scalars['Boolean'];
  /** Whether people without user account should be excluded or not */
  excludeRespondentsWithoutEnabledUsers: Scalars['Boolean'];
  /** Array of Business Entity ids to include. Null indicates that this filter is not enabled */
  includeBusinessEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Business Unit ids to include. Null indicates that this filter is not enabled */
  includeBusinessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Job ids to include. Null indicates that this filter is not enabled */
  includeJobIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Location ids to include. Null indicates that this filter is not enabled */
  includeLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Pay Grade IDs to include. Null indicates that this filter is not enabled */
  includePayGradeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Array of Work Class ids to include. Null indicates that this filter is not enabled */
  includeWorkClassIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PulseFormPreviewsResponse = {
  __typename?: 'PulseFormPreviewsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  formPreviews: Array<FormPreview>;
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PulseModelDeferredUnion = PulseDeferred | RecurringPulseDeferred;

export type PulseModelUnion = Pulse | PulseRecurrence;

export type PulseModelsForReviewPulseLocationFiltersResponse = PaginationResponse & {
  __typename?: 'PulseModelsForReviewPulseLocationFiltersResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  pulseModels: Array<PulseModelDeferredUnion>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PulseRecurrence = {
  __typename?: 'PulseRecurrence';
  /** When the pulse recurrence was created */
  createdAt: Scalars['DateTime'];
  /** Additional data for pulse */
  data?: Maybe<Scalars['JSON']>;
  /** Recurring amount of days */
  days: Scalars['Int'];
  /** When the pulse recurrence was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The form design sent in the pulse */
  formDesign: FormDesign;
  /** The form design sent in the pulse (Anonymous/Non-anonymous) */
  formDesignable: FormDesignable;
  /** Pulse recurrence id */
  id: Scalars['ID'];
  /** Date this pulse was last sent */
  lastPulseDate?: Maybe<Scalars['DateTime']>;
  /** Recurring amount of months */
  months: Scalars['Int'];
  /** Pulse recurrence name */
  name: Scalars['String'];
  /** When the next pulse will be sent to its respondents */
  nextSendDate?: Maybe<Scalars['DateTime']>;
  /** When the first pulse was sent to its respondents */
  startDate: Scalars['DateTime'];
  /** The status of this recurring pulse */
  status: PulseStatusEnum;
  /** When the pulse recurrence was last updated */
  updatedAt: Scalars['DateTime'];
  /** Recurring amount of weeks */
  weeks: Scalars['Int'];
  /** Recurring amount of years */
  years: Scalars['Int'];
};

export type PulseSingleSelectCustomFieldsInput = {
  customFieldDefinitionId: Scalars['ID'];
  /** Single select options. If wanting to filter by the answer was not being provided, pass null as an array element. */
  options: Array<InputMaybe<Scalars['ID']>>;
};

export enum PulseStatusEnum {
  FAILED_TO_SEND = 'FAILED_TO_SEND',
  INVALID_FILTERS = 'INVALID_FILTERS',
  SENT = 'SENT',
  UPCOMING = 'UPCOMING'
}

export type PulsesUsingCustomField = {
  __typename?: 'PulsesUsingCustomField';
  pulses: Array<Maybe<Pulse>>;
  recurringPulses: Array<Maybe<PulseRecurrence>>;
};

export type QualificationAttachmentRequirementType = {
  __typename?: 'QualificationAttachmentRequirementType';
  id: Scalars['ID'];
  /** The human-readable name of the qualification attachment requirement type */
  name: Scalars['String'];
  /** The sort order of the qualification attachment requirement type */
  sortOrder: Scalars['Int'];
};

export type QualificationComplianceOverview = {
  __typename?: 'QualificationComplianceOverview';
  qualificationTemplateId?: Maybe<Scalars['ID']>;
  qualificationTemplateName?: Maybe<Scalars['String']>;
  qualificationTypeName?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalAwaitingApproval?: Maybe<Scalars['Int']>;
  totalCompliant?: Maybe<Scalars['Int']>;
  totalExpiringSoon?: Maybe<Scalars['Int']>;
  totalNonCompliant?: Maybe<Scalars['Int']>;
};

export type QualificationComplianceOverviewStatsV2 = {
  __typename?: 'QualificationComplianceOverviewStatsV2';
  total?: Maybe<Scalars['Int']>;
  totalAwaitingApproval?: Maybe<Scalars['Int']>;
  totalCompliant?: Maybe<Scalars['Int']>;
  totalCompliantPercentage?: Maybe<Scalars['Int']>;
  totalExpiringSoon?: Maybe<Scalars['Int']>;
  totalNonCompliant?: Maybe<Scalars['Int']>;
};

export type QualificationComplianceOverviewsResponse = PaginationResponse & {
  __typename?: 'QualificationComplianceOverviewsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  qualificationComplianceOverviews?: Maybe<Array<QualificationComplianceOverview>>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type QualificationCompositeCompliance = {
  __typename?: 'QualificationCompositeCompliance';
  /** The expiry date of the current qualification (optional) */
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the current qualification */
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of composite qualification. Matches the ID of the current qualification */
  currentQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** The Qualification Status of the current qualification */
  currentQualificationStatus?: Maybe<QualificationStatuses>;
  /** The last date the status the current qualification was changed */
  currentStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  /** The updatedAt date of the current qualification (optional) */
  currentUpdatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Is the composite qualification Compliant */
  isCompliant: Scalars['Boolean'];
  /** The job of the owner of the composite qualification */
  job: Job;
  /** The related Job Requirement Group */
  jobRequirementGroup?: Maybe<JobRequirementGroup>;
  /** The owner of the composite qualification */
  person: Person;
  /** The Qualification Template */
  qualificationTemplate: QualificationTemplate;
  /** The expiry date of the renewal qualification (optional) */
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the renewal qualification */
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of renewal qualification */
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** The Qualification Status of the current qualification */
  renewalQualificationStatus?: Maybe<QualificationStatuses>;
  /** The last date the status the renewal qualification was changed */
  renewalStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  /** The updatedAt date of the renewal qualification (optional) */
  renewalUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The effective end date of the job requirement group */
  restrictedEndDate?: Maybe<Scalars['DateTime']>;
  /** The effective start date of the job requirement group */
  restrictedStartDate?: Maybe<Scalars['DateTime']>;
};

export type QualificationCompositeComplianceStats = {
  __typename?: 'QualificationCompositeComplianceStats';
  total: Scalars['Int'];
  totalAwaitingApproval: Scalars['Int'];
  totalCompliant: Scalars['Int'];
  totalCompliantPercentage: Scalars['Int'];
  totalExpiringSoon: Scalars['Int'];
  totalNonCompliant: Scalars['Int'];
};

export type QualificationCompositeComplianceV2 = {
  __typename?: 'QualificationCompositeComplianceV2';
  currentApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the current qualification */
  currentExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the current qualification */
  currentIssueDate?: Maybe<Scalars['DateTime']>;
  currentQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** When the current qualification was last changed */
  currentStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  currentUpdatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  /** Compliance status of the qualification (boolean) */
  isCompliant?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  /** The job assigned to the standalone compliance qualification overview */
  jobId?: Maybe<Scalars['ID']>;
  jobRequirementGroupName?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  /** The person assigned to the standalone compliance qualification overview */
  personId?: Maybe<Scalars['ID']>;
  /** The effective end date of the qualification restricted period */
  qualificationRestrictedPeriodEndDate?: Maybe<Scalars['DateTime']>;
  /** The effective start date of the qualification restricted period */
  qualificationRestrictedPeriodStartDate?: Maybe<Scalars['DateTime']>;
  qualificationRestrictedPeriods?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  renewalApprovalStatusId?: Maybe<Scalars['ID']>;
  /** The expiry of the renewal qualification */
  renewalExpiryDate?: Maybe<Scalars['DateTime']>;
  /** The issue date of the renewal qualification */
  renewalIssueDate?: Maybe<Scalars['DateTime']>;
  renewalQualificationInstanceId?: Maybe<Scalars['ID']>;
  /** When the current qualification was last changed */
  renewalStatusLastChangedAt?: Maybe<Scalars['DateTime']>;
  renewalUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type QualificationExpiryType = {
  __typename?: 'QualificationExpiryType';
  id: Scalars['ID'];
  /** The human-readable name of the qualification expiry type */
  name: Scalars['String'];
  /** The sort order of the qualification expiry type */
  sortOrder: Scalars['Int'];
};

export type QualificationInstanceAttachmentCreateInput = {
  /** The extension of the document, not including the dot. */
  extension: Scalars['String'];
  /** The original filename of the document. This will be used for display name. Includes extension */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The ID of the qualification instance the document will be attached to */
  qualificationInstanceId: Scalars['ID'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
};

export type QualificationInstanceAttachmentCreateResponse = {
  __typename?: 'QualificationInstanceAttachmentCreateResponse';
  document: PersonDocument;
  presignedUploadUrl: Scalars['String'];
};

export type QualificationInstanceAttachmentDeleteResponse = {
  __typename?: 'QualificationInstanceAttachmentDeleteResponse';
  documentId: Scalars['ID'];
};

export type QualificationInstanceAttachmentUpdateInput = {
  /** New display name to give the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document to update */
  documentId: Scalars['ID'];
  /** The ID of the folder where the document will be stored */
  folderId?: InputMaybe<Scalars['String']>;
  /** New upload status to set the document to */
  uploadStatus?: InputMaybe<PersonDocumentUploadStatus>;
};

export type QualificationInstanceAttachmentUpdateResponse = {
  __typename?: 'QualificationInstanceAttachmentUpdateResponse';
  document: PersonDocument;
};

export type QualificationInstanceComment = {
  __typename?: 'QualificationInstanceComment';
  approvalStatusId?: Maybe<Scalars['ID']>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  person: Person;
};

export type QualificationInstanceCommentCreateInput = {
  comment: Scalars['String'];
  notifyEmployee?: InputMaybe<Scalars['Boolean']>;
  qualificationInstanceId: Scalars['ID'];
};

export type QualificationInstanceCommentCreateResponse = {
  __typename?: 'QualificationInstanceCommentCreateResponse';
  qualificationInstance?: Maybe<QualificationInstanceV2>;
};

export type QualificationInstanceCommentDeleteResponse = {
  __typename?: 'QualificationInstanceCommentDeleteResponse';
  id: Scalars['ID'];
};

export type QualificationInstanceCreateInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  expiryNotificationQueuedAt?: InputMaybe<Scalars['DateTime']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganisation?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  personId: Scalars['ID'];
  qualificationStatusId: Scalars['ID'];
  qualificationTemplateId: Scalars['ID'];
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type QualificationInstanceCreateResponse = {
  __typename?: 'QualificationInstanceCreateResponse';
  qualificationInstance: QualificationInstanceV2;
};

export type QualificationInstanceDeleteResponse = {
  __typename?: 'QualificationInstanceDeleteResponse';
  id: Scalars['ID'];
};

export type QualificationInstanceSubmitResponse = {
  __typename?: 'QualificationInstanceSubmitResponse';
  qualificationInstance: QualificationInstanceV2;
};

export type QualificationInstanceUpdateInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  expiryNotificationQueuedAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganisation?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  qualificationStatusId?: InputMaybe<Scalars['ID']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type QualificationInstanceUpdateResponse = {
  __typename?: 'QualificationInstanceUpdateResponse';
  qualificationInstance: QualificationInstanceV2;
};

export type QualificationInstanceV2 = {
  __typename?: 'QualificationInstanceV2';
  /** Attachments linked to this qualification instance */
  attachments: Array<PersonDocument>;
  /** The Calculated Qualification Status */
  calculatedQualificationStatus: QualificationStatuses;
  /** Comments linked to this qualification instance */
  comments: Array<QualificationInstanceComment>;
  /** The expiry date of the qualification instance (optional) */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The queued at time of the expiry notification for the qualification instance (optional) */
  expiryNotificationQueuedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The issue date of the qualification instance */
  issueDate?: Maybe<Scalars['DateTime']>;
  /** The issuing organisation of the qualification instance (optional) */
  issuingOrganisation?: Maybe<Scalars['String']>;
  /** The notes stored on the qualification instance */
  notes?: Maybe<Scalars['String']>;
  /** The owner of the qualification instance */
  person: Person;
  /** The Qualification Status */
  qualificationStatus: QualificationStatuses;
  /** The Qualification Template  */
  qualificationTemplate: QualificationTemplate;
  /** The registration number of the qualification instance (optional) */
  registrationNumber?: Maybe<Scalars['String']>;
};

export type QualificationRegistrationNumberVisibilityTypeV2 = {
  __typename?: 'QualificationRegistrationNumberVisibilityTypeV2';
  id: Scalars['ID'];
  /** The human-readable name of the qualification registration number visibility type */
  name: Scalars['String'];
  /** The sort order of the qualification registration number visibility type */
  sortOrder: Scalars['Int'];
};

export type QualificationStandaloneComplianceOverviewsResponse = PaginationResponse & {
  __typename?: 'QualificationStandaloneComplianceOverviewsResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  qualificationStandaloneComplianceOverviews?: Maybe<Array<QualificationComplianceOverview>>;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type QualificationStatuses = {
  __typename?: 'QualificationStatuses';
  id: Scalars['ID'];
  /** The human-readable name of the qualification status */
  name: Scalars['String'];
  /** The sort order of the qualification status */
  sortOrder: Scalars['Int'];
};

export type QualificationTemplate = {
  __typename?: 'QualificationTemplate';
  /**
   * The Expiry Warning Periods (Expanded to have multiple levels)
   * @deprecated Use expiryWarningPeriodHigh, expiryWarningPeriodMed, and expiryWarningPeriodLow instead
   */
  expiryPeriods?: Maybe<Array<Maybe<QualificationTemplateExpiryPeriod>>>;
  /** The Expiry Warning Period */
  expiryWarningPeriod?: Maybe<Scalars['Int']>;
  /** Accessors for the expiry periods for specific levels */
  expiryWarningPeriodHigh?: Maybe<Scalars['Int']>;
  expiryWarningPeriodLow?: Maybe<Scalars['Int']>;
  expiryWarningPeriodMed?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Number of job requirement groups currently being used with this qualification template */
  jobRequirementGroupUsageCount: Scalars['Int'];
  /** Number of job requirements currently being used with this qualification template */
  jobRequirementUsageCount: Scalars['Int'];
  /** The human-readable name of the qualification template */
  name: Scalars['String'];
  /** The Qualification Attachment Requirement Type */
  qualificationAttachmentRequirementType: QualificationAttachmentRequirementType;
  /** The Qualification Expiry Type */
  qualificationExpiryType: QualificationExpiryType;
  /** Number of qualification instances currently being used with this qualification template */
  qualificationInstanceUsageCount: Scalars['Int'];
  /** The Qualification RegistrationNumberVisibility Type */
  qualificationRegistrationNumberVisibilityType: QualificationRegistrationNumberVisibilityTypeV2;
  /** The Qualification Type  */
  qualificationType: QualificationTypeV2;
  qualificationTypeId: Scalars['ID'];
  /** Custom label for the registration number for this qualification template */
  registrationNumberName?: Maybe<Scalars['String']>;
  /** Send Expiry Warning */
  sendExpiryWarning?: Maybe<Scalars['Boolean']>;
};

export type QualificationTemplateCreateInput = {
  expiryWarningPeriod?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodHigh?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodLow?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodMed?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  qualificationAttachmentRequirementTypeId: Scalars['ID'];
  qualificationExpiryTypeId: Scalars['ID'];
  qualificationRegistrationNumberVisibilityTypeId: Scalars['ID'];
  qualificationTypeId: Scalars['ID'];
  registrationNumberName?: InputMaybe<Scalars['String']>;
  sendExpiryWarning?: InputMaybe<Scalars['Boolean']>;
};

export type QualificationTemplateCreateResponse = {
  __typename?: 'QualificationTemplateCreateResponse';
  qualificationTemplate: QualificationTemplate;
};

export type QualificationTemplateDeleteResponse = {
  __typename?: 'QualificationTemplateDeleteResponse';
  id: Scalars['ID'];
};

export type QualificationTemplateExpiryPeriod = {
  __typename?: 'QualificationTemplateExpiryPeriod';
  /** The Expiry Warning Period */
  expiryWarningPeriod: Scalars['Int'];
  id: Scalars['ID'];
  /** The Notification Event Type */
  notificationEventType: Scalars['ID'];
  /** The Qualification Template */
  qualificationTemplate: QualificationTemplate;
};

export type QualificationTemplateUpdateInput = {
  expiryWarningPeriod?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodHigh?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodLow?: InputMaybe<Scalars['Int']>;
  expiryWarningPeriodMed?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  qualificationAttachmentRequirementTypeId?: InputMaybe<Scalars['ID']>;
  qualificationExpiryTypeId?: InputMaybe<Scalars['ID']>;
  qualificationRegistrationNumberVisibilityTypeId?: InputMaybe<Scalars['ID']>;
  qualificationTypeId?: InputMaybe<Scalars['ID']>;
  registrationNumberName?: InputMaybe<Scalars['String']>;
  sendExpiryWarning?: InputMaybe<Scalars['Boolean']>;
};

export type QualificationTemplateUpdateResponse = {
  __typename?: 'QualificationTemplateUpdateResponse';
  qualificationTemplate?: Maybe<QualificationTemplate>;
};

export type QualificationTypeCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type QualificationTypeCreateResponse = {
  __typename?: 'QualificationTypeCreateResponse';
  qualificationType: QualificationTypeV2;
};

export type QualificationTypeDeleteResponse = {
  __typename?: 'QualificationTypeDeleteResponse';
  id: Scalars['ID'];
};

export type QualificationTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type QualificationTypeUpdateResponse = {
  __typename?: 'QualificationTypeUpdateResponse';
  qualificationType?: Maybe<QualificationTypeV2>;
};

export type QualificationTypeV2 = {
  __typename?: 'QualificationTypeV2';
  id: Scalars['ID'];
  /** The human-readable name of the qualification type */
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Empty type so the base query can be extended */
  _empty?: Maybe<Scalars['String']>;
  analyticsConfiguration?: Maybe<AnalyticsConfigurationQuery>;
  /**
   * Operations for accessing analytics domain info or metadata, such as
   * analytics pages
   */
  analyticsDomain: AnalyticsDomainQuery;
  analyticsFormData?: Maybe<AnalyticsFormDataQuery>;
  analyticsPermission?: Maybe<AnalyticsPermissionQuery>;
  /** Operations for accessing operations related to analytics setting page */
  analyticsSetting: AnalyticsSettingQuery;
  analyticsSettings?: Maybe<AnalyticsSettingsQuery>;
  /** Operations for accessing analytics support data */
  analyticsSupportData: AnalyticsSupportDataQuery;
  /**
   * Operations for accessing operations related to anonymous form data
   * analytics
   */
  anonymousFormDataAnalytics: AnonymousFormDataAnalyticsQuery;
  auth?: Maybe<AuthQuery>;
  authSftpInboundCredential: AuthSftpInboundCredentialResponse;
  bulkDataImportGetSupportedDownload?: Maybe<BulkDataImportSupportedDownload>;
  /** Lists all supported csv uploads with their details for bulk data imports */
  bulkDataImportGetSupportedUpload: BulkDataImportSupportedUpload;
  /** Lists all supported csv downloads */
  bulkDataImportListAllDownloads: Array<BulkDataImportSupportedDownload>;
  /** Lists all supported csv uploads with their details for bulk data imports */
  bulkDataImportListAllSupportedUploads: Array<BulkDataImportSupportedUpload>;
  /**
   * IMPORTANT NOTE
   * This resolver should not be used in production until the caching
   * implementation in the PrivateKeyAuthenticationService has been resolved.
   * See PrivateKeyAuthenticationService->storeInCache for more details.
   */
  checkPrivateKeyStatus?: Maybe<PrivateKeyStatusResponse>;
  compliance?: Maybe<ComplianceQuery>;
  formCompletionRecipients?: Maybe<FormCompletionRecipientQuery>;
  formCompliance: FormComplianceQuery;
  /**
   * Operations for accessing operations related to form data
   * analytics
   */
  formDataAnalytics: FormDataAnalyticsQuery;
  /** A query to get a specific address */
  getAddress?: Maybe<Address>;
  /** A query to get a specific address type */
  getAddressType: AddressType;
  /** A query to get analytics permission of a permission group */
  getAnalyticsPermissionByPermissionGroup: AnalyticsPermissionPermissionGroupType;
  /** A query to get analytics permission of a user */
  getAnalyticsPermissionByUser: AnalyticsPermissionUserType;
  /** A query to get a specific anonymous form design */
  getAnonymousFormDesign: AnonymousFormDesign;
  getAnonymousFormResponseAggregatedNumbers?: Maybe<AnonymousFormResponseAggregatedNumbers>;
  /** Get details about a specific data import, or null if not present */
  getBulkDataImport?: Maybe<BulkDataImport>;
  /** Get details about a specific data import action, or null if not present */
  getBulkDataImportAction?: Maybe<BulkDataImportAction>;
  /**
   * A query to get a group of specific business entities. Note: These business
   * entities are NOT guaranteed to be in the order of the ids provided
   */
  getBusinessEntities: Array<BusinessEntity>;
  /** Get a single business entity by id */
  getBusinessEntity: BusinessEntity;
  getBusinessPerformanceConfiguration?: Maybe<BusinessPerformanceConfigurationInterface>;
  getBusinessPerformanceMetric?: Maybe<BusinessPerformanceMetric>;
  /** Get a single business unit by id */
  getBusinessUnit: BusinessUnit;
  /**
   * A query to get a group of specific business units. Note: These business units
   * are NOT guaranteed to be in the order of the ids provided
   */
  getBusinessUnits: Array<BusinessUnit>;
  /** A query to get a specific Compensation Plan */
  getCompensationPlan?: Maybe<CompensationPlan>;
  /** @deprecated Moved to V2 model structure */
  getCompensationPlannerBudget?: Maybe<CompensationPlannerBudget>;
  /** Get a single budget group */
  getCompensationPlannerBudgetGroupV2?: Maybe<CompensationPlannerBudgetGroupResponseV2>;
  /** @deprecated Moved to V2 model structure */
  getCompensationPlannerBudgetGroups?: Maybe<CompensationPlannerBudgetGroupsResponse>;
  /** Get all budget groups */
  getCompensationPlannerBudgetGroupsV2?: Maybe<CompensationPlannerBudgetGroupsResponseV2>;
  /** @deprecated Moved to V2 model structure */
  getCompensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
  /** Get a plan */
  getCompensationPlannerPlanV2?: Maybe<CompensationPlannerPlanV2>;
  getCompletedFormInstance: CompletedFormInstanceResponse;
  /** Query the Composite Qualification Statistics */
  getCompositeQualificationsForQualificationTemplateStats: QualificationCompositeComplianceStats;
  getContract?: Maybe<Contract>;
  /** Get details about a specific contract issue batch */
  getContractIssueBatch: ContractIssueBatch;
  /** Get details about a specific contract issue batch item */
  getContractIssueBatchItem: ContractIssueBatchItem;
  getContractPersonSignature?: Maybe<ContractPersonSignature>;
  getContractPersonSignatureForPerson?: Maybe<ContractPersonSignature>;
  /** query to get a specific contract template */
  getContractTemplate?: Maybe<ContractTemplate>;
  getContractTemplatePopulated?: Maybe<PopulatedContract>;
  getContractTemplatePopulatedV2?: Maybe<PopulatedContractV2>;
  /** query to get a single contract signer type by ID */
  getContractTemplateSignerType?: Maybe<ContractTemplateSignerType>;
  /** query to get a specific contract template version 2 */
  getContractTemplateV2?: Maybe<ContractTemplateV2>;
  getContractsForPerson: Array<Contract>;
  /** A query to get a specific country */
  getCountry: Country;
  getCreateJobDetailsFromSearchObjects: GetCreateJobDetailsFromSearchObjectsResponse;
  /** @deprecated Use getPersonDetails instead */
  getCreatePersonDetails: GetCreatePersonDetailsResponse;
  /** A query to get a specific currency */
  getCurrency: Currency;
  getCustomFieldCategory?: Maybe<CustomFieldCategory>;
  /**
   * A query to get a specific custom field definition
   * @deprecated this should be made nullable use getNullableCustomFieldDefinition for now
   */
  getCustomFieldDefinition: CustomFieldDefinition;
  getCustomFieldTemplate?: Maybe<CustomFieldTemplate>;
  getCustomFieldTemplateCategory?: Maybe<CustomFieldTemplateCategory>;
  getDashboardQuote: DashboardQuote;
  /** A query to get a specific default remuneration component */
  getDefaultRemunerationComponent: DefaultRemunerationComponent;
  /** A query to get a specific diary note */
  getDiaryNote?: Maybe<DiaryNote>;
  /** A query to get a specific diary note category */
  getDiaryNoteCategory: DiaryNoteCategory;
  /** A query to get a specific diary note category */
  getDiaryNoteType: DiaryNoteType;
  getDocumentFolder?: Maybe<DocumentFolder>;
  /** A query to get a specific email address */
  getEmailAddress?: Maybe<EmailAddress>;
  /** A query to get a number of duplicate email addresses, ignore email addresses with specific ids */
  getEmailAddressUsageCount?: Maybe<Scalars['Int']>;
  /** A query to get a specific employment condition */
  getEmploymentCondition: EmploymentCondition;
  getEndJobDetailsFromSearchObjects?: Maybe<GetEndJobDetailsFromSearchObjectsResponse>;
  getEstablishment: Establishment;
  /**
   * A query to get a group of specific establishments. Note: These establishments
   * are NOT guaranteed to be in the order of the ids provided
   */
  getEstablishments: Array<Establishment>;
  /** A query to get all external event logs */
  getExternalEventLog?: Maybe<ExternalEventLog>;
  /** A query to get all external webhooks */
  getExternalWebhook?: Maybe<ExternalWebhook>;
  /** A query to get a specific form instance */
  getForm?: Maybe<Form>;
  /** A query to get a specific form design */
  getFormDesign: FormDesign;
  /** A query to get a specific form design category */
  getFormDesignCategory: FormDesignCategory;
  /** A query to get a specific form library item */
  getFormLibraryItem: FormLibraryItem;
  /** A query to get a specific global notification configuration. */
  getGlobalNotificationConfiguration?: Maybe<GlobalNotificationConfiguration>;
  getGoal?: Maybe<Goal>;
  getGoalApprovalStatus?: Maybe<GoalApprovalStatus>;
  getGoalCategory?: Maybe<GoalCategory>;
  getGoalMeasurement?: Maybe<GoalMeasurement>;
  getGoalMeasurementType?: Maybe<GoalMeasurementType>;
  getGoalProgressStatus?: Maybe<GoalProgressStatus>;
  getGoalStatus?: Maybe<GoalStatus>;
  getGoalTemplate?: Maybe<GoalTemplate>;
  getGoalTemplateMeasurement?: Maybe<GoalTemplateMeasurement>;
  getGoalsForPerson: Array<Goal>;
  getGroupCompositeQualificationsForJobRequirementGroupStats?: Maybe<QualificationCompositeComplianceStats>;
  getGroupQualificationComplianceOverviewStats?: Maybe<QualificationComplianceOverviewStatsV2>;
  getHiddenOnboardingSessions?: Maybe<GetPaginatedHiddenOnboardingSessionsResponse>;
  /** A query to get a specific hourly rate type */
  getHourlyRateType: HourlyRateType;
  /** A query to get all enabled integrations */
  getIntegration?: Maybe<Integration>;
  /** A query to get a specific integration configuration */
  getIntegrationConfiguration?: Maybe<IntegrationConfiguration>;
  /** A query to get a specific integration configuration */
  getIntegrationMapping?: Maybe<IntegrationMapping>;
  /** A query to get an iframe url for use in SPA Integrations */
  getIntegrationsCentralIframeUrl: IntegrationCentralIframeResponse;
  /** A query to get a specific address */
  getIntellihrIdStartingValue: IntellihrIdStartingValue;
  /** A query to get a specific job */
  getJob?: Maybe<Job>;
  getJobChangeReason: JobChangeReason;
  /** Query to get a single pending update */
  getJobPendingUpdate?: Maybe<JobPendingUpdate>;
  /** A query to get a specific job requirement */
  getJobRequirement?: Maybe<JobRequirement>;
  /** A query to get a specific job requirement group */
  getJobRequirementGroup?: Maybe<JobRequirementGroup>;
  /** A query to get a group of specific jobs. Note: These jobs are NOT guaranteed to be in the order of the ids provided */
  getJobs: Array<Job>;
  getLeaveDetails: GetLeaveDetailsResponse;
  /** A query to get a specific leaveType */
  getLeaveType: LeaveType;
  /** Get a single location by id */
  getLocation: Location;
  /**
   * A query to get a group of specific locations. Note: These locations are NOT
   * guaranteed to be in the order of the ids provided
   */
  getLocations: Array<Location>;
  /** A query to get a specific medical condition */
  getMedicalCondition?: Maybe<MedicalCondition>;
  getMessageSchedule: MessageSchedule;
  getModule?: Maybe<Module>;
  getNote?: Maybe<Note>;
  /** A query to get a specific notification. Can only fetch notifications targeted at yourself. */
  getNotification?: Maybe<Notification>;
  /** A query go get a specific notification event type. */
  getNotificationEventType?: Maybe<NotificationEventType>;
  /** A query to get multiple notification event types. */
  getNotificationEventTypes?: Maybe<Array<NotificationEventType>>;
  /** A query to get the supported event settings include detail */
  getNotificationSupportedEventSettingsIncludeDetail?: Maybe<NotificationEventSettingsIncludeDetail>;
  getNullableAddressType?: Maybe<AddressType>;
  getNullableBusinessEntity?: Maybe<BusinessEntity>;
  getNullableBusinessUnit?: Maybe<BusinessUnit>;
  getNullableCountry?: Maybe<Country>;
  getNullableCurrency?: Maybe<Currency>;
  /** A query to get a specific custom field definition or null */
  getNullableCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  getNullableDefaultRemunerationComponent?: Maybe<DefaultRemunerationComponent>;
  getNullableEmploymentCondition?: Maybe<EmploymentCondition>;
  getNullableEstablishment?: Maybe<Establishment>;
  getNullableLeaveType?: Maybe<LeaveType>;
  getNullableLocation?: Maybe<Location>;
  getNullablePayCycle?: Maybe<PayCycle>;
  getNullablePayGrade?: Maybe<PayGrade>;
  getNullableRecruitmentSource?: Maybe<RecruitmentSource>;
  getNullableWorkClass?: Maybe<WorkClass>;
  getNullableWorkRight?: Maybe<WorkRight>;
  getNullableWorkType?: Maybe<WorkType>;
  getOnboardingFormInstance?: Maybe<OnboardingFormInstance>;
  getOnboardingFormInstanceById?: Maybe<OnboardingFormInstance>;
  getOnboardingPerson?: Maybe<OnboardingPerson>;
  /** A query to get a specific qualification instance with onboarding checks */
  getOnboardingQualificationInstance?: Maybe<QualificationInstanceV2>;
  getOnboardingSession?: Maybe<OnboardingSession>;
  getOnboardingSessionsForPerson: Array<OnboardingSession>;
  getOnboardingStatus?: Maybe<OnboardingStatus>;
  getOnboardingStatusForPerson?: Maybe<OnboardingStatus>;
  getOnboardingTemplate: OnboardingTemplate;
  getOrgObjective?: Maybe<OrganisationObjective>;
  getPaginatedOnboardingFormInstances: PaginatedOnboardingFormInstances;
  getPaginatedOnboardingSessions: ListPaginatedOnboardingSessionsResponse;
  getPaginatedPeopleAndJobsWithoutOnboarding?: Maybe<ListPaginatedPeopleAndJobsWithoutOnboardingResponse>;
  /** A query to get a specific pay cycle */
  getPayCycle: PayCycle;
  /** A query to get a specific payGrade */
  getPayGrade: PayGrade;
  getPayGradeAuditExportUrl: PayGradeAuditExportUrlResponse;
  getPayGrades: Array<PayGrade>;
  /** A query to get a specific payGrade */
  getPayStep?: Maybe<PayStep>;
  /** A query to get a group of specific people. Note: These people are NOT guaranteed to be in the order of the ids provided */
  getPeople: Array<Person>;
  /** A query to get people and filter by business units for contract issue batch */
  getPeopleBy: Array<Person>;
  getPerformanceFramework?: Maybe<PerformanceFramework>;
  /** A query to get a specific performance improvement plan */
  getPerformanceImprovementPlan?: Maybe<PerformanceImprovementPlan>;
  /** A query to get a specific performance improvement plan status */
  getPerformanceImprovementPlanStatus: PerformanceImprovementPlanStatus;
  /** A query to get a specific performance management */
  getPerformanceManagement?: Maybe<PerformanceManagement>;
  /** A query to get a specific performance management status */
  getPerformanceManagementStatus: PerformanceManagementStatus;
  getPerformanceMetricGroup?: Maybe<PerformanceMetricGroup>;
  getPerformanceReportConfiguration?: Maybe<PerformanceReportConfiguration>;
  getPerformanceReportSection: PerformanceReportSectionInterface;
  /**
   * A query to get a specific permission group
   * @deprecated Use getPermissionGroupNullable instead to correctly handle not found cases
   */
  getPermissionGroup: PermissionGroup;
  /** A query to get a specific permission group. Returns null if not found */
  getPermissionGroupNullable?: Maybe<PermissionGroup>;
  /**
   * A query to get a group of specific permission groups. Note: These permission
   * groups are NOT guaranteed to be in the order of the ids provided
   */
  getPermissionGroups: Array<PermissionGroup>;
  /** A query to get a specific person */
  getPerson?: Maybe<Person>;
  getPersonByPersonSearchObject?: Maybe<Person>;
  getPersonDetails: GetPersonDetailsResponse;
  /** A query to get a specific address */
  getPhoneNumber?: Maybe<PhoneNumber>;
  getPlan?: Maybe<Plan>;
  getPositionTitle?: Maybe<PositionTitle>;
  getPulse?: Maybe<Pulse>;
  getPulseRecurrence?: Maybe<PulseRecurrence>;
  /** A query to get a specific qualification attachment requirement type */
  getQualificationAttachmentRequirementTypeV2?: Maybe<QualificationAttachmentRequirementType>;
  /** Query the qualification compliance overview stats */
  getQualificationComplianceOverviewStats?: Maybe<QualificationComplianceOverviewStatsV2>;
  /** A query to get a specific qualification expiry type */
  getQualificationExpiryTypeV2?: Maybe<QualificationExpiryType>;
  /** A query to get a specific qualification instance */
  getQualificationInstance?: Maybe<QualificationInstanceV2>;
  /** A query to get a specific qualification registration number visibility type */
  getQualificationRegistrationNumberVisibilityTypeV2?: Maybe<QualificationRegistrationNumberVisibilityTypeV2>;
  /** Query the Standalone Compliance Qualification Statistics */
  getQualificationStandaloneComplianceForQualificationTemplateStats?: Maybe<CompositeStandaloneQualificationForQualificationTemplateStats>;
  /** Query the Qualification Standalone Compliance Overview Statistics */
  getQualificationStandaloneComplianceOverviewStats?: Maybe<QualificationComplianceOverviewStatsV2>;
  /** A query to get a specific qualification status */
  getQualificationStatusesV2?: Maybe<QualificationStatuses>;
  /** A query to get a specific qualification template */
  getQualificationTemplate?: Maybe<QualificationTemplate>;
  /** A query to get a specific qualification type */
  getQualificationTypeV2?: Maybe<QualificationTypeV2>;
  /** A query to get a specific recruitment source */
  getRecruitmentSource: RecruitmentSource;
  getRepresentativeType?: Maybe<RepresentativeType>;
  getScheduledForm?: Maybe<ScheduledForm>;
  /** A query to get a specific self-service action */
  getSelfServiceAction?: Maybe<SelfServiceAction>;
  /** query to get a skill */
  getSkill: Skill;
  /**
   * query to get a skill category
   * @deprecated rename to skill discipline
   */
  getSkillCategory: SkillDiscipline;
  /** query to get a skill discipline */
  getSkillDiscipline: SkillDiscipline;
  getSuperAPIEmployeeOnboardingSession?: Maybe<SuperAPIEmployeeOnboardingSession>;
  /** Create and return the embed url for a superAPI employer onboarding session by business entity ID */
  getSuperApiEmbedUrlForBusinessEntity: Scalars['String'];
  /** Create and return the embed url for a superAPI employee onboarding session by our onboarding session ID */
  getSuperApiEmbedUrlForOnboardingSession: Scalars['String'];
  /** Create and return the embed url for a superAPI employee update bank/tax/super session by job ID and update detail type */
  getSuperApiEmbedUrlForPayrollDetailUpdate: Scalars['String'];
  getSystemCustomFieldCategoryForModel?: Maybe<CustomFieldCategory>;
  /** Retrieve a single template by ID */
  getTemplate?: Maybe<Template>;
  /** Returns configuration for the overall tenant, with fallbacks to the public values. */
  getTenantConfig: TenantConfiguration;
  /**
   * A query to get the headcounts for each tenant and categorize them based on
   * work type roles. The query can only be run on master_intellihr tenant.
   */
  getTenantHeadcounts: PaginatedTenantHeadcounts;
  /** A query to get a specific training */
  getTraining: Training;
  /** A query to get a specific training provider */
  getTrainingProvider: TrainingProvider;
  /** A query to get a specific training type */
  getTrainingType: TrainingType;
  getUndrawBanner?: Maybe<UndrawBanner>;
  getUpdateJobDetailsFromSearchObjects: GetUpdateJobDetailsFromSearchObjectsResponse;
  /** A query to get a specific user */
  getUser?: Maybe<User>;
  /**
   * Returns configuration for the current user, with fallbacks to tenant/public
   * values if a user doesn't have a value specified.
   */
  getUserConfig: UserConfiguration;
  /** A query to get all webhook events */
  getWebhookEvent?: Maybe<WebhookEvent>;
  /** A query to get a specific work class */
  getWorkClass: WorkClass;
  /**
   * A query to get a group of specific work classes. Note: These work classes are
   * NOT guaranteed to be in the order of the ids provided
   */
  getWorkClasses: Array<WorkClass>;
  /** A query to get a specific work right */
  getWorkRight: WorkRight;
  /** A query to get a specific work type */
  getWorkType: WorkType;
  /**
   * A query to get a group of specific work types. Note: These work types are NOT
   * guaranteed to be in the order of the ids provided
   */
  getWorkTypes: Array<WorkType>;
  /** A query to get a specific workflow */
  getWorkflow?: Maybe<Workflow>;
  /** A query to get a specific workflow event */
  getWorkflowEvent?: Maybe<WorkflowEvent>;
  /** A query to get a specific workflow form design */
  getWorkflowFormDesign?: Maybe<WorkflowFormDesign>;
  /** A query to get the count of global notification configurations. */
  globalNotificationConfigurationsCount: Scalars['Int'];
  /** A query to get the limits of global notification configurations. */
  globalNotificationConfigurationsLimit: Scalars['Int'];
  goal?: Maybe<GoalQuery>;
  /**
   * Return the Goals for a given Person that are aligned to a given Organisation
   * Objective (including those nested below other Goals)
   */
  goalsAlignedToOrgObjective?: Maybe<Array<Goal>>;
  goalsCascadedToJob?: Maybe<Array<CascadedGoal>>;
  /** A query to tell if a user has unread notifications. Can only check this for your own user account. */
  hasUnreadNotifications: Scalars['Boolean'];
  /** Integrations domain contains queries relating to the integrations with third-party resources */
  integrations?: Maybe<IntegrationsQuery>;
  /**
   * Returns logos for display in the application.
   * Depending upon integrations or other factors, these logos may dynamically change.
   */
  intelliHRLogos: IntelliHRLogos;
  /** Query for dry testing a job update */
  jobDryRunUpdate: JobUpdateResponse;
  /** Query for dry testing the current pending update of a job, if any */
  jobPendingUpdateDryRun: JobUpdateResponse;
  /** A query to get a specific address */
  listAllAccessKeys: Array<AccessKey>;
  /** query to get a list of address types */
  listAllAddressTypes: Array<AddressType>;
  /** A query to list all the permission-group-type analytics permissions */
  listAllAnalyticsPermissionsPermissionGroupType: Array<Maybe<AnalyticsPermissionPermissionGroupType>>;
  /** A query to list all the user-type analytics permissions */
  listAllAnalyticsPermissionsUserType: Array<Maybe<AnalyticsPermissionUserType>>;
  /** query to get a list of anonymous form designs */
  listAllAnonymousFormDesigns: Array<AnonymousFormDesign>;
  /** query to get a list of qualification templates that a person doesn't have a Qualification Instance of */
  listAllAvailableQualificationTemplates: Array<QualificationTemplate>;
  /** List all bulk data imports that have been set up in the system */
  listAllBulkDataImports: Array<BulkDataImport>;
  /** Get a list of all business entities in the system */
  listAllBusinessEntities: Array<BusinessEntity>;
  listAllBusinessPerformanceConfiguration: Array<BusinessPerformanceConfigurationInterface>;
  listAllBusinessPerformanceMetrics: Array<BusinessPerformanceMetric>;
  /** Get a list of all business units in the system */
  listAllBusinessUnits: Array<BusinessUnit>;
  /**
   * List all of the Composite Qualifications for a given Qualification Template
   * @deprecated This query is slow. Please use listAllCompositeQualificationsForQualificationTemplateV2
   */
  listAllCompositeQualificationsForQualificationTemplate: Array<Maybe<QualificationCompositeCompliance>>;
  /** List all of the Composite Qualifications for a given Qualification Template */
  listAllCompositeQualificationsForQualificationTemplateV2: Array<Maybe<QualificationCompositeComplianceV2>>;
  /** List all contract issue batches */
  listAllContractIssueBatches: Array<ContractIssueBatch>;
  /** query to get a list of contract signer types */
  listAllContractTemplateSignerTypes: Array<ContractTemplateSignerType>;
  /** query to get a list of contract templates */
  listAllContractTemplates: Array<ContractTemplate>;
  /** query to get a list of contract templates version 2 */
  listAllContractTemplatesV2: Array<Maybe<ContractTemplateV2>>;
  /** @deprecated This query field is not performing well. Please use listPaginatedContracts */
  listAllContracts: Array<Contract>;
  /** query to get a list of countries */
  listAllCountries: Array<Country>;
  /** A list of all currencies */
  listAllCurrencies: Array<Currency>;
  listAllCustomFieldAnalyticsCategories: Array<CustomFieldAnalyticsCategory>;
  listAllCustomFieldCategories: Array<CustomFieldCategory>;
  /** A query to get a list of custom field definition */
  listAllCustomFieldDefinitions: Array<CustomFieldDefinition>;
  /**
   * A query to get a list of custom field definition.
   * This is sepatate from the existing listAllCustomFieldDefinition query
   * due to the additional sorting requirements
   */
  listAllCustomFieldDefinitionsForOnboardingTemplate: Array<CustomFieldDefinition>;
  /** will list all if super admin otherwise will only list enabled ones */
  listAllCustomFieldTemplateCategories: Array<CustomFieldTemplateCategory>;
  listAllCustomFieldTemplates: Array<CustomFieldTemplate>;
  /** query to get a list of default remuneration components */
  listAllDefaultRemunerationComponents: Array<DefaultRemunerationComponent>;
  /** query to get a list of diary note categories */
  listAllDiaryNoteCategories: Array<DiaryNoteCategory>;
  /** query to get a list of diary note categories */
  listAllDiaryNoteTypes: Array<DiaryNoteType>;
  /** query to get a list of diary notes */
  listAllDiaryNotes: Array<DiaryNote>;
  listAllDocumentFolders: Array<DocumentFolder>;
  /** Query to get a list of all employment conditions */
  listAllEmploymentConditions: Array<EmploymentCondition>;
  listAllEstablishments: Array<Establishment>;
  /** A query to get all external event logs */
  listAllExternalEventLogs?: Maybe<Array<Maybe<ExternalEventLog>>>;
  /** A query to get all external webhooks */
  listAllExternalWebhooks?: Maybe<Array<Maybe<ExternalWebhook>>>;
  /** query to get a list of form design categories */
  listAllFormDesignCategories: Array<FormDesignCategory>;
  listAllGenders: Array<Gender>;
  listAllGoalApprovalStatuses: Array<GoalApprovalStatus>;
  listAllGoalCategories: Array<GoalCategory>;
  listAllGoalMeasurementTypes: Array<GoalMeasurementType>;
  listAllGoalMeasurements: Array<GoalMeasurement>;
  listAllGoalProgressStatuses: Array<GoalProgressStatus>;
  listAllGoalStatuses: Array<GoalStatus>;
  listAllGoalTemplateMeasurements: Array<GoalTemplateMeasurement>;
  listAllGoalTemplates: Array<GoalTemplate>;
  listAllGoals: Array<Goal>;
  /** query to get the Group Composite overview for a given Job Requirement Group */
  listAllGroupCompositeQualificationsForJobRequirementGroup: Array<Maybe<GroupCompositeQualificationStatsV2>>;
  listAllGroupQualificationComplianceOverviews: Array<Maybe<GroupComplianceOverview>>;
  /** Get a list of all hourly rate types in the system */
  listAllHourlyRateTypes: Array<HourlyRateType>;
  /** Get a list of all integration mappings in the system */
  listAllIntegrationMappings: Array<IntegrationMapping>;
  /** A query to get all enabled integrations */
  listAllIntegrations?: Maybe<Array<Maybe<Integration>>>;
  listAllJobChangeReasons: Array<JobChangeReason>;
  /** Query to get a list of all pending job updates */
  listAllJobPendingUpdates: Array<JobPendingUpdate>;
  /** query to get a list of job requirement groups */
  listAllJobRequirementGroups: Array<JobRequirementGroup>;
  /** query to get a list of job requirements */
  listAllJobRequirements: Array<JobRequirement>;
  /**
   * A query to list all jobs in the system
   * @deprecated This is horribly slow; please use listPaginatedJobs instead
   */
  listAllJobs: Array<Job>;
  /** Query to get a list of all leaveTypes */
  listAllLeaveTypes: Array<LeaveType>;
  /** Get a list of all leave records in the system where the leaveType is not null */
  listAllLeaves: Array<Leave>;
  /** Get a list of all locations in the system */
  listAllLocations: Array<Location>;
  listAllModules: Array<Module>;
  /** A query to list all notification event types. */
  listAllNotificationEventTypes?: Maybe<Array<NotificationEventType>>;
  listAllNotificationMethods: Array<NotificationMethod>;
  /**
   * We provide all variants of the people.onboarding_session.create and people.onboarding_session.update permissions
   * since we can't use a hierarchical check here as there's no person id to check against
   */
  listAllOnboardingTemplates: Array<OnboardingTemplate>;
  listAllOrgObjectives: Array<OrganisationObjective>;
  /** Query to get a list of all pay cycles */
  listAllPayCycles: Array<PayCycle>;
  /** Query to get a list of all payGrades */
  listAllPayGrades: Array<PayGrade>;
  /** Query to get a list of all payGrades */
  listAllPaySteps: Array<PayStep>;
  listAllPerformanceFrameworks: Array<PerformanceFramework>;
  /** query to get a list of performance improvement plan statuses */
  listAllPerformanceImprovementPlanStatuses: Array<PerformanceImprovementPlanStatus>;
  /** query to get a list of performance improvement plans */
  listAllPerformanceImprovementPlans: Array<PerformanceImprovementPlan>;
  /** query to get a list of performance management statuses */
  listAllPerformanceManagementStatuses: Array<PerformanceManagementStatus>;
  /** query to get a list of performance managements */
  listAllPerformanceManagements: Array<PerformanceManagement>;
  listAllPerformanceMetricGroups?: Maybe<Array<PerformanceMetricGroup>>;
  /** A query to list all permission groups */
  listAllPermissionGroups: Array<PermissionGroup>;
  listAllPlans: Array<Plan>;
  listAllPulseFilterTypes: Array<PulseFilterType>;
  listAllPulseFilters: Array<PulseFilter>;
  listAllPulseRecurrences: Array<PulseRecurrence>;
  listAllPulses: Array<Pulse>;
  /** query to get a list of qualification attachment requirement types */
  listAllQualificationAttachmentRequirementTypesV2: Array<QualificationAttachmentRequirementType>;
  /** query to get a list of qualification expiry types */
  listAllQualificationExpiryTypesV2: Array<QualificationExpiryType>;
  /** query to get a list of qualification instances */
  listAllQualificationInstances: Array<QualificationInstanceV2>;
  /** query to get a list of qualification registration number visibility types */
  listAllQualificationRegistrationNumberVisibilityTypesV2: Array<QualificationRegistrationNumberVisibilityTypeV2>;
  /** query to get a list of qualification statuses */
  listAllQualificationStatusesV2: Array<QualificationStatuses>;
  /** query to get a list of qualification templates */
  listAllQualificationTemplates: Array<QualificationTemplate>;
  /** query to get a list of qualification  types */
  listAllQualificationTypesV2: Array<QualificationTypeV2>;
  /** A list of all recruitment sources */
  listAllRecruitmentSources: Array<RecruitmentSource>;
  listAllRepresentativeTypes: Array<RepresentativeType>;
  /**
   * query to get a list of skill categories
   * @deprecated rename to skill discipline
   */
  listAllSkillCategories: Array<SkillDiscipline>;
  /** query to get a list of skill disciplines */
  listAllSkillDisciplines: Array<SkillDiscipline>;
  /** query to get a list of skill */
  listAllSkillLevels: Array<SkillLevel>;
  /** query to get a list of skill */
  listAllSkills: Array<Skill>;
  /** List all templates in the system. These are typically email or other HTML templates. */
  listAllTemplates: Array<Template>;
  listAllTitles: Array<Title>;
  /** query to get a list of training providers */
  listAllTrainingProviders: Array<TrainingProvider>;
  /** Query to get a list of all training statuses */
  listAllTrainingStatuses: Array<TrainingStatus>;
  /** query to get a list of training types */
  listAllTrainingTypes: Array<TrainingType>;
  /** query to get all trainings of given personId, otherwise all trainings people that user has permission to view */
  listAllTrainings: Array<Training>;
  /** A query to list all user analytics permissions */
  listAllUserAnalyticsPermissions: Array<Maybe<UserAnalyticsPermission>>;
  /** A query to list all users */
  listAllUsers: Array<Maybe<User>>;
  /** A query to get all webhooks events */
  listAllWebhookEvents?: Maybe<Array<Maybe<WebhookEvent>>>;
  /** Get a list of all work classes in the system */
  listAllWorkClasses: Array<WorkClass>;
  /** A query to get a list of work rights */
  listAllWorkRights: Array<WorkRight>;
  /** Get a list of all work types in the system */
  listAllWorkTypes: Array<WorkType>;
  /** A query to get a list of workflow events */
  listAllWorkflowEvents: Array<WorkflowEvent>;
  /** A query to get a list of workflow form designs */
  listAllWorkflowFormDesigns: Array<WorkflowFormDesign>;
  /** A query to get a list of workflows */
  listAllWorkflows: Array<Workflow>;
  /** Returns the full list of date formats that can be configured for the organisation and account settings */
  listAllowedDateFormats: Array<AllowedDateFormat>;
  /** Returns the full list of time formats that can be configured for the organisation and account settings */
  listAllowedTimeFormats: Array<AllowedTimeFormat>;
  /** Returns the full list of timezones that can be configured for the organisation and account settings */
  listAllowedTimezones: Array<AllowedTimezone>;
  listApps: AppList;
  /** A query to list all permission groups */
  listAssignablePermissionGroups: Array<PermissionGroup>;
  /** @deprecated Use `listPaginatedBulkUserCreationCandidates` instead. */
  listBulkUserCreationCandidates: Array<BulkUserCreationCandidate>;
  listDeletableDocumentFolders: Array<DocumentFolder>;
  /**
   * Return list of Calendar Events occuring in for a set month, filtering persons
   * attending the events via employee status and hierarchy scope
   */
  listEventCalendarForMonth: Array<EventCalendarEvent>;
  /** Return list of Calendar Event types */
  listEventCalendarTypes: Array<EventCalendarType>;
  listFormCompletedRecipients: Array<FormCompletedRecipient>;
  /** query to get a list of form respondent types */
  listFormDesigns: Array<FormDesign>;
  /** Query to get a list of form ignore reasons */
  listFormIgnoreReasons: Array<FormIgnoreReason>;
  /** A query to get a list of form library items */
  listFormLibraryItems?: Maybe<Array<FormLibraryItem>>;
  /** query to get a list of form respondent types */
  listFormRespondentTypes: Array<FormRespondentType>;
  /** query to get a list of form types */
  listFormTypes: Array<FormType>;
  /** Get a list of all integration event logs */
  listIntegrationLogs?: Maybe<Array<IntegrationLog>>;
  listJobApprovalOrders: Array<JobApprovalOrder>;
  listManageableDocumentFolders: Array<DocumentFolder>;
  /** query to get a list of my compliance overviews */
  listMyComplianceOverview: MyOverview;
  /** query to get my direct reports for the dashboard page */
  listMyDirectReportsForDashboard: ListMyDirectReportsForDashboardResponse;
  /** query to get a list of my team compliance overviews */
  listMyTeamComplianceOverview: MyOverview;
  listNavigationItems: NavigationList;
  listNavigationTopbarItems: Array<NavigationTopbarItem>;
  listNonAnonymousFormRespondentTypes: Array<FormRespondentType>;
  /** A query to list all supported actioning person include details */
  listNotificationSupportedActioningPersonIncludeDetails: Array<NotificationIncludeDetail>;
  /** A query to list all supported affected person include details */
  listNotificationSupportedAffectedPersonIncludeDetails: Array<NotificationIncludeDetail>;
  /**
   * A query to list notifications from an offset ID for the currently logged in user.
   *
   * This works by taking a limit and a ID and returning all notifications that are earlier
   * than that ID. Unlike similar pagination, this does not support counts or listing which page
   * you are on, as notifications generally are very large in number and are constantly generated.
   *
   * Warning: assumed user accounts do not return any notifications.
   */
  listOffsetNotifications: OffsetPaginatedNotifications;
  listOnboardingTemplateSectionTypes: Array<OnboardingTemplateSectionType>;
  /**
   * Query to get a paginated list of Composite Qualifications for a given Qualification Template
   * @deprecated This query is slow. Please use listPaginatedAllCompositeQualificationsForQualificationTemplateV2
   */
  listPaginatedAllCompositeQualificationsForQualificationTemplate: PaginatedQualificationCompositeCompliance;
  /** Query to get a paginated list of Composite Qualifications for a given Qualification Template */
  listPaginatedAllCompositeQualificationsForQualificationTemplateV2: PaginatedQualificationCompositeComplianceV2;
  /** Query to get a paginated list of all qualification compliance overviews */
  listPaginatedAllQualificationComplianceOverviews: QualificationComplianceOverviewsResponse;
  listPaginatedAuditLog: AuditLogListResponse;
  listPaginatedBulkUserCreationCandidates: PaginatedBulkUserCreationCandidateResponse;
  /** Query to get a paginated list of business entities */
  listPaginatedBusinessEntities: PaginatedBusinessEntities;
  /** Get a paginated list of business units in the system */
  listPaginatedBusinessUnits: PaginatedBusinessUnits;
  /** @deprecated Moved to V2 model structure */
  listPaginatedCompensationPlannerPlans: ListPaginatedCompensationPlannerPlansResponse;
  /** Get a paginated list of plans */
  listPaginatedCompensationPlannerPlansV2: ListCompensationPlannerPlansResponseV2;
  /** Get a paginated list of all Compensation Plans */
  listPaginatedCompensationPlans: PaginatedCompensationPlans;
  /** Get a paginated list of all Contracts */
  listPaginatedContracts: PaginatedContracts;
  /** paginated query to get a list of countries */
  listPaginatedCountries: PaginatedCountries;
  /** Paginated list of currencies */
  listPaginatedCurrencies: PaginatedCurrencies;
  /** A query to get a paginated list of custom field definition */
  listPaginatedCustomFieldDefinitions: PaginatedCustomFieldDefinitions;
  /** A query to get a paginated list of custom field template categories */
  listPaginatedCustomFieldTemplateCategories: PaginatedCustomFieldTemplateCategories;
  /** Query to get a paginated list of default remuneration components */
  listPaginatedDefaultRemunerationComponents: PaginatedDefaultRemunerationComponents;
  /** Query to get a paginated list of employment conditions */
  listPaginatedEmploymentConditions: PaginatedEmploymentConditions;
  listPaginatedEstablishments: PaginatedEstablishments;
  /** Get a paginated list of all external event logs */
  listPaginatedExternalEventLogs: PaginatedExternalEventLogs;
  /** Get a paginated list of all external webhooks */
  listPaginatedExternalWebhooks: PaginatedExternalWebhooks;
  /** Get a paginated list of all combined Form Designs and Anonymous Form Designs */
  listPaginatedFormDesigns?: Maybe<ListPaginatedFormDesignsResponse>;
  listPaginatedFormPreviews?: Maybe<PulseFormPreviewsResponse>;
  listPaginatedForms: PaginatedForms;
  /** A paginated query to list all the global notification configurations. */
  listPaginatedGlobalNotificationConfigurations: PaginatedGlobalNotificationConfigurations;
  /** query to get a paginated list of goal categories */
  listPaginatedGoalCategories: PaginatedGoalCategories;
  listPaginatedGroupCompositeQualificationsForJobRequirementGroup?: Maybe<PaginatedGroupCompositeQualificationsV2>;
  listPaginatedGroupQualificationComplianceOverviews?: Maybe<PaginatedGroupQualificationComplianceOverviewsV2>;
  /** Paginated list of hourly rate types in the system */
  listPaginatedHourlyRateTypes: PaginatedHourlyRateTypes;
  /** Get a paginated list of all integration event logs */
  listPaginatedIntegrationLogs: PaginatedIntegrationLogs;
  /** Get a paginated list of all integration mappings in the system */
  listPaginatedIntegrationMappings: PaginatedIntegrationMappings;
  listPaginatedJobChangeReasons: PaginatedJobChangeReason;
  /** query to get a paginated list of job requirement groups */
  listPaginatedJobRequirementGroups: PaginatedJobRequirementGroups;
  /** query to get a paginated list of job requirements */
  listPaginatedJobRequirements: PaginatedJobRequirements;
  /** Query to get a paginated list of job update requests by status */
  listPaginatedJobUpdateRequestsByStatus?: Maybe<ListJobUpdateRequestResponse>;
  /** Paginated query to get a list of jobs. */
  listPaginatedJobs: PaginatedJobs;
  /** Query to get a paginated list of leaveTypes */
  listPaginatedLeaveTypes: PaginatedLeaveTypes;
  /** Get a paginated list of locations in the system */
  listPaginatedLocations: PaginatedLocations;
  /** A query to get paginated message schedules */
  listPaginatedMessageSchedules: PaginatedMessageSchedules;
  listPaginatedNotes: PaginatedNotes;
  listPaginatedNotesNullable: PaginatedNotes;
  listPaginatedOnboardingTemplates?: Maybe<ListPaginatedOnboardingTemplateResponse>;
  /** Query to get a paginated list of pay cycles */
  listPaginatedPayCycles: PaginatedPayCycles;
  listPaginatedPayGradeAudit: PayGradeAuditListResponse;
  /** Query to get a paginated list of payGrades */
  listPaginatedPayGrades: PaginatedPayGrades;
  /** Paginated query to get a list of people */
  listPaginatedPeople: PaginatedPeople;
  /** Paginated query to list permission groups */
  listPaginatedPermissionGroups: PaginatedPermissionGroups;
  /** Paginated query to list permission groups */
  listPaginatedPermissions: ListPaginatedPermissionsResponse;
  listPaginatedPermissionsForPermissionGroup: PaginatedPermissionsForPermissionGroupResponse;
  /** Paginated list of position titles in the system */
  listPaginatedPositionTitles: PaginatedPositionTitles;
  listPaginatedPulseModelsForReviewToAddChildLocation?: Maybe<PulseModelsForReviewPulseLocationFiltersResponse>;
  listPaginatedPulseModelsForReviewToRemoveChildLocation?: Maybe<PulseModelsForReviewPulseLocationFiltersResponse>;
  listPaginatedPulses: PaginatedPulses;
  /** query to get a paginated list of qualification instance */
  listPaginatedQualificationInstances: PaginatedQualificationInstances;
  /**
   * query to get a paginated list of standalone compliance qualification for the qualification template
   * @deprecated This query is slow. Please use listPaginatedQualificationStandaloneComplianceForQualificationTemplateV2
   */
  listPaginatedQualificationStandaloneComplianceForQualificationTemplate: PaginatedCompositeStandaloneQualification;
  /** query to get a paginated list of standalone compliance qualification for the qualification template */
  listPaginatedQualificationStandaloneComplianceForQualificationTemplateV2?: Maybe<PaginatedCompositeStandaloneQualificationV2>;
  /** query to get a paginated list of qualification standalone compliance overviews */
  listPaginatedQualificationStandaloneComplianceOverview: QualificationStandaloneComplianceOverviewsResponse;
  /** query to get a paginated list of qualification statuses */
  listPaginatedQualificationStatuses: PaginatedQualificationStatuses;
  /** query to get a paginated list of qualification templates */
  listPaginatedQualificationTemplates: PaginatedQualificationTemplates;
  /** query to get a paginated list of qualification types */
  listPaginatedQualificationTypes: PaginatedQualificationTypes;
  listPaginatedQueuedPulses: PaginatedPulses;
  /** Paginated list of recruitment sources */
  listPaginatedRecruitmentSources: PaginatedRecruitmentSources;
  listPaginatedRepresentativeTypes: PaginatedRepresentativeTypes;
  listPaginatedScheduledForms: ListPaginatedScheduledFormsResponse;
  /** query to get a paginated list of skill disciplines */
  listPaginatedSkillDisciplines: PaginatedSkillDiscipline;
  /** Paginated query to get a list of skills */
  listPaginatedSkills: PaginatedSkills;
  /** query to get a paginated list of training providers */
  listPaginatedTrainingProviders: PaginatedTrainingProviders;
  /** query to get a paginated list of training types */
  listPaginatedTrainingTypes: PaginatedTrainingTypes;
  /**
   * query to get paginated trainings of given personId, otherwise paginated
   * trainings of people that user has permission to view
   */
  listPaginatedTrainings: PaginatedTrainings;
  listPaginatedUndrawBanners: PaginatedUndrawBanners;
  listPaginatedUpcomingPulseModels: ListPaginatedUpcomingPulseModelsResponse;
  /** Paginated query to get a list of users */
  listPaginatedUsers: PaginatedUsers;
  /** Paginated query to list users associated with a specific Permission Group */
  listPaginatedUsersForPermissionGroup: ListPaginatedUsersForPermissionGroupResponse;
  /** Get a paginated list of all webhook events */
  listPaginatedWebhookEvents: PaginatedWebhookEvents;
  /** Paginated list of work classes in the system */
  listPaginatedWorkClasses: PaginatedWorkClasses;
  /** Paginated list of work rights in the system */
  listPaginatedWorkRights: PaginatedWorkRights;
  /** Paginated list of work types in the system */
  listPaginatedWorkTypes: PaginatedWorkTypes;
  /** List all of the performance management records */
  listPerformanceManagements: Array<PerformanceManagement>;
  listPositionTitles: Array<PositionTitle>;
  listPulsesByCustomField?: Maybe<PulsesUsingCustomField>;
  /** query to get a list of self-service action types */
  listSelfServiceActionTypes: Array<SelfServiceActionType>;
  /** query to get a list of self-service actions */
  listSelfServiceActions: Array<SelfServiceAction>;
  listSelfServiceActionsForPerson: Array<SelfServiceAction>;
  listSettingsPaginatedPermissionGroup: PaginatedSettingsPaginatedPermissionGroup;
  /** Returns a list of current inbound credentials */
  listSftpInboundCredentials?: Maybe<Array<Maybe<SftpInboundCredential>>>;
  /** Returns a list of current outbound credentials */
  listSftpOutboundCredentials?: Maybe<Array<Maybe<SftpOutboundCredential>>>;
  /** The list of supported languages that can be used in the frontend */
  listSupportedLanguages: Array<SupportedLanguage>;
  /** A query to list all tenant DNS aliases */
  listTenantAliases: Array<TenantAlias>;
  /** Get a list of all training statistics */
  listTrainingStatistics?: Maybe<TrainingStatisticsResponse>;
  listTurnoverReasons: Array<TurnoverReason>;
  listViewableDocumentFolders: Array<DocumentFolder>;
  myFormCompliance: MyFormComplianceQuery;
  /** @deprecated Use myFormCompliance instead */
  myTaskCompliance: MyTaskComplianceQuery;
  navigation?: Maybe<NavigationQuery>;
  orgObjectiveAlignmentTree: Array<AlignmentNode>;
  payGradesHaveHourlyRates: Scalars['Boolean'];
  peopleDirectoryList: PeopleDirectoryListResponse;
  peopleJobSearch?: Maybe<PeopleJobSearchQuery>;
  /** Perform multiple custom field model auth check against target resources (usually a person) */
  performMultipleCustomFieldModelAuthCheck: AuthCheckPerformResponse;
  /** Perform multiple hierarchical auth check against target resources (usually a person) */
  performMultipleHierarchicalAuthCheck: AuthCheckPerformResponse;
  /** Perform a search across people in the system, grouped by business unit (for use in people dropdowns) */
  performPeopleDropdownSearch: PeopleDropdownSearchResponse;
  /** Perform a single custom field model auth check against target resources (usually a person) */
  performSingleCustomFieldModelAuthCheck: AuthCheckPerformResponse;
  /** Perform a single hierarchical auth check against target resources (usually a person) */
  performSingleHierarchicalAuthCheck: AuthCheckPerformResponse;
  personCanViewCompletedForm: Scalars['Boolean'];
  personProfile: PersonProfileQuery;
  remuneration: RemunerationQuery;
  resolveSignersForContractTemplate: Array<ResolvedContractSigner>;
  /** @deprecated use listSelfServiceActionsForPerson */
  selfServiceAction: SelfServiceActionQuery;
  shouldLinkPayGradesAndEmploymentConditions: Scalars['Boolean'];
  /**
   * Returns whether SSO is enabled for the tenant.
   * SSO is considered enabled if there are 1 or more enabled SSO IdPs configured.
   */
  ssoEnabled: Scalars['Boolean'];
  /** @deprecated Use formCompliance instead */
  taskCompliance: TaskComplianceQuery;
  user?: Maybe<UserQuery>;
  userHasCompensationPlannerAccess?: Maybe<Scalars['Boolean']>;
};


export type QueryauthSftpInboundCredentialArgs = {
  input: AuthSftpInboundCredentialInput;
};


export type QuerybulkDataImportGetSupportedDownloadArgs = {
  typeString?: InputMaybe<Scalars['String']>;
};


export type QuerybulkDataImportGetSupportedUploadArgs = {
  typeString?: InputMaybe<Scalars['String']>;
};


export type QuerygetAddressArgs = {
  id: Scalars['ID'];
};


export type QuerygetAddressTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetAnalyticsPermissionByPermissionGroupArgs = {
  id: Scalars['ID'];
};


export type QuerygetAnalyticsPermissionByUserArgs = {
  id: Scalars['ID'];
};


export type QuerygetAnonymousFormDesignArgs = {
  id: Scalars['ID'];
};


export type QuerygetAnonymousFormResponseAggregatedNumbersArgs = {
  anonymousPulseId: Scalars['ID'];
};


export type QuerygetBulkDataImportArgs = {
  id: Scalars['ID'];
};


export type QuerygetBulkDataImportActionArgs = {
  id: Scalars['ID'];
};


export type QuerygetBusinessEntitiesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetBusinessEntityArgs = {
  id: Scalars['ID'];
};


export type QuerygetBusinessPerformanceConfigurationArgs = {
  key: BusinessPerformanceConfigurationKey;
};


export type QuerygetBusinessPerformanceMetricArgs = {
  id: Scalars['ID'];
};


export type QuerygetBusinessUnitArgs = {
  id: Scalars['ID'];
};


export type QuerygetBusinessUnitsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type QuerygetCompensationPlannerBudgetArgs = {
  id: Scalars['ID'];
};


export type QuerygetCompensationPlannerBudgetGroupV2Args = {
  budgetId: Scalars['ID'];
  planId: Scalars['ID'];
};


export type QuerygetCompensationPlannerBudgetGroupsArgs = {
  budgeted?: InputMaybe<Scalars['Boolean']>;
  planId: Scalars['ID'];
};


export type QuerygetCompensationPlannerBudgetGroupsV2Args = {
  budgeted?: InputMaybe<Scalars['Boolean']>;
  planId: Scalars['ID'];
};


export type QuerygetCompensationPlannerPlanArgs = {
  id: Scalars['ID'];
};


export type QuerygetCompensationPlannerPlanV2Args = {
  id: Scalars['ID'];
};


export type QuerygetCompletedFormInstanceArgs = {
  id: Scalars['ID'];
};


export type QuerygetCompositeQualificationsForQualificationTemplateStatsArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  templateID: Scalars['ID'];
};


export type QuerygetContractArgs = {
  id: Scalars['ID'];
  trashed?: InputMaybe<Trashed>;
};


export type QuerygetContractIssueBatchArgs = {
  id: Scalars['ID'];
};


export type QuerygetContractIssueBatchItemArgs = {
  id: Scalars['ID'];
};


export type QuerygetContractPersonSignatureArgs = {
  id: Scalars['ID'];
  trashed?: InputMaybe<Trashed>;
};


export type QuerygetContractPersonSignatureForPersonArgs = {
  personId: Scalars['ID'];
};


export type QuerygetContractTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetContractTemplatePopulatedArgs = {
  input: GetContractTemplatePopulatedInput;
};


export type QuerygetContractTemplatePopulatedV2Args = {
  input: GetContractTemplatePopulatedInputV2;
};


export type QuerygetContractTemplateSignerTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetContractTemplateV2Args = {
  id: Scalars['ID'];
};


export type QuerygetContractsForPersonArgs = {
  personId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetCountryArgs = {
  id: Scalars['ID'];
};


export type QuerygetCreateJobDetailsFromSearchObjectsArgs = {
  input: GetCreateJobDetailsFromSearchObjectsInput;
};


export type QuerygetCreatePersonDetailsArgs = {
  input: GetCreatePersonDetailsInput;
};


export type QuerygetCurrencyArgs = {
  id: Scalars['ID'];
};


export type QuerygetCustomFieldCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetCustomFieldDefinitionArgs = {
  id: Scalars['ID'];
};


export type QuerygetCustomFieldTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetCustomFieldTemplateCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetDefaultRemunerationComponentArgs = {
  id: Scalars['ID'];
};


export type QuerygetDiaryNoteArgs = {
  id: Scalars['ID'];
};


export type QuerygetDiaryNoteCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetDiaryNoteTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetDocumentFolderArgs = {
  id: Scalars['ID'];
};


export type QuerygetEmailAddressArgs = {
  id: Scalars['ID'];
};


export type QuerygetEmailAddressUsageCountArgs = {
  email: Scalars['String'];
  ignoreIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerygetEmploymentConditionArgs = {
  id: Scalars['ID'];
};


export type QuerygetEndJobDetailsFromSearchObjectsArgs = {
  input: GetEndJobDetailsFromSearchObjectsInput;
};


export type QuerygetEstablishmentArgs = {
  id: Scalars['ID'];
};


export type QuerygetEstablishmentsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetExternalEventLogArgs = {
  id: Scalars['ID'];
};


export type QuerygetExternalWebhookArgs = {
  id: Scalars['ID'];
};


export type QuerygetFormArgs = {
  id: Scalars['ID'];
};


export type QuerygetFormDesignArgs = {
  id: Scalars['ID'];
};


export type QuerygetFormDesignCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetFormLibraryItemArgs = {
  id: Scalars['ID'];
};


export type QuerygetGlobalNotificationConfigurationArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalApprovalStatusArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalMeasurementArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalMeasurementTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalProgressStatusArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalStatusArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalTemplateMeasurementArgs = {
  id: Scalars['ID'];
};


export type QuerygetGoalsForPersonArgs = {
  isAligned?: InputMaybe<Scalars['Boolean']>;
  personId: Scalars['ID'];
  progressStatuses?: InputMaybe<Array<GoalProgressStatusType>>;
};


export type QuerygetGroupCompositeQualificationsForJobRequirementGroupStatsArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  jobRequirementGroupId: Scalars['ID'];
};


export type QuerygetGroupQualificationComplianceOverviewStatsArgs = {
  complianceStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetHiddenOnboardingSessionsArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetHourlyRateTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetIntegrationArgs = {
  id: Scalars['ID'];
};


export type QuerygetIntegrationConfigurationArgs = {
  id: Scalars['ID'];
};


export type QuerygetIntegrationMappingArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobChangeReasonArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobPendingUpdateArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobRequirementArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobRequirementGroupArgs = {
  id: Scalars['ID'];
};


export type QuerygetJobsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetLeaveDetailsArgs = {
  input: GetLeaveDetailsInput;
};


export type QuerygetLeaveTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetLocationArgs = {
  id: Scalars['ID'];
};


export type QuerygetLocationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetMedicalConditionArgs = {
  id: Scalars['ID'];
};


export type QuerygetMessageScheduleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerygetModuleArgs = {
  id: Scalars['ID'];
};


export type QuerygetNoteArgs = {
  id: Scalars['ID'];
};


export type QuerygetNotificationArgs = {
  id: Scalars['ID'];
};


export type QuerygetNotificationEventTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetNotificationEventTypesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetNotificationSupportedEventSettingsIncludeDetailArgs = {
  eventType: Scalars['ID'];
  settingsValue?: InputMaybe<GlobalNotificationConfigurationSettingValueInput>;
};


export type QuerygetNullableAddressTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableBusinessEntityArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableBusinessUnitArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableCountryArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableCurrencyArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableCustomFieldDefinitionArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableDefaultRemunerationComponentArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableEmploymentConditionArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableEstablishmentArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableLeaveTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableLocationArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullablePayCycleArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullablePayGradeArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableRecruitmentSourceArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableWorkClassArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableWorkRightArgs = {
  id: Scalars['ID'];
};


export type QuerygetNullableWorkTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetOnboardingFormInstanceByIdArgs = {
  onboardingFormId: Scalars['ID'];
};


export type QuerygetOnboardingQualificationInstanceArgs = {
  id: Scalars['ID'];
};


export type QuerygetOnboardingSessionArgs = {
  id: Scalars['ID'];
};


export type QuerygetOnboardingSessionsForPersonArgs = {
  personId: Scalars['ID'];
};


export type QuerygetOnboardingStatusForPersonArgs = {
  personId: Scalars['ID'];
};


export type QuerygetOnboardingTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetOrgObjectiveArgs = {
  id: Scalars['ID'];
};


export type QuerygetPaginatedOnboardingFormInstancesArgs = {
  paginationOptions: PaginationOptions;
  templateId: Scalars['ID'];
};


export type QuerygetPaginatedOnboardingSessionsArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  statusOption: OnboardingSessionStatusOption;
};


export type QuerygetPaginatedPeopleAndJobsWithoutOnboardingArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetPayCycleArgs = {
  id: Scalars['ID'];
};


export type QuerygetPayGradeArgs = {
  id: Scalars['ID'];
};


export type QuerygetPayGradesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetPayStepArgs = {
  id: Scalars['ID'];
};


export type QuerygetPeopleArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetPeopleByArgs = {
  businessUnits?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerygetPerformanceFrameworkArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceImprovementPlanArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceImprovementPlanStatusArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceManagementArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceManagementStatusArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceMetricGroupArgs = {
  id: Scalars['ID'];
};


export type QuerygetPerformanceReportConfigurationArgs = {
  frameworkId: Scalars['ID'];
  jobId: Scalars['ID'];
  viewType: PerformanceReportConfigurationViewType;
};


export type QuerygetPerformanceReportSectionArgs = {
  endDate: Scalars['DateTime'];
  frameworkId: Scalars['ID'];
  jobId: Scalars['ID'];
  sectionId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  viewType: PerformanceReportConfigurationViewType;
};


export type QuerygetPermissionGroupArgs = {
  id: Scalars['ID'];
};


export type QuerygetPermissionGroupNullableArgs = {
  id: Scalars['ID'];
};


export type QuerygetPermissionGroupsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetPersonArgs = {
  id: Scalars['ID'];
};


export type QuerygetPersonByPersonSearchObjectArgs = {
  personSearchObject: PersonSearchObjectInput;
};


export type QuerygetPersonDetailsArgs = {
  input: GetPersonDetailsInput;
};


export type QuerygetPhoneNumberArgs = {
  id: Scalars['ID'];
};


export type QuerygetPlanArgs = {
  id: Scalars['ID'];
};


export type QuerygetPositionTitleArgs = {
  id: Scalars['ID'];
};


export type QuerygetPulseArgs = {
  id: Scalars['ID'];
};


export type QuerygetPulseRecurrenceArgs = {
  id: Scalars['ID'];
};


export type QuerygetQualificationAttachmentRequirementTypeV2Args = {
  id: Scalars['ID'];
};


export type QuerygetQualificationComplianceOverviewStatsArgs = {
  complianceStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  qualificationTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetQualificationExpiryTypeV2Args = {
  id: Scalars['ID'];
};


export type QuerygetQualificationInstanceArgs = {
  id: Scalars['ID'];
};


export type QuerygetQualificationRegistrationNumberVisibilityTypeV2Args = {
  id: Scalars['ID'];
};


export type QuerygetQualificationStandaloneComplianceForQualificationTemplateStatsArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  templateID: Scalars['ID'];
};


export type QuerygetQualificationStandaloneComplianceOverviewStatsArgs = {
  complianceStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  qualificationTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerygetQualificationStatusesV2Args = {
  id: Scalars['ID'];
};


export type QuerygetQualificationTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetQualificationTypeV2Args = {
  id: Scalars['ID'];
};


export type QuerygetRecruitmentSourceArgs = {
  id: Scalars['ID'];
};


export type QuerygetRepresentativeTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetScheduledFormArgs = {
  id: Scalars['ID'];
};


export type QuerygetSelfServiceActionArgs = {
  id: Scalars['ID'];
};


export type QuerygetSkillArgs = {
  id: Scalars['ID'];
};


export type QuerygetSkillCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerygetSkillDisciplineArgs = {
  id: Scalars['ID'];
};


export type QuerygetSuperAPIEmployeeOnboardingSessionArgs = {
  id: Scalars['ID'];
};


export type QuerygetSuperApiEmbedUrlForBusinessEntityArgs = {
  businessEntityId: Scalars['ID'];
};


export type QuerygetSuperApiEmbedUrlForOnboardingSessionArgs = {
  onboardingSessionId: Scalars['ID'];
};


export type QuerygetSuperApiEmbedUrlForPayrollDetailUpdateArgs = {
  jobId: Scalars['ID'];
  type: PayrollDetailType;
};


export type QuerygetSystemCustomFieldCategoryForModelArgs = {
  modelType: CustomFieldDefinitionModelType;
};


export type QuerygetTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerygetTenantHeadcountsArgs = {
  afterTenant?: InputMaybe<Scalars['String']>;
  asAt?: InputMaybe<Scalars['DateTime']>;
  includeBusinessEntityTenants?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerygetTrainingArgs = {
  id: Scalars['ID'];
};


export type QuerygetTrainingProviderArgs = {
  id: Scalars['ID'];
};


export type QuerygetTrainingTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetUndrawBannerArgs = {
  id: Scalars['ID'];
};


export type QuerygetUpdateJobDetailsFromSearchObjectsArgs = {
  input: GetUpdateJobDetailsFromSearchObjectsInput;
};


export type QuerygetUserArgs = {
  id: Scalars['ID'];
};


export type QuerygetWebhookEventArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkClassArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkClassesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetWorkRightArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkTypeArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkTypesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerygetWorkflowArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkflowEventArgs = {
  id: Scalars['ID'];
};


export type QuerygetWorkflowFormDesignArgs = {
  id: Scalars['ID'];
};


export type QuerygoalsAlignedToOrgObjectiveArgs = {
  orgObjectiveId: Scalars['ID'];
  personId: Scalars['ID'];
};


export type QuerygoalsCascadedToJobArgs = {
  jobId: Scalars['ID'];
  progressStatusIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryhasUnreadNotificationsArgs = {
  id: Scalars['ID'];
};


export type QueryjobDryRunUpdateArgs = {
  input: JobUpdateInput;
};


export type QueryjobPendingUpdateDryRunArgs = {
  jobId: Scalars['ID'];
};


export type QuerylistAllAddressTypesArgs = {
  filters?: InputMaybe<AddressTypeFilters>;
};


export type QuerylistAllAvailableQualificationTemplatesArgs = {
  personId: Scalars['ID'];
};


export type QuerylistAllBusinessEntitiesArgs = {
  filters?: InputMaybe<BusinessEntityFilters>;
  legalName?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllBusinessUnitsArgs = {
  filters?: InputMaybe<BusinessUnitFilters>;
  identifier?: InputMaybe<Array<Scalars['String']>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllCompositeQualificationsForQualificationTemplateArgs = {
  templateID: Scalars['ID'];
};


export type QuerylistAllCompositeQualificationsForQualificationTemplateV2Args = {
  templateID: Scalars['ID'];
};


export type QuerylistAllContractTemplatesArgs = {
  statuses?: InputMaybe<Array<ContractTemplateStatusId>>;
};


export type QuerylistAllContractTemplatesV2Args = {
  statuses?: InputMaybe<Array<ContractTemplateStatusId>>;
};


export type QuerylistAllContractsArgs = {
  orderBy?: InputMaybe<Array<ListAllContractsOrderByOrderByClause>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ContractStatusEnum>;
};


export type QuerylistAllCountriesArgs = {
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
};


export type QuerylistAllCurrenciesArgs = {
  code?: InputMaybe<Array<Scalars['String']>>;
  filters?: InputMaybe<CurrencyFilters>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllCustomFieldCategoriesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type QuerylistAllCustomFieldDefinitionsArgs = {
  customFieldCategory?: InputMaybe<Array<Scalars['ID']>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type QuerylistAllCustomFieldDefinitionsForOnboardingTemplateArgs = {
  customFieldCategory?: InputMaybe<Array<Scalars['ID']>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type QuerylistAllCustomFieldTemplateCategoriesArgs = {
  isInUse?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type QuerylistAllCustomFieldTemplatesArgs = {
  isInCategory?: InputMaybe<Scalars['Boolean']>;
  isInUse?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
};


export type QuerylistAllDefaultRemunerationComponentsArgs = {
  filters?: InputMaybe<DefaultRemunerationComponentFilters>;
};


export type QuerylistAllDiaryNoteCategoriesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllDiaryNotesArgs = {
  typeIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistAllEmploymentConditionsArgs = {
  filters?: InputMaybe<EmploymentConditionFilters>;
};


export type QuerylistAllEstablishmentsArgs = {
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  supervisorEstablishmentId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistAllExternalEventLogsArgs = {
  externalWebhookId?: InputMaybe<Array<Scalars['ID']>>;
  webhookEventId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistAllExternalWebhooksArgs = {
  webhookEventId?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllFormDesignCategoriesArgs = {
  filters?: InputMaybe<FormDesignCategoryFilters>;
};


export type QuerylistAllGendersArgs = {
  filters?: InputMaybe<GenderFilters>;
};


export type QuerylistAllGoalCategoriesArgs = {
  filters?: InputMaybe<GoalCategoryFilters>;
};


export type QuerylistAllGoalMeasurementTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllGoalProgressStatusesArgs = {
  filters?: InputMaybe<GoalProgressStatusFilters>;
};


export type QuerylistAllGoalTemplatesArgs = {
  filters?: InputMaybe<GoalTemplateFilters>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllGroupCompositeQualificationsForJobRequirementGroupArgs = {
  jobRequirementGroupId: Scalars['ID'];
};


export type QuerylistAllIntegrationMappingsArgs = {
  integrationConfigurationId?: InputMaybe<Scalars['ID']>;
  relationType?: InputMaybe<Scalars['Int']>;
  tenantRelatedId?: InputMaybe<Scalars['ID']>;
  thirdPartyClientId?: InputMaybe<Scalars['String']>;
};


export type QuerylistAllIntegrationsArgs = {
  name?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllJobChangeReasonsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllJobsArgs = {
  autoIncrementIntellihrId?: InputMaybe<Array<Scalars['String']>>;
  employeeNumber?: InputMaybe<Array<Scalars['String']>>;
  employmentCondition?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<JobFilters>;
  jobEndedWithin?: InputMaybe<Scalars['Int']>;
  jobEndingWithin?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Array<Scalars['ID']>>;
  jobName?: InputMaybe<Array<Scalars['String']>>;
  jobStatus?: InputMaybe<Array<JobStatus>>;
  jobWorkType?: InputMaybe<Array<Scalars['String']>>;
  orderBy?: InputMaybe<Array<ListAllJobsOrderByOrderByClause>>;
  personId?: InputMaybe<Array<Scalars['ID']>>;
  personName?: InputMaybe<Array<Scalars['String']>>;
  primaryEmail?: InputMaybe<Array<Scalars['String']>>;
  updatedWithin?: InputMaybe<Scalars['Int']>;
};


export type QuerylistAllLeaveTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllLeavesArgs = {
  filters?: InputMaybe<LeaveFilters>;
};


export type QuerylistAllLocationsArgs = {
  filters?: InputMaybe<LocationFilters>;
};


export type QuerylistAllOnboardingTemplatesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllOrgObjectivesArgs = {
  status?: InputMaybe<OrgObjectiveStatus>;
  statuses?: InputMaybe<Array<OrgObjectiveStatus>>;
};


export type QuerylistAllPayGradesArgs = {
  filters?: InputMaybe<PayGradeFilters>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistAllPayStepsArgs = {
  payGradeIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllPermissionGroupsArgs = {
  filters?: InputMaybe<PermissionGroupFilters>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  isSystem?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllPulseFiltersArgs = {
  pulseModelId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistAllQualificationInstancesArgs = {
  employeeNumber?: InputMaybe<Array<Scalars['String']>>;
  personId?: InputMaybe<Array<Scalars['ID']>>;
  qualificationTemplateId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistAllRecruitmentSourcesArgs = {
  filters?: InputMaybe<RecruitmentSourceFilters>;
};


export type QuerylistAllRepresentativeTypesArgs = {
  model?: InputMaybe<Array<RepresentativeModel>>;
};


export type QuerylistAllSkillsArgs = {
  filters?: InputMaybe<SkillFilter>;
};


export type QuerylistAllTemplatesArgs = {
  masterIntellihrTemplateTypeId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistAllTitlesArgs = {
  filters?: InputMaybe<TitleFilters>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllTrainingProvidersArgs = {
  filters?: InputMaybe<TrainingProviderFilters>;
};


export type QuerylistAllTrainingTypesArgs = {
  filters?: InputMaybe<TrainingTypeFilters>;
};


export type QuerylistAllTrainingsArgs = {
  completedAtEndDate?: InputMaybe<Scalars['DateTime']>;
  completedAtStartDate?: InputMaybe<Scalars['DateTime']>;
  employeeNumber?: InputMaybe<Scalars['String']>;
  jobId?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<ListAllTrainingsOrderByOrderByClause>>;
  personId?: InputMaybe<Scalars['ID']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  providerName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  trainingStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  typeIds?: InputMaybe<Array<Scalars['ID']>>;
  typeName?: InputMaybe<Scalars['String']>;
};


export type QuerylistAllUsersArgs = {
  filters?: InputMaybe<UserFilters>;
};


export type QuerylistAllWebhookEventsArgs = {
  externalEventName?: InputMaybe<Array<Scalars['String']>>;
  internalEventName?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistAllWorkClassesArgs = {
  filters?: InputMaybe<WorkClassFilters>;
};


export type QuerylistAllWorkRightsArgs = {
  filters?: InputMaybe<WorkRightFilters>;
};


export type QuerylistAllWorkTypesArgs = {
  filters?: InputMaybe<WorkTypeFilters>;
  hasEnabledChildren?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistAllowedTimezonesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type QuerylistDeletableDocumentFoldersArgs = {
  personId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistEventCalendarForMonthArgs = {
  input: ListEventCalendarForMonthInput;
};


export type QuerylistFormCompletedRecipientsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistFormDesignsArgs = {
  filters?: InputMaybe<FormDesignFilters>;
};


export type QuerylistFormIgnoreReasonsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistFormLibraryItemsArgs = {
  fieldTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistFormRespondentTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistFormTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistIntegrationLogsArgs = {
  events?: InputMaybe<Array<Scalars['String']>>;
  integrationConfigurationId?: InputMaybe<Array<Scalars['ID']>>;
  integrationId?: InputMaybe<Array<Scalars['ID']>>;
  occurredAt?: InputMaybe<OccurredAtDateRange>;
  orderBy?: InputMaybe<Array<ListIntegrationLogsOrderByOrderByClause>>;
  types?: InputMaybe<Array<IntegrationLogType>>;
};


export type QuerylistManageableDocumentFoldersArgs = {
  personId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistMyDirectReportsForDashboardArgs = {
  timezone?: InputMaybe<Scalars['Timezone']>;
};


export type QuerylistNonAnonymousFormRespondentTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistOffsetNotificationsArgs = {
  afterId?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
};


export type QuerylistPaginatedAllCompositeQualificationsForQualificationTemplateArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions: PaginationOptions;
  templateID: Scalars['ID'];
};


export type QuerylistPaginatedAllCompositeQualificationsForQualificationTemplateV2Args = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  templateID: Scalars['ID'];
};


export type QuerylistPaginatedAllQualificationComplianceOverviewsArgs = {
  complianceStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  qualificationTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedAuditLogArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventType?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<AuditLogSort>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QuerylistPaginatedBulkUserCreationCandidatesArgs = {
  canCreate: Scalars['Boolean'];
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedBusinessEntitiesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  legalName?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedBusinessUnitsArgs = {
  filters?: InputMaybe<BusinessUnitFilters>;
  identifier?: InputMaybe<Array<Scalars['String']>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedCompensationPlannerPlansArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<ListCompensationPlannerPlansOrderOptions>>;
};


export type QuerylistPaginatedCompensationPlannerPlansV2Args = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<ListCompensationPlannerPlansOrderOptions>>;
};


export type QuerylistPaginatedCompensationPlansArgs = {
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<CompensationPlanSortDirection>>;
};


export type QuerylistPaginatedContractsArgs = {
  orderBy?: InputMaybe<Array<ListPaginatedContractsOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ContractStatusEnum>;
};


export type QuerylistPaginatedCountriesArgs = {
  orderBy?: InputMaybe<Array<CountryOrderBy>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedCurrenciesArgs = {
  code?: InputMaybe<Array<Scalars['String']>>;
  filters?: InputMaybe<CurrencyFilters>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedCustomFieldDefinitionsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedCustomFieldTemplateCategoriesArgs = {
  isInUse?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Array<CustomFieldDefinitionModelType>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedDefaultRemunerationComponentsArgs = {
  filters?: InputMaybe<DefaultRemunerationComponentFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedEmploymentConditionsArgs = {
  filters?: InputMaybe<EmploymentConditionFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedEstablishmentsArgs = {
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  supervisorEstablishmentId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedExternalEventLogsArgs = {
  externalWebhookId?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions: PaginationOptions;
  webhookEventId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedExternalWebhooksArgs = {
  paginationOptions: PaginationOptions;
  webhookEventId?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistPaginatedFormDesignsArgs = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  orderBy: Array<ListPaginatedFormDesignsSortDirection>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  respondentTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedFormPreviewsArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  pulseId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedFormsArgs = {
  paginationOptions: PaginationOptions;
  pulseId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  trashed?: InputMaybe<Trashed>;
};


export type QuerylistPaginatedGlobalNotificationConfigurationsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationEventTypeId?: InputMaybe<Array<Scalars['ID']>>;
  orderBy?: InputMaybe<Array<GlobalNotificationConfigurationOrderBy>>;
  paginationOptions: PaginationOptions;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedGoalCategoriesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedGroupCompositeQualificationsForJobRequirementGroupArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  jobRequirementGroupId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
};


export type QuerylistPaginatedGroupQualificationComplianceOverviewsArgs = {
  complianceStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedHourlyRateTypesArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedIntegrationLogsArgs = {
  events?: InputMaybe<Array<Scalars['String']>>;
  integrationConfigurationId?: InputMaybe<Array<Scalars['ID']>>;
  integrationId?: InputMaybe<Array<Scalars['ID']>>;
  occurredAt?: InputMaybe<OccurredAtDateRange>;
  orderBy?: InputMaybe<Array<ListPaginatedIntegrationLogsOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  personId?: InputMaybe<Scalars['ID']>;
  types?: InputMaybe<Array<IntegrationLogType>>;
};


export type QuerylistPaginatedIntegrationMappingsArgs = {
  integrationConfigurationId?: InputMaybe<Scalars['ID']>;
  paginationOptions: PaginationOptions;
  relationType?: InputMaybe<Scalars['Int']>;
  tenantRelatedId?: InputMaybe<Scalars['ID']>;
  thirdPartyClientId?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedJobChangeReasonsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedJobRequirementGroupsArgs = {
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedJobRequirementsArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedJobUpdateRequestsByStatusArgs = {
  jobUpdateRequestStatus: JobUpdateRequestStatus;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<JobUpdateRequestSortDirection>>;
};


export type QuerylistPaginatedJobsArgs = {
  autoIncrementIntellihrId?: InputMaybe<Array<Scalars['String']>>;
  employeeNumber?: InputMaybe<Array<Scalars['String']>>;
  employmentCondition?: InputMaybe<Array<Scalars['ID']>>;
  filters?: InputMaybe<JobFilters>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  jobEndedWithin?: InputMaybe<Scalars['Int']>;
  jobEndingWithin?: InputMaybe<Scalars['Int']>;
  jobId?: InputMaybe<Array<Scalars['ID']>>;
  jobName?: InputMaybe<Array<Scalars['String']>>;
  jobNameAsAt?: InputMaybe<ListPaginatedJobsJobNameAsAt>;
  jobStatus?: InputMaybe<Array<JobStatus>>;
  jobWorkType?: InputMaybe<Array<Scalars['String']>>;
  orderBy?: InputMaybe<Array<ListPaginatedJobsOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  personId?: InputMaybe<Array<Scalars['ID']>>;
  personName?: InputMaybe<Array<Scalars['String']>>;
  primaryEmail?: InputMaybe<Array<Scalars['String']>>;
  updatedWithin?: InputMaybe<Scalars['Int']>;
};


export type QuerylistPaginatedLeaveTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedLocationsArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedMessageSchedulesArgs = {
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  showFailedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QuerylistPaginatedNotesArgs = {
  createdByLoggedInUser?: InputMaybe<Scalars['Boolean']>;
  orderByCreatedAtAscending?: InputMaybe<Scalars['Boolean']>;
  orderByUpdatedAtAscending?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  sharedWithPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<Scalars['String']>;
  subjectPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedNotesNullableArgs = {
  createdByLoggedInUser?: InputMaybe<Scalars['Boolean']>;
  orderByCreatedAtAscending?: InputMaybe<Scalars['Boolean']>;
  orderByUpdatedAtAscending?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  sharedWithPeopleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedOnboardingTemplatesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};


export type QuerylistPaginatedPayCyclesArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedPayGradeAuditArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<PayGradeAuditSort>>;
};


export type QuerylistPaginatedPayGradesArgs = {
  filters?: InputMaybe<PayGradeFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedPeopleArgs = {
  autoIncrementIntellihrId?: InputMaybe<Array<Scalars['String']>>;
  autoIncrementingIntellihrId?: InputMaybe<Array<Scalars['String']>>;
  employeeNumber?: InputMaybe<Array<Scalars['String']>>;
  isOnExtendedLeave?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Array<Scalars['String']>>;
  orderBy?: InputMaybe<Array<ListPaginatedPeopleOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  primaryEmail?: InputMaybe<Array<Scalars['String']>>;
  updatedWithin?: InputMaybe<Scalars['Int']>;
};


export type QuerylistPaginatedPermissionGroupsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  isSystem?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedPermissionsArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedPermissionsForPermissionGroupArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  permissionGroupId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedPositionTitlesArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  nameSearch?: InputMaybe<Scalars['String']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedPulseModelsForReviewToAddChildLocationArgs = {
  childLocationId: Scalars['ID'];
  newParentLocationId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  previousParentLocationId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistPaginatedPulseModelsForReviewToRemoveChildLocationArgs = {
  childLocationId: Scalars['ID'];
  newParentLocationId?: InputMaybe<Scalars['ID']>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  previousParentLocationId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistPaginatedPulsesArgs = {
  paginationOptions: PaginationOptions;
  pulseRecurrenceId?: InputMaybe<Scalars['ID']>;
};


export type QuerylistPaginatedQualificationInstancesArgs = {
  employeeNumber?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions: PaginationOptions;
  personId?: InputMaybe<Array<Scalars['ID']>>;
  qualificationTemplateId?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedQualificationStandaloneComplianceForQualificationTemplateArgs = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions: PaginationOptions;
  qualificationTemplateId: Scalars['ID'];
};


export type QuerylistPaginatedQualificationStandaloneComplianceForQualificationTemplateV2Args = {
  approvalStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  qualificationTemplateId: Scalars['ID'];
};


export type QuerylistPaginatedQualificationStandaloneComplianceOverviewArgs = {
  complianceStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  qualificationTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedQualificationStatusesArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedQualificationTemplatesArgs = {
  paginationOptions: PaginationOptions;
  qualificationTypeIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedQualificationTypesArgs = {
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedQueuedPulsesArgs = {
  orderBy?: InputMaybe<Array<ListPaginatedQueuedPulsesOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedRecruitmentSourcesArgs = {
  filters?: InputMaybe<RecruitmentSourceFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedRepresentativeTypesArgs = {
  model?: InputMaybe<Array<RepresentativeModel>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedScheduledFormsArgs = {
  orderBy?: InputMaybe<Array<ScheduledFormSortDirection>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedSkillDisciplinesArgs = {
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedSkillsArgs = {
  excludedSkillIds?: InputMaybe<Array<Scalars['ID']>>;
  isBusinessCritical?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
  search?: InputMaybe<Scalars['String']>;
  skillDisciplineIds?: InputMaybe<Array<Scalars['ID']>>;
  skillIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistPaginatedTrainingProvidersArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedTrainingTypesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedTrainingsArgs = {
  completedAtEndDate?: InputMaybe<Scalars['DateTime']>;
  completedAtStartDate?: InputMaybe<Scalars['DateTime']>;
  employeeNumber?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<ListPaginatedTrainingsOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  personId?: InputMaybe<Scalars['ID']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  providerName?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  trainingStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  typeIds?: InputMaybe<Array<Scalars['ID']>>;
  typeName?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedUndrawBannersArgs = {
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedUpcomingPulseModelsArgs = {
  orderBy?: InputMaybe<UpcomingPulseModelOrderBy>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedUsersArgs = {
  hasAcceptedTerms?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  mfaEnabled?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<ListPaginatedUsersOrderByOrderByClause>>;
  paginationOptions: PaginationOptions;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedUsersForPermissionGroupArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  permissionGroupId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistPaginatedWebhookEventsArgs = {
  externalEventName?: InputMaybe<Array<Scalars['String']>>;
  internalEventName?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedWorkClassesArgs = {
  filters?: InputMaybe<WorkClassFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedWorkRightsArgs = {
  filters?: InputMaybe<WorkRightFilters>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPaginatedWorkTypesArgs = {
  filters?: InputMaybe<WorkTypeFilters>;
  hasEnabledChildren?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptions;
};


export type QuerylistPerformanceManagementsArgs = {
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QuerylistPositionTitlesArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  nameSearch?: InputMaybe<Scalars['String']>;
};


export type QuerylistPulsesByCustomFieldArgs = {
  customFieldDefinitionId: Scalars['ID'];
};


export type QuerylistSelfServiceActionsForPersonArgs = {
  personId: Scalars['ID'];
};


export type QuerylistSettingsPaginatedPermissionGroupArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerylistSftpInboundCredentialsArgs = {
  alias?: InputMaybe<Array<Scalars['String']>>;
  username?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerylistTrainingStatisticsArgs = {
  completedAtEndDate?: InputMaybe<Scalars['DateTime']>;
  completedAtStartDate?: InputMaybe<Scalars['DateTime']>;
  jobId?: InputMaybe<Scalars['ID']>;
  personId: Scalars['ID'];
  providerIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  trainingStatusIds?: InputMaybe<Array<Scalars['ID']>>;
  typeIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerylistTurnoverReasonsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  turnoverType?: InputMaybe<TurnoverType>;
};


export type QuerylistViewableDocumentFoldersArgs = {
  personId?: InputMaybe<Scalars['ID']>;
};


export type QueryorgObjectiveAlignmentTreeArgs = {
  maxDepth?: InputMaybe<Scalars['Int']>;
  orgObjectiveId: Scalars['ID'];
};


export type QuerypayGradesHaveHourlyRatesArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type QuerypeopleDirectoryListArgs = {
  businessEntityIdsFilter?: InputMaybe<Array<Scalars['String']>>;
  businessUnitIdsFilter?: InputMaybe<Array<Scalars['String']>>;
  employmentStatusesFilter?: InputMaybe<Array<PeopleDirectoryEmploymentStatusFilter>>;
  hasConcurrentJobs?: InputMaybe<Scalars['Boolean']>;
  hasMedicalCondition?: InputMaybe<Scalars['Boolean']>;
  locationIdsFilter?: InputMaybe<Array<Scalars['String']>>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  peopleCustomFieldsFilters?: InputMaybe<Array<PeopleDirectoryPeopleCustomFieldFilter>>;
  searchText?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<PeopleDirectorySortDirection>>;
  supervisorIdsFilter?: InputMaybe<Array<Scalars['String']>>;
  workClassIdsFilter?: InputMaybe<Array<Scalars['String']>>;
  workTypeIdsFilter?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryperformMultipleCustomFieldModelAuthCheckArgs = {
  input: PerformMultipleAuthChecksForCustomFieldModelInput;
};


export type QueryperformMultipleHierarchicalAuthCheckArgs = {
  input: PerformMultipleAuthChecksInput;
};


export type QueryperformPeopleDropdownSearchArgs = {
  hasUserAccount?: InputMaybe<Scalars['Boolean']>;
  hierarchicalPermissionsFilter?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  showIndividualJobs?: InputMaybe<Scalars['Boolean']>;
  showNoJobRecords?: InputMaybe<Scalars['Boolean']>;
  showPastStaff?: InputMaybe<Scalars['Boolean']>;
  validJobIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryperformSingleCustomFieldModelAuthCheckArgs = {
  input: PerformSingleAuthCheckForCustomFieldModelInput;
};


export type QueryperformSingleHierarchicalAuthCheckArgs = {
  input: PerformSingleAuthCheckInput;
};


export type QuerypersonCanViewCompletedFormArgs = {
  id: Scalars['ID'];
};


export type QueryresolveSignersForContractTemplateArgs = {
  input?: InputMaybe<ResolveSignersForContractTemplateInput>;
};


export type QueryshouldLinkPayGradesAndEmploymentConditionsArgs = {
  asAt?: InputMaybe<Scalars['DateTime']>;
};


export type QueryuserHasCompensationPlannerAccessArgs = {
  accessType: Scalars['String'];
};

export type QueuedMutationResponse = {
  __typename?: 'QueuedMutationResponse';
  /** The internal name of the command that was dispatched as */
  command: Scalars['String'];
  /** The ID of the model created/updated/deleted as a result of this mutation */
  id?: Maybe<Scalars['ID']>;
  /** Whether this mutation was successfully queued. In practice this is always true as errors result in a validation failure */
  success: Scalars['Boolean'];
};

export type RecruitmentInfoUpdateInput = {
  /** UUID of the currency of the recruitment cost */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** UUID of the job */
  id: Scalars['ID'];
  /** The total cost of recruitment for the job */
  recruitmentCost?: InputMaybe<Scalars['Float']>;
  /** UUID of the recruitment source for the job */
  sourceId?: InputMaybe<Scalars['ID']>;
};

export type RecruitmentInfoUpdateResponse = {
  __typename?: 'RecruitmentInfoUpdateResponse';
  job: Job;
};

export type RecruitmentSource = {
  __typename?: 'RecruitmentSource';
  id: Scalars['ID'];
  /** If true, the source should be shown in dropdowns */
  isEnabled: Scalars['Boolean'];
  /** The name of the recruitment source */
  name: Scalars['String'];
  /** The hierarchical parent source for this recruitment source */
  parentRecruitmentSource?: Maybe<RecruitmentSource>;
  /** The sorting order of the recruitment source */
  sortOrder: Scalars['Int'];
};

export type RecruitmentSourceFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type RecurringPulseDeferred = {
  __typename?: 'RecurringPulseDeferred';
  recurringPulse?: Maybe<PulseRecurrence>;
};

export type RecurringPulseFrequencyInput = {
  days?: InputMaybe<Scalars['Int']>;
  months?: InputMaybe<Scalars['Int']>;
  nextSendDate?: InputMaybe<Scalars['DateTime']>;
  weeks?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['Int']>;
};

export type RejectTermsResponse = {
  __typename?: 'RejectTermsResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemunerationComponentInput = {
  /** The currency to use for this addition/deduction. For multiples, this is always the same as the base currency. */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** The id of the default this remuneration component is based on if it is based on one */
  defaultRemunerationComponentId?: InputMaybe<Scalars['ID']>;
  /** Human-readable name for this addition/deduction */
  name?: InputMaybe<Scalars['String']>;
  /** Whether the addition/deduction is a multiple of the base or a fixed value */
  type?: InputMaybe<RemunerationComponentValueType>;
  /** The value of the addition/deduction. For multiples, this is a _decimal_ (0.095 for superannuation, for example). */
  value?: InputMaybe<Scalars['String']>;
};

export enum RemunerationComponentType {
  AdditionToBase = 'AdditionToBase',
  AdditionToTotal = 'AdditionToTotal',
  Breakdown = 'Breakdown',
  DeductionToBase = 'DeductionToBase'
}

export enum RemunerationComponentValueType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  MULTIPLE_OF_BASE = 'MULTIPLE_OF_BASE'
}

export type RemunerationCustomFieldValueInput = {
  /** The custom field api name */
  apiName: Scalars['String'];
  /** The custom field value */
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type RemunerationQuery = {
  __typename?: 'RemunerationQuery';
  /** query to get a list of self-service actions for a person */
  calculateRemuneration?: Maybe<CalculateRemunerationResponse>;
};


export type RemunerationQuerycalculateRemunerationArgs = {
  input: CalculateRemunerationInput;
};

export enum RemunerationScheduleType {
  ANNUAL = 'ANNUAL',
  NO_SCHEDULE = 'NO_SCHEDULE',
  RATE = 'RATE'
}

export enum ReportPeriodRangeType {
  CUSTOM_REPORT_PERIOD_RANGE_TYPE = 'CUSTOM_REPORT_PERIOD_RANGE_TYPE',
  SIX_MONTHS_REPORT_PERIOD_RANGE_TYPE = 'SIX_MONTHS_REPORT_PERIOD_RANGE_TYPE',
  THREE_MONTHS_REPORT_PERIOD_RANGE_TYPE = 'THREE_MONTHS_REPORT_PERIOD_RANGE_TYPE',
  TWELVE_MONTHS_REPORT_PERIOD_RANGE_TYPE = 'TWELVE_MONTHS_REPORT_PERIOD_RANGE_TYPE'
}

export type Representative = {
  __typename?: 'Representative';
  id: Scalars['ID'];
  model: RepresentativeModel;
  person?: Maybe<Person>;
  representativeType: RepresentativeType;
};

export enum RepresentativeModel {
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  LOCATION = 'LOCATION'
}

export type RepresentativeType = {
  __typename?: 'RepresentativeType';
  id: Scalars['ID'];
  modelType: RepresentativeModel;
  name: Scalars['String'];
};

export type RepresentativeTypeEditInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type RepresentativeTypeEditResponse = {
  __typename?: 'RepresentativeTypeEditResponse';
  representativeType: RepresentativeType;
};

export enum RequirementType {
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  LOCATION = 'LOCATION',
  MAXIMUM_FTE = 'MAXIMUM_FTE',
  PAY_GRADE = 'PAY_GRADE',
  POSITION_TITLE = 'POSITION_TITLE',
  TOTAL_FTE = 'TOTAL_FTE',
  TOTAL_JOBS = 'TOTAL_JOBS',
  WORK_CLASS = 'WORK_CLASS'
}

export type ResendFormInput = {
  /** The ID of the form to resend */
  id: Scalars['ID'];
};

export type ResendFormResponse = {
  __typename?: 'ResendFormResponse';
  /** The form that was resent */
  form: Form;
};

export type ResendMessageInput = {
  /** The ID of the message to resend */
  id: Scalars['ID'];
};

export type ResendMessageResponse = {
  __typename?: 'ResendMessageResponse';
  /** The ID of the new re-sent message */
  id?: Maybe<Scalars['ID']>;
  /** The result of the request */
  status: Scalars['String'];
};

export type ResendSignatureRequestEmailResponse = {
  __typename?: 'ResendSignatureRequestEmailResponse';
  id: Scalars['ID'];
};

export type ResendTaskInput = {
  /** The ID of the task to resend */
  id: Scalars['ID'];
};

export type ResendTaskResponse = {
  __typename?: 'ResendTaskResponse';
  /** The task that was resent */
  task: Task;
};

export enum ResidencyStatus {
  AUSTRALIANRESIDENT = 'AUSTRALIANRESIDENT',
  FOREIGNRESIDENT = 'FOREIGNRESIDENT',
  WORKINGHOLIDAYMAKER = 'WORKINGHOLIDAYMAKER'
}

export type ResolveSignersForContractTemplateInput = {
  contractTemplateId: Scalars['ID'];
  subjectPersonId: Scalars['ID'];
};

export type ResolvedContractSigner = {
  __typename?: 'ResolvedContractSigner';
  person?: Maybe<Person>;
  signaturePosition: Scalars['Int'];
  signerType: ContractTemplateSignerType;
};

export type RespondentEmail = {
  __typename?: 'RespondentEmail';
  respondentEmailAddress?: Maybe<Scalars['String']>;
};

export type RespondentJob = {
  __typename?: 'RespondentJob';
  respondentJob?: Maybe<Job>;
};

export enum RowOrListEnum {
  LIST = 'LIST',
  ROW = 'ROW'
}

export type SESBounceError = {
  __typename?: 'SESBounceError';
  bounceSubType?: Maybe<Scalars['String']>;
  bounceType?: Maybe<Scalars['String']>;
};

export type SESComplaintError = {
  __typename?: 'SESComplaintError';
  complaintSubType?: Maybe<Scalars['String']>;
  feedbackType?: Maybe<Scalars['String']>;
};

export type ScheduledForm = {
  __typename?: 'ScheduledForm';
  /** When the scheduled form was created */
  createdAt: Scalars['DateTime'];
  /** When the scheduled form was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The form design this scheduled form was based on */
  formDesign: FormDesign;
  /** Form Instances relating to this Scheduled Form */
  formInstances?: Maybe<Array<Form>>;
  id: Scalars['ID'];
  /** Nullable: The new intended Notification Method. */
  intendedNotificationMethod?: Maybe<NotificationMethod>;
  /**
   * Nullable: The new intended Respondent Email Address. Will return a value if
   * there is a intended change to the Form Designs recipient email address.
   */
  intendedRespondentEmailAddress?: Maybe<Scalars['String']>;
  /**
   * Nullable: The new intended Respondent Job. Will return a value if there is a
   * change to the intended Respondent Type and that value is not for a specific
   */
  intendedRespondentJob?: Maybe<Job>;
  /** Nullable: The new intended respondent type.  Will return null if using default form design configuration. */
  intendedRespondentType?: Maybe<FormRespondentType>;
  /** Whether this scheduled form has been queued to send */
  isQueued: Scalars['Boolean'];
  /** When the scheduled form is scheduled to send */
  issueDate: Scalars['DateTime'];
  /** When the scheduled form was last updated */
  updatedAt: Scalars['DateTime'];
  /** The Workflow Instance this scheduled form belongs to */
  workflowInstance: WorkflowInstance;
};

export enum ScheduledFormSortDirection {
  FORM_DESIGN_NAME_ASCENDING = 'FORM_DESIGN_NAME_ASCENDING',
  FORM_DESIGN_NAME_DESCENDING = 'FORM_DESIGN_NAME_DESCENDING',
  ISSUE_DATE_ASCENDING = 'ISSUE_DATE_ASCENDING',
  ISSUE_DATE_DESCENDING = 'ISSUE_DATE_DESCENDING',
  RESPONDENT_ASCENDING = 'RESPONDENT_ASCENDING',
  RESPONDENT_DESCENDING = 'RESPONDENT_DESCENDING',
  SUBJECT_ASCENDING = 'SUBJECT_ASCENDING',
  SUBJECT_DESCENDING = 'SUBJECT_DESCENDING'
}

export type SelectSuperFundProduct = {
  __typename?: 'SelectSuperFundProduct';
  id: Scalars['ID'];
  memberNumber: Scalars['String'];
  superFundProduct: SuperFundProduct;
};

export type SelfManagedSuperFund = {
  __typename?: 'SelfManagedSuperFund';
  bankAccountBsb: Scalars['String'];
  bankAccountName: Scalars['String'];
  bankAccountNumber: Scalars['String'];
  fundAbn: Scalars['String'];
  fundElectronicServiceAddress: Scalars['String'];
  fundName: Scalars['String'];
  id: Scalars['ID'];
  memberName: Scalars['String'];
  memberNumber: Scalars['String'];
};

export type SelfServiceAction = {
  __typename?: 'SelfServiceAction';
  /** The action properties that the self-service action has */
  actionProperties: SelfServiceActionProperties;
  /** The action type that the self-service action belongs to */
  actionType: SelfServiceActionType;
  /** when the self service action was created */
  createdAt: Scalars['DateTime'];
  /** The icon of the self-service action tile on dashboard or null for no icon */
  dashboardIcon?: Maybe<Scalars['String']>;
  /** The order number of the self-service action tile on dashboard */
  dashboardOrder: Scalars['Int'];
  /** The description of the self-service action tile on dashboard */
  dashboardTileDescription: Scalars['String'];
  /** The tile of the self-service action tile on dashboard */
  dashboardTileTitle: Scalars['String'];
  /** Filters applied to who can see and interact with the self-service action */
  filters: SelfServiceActionFilters;
  id: Scalars['ID'];
  /**
   * Whether the self-service action tile is enabled
   * @deprecated use visibility instead
   */
  isEnabled: Scalars['Boolean'];
  /** when the self service action was last updated */
  updatedAt: Scalars['DateTime'];
  /** Whether the self-service action is visible */
  visibility: SelfServiceActionVisibility;
};

export type SelfServiceActionCreateInput = {
  /** The action properties that the self-service action has */
  actionProperties: SelfServiceActionPropertiesInput;
  /** The action type that the self-service action belongs to */
  actionTypeId: Scalars['ID'];
  /** The icon of the self-service action tile on dashboard */
  dashboardIcon?: InputMaybe<Scalars['String']>;
  /** The order number of the self-service action tile on dashboard */
  dashboardOrder?: InputMaybe<Scalars['Int']>;
  /** The description of the self-service action tile on dashboard */
  dashboardTileDescription: Scalars['String'];
  /** The tile of the self-service action tile on dashboard */
  dashboardTileTitle: Scalars['String'];
  /** The filters applied to the self service tile on who can see and interact with it */
  filters?: InputMaybe<SelfServiceActionFiltersInput>;
  /** Whether the self-service action tile is enabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The visibility of the self service tile */
  visibility?: InputMaybe<SelfServiceActionVisibility>;
};

export type SelfServiceActionCreateResponse = {
  __typename?: 'SelfServiceActionCreateResponse';
  selfServiceAction: SelfServiceAction;
};

export type SelfServiceActionDeleteInput = {
  /** The self service action id */
  id: Scalars['ID'];
};

export type SelfServiceActionDeleteResponse = {
  __typename?: 'SelfServiceActionDeleteResponse';
  /** The ID of the self service action that was deleted. */
  id: Scalars['ID'];
};

export type SelfServiceActionFilters = {
  __typename?: 'SelfServiceActionFilters';
  businessEntities?: Maybe<Array<BusinessEntity>>;
  businessUnits?: Maybe<Array<BusinessUnit>>;
  establishments?: Maybe<Array<Establishment>>;
  excludeOnExtendedLeave: Scalars['Boolean'];
  excludePeople?: Maybe<Array<Person>>;
  locations?: Maybe<Array<Location>>;
  onlyWithDirectReports: Scalars['Boolean'];
  payGrades?: Maybe<Array<PayGrade>>;
  people?: Maybe<Array<Person>>;
  permissionGroups?: Maybe<Array<PermissionGroup>>;
  workTypes?: Maybe<Array<WorkType>>;
};

export type SelfServiceActionFiltersInput = {
  /** The list of business entities ids to filter on */
  businessEntityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of business unit ids to filter on */
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of establishment ids to filter on */
  establishmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Set flag to filter out those who are on extended leave */
  excludeOnExtendedLeave?: InputMaybe<Scalars['Boolean']>;
  /** The list of excluded people ids to filter on */
  excludePeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of location ids to filter on */
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Set flag to filter only on those with direct reports */
  onlyWithDirectReports?: InputMaybe<Scalars['Boolean']>;
  /** The list of pay grade ids to filter on */
  payGradeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of people ids to filter on */
  peopleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of permission ids to filter on */
  permissionGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The list of work type ids to filter on */
  workTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SelfServiceActionFormCompletionRequestInput = {
  /** The list of expected respondent jobs with matching person */
  expectedRespondents: Array<ExpectedRespondentInput>;
  /** The form design Id */
  formDesignId: Scalars['ID'];
  /** The person id of the issuer */
  issuerPersonId: Scalars['ID'];
  /** The self service action id */
  selfServiceActionId: Scalars['ID'];
  /** Should the respondent be notified */
  shouldNotifyRespondent: Scalars['Boolean'];
  /** The job id of the subject */
  subjectJobId: Scalars['ID'];
  /** The person id of the subject */
  subjectPersonId: Scalars['ID'];
};

export type SelfServiceActionFromToInput = {
  /** The ID of the self service action that was moved to a new position */
  fromId: Scalars['ID'];
  /** The ID of the self service action that the fromId was moved to */
  toId: Scalars['ID'];
};

export type SelfServiceActionFromToResponse = {
  __typename?: 'SelfServiceActionFromToResponse';
  selfServiceActions: Array<SelfServiceAction>;
};

export type SelfServiceActionPerformFillInFormInput = {
  /** The job id of the expected respondent */
  expectedRespondentJobId: Scalars['ID'];
  /** The person id of the expected respondent */
  expectedRespondentPersonId: Scalars['ID'];
  /** The form design Id */
  formDesignId: Scalars['ID'];
  /** The person id of the issuer */
  issuerPersonId: Scalars['ID'];
  /** The self service action id */
  selfServiceActionId: Scalars['ID'];
  /** Should the respondent be notified */
  shouldNotifyRespondent: Scalars['Boolean'];
  /** The job id of the subject */
  subjectJobId: Scalars['ID'];
  /** The person id of the subject */
  subjectPersonId: Scalars['ID'];
};

export type SelfServiceActionPerformFillInFormResponse = {
  __typename?: 'SelfServiceActionPerformFillInFormResponse';
  filledInform?: Maybe<SelfServiceActionPerformFillInFormResponseForm>;
};

export type SelfServiceActionPerformFillInFormResponseForm = {
  __typename?: 'SelfServiceActionPerformFillInFormResponseForm';
  accessCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type SelfServiceActionPerformRequestFormCompletionResponseForm = {
  __typename?: 'SelfServiceActionPerformRequestFormCompletionResponseForm';
  accessCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type SelfServiceActionProperties = SelfServiceActionPropertiesFillInForm | SelfServiceActionPropertiesRequestFormCompletion | SelfServiceActionPropertiesUrl;

export type SelfServiceActionPropertiesFillInForm = {
  __typename?: 'SelfServiceActionPropertiesFillInForm';
  formChoices: Array<SelfServiceFormChoice>;
};

export type SelfServiceActionPropertiesInput = {
  /** Form choices for self service tile */
  formChoices?: InputMaybe<Array<SelfServiceFormChoiceInput>>;
  /** The direct url for the action property */
  url?: InputMaybe<Scalars['String']>;
};

export type SelfServiceActionPropertiesRequestFormCompletion = {
  __typename?: 'SelfServiceActionPropertiesRequestFormCompletion';
  formChoices: Array<SelfServiceFormChoice>;
};

export type SelfServiceActionPropertiesUrl = {
  __typename?: 'SelfServiceActionPropertiesUrl';
  url?: Maybe<Scalars['String']>;
};

export type SelfServiceActionQuery = {
  __typename?: 'SelfServiceActionQuery';
  /** query to get a list of self-service actions for a person */
  listSelfServiceActionsForPerson?: Maybe<Array<Maybe<SelfServiceAction>>>;
};


export type SelfServiceActionQuerylistSelfServiceActionsForPersonArgs = {
  personId: Scalars['ID'];
};

export enum SelfServiceActionSubjectSelectionType {
  CHOOSE_WHEN_PERFORMING_ACTION = 'CHOOSE_WHEN_PERFORMING_ACTION',
  NO_SUBJECT = 'NO_SUBJECT',
  SELF = 'SELF'
}

export type SelfServiceActionType = {
  __typename?: 'SelfServiceActionType';
  id: Scalars['ID'];
  /** The human-readable name of the self service action type */
  name: Scalars['String'];
  /** The sort order of the self-service action type */
  sortOrder: Scalars['Int'];
};

export type SelfServiceActionUpdateInput = {
  /** The action properties that the self-service action has */
  actionProperties?: InputMaybe<SelfServiceActionPropertiesInput>;
  /** The action type that the self-service action belongs to */
  actionTypeId?: InputMaybe<Scalars['ID']>;
  /** The icon of the self-service action tile on dashboard */
  dashboardIcon?: InputMaybe<Scalars['String']>;
  /** The order number of the self-service action tile on dashboard */
  dashboardOrder?: InputMaybe<Scalars['Int']>;
  /** The description of the self-service action tile on dashboard */
  dashboardTileDescription?: InputMaybe<Scalars['String']>;
  /** The tile of the self-service action tile on dashboard */
  dashboardTileTitle?: InputMaybe<Scalars['String']>;
  /** The filters applied to the self service tile on who can see and interact with it */
  filters?: InputMaybe<SelfServiceActionFiltersInput>;
  /** The self service action id */
  id: Scalars['ID'];
  /** Whether the self-service action tile is enabled */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The visibility of the self service tile */
  visibility?: InputMaybe<SelfServiceActionVisibility>;
};

export type SelfServiceActionUpdateResponse = {
  __typename?: 'SelfServiceActionUpdateResponse';
  selfServiceAction: SelfServiceAction;
};

export enum SelfServiceActionVisibility {
  EVERYBODY = 'EVERYBODY',
  FILTERED = 'FILTERED',
  HIDDEN = 'HIDDEN'
}

export type SelfServiceFormChoice = {
  __typename?: 'SelfServiceFormChoice';
  formDesign: FormDesign;
  subjectReceivesAnswers?: Maybe<Scalars['Boolean']>;
  subjectSelectionType?: Maybe<SelfServiceActionSubjectSelectionType>;
};

export type SelfServiceFormChoiceInput = {
  /** The ID of the form design */
  formDesignId?: InputMaybe<Scalars['ID']>;
  /** The subject receive answer for form choices */
  subjectReceivesAnswers?: InputMaybe<Scalars['Boolean']>;
  /** The selection type for the form design */
  subjectSelectionType?: InputMaybe<SelfServiceActionSubjectSelectionType>;
};

export type SendIntegrationNotificationInput = {
  /** Relevant body to send */
  emailBody: Scalars['String'];
  /** Relevant subject to send */
  emailSubject: Scalars['String'];
  /** Email to send integration notification to */
  emailTo: Scalars['String'];
};

export type SendIntegrationNotificationResponse = {
  __typename?: 'SendIntegrationNotificationResponse';
  /** Status of mutation completion. */
  success: Scalars['Boolean'];
};

export type SesErrors = {
  __typename?: 'SesErrors';
  bounce?: Maybe<SESBounceError>;
  complaint?: Maybe<SESComplaintError>;
};

export type SetAnalyticsConfigurationResponse = {
  __typename?: 'SetAnalyticsConfigurationResponse';
  /** Whether the configuration set was successful */
  wasSuccessful: Scalars['Boolean'];
};

export type SetAnalyticsConfigurationValueInput = {
  /** The data to be set in the specified configuration path */
  data?: InputMaybe<Scalars['JSONObject']>;
  /** Force value to be set in the specified path in the configuration */
  force?: InputMaybe<Scalars['Boolean']>;
  /** Only set the data if the specified path does not exist */
  ifPathNotExists?: InputMaybe<Scalars['String']>;
  /** The path in the configuration where the data should be set */
  path?: InputMaybe<Scalars['String']>;
  /** Should configuration service enable additional clean up on the input payload, if not set to true explicitly, the payload will be preprocessed */
  raw?: InputMaybe<Scalars['Boolean']>;
};

export type SetAnalyticsNavConfigurationInput = {
  view: AnalyticsNavViewType;
};

export type SettingsPermissionGroupEntry = {
  __typename?: 'SettingsPermissionGroupEntry';
  canAssumeAsSelf: Scalars['Boolean'];
  canAssumeAsSelfDisclaimer?: Maybe<Scalars['String']>;
  canAssumeAsSomebodyElse: Scalars['Boolean'];
  canAssumeAsSomebodyElseDisclaimer?: Maybe<Scalars['String']>;
  canDelete: Scalars['Boolean'];
  canDeleteDisclaimer?: Maybe<Scalars['String']>;
  canDuplicate: Scalars['Boolean'];
  canDuplicateDisclaimer?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  canEditDisclaimer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  isSystemRole: Scalars['Boolean'];
  name: Scalars['String'];
  permissionsTotal: Scalars['Int'];
  usersTotal: Scalars['Int'];
};

export type SftpInboundCredential = {
  __typename?: 'SftpInboundCredential';
  /** The alias of the inbound credential. */
  alias?: Maybe<Scalars['String']>;
  /** Whitelisted CIDR IPV4 */
  cidrIpv4?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Public acceptable SSH keys */
  sshPublicKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt: Scalars['DateTime'];
  /** Username of the credential */
  username: Scalars['String'];
};

export type SftpInboundCredentialCreateInput = {
  /** Alias of this credential */
  alias?: InputMaybe<Scalars['String']>;
  /** CIDR IPV4 */
  cidrIpv4?: InputMaybe<Scalars['String']>;
  /** Password */
  password?: InputMaybe<Scalars['String']>;
  /** List of SSH public keys */
  sshPublicKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Username */
  username: Scalars['String'];
};

export type SftpInboundCredentialResponse = {
  __typename?: 'SftpInboundCredentialResponse';
  password: Scalars['String'];
  /** The inbound credential that has been created. */
  sftpInboundCredential: SftpInboundCredential;
};

export type SftpInboundCredentialUpdateInput = {
  /** Alias of this credential */
  alias?: InputMaybe<Scalars['String']>;
  /** CIDR IPV4 */
  cidrIpv4?: InputMaybe<Scalars['String']>;
  /** ID of this credential */
  id: Scalars['ID'];
};

export type SftpMutation = {
  __typename?: 'SftpMutation';
  _empty?: Maybe<Scalars['String']>;
  /** Pass details of a file being uploaded to Jimbo */
  sftpOnS3FileUploaded: Scalars['Boolean'];
};

export type SftpOutboundCredential = {
  __typename?: 'SftpOutboundCredential';
  /** The alias of the outbound credential. */
  alias: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The hostname of the outbound server. */
  hostname: Scalars['String'];
  id: Scalars['ID'];
  /** The drop / connection path of the outbound credential. */
  path?: Maybe<Scalars['String']>;
  /** The port of the outbound server. */
  port: Scalars['Int'];
  /** SSH Key of the credential */
  sshKey: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Username of the credential */
  username: Scalars['String'];
};

export type SftpOutboundCredentialCreateInput = {
  /** Alias of this credential */
  alias?: InputMaybe<Scalars['String']>;
  /** Hostname of the server */
  hostname: Scalars['String'];
  /** Password of the outbound credential */
  password?: InputMaybe<Scalars['String']>;
  /** Path to place files */
  path?: InputMaybe<Scalars['String']>;
  /** Port of the server */
  port: Scalars['Int'];
  /** SSH Key of the outbound credential */
  sshKey?: InputMaybe<Scalars['String']>;
  /** SSH Pass of the outbound credential */
  sshPass?: InputMaybe<Scalars['String']>;
  /** Username of the outbound credential */
  username: Scalars['String'];
};

export type SftpOutboundCredentialUpdateInput = {
  /** Alias of this credential */
  alias?: InputMaybe<Scalars['String']>;
  /** Path to place files */
  path?: InputMaybe<Scalars['String']>;
};

export type SftpQuery = {
  __typename?: 'SftpQuery';
  _empty?: Maybe<Scalars['String']>;
  /** Returns True if provided outbound credentials are correct */
  checkOutboundCredentials?: Maybe<Scalars['Boolean']>;
};

export type ShareCompensationPlanInput = {
  /** The id of compensation plan to edit */
  id: Scalars['ID'];
  /** Ids of People to remove sharing rights for compensation plan */
  removePeopleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Ids of People to share compensation plan with */
  sharedWithPeopleIds: Array<Scalars['ID']>;
};

export type ShareCompensationPlanResponse = {
  __typename?: 'ShareCompensationPlanResponse';
  compensationPlan: CompensationPlan;
};

export enum SignerType {
  BUSINESS_UNIT_REPRESENTATIVE = 'BUSINESS_UNIT_REPRESENTATIVE',
  LOCATION_REPRESENTATIVE = 'LOCATION_REPRESENTATIVE',
  PERSON_ID = 'PERSON_ID',
  RESOLVE_AT_ISSUING_CONTRACT = 'RESOLVE_AT_ISSUING_CONTRACT',
  SUBJECT = 'SUBJECT',
  SUPERVISOR = 'SUPERVISOR',
  SUPERVISORS_SUPERVISOR = 'SUPERVISORS_SUPERVISOR'
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export type Skill = {
  __typename?: 'Skill';
  assignedSkills: Array<AssignedSkill>;
  /** The description of the skill */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** Whether the skill is a business critical skill */
  isBusinessCritical: Scalars['Boolean'];
  /** The human-readable name of the skill */
  name: Scalars['String'];
  skillDiscipline: SkillDiscipline;
};


export type SkillassignedSkillsArgs = {
  filters?: InputMaybe<AssignedSkillFilter>;
};

export type SkillDiscipline = {
  __typename?: 'SkillDiscipline';
  id: Scalars['ID'];
  /** The human-readable name of the skill discipline */
  name: Scalars['String'];
  skills: Array<Skill>;
};


export type SkillDisciplineskillsArgs = {
  filters?: InputMaybe<SkillFilter>;
};

export type SkillDisciplineMutation = {
  __typename?: 'SkillDisciplineMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Creates a skill discipline with the given input */
  createSkillDiscipline: SkillDiscipline;
  /** Deletes a skill discipline with the given input */
  deleteSkillDiscipline: DeleteSkillDisciplineResponse;
  /** Updates a skill discipline with the given input */
  updateSkillDiscipline: SkillDiscipline;
};


export type SkillDisciplineMutationcreateSkillDisciplineArgs = {
  input: CreateSkillDisciplineInput;
};


export type SkillDisciplineMutationdeleteSkillDisciplineArgs = {
  input: DeleteSkillDisciplineInput;
};


export type SkillDisciplineMutationupdateSkillDisciplineArgs = {
  input: UpdateSkillDisciplineInput;
};

export type SkillFilter = {
  isBusinessCritical?: InputMaybe<BooleanFilter>;
};

export type SkillLevel = {
  __typename?: 'SkillLevel';
  /** The alias of the skill level */
  alias: Scalars['String'];
  /** The description of the skill level */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The human-readable name of the skill level */
  name: Scalars['String'];
  /** The ordinal ranking of the skill level */
  ranking: Scalars['Int'];
};

export type SkillMutation = {
  __typename?: 'SkillMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Creates a skill with the given input */
  createSkill: Skill;
  /** Deletes a skill with the given input */
  deleteSkill: DeleteSkillResponse;
  /** Updates a skill with the given input */
  updateSkill: Skill;
};


export type SkillMutationcreateSkillArgs = {
  input: CreateSkillInput;
};


export type SkillMutationdeleteSkillArgs = {
  input: DeleteSkillInput;
};


export type SkillMutationupdateSkillArgs = {
  input: UpdateSkillInput;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  ASC = 'ASC',
  /** Sort records in descending order. */
  DESC = 'DESC'
}

export enum StateAbbreviation {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA'
}

export type SubmitOnboardingFormInput = {
  /** The physical addresses associated with this person. */
  addresses?: InputMaybe<Array<OnboardingAddressInput>>;
  /** Custom values associated with this person */
  customFieldValues?: InputMaybe<Array<OnboardingCustomFieldValueInput>>;
  /** The person's date of birth */
  dateOfBirth?: InputMaybe<Scalars['String']>;
  /** The email addresses associated with this person. */
  emailAddresses?: InputMaybe<Array<OnboardingEmailInput>>;
  /** The email of the person's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']>;
  /** The name of the person's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']>;
  /** The phone number of the person's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  /** The country ID of the phone number of the person's emergency contact */
  emergencyContactPhoneCountryId?: InputMaybe<Scalars['ID']>;
  /** The relationship between the person and emergency contact */
  emergencyContactRelationship?: InputMaybe<Scalars['String']>;
  /** The person's first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the gender of the person */
  genderId?: InputMaybe<Scalars['ID']>;
  /** The person's last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** The medical conditions that this person has. */
  medicalConditions?: InputMaybe<Array<OnboardingMedicalConditionInput>>;
  /** The person's middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** The phone numbers associated with this person. */
  phoneNumbers?: InputMaybe<Array<OnboardingPhoneNumberInput>>;
  /** The person's preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** The title ID for this person */
  titleId?: InputMaybe<Scalars['ID']>;
  /** Deprecated. This field is unused. */
  workRightCountryId?: InputMaybe<Scalars['ID']>;
  /** The expiry date of the person's work right */
  workRightExpiryDate?: InputMaybe<Scalars['String']>;
  /** The work right ID */
  workRightId?: InputMaybe<Scalars['ID']>;
};

export type SubmitOnboardingFormInputV2 = {
  /** The physical addresses associated with this person. */
  addresses?: InputMaybe<Array<OnboardingAddressInput>>;
  /** Custom values associated with this person */
  customFieldValues?: InputMaybe<Array<OnboardingCustomFieldValueInput>>;
  /** The person's date of birth */
  dateOfBirth?: InputMaybe<Scalars['String']>;
  /** The email addresses associated with this person. */
  emailAddresses?: InputMaybe<Array<OnboardingEmailInput>>;
  /** The email of the person's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']>;
  /** The name of the person's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']>;
  /** The phone number of the person's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  /** The country ID of the phone number of the person's emergency contact */
  emergencyContactPhoneCountryId?: InputMaybe<Scalars['ID']>;
  /** The relationship between the person and emergency contact */
  emergencyContactRelationship?: InputMaybe<Scalars['String']>;
  /** The person's first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the gender of the person */
  genderId?: InputMaybe<Scalars['ID']>;
  /** The person's last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** The medical conditions that this person has. */
  medicalConditions?: InputMaybe<Array<OnboardingMedicalConditionInput>>;
  /** The person's middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** The onboarding form id */
  onboardingFormId: Scalars['ID'];
  /** The phone numbers associated with this person. */
  phoneNumbers?: InputMaybe<Array<OnboardingPhoneNumberInput>>;
  /** The person's preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** The title ID for this person */
  titleId?: InputMaybe<Scalars['ID']>;
  /** The work right country ID */
  workRightCountryId?: InputMaybe<Scalars['ID']>;
  /** The expiry date of the person's work right */
  workRightExpiryDate?: InputMaybe<Scalars['String']>;
  /** The work right ID */
  workRightId?: InputMaybe<Scalars['ID']>;
};

export type SubmitOnboardingFormResponse = {
  __typename?: 'SubmitOnboardingFormResponse';
  onboardingFormInstance: OnboardingFormInstance;
};

export type SuperAPIEmployeeOnboardingSession = {
  __typename?: 'SuperAPIEmployeeOnboardingSession';
  bankAccountStatus: SuperAPIEmployeeOnboardingSessionStatus;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['JSON']>;
  hasBankAccountChanged: Scalars['Boolean'];
  hasSuperChanged: Scalars['Boolean'];
  hasTaxChanged: Scalars['Boolean'];
  id: Scalars['ID'];
  isFirstOnboardingCompleted: Scalars['Boolean'];
  job: Job;
  onboardingStep?: Maybe<OnboardingStep>;
  sessionStatus: SuperAPIEmployeeOnboardingSessionStatus;
  superAPIOnboardingSessionId: Scalars['ID'];
  superStatus: SuperAPIEmployeeOnboardingSessionStatus;
  taxStatus: SuperAPIEmployeeOnboardingSessionStatus;
};

export enum SuperAPIEmployeeOnboardingSessionStatus {
  COMPLETE = 'COMPLETE',
  COMPLETE_WITHOUT_MEMBER_NUMBER = 'COMPLETE_WITHOUT_MEMBER_NUMBER',
  PENDING = 'PENDING'
}

export type SuperAPIEmployeeOnboardingSessionSubmitResponse = {
  __typename?: 'SuperAPIEmployeeOnboardingSessionSubmitResponse';
  superAPIEmployeeOnboardingSession?: Maybe<SuperAPIEmployeeOnboardingSession>;
};

export type SuperApiEmployer = {
  __typename?: 'SuperApiEmployer';
  businessEntity: BusinessEntity;
  id: Scalars['ID'];
  onboardingStatus?: Maybe<Scalars['JSON']>;
  status: Scalars['String'];
  superApiEmployerId: Scalars['String'];
};

export type SuperFundMemberDetail = {
  __typename?: 'SuperFundMemberDetail';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLine4?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  familyName: Scalars['String'];
  gender: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  locality: Scalars['String'];
  postcode: Scalars['String'];
  previousFamilyName?: Maybe<Scalars['String']>;
  previousGivenName?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  tfn: Scalars['String'];
  title: Scalars['String'];
};

export type SuperFundProduct = {
  __typename?: 'SuperFundProduct';
  abn: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  usiNumber: Scalars['String'];
};

export type SuperFundSelectionCollection = {
  __typename?: 'SuperFundSelectionCollection';
  generated: SuperFundSelectionGeneratedUrlCollection;
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  outcome: Scalars['String'];
  progressState: Scalars['String'];
  selectSuperFundProduct?: Maybe<SelectSuperFundProduct>;
  selfManagedSuperFund?: Maybe<SelfManagedSuperFund>;
  superFundMemberDetail: SuperFundMemberDetail;
};

export type SuperFundSelectionGeneratedUrlCollection = {
  __typename?: 'SuperFundSelectionGeneratedUrlCollection';
  complianceLetterUrl: Scalars['String'];
  superChoiceFormPdfMergedUrl: Scalars['String'];
  superChoiceFormPdfUrl: Scalars['String'];
};

export enum SuperFundType {
  COMPANYPREFERRED = 'COMPANYPREFERRED',
  REGULATED = 'REGULATED',
  REQUIRESHELP = 'REQUIRESHELP',
  SELFMANAGED = 'SELFMANAGED',
  UNLISTED = 'UNLISTED'
}

export enum SuperannuationFundType {
  COMPANYPREFERRED = 'COMPANYPREFERRED',
  PROVIDELATER = 'PROVIDELATER',
  REGULATED = 'REGULATED',
  REQUIRESHELP = 'REQUIRESHELP',
  SELFMANAGED = 'SELFMANAGED',
  STAPLEDFUND = 'STAPLEDFUND',
  UNLISTED = 'UNLISTED'
}

export type SupportedLanguage = {
  __typename?: 'SupportedLanguage';
  /** Name of the language in english. */
  englishName: Scalars['String'];
  /** Whether the language is in beta and should be displayed as such. */
  isBeta: Scalars['Boolean'];
  /** Name of the language in the currently logged in user's language. */
  localisedName: Scalars['String'];
  /** Name of the language in the language itself e.g. Español for spanish */
  nativeName: Scalars['String'];
  /** Unique identifier for the language e.g. 'en', 'fr-CA'. */
  slug: Scalars['String'];
};

export type SyncWorkflowInput = {
  id: Scalars['ID'];
  /** The workflow form designs to set for this workflow */
  workflowFormDesigns: Array<InputMaybe<WorkflowFormDesignInput>>;
};

export type SyncWorkflowResponse = {
  __typename?: 'SyncWorkflowResponse';
  workflow?: Maybe<Workflow>;
};

export type TFNDeclaration = {
  __typename?: 'TFNDeclaration';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  countryOfOriginName?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  declarationInformationAccepted: Scalars['Boolean'];
  declarationStaplingAccepted: Scalars['Boolean'];
  declarationTfndAccepted: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  hasFinancialSupplementDebt: Scalars['Boolean'];
  hasStudyAndTrainingRepayment: Scalars['Boolean'];
  locality: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  payBasis: Scalars['String'];
  postcode: Scalars['String'];
  previousFamilyName?: Maybe<Scalars['String']>;
  previousGivenName?: Maybe<Scalars['String']>;
  residencyType: Scalars['String'];
  state: Scalars['String'];
  taxFreeThresholdClaimed: Scalars['Boolean'];
  tfn?: Maybe<Scalars['String']>;
  tfnExemptionType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TFNDeclarationCollection = {
  __typename?: 'TFNDeclarationCollection';
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  outcome: Scalars['String'];
  tfnDeclaration: TFNDeclaration;
};

/** Deprecating "Task" in favour of "Form" */
export type Task = {
  __typename?: 'Task';
  /** The Job that is expected to fill out this form */
  expectedRespondent?: Maybe<Job>;
  id: Scalars['ID'];
};

export type TaskComplianceFormDesign = {
  __typename?: 'TaskComplianceFormDesign';
  defaultNotificationMethodId: Scalars['ID'];
  formRespondentEmailAddresses?: Maybe<Scalars['String']>;
  formRespondentTypeId: Scalars['ID'];
  formRespondentTypeName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TaskComplianceFormInstance = {
  __typename?: 'TaskComplianceFormInstance';
  accessCode?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  formDesign: TaskComplianceFormDesign;
  hasBeenCompleted?: Maybe<Scalars['Boolean']>;
  hasNotificationBeenResent: Scalars['Boolean'];
  id: Scalars['ID'];
  ignoredAt?: Maybe<Scalars['DateTime']>;
  isIgnored?: Maybe<Scalars['Boolean']>;
  issueDate: Scalars['DateTime'];
  latestDraft?: Maybe<Scalars['DateTime']>;
  latestMessageSchedule?: Maybe<TaskComplianceMessageSchedule>;
  /** @deprecated Use latestMessageSchedule.id instead */
  messageScheduleId?: Maybe<Scalars['ID']>;
  respondentEmailAddress?: Maybe<EmailAddress>;
  respondentJob?: Maybe<Job>;
  /** @deprecated This can be accessed via the respondent job */
  respondentPerson?: Maybe<Person>;
  /** @deprecated Use latestMessageSchedule.sentTo instead */
  sentTo?: Maybe<Scalars['String']>;
  subjectJob: Job;
  submitterPerson?: Maybe<Person>;
};

export type TaskComplianceMessageSchedule = {
  __typename?: 'TaskComplianceMessageSchedule';
  /** Message schedule id */
  id: Scalars['ID'];
  /** Form issued notification sentTo */
  notificationSentTo?: Maybe<Scalars['String']>;
  /** Time sent */
  sentAt?: Maybe<Scalars['DateTime']>;
};

export type TaskComplianceQuery = {
  __typename?: 'TaskComplianceQuery';
  /** query to get a list of form instances for the task compliance page */
  listPaginatedTaskComplianceForStatus?: Maybe<TaskComplianceResponse>;
};


export type TaskComplianceQuerylistPaginatedTaskComplianceForStatusArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<TaskComplianceSortDirection>>;
  taskComplianceStatus: TaskComplianceStatus;
};

export type TaskComplianceResponse = {
  __typename?: 'TaskComplianceResponse';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  formInstances: Array<TaskComplianceFormInstance>;
  hasMore: Scalars['Boolean'];
  perPage: Scalars['Int'];
  sort?: Maybe<Array<TaskComplianceSortDirection>>;
  taskComplianceStatus: TaskComplianceStatus;
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum TaskComplianceSortDirection {
  COMPLETED_AT_ASCENDING = 'COMPLETED_AT_ASCENDING',
  COMPLETED_AT_DESCENDING = 'COMPLETED_AT_DESCENDING',
  DUE_DATE_ASCENDING = 'DUE_DATE_ASCENDING',
  DUE_DATE_DESCENDING = 'DUE_DATE_DESCENDING',
  FORM_DESIGN_NAME_ASCENDING = 'FORM_DESIGN_NAME_ASCENDING',
  FORM_DESIGN_NAME_DESCENDING = 'FORM_DESIGN_NAME_DESCENDING',
  IGNORED_AT_ASCENDING = 'IGNORED_AT_ASCENDING',
  IGNORED_AT_DESCENDING = 'IGNORED_AT_DESCENDING',
  ISSUE_DATE_ASCENDING = 'ISSUE_DATE_ASCENDING',
  ISSUE_DATE_DESCENDING = 'ISSUE_DATE_DESCENDING',
  RESPONDENT_ASCENDING = 'RESPONDENT_ASCENDING',
  RESPONDENT_DESCENDING = 'RESPONDENT_DESCENDING',
  SUBJECT_ASCENDING = 'SUBJECT_ASCENDING',
  SUBJECT_DESCENDING = 'SUBJECT_DESCENDING',
  SUBMITTER_ASCENDING = 'SUBMITTER_ASCENDING',
  SUBMITTER_DESCENDING = 'SUBMITTER_DESCENDING'
}

export enum TaskComplianceStatus {
  COMPLETED = 'COMPLETED',
  IGNORED = 'IGNORED',
  ISSUED = 'ISSUED',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING'
}

export enum TaxScaleType {
  ACTORSARTISTSENTERTAINERS = 'ACTORSARTISTSENTERTAINERS',
  FOREIGN = 'FOREIGN',
  HORTICULTURISTORSHEARER = 'HORTICULTURISTORSHEARER',
  REGULAR = 'REGULAR',
  SENIORORPENSIONER = 'SENIORORPENSIONER',
  WORKINGHOLIDAYMAKER = 'WORKINGHOLIDAYMAKER'
}

export type TempAdminCreateInput = {
  /** The password of the user to be created */
  password: Scalars['String'];
  /** The username of the user to be created */
  username: Scalars['String'];
};

export type TempAdminCreateResponse = {
  __typename?: 'TempAdminCreateResponse';
  person?: Maybe<Person>;
};

export type TempAdminDeleteInput = {
  /** The username of the user and person to be deleted */
  username: Scalars['String'];
};

export type TempAdminDeleteResponse = {
  __typename?: 'TempAdminDeleteResponse';
  /** The ID of the person that was deleted */
  id: Scalars['ID'];
};

export type Template = {
  __typename?: 'Template';
  /** Human readable name of the template */
  description: Scalars['String'];
  /** Template id */
  id: Scalars['ID'];
  /** The linked template type in master_intellihr */
  masterIntellihrTemplateType?: Maybe<TemplateType>;
  /** Slug name of the template for internal use */
  name: Scalars['String'];
};

export type TemplateType = {
  __typename?: 'TemplateType';
  /** Human readable name of the template type */
  description: Scalars['String'];
  /** Template type id */
  id: Scalars['ID'];
  /** Slug name of the template type for internal use */
  name: Scalars['String'];
};

export type TenantAlias = {
  __typename?: 'TenantAlias';
  /** The alias. */
  alias?: Maybe<Scalars['String']>;
  /** The tenant the alias is for. */
  tenant?: Maybe<Scalars['String']>;
};

export type TenantBusinessEntityHeadcount = {
  __typename?: 'TenantBusinessEntityHeadcount';
  /** Name of the business entity the below set of headcounts pertain to */
  businessEntityName: Scalars['String'];
  /** Count any active staff with any job with a work type of casual */
  casualEmployees: Scalars['Int'];
  /** Count any active staff with any job with work type of fixed/independent contract and not permanent */
  contractors: Scalars['Int'];
  /** Count any active staff with any job of work type that is not unpaid */
  paidEmployees: Scalars['Int'];
  /** Count any active staff with any job with a work type of permanent */
  permanentEmployees: Scalars['Int'];
  /** Count any active staff with any job with only a work type of unpaid and no other work types */
  unpaidEmployees: Scalars['Int'];
};

export type TenantConfiguration = {
  __typename?: 'TenantConfiguration';
  /** Whether or not auto incremented intellihr ids should be shown in the system. */
  autoIncrementIntellihrIdVisible: Scalars['Boolean'];
  /** List of business days the tenant considers as working days. */
  businessDays: BusinessDays;
  /** If Contract Offer Making is enabled for the tenant */
  contractsEnabled?: Maybe<Scalars['Boolean']>;
  /** The default country the tenant is based in. */
  country?: Maybe<Country>;
  /** The default currency the tenant uses. */
  currency?: Maybe<Currency>;
  /** The href of the custom ATS link in the navigation */
  customAtsLinkHref?: Maybe<Scalars['String']>;
  /** The label of the custom ATS link in the navigation */
  customAtsLinkLabel?: Maybe<Scalars['String']>;
  /** The href of the custom LMS link in the navigation */
  customLmsLinkHref?: Maybe<Scalars['String']>;
  /** The label of the custom LMS link in the navigation */
  customLmsLinkLabel?: Maybe<Scalars['String']>;
  /**
   * This value defines the first date when the data are considered valid for analytics. Data imported before this
   * date will not be analyzed.
   * When null, a date 12 months ago from now should be used.
   */
  dataStartDate?: Maybe<Scalars['DateTime']>;
  /** The tenant's configured default date format. */
  dateFormat: DateFormat;
  /** URL of tenant configured default profile cover image. */
  defaultProfileCoverImage: Scalars['String'];
  /** Whether non-sso logins should be disabled */
  disableNonSSOLogins: Scalars['Boolean'];
  /** Whether or not to enable email sending for the entire tenant */
  emailsEnabled: Scalars['Boolean'];
  /** Whether or not employee numbers should be shown in the system. */
  employeeNumberVisible: Scalars['Boolean'];
  /** Whether notifications are enabled globally for this tenant. */
  globalNotificationsEnabled: Scalars['Boolean'];
  /** Whether or not goals have to be approved before being active. */
  goalApprovalProcessEnabled: Scalars['Boolean'];
  /** Whether or not goals can have a weight value. */
  goalWeightEnabled: Scalars['Boolean'];
  /** The href of the Humanforce Payroll link */
  hfPayrollLinkHref?: Maybe<Scalars['String']>;
  /** Whether or not contract signature saving is enabled for the tenant */
  isContractSignatureSavingEnabled?: Maybe<Scalars['Boolean']>;
  /** Whether a link to a custom ATS in the navigation should be shown */
  isCustomAtsLinkEnabled?: Maybe<Scalars['Boolean']>;
  /** Whether a link to a custom LMS in the navigation should be shown */
  isCustomLmsLinkEnabled?: Maybe<Scalars['Boolean']>;
  /** Whether a link to the Humanforce Payroll system in the navigation should be shown */
  isHfPayrollLinkEnabled?: Maybe<Scalars['Boolean']>;
  /** Whether or not probation feature is enabled for the tenant */
  isJobProbationEnabled: Scalars['Boolean'];
  /** Whether multi-factor authentication is required for all users to login to the system */
  isMfaEnforced?: Maybe<Scalars['Boolean']>;
  /** If the organisation chart can be shared to people that are not logged into the platform */
  isOrganisationChartShareable?: Maybe<Scalars['Boolean']>;
  /** When true the system will display a new field that is the total annual salary multiplied by the FTE */
  isProratedSalaryVisible: Scalars['Boolean'];
  /** Whether a link to the WFM system in the navigation should be shown */
  isWfmLinkEnabled?: Maybe<Scalars['Boolean']>;
  /** When true the system will allow job approvals */
  jobChangeApprovals?: Maybe<Scalars['Boolean']>;
  /**
   * The language this tenant should use.
   * This is a language code (en, fr-CA etc.)
   */
  language: Scalars['String'];
  /**
   * The tenant's levity level.
   * Depending on the level, certain functionalities can be restricted as they can
   * be potentially used inappropriately (eg. gif picker)
   */
  levityLevel: LevityLevel;
  /** URL of the tenant configured login splash image. */
  loginSplashImage?: Maybe<Scalars['String']>;
  /** Minimum length that user account passwords have to be. */
  minPasswordLength: Scalars['Int'];
  /** Whether or not the Organisation Objectives feature is enabled for the tenant */
  orgObjectivesEnabled: Scalars['Boolean'];
  /** The code that must be given by a person not logged into the platform to access the organisation chart */
  organisationChartToken?: Maybe<Scalars['String']>;
  /** The name format used for display names in the system. */
  personDisplayNameFormat: Scalars['String'];
  /** The plan which the tenant is currently paying for. */
  plan: Plan;
  /** Probation length in months. */
  probationLength?: Maybe<Scalars['Int']>;
  /** The number of decimal places to calculate and display pay packages */
  remunerationScheduleDecimalPlaces?: Maybe<Scalars['Int']>;
  /** Whether or not to send email notfications to supervisors when a goal is created */
  sendGoalCreatedEmailToSupervisor: Scalars['Boolean'];
  /** DNS name of the tenant. */
  tenantAlias?: Maybe<Scalars['String']>;
  /**
   * Tenant's default send / start time for Pulses and Workflows ('09:00' for example).
   * Defaults to the public schema value if not set on the tenant level.
   */
  tenantDefaultSendTime: Scalars['String'];
  /** Name of the tenant to display in the from: field of emails. */
  tenantEmailFromName?: Maybe<Scalars['String']>;
  /** URL of the tenant configured logo image. */
  tenantLogo?: Maybe<Scalars['String']>;
  /** Human readable name of the tenant. */
  tenantName: Scalars['String'];
  /** Number of days supervisors should have permission over upcoming subordinates for before they begin. */
  tenantSupervisorEarlyAccessPeriod: Scalars['Int'];
  /** The tenant's configured default time format. */
  timeFormat: TimeFormat;
  /**
   * The tenant's configured default timezone.
   * This is a string as returned from PHP's official list of timezones (see https://www.php.net/manual/en/timezones.php)
   */
  timezone: Scalars['Timezone'];
  /** The href of the WFM tenant link */
  wfmTenantLinkHref?: Maybe<Scalars['String']>;
  /** Whether the tenant is currently connected to xero or not. */
  xeroConnected: Scalars['Boolean'];
};

export type TenantHeadcount = {
  __typename?: 'TenantHeadcount';
  /**
   * The above counts grouped by business entity in the tenant, including zeroes.
   * Employees with multiple jobs across business entities will go into the entity for their primary job.
   * Only present when the current tenant is in the "includeBusinessEntityTenants" option.
   */
  businessEntityHeadcounts: Array<TenantBusinessEntityHeadcount>;
  /** Count any active staff with any job with a work type of casual */
  casualEmployees: Scalars['Int'];
  /** Count any active staff with any job with work type of fixed/independent contract and not permanent */
  contractors: Scalars['Int'];
  /** Count any active staff with any job of work type that is not unpaid */
  paidEmployees: Scalars['Int'];
  /** Count any active staff with any job with a work type of permanent */
  permanentEmployees: Scalars['Int'];
  /** Name of the tenant the below set of headcounts pertain to */
  tenantName: Scalars['String'];
  /** Count any active staff with any job with only a work type of unpaid and no other work types */
  unpaidEmployees: Scalars['Int'];
};

export type TenantPermissionConfiguration = {
  __typename?: 'TenantPermissionConfiguration';
  /** Manager Access Configuration */
  manager?: Maybe<ManagerAccess>;
};

export type TenantPermissionConfigurationRecord = {
  __typename?: 'TenantPermissionConfigurationRecord';
  /** Configuration Object */
  config: TenantPermissionConfiguration;
  /** The date when the record was created */
  createdAt: Scalars['DateTime'];
  /** The date when the record was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TenantUsedCurrency = {
  __typename?: 'TenantUsedCurrency';
  /** Currency code */
  code: Scalars['String'];
  /** Number of decimal places according to ISO 4217 */
  decimalPlaces?: Maybe<Scalars['Int']>;
  /** The time the currency is first used at */
  firstUsedAt: Scalars['String'];
  /** The source the currency is first used by */
  firstUsedBy: Scalars['String'];
  id: Scalars['ID'];
  /** Long name for the currency */
  name: Scalars['String'];
  /** Symbol for the currency */
  symbol: Scalars['String'];
};

export enum TfnExemptionType {
  NOTQUOTED = 'NOTQUOTED',
  PENDING = 'PENDING',
  PENSIONER = 'PENSIONER',
  UNDER18 = 'UNDER18'
}

export enum TimeFormat {
  HOURS_12 = 'HOURS_12',
  HOURS_24 = 'HOURS_24'
}

export type Title = {
  __typename?: 'Title';
  id: Scalars['ID'];
  /** If the title has been archived */
  isArchived: Scalars['Boolean'];
  /** If the title is enabled or not */
  isEnabled: Scalars['Boolean'];
  /** If the title is currently in use by an existing person */
  isInUse: Scalars['Boolean'];
  /** The name of the title */
  name: Scalars['String'];
  /** How many people are using this title */
  personCount: Scalars['Int'];
  /**
   * The sort order of the title
   * @deprecated This does not exist anymore and always returns 0
   */
  sortOrder: Scalars['Int'];
};

export type TitleCreateInput = {
  /** The name of title to create. This must be unique among all lowercase names */
  name: Scalars['String'];
};

export type TitleCreateResponse = {
  __typename?: 'TitleCreateResponse';
  /** The title that was created */
  title: Title;
};

export type TitleDeleteResponse = {
  __typename?: 'TitleDeleteResponse';
  /** The ID of the title that was deleted */
  id: Scalars['ID'];
};

export type TitleFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type TitleUpdateInput = {
  /** The id of title to update */
  id: Scalars['ID'];
  /** Whether the title is archived or not */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Whether the title is enabled or not */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name to update to. This must be unique among all lowercase names */
  name?: InputMaybe<Scalars['String']>;
};

export type TitleUpdateResponse = {
  __typename?: 'TitleUpdateResponse';
  /** The title that was updated */
  title: Title;
};

export type Training = {
  __typename?: 'Training';
  /** The completion date of the training */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** The coordinator person that belong to this training, or null in cases where this is invalid */
  coordinatorPerson?: Maybe<Person>;
  /** The cost of the training */
  cost?: Maybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrency: Currency;
  /** When training was created */
  createdAt: Scalars['DateTime'];
  /** The person that created this training */
  createdByPerson?: Maybe<Person>;
  /** Who (person) created the training record */
  createdByPersonId?: Maybe<Scalars['ID']>;
  /** Who (user) created the training record */
  createdByUserId?: Maybe<Scalars['ID']>;
  /** Custom fields for this training */
  customFieldValues: Array<CustomFieldValue>;
  /** Lists the documents uploaded to this training */
  documents: Array<Document>;
  /** The hour of the training */
  hours?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The job that belong to this training, or null for old training records without jobs */
  job?: Maybe<Job>;
  /** The number of the training */
  name: Scalars['String'];
  /** The person that belong to this training */
  person: Person;
  /** The training provider that belong to this training */
  provider: TrainingProvider;
  /** The current status of this training */
  status: TrainingStatus;
  /**
   * The cost of the training, including salary costs based on the hours spent and
   * the jobs remuneration package, in the tenant currency. Returns null if the
   * training hours are greater than zero, and the user does not have remuneration
   * view permission.
   */
  totalCostInTenantCurrency?: Maybe<Scalars['String']>;
  /** The training type that belong to this training */
  type: TrainingType;
  /** When training was updated */
  updatedAt: Scalars['DateTime'];
};


export type TrainingcustomFieldValuesArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type TrainingCreateAdditionalPersonJobsInput = {
  jobId?: InputMaybe<Scalars['ID']>;
  personId: Scalars['ID'];
};

export type TrainingCreateCustomFieldsInput = {
  apiName: Scalars['String'];
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type TrainingCreateInput = {
  /** A list of extra people/jobs to create the training on */
  additionalPersonJobIds?: InputMaybe<Array<TrainingCreateAdditionalPersonJobsInput>>;
  /** The completion date of the training */
  completionDate: Scalars['String'];
  /** The training coordinator person id that belongs to this training */
  coordinatorPersonId?: InputMaybe<Scalars['ID']>;
  /** The cost of the training */
  cost?: InputMaybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** Custom field values to save for this training */
  customFieldValues?: InputMaybe<Array<TrainingCreateCustomFieldsInput>>;
  /** The hours the training took */
  hours?: InputMaybe<Scalars['String']>;
  /** The ID of the job to which the training belongs to - if this isn't provided, the primary job for the person will be used */
  jobId?: InputMaybe<Scalars['ID']>;
  /** The name of the training */
  name: Scalars['String'];
  /** The ID of the person to whom the training belongs to */
  personId: Scalars['ID'];
  /**
   * The training provider id that belongs to this training - if this isn't
   * provided, the default Training Provider will be used
   */
  providerId?: InputMaybe<Scalars['ID']>;
  /** The training type id that belongs to this training - if this isn't provided, the default Training Type will be used */
  typeId?: InputMaybe<Scalars['ID']>;
};

export type TrainingCreateInputV2 = {
  /** A list of extra people/jobs to create the training on */
  additionalPersonJobIds?: InputMaybe<Array<TrainingCreateAdditionalPersonJobsInput>>;
  /** The completion/end date of the training */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** The training coordinator person id that belongs to this training */
  coordinatorPersonId?: InputMaybe<Scalars['ID']>;
  /** The cost of the training */
  cost?: InputMaybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** Custom field values to save for this training */
  customFieldValues?: InputMaybe<Array<TrainingCreateCustomFieldsInput>>;
  /** The hours the training took */
  hours?: InputMaybe<Scalars['String']>;
  /** The ID of the job to which the training belongs to - if this isn't provided, the primary job for the person will be used */
  jobId?: InputMaybe<Scalars['ID']>;
  /** The name of the training */
  name: Scalars['String'];
  /** The ID of the person to whom the training belongs to */
  personId: Scalars['ID'];
  /**
   * The training provider id that belongs to this training - if this isn't
   * provided, the default Training Provider will be used
   */
  providerId?: InputMaybe<Scalars['ID']>;
  /** The status of the training */
  statusId: Scalars['ID'];
  /** The training type id that belongs to this training - if this isn't provided, the default Training Type will be used */
  typeId?: InputMaybe<Scalars['ID']>;
};

export type TrainingCreateResponse = {
  __typename?: 'TrainingCreateResponse';
  trainings: Array<Training>;
  workflowEventId?: Maybe<Scalars['ID']>;
  workflowEventTriggers: Array<WorkflowEventTrigger>;
};

export type TrainingCustomFieldNumberStatistic = {
  __typename?: 'TrainingCustomFieldNumberStatistic';
  customFieldDefinition: CustomFieldDefinition;
  totalSum: Scalars['Float'];
};

export type TrainingDeleteResponse = {
  __typename?: 'TrainingDeleteResponse';
  id: Scalars['ID'];
};

export type TrainingDocumentCreateInput = {
  /** The extension of the document */
  extension: Scalars['String'];
  /** The name of the document */
  filename: Scalars['String'];
  /** The mime type of the document */
  mime: Scalars['String'];
  /** The estimated size of the document in bytes */
  size: Scalars['Int'];
  /** The ID of the training to create the document for */
  trainingId: Scalars['ID'];
};

export type TrainingDocumentCreateResponse = {
  __typename?: 'TrainingDocumentCreateResponse';
  document: Document;
  /** A URL to upload the document to */
  presignedUrl: Scalars['String'];
};

export type TrainingDocumentDeleteResponse = {
  __typename?: 'TrainingDocumentDeleteResponse';
  /** The ID of the deleted training document */
  id: Scalars['ID'];
};

export type TrainingDocumentUpdateInput = {
  /** The name of the document */
  displayName?: InputMaybe<Scalars['String']>;
  /** The ID of the document */
  id: Scalars['ID'];
  /** The current status of the upload of this document */
  uploadStatus?: InputMaybe<DocumentUploadStatus>;
};

export type TrainingDocumentUpdateResponse = {
  __typename?: 'TrainingDocumentUpdateResponse';
  document: Document;
};

export type TrainingProvider = {
  __typename?: 'TrainingProvider';
  id: Scalars['ID'];
  /** Whether the training provider is the default. Only one training provider is the default */
  isDefault: Scalars['Boolean'];
  /** Whether the training provider is enabled for viewing in dropdowns */
  isEnabled: Scalars['Boolean'];
  /** The human-readable name of the training provider */
  name: Scalars['String'];
  /**
   * The sort order of the training provider
   * @deprecated Training Providers are now sorted alphabetically
   */
  sortOrder: Scalars['Int'];
  trainingUsageCount: Scalars['Int'];
};

export type TrainingProviderCreateInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TrainingProviderCreateResponse = {
  __typename?: 'TrainingProviderCreateResponse';
  trainingProvider?: Maybe<TrainingProvider>;
};

export type TrainingProviderDeleteResponse = {
  __typename?: 'TrainingProviderDeleteResponse';
  id: Scalars['ID'];
};

export type TrainingProviderFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type TrainingProviderUpdateInput = {
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TrainingProviderUpdateResponse = {
  __typename?: 'TrainingProviderUpdateResponse';
  trainingProvider?: Maybe<TrainingProvider>;
};

export type TrainingStatisticsResponse = {
  __typename?: 'TrainingStatisticsResponse';
  customFieldStatistics: Array<TrainingCustomFieldNumberStatistic>;
  sessionCount: Scalars['Int'];
  totalCost?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  trainingCost: Scalars['Float'];
};

export type TrainingStatus = {
  __typename?: 'TrainingStatus';
  id: Scalars['ID'];
  /** The human-readable name of the training status */
  name: Scalars['String'];
  /** The sort order of the training status */
  sortOrder: Scalars['Int'];
};

export type TrainingType = {
  __typename?: 'TrainingType';
  id: Scalars['ID'];
  /** Whether the training type is the default. Only one training type is the default */
  isDefault: Scalars['Boolean'];
  /** Whether the training type is enabled for viewing in dropdowns */
  isEnabled: Scalars['Boolean'];
  /** The human-readable name of the training type */
  name: Scalars['String'];
  /**
   * The sort order of the training type
   * @deprecated Training Types are now sorted alphabetically
   */
  sortOrder: Scalars['Int'];
  trainingUsageCount: Scalars['Int'];
};

export type TrainingTypeCreateInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TrainingTypeCreateResponse = {
  __typename?: 'TrainingTypeCreateResponse';
  trainingType?: Maybe<TrainingType>;
};

export type TrainingTypeDeleteResponse = {
  __typename?: 'TrainingTypeDeleteResponse';
  id: Scalars['ID'];
};

export type TrainingTypeFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type TrainingTypeUpdateInput = {
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TrainingTypeUpdateResponse = {
  __typename?: 'TrainingTypeUpdateResponse';
  trainingType?: Maybe<TrainingType>;
};

export type TrainingUpdateCustomFieldsInput = {
  apiName: Scalars['String'];
  value?: InputMaybe<Scalars['CustomFieldValueValue']>;
};

export type TrainingUpdateInput = {
  /** The completion/end date of the training */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** The training coordinator person id that belongs to this training */
  coordinatorPersonId?: InputMaybe<Scalars['ID']>;
  /** The cost of the training */
  cost?: InputMaybe<Scalars['String']>;
  /** The training cost currency for this training */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** Custom field values to save for this training */
  customFieldValues?: InputMaybe<Array<TrainingUpdateCustomFieldsInput>>;
  /** The hours the training took */
  hours?: InputMaybe<Scalars['String']>;
  /** The ID of the training to update */
  id: Scalars['ID'];
  /** The ID of the job to which the training belongs to */
  jobId?: InputMaybe<Scalars['ID']>;
  /** The name of the training */
  name?: InputMaybe<Scalars['String']>;
  /** The training provider id that belongs to this training */
  providerId?: InputMaybe<Scalars['ID']>;
  /** The status of the training */
  statusId?: InputMaybe<Scalars['ID']>;
  /** The training type id that belongs to this training */
  typeId?: InputMaybe<Scalars['ID']>;
};

export type TrainingUpdateResponse = {
  __typename?: 'TrainingUpdateResponse';
  training: Training;
  workflowEventId?: Maybe<Scalars['ID']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  ONLY = 'ONLY',
  /** Return both trashed and non-trashed results. */
  WITH = 'WITH',
  /** Only return non-trashed results. */
  WITHOUT = 'WITHOUT'
}

export type TriggerScheduledFormResponse = {
  __typename?: 'TriggerScheduledFormResponse';
  id: Scalars['ID'];
};

export type TurnoverReason = {
  __typename?: 'TurnoverReason';
  id: Scalars['ID'];
  /** If this turnover reason should be shown when finalising a job */
  isEnabled: Scalars['Boolean'];
  /** If this turnover reason is currently being used by an undeleted job */
  isInUse: Scalars['Boolean'];
  /** The name of the turnover reason */
  name: Scalars['String'];
  /** The enum of the turnover type this turnover reason belongs to */
  turnoverType: TurnoverType;
};

export type TurnoverReasonCreateInput = {
  /** The name of the turnover reason */
  name: Scalars['String'];
  /** The turnover type the reason will be shown for */
  turnoverType: TurnoverType;
};

export type TurnoverReasonCreateResponse = {
  __typename?: 'TurnoverReasonCreateResponse';
  turnoverReason?: Maybe<TurnoverReason>;
};

export type TurnoverReasonDeleteResponse = {
  __typename?: 'TurnoverReasonDeleteResponse';
  id: Scalars['ID'];
};

export type TurnoverReasonSearchObject = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  turnover_type_id?: InputMaybe<Scalars['ID']>;
};

export type TurnoverReasonUpdateInput = {
  id: Scalars['ID'];
  /** If this turnover reason should be shown when finalising a job */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The name of the turnover reason */
  name?: InputMaybe<Scalars['String']>;
};

export type TurnoverReasonUpdateResponse = {
  __typename?: 'TurnoverReasonUpdateResponse';
  turnoverReason?: Maybe<TurnoverReason>;
};

export enum TurnoverType {
  INVOLUNTARY = 'INVOLUNTARY',
  UNKNOWN = 'UNKNOWN',
  VOLUNTARY = 'VOLUNTARY'
}

export type UndrawBanner = {
  __typename?: 'UndrawBanner';
  /** The ID of the banner */
  id: Scalars['ID'];
  /** The s3 key associated with the banner */
  key: Scalars['String'];
  /** The title of the banner */
  name: Scalars['String'];
  /** The percentage that is offsetted from the top */
  offset: Scalars['Float'];
};

export type UnsuppressAndRescheduleMessageInput = {
  /** The ID of the message to unsuppress and reschedule */
  id: Scalars['ID'];
};

export type UnsuppressAndRescheduleMessageResponse = {
  __typename?: 'UnsuppressAndRescheduleMessageResponse';
  /** The ID of the new re-scheduled message */
  id?: Maybe<Scalars['ID']>;
  /** The result of the request */
  status: Scalars['String'];
};

export type UpcomingPulseModelOrderBy = {
  column?: InputMaybe<UpcomingPulseModelOrderByColumn>;
  direction?: InputMaybe<SortOrder>;
};

export enum UpcomingPulseModelOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  SEND_DATE = 'SEND_DATE'
}

export type UpdateAnonymousPulseModelInput = {
  /** The id of new anonymous form design */
  anonymousFormDesignId?: InputMaybe<Scalars['ID']>;
  /** The id of pulse model to edit */
  id: Scalars['ID'];
  /** The new name for the pulse */
  name?: InputMaybe<Scalars['String']>;
  /** The new set of pulse filters */
  newFilters?: InputMaybe<AnonymousPulseFilters>;
  /** The new send date time for pulse, the new start date time for pulse recurrence */
  sendAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateAssignedSkillForPersonInput = {
  personId: Scalars['ID'];
  skillId: Scalars['ID'];
  skillLevelId: Scalars['ID'];
};

export type UpdateAssignedSkillForPersonResponse = {
  __typename?: 'UpdateAssignedSkillForPersonResponse';
  assignedSkills: Array<AssignedSkill>;
};

export type UpdateBudgetGroup = {
  amount: UpdateBudgetGroupAmount;
  children?: InputMaybe<Array<InputMaybe<UpdateBudgetGroup>>>;
  directChildId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  jobCount: Scalars['Int'];
  name: Scalars['String'];
  ownerPersonId?: InputMaybe<Scalars['String']>;
  split?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBudgetGroupAmount = {
  base: Scalars['Float'];
  bonus: Scalars['Float'];
};

export type UpdateBudgetGroups = {
  children: Array<UpdateBudgetGroup>;
  id: Scalars['String'];
  planId: Scalars['String'];
  total: UpdateBudgetGroupAmount;
};

export type UpdateCompensationPlanInput = {
  budgetAllocated: Scalars['Float'];
  budgetAmount: Scalars['Float'];
  budgetRemaining: Scalars['Float'];
  compensationPlanJobs: Array<InputMaybe<UpdateCompensationPlanJobInput>>;
  /** The id of compensation plan to edit */
  id: Scalars['ID'];
  /** The title of this compensation plan */
  title: Scalars['String'];
};

export type UpdateCompensationPlanJobInput = {
  adjustmentAmount: Scalars['Float'];
  adjustmentPercent: Scalars['Float'];
  /** The id of compensation plan job to edit */
  id: Scalars['ID'];
  newSalary: Scalars['Float'];
};

export type UpdateCompensationPlanResponse = {
  __typename?: 'UpdateCompensationPlanResponse';
  compensationPlan: CompensationPlan;
};

export type UpdateCompensationPlannerBudgetGroupsAmountsInput = {
  /** Base amount */
  base: Scalars['Float'];
  /** Bonus amount */
  bonus: Scalars['Float'];
};

export type UpdateCompensationPlannerBudgetGroupsBudgetInput = {
  /** Budgets allocated amounts */
  allocated: UpdateCompensationPlannerBudgetGroupsAmountsInput;
  /** Budgets children */
  children?: InputMaybe<Array<InputMaybe<UpdateCompensationPlannerBudgetGroupsBudgetInput>>>;
  /** Budget id */
  id: Scalars['String'];
  /** Is the budget split? */
  isSplit?: InputMaybe<Scalars['Boolean']>;
  /** Budget owner person id */
  ownerPersonId?: InputMaybe<Scalars['String']>;
};

export type UpdateCompensationPlannerBudgetGroupsInput = {
  /** Budget children */
  children: Array<UpdateCompensationPlannerBudgetGroupsBudgetInput>;
  /** Master budget id */
  id: Scalars['String'];
  /** Plan id */
  planId: Scalars['String'];
  /** Budget totals */
  total: UpdateCompensationPlannerBudgetGroupsAmountsInput;
};

export type UpdateCompensationPlannerBudgetGroupsResponseV2 = {
  __typename?: 'UpdateCompensationPlannerBudgetGroupsResponseV2';
  /** Compensation planner Plan */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlanV2>;
};

export type UpdateCompensationPlannerBudgetsResponse = {
  __typename?: 'UpdateCompensationPlannerBudgetsResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
};

export type UpdateCompensationPlannerConfigEligibilityRulesInput = {
  /** Array of eligibility rules */
  eligibilityRules?: InputMaybe<CompensationPlannerConfigEligibilityRulesInput>;
  /** Plan ID */
  planId: Scalars['String'];
};

export type UpdateCompensationPlannerConfigEligibilityRulesResponse = {
  __typename?: 'UpdateCompensationPlannerConfigEligibilityRulesResponse';
  /** Compensation planner Plan */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlanV2>;
};

export type UpdateCompensationPlannerConfigInput = {
  /** Array of column keys in order */
  columns: Array<Scalars['String']>;
  /** The compensation planner plan ID */
  id: Scalars['String'];
};

export type UpdateCompensationPlannerConfigJobDatagridColumnsInput = {
  /** Array of column keys in order */
  jobDatagridColumns: Array<Scalars['String']>;
  /** Plan ID */
  planId: Scalars['String'];
};

export type UpdateCompensationPlannerConfigJobDatagridColumnsResponse = {
  __typename?: 'UpdateCompensationPlannerConfigJobDatagridColumnsResponse';
  /** Compensation planner Plan */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlanV2>;
};

export type UpdateCompensationPlannerConfigResponse = {
  __typename?: 'UpdateCompensationPlannerConfigResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
};

export type UpdateCompensationPlannerEligibilityRulesInput = {
  eligibilityRules?: InputMaybe<CompensationPlannerEligibilityRulesInput>;
  planId: Scalars['String'];
};

export type UpdateCompensationPlannerEligibilityRulesResponse = {
  __typename?: 'UpdateCompensationPlannerEligibilityRulesResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
};

export type UpdateCompensationPlannerJobInput = {
  adjustment_amount_base: Scalars['Float'];
  adjustment_amount_bonus: Scalars['Float'];
  budget_id: Scalars['String'];
  id: Scalars['String'];
  plan_id: Scalars['String'];
};

export type UpdateCompensationPlannerJobInputV2 = {
  /** The adjusted dollar value of the base annual amount */
  adjustmentBaseAnnual?: InputMaybe<Scalars['Float']>;
  /** The adjusted dollar value of the base hourly amount */
  adjustmentBaseHourly?: InputMaybe<Scalars['Float']>;
  /** The adjusted dollar value of the bonus amount */
  adjustmentBonus?: InputMaybe<Scalars['Float']>;
  /** Budget id */
  budgetId: Scalars['String'];
  /** Job id */
  id: Scalars['String'];
  /** Source id */
  sourceId: Scalars['String'];
};

export type UpdateCompensationPlannerJobResponse = {
  __typename?: 'UpdateCompensationPlannerJobResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerJob?: Maybe<CompensationPlannerJob>;
};

export type UpdateCompensationPlannerJobResponseV2 = {
  __typename?: 'UpdateCompensationPlannerJobResponseV2';
  /** Compensation planner Job */
  compensationPlannerJob?: Maybe<CompensationPlannerJobV2>;
};

export type UpdateCompensationPlannerPlanInput = {
  /** Plan id */
  id: Scalars['String'];
  /** Plan name */
  name: Scalars['String'];
};

export type UpdateCompensationPlannerPlanResponse = {
  __typename?: 'UpdateCompensationPlannerPlanResponse';
  /** @deprecated Moved to V2 model structure */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlan>;
};

export type UpdateCompensationPlannerPlanResponseV2 = {
  __typename?: 'UpdateCompensationPlannerPlanResponseV2';
  /** Compensation planner Plan */
  compensationPlannerPlan?: Maybe<CompensationPlannerPlanV2>;
};

export type UpdateCustomFieldDefinitionSelectOptionInput = {
  id?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
};

export type UpdateCustomFieldResponse = {
  __typename?: 'UpdateCustomFieldResponse';
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
};

export type UpdateCustomFieldSelectDefinitionInput = {
  options?: InputMaybe<Array<InputMaybe<UpdateCustomFieldDefinitionSelectOptionInput>>>;
};

export type UpdateFormDesignSettingsInput = {
  autoIgnorePeriod?: InputMaybe<Scalars['Int']>;
  defaultNotificationMethodId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  duePeriod?: InputMaybe<Scalars['Int']>;
  escalationPeriod?: InputMaybe<Scalars['Int']>;
  formCompletedRecipientIds: Array<Scalars['ID']>;
  formCompletedRecipientPeopleIds: Array<Scalars['ID']>;
  formDesignCategoryId?: InputMaybe<Scalars['ID']>;
  formRespondentTypeId: Scalars['ID'];
  formTypeId: Scalars['ID'];
  id: Scalars['ID'];
  jobRespondentIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  reminderPeriod?: InputMaybe<Scalars['Int']>;
  requiresLogin?: InputMaybe<Scalars['Boolean']>;
  respondentEmailAddresses: Array<Scalars['String']>;
  showTriggeredBy?: InputMaybe<Scalars['Boolean']>;
  triggerOnCompleteFormDesignId?: InputMaybe<Scalars['ID']>;
};

export type UpdateFormDesignSettingsResponse = {
  __typename?: 'UpdateFormDesignSettingsResponse';
  formDesign: FormDesign;
};

export type UpdateJobApprovalOrderInput = {
  approverType: ApproverType;
  businessUnitRepresentativeId?: InputMaybe<Scalars['ID']>;
  locationRepresentativeId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateJobApprovalOrdersInput = {
  jobApprovalOrders: Array<UpdateJobApprovalOrderInput>;
};

export type UpdateJobApprovalOrdersResponse = {
  __typename?: 'UpdateJobApprovalOrdersResponse';
  jobApprovalOrders: Array<JobApprovalOrder>;
};

export type UpdatePersoNameFormatInput = {
  /** Format of input to update person name format */
  personNameFormat: Scalars['String'];
};

export type UpdatePersonProfileCoverPictureFromTemporaryInput = {
  /** The coordinates to crop the image to */
  coordinates: PersonImageCropCoordinates;
  /** The id of the person to whom the picture belongs to */
  personId: Scalars['ID'];
};

export type UpdatePersonProfileCoverPictureFromTemporaryResponse = {
  __typename?: 'UpdatePersonProfileCoverPictureFromTemporaryResponse';
  person?: Maybe<Person>;
};

export type UpdatePersonProfilePictureFromTemporaryInput = {
  /** The coordinates to crop the image to */
  coordinates: PersonImageCropCoordinates;
  /** The id of the person to whom the picture belongs to */
  personId: Scalars['ID'];
  /** The degree to rotate the image from 0-359 */
  rotation: Scalars['Float'];
};

export type UpdatePersonProfilePictureFromTemporaryResponse = {
  __typename?: 'UpdatePersonProfilePictureFromTemporaryResponse';
  person?: Maybe<Person>;
};

export type UpdatePulseModelInput = {
  /** Pulse filters - Supporting custom fields */
  combinedFilters?: InputMaybe<Array<InputMaybe<Filter>>>;
  /** The id of new form design */
  formDesignId?: InputMaybe<Scalars['ID']>;
  /** The frequency of pulse recurrence */
  frequency?: InputMaybe<RecurringPulseFrequencyInput>;
  /** The id of pulse model to edit */
  id: Scalars['ID'];
  /** The new name for the pulse */
  name?: InputMaybe<Scalars['String']>;
  /** The pre-custom_fields supported pulse filters */
  newFilters?: InputMaybe<PulseFilters>;
  /** The new send date time for pulse, the new start date time for pulse recurrence */
  sendAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateScheduledFormInput = {
  /** The id of scheduled form to edit */
  id: Scalars['ID'];
  /** The new intended Notification Method. */
  intendedNotificationMethodId?: InputMaybe<Scalars['ID']>;
  /** The new intended respondent Email address. Required if intendedRespondentTypeId is of email address type. */
  intendedRespondentEmailAddress?: InputMaybe<Scalars['String']>;
  /** The new intended respondent Job. Required if intendedRespondentJobId is not null, and not an email address respondent type */
  intendedRespondentJobId?: InputMaybe<Scalars['ID']>;
  /** The new intended respondent type. */
  intendedRespondentTypeId?: InputMaybe<Scalars['ID']>;
  /** The issue date time for form */
  issueDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSkillDisciplineInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateSkillInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  isBusinessCritical: Scalars['Boolean'];
  name: Scalars['String'];
  /** Deprecated due to rename to skill discipline */
  skillCategoryId?: InputMaybe<Scalars['ID']>;
  skillDisciplineId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTenantConfigurationInput = {
  /** Whether or not auto incremented intellihr ids should be shown in the system. */
  autoIncrementIntellihrIdVisible?: InputMaybe<Scalars['Boolean']>;
  /** List of business days the tenant considers as working days. */
  businessDays?: InputMaybe<BusinessDaysInput>;
  /** If Contract Offer Making is enabled for the tenant */
  contractsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The default country the tenant is based in. */
  countryId?: InputMaybe<Scalars['ID']>;
  /** The default currency the tenant uses. */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** The href of the custom ATS link in the navigation */
  customAtsLinkHref?: InputMaybe<Scalars['String']>;
  /** The label of the custom ATS link in the navigation */
  customAtsLinkLabel?: InputMaybe<Scalars['String']>;
  /** The href of the custom LMS link in the navigation */
  customLmsLinkHref?: InputMaybe<Scalars['String']>;
  /** The label of the custom LMS link in the navigation */
  customLmsLinkLabel?: InputMaybe<Scalars['String']>;
  /**
   * This value defines the first date when the data are considered valid for analytics. Data imported before this
   * date will not be analyzed.
   * When null, a date 12 months ago from now should be used.
   */
  dataStartDate?: InputMaybe<Scalars['DateTime']>;
  /** The tenant's configured default date format. */
  dateFormat?: InputMaybe<DateFormat>;
  /** Whether non-sso logins should be disabled */
  disableNonSSOLogins?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not to enable email sending for the entire tenant */
  emailsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not employee numbers should be shown in the system. */
  employeeNumberVisible?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not goals have to be approved before being active. */
  goalApprovalProcessEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not goals can have a weight value. */
  goalWeightEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The href of the Humanforce Payroll link */
  hfPayrollLinkHref?: InputMaybe<Scalars['String']>;
  /** Whether or not contract signature saving is enabled for the tenant */
  isContractSignatureSavingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether a link to a custom ATS in the navigation should be shown */
  isCustomAtsLinkEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether a link to a custom LMS in the navigation should be shown */
  isCustomLmsLinkEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether a link to the Humanforce Payroll system in the navigation should be shown */
  isHfPayrollLinkEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not probation feature is enabled for the tenant */
  isJobProbationEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Whether multi-factor authentication is required for all users to login to the system */
  isMfaEnforced?: InputMaybe<Scalars['Boolean']>;
  /** If the organisation chart can be shared to people that are not logged into the platform */
  isOrganisationChartShareable?: InputMaybe<Scalars['Boolean']>;
  /** When true the system will display a new field that is the total annual salary multiplied by the FTE */
  isProratedSalaryVisible?: InputMaybe<Scalars['Boolean']>;
  /** Whether a link to the WFM system in the navigation should be shown */
  isWfmLinkEnabled?: InputMaybe<Scalars['Boolean']>;
  /** When true the system will allow job approvals */
  jobChangeApprovals?: InputMaybe<Scalars['Boolean']>;
  /**
   * The language this tenant should use.
   * This is a language code (en, fr-CA etc.)
   */
  language?: InputMaybe<Scalars['String']>;
  /**
   * The tenant's levity level.
   * Depending on the level, certain functionalities can be restricted as they can
   * be potentially used inappropriately (eg. gif picker)
   */
  levityLevel?: InputMaybe<LevityLevel>;
  /** Whether or not the Organisation Objectives feature is enabled for the tenant */
  orgObjectivesEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The code that must be given by a person not logged into the platform to access the organisation chart */
  organisationChartToken?: InputMaybe<Scalars['String']>;
  /** The name format used for display names in the system. */
  personDisplayNameFormat?: InputMaybe<Scalars['String']>;
  /** The ID of the plan the tenant is currently paying for. */
  planId?: InputMaybe<Scalars['ID']>;
  /** Probation length in months. */
  probationLength?: InputMaybe<Scalars['Int']>;
  /** The number of decimal places to calculate and display pay packages */
  remunerationScheduleDecimalPlaces?: InputMaybe<Scalars['Int']>;
  /** Whether or not to send email notfications to supervisors when a goal is created */
  sendGoalCreatedEmailToSupervisor?: InputMaybe<Scalars['Boolean']>;
  /** DNS name of the tenant. */
  tenantAlias?: InputMaybe<Scalars['String']>;
  /**
   * Tenant's default send / start time for Pulses and Workflows ('09:00' for example).
   * Defaults to the public schema value if not set on the tenant level.
   */
  tenantDefaultSendTime?: InputMaybe<Scalars['String']>;
  /** Name of the tenant to display in the from: field of emails. */
  tenantEmailFromName?: InputMaybe<Scalars['String']>;
  /** Human readable name of the tenant. */
  tenantName?: InputMaybe<Scalars['String']>;
  /** Number of days supervisors should have permission over upcoming subordinates for before they begin. */
  tenantSupervisorEarlyAccessPeriod?: InputMaybe<Scalars['Int']>;
  /** The tenant's configured default time format. */
  timeFormat?: InputMaybe<TimeFormat>;
  /**
   * The tenant's configured default timezone.
   * This is a string as returned from PHP's official list of timezones (see https://www.php.net/manual/en/timezones.php)
   */
  timezone?: InputMaybe<Scalars['Timezone']>;
  /** The href of the WFM tenant link */
  wfmTenantLinkHref?: InputMaybe<Scalars['String']>;
};

export type UpdateUserConfigurationInput = {
  /** The user's chosen date format, falling back to the tenant default if not configured. */
  dateFormat?: InputMaybe<DateFormat>;
  /**
   * The language this individual user should use, or null to set this back to the tenant default.
   * This is a language code (en, fr-CA etc.)
   */
  language?: InputMaybe<Scalars['String']>;
  /** The user's chosen time format, falling back to the tenant default if not configured. */
  timeFormat?: InputMaybe<TimeFormat>;
  /**
   * The user's local timezone, falling back to the tenant default if not present.
   * When dates are given in the UI they will be displayed in this timezone.
   * This is a string as returned from PHP's official list of timezones (see https://www.php.net/manual/en/timezones.php)
   */
  timezone?: InputMaybe<Scalars['Timezone']>;
};

export type UpdateWorkflowInput = {
  /** The description of the workflow */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The number of the workflow */
  name: Scalars['String'];
  /** The workflow event id that belong to this workflow */
  workflowEventId: Scalars['ID'];
};

export type UpdateWorkflowResponse = {
  __typename?: 'UpdateWorkflowResponse';
  workflow?: Maybe<Workflow>;
};

export type User = {
  __typename?: 'User';
  /** The date a user accepted the terms of service */
  acceptedTermsDate?: Maybe<Scalars['DateTime']>;
  /** List of enabled permission groups that the user can access */
  accessiblePermissionGroups: Array<PermissionGroup>;
  /**
   * When the user's account moves from restricted to full access.
   * Used during onboarding. If null the account gains full access immediately
   */
  activeFrom?: Maybe<Scalars['DateTime']>;
  /** The associated analytics permission */
  analyticsPermission?: Maybe<AnalyticsPermissionUserType>;
  /** when the user was created */
  createdAt: Scalars['DateTime'];
  /** When the user account was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** What date the user account will not be accessible or null */
  disableAt?: Maybe<Scalars['DateTime']>;
  /** If the user has accepted terms and conditions */
  hasAcceptedTerms: Scalars['Boolean'];
  id: Scalars['ID'];
  /** If the user is currently assuming a role */
  isAssumedAccount: Scalars['Boolean'];
  /** If the user is currently assuming a role as another user */
  isAssumingAccountAsAnotherUser: Scalars['Boolean'];
  /** Whether the user is enabled */
  isEnabled: Scalars['Boolean'];
  /** Flag to indicate if the current user is onboarding */
  isOnboarding: Scalars['Boolean'];
  /** when the user last logged in */
  lastLoggedInAt?: Maybe<Scalars['DateTime']>;
  /** If the user has MFA enabled */
  mfaEnabled: Scalars['Boolean'];
  /**
   * A list of permission checks for all functional permissions in the system.
   * Returns all the permissions on the system and checks if the user has them,
   * returning true for 'passes' if passing the check.
   * Note: performance of this will be poor if run across many users at once.
   */
  permissionChecks: Array<UserPermissionCheck>;
  /** The person that is associated with the user */
  person: Person;
  /** The person that is associated with the user */
  softDeletedPerson: Person;
  /** The sso id for the user */
  ssoId?: Maybe<Scalars['String']>;
  /** when the user wast last updated */
  updatedAt: Scalars['DateTime'];
  /** The junction entity between the user and the associated permission groups */
  userPermissionGroups: Array<UserPermissionGroup>;
  /** The username for the user */
  username: Scalars['String'];
};

export type UserAnalyticsConfiguration = {
  __typename?: 'UserAnalyticsConfiguration';
  nav?: Maybe<AnalyticsNavConfiguration>;
  pages?: Maybe<Scalars['JSONObject']>;
};

export type UserAnalyticsPermission = {
  __typename?: 'UserAnalyticsPermission';
  /** The permission configuration for the user */
  config: Scalars['JSONObject'];
  /** The date when the permission record was created */
  createdAt: Scalars['DateTime'];
  /** If the permission is derived from the legacy analytics permission */
  isLegacy: Scalars['Boolean'];
  /** The associated permission group */
  sourcePermissionGroup?: Maybe<PermissionGroup>;
  /** The source of the permission inherited from */
  sourceType?: Maybe<Scalars['String']>;
  /** The date when the permission record was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The user */
  user: User;
};

export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  /** The user's chosen date format, falling back to the tenant default if not configured. */
  dateFormat: DateFormat;
  /**
   * The language this individual user should use, falling back to the tenant default if not present.
   * This is a language code (en, fr-CA etc.)
   */
  language: Scalars['String'];
  /** The user's chosen date format, falling back to the tenant default if not configured. */
  timeFormat?: Maybe<TimeFormat>;
  /**
   * The user's local timezone, falling back to the tenant default if not present.
   * When dates are given in the UI they will be displayed in this timezone.
   * This is a string as returned from PHP's official list of timezones (see https://www.php.net/manual/en/timezones.php)
   */
  timezone: Scalars['Timezone'];
};

export type UserCreateInput = {
  /**
   * When the user's account moves from restricted to full access.
   * Used during onboarding. If null the account gains full access immediately.
   */
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  /** If present, this person and optionally job will be hidden from onboarding */
  hideFromOnboarding?: InputMaybe<HideFromOnboardingInput>;
  /** Whether the user is enabled or disabled. Defaults to disabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * Onboarding setup.
   * Provides all the fields required to configure onboarding for that user
   */
  onboardingSetup?: InputMaybe<OnboardingSetupInput>;
  /**
   * The onboarding form that the user should complete ahead of their active from date.
   * Deprecate when removing the ONBOARDING_FORM
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
  /** The user's password. Required if passwordMethod set to MANUAL. */
  password?: InputMaybe<Scalars['String']>;
  /** The password method for the created user. */
  passwordMethod: UserCreatePasswordMethod;
  /**
   * The permission groups to assign to the user on creation.
   * Permission groups that are not accessible to the currently logged in user will not be assigned.
   */
  permissionGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The person ID to assign. */
  personId: Scalars['ID'];
  /**
   * Whether or not to send a welcome email to the new user.
   * If this is not provided it will fallback to default which is linked to the passwordMethod.
   * USER_SETS_PASSWORD: true
   * MANUAL: true
   * NO_PASSWORD: false
   */
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
  /** The SSO ID of the created user. */
  ssoId?: InputMaybe<Scalars['String']>;
  /** The username to assign. */
  username: Scalars['String'];
};

export enum UserCreatePasswordMethod {
  /** Supply a password, no primary email required. */
  MANUAL = 'MANUAL',
  /** Do not supply a password. If SSO is not enabled, user cannot login. */
  NO_PASSWORD = 'NO_PASSWORD',
  /** Requires primary email address. Generate a password token and send a welcome email to the user. */
  USER_SETS_PASSWORD = 'USER_SETS_PASSWORD'
}

export type UserCreateResponse = {
  __typename?: 'UserCreateResponse';
  /** The user that is created. */
  user: User;
};

export type UserDeleteInput = {
  /** The ID of the user to delete. */
  id: Scalars['ID'];
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  /** The ID of the user that was deleted. */
  id: Scalars['ID'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  /** The date the user was granted access */
  grantedAccessDate: Scalars['DateTime'];
  /** The user id */
  id: Scalars['ID'];
  /** The persons initials */
  initials: Scalars['String'];
  /** The name of the person */
  name: Scalars['String'];
  /** The person id asscoiated with the user */
  personId: Scalars['ID'];
  /** The person profile picture url */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** The user name for the person */
  username: Scalars['String'];
};

export type UserFilters = {
  isAssumedUser?: InputMaybe<BooleanFilter>;
  isEnabled?: InputMaybe<BooleanFilter>;
};

export type UserPermissionCheck = {
  __typename?: 'UserPermissionCheck';
  /** The human-readable description of the permission for this check */
  description: Scalars['String'];
  /** Whether the user has this permission or not */
  passes: Scalars['Boolean'];
  /** The ID of the permission for this check */
  permissionId: Scalars['ID'];
  /** The slug of the permission for this check */
  slug: Scalars['String'];
};

export type UserPermissionGroup = {
  __typename?: 'UserPermissionGroup';
  /** The date the permission group was assigned */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The associated permission group */
  permissionGroup: PermissionGroup;
  /** The associated user */
  user: User;
};

export type UserPermissionGroupFilters = {
  isAssumedUser?: InputMaybe<BooleanFilter>;
};

export type UserQuery = {
  __typename?: 'UserQuery';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** List of enabled permission groups that the user can access - this is required outside of the user object due to permission restrictions */
  accessiblePermissionGroups: Array<PermissionGroup>;
};


export type UserQueryaccessiblePermissionGroupsArgs = {
  id: Scalars['ID'];
};

export type UserUpdateInput = {
  /**
   * When the user's account moves from restricted to full access.
   * Used during onboarding. If null the account gains full access immediately.
   */
  activeFrom?: InputMaybe<Scalars['DateTime']>;
  /**
   * User disable at
   * If provided and null, removes disabled at from the user, otherwise updates it
   * If provided without isEnabled, then set isEnabled to appropriate value
   */
  disableAt?: InputMaybe<Scalars['DateTime']>;
  /** The ID of the user to update. */
  id: Scalars['ID'];
  /** Whether the user is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * Onboarding setup.
   * Provides all the fields required to configure onboarding for that user
   */
  onboardingSetup?: InputMaybe<OnboardingSetupInput>;
  /**
   * The onboarding form that the user should complete ahead of their active from date.
   * Deprecate when removing the ONBOARDING_FORM
   */
  onboardingTemplateId?: InputMaybe<Scalars['ID']>;
  /** The user's updated password. */
  password?: InputMaybe<Scalars['String']>;
  /**
   * When provided, updates the permission groups for this user.
   * Permission groups that are not accessible to the currently logged in user will not be assigned.
   */
  permissionGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The new person ID. */
  personId?: InputMaybe<Scalars['ID']>;
  /** The new SSO ID. */
  ssoId?: InputMaybe<Scalars['String']>;
  /** The new username. */
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateResponse = {
  __typename?: 'UserUpdateResponse';
  /** The user that is updated. */
  user: User;
};

export type ValueFilter = {
  equals?: InputMaybe<Scalars['String']>;
};

export type WebhookEvent = {
  __typename?: 'WebhookEvent';
  /** An example of the payload which will be sent to Webhooks that subscribe to this particular Webhook Event. */
  examplePayload: Scalars['JSON'];
  /**
   * The external name of the event.
   * The slug of the Webhook Event.
   * This is what should be sent to the UI
   */
  externalEventName: Scalars['String'];
  /** Current webhooks that are registered with this event */
  externalWebhooks?: Maybe<Array<Maybe<ExternalWebhook>>>;
  /**
   * The external name of the event.
   * The slug of the Webhook Event.
   * This is what should be sent to the UI
   * @deprecated(reason: "Use externalEventName")
   */
  external_event_name: Scalars['String'];
  id: Scalars['ID'];
  /** The internal name of the event. */
  internalEventName: Scalars['String'];
  /**
   * The internal name of the event.
   * @deprecated(reason: "Use internalEventName")
   */
  internal_event_name: Scalars['String'];
  /** The human readable unique event key for this Webhook Event. */
  name: Scalars['String'];
};

export type WorkClass = {
  __typename?: 'WorkClass';
  /** The hourly rate type based on the work type id */
  hourlyRateType: HourlyRateType;
  id: Scalars['ID'];
  /** If the work class is enabled for viewing in dropdowns in the system */
  isEnabled: Scalars['Boolean'];
  isInUse: Scalars['Boolean'];
  /** The display name of the work class */
  name: Scalars['String'];
  /** The order to show the work class in */
  sortOrder: Scalars['Int'];
  /** The associated work type this work class falls under */
  workType: WorkType;
};

export type WorkClassCreateInput = {
  hourlyRateTypeId?: InputMaybe<Scalars['ID']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  workTypeId: Scalars['ID'];
};

export type WorkClassCreateResponse = {
  __typename?: 'WorkClassCreateResponse';
  workClass: WorkClass;
};

export type WorkClassDeleteResponse = {
  __typename?: 'WorkClassDeleteResponse';
  id: Scalars['ID'];
};

export type WorkClassFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
  workTypeId?: InputMaybe<ValueFilter>;
};

export type WorkClassUpdateInput = {
  hourlyRateTypeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type WorkClassUpdateResponse = {
  __typename?: 'WorkClassUpdateResponse';
  workClass: WorkClass;
};

export type WorkRight = {
  __typename?: 'WorkRight';
  /** The name of the country the work right is valid in */
  country?: Maybe<Scalars['String']>;
  /** The ID of the country the work right is valid in */
  countryId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** The name of the work right */
  name: Scalars['String'];
  /** The order to display the work right */
  sortOrder: Scalars['Int'];
};

export type WorkRightFilters = {
  countryId?: InputMaybe<ValueFilter>;
};

export type WorkType = {
  __typename?: 'WorkType';
  id: Scalars['ID'];
  /** If the work type is enabled */
  isEnabled: Scalars['Boolean'];
  /** The customisable name of the work type */
  name: Scalars['String'];
  /** The order to show the work type in */
  sortOrder: Scalars['Int'];
  /** human readable static work type slug */
  type: WorkTypeSlug;
};

export type WorkTypeFilters = {
  isEnabled?: InputMaybe<BooleanFilter>;
};

export enum WorkTypeSlug {
  CASUAL_TEMPORARY = 'CASUAL_TEMPORARY',
  CONTRACT = 'CONTRACT',
  INDEPENDENT_CONTRACT = 'INDEPENDENT_CONTRACT',
  PERMANENT = 'PERMANENT',
  UNPAID = 'UNPAID'
}

export type Workflow = {
  __typename?: 'Workflow';
  /** The sort description of the workflow */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the workflow */
  name: Scalars['String'];
  /** The workflow event that belong to this workflow */
  workflowEvent?: Maybe<WorkflowEvent>;
  /** a list of workflow form design that belong to this workflow */
  workflowFormDesign: Array<WorkflowFormDesign>;
};

export type WorkflowEvent = {
  __typename?: 'WorkflowEvent';
  /** The action name of the workflow event */
  actionName: Scalars['String'];
  /** The sort description of the workflow event */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The name of the workflow event */
  name: Scalars['String'];
  /** The list of workflows that can be triggered by this event */
  workflows: Array<Workflow>;
};

/** Represents a potential trigger for a workflow event as a result from a mutation */
export type WorkflowEventTrigger = {
  __typename?: 'WorkflowEventTrigger';
  /** The job to do the workflow for */
  job?: Maybe<Job>;
  /** The offset date to display on the schedule workflow page */
  offsetDate: Scalars['DateTime'];
  /** The person to do the workflow for */
  person?: Maybe<Person>;
  /** The ID of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is the training id. */
  triggerId?: Maybe<Scalars['ID']>;
  /** The type of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is TRAINING. */
  triggerType?: Maybe<WorkflowEventTriggerTriggerType>;
  /** The workflow event this triggers */
  workflowEvent: WorkflowEvent;
};

/** Represents a potential trigger for a workflow event as a result from a mutation */
export type WorkflowEventTriggerGraphite = {
  __typename?: 'WorkflowEventTriggerGraphite';
  /** The job to do the workflow for */
  job?: Maybe<Job>;
  /** The offset date to display on the schedule workflow page */
  offsetDate: Scalars['DateTime'];
  /** The person to do the workflow for */
  person?: Maybe<Person>;
  /** The id of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is the training id. */
  triggerId?: Maybe<Scalars['ID']>;
  /** The type of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is TRAINING. */
  triggerType?: Maybe<WorkflowEventTriggerTriggerTypeGraphite>;
  /** The workflow event this triggers */
  workflowEvent: WorkflowEvent;
};

export enum WorkflowEventTriggerTriggerType {
  JOB = 'JOB',
  PERFORMANCE_IMPROVEMENT_PLAN_DIARY_NOTE = 'PERFORMANCE_IMPROVEMENT_PLAN_DIARY_NOTE',
  TRAINING = 'TRAINING'
}

export enum WorkflowEventTriggerTriggerTypeGraphite {
  JOB = 'JOB',
  PERFORMANCE_IMPROVEMENT_PLAN_DIARY_NOTE = 'PERFORMANCE_IMPROVEMENT_PLAN_DIARY_NOTE',
  TRAINING = 'TRAINING'
}

export type WorkflowFormDesign = {
  __typename?: 'WorkflowFormDesign';
  /** The days of the workflow form design */
  days: Scalars['Int'];
  /** The form design that belong to this workflow form design */
  formDesign: FormDesign;
  id: Scalars['ID'];
  /** The months of the workflow form design */
  months: Scalars['Int'];
  /** The workflow that belong to this workflow form design */
  workflow: Workflow;
};

export type WorkflowFormDesignInput = {
  /** The days of the workflow form design */
  days: Scalars['Int'];
  /** The form design id that belong to this workflow form design */
  formDesignId: Scalars['ID'];
  /** The months of the workflow form design */
  months: Scalars['Int'];
  /** The id for the workflow form design being updated; will create a new one if null/undefined */
  workflowFormDesignId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowInstance = {
  __typename?: 'WorkflowInstance';
  createdAt: Scalars['DateTime'];
  /** The person who created this Workflow Instance */
  createdByPerson?: Maybe<Person>;
  /** When the scheduled form was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** When the Workflow Event that created the Workflow Instance occurred */
  eventDate: Scalars['DateTime'];
  /** Form Instances relating to this Workflow Instance */
  formInstances?: Maybe<Array<Maybe<Form>>>;
  id: Scalars['ID'];
  /** The Scheduled Forms created by this Workflow Instance */
  scheduledForms?: Maybe<Array<ScheduledForm>>;
  /** The Job that the Workflow instance is about */
  subjectJob: Job;
  /** The Person that the Workflow instance is about */
  subjectPerson?: Maybe<Person>;
  /** The ID of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is the training id. */
  triggerId?: Maybe<Scalars['ID']>;
  /** The type of the thing which triggered this workflow event. E.g. for TRAINING_ADDED this is TRAINING. */
  triggerType?: Maybe<WorkflowEventTriggerTriggerType>;
  /** When the scheduled form was last updated */
  updatedAt: Scalars['DateTime'];
  /** The Workflow linked to this Workflow Instance */
  workflow: Workflow;
  /** The Workflow Event linked to this Workflow Instance */
  workflowEvent: WorkflowEvent;
};

export type WorkflowInstanceScheduledFormInput = {
  /** The id of form design */
  formDesignId: Scalars['ID'];
  /** The new intended Notification Method. */
  intendedNotificationMethodId?: InputMaybe<Scalars['ID']>;
  /** The new intended respondent Email address. Required if intendedRespondentTypeId is of email address type. */
  intendedRespondentEmailAddress?: InputMaybe<Scalars['String']>;
  /**
   * The new intended respondent Job.  Required if intendedRespondentTypeId is not
   * null, and not an email address respondent type
   */
  intendedRespondentJobId?: InputMaybe<Scalars['ID']>;
  /** The new intended respondent type. */
  intendedRespondentTypeId?: InputMaybe<Scalars['ID']>;
  /** The issue date time for form */
  issueDate: Scalars['DateTime'];
};

export type WorkflowMutation = {
  __typename?: 'WorkflowMutation';
  /** Empty type so the base mutation can be extended */
  _empty?: Maybe<Scalars['String']>;
  /** Creates a workflow with the given input */
  createWorkflow?: Maybe<CreateWorkflowResponse>;
  /** Deletes the given workflow */
  deleteWorkflow?: Maybe<DeleteWorkflowResponse>;
  /** Duplicates a workflow with the given input */
  duplicateWorkflow?: Maybe<DuplicateWorkflowResponse>;
  /** Syncs a workflow with the given input */
  syncWorkflow?: Maybe<SyncWorkflowResponse>;
  /** Updates a workflow with the given input */
  updateWorkflow?: Maybe<UpdateWorkflowResponse>;
};


export type WorkflowMutationcreateWorkflowArgs = {
  input: CreateWorkflowInput;
};


export type WorkflowMutationdeleteWorkflowArgs = {
  input: DeleteWorkflowInput;
};


export type WorkflowMutationduplicateWorkflowArgs = {
  input: DuplicateWorkflowInput;
};


export type WorkflowMutationsyncWorkflowArgs = {
  input: SyncWorkflowInput;
};


export type WorkflowMutationupdateWorkflowArgs = {
  input: UpdateWorkflowInput;
};

export type WorkflowScheduleInput = {
  /** The ID of the job to schedule the forms for. This is used to determine the supervisor for supervisor forms */
  jobId: Scalars['ID'];
  /** The list of schedule rows for each of the form designs in the workflow. */
  schedules: Array<WorkflowScheduleInputScheduleRow>;
  /** The ID of the thing which triggered this workflow schedule. Is stored as-given and does not affect the schedule itself. */
  triggerId?: InputMaybe<Scalars['ID']>;
  /** The type of the thing which triggered this workflow schedule (i.e. JOB or TRAINING) */
  triggerType?: InputMaybe<WorkflowEventTriggerTriggerType>;
  /** The ID of the workflow to schedule forms for */
  workflowId: Scalars['ID'];
};

export type WorkflowScheduleInputScheduleRow = {
  /** When false, this schedule row will not be processed */
  doSchedule: Scalars['Boolean'];
  /**
   * The method through which to notify the person e.g. primary email, personal
   * email, work email. If not set notifications wil fall back to selection on the
   * Form Design. Optional.
   */
  intendedNotificationMethodId?: InputMaybe<Scalars['ID']>;
  /** The method through which to notify the person e.g. primary email, personal email. Optional. */
  notificationMethodId?: InputMaybe<Scalars['ID']>;
  /** The job id of the recipient of the form. Required when scheduling */
  recipientJobId?: InputMaybe<Scalars['ID']>;
  /**
   * The send date of the schedule.
   * This is a TenantDate type, which means only YYYY-MM-DD is accepted, and the
   * exact time will be informed by the system config.
   * Required when scheduling.
   */
  sendDate: Scalars['TenantDate'];
  /** The ID of the WorkflowFormDesign to schedule. */
  workflowFormDesignId: Scalars['ID'];
};

export type WorkflowScheduleResponse = {
  __typename?: 'WorkflowScheduleResponse';
  scheduledFormInstanceInfo: Array<WorkflowScheduleResponseFormInstanceInfo>;
  workflow: Workflow;
};

export type WorkflowScheduleResponseFormInstanceInfo = {
  __typename?: 'WorkflowScheduleResponseFormInstanceInfo';
  id: Scalars['ID'];
  issueDate: Scalars['DateTime'];
};

export type XeroAuthenticationToken = {
  __typename?: 'XeroAuthenticationToken';
  /** The Xero oauth page to verify the request token */
  authorisationUrl?: Maybe<Scalars['String']>;
  /** The link back to the xero integration settings page in spa */
  callbackUri?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** if the authentication is partner based authentication */
  isPartnerAuthentication?: Maybe<Scalars['Boolean']>;
  /** when the overall access permissions expire (~10 year window) */
  oauthAuthorizationExpiresAt?: Maybe<Scalars['String']>;
  /** When automated access to xero will expire at */
  oauthExpiresAt?: Maybe<Scalars['String']>;
  /** If the request token has veen verified */
  verified?: Maybe<Scalars['Boolean']>;
};

export type XeroAuthenticationTokenVerification = {
  /** The Oauth token used to access xero */
  oauthToken?: InputMaybe<Scalars['String']>;
  /** A code returned from xero to authenticate access */
  oauthVerifier?: InputMaybe<Scalars['String']>;
  /** The organisation ID */
  org?: InputMaybe<Scalars['String']>;
};

export type XeroConfiguration = {
  __typename?: 'XeroConfiguration';
  /** the email address to send notification emails to */
  email?: Maybe<Scalars['String']>;
  /** The name of the Xero organisation the tenant is connected to */
  organisationName?: Maybe<Scalars['String']>;
  /** The date that people created after are required to fill in payroll */
  requiredDate?: Maybe<Scalars['DateTime']>;
  /** when the configuration was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type XeroConfigurationCreateInput = {
  /** the email address to send notification emails to */
  email?: InputMaybe<Scalars['String']>;
  /** The date that people created after are required to fill in payroll */
  requiredDate?: InputMaybe<Scalars['DateTime']>;
};

export type XeroPayrollOnboardingInput = {
  /** The name of the bank account associated with the payroll. */
  accountName: Scalars['String'];
  /** The account number of the bank account associated with the payroll. */
  accountNumber: Scalars['String'];
  /** Address line 1 for employee home address. */
  addressLine1: Scalars['String'];
  /** Address line 2 for employee home address. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** The BSB number of the bank account associated with the payroll. */
  bsb: Scalars['String'];
  /** Suburb for employee home address. */
  city: Scalars['String'];
  /** The basis of the persons employment (e.g. FULLTIME, PARTTIME). */
  employmentBasis: EmploymentBasis;
  /** If employee has HECS or HELP debt. */
  hasHELPDebt: Scalars['Boolean'];
  /** If employee has financial supplement debt. */
  hasSFSSDebt: Scalars['Boolean'];
  /** If employee has trade support loan. */
  hasTradeSupportLoanDebt: Scalars['Boolean'];
  /** The id in lapis for the person. */
  personId: Scalars['String'];
  /** PostCode for employee home address. */
  postalCode: Scalars['String'];
  /** State abbreviation for employee home address. */
  region: StateAbbreviation;
  /** Residency status for tax purposes. */
  residencyStatus: ResidencyStatus;
  /** The account name of the superanuation if they have either a regulated or unlisted super fund. */
  superAccountName?: InputMaybe<Scalars['String']>;
  /** The abn of the super fund if they have a self managed super fund. */
  superFundAbn?: InputMaybe<Scalars['String']>;
  /** The bank accuount name for the super fund if they have a self managed super fund. */
  superFundBankAccountName?: InputMaybe<Scalars['String']>;
  /** The bank account number for if they have a self managed super fund. */
  superFundBankAccountNumber?: InputMaybe<Scalars['String']>;
  /** The bank bsb for if they have a self managed super fund. */
  superFundBankBsb?: InputMaybe<Scalars['String']>;
  /** An electronic service address to receive data messages associated with employer contributions sent if they have a self managed super fund. */
  superFundEsaa?: InputMaybe<Scalars['String']>;
  /** Xero identifier for if they have a regulated super fund. */
  superFundId?: InputMaybe<Scalars['String']>;
  /** The name of the super fund if they have an unlisted or self managed super fund. */
  superFundName?: InputMaybe<Scalars['String']>;
  /** The type of super fund */
  superFundType: SuperFundType;
  /** The membership number for the superanuation account if they have a regulated or unlisted super fund. */
  superMembershipNumber?: InputMaybe<Scalars['String']>;
  /** The Unique Superannuation Identifier if they have an unlisted super fund */
  superUsi?: InputMaybe<Scalars['String']>;
  /** The users Tax file number. */
  taxFileNumber?: InputMaybe<Scalars['String']>;
  /** If tax free threshold claimed. */
  taxFreeThresholdClaimed: Scalars['Boolean'];
  /** Tenant timezone */
  tenantTimezone?: InputMaybe<Scalars['String']>;
  /** If a user is TFN exempt this is the type of exemption. */
  tfnExemptionType?: InputMaybe<TfnExemptionType>;
};

export type XeroPerson = {
  __typename?: 'XeroPerson';
  id?: Maybe<Scalars['ID']>;
  onboardedAt?: Maybe<Scalars['DateTime']>;
  onboardedBy?: Maybe<Person>;
  person?: Maybe<Person>;
  xeroIdentifier?: Maybe<Scalars['ID']>;
};

export type XeroQuery = {
  __typename?: 'XeroQuery';
  /** Returns a list of available super funds */
  getSuperFunds?: Maybe<Array<Maybe<XeroSuperannuationFund>>>;
};

export type XeroSuperannuationFund = {
  __typename?: 'XeroSuperannuationFund';
  /** The superFundID generated by Xero */
  id: Scalars['String'];
  /** Name of the super fund */
  name?: Maybe<Scalars['String']>;
  /** The USI(unique superannuation identifier) of the regulated super fund */
  usi?: Maybe<Scalars['String']>;
};

export type XeroSuperfund = {
  __typename?: 'XeroSuperfund';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  usi?: Maybe<Scalars['String']>;
};

export type defaultRemunerationComponentSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type employmentConditionSearchObject = {
  awardName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type locationSearchObjectInput = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type payCycleSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type recruitmentSourceSearchObject = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type rejectContractResponse = {
  __typename?: 'rejectContractResponse';
  contract?: Maybe<Contract>;
};

export type sendFormsViaSelfServiceActionResponse = {
  __typename?: 'sendFormsViaSelfServiceActionResponse';
  requestedForms?: Maybe<Array<Maybe<SelfServiceActionPerformRequestFormCompletionResponseForm>>>;
};

export type workClassSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type workRightsSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type workTypeSearchObjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};
