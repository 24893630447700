import { cloudLogger } from '../error/services/cloudLogger'

const uuid = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
const spaRoutes = {
  access_denied: '/access_denied',

  'compliance.index': '/compliance',
  'compliance.contractors': '/compliance/contractors',
  'compliance.leave': '/compliance/leave',
  'compliance.performance_managements': '/compliance/performance-managements',
  'compliance.performance_improvements': '/compliance/performance-improvements',
  'compliance.qualifications.overview': '/compliance/qualifications',
  'compliance.qualifications.standalone': '/compliance/qualifications/standalone/:qualificationLibraryItemId',
  'compliance.qualifications.group': '/compliance/qualifications/group/:mandatoryRequirementGroupId',
  'compliance.qualifications.all': '/compliance/qualifications/all/:qualificationLibraryItemId',
  'compliance.tasks': '/compliance/tasks',

  'contracts.create': '/contracts/issue',
  'contracts.index': '/contracts',
  'contracts.print': `/contracts/:contractId(${uuid})/print`,
  'contracts.show': `/contracts/:contractId(${uuid})`,
  'contracts.view_and_sign': `/contracts/:contractId(${uuid})/view`,
  'contract_templates.index': '/contract-templates',
  'contract_templates.create': '/contract-templates/create',
  'contract_templates.edit': `/contract-templates/:contractTemplateId(${uuid})/edit`,
  'contracts.bulk_issue': '/contracts/bulk-issue',

  dashboard: '/dashboard',
  'dashboard.team_training': '/dashboard/team-training',
  'dashboard.team_goals': '/dashboard/team-goals',
  'dashboard.self_service_actions': '/dashboard/self-service-actions',
  'dashboard.self_service_actions.create': '/dashboard/self-service-actions/create',
  'dashboard.self_service_actions.perform': '/dashboard/self-service-actions/:selfServiceActionId/perform',

  // deprecated establishments - use workforce_planning
  'establishments.index': '/establishments',
  'establishments.show': `/establishments/:establishmentId(${uuid})`,
  'establishments.past': '/establishments/past',
  'establishments.create': '/establishments/create',
  'establishments.edit': `/establishments/:establishmentId(${uuid})/edit`,
  'establishments.set_end_date': `/establishments/:establishmentId(${uuid})/set-end-date`,
  'establishments.cancel_end_date': `/establishments/:establishmentId(${uuid})/cancel-end-date`,
  'establishments.edit_start_date': `/establishments/:establishmentId(${uuid})/edit-start-date`,
  'establishments.fill': '/establishments/fill',
  'establishments.show.current': `/establishments/:establishmentId(${uuid})/current`,
  'establishments.show.timeline': `/establishments/:establishmentId(${uuid})/timeline`,

  'workforce_planning.index': '/workforce-planning',
  'workforce_planning.roles.past': '/workforce-planning/roles/past',
  'workforce_planning.roles.show': `/workforce-planning/roles/:establishmentId(${uuid})`,
  'workforce_planning.roles.create': '/workforce-planning/roles/create',
  'workforce_planning.roles.edit': `/workforce-planning/roles/:establishmentId(${uuid})/edit`,
  'workforce_planning.roles.fill': '/workforce-planning/roles/fill',
  'workforce_planning.roles.show.current': `/workforce-planning/roles/:establishmentId(${uuid})/current`,
  'workforce_planning.roles.show.detailed': `/workforce-planning/roles/:establishmentId(${uuid})/detailed`,

  // deprecated use roles
  'workforce_planning.establishments.past': '/workforce-planning/establishments/past',
  'workforce_planning.establishments.show': `/workforce-planning/establishments/:establishmentId(${uuid})`,
  'workforce_planning.establishments.create': '/workforce-planning/establishments/create',
  'workforce_planning.establishments.edit': `/workforce-planning/establishments/:establishmentId(${uuid})/edit`,
  'workforce_planning.establishments.fill': '/workforce-planning/establishments/fill',
  'workforce_planning.establishments.show.current': `/workforce-planning/establishments/:establishmentId(${uuid})/current`,
  'workforce_planning.establishments.show.detailed': `/workforce-planning/establishments/:establishmentId(${uuid})/detailed`,
  'workforce_planning.establishments.show.timeline': `/workforce-planning/establishments/:establishmentId(${uuid})/timeline`,

  'compensation_plans.index': '/compensation-plans',
  'compensation_plans.create': '/compensation-plans/create',
  'compensation_plans.edit': `/compensation-plans/:compensationPlanId(${uuid})/edit`,
  'compensation_plans.edit.general': `/compensation-plans/:compensationPlannerPlanId(${uuid})/edit/general`,
  'compensation_plans.overview': `/compensation-plans/:compensationPlannerPlanId(${uuid})/overview`,
  'compensation_plans.budget_groups': `/compensation-plans/:compensationPlannerPlanId(${uuid})/budget-groups`,
  'compensation_plans.eligibility_rules': `/compensation-plans/:compensationPlannerPlanId(${uuid})/eligibility-rules`,
  'compensation_plans.view': `/compensation-plans/:compensationPlanId(${uuid})/view`,

  'form_designs.create': '/form-designs/create',
  'form_designs.edit': '/form-designs/:formDesignId/edit',
  'form_designs.index': '/form-designs',
  'templates.index': '/templates',

  'anonymous_form_designs.create': '/anonymous-form-designs/create',
  'anonymous_form_designs.edit': `/anonymous-form-designs/:anonymousFormDesignId(${uuid})/edit`,
  'anonymous_form_designs.index': '/anonymous-form-designs',

  'anonymous_pulses.schedule': '/anonymous-pulses/schedule',
  'anonymous_pulses.edit': `/anonymous-pulses/:pulseId(${uuid})/edit`,
  'anonymous_recurring_pulses.edit': `/anonymous-recurring-pulses/:recurringPulseId(${uuid})/edit`,

  'tasks.show': `/tasks/:formInstanceId(${uuid})`,

  'pulses.index': '/pulses',
  'pulses.show': `/pulses/:pulseId(${uuid})`,
  'pulses.schedule': '/pulses/schedule',
  'pulses.edit': `/pulses/:pulseId(${uuid})/edit`,
  'pulses.review_filters.locations': '/pulses/review-filters/locations',

  'recurring_pulses.index': '/recurring-pulses',
  'recurring_pulses.show': `/recurring-pulses/:recurringPulseId(${uuid})`,
  'recurring_pulses.edit': `/recurring-pulses/:recurringPulseId(${uuid})/edit`,

  'me.index': '/me',
  'me.settings.index': '/me/settings',
  'me.payroll.create': '/me/payroll/create',
  'me.payroll.index': '/me/payroll',
  'me.performance_report.generate': '/me/performance-report/generate',

  notifications: '/notifications',

  onboarding: '/onboarding',

  'organisationObjectives.index': '/organisation/organisation-objectives',
  'organisationObjectives.show': `/organisation/organisation-objectives/:orgObjectiveId(${uuid})`,
  'organisationObjectives.create': '/organisation/organisation-objectives/create',
  'organisationObjectives.edit': `/organisation/organisation-objectives/:orgObjectiveId(${uuid})/edit`,

  'people.address.create': `/people/:personId(${uuid})/addresses/create`,
  'people.address.edit': `/people/:personId(${uuid})/addresses/:addressId(${uuid})/edit`,
  'people.birthday_calendar': '/people/birthday-calendar',
  'people.contracts': `/people/:personId(${uuid})/contracts`,
  'people.event_calendar': '/people/event-calendar',
  'people.create': '/people/create',
  'people.diary_notes.view': '/people/:personId/diary-notes/:diaryNoteId',
  'people.diary_notes.create': '/people/:personId/diary-notes/create',
  'people.diary_notes.edit': '/people/:personId/diary-notes/:diaryNoteId/edit',
  'people.performance_improvement_plan.view': '/people/:personId/performance-improvement-plan/:pipId',
  'people.performance_improvement_plan.create': '/people/:personId/performance-improvement-plan/create',
  'people.performance_improvement_plan.escalate': '/people/:personId/performance-improvement-plan/:pipId/escalate',
  'people.performance_improvement_plan.resolve': '/people/:personId/performance-improvement-plan/:pipId/resolve',
  'people.performance_improvement_plan.create_related':
    '/people/:personId/performance-improvement-plan/:pipId/create-related',
  'people.performance_improvement_plan.edit':
    '/people/:personId/performance-improvement-plan/:pipId/diary-note/:diaryNoteId/edit',
  'people.performance_improvement_plan.letter':
    '/people/:personId/performance-improvement-plan/:pipId/diary-note/:diaryNoteId/letter',
  'people.documents': `/people/:personId(${uuid})/documents`,
  'people.edit': `/people/:personId(${uuid})/edit`,
  'people.edit.custom_fields': `/people/:personId(${uuid})/custom-fields/:categoryId(${uuid})/edit`,
  'people.email.create': `/people/:personId(${uuid})/emails/create`,
  'people.email.edit': `/people/:personId(${uuid})/emails/:emailId(${uuid})/edit`,
  'people.feedback': `/people/:personId(${uuid})/feedback`,
  'people.goals': `/people/:personId(${uuid})/goals`,
  'people.goals.create': `/people/:personId(${uuid})/goals/create`,
  'people.goals.edit': `/people/:personId(${uuid})/goals/:goalId(${uuid})/edit`,
  'people.goals.show': `/people/:personId(${uuid})/goals/:goalId(${uuid})`,
  'people.index': '/people',
  'people.jobs': `/people/:personId(${uuid})/jobs`,
  'people.jobs.add_concurrent': `/people/:personId(${uuid})/jobs/add-concurrent`,
  'people.jobs.add_onboarding_session': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/add-onboarding-sessions`,
  'people.jobs.adjust_expected_end_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/adjust-expected-end-date`,
  'people.jobs.cancel_end_of_job': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/cancel-end-of-job`,
  'people.jobs.change': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/change`,
  'people.jobs.pending_update': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/job-pending-update`,
  'people.jobs.pending_update_archived': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/pending-update`, // leave the old url here until we are sure that there is no more reference to it
  'people.jobs.create': `/people/:personId(${uuid})/jobs/create`,
  'people.jobs.trigger_delete': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/trigger-delete`,
  'people.jobs.delete': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/delete`,
  'people.jobs.edit_history': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/history`,
  'people.jobs.edit_start_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/edit-start-date`,
  'people.jobs.edit_probation_end_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/edit-probation-end-date`,
  'people.jobs.finalise_end_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/finalise-end-date`,
  'people.jobs.finalise_leave': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/finalise-leave-return-date`,
  'people.jobs.go_on_leave': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/go-on-extended-leave`,
  'people.jobs.reassign_direct_reports': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/reassign-direct-reports`,
  'people.jobs.recruitment_info': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/recruitment-info`,
  'people.jobs.remove_expected_end_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/remove-expected-end-date`,
  'people.jobs.remuneration_schedules.create': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/remuneration-schedules/create`,
  'people.jobs.remuneration_schedules.edit': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/remuneration-schedules/:remScheduleId(${uuid})/edit`,
  'people.jobs.set_expected_end_date': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/set-expected-end-date`,
  'people.jobs.set_as_primary_job': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/set-as-primary-job`,
  'people.jobs.payroll_change': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/payroll-change/:type`,
  'people.jobs.show': `/people/:personId(${uuid})/jobs/:jobId(${uuid})`,
  'people.jobs.show.current': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/current`,
  'people.jobs.show.timeline': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/timeline`,
  'people.jobs.update_onboarding_session': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/onboarding-sessions/:onboardingSessionId(${uuid})`,
  'people.jobs.add_mandatory_requirement': `/people/:personId(${uuid})/jobs/:jobId(${uuid})/add-mandatory-requirements`,
  'people.managers_log': `/people/:personId(${uuid})/managers-log`,
  'people.onboarding': `/people/:personId(${uuid})/onboarding`,
  'people.onboarding_overview': `/people/onboarding`,
  'people.payroll.create': `/people/:personId(${uuid})/payroll/create`,
  'people.performance_report': `/people/:personId(${uuid})/performance-report`,
  'people.performance_report.view': `/people/:personId(${uuid})/performance-report/:frameworkId(${uuid})`,
  'people.performance_report.generate': `/people/:personId(${uuid})/performance-report/generate`,
  'people.phone_number.create': `/people/:personId(${uuid})/phone-numbers/create`,
  'people.phone_number.edit': `/people/:personId(${uuid})/phone-numbers/:phoneNumberId(${uuid})/edit`,
  'people.medical_condition.create': '/people/:personId/medical_conditions/create',
  'people.medical_condition.edit': '/people/:personId/medical_conditions/:medicalConditionId/edit',
  'people.profile': `/people/:personId(${uuid})`,
  'people.qualifications': `/people/:personId(${uuid})/qualifications`,
  'people.qualifications.instances.show': '/people/:personId/qualifications/:qualificationTemplateId',
  'people.qualifications.instances.create': '/people/:personId/qualifications/create',
  'people.qualifications.instances.draft': '/people/:personId/qualifications/draft/:qualificationInstanceId',
  'people.qualifications.instances.edit': '/people/:personId/qualifications/:qualificationInstanceId/edit',
  'people.qualifications.instances.renew': '/people/:personId/qualifications/renew/:qualificationInstanceId',
  'people.show': `/people/:personId(${uuid})`,
  'people.skills': `/people/:personId(${uuid})/skills`,
  'people.skills.create': `/people/:personId(${uuid})/skills/create`,
  'people.skills.delete': `/people/:personId(${uuid})/skills/:skillId(${uuid})/delete`,
  'people.skills.edit': `/people/:personId(${uuid})/skills/:skillId(${uuid})/edit`,
  'people.trainings': `/people/:personId(${uuid})/trainings`,
  'people.trainings.create': `/people/:personId(${uuid})/trainings/create`,
  'people.trainings.edit': `/people/:personId(${uuid})/trainings/:trainingId(${uuid})/edit`,
  'people.trainings.show': `/people/:personId(${uuid})/trainings/:trainingId(${uuid})`,
  'people.user': `/people/:personId(${uuid})/user`,

  'settings.access_keys': '/settings/access-keys',
  'settings.account': '/settings/account',
  'settings.acls': '/settings/acls',
  'settings.audit_log': '/settings/audit-log',
  'settings.bulk_data_tool': '/settings/bulk-data-tool',
  'settings.bulk_data_tool.help': '/settings/bulk-data-tool/help',
  'settings.bulk_data_tool.help.how_to_use': '/settings/bulk-data-tool/help/how-to-use',
  'settings.bulk_data_tool.help.upload_type': '/settings/bulk-data-tool/help/upload/:typeString',
  'settings.bulk_data_tool.create_import': '/settings/bulk-data-tool/import/create',
  'settings.bulk_data_tool.download_export': '/settings/bulk-data-tool/export/download',
  'settings.bulk_data_tool.view_import': `/settings/bulk-data-tool/import/:importId(${uuid})`,
  'settings.business_entities': '/settings/business-entities',
  'settings.business_entities.create': '/settings/business-entities/create',
  'settings.business_entities.edit': `/settings/business-entities/:businessEntityId(${uuid})/edit`,
  'settings.business_entities.super_api': `/settings/business-entities/:businessEntityId(${uuid})/onboarding`,
  'settings.business_units': '/settings/business-units',
  'settings.business_units.create': '/settings/business-units/create',
  'settings.business_units.edit': `/settings/business-units/:businessUnitId(${uuid})/edit`,
  'settings.business_unit_representative_types': '/settings/business-unit-representative-types',
  'settings.business_unit_representative_types.edit': `/settings/business-unit-representative-types/:representativeTypeId(${uuid})/edit`,
  'settings.configuration': '/settings/configuration',
  'settings.configure_product_plan': '/settings/configure-product-plan',
  'settings.configure_product_plan.show': `/settings/configure-product-plan/:productPlanId(${uuid})`,
  'settings.configure_product_plan.create': '/settings/configure-product-plan/create',
  'settings.configure_product_plan.edit': `/settings/configure-product-plan/:productPlanId(${uuid})/edit`,
  'settings.custom_fields': '/settings/custom-fields',
  'settings.custom_fields.categories': '/settings/custom-fields/categories',
  'settings.custom_fields.permissions_created': '/settings/custom-fields/permissions-created',
  'settings.custom_fields.category.create': '/settings/custom-fields/category/create',
  'settings.custom_fields.category.edit': `/settings/custom-fields/category/:categoryId(${uuid})/edit`,
  'settings.custom_fields.create': '/settings/custom-fields/create',
  'settings.custom_fields.edit': `/settings/custom-fields/:customFieldId(${uuid})/edit`,
  'settings.custom_field_templates': '/settings/custom-field-templates',
  'settings.custom_field_templates.create': '/settings/custom-field-templates/create',
  'settings.custom_field_profile_sections': '/settings/custom-fields-profile-section',
  'settings.custom_field_profile_sections.create': '/settings/custom-fields-profile-section/create',
  'settings.custom_field_profile_sections.edit': `/settings/custom-fields-profile-section/:customFieldProfileSection(${uuid})/edit`,
  'settings.data_export': '/settings/data-export',
  'settings.data_import': '/settings/data-import',
  'settings.default_remuneration_components': '/settings/default-remuneration-components',
  'settings.default_remuneration_components.create': '/settings/default-remuneration-components/create',
  'settings.default_remuneration_components.edit': `/settings/default-remuneration-components/:defaultRemunerationComponentId(${uuid})/edit`,
  'settings.delegated_access': '/settings/delegated-access',
  'settings.diary_note_categories': '/settings/diary-note-categories',
  'settings.diary_note_categories.create': '/settings/diary-note-categories/create',
  'settings.diary_note_categories.edit': '/settings/diary-note-categories/:diaryNoteCategoryId/edit',
  'settings.document_folders.index': '/settings/document-folders',
  'settings.document_folders.create': '/settings/document-folders/create',
  'settings.document_folders.edit': `/settings/document-folders/:documentFolderId(${uuid})/edit`,
  'settings.document_folders.permissions_created': '/settings/document-folders/permissions-created',
  'settings.employment_conditions': '/settings/employment-conditions',
  'settings.employment_conditions.create': '/settings/employment-conditions/create',
  'settings.employment_conditions.edit': `/settings/employment-conditions/:employmentConditionId(${uuid})/edit`,
  'settings.files': '/settings/files',
  'settings.form_design_categories': '/settings/form-design-categories',
  'settings.form_ignore_reasons': '/settings/form-ignore-reasons',
  'settings.form_items': '/settings/form-items',
  'settings.goal_categories': '/settings/goal-categories',
  'settings.goal_categories.create': '/settings/goal-categories/create',
  'settings.goal_categories.edit': `/settings/goal-categories/:goalCategoryId(${uuid})/edit`,
  'settings.global_notifications': '/settings/global-notifications',
  'settings.global_notifications.show': `/settings/global-notifications/:globalNotificationConfigurationId(${uuid})`,
  'settings.global_notifications.create': '/settings/global-notifications/create',
  'settings.global_notifications.edit': `/settings/global-notifications/:globalNotificationConfigurationId(${uuid})/edit`,
  'settings.index': '/settings',
  'settings.integrations.index': '/settings/integrations',
  'settings.integrations.logs': '/settings/integrations/logs',
  'settings.integrations.azure_ad': '/settings/integrations/azure_ad',
  'settings.integrations.jobadder': '/settings/integrations/jobadder',
  'settings.integrations.go1': '/settings/integrations/go1',
  'settings.integrations.cintra': '/settings/integrations/cintra',
  'settings.integrations.xero_v2': '/settings/integrations/xero_v2',
  'settings.integrations.sftp': '/settings/integrations/sftp',
  'settings.integrations.hf-ic': '/settings/integrations/hf-ic',
  'settings.integrations': '/settings/integrations/:integrationSlug',
  'settings.job_approvals': '/settings/job-approvals',
  'settings.job_change_reasons': '/settings/job-change-reason',
  'settings.job_change_reason.create': '/settings/job-change-reason/create',
  'settings.job_change_reason.edit': `/settings/job-change-reason/:jobChangeReasonId(${uuid})/edit`,
  'settings.leave_types': '/settings/leave-type',
  'settings.leave_type.create': '/settings/leave-type/create',
  'settings.leave_type.edit': `/settings/leave-type/:leaveTypeId(${uuid})/edit`,
  'settings.locations': '/settings/locations',
  'settings.locations.create': '/settings/locations/create',
  'settings.locations.edit': `/settings/locations/:locationId(${uuid})/edit`,
  'settings.message_blacklist': '/settings/message-blacklist',
  'settings.message_schedule': '/settings/message-schedule',
  'settings.message_schedule.view': '/settings/message-schedule/:messageId',
  'settings.metric_groups': '/settings/metric-groups',
  'settings.metrics': '/settings/metrics',
  'settings.onboarding_templates.create': '/settings/onboarding-templates/create',
  'settings.onboarding_templates.edit': `/settings/onboarding-templates/:onboardingTemplateId(${uuid})/edit`,
  'settings.onboarding_templates.index': '/settings/onboarding-templates',
  'settings.organisation': '/settings/organisation',
  'settings.pay_grades': '/settings/pay-grades',
  'settings.pay_grades.create': '/settings/pay-grades/create',
  'settings.pay_grades.edit': `/settings/pay-grades/:payGradeId(${uuid})/edit`,
  'settings.performance_report_templates': '/settings/performance-report-templates',
  'settings.performance_report_templates.create': '/settings/performance-report-templates/create',
  'settings.performance_report_templates.edit': '/settings/performance-report-templates/:frameworkId/edit',
  'settings.performance_report_templates.duplicate': '/settings/performance-report-templates/:frameworkId/duplicate',
  'settings.performance_metric_groups': '/settings/performance-metrics',
  'settings.performance_metric_groups.create': '/settings/performance-metrics/create',
  'settings.performance_metric_groups.edit': '/settings/performance-metrics/:metricGroupId/edit',
  'settings.performance_metric_groups.duplicate': '/settings/performance-metrics/:metricGroupId/duplicate',
  'settings.permission_groups': '/settings/permission-groups',
  'settings.product_plan': '/settings/product-plan',
  'settings.position_titles': '/settings/position-titles',
  'settings.position_titles.archived': '/settings/position-titles/archived',
  'settings.position_titles.create': '/settings/position-titles/create',
  'settings.position_titles.edit': `/settings/position-titles/:positionTitleId(${uuid})/edit`,
  'settings.position_titles.merge': `/settings/position-titles/:positionTitleId(${uuid})/merge`,
  'settings.qualifications_library_items': '/settings/qualification-library-items',
  'settings.qualifications_library_items.create': '/settings/qualification-library-items/create',
  'settings.qualifications_library_items.edit':
    '/settings/qualification-library-items/:qualificationLibraryItemId/edit',
  'settings.qualifications_mandatory_requirement_groups': '/settings/mandatory-requirement-groups',
  'settings.qualifications_mandatory_requirement_groups.create': '/settings/mandatory-requirement-groups/create',
  'settings.qualifications_mandatory_requirement_groups.edit':
    '/settings/mandatory-requirement-groups/:mandatoryRequirementGroupId/edit',
  'settings.qualifications_types': '/settings/qualification-types',
  'settings.qualifications_types.create': '/settings/qualification-types/create',
  'settings.qualifications_types.edit': '/settings/qualification-types/:qualificationTypeId/edit',
  'settings.recalculate_intellihr_ids': '/settings/recalculate-intellihr-ids',
  'settings.representative_types': '/settings/representative-types',
  'settings.representative_types.edit': `/settings/representative-types/:representativeTypeId(${uuid})/edit`,
  'settings.self_service_actions': '/settings/self-service-actions',
  'settings.self_service_actions.create': '/settings/self-service-actions/create',
  'settings.self_service_actions.edit': '/settings/self-service-actions/:selfServiceActionId/edit',
  'settings.skill_disciplines': '/settings/skill-disciplines',
  'settings.skill_disciplines.create': '/settings/skill-disciplines/create',
  'settings.skill_disciplines.delete': '/settings/skill-disciplines/:skillDisciplineId/delete',
  'settings.skill_disciplines.edit': '/settings/skill-disciplines/:skillDisciplineId/edit',
  'settings.skills': '/settings/skills',
  'settings.skills.create': '/settings/skills/create',
  'settings.skills.edit': '/settings/skills/:skillId/edit',
  'settings.sources': '/settings/sources',
  'settings.sso_idps': '/settings/sso-idps',
  'settings.person_titles': '/settings/person-titles',
  'settings.person_titles.archived': '/settings/person-titles/archived',
  'settings.person_titles.create': '/settings/person-titles/create',
  'settings.person_titles.edit': `/settings/person-titles/:titleId(${uuid})/edit`,
  'settings.template_types': '/settings/template-types',
  'settings.training_providers': '/settings/training-providers',
  'settings.training_providers.create': '/settings/training-providers/create',
  'settings.training_providers.edit': `/settings/training-providers/:trainingProviderId(${uuid})/edit`,
  'settings.training_types': '/settings/training-types',
  'settings.training_types.create': '/settings/training-types/create',
  'settings.training_types.edit': `/settings/training-types/:trainingTypeId(${uuid})/edit`,
  'settings.turnover_reasons': '/settings/turnover-reasons',
  'settings.turnover_reasons.create': '/settings/turnover-reasons/create',
  'settings.turnover_reasons.edit': `/settings/turnover-reasons/:turnoverReasonId(${uuid})/edit`,
  'settings.users': '/settings/users',
  'settings.users.bulk_user_create': '/settings/users/bulk-user-create',
  'settings.users.create': '/settings/users/create',
  'settings.users.edit': `/settings/users/:userId(${uuid})/edit`,
  'settings.users.show': `/settings/users/:userId(${uuid})`,
  'settings.webhooks': '/settings/webhooks',
  'settings.webhooks.create': '/settings/webhooks/create',
  'settings.webhooks.edit': '/settings/webhooks/:webhookId/edit',
  'settings.webhooks.logs': '/settings/webhooks/:webhookId/logs',
  'settings.work_classes': '/settings/work-class',
  'settings.work_class.create': '/settings/work-class/create',
  'settings.work_class.edit': `/settings/work-class/:workClassId(${uuid})/edit`,
  'settings.work_rights': '/settings/work-rights',

  'users.index': '/users',
  'users.show': '/users/:userId',

  'workflow_events.schedule': `/workflow-events/:workflowEventId(${uuid})/schedule`,
  'workflow_events.schedule.legacy': `/workflow_events/:workflowEventId(${uuid})/schedule`,

  'workflows.index': '/workflows',
  'workflows.show': '/workflows/:workflowId',
  'workflows.edit': '/workflows/:workflowId?mode=edit',
  'workflows.trigger': '/workflows/:workflowId/trigger',

  'performance.index': '/performance',
  'performance.goals.create': '/performance/goals/create',
  'performance.goal_templates': '/performance/goal-templates',
  'performance.goal_templates.create': '/performance/goal-templates/create',
  'performance.goal_templates.edit': '/performance/goal-templates/:goalTemplateId/edit',
  'performance.goal_templates.duplicate': '/performance/goal-templates/:goalTemplateId/duplicate',
  'performance.goal_templates.archived': '/performance/goal-templates/archived',
  'performance.training.create': '/performance/training/create',
  'performance.diary_notes.create': '/performance/diary-notes/create',

  'notes.index': '/notes',
  'notes.create.modal': '/notes/m/note/create',
  'notes.view.modal': '/notes/m/note/:noteId',

  'cintra.index': '/cintra',
  'cintra.calendar': '/cintra/calendar',
  'cintra.absence': '/cintra/absence',
  'cintra.holiday_bookings': '/cintra/holiday_bookings',
  'cintra.payslips': '/cintra/payslips',
  'cintra.bank_accounts': '/cintra/bank_accounts',
  'cintra.manage_absence': '/cintra/manage_absence',
  'cintra.manage_holidays': '/cintra/manage_holidays',
  'cintra.p45': '/cintra/p45',
  'cintra.p60': '/cintra/p60',
  'cintra.p11d': '/cintra/p11d',
  'cintra.documents': '/cintra/documents',
  'cintra.pay_claims': '/cintra/pay_claims',
  'cintra.manage_pay_claims': '/cintra/manage_pay_claims',
  '404': '/404',

  'job_pending_updates.index': '/job-updates',
}

// NOTE
// Before using urlForLapisRoute, consider adding an SPA route for the page and setting up a redirect to the
// lapis route on that page. This makes the porting path much simpler for moving pages from lapis to SPA
const lapisRoutes = {
  'dashboard.my_outstanding_tasks': '/dashboard/my/tasks/outstanding',
  'dashboard.my_team_outstanding_tasks': '/dashboard/my/team/tasks/outstanding',

  'analytics.pages': '/analytics/pages/:pageKey',
  'analytics.forms': '/analytics/forms/:formDesignId',

  'account.mfa.enable': '/account/mfa/enable',
  'account.mfa.disable': '/account/mfa/disable',

  'automation.form_designs': '/form_designs',
  'automation.pulses': '/pulses',
  'automation.pulses.create': '/pulses/create',
  'automation.pulse_recurrences': '/pulse_recurrences',
  'automation.pulse_recurrences.create': '/pulse_recurrences/create',
  'automation.pulse_recurrences.edit': `/pulse_recurrences/:recurringPulseId(${uuid})/edit`,
  'automation.templates': '/templates',

  'pulses.edit': '/pulses/:pulseId/edit',
  'pulse_recurrences.edit': '/pulse_recurrences/:recurringPulseId/edit',

  'compliance.qualifications': '/compliance/qualifications',
  'compliance.form_instances': '/form_instances',
  'compliance.work_rights': '/compliance/work_rights',

  'export.all_composite_qualifications': '/api/all_composite_qualifications/export',
  'export.form_instance': '/settings/data-export/form-instances',
  'export.non_mandatory_qualifications': '/api/non_mandatory_qualifications/export',
  'export.people': '/people/export',
  'export.qualification_compliance': '/api/qualification_compliance/export',

  'form_designs.index': '/form_designs',
  'form_designs.design': '/form_designs/:formDesignId/design',
  'form_designs.preview': '/form_designs/:formDesignId/preview',
  'form_designs.delete': '/form_designs/:formDesignId/delete',

  'anonymous.form': `/anonymous/:anonymousPulseId(${uuid})`,
  'anonymous_form_designs.index': '/form_designs',
  'anonymous_form_designs.design': `/anonymous_form_designs/:anonymousFormDesignId(${uuid})/design`,
  'anonymous_form_designs.preview': `/anonymous_form_designs/:anonymousFormDesignId(${uuid})/preview`,
  'anonymous_form_designs.delete': `/anonymous_form_designs/:anonymousFormDesignId(${uuid})/delete`,

  'form_instances.complete': '/form/:formInstanceId/code/:code',
  'form_instances.ignore': '/form/:formInstanceId/code/:code?ignore=1',
  'form_instances.view': '/form_instances/:formInstanceId',

  'templates.create': '/templates/create',
  'templates.compare': '/templates/:templateId/compare',
  'templates.edit': '/templates/:templateId/edit',
  'templates.preview': '/templates/:templateId/variables',

  'me.tasks': '/my/tasks',

  'notifications.email_sample': '/global_notifications/email_sample',
  'notifications.immediate_email_sample': '/global_notifications/immediate_email_sample',

  'people.index': '/people',
  'people.show': '/people/:personId',
  'people.diary_notes.letter': '/people/:personId/diary_notes/:diaryNoteId/letter_raw/access_code/:accessCode',
  'people.diary_notes.letter_generate': '/people/:personId/diary_notes/:diaryNoteId/letter_generate',
  'people.trainings': '/people/:personId#panel-trainings',
  'people.emails.create': '/people/:personId/emails/create',
  'people.goal.requestFeedback': '/people/:personId/goal/:goalId/request-feedback',
  'people.jobs': '/people/:personId/jobs',
  'people.jobs.show': '/people/:personId/jobs/:jobId',
  'people.jobs.finalise_end_date': '/people/:personId/jobs/:jobId/finalise_end_date',
  'people.jobs.adjust_expected_end_date': '/people/:personId/jobs/:jobId/adjust_expected_end_date',
  'people.jobs.set_expected_end_date': '/people/:personId/jobs/:jobId/set_expected_end_date',
  'people.jobs.cancel_end_of_job': '/people/:personId/jobs/:jobId/cancel_end_of_job',
  'people.jobs.effective_dates.edit': '/people/:personId/jobs/:jobId/effective_dates',
  'people.jobs.delete': '/people/:personId/jobs/:jobId/delete',
  'people.medical_condition.create': '/people/:personId/medical_conditions/create',
  'people.medical_condition.edit': '/people/:personId/medical_conditions/:medicalConditionId/edit',
  'people.qualifications': '/people/:personId#panel-qualifications',
  'people.qualifications.instances.show': '/people/:personId/qualifications/:qualificationLibraryItemId',
  'people.qualifications.instances.create': '/people/:personId/qualifications/create',
  'people.qualifications.draft.create':
    '/people/:personId/qualifications/create/?qualificationLibraryItem=:qualificationLibraryItemId',
  'people.qualifications.draft.show':
    '/people/:personId/qualifications/:qualificationLibraryItemId/draft/:qualificationInstanceId',
  'people.qualifications.renewal.show':
    '/people/:personId/qualifications/:qualificationLibraryItemId/renew/:renewalQualificationInstanceId',

  // Settings routes which don't start with /settings in lapis
  'acls.index': '/acls',
  'acls.exit_assumed_role': '/acls/switch',
  'business_units.index': '/business_units',
  'business_units.create': '/business_units/create',
  'business_units.edit': '/business_units/:businessUnitId/edit',
  'configuration.index': '/configuration',
  'employment_conditions.index': '/employment_conditions',
  'files.index': '/files',
  'form_design_categories.index': '/form_design_categories',
  'form_ignore_reasons.index': '/form_ignore_reasons',
  'form_items.index': '/form_items',
  'goal.comment': '/people/:personId/goals/:goalId#comments',
  'goal.edit': '/people/:personId/goals/:goalId/edit',
  'goal.create': '/people/:personId/goals/create',
  'goal_categories.index': '/goal_categories',
  'message_blacklist.index': '/message_blacklist',
  'message_schedule.index': '/message_schedule',
  'message_schedule.view': '/message_schedule/:messageId',
  'metric_groups.index': '/metric_groups',
  'metrics.index': '/metrics',
  'pay_grades.index': '/pay_grades',
  'permissions.show': '/permissions/:permissionId',
  'sources.index': '/sources',
  'template_types.index': '/template_types',
  'users.resend_welcome_email': '/users/:userId/resend-welcome-email',
  'work_classes.index': '/work_classes',
  'work_rights.index': '/work_rights',
  'work_types.index': '/work_types',

  'organisation_settings.update': '/api/organisation_settings',

  'settings.data_import': '/settings/data_import',
  'settings.delegated_access': '/settings/delegated_access',
  'settings.locations': '/settings/locations',
  'settings.organisation': '/settings/organisation',
  'settings.sso_idps': '/settings/sso_idps',
  'settings.webhooks': '/settings/webhooks',
  'settings.webhooks.logs': '/settings/webhooks/:webhookId/logs',
  'settings.webhooks.edit': '/settings/webhooks/:webhookId/edit',
  'settings.webhooks.create': '/settings/webhooks/create',

  'tasks.issue': '/tasks/create/initiate',
  'tasks.resend': '/tasks/:formInstanceId/resend',
  'tasks.view': '/tasks/:formInstanceId',

  'workflow_events.schedule': '/workflow_events/:workflowEventId/people/:personId/jobs/:jobId/schedule',
  'workflows.trigger': '/workflows/:workflowId/trigger',

  'organisation.chart': '/organisation/directReports',
}

const analyticsPortalRoutes = {
  'analytics.pages': '/explore/pages/:pageKey',
  'analytics.pages.anonymous_form_data': '/explore/pages/anonymous-form-data?form-design-id=:formDesignId',
}

interface ISlugMapping {
  [slug: string]: string | undefined
}

const applyMappingForUrl = (baseUrl: string, mapping?: ISlugMapping): string => {
  const url = mapping
    ? Object.entries(mapping).reduce<string>((acc, [key, value]) => {
        if (!value) {
          return acc
        }

        // Regex matches :key(..regex..), with the brackets () being optional
        return acc.replace(new RegExp(`:(${key})(\\([0-9a-z{}\\[\\]+-]+\\))?`), value)
      }, baseUrl)
    : baseUrl

  if (mapping && url.match(/:[A-Za-z0-9]+/)?.some((match) => !(match.substr(1) in mapping))) {
    cloudLogger.error(
      `A route :mapping is still present after applying the provided values: url ${url}, mapping ${JSON.stringify(
        mapping,
      )}`,
      {
        generateStack: true,
        userImpact: 'page will likely fail to load',
      },
    )
  }

  return url
}

const urlForRoute = (route: keyof typeof spaRoutes, mapping?: ISlugMapping): string => {
  const baseUrl: string = spaRoutes[route]

  const url = applyMappingForUrl(baseUrl, mapping)

  return `/spa${url}`
}
interface IUrlForRouteWithParamsArgs {
  route: keyof typeof spaRoutes
  mapping?: ISlugMapping
  queryParameters?: string
}

const urlForRouteWithParams = ({ route, mapping, queryParameters }: IUrlForRouteWithParamsArgs): string => {
  let url = urlForRoute(route, mapping)
  let queryString = ''

  if (queryParameters) {
    if (queryParameters.startsWith('?')) {
      queryString = `${queryParameters}`
    } else {
      queryString = `?${queryParameters}`
    }
  }

  url = `${url}${queryString}`

  return url
}

const urlForLapisRoute = (route: keyof typeof lapisRoutes, mapping?: ISlugMapping): string => {
  const baseUrl: string = lapisRoutes[route]

  const url = applyMappingForUrl(baseUrl, mapping)

  return url
}

const urlForAnalyticsPortalRoute = (route: keyof typeof analyticsPortalRoutes, mapping?: ISlugMapping): string => {
  const baseUrl = analyticsPortalRoutes[route]

  const url = applyMappingForUrl(baseUrl, mapping)
  return `/portal${url}`
}

const currentURLWithoutModalRoute = () => {
  // This is assuming you are using the '/m/' format
  // eslint-disable-next-line no-useless-escape
  return `${location.pathname.replace(new RegExp('/m/[^.]+', 'g'), '')}${location.search}`
}

type IValidLapisRoute = keyof typeof lapisRoutes
type IValidSpaRoute = keyof typeof spaRoutes

export type { IValidLapisRoute, IValidSpaRoute }
export {
  urlForRoute,
  urlForRouteWithParams,
  urlForLapisRoute,
  urlForAnalyticsPortalRoute,
  currentURLWithoutModalRoute,
  spaRoutes,
}
