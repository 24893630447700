import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  OnboardingQualificationInstanceAttachmentCreate,
  OnboardingQualificationInstanceAttachmentCreateVariables,
} from './__gql__/useOnboardingQualificationInstanceAttachmentCreateCodeGen'

const ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_CREATE = gql`
  mutation OnboardingQualificationInstanceAttachmentCreate(
    $qualificationInstanceId: ID!
    $filename: String!
    $mime: String!
    $size: Int!
    $extension: String!
  ) {
    onboardingQualificationInstanceAttachmentCreate(
      input: {
        qualificationInstanceId: $qualificationInstanceId
        filename: $filename
        mime: $mime
        size: $size
        extension: $extension
      }
    ) {
      presignedUploadUrl
      document {
        id
        createdAt
        createdAtDateTime
        displayName
        downloadUrlAbsolute
        humanReadableSize
        extension
        uploadStatus
        size
        personId
        downloadUrl
        category
      }
    }
  }
`

const useOnboardingQualificationInstanceAttachmentCreate = (successMessage: string) => {
  return useMutation<
    OnboardingQualificationInstanceAttachmentCreate,
    OnboardingQualificationInstanceAttachmentCreateVariables
  >(ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_CREATE, {
    successMessage,
  })
}

export { useOnboardingQualificationInstanceAttachmentCreate }
