import { useState, useMemo } from 'react'
import { Option } from 'react-select'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import * as Uuid from 'src/services/constants/uuid.yml'
import { useConfigCatWithKey } from 'src/services/configCat/services/configCat'
import { usePermissionCheck } from 'src/services/permissions/components/PermissionCheck'
import {
  IPeopleDropdownOption,
  IPeopleDropdownValue,
  IPersonOption,
  isBusinessUnitValue,
  isPeopleJobsValue,
} from './types'
import { usePerformPeopleDropdownSearch } from './gql'

const minSearchLength = 2

interface IUsePeopleSearchExtraDetails {
  showIndividualJobs: boolean
  currentlySelected: Array<Option<IPeopleDropdownValue>>
  hierarchicalPermissionsFilter?: string[]
  showNoJobRecords: boolean
  selectableValues: 'people_jobs_only' | 'people_jobs_business_units'
  validJobIds?: string[]
  hasUserAccount?: boolean
}

const usePeopleSearch = (
  searchText: string,
  {
    showIndividualJobs,
    currentlySelected,
    hierarchicalPermissionsFilter,
    selectableValues,
    showNoJobRecords,
    validJobIds,
    hasUserAccount,
  }: IUsePeopleSearchExtraDetails,
) => {
  const [showPastStaff, setShowPastStaff] = useState(false)
  const t = useScope('edm:features.PeopleDropdownInput')
  const { value: isPastPeoplePermissionEnabled } = useConfigCatWithKey('PAST_PEOPLE_PERMISSION', false)
  const { data: dropdownSearchResults, loading } = usePerformPeopleDropdownSearch(
    {
      searchText,
      showPastStaff,
      showIndividualJobs,
      hierarchicalPermissionsFilter,
      showNoJobRecords,
      validJobIds,
      hasUserAccount,
    },
    searchText.length < minSearchLength,
  )

  const { passedPermissionSlugs } = usePermissionCheck({
    targetType: 'functional',
    permissionSlugs: ['people.past_profile.view'],
  })
  const hasPastPeoplePermission = passedPermissionSlugs?.includes('people.past_profile.view')

  const results = useMemo(
    () =>
      dropdownSearchResults?.performPeopleDropdownSearch.results.filter((result) => {
        return !currentlySelected.some((current) => {
          if (isBusinessUnitValue(current.value) && result.businessUnit) {
            return current.value.businessUnitId === result.businessUnit.id
          }

          if (isPeopleJobsValue(current.value)) {
            if (showIndividualJobs) {
              const selectedPerson = current.value.personId === result.person.id

              const selectedJob = (current.value.jobId ?? undefined) === result.job?.id
              return selectedPerson && selectedJob
            }

            return current.value.personId === result.person.id
          }

          return false
        })
      }) ?? [],
    [currentlySelected, dropdownSearchResults, showIndividualJobs],
  )

  const peopleSearchOptions = useMemo(() => {
    const options: IPeopleDropdownOption[] = []

    if (!isPastPeoplePermissionEnabled || hasPastPeoplePermission) {
      options.push({
        type: 'control',
        toggleSet: () => setShowPastStaff(!showPastStaff),
        isSet: showPastStaff,
        name: t('optionControlShowPastStaff'),
      })
    }

    if (searchText.length < minSearchLength) {
      options.push({
        type: 'info',
        disabled: true,
        message: t('optionInfoSearchTooShort', { minSearchLength }),
      })

      return options
    }

    if (dropdownSearchResults?.performPeopleDropdownSearch.noResultsReason === 'NO_RESULTS' && !loading) {
      options.push({
        type: 'info',
        disabled: true,
        message: t('optionInfoNoResults'),
      })

      return options
    }

    if (dropdownSearchResults?.performPeopleDropdownSearch.noResultsReason === 'NO_PERMISSION' && !loading) {
      options.push({
        type: 'info',
        disabled: true,
        message: t('optionInfoNoPermission'),
      })

      return options
    }

    if (results) {
      options.push(
        ...results.flatMap<IPeopleDropdownOption>((data, index, businessUnitGroups) => {
          const businessUnitName = data.businessUnit?.name ?? t('optionBusinessUnitNone')

          const person: IPersonOption = {
            type: 'person',
            person: data.person,
            job: data.job,
            otherJobs: !showIndividualJobs ? data.otherJobs : [],
            value: {
              person: data.person,
              job: data.job ?? null,
              personId: data.person.id,
              jobId: data.job?.id ?? null,
            },
          } as const

          if (businessUnitGroups[index - 1]?.businessUnit?.id !== data.businessUnit?.id) {
            return [
              {
                type: 'businessUnit',
                disabled: selectableValues !== 'people_jobs_business_units' || !data.businessUnit?.id,
                value: {
                  businessUnitId: data.businessUnit?.id ?? Uuid.NIL,
                },
                name: businessUnitName,
              },
              person,
            ]
          }

          return [person]
        }),
      )
    }

    return options
  }, [
    showPastStaff,
    t,
    searchText.length,
    dropdownSearchResults,
    loading,
    results,
    showIndividualJobs,
    selectableValues,
    hasPastPeoplePermission,
    isPastPeoplePermissionEnabled,
  ])

  return {
    options: peopleSearchOptions,
    loading,
  }
}

export { usePeopleSearch }
