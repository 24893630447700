import {
  AvatarEntity,
  FontAwesomeIcon,
  Margin,
  Props,
  Text,
  TextInput,
  UnstyledLink,
  Variables,
} from '@intellihr/ui-components'
import React, { useState } from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { FilteredList } from 'src/services/filters/FilteredList'
import { StyledLimitedListHeight } from '../style'
import { ILinkableAvatarEntityProps } from '../types'

interface IFilterableAvatarEntityListProps {
  avatarEntityList: ILinkableAvatarEntityProps[]
  filterPlaceholder: string
}

const FilterableAvatarEntityList: React.FC<IFilterableAvatarEntityListProps> = ({
  avatarEntityList,
  filterPlaceholder,
}) => {
  const tCommon = useScope('common:components')
  const [search, setSearch] = useState<string>('')

  const noDataText = (
    <Text
      isInline={false}
      type={Props.TypographyType.Small}
      margins={{ top: Variables.Spacing.sXSmall }}
    >
      {tCommon('FilterableAvatarEntityList.searchNoResultsMessage', {
        defaultValue: 'Unfortunately, we couldn&apos;t find anything from your search',
      })}
    </Text>
  )

  return (
    <>
      <TextInput
        name="filterPersonJobList"
        value={search}
        onChange={setSearch}
        icon={
          <FontAwesomeIcon
            type="solid"
            icon="search"
          />
        }
        placeholder={filterPlaceholder}
      />
      <StyledLimitedListHeight>
        <FilteredList<ILinkableAvatarEntityProps>
          filters={[
            {
              kind: 'valueFilter',
              paths: ['primaryText', 'secondaryText'],
              caseSensitive: false,
              filterValue: search,
            },
          ]}
          noDataComponent={noDataText}
          rowCallback={({ row }) => {
            const { href, ...spreadableProps } = row

            return (
              <Margin margins={{ top: Variables.Layout.l2XSmall }}>
                <UnstyledLink href={href}>
                  <AvatarEntity
                    {...spreadableProps}
                    isHoverable
                  />
                </UnstyledLink>
              </Margin>
            )
          }}
          rowData={avatarEntityList}
        />
      </StyledLimitedListHeight>
    </>
  )
}

export { FilterableAvatarEntityList }
