import { Location } from 'history'
import React from 'react'
import { Redirect } from 'react-router'

interface IRedirectAs404Props {
  location: Location
}

class RedirectAs404 extends React.PureComponent<IRedirectAs404Props> {
  public render(): JSX.Element {
    const { location } = this.props

    if (location.pathname.includes('//')) {
      return (
        <Redirect
          to={{
            ...location,
            pathname: location.pathname.replace(/\/{2,}/, '/'),
          }}
        />
      )
    }

    return (
      <Redirect
        to={{
          ...location,
          state: {
            is404: true,
          },
        }}
      />
    )
  }
}
export { RedirectAs404 }
