import React from 'react'
import { Variables, Props, FontAwesomeIcon } from '@intellihr/ui-components'
import { TextMatch } from 'src/domain/EDM/internal/components/displays/TextMatch'
import { IBusinessUnitOption, IPeopleDropdownOption } from '../../../types'
import { StyledBusinessUnitWrapper } from '../style'

const BusinessUnitOption: React.FC<{
  option: IBusinessUnitOption
  selectable: boolean
  inputValue?: string
  isFocused?: boolean
  onSelect?: (option: IPeopleDropdownOption, event: React.MouseEvent) => void
}> = ({ option, inputValue, onSelect, selectable, isFocused }) => {
  return (
    <StyledBusinessUnitWrapper
      selectable={selectable}
      isFocused={isFocused}
      onClick={(event) => selectable && onSelect?.(option, event)}
      data-cy={`person-dropdown-business-unit-option-${option.name}`}
    >
      {selectable && (
        <FontAwesomeIcon
          icon="building"
          type="regular"
          size="small"
          margins={{ left: Variables.Spacing.sSmall, right: Variables.Spacing.sSmall }}
          color={Variables.Color.n800}
        />
      )}
      <TextMatch
        mainText={option.name}
        searchText={inputValue}
        otherTextProps={{ type: Props.TypographyType.Small, color: Variables.Color.n700 }}
        matchTextProps={{ type: Props.TypographyType.Small, weight: Variables.FontWeight.fwSemiBold }}
      />
    </StyledBusinessUnitWrapper>
  )
}

export { BusinessUnitOption }
