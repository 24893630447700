import React from 'react'
import { Skeleton } from '@intellihr/blueberry'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import { urlForRoute } from 'src/services/routes'
import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { useGetOnboardingStatus } from './hooks/useGetOnboardingStatus'
import { OnboardingPageContent } from './OnboardingPageContent'
import { useGetOnboardingPerson } from './steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'

const OnboardingPage: React.FC<{}> = () => {
  const history = useHistory()
  const { data: statusData, loading: statusLoading } = useGetOnboardingStatus()
  const { data: onboardingPerson, loading: onboardingPersonLoading } = useGetOnboardingPerson()
  const activeFrom = useUserContext().person.user.activeFrom
  const apolloClient = useApolloClient()

  if (statusLoading || onboardingPersonLoading) {
    return <Skeleton />
  }

  if (!statusData?.getOnboardingStatus) {
    return null
  }

  return (
    <OnboardingPageContent
      dashboardRedirect={() => history.push(urlForRoute('dashboard'))}
      activeFrom={activeFrom}
      status={statusData.getOnboardingStatus}
      onboardingPerson={onboardingPerson?.getOnboardingPerson ?? null}
      apolloClient={apolloClient}
    />
  )
}

export { OnboardingPage }
