import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Button, ButtonIcon, IconTrash, Inline, Link, Spinner, Tag, Text, TextStyle } from '@intellihr/blueberry'
import type { IDataTableColumnProps } from '@intellihr/ui-components/types/domain/Tables/DataTable/DataTable'
import { DataTable } from '@intellihr/ui-components'
import { Date } from 'src/services/uiComponentCandidates/Date'
import type { IOnboardingDocumentField } from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'
import { VisibilityOption } from 'src/domain/EDM/internal/components/forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { getProgress } from 'src/services/documents/components/DocumentTable/helpers'
import { useToasts } from 'src/services/toasts'
import { DocumentUploadStatus, PersonDocumentUploadStatus } from 'src/__gql__/globalTypes'
import { IUploadedDocument } from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/PersonalDetailsFormStep/PersonalDetailsFormStepContent'
import {
  ICreateOnboardingDocumentVariables,
  ISetOnboardingDocumentUploadStatusVariables,
  useUploadOnboardingDocument,
} from './hooks/useUploadOnboardingDocument'
import {
  useGetOnboardingPersonUploadedDocuments,
  useOnboardingPersonCreateDocumentMutation,
  useOnboardingPersonDeleteDocumentMutation,
  useSetOnboardingDocumentUploadStatusMutation,
  IDocument,
} from './gql'

type IDocumentV2 = IDocument & {
  progress?: number
}
interface IOnboardingDocumentUploadSectionProps {
  documentFolders: IOnboardingDocumentField[]
  onboardingPersonId: string
  uploadedDocuments: IUploadedDocument[]
  setUploadedDocuments: (documents: IUploadedDocument[]) => void
}
interface IOnboardingDocumentUploadTableRow {
  onboardingDocumentFolderId: string
  label: string
  visibility: VisibilityOption
  uploadedDocument?: IDocumentV2
  uploadedDocumentName?: string
  uploadedDocumentDate?: string
  progress?: number
}

const OnboardingDocumentUploadSection: React.FC<IOnboardingDocumentUploadSectionProps> = ({
  documentFolders,
  onboardingPersonId,
  setUploadedDocuments,
}) => {
  const tFeature = useScope('edm:features.Onboarding')
  const tSettingsOnboarding = useScope('edm:features.SettingsOnboardingTemplates')
  const tCommon = useScope('common:components')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFolderId, setSelectedFolderId] = useState<string>('')
  const [documentToDeleteId, setDocumentToDeleteId] = useState<string>('')
  const { data: onboardingPersonUploadedDocuments, loading: loadingUploadedDocuments } =
    useGetOnboardingPersonUploadedDocuments({
      onCompleted: (response) => {
        const documents = response.getOnboardingPerson?.onboardingDocuments.map((doc) => {
          return {
            documentId: doc.document.id,
            documentFolderId: doc.onboardingTemplateDocumentFolderId,
          }
        })
        setUploadedDocuments(documents ?? [])
      },
    })
  const [onboardingPersonDocumentCreate] = useOnboardingPersonCreateDocumentMutation(onboardingPersonId)
  const [onboardingPersonDocumentDelete, { loading: deletingDocument }] = useOnboardingPersonDeleteDocumentMutation()
  const [setOnboardingDocumentUploadStatus] = useSetOnboardingDocumentUploadStatusMutation()
  const postToast = useToasts()

  const handleDeleteDocument = (documentToDeleteId: string, onboardingTemplateDocumentFolderId: string) => {
    setDocumentToDeleteId(documentToDeleteId)
    onboardingPersonDocumentDelete({
      variables: {
        input: {
          documentId: documentToDeleteId,
          onboardingTemplateDocumentFolderId,
        },
      },
    }).then((response) => {
      if (response) {
        if (response.errors) {
          postToast({
            type: 'alert',
            content: tFeature('toastDocumentRemoveFail'),
            logType: 'warning',
          })
        }

        if (!response.errors && response.data?.onboardingPersonDocumentDelete.documentId) {
          postToast({
            type: 'success',
            content: tFeature('toastDocumentRemoveSuccess'),
          })
        }
      }
      setDocumentToDeleteId('')
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    })
  }
  const { currentlyUploadingDocuments, uploadDocument } = useUploadOnboardingDocument({
    onboardingTemplateDocumentFolderId: selectedFolderId,
    createDocument: useCallback(
      async ({
        onboardingTemplateDocumentFolderId,
        filename,
        extension,
        mime,
        size,
      }: ICreateOnboardingDocumentVariables) => {
        const response = await onboardingPersonDocumentCreate({
          variables: { input: { filename, extension, mime, size, onboardingTemplateDocumentFolderId } },
        })
        if (response.data) {
          const { document, presignedUrl } = response.data.onboardingPersonDocumentCreate
          return {
            document: {
              ...document,
              __typename: 'Document' as const,
              uploadStatus: document.uploadStatus as unknown as DocumentUploadStatus,
              size,
            },
            presignedUrl,
          }
        }
        return undefined
      },
      [onboardingPersonDocumentCreate],
    ),
    setDocumentUploadStatus: useCallback(
      async ({ id, uploadStatus, onboardingTemplateDocumentFolderId }: ISetOnboardingDocumentUploadStatusVariables) => {
        await setOnboardingDocumentUploadStatus({
          variables: {
            input: {
              onboardingTemplateDocumentFolderId,
              documentId: id,
              uploadStatus: uploadStatus as unknown as PersonDocumentUploadStatus,
            },
          },
        })
      },
      [setOnboardingDocumentUploadStatus],
    ),
  })

  const isUploading = useMemo(
    () =>
      Object.values(currentlyUploadingDocuments).some((uploadingDocument) => uploadingDocument?.state === 'uploading'),
    [currentlyUploadingDocuments],
  )

  const handleFilePicked = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      if (!e.target.files?.length) {
        return
      }

      const file: File = e.target.files[0]

      return uploadDocument(file)
    },
    [uploadDocument],
  )

  const documentsMemo = useMemo(() => {
    if (loadingUploadedDocuments) {
      return []
    }

    const formattedDocumentFolders: IOnboardingDocumentUploadTableRow[] = documentFolders.map((folder) => {
      if (onboardingPersonUploadedDocuments && onboardingPersonUploadedDocuments.getOnboardingPerson) {
        const uploadedDocument = onboardingPersonUploadedDocuments.getOnboardingPerson.onboardingDocuments.find(
          (onboardingDocument) => {
            return onboardingDocument.onboardingTemplateDocumentFolderId === folder.id
          },
        )

        if (uploadedDocument) {
          return {
            onboardingDocumentFolderId: folder.id,
            label: folder.label,
            visibility: folder.visibility.id as VisibilityOption,
            uploadedDocument: uploadedDocument.document as unknown as IDocumentV2,
            uploadedDocumentDate: uploadedDocument.document.createdAtTimestamp,
            uploadedDocumentName: uploadedDocument.document.displayName,
          }
        }
      }

      return {
        onboardingDocumentFolderId: folder.id,
        label: folder.label,
        visibility: folder.visibility.id as VisibilityOption,
      }
    })

    Object.values(currentlyUploadingDocuments).forEach((upload) => {
      if (upload) {
        const assignedFolder = formattedDocumentFolders.find(
          (folder) => folder.onboardingDocumentFolderId === upload.onboardingTemplateDocumentFolderId,
        )
        if (assignedFolder) {
          if (!assignedFolder.uploadedDocument) {
            assignedFolder.uploadedDocument = upload.document
          }
          assignedFolder.progress = getProgress(upload)
        }
      }
    })

    return formattedDocumentFolders
  }, [documentFolders, loadingUploadedDocuments, onboardingPersonUploadedDocuments, currentlyUploadingDocuments])

  const columns: Array<IDataTableColumnProps<IOnboardingDocumentUploadTableRow>> = [
    {
      Header: tFeature('documentUploadHeadingDocumentType'),
      accessor: 'label',
    },
    {
      Header: '',
      columnWidth: 'narrow',
      accessor: 'visibility',
      Cell: (props) => {
        if (props.value === VisibilityOption.OPTIONAL) {
          return (
            <Tag
              tone="neutral"
              staticColor="orange"
            >
              {tSettingsOnboarding('visibilityOptions.optional')}
            </Tag>
          )
        }

        return (
          <Tag
            tone="neutral"
            staticColor="red"
          >
            {tSettingsOnboarding('visibilityOptions.required')}
          </Tag>
        )
      },
    },
    {
      Header: tFeature('documentUploadHeadingName'),
      accessor: 'uploadedDocumentName',
      Cell: (props) => {
        if (
          props.row.original.uploadedDocument &&
          currentlyUploadingDocuments[props.row.original.uploadedDocument.id]
        ) {
          return <Text size="small">{props.value}</Text>
        }

        if (props.value) {
          return (
            <Link
              openNewTab={true}
              href={props.row.original.uploadedDocument?.downloadUrlAbsolute ?? ''}
              title={`${tCommon('buttons.view')} "${props.value}"`}
            >
              {props.value}
            </Link>
          )
        }

        return (
          <Text size="small">
            <TextStyle tone="subdued">-</TextStyle>
          </Text>
        )
      },
    },
    {
      Header: tFeature('documentUploadHeadingUploaded'),
      accessor: 'uploadedDocumentDate',
      Cell: (props) => {
        if (
          props.row.original.uploadedDocument &&
          currentlyUploadingDocuments[props.row.original.uploadedDocument.id]
        ) {
          const documentUpload = currentlyUploadingDocuments[props.row.original.uploadedDocument.id]
          const documentUploadStatus = documentUpload!.document.uploadStatus as unknown as PersonDocumentUploadStatus
          if (documentUploadStatus === PersonDocumentUploadStatus.PENDING || isUploading) {
            return (
              <Text size="small">
                <TextStyle tone="subdued">
                  {tCommon('DocumentTable.uploadingOverrideText')}{' '}
                  {props.row.original.uploadedDocument.humanReadableSize}
                </TextStyle>
              </Text>
            )
          }
        }

        if (props.value) {
          return (
            <Text size="small">
              <Date
                date={props.value}
                showTime={true}
              />
            </Text>
          )
        }

        return (
          <Text size="small">
            <TextStyle tone="subdued">-</TextStyle>
          </Text>
        )
      },
    },
    {
      accessor: 'actions',
      columnWidth: 'narrow',
      align: 'right',
      Header: '',
      Cell: (props) => {
        if (
          props.row.original.uploadedDocument &&
          currentlyUploadingDocuments[props.row.original.uploadedDocument.id]
        ) {
          const documentUpload = currentlyUploadingDocuments[props.row.original.uploadedDocument.id]
          const documentUploadStatus = documentUpload!.document.uploadStatus as unknown as PersonDocumentUploadStatus
          if (documentUploadStatus === PersonDocumentUploadStatus.PENDING) {
            return (
              <Inline
                align="center"
                alignY="center"
              >
                <Spinner></Spinner>
              </Inline>
            )
          }
        } else if (props.row.original.uploadedDocument) {
          const documentId = props.row.original.uploadedDocument.id
          return (
            <div style={{ width: '120px' }}>
              <ButtonIcon
                icon={IconTrash}
                variant="destructive"
                disabled={deletingDocument && documentToDeleteId === documentId}
                onClick={() => {
                  handleDeleteDocument(documentId, props.row.original.onboardingDocumentFolderId)
                }}
                label={tCommon('buttons.remove')}
                testId="onboarding-document-upload-table-remove-button"
              />
            </div>
          )
        }

        return (
          <div style={{ width: '120px' }}>
            <Button
              variant="secondary"
              disabled={isUploading}
              loading={isUploading && selectedFolderId === props.row.original.onboardingDocumentFolderId}
              onClick={() => {
                if (!isUploading) {
                  setSelectedFolderId(props.row.original.onboardingDocumentFolderId)
                  fileInputRef.current?.click()
                }
              }}
            >
              {tFeature('documentUploadChooseFile')}
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <DataTable<IOnboardingDocumentUploadTableRow>
        componentContext="onboarding-document-upload-table"
        isLoading={loadingUploadedDocuments}
        data={documentsMemo}
        columns={columns}
      ></DataTable>
      <input
        type="file"
        id="onboarding-document-input"
        ref={fileInputRef}
        data-cy="onboarding-document-input"
        onChange={handleFilePicked}
        style={{ display: 'none' }}
      />
    </>
  )
}

export { OnboardingDocumentUploadSection }
