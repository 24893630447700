// eslint-disable-next-line no-restricted-imports
import moment from 'moment'

interface ILeavePeriod {
  startDate?: string | null
  endDate?: string | null
}

const isOnLeave = (leavePeriod?: ILeavePeriod | null) => {
  if (!leavePeriod) {
    return false
  }

  if (!leavePeriod.startDate || !leavePeriod.endDate) {
    return false
  }

  const now = moment()

  return now.isBetween(leavePeriod.startDate, leavePeriod.endDate, 'days', '[)')
}

export { isOnLeave }
