import { Variables } from '@intellihr/ui-components'
import styled, { css } from 'styled-components'

interface ILinkContainerProps {
  inactive?: boolean
}

const LinkContainer = styled.div<ILinkContainerProps>`
  display: inline-block;
  width: 100%;
  padding: 6px;
  transition: background-color 0.3s ease-in-out;

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.65;
    `}

  &:hover {
    background-color: ${Variables.Color.n250};
  }
`

const DisplayName = styled.div`
  overflow: hidden;
`

export { LinkContainer, DisplayName }
