import { useCallback } from 'react'
import { useSelector } from 'react-redux'

interface IBrowserBreakpoints {
  mobile: boolean
  tablet: boolean
  desktop: boolean
}

const useBrowserBreakpoints = (): IBrowserBreakpoints => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const browserState = useCallback((state: any) => state.getIn(['browser']), [])
  const browser = useSelector(browserState)

  return {
    mobile: browser.lessThan.medium,
    tablet: browser.lessThan.large && browser.greaterThan.small,
    desktop: browser.greaterThan.medium,
  }
}

export { useBrowserBreakpoints }
