import React from 'react'
import { IPeopleDropdownOption, isBusinessUnitOption, isControlOption, isInfoOption, isPersonOption } from '../../types'
import { InfoOption, BusinessUnitOption, PersonOption, ControlOption } from './subcomponents'

interface IOptionComponent {
  isFocused?: boolean
  onSelect?: (option: IPeopleDropdownOption, event: React.MouseEvent) => void
  option: IPeopleDropdownOption
  inputValue?: string
  selectableValues: 'people_jobs_only' | 'people_jobs_business_units'
}

const OptionComponent: React.FC<IOptionComponent> = ({ option, inputValue, onSelect, isFocused, selectableValues }) => {
  if (isControlOption(option)) {
    return (
      <ControlOption
        option={option}
        isFocused={isFocused}
        onSelect={onSelect}
      />
    )
  }
  if (isPersonOption(option)) {
    return (
      <PersonOption
        option={option}
        inputValue={inputValue}
        isFocused={isFocused}
        onSelect={onSelect}
      />
    )
  }
  if (isBusinessUnitOption(option)) {
    return (
      <BusinessUnitOption
        option={option}
        inputValue={inputValue}
        isFocused={isFocused}
        selectable={selectableValues === 'people_jobs_business_units'}
        onSelect={onSelect}
      />
    )
  }
  if (isInfoOption(option)) {
    return <InfoOption option={option} />
  }

  return null
}

export { OptionComponent }

export type { IOptionComponent }
