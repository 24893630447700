import { Emoji, FontAwesomeIcon, Props, Spinner } from '@intellihr/ui-components'
import React, { useMemo, useState } from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { FilteredOptionList, IOptionProps } from 'src/domain/EDM/internal/components/uiComponents/OptionList'
import { IconCaretDown, Inline, Popover } from '@intellihr/blueberry'
import { UICInputGroupButton } from 'src/services/ui-components/UICInputGroupButton'
import { ListCountriesQuery } from '../../__gql__/CountryTelephoneInputGroupCodeGen'

interface ICountryDialCodeInputProps {
  onChange: (value: string) => void
  selectedCountry: ListCountriesQuery['listAllCountries'][0] | null
  countryData?: ListCountriesQuery['listAllCountries']
  loading: boolean
  value: string
}

const CountryDialCodeInput = (props: ICountryDialCodeInputProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const tFeature = useScope('edm:features.CountryTelephoneInputGroup')
  const { onChange, selectedCountry, countryData, loading, value } = props

  const memoizedCountryData = useMemo(
    () =>
      countryData?.map((country) => ({
        text: `${country.name.split('(')[0]} (+${country.dialCode})`,
        value: country.id,
        leftComponent: (
          <Emoji
            emoji={`flag-${country.isoCode}`}
            type={Props.TypographyType.Body}
          />
        ),
      })) || [],
    [countryData],
  )

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(value) => setIsPopoverOpen(value)}
      align="bottom"
      trigger={
        <UICInputGroupButton disabled={loading}>
          <Inline alignY="center">
            {selectedCountry && !loading ? (
              <Emoji
                emoji={`flag-${selectedCountry.isoCode}`}
                type={Props.TypographyType.Body}
              />
            ) : undefined}
            {selectedCountry && !loading ? (
              selectedCountry.isoCode ? (
                <Inline space="xxSmall">
                  {selectedCountry.isoCode.toUpperCase()}
                  <IconCaretDown />
                </Inline>
              ) : (
                ''
              )
            ) : (
              <Spinner
                type="fading-circle"
                size={16}
              />
            )}
          </Inline>
        </UICInputGroupButton>
      }
      content={
        <FilteredOptionList
          maxHeight={180}
          width="300"
          selectedValue={value}
          textInputProps={{
            name: 'countryFilter',
            icon: (
              <FontAwesomeIcon
                type="solid"
                icon="search"
              />
            ),
            placeholder: tFeature('inputPlaceholderCountryFilter'),
            width: '300px',
          }}
          handleClick={(option: IOptionProps) => {
            onChange(option.value.toString())
            setIsPopoverOpen(false)
          }}
          options={memoizedCountryData}
        />
      }
    />
  )
}

export { CountryDialCodeInput }
