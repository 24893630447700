import { MangoTurnBack } from '@intellihr/blueberry'
import { useErrorFooterButtons } from 'src/services/error/components/ErrorFooter'
import React from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { StyledTurnBackWrapper } from '../style'

const PageNotFound = () => {
  const tCommon = useScope('common:error')
  const errorFooterButtons = useErrorFooterButtons()

  return (
    <StyledTurnBackWrapper>
      <MangoTurnBack
        variant="page-not-found"
        title={tCommon('PageNotFound.header', { defaultValue: 'Page Not Found' })}
        description={[
          tCommon('PageNotFound.messageContent', {
            defaultValue: "The page that you're looking for was removed or doesn't exist.",
          }),
          tCommon('PageNotFound.pageShouldExistContent', {
            defaultValue:
              "If you think that this page should exist, first check that you've typed the correct URL, then contact your system administrator.",
          }),
        ]}
        actions={errorFooterButtons}
      />
    </StyledTurnBackWrapper>
  )
}

export { PageNotFound }
