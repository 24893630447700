import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ErrorBoundary } from 'src/services/error'
import { Toaster } from 'src/services/toasts'
import { InitialLoadBrandSpinner } from 'src/services/layout/components/InitialLoadBrandSpinner'
import { Routers } from 'src/routers'
import styled from 'styled-components'
import { PageFrame } from 'src/services/layout/components/PageFrame'
import { urlForRoute } from './services/routes'
import { OnboardingApp } from './services/layout/components/OnboardingApp/OnboardingApp'

const ReactSuspenseWrapper = styled.div`
  height: 100vh;
`

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<InitialLoadBrandSpinner />}>
        <Toaster />
        <Switch>
          <Route path={urlForRoute('onboarding')}>
            <OnboardingApp />
          </Route>
          <Route
            path="/"
            id="outer-container"
          >
            <PageFrame>
              <ErrorBoundary>
                <Suspense fallback={<ReactSuspenseWrapper data-cy="react-suspense-spinner" />}>
                  <Routers />
                </Suspense>
              </ErrorBoundary>
            </PageFrame>
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  )
}

const MemoApp = React.memo(App)

export { MemoApp as App }
