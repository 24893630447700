import React, { useEffect, useMemo } from 'react'
import { SelectInput } from '@intellihr/ui-components'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'
import * as Uuid from 'src/services/constants/uuid.yml'

interface ICustomInputForSelectInput {
  listOptions?: ReadonlyArray<{ name: string; id: string }>
  includeIds?: string[]
  props: ISelectInputProps
  // If this list is used for templates and need to add No Selection manually
  addLabelNotInList?: string
}

const CustomInputsForSelectInput: React.FC<ICustomInputForSelectInput> = ({
  listOptions,
  includeIds,
  props,
  addLabelNotInList,
}) => {
  const options = useMemo(
    () =>
      listOptions?.flatMap((option) => {
        if (!includeIds || includeIds.includes(option.id)) {
          return [
            {
              label: option.name,
              value: option.id,
            },
          ]
        }
        return []
      }) ?? [],
    [listOptions, includeIds],
  )

  if (options.length > 0 && addLabelNotInList && options[0].value !== Uuid.NIL) {
    options.unshift({ value: Uuid.NIL, label: addLabelNotInList })
  }

  useEffect(() => {
    const keys = options.map((option) => option.value)
    if (keys.length > 0) {
      switch (typeof props.value) {
        case 'string':
          {
            if (!keys.includes(props.value)) {
              props?.onChange?.(null)
            }
          }
          break
        case 'object':
          {
            props?.onChange?.(props.value?.filter((value) => keys.includes(value as string)))
          }
          break
      }
    }
  }, [options, props])

  return (
    <SelectInput
      {...props}
      isFetching={!listOptions}
      options={options}
    />
  )
}
export { CustomInputsForSelectInput }
