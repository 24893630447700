import { GridLayout, TextAreaInput, Variables, TextInput } from '@intellihr/ui-components'
import { find, trim } from 'lodash'
import React, { useState } from 'react'
import { Field } from 'redux-form'
import { ITenantDetailsProps, withTenantDetails } from 'src/services/user/TenantContext/helpers/hoc'
import {
  IReduxVerticalFormFieldParam,
  ReduxFormChange,
  ReduxVerticalFormField,
  useValidationRulesForField,
} from 'src/services/reduxForm'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { AddressTypeSelectInput } from './AddressTypeSelectInput'
import { AutocompleteAddressField } from '../AutocompleteAddressField'
import { ManualAddressField } from '../ManualAddressField'

interface IAddressFieldsProps {
  initialValues?: {
    addressTypeId: string
    currentAddress?: string
  }
  reduxFormChange: ReduxFormChange
  reduxEnforceIsRequired?: boolean
}

const BaseAddressFields: React.FC<IAddressFieldsProps & ITenantDetailsProps> = ({
  initialValues,
  reduxFormChange,
  reduxEnforceIsRequired,
  tenantDetails,
}) => {
  const tTerminology = useScope('edm:terminology')
  const tFeature = useScope('edm:features.AddressForm')
  const [isManual, setIsManual] = useState(false)
  const [isDisabledAutoCompleteInput, setIsDisabledAutoCompleteInput] = useState(false)
  const [autocompleteFullAddress, setAutocompleteFullAddress] = useState('')

  const initialRecord = (): JSX.Element | null => {
    if (initialValues && initialValues.currentAddress) {
      return (
        <Field<IReduxVerticalFormFieldParam>
          name="currentAddress"
          component={ReduxVerticalFormField}
          props={{
            label: tFeature('inputCurrentAddress'),
            component: TextAreaInput,
            inputProps: {
              row: 3,
              isDisabled: true,
            },
          }}
        />
      )
    }
    return null
  }

  const resetFullAddress = () => {
    if (isManual) {
      reduxFormChange('fullAddress', '')
      reduxFormChange('postcode', '')
      reduxFormChange('country', '')
    }
    reduxFormChange('latitude', null)
    reduxFormChange('longitude', null)
    setIsDisabledAutoCompleteInput(false)
  }

  const onClickManualOptionButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    resetFullAddress()
    setIsManual(!isManual)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAddressSubcomponents = (addressResult: any, location: any): void => {
    const postcode = find(addressResult, ['types', ['postal_code']])?.long_name
    const country = find(addressResult, ['types', ['country']])?.long_name

    reduxFormChange('postcode', postcode)
    reduxFormChange('country', country)
    reduxFormChange('latitude', location.lat)
    reduxFormChange('longitude', location.lng)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuggestSelectFullAddress = (suggest: any) => {
    if (suggest) {
      const addressResult = suggest.gmaps.address_components
      const fullAddress = suggest.label
      setIsDisabledAutoCompleteInput(true)
      updateAddressSubcomponents(addressResult, suggest.location)
      reduxFormChange('fullAddress', fullAddress)
      setAutocompleteFullAddress(fullAddress)
    }
  }

  const validatePostcode = useValidationRulesForField('postcode', (formValues) => {
    if (formValues.country) {
      const country = (formValues.country as string).toLowerCase()
      if (country === 'united kingdom' || country === 'uk') {
        return [
          {
            rule: 'regex',
            options: {
              regexPattern:
                '(^(([A-Za-z][0-9]{1,2})|([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))\\s[0-9][A-Za-z]{2}$)|(^[Gg][Ii][Rr] 0[Aa]{2}$)',
              errorMessage: tFeature('invalidUkPostcode'),
            },
          },
        ]
      }
    }
    return []
  })

  const validateCountry = useValidationRulesForField('country', [reduxEnforceIsRequired ? 'required' : undefined])
  const validateAddress = useValidationRulesForField('fullAddress', [reduxEnforceIsRequired ? 'required' : undefined])

  const formFields = (parsedTenantCountryName: string | null): JSX.Element => {
    return (
      <GridLayout gutterMarginX={Variables.Spacing.sLarge}>
        <GridLayout.Cell size={12}>
          {isManual ? (
            <ManualAddressField
              onClickManualOptionButton={onClickManualOptionButton}
              validation={validateAddress}
            />
          ) : (
            <AutocompleteAddressField
              isRequired
              label={
                initialValues && initialValues.currentAddress
                  ? tFeature('inputUpdateAddress')
                  : tTerminology('Address.term')
              }
              placeholder={tFeature('inputPlaceholderAutoComplete')}
              addressTypesIncluded={['address']}
              onClickManualOptionButton={onClickManualOptionButton}
              onSuggestSelect={onSuggestSelectFullAddress}
              isDisabled={isDisabledAutoCompleteInput}
              onResetClick={isDisabledAutoCompleteInput ? resetFullAddress : undefined}
              tenantCountry={parsedTenantCountryName}
              initialValue={
                isDisabledAutoCompleteInput && autocompleteFullAddress ? autocompleteFullAddress : undefined
              }
              validation={validateAddress}
            />
          )}
        </GridLayout.Cell>
        <GridLayout.Cell size={8}>
          <Field<IReduxVerticalFormFieldParam>
            name="country"
            component={ReduxVerticalFormField}
            validate={validateCountry}
            props={{
              isRequired: true,
              label: tTerminology('Address.country'),
              component: TextInput,
              inputProps: {
                componentContext: 'text-input-address-field-country',
                placeholder: tTerminology('Address.country'),
              },
            }}
          />
        </GridLayout.Cell>
        <GridLayout.Cell size={4}>
          <Field<IReduxVerticalFormFieldParam>
            name="postcode"
            component={ReduxVerticalFormField}
            validate={validatePostcode}
            props={{
              label: tTerminology('Address.postcode'),
              component: TextInput,
              inputProps: {
                componentContext: 'text-input-address-field-postcode',
                placeholder: tTerminology('Address.postcode'),
              },
            }}
          />
        </GridLayout.Cell>
      </GridLayout>
    )
  }

  const parseTenantCountryName = (countryName: string | null) => {
    if (countryName) {
      return trim(countryName.split('(')[0])
    }

    return null
  }

  return (
    <>
      <GridLayout
        gutterMarginX={Variables.Spacing.sLarge}
        cells={[
          {
            size: { min: 'fullWidth', tablet: 6 },
            content: <AddressTypeSelectInput enforceIsRequired={reduxEnforceIsRequired} />,
          },
          {
            size: { min: 'fullWidth' },
            content: initialRecord(),
          },
          {
            size: { min: 'fullWidth' },
            content: formFields(parseTenantCountryName(tenantDetails.tenantCountry?.name ?? null)),
          },
        ]}
      />
    </>
  )
}

const AddressFields = withTenantDetails(BaseAddressFields)

export { BaseAddressFields, AddressFields }
