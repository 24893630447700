import { Map, fromJS, List } from 'immutable'
import { some } from 'lodash'
import { handleActions } from 'redux-actions'
import { toJSSafe } from 'src/services/immutable/formatting'

interface IToast {
  id: string
  type: 'alert' | 'success'
  content: JSX.Element | string | string[]
  autoClose: boolean
  autoCloseTimeout: number
}

interface IToastState {
  toasts: IToast[]
}

type TReduxToastStore = List<Map<keyof IToast, IToast>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IImmutableToastState extends Map<string, any> {
  toJS(): IToastState
  get<K extends keyof IToastState>(key: K): IToastState[K]
}

const toasts: IToast[] = []

const initialState: IImmutableToastState = fromJS({
  toasts,
})

const toastReducer = handleActions<IImmutableToastState, IToast>(
  {
    POST_TOAST: (state, { payload }) => {
      const toastsArray = toJSSafe(state.getIn(['toasts']))

      if (payload && some(toastsArray, ['content', payload.content])) {
        return state
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return state.updateIn(['toasts'], (arr: any) => arr.push(payload))
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    REMOVE_TOAST: (state, { payload }: any) => {
      const toastsArray = toJSSafe(state.getIn(['toasts']))

      return state.updateIn(['toasts'], () => fromJS(toastsArray.filter((toast: IToast) => toast.id !== payload)))
    },
  },
  initialState,
)

export type { IToast, IImmutableToastState, TReduxToastStore }
export { toastReducer }
