import styled from 'styled-components'

// Colours are intentionally hardcoded to reduce number of items requiring import on load

const BrandSpinnerFrame = styled.div`
  width: 208px;
  height: 109px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const BrandSpinnerInner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const BrandWordmarkSVG = styled.svg``

const BrandLoadingBarContainer = styled.div`
  overflow: hidden;
  height: 24px;
`

const BrandLoadingBarSVG = styled.svg`
  height: 24px;
  animation: 1.25s linear infinite brand-accent-slide;
`

export { BrandSpinnerFrame, BrandSpinnerInner, BrandWordmarkSVG, BrandLoadingBarContainer, BrandLoadingBarSVG }
