import axios, { CancelTokenSource } from 'axios'
import { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { useToasts } from 'src/services/toasts'
import { Document, DocumentUploadStatus } from 'src/__gql__/globalTypes'
import { useScope } from 'src/services/i18n/LocalizationProvider'

interface ICreateOnboardingDocumentVariables {
  onboardingTemplateDocumentFolderId: string
  extension: string
  filename: string
  mime: string
  size: number
}

interface ISetOnboardingDocumentUploadStatusVariables {
  id: string
  onboardingTemplateDocumentFolderId: string
  uploadStatus: DocumentUploadStatus
}

interface IUploadOnboardingDocument {
  onboardingTemplateDocumentFolderId: string
  createDocument: (
    options: ICreateOnboardingDocumentVariables,
  ) => Promise<{ document: Document; presignedUrl: string } | undefined>
  setDocumentUploadStatus: (options: ISetOnboardingDocumentUploadStatusVariables) => void
}

interface ICurrentlyUploadingOnboardingDocumentsStatus {
  [documentId: string]:
    | {
        state: 'uploading' | 'uploaded' | 'failed'
        progressEvent: ProgressEvent | null
        document: Document
        onboardingTemplateDocumentFolderId: string
        cancelUpload: () => void
        file: File
      }
    | undefined
}

function getFileAndExtension(filename: string) {
  const splitName = filename.split('.')

  return {
    extension: splitName[splitName.length - 1],
    name: filename,
  }
}

function uploadDocumentToS3(
  url: string,
  document: File,
  progressCallback: (progressEvent: ProgressEvent) => void,
  cancelToken: CancelTokenSource,
) {
  return axios.put(url, document, {
    headers: {
      'Content-Type': document.type,
    },
    onUploadProgress: progressCallback,
    cancelToken: cancelToken.token,
  })
}

/**
 * Upload the given document to s3 & set it as successful in lapis for users in the onboarding flow
 * Steps:
 *  1 - Create a document in lapis with status: pending
 *  2 - Use the presigned url from 1) to upload to s3
 *  3 - Set the document status to successful.
 *
 * After a success, the document will be added to the gql state for the person.
 * The document will still be present in the state from this mutation - it's up
 * to the component to filter out duplicates.
 */
const useUploadOnboardingDocument = ({
  onboardingTemplateDocumentFolderId,
  createDocument,
  setDocumentUploadStatus,
}: IUploadOnboardingDocument) => {
  const tCommon = useScope('common:error.Document')
  const postToast = useToasts()
  const [currentlyUploadingDocuments, setCurrentlyUploadingDocuments] =
    useState<ICurrentlyUploadingOnboardingDocumentsStatus>({})

  const uploadDocument = useCallback(
    async (file: File) => {
      try {
        const { extension, name: filename } = getFileAndExtension(file.name)

        const response = await createDocument({
          onboardingTemplateDocumentFolderId,
          extension,
          filename,
          // since js can't always figure out the mimetype this is the most generic mime type
          mime: file.type && file.type !== '' ? file.type : 'application/octet-stream',
          size: file.size,
        })
        const cancelToken = axios.CancelToken.source()
        const cancelUpload = () => cancelToken.cancel()

        if (response) {
          const { document, presignedUrl } = response
          setCurrentlyUploadingDocuments((prevState) => ({
            ...prevState,
            [document.id]: {
              state: 'uploading',
              progressEvent: null,
              document,
              onboardingTemplateDocumentFolderId,
              file,
              cancelUpload,
            },
          }))

          // Debounce the callback so we aren't firing a billion dispatches a second
          const progressCallback = debounce((progressEvent) => {
            setCurrentlyUploadingDocuments((prevState) => ({
              ...prevState,
              [document.id]: prevState[document.id] && {
                state: prevState[document.id]!.state,
                progressEvent,
                document,
                onboardingTemplateDocumentFolderId,
                file,
                cancelUpload,
              },
            }))
          }, 100)

          try {
            await uploadDocumentToS3(presignedUrl, file, progressCallback, cancelToken)

            await setDocumentUploadStatus({
              onboardingTemplateDocumentFolderId,
              id: document.id,
              uploadStatus: DocumentUploadStatus.SUCCESS,
            })

            setCurrentlyUploadingDocuments((prevState) => ({
              ...prevState,
              [document.id]: {
                state: 'uploaded',
                progressEvent: null,
                file,
                document,
                onboardingTemplateDocumentFolderId,
                cancelUpload,
              },
            }))
          } catch (e) {
            if (!(e instanceof axios.Cancel)) {
              setCurrentlyUploadingDocuments((prevState) => ({
                ...prevState,
                [document.id]: {
                  state: 'failed',
                  progressEvent: null,
                  file,
                  document,
                  onboardingTemplateDocumentFolderId,
                  cancelUpload,
                },
              }))
              throw e
            }
          }

          setTimeout(
            () =>
              setCurrentlyUploadingDocuments((prevState) => {
                const newState = { ...prevState }
                delete newState[document.id]
                return newState
              }),
            3000,
          )
        }
      } catch (e) {
        if (e instanceof Error) {
          postToast({
            type: 'alert',
            content: tCommon('uploadingIssueMessage'),
            logType: 'error',
          })
          postToast({
            type: 'alert',
            content: e.message,
            logType: 'error',
          })
        }
      }
    },
    [
      createDocument,
      setDocumentUploadStatus,
      setCurrentlyUploadingDocuments,
      onboardingTemplateDocumentFolderId,
      postToast,
      tCommon,
    ],
  )

  const cancelUpload = useCallback(
    (id: string) => {
      const fileToCancel = currentlyUploadingDocuments[id]
      if (!fileToCancel) {
        postToast({
          type: 'alert',
          content: tCommon('cannotFindFileToCancel'),
          logType: 'warning',
        })

        return
      }

      fileToCancel.cancelUpload()
      setCurrentlyUploadingDocuments((prevState) => {
        const newState = { ...prevState }
        delete newState[id]
        return newState
      })
    },
    [currentlyUploadingDocuments, postToast, tCommon],
  )

  const retryUpload = useCallback(
    (id: string) => {
      const fileToRetry = currentlyUploadingDocuments[id]
      if (!fileToRetry) {
        postToast({
          type: 'alert',
          content: tCommon('cannotFindFileToRetry'),
          logType: 'warning',
        })

        return
      }

      const filePointer = fileToRetry.file
      setCurrentlyUploadingDocuments((prevState) => {
        const newState = { ...prevState }
        delete newState[id]
        return newState
      })
      return uploadDocument(filePointer)
    },
    [currentlyUploadingDocuments, postToast, uploadDocument, tCommon],
  )

  return {
    currentlyUploadingDocuments,
    uploadDocument,
    retryUpload,
    cancelUpload,
  }
}

export type {
  ICurrentlyUploadingOnboardingDocumentsStatus,
  ICreateOnboardingDocumentVariables,
  ISetOnboardingDocumentUploadStatusVariables,
}
export { useUploadOnboardingDocument }
