import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  OnboardingQualificationInstanceUpdate,
  OnboardingQualificationInstanceUpdateVariables,
} from './__gql__/useOnboardingQualificationInstanceUpdateCodeGen'

const ONBOARDING_QUALIFICATION_INSTANCE_UPDATE = gql`
  mutation OnboardingQualificationInstanceUpdate($input: QualificationInstanceUpdateInput!) {
    onboardingQualificationInstanceUpdate(input: $input) {
      qualificationInstance {
        id
        person {
          id
        }
        qualificationStatus {
          id
        }
        issuingOrganisation
        registrationNumber
        issueDate
        expiryDate
        notes
      }
    }
  }
`

const useOnboardingQualificationInstanceUpdate = (successMessage?: string) => {
  return useMutation<OnboardingQualificationInstanceUpdate, OnboardingQualificationInstanceUpdateVariables>(
    ONBOARDING_QUALIFICATION_INSTANCE_UPDATE,
    {
      successMessage,
    },
  )
}

export { useOnboardingQualificationInstanceUpdate }
