// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toJSSafe = (immutableData: any) => {
  if (immutableData && immutableData.toJS) {
    return immutableData.toJS()
  }

  return immutableData
}

export { toJSSafe }
