import { Button } from '@intellihr/blueberry'
import React, { useState } from 'react'
import { Variables, TextInput, GridLayout } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { IDocument } from './types'

const EditingOverride: React.FC<{
  data: IDocument
  onSubmit: (document: IDocument, value: string) => void
  onCancel: () => void
}> = ({ data, onSubmit, onCancel }) => {
  const tCommon = useScope('common:components')
  const displayNameWithoutExtension = data.displayName.substring(0, data.displayName.lastIndexOf(`.${data.extension}`))
  const [newDisplayName, setNewDisplayName] = useState(displayNameWithoutExtension)

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        onSubmit(data, `${newDisplayName}.${data.extension}`)
      }}
    >
      <GridLayout gutterMarginX={Variables.Spacing.sMedium}>
        <GridLayout.Cell size="auto">
          <TextInput
            autoFocus
            value={newDisplayName}
            onChange={setNewDisplayName}
            name="displayName"
          />
        </GridLayout.Cell>
        <GridLayout.Cell size="shrink">
          <Button
            disabled={newDisplayName === ''}
            variant="primary"
            type="submit"
          >
            {tCommon('buttons.confirm', { defaultValue: 'Confirm' })}
          </Button>
        </GridLayout.Cell>
        <GridLayout.Cell size="shrink">
          <Button
            variant="tertiary"
            onClick={onCancel}
          >
            {tCommon('buttons.cancel', { defaultValue: 'Cancel' })}
          </Button>
        </GridLayout.Cell>
      </GridLayout>
    </form>
  )
}

export { EditingOverride }
