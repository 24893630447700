import { gql } from '@apollo/client'
import { useQuery } from 'src/services/apollo'
import { GetIhrLogoUrl } from './__gql__/useGetIhrLogoUrlCodeGen'

const GET_IHR_LOGO_URL = gql`
  query GetIhrLogoUrl {
    intelliHRLogos {
      onWhiteRGBUrl
    }
  }
`
const useGetIhrLogoUrl = () => {
  return useQuery<GetIhrLogoUrl>(GET_IHR_LOGO_URL, {
    errorBehaviour: 'do-nothing',
  })
}

export { useGetIhrLogoUrl }
