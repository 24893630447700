import { Variables } from '@intellihr/ui-components'
import styled, { css } from 'styled-components'

const StyledContentWrapper = styled.div<{ desktop: boolean }>`
  ${({ desktop }) =>
    desktop &&
    css`
      margin: 0 auto;
      max-width: 900px;
      width: 100%;
    `}

  margin-top: ${Variables.Spacing.sLarge}px;
  padding-bottom: ${Variables.Spacing.s3XLarge}px;
`

const StyledContainer = styled.div`
  margin-left: ${Variables.Spacing.sLarge}px;
  margin-right: ${Variables.Spacing.sLarge}px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const StyledLogo = styled.img`
  max-height: 40px;
`

export { StyledContentWrapper, StyledLogo, StyledHeader, StyledContainer }
