import React from 'react'
import { Variables, Text, Props, FontAwesomeIcon } from '@intellihr/ui-components'
import { IControlOption, IPeopleDropdownOption } from '../../../types'
import { StyledControlComponent, StyledControlWrapper } from '../style'

const ControlOption: React.FC<{
  option: IControlOption
  isFocused?: boolean
  onSelect?: (option: IPeopleDropdownOption, event: React.MouseEvent) => void
}> = ({ option, isFocused, onSelect }) => {
  return (
    <StyledControlWrapper>
      <StyledControlComponent
        onClick={(event) => onSelect?.(option, event)}
        isSet={option.isSet}
        isFocused={isFocused}
      >
        {option.isSet && (
          <FontAwesomeIcon
            type="light"
            color={Variables.Color.i500}
            icon="check"
          />
        )}
        <Text
          margins={option.isSet ? { left: Variables.Spacing.sXSmall } : undefined}
          type={Props.TypographyType.XSmall}
          color={option.isSet ? Variables.Color.i500 : Variables.Color.n800}
        >
          {option.name}
        </Text>
      </StyledControlComponent>
    </StyledControlWrapper>
  )
}

export { ControlOption }
