import React, { useEffect } from 'react'
import { Button, Heading, Spinner, Stack, Text, TextStyle } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService/SessionManagementService'
import { S3AssetImage } from 'src/services/assets/helper'
import { Date } from 'src/services/uiComponentCandidates/Date'
import styled from 'styled-components'
import { getCurrentIsoDate, useUserTimezoneDateModifiers } from 'src/services/helpers/dates'
import { SESSION_DETAILS } from 'src/services/user/SessionDetailsProvider/hooks/useSessionProvider'
import type { ApolloClient } from '@apollo/client'

const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 20rem;
  padding: 0 5px;
  width: 100%;
`

interface ILandingPageStepProps {
  dashboardRedirect: () => void
  startDate: DateTime | null
  apolloClient: ApolloClient<object>
}

const LandingPageStep: React.FC<ILandingPageStepProps> = ({ dashboardRedirect, startDate, apolloClient }) => {
  const tFeature = useScope('edm:features.Onboarding')
  const { isSameOrBefore } = useUserTimezoneDateModifiers()
  const today = getCurrentIsoDate()
  const hasPreStartPassed = isSameOrBefore(startDate, today) ?? false
  const shouldRedirect = startDate === null || hasPreStartPassed

  useEffect(() => {
    const redirect = async () => {
      if (shouldRedirect) {
        // This is needed to prevent an infinite redirect loop caused by stale session data.
        // When the dashboard redirect is called the router checks the session state to check if `isOnboarding` is set to true.
        // Even once onboarding is complete, `isOnboarding` is still true because the session details haven't been updated,
        // so the user is redirected to the onboarding page. The onboarding page fetches the onboarding status from lapis,
        // which will return that the user is no longer onboarding (`isOnboarding` is false). The onboarding page will then redirect to the dashboard
        // and the loop repeats.
        //
        // To prevent this we use `refetchQueries` to refresh the session details. This corrects the state of `isOnboarding` and removes the redirect loop.
        // More details in refetchQueries can be found here: https://www.apollographql.com/docs/react/data/refetching/
        apolloClient.refetchQueries({ include: [SESSION_DETAILS] }).then(() => dashboardRedirect())
      }
    }

    redirect()
  }, [apolloClient, shouldRedirect, dashboardRedirect])

  return (
    <Stack
      align="center"
      space="large"
    >
      <Stack
        align="center"
        space="small"
      >
        <ImageWrapper>
          <S3AssetImage assetPath="images/illustrations/welcome-team.svg" />
        </ImageWrapper>
        <Heading variant="pageTitle">{tFeature('landingPageHeading')}</Heading>
        <ImageWrapper>
          <S3AssetImage assetPath="images/illustrations/brand-line.svg" />
        </ImageWrapper>
      </Stack>
      {shouldRedirect && (
        <Stack align="center">
          {tFeature('landingPageRedirectDescription')}
          <Spinner />
        </Stack>
      )}
      {!shouldRedirect && (
        <Stack align="center">
          <Text align="center">
            {tFeature('landingPageDescriptionLine1')}
            <TextStyle variant="strong">
              <Date date={startDate} />
            </TextStyle>
            <br />
            {tFeature('landingPageDescriptionLine2')}
          </Text>
          <Button
            onClick={() => {
              getSession().logout()
            }}
            variant="primary"
          >
            {tFeature('logoutButtonLabel')}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export { LandingPageStep }
