import React from 'react'
import { loadGoogleMapsApi } from 'src/services/googleMaps'
import { ReduxFormChange } from 'src/services/reduxForm'
import { AddressFields } from '../../../../../forms/PersonAddressForm'

const OnboardingAddressField: React.FC<{ reduxFormChange: ReduxFormChange } & { google: unknown }> = ({
  reduxFormChange,
}) => {
  return (
    <AddressFields
      reduxFormChange={reduxFormChange}
      reduxEnforceIsRequired={true}
    />
  )
}

const WrappedOnboardingAddressField = loadGoogleMapsApi<{}>({
  key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  libraries: ['places'],
})(OnboardingAddressField)

export { WrappedOnboardingAddressField as OnboardingAddressField }
