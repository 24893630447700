import React from 'react'
import { SideNavigation } from '@intellihr/blueberry'
import { useNavigationItems } from 'src/services/layout/components/PageFrame/components/Navigation/useNavigationItems'

const DesktopNavigation: React.FC = () => {
  const navigationData = useNavigationItems()

  if (!navigationData) {
    return (
      <SideNavigation
        items={[]}
        showSkeleton
      />
    )
  }

  return (
    <SideNavigation
      nativeApps={navigationData.nativeApps}
      externalApps={navigationData.externalApps}
      testId="desktop-navigation"
      logo={navigationData.logoUrl}
      items={navigationData.primaryItems}
      secondaryItems={navigationData.secondaryItems}
    />
  )
}

export { DesktopNavigation }
