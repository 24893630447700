import { gql } from '@apollo/client'
import { useQuery } from 'src/services/apollo'
import { customFieldValueUnionFragment } from 'src/domain/EDM/public/services/customFieldsGQLService'
import {
  GetOnboardingFormInstanceAndPerson,
  GetOnboardingFormInstance,
  GetOnboardingPerson,
  getOnboardingFormCompletionStatusAndUserPreStart,
} from './__gql__/useGetOnboardingFormInstanceAndPersonCodeGen'

type IOnboardingField = Exclude<
  GetOnboardingFormInstanceAndPerson['getOnboardingFormInstance'],
  undefined | null
>['template']['fields'][number]

type OnboardingCustomField = Exclude<
  GetOnboardingFormInstanceAndPerson['getOnboardingFormInstance'],
  undefined | null
>['template']['customFields'][number]

type OnboardingCustomFieldDefinition = Exclude<
  GetOnboardingFormInstanceAndPerson['getOnboardingFormInstance'],
  undefined | null
>['template']['customFields'][number]['customFieldDefinition']

type ICustomFieldValue = NonNullable<
  GetOnboardingFormInstanceAndPerson['getOnboardingPerson']
>['addresses'][number]['customFieldValues'][number]

type IOnboardingPerson = NonNullable<GetOnboardingPerson['getOnboardingPerson']>
type IOnboardingFormInstance = NonNullable<GetOnboardingFormInstance['getOnboardingFormInstanceById']>

type IJob = NonNullable<GetOnboardingFormInstanceAndPerson['getOnboardingPerson']>['jobs'][0]
type IJobRequirement = NonNullable<
  NonNullable<GetOnboardingFormInstanceAndPerson['getOnboardingPerson']>['jobs'][0]['jobRequirements'][0]
>

type ICompositeQualification = NonNullable<
  NonNullable<GetOnboardingFormInstanceAndPerson['getOnboardingPerson']>['compositeQualifications'][0]
>
type IOnboardingDocumentField = NonNullable<
  NonNullable<GetOnboardingFormInstanceAndPerson['getOnboardingFormInstance']>['template']['documentFolders'][0]
>

const onboardingFormFragment = gql`
  fragment OnboardingFormInstanceFragment on OnboardingFormInstance {
    id
    template {
      id
      name
      sections {
        id
        description
        sectionType {
          id
          name
          sortOrder
        }
      }
      fields {
        id
        fieldType {
          id
          name
          sectionTypeId
          sortOrder
        }
        visibility {
          id
          name
        }
      }
      customFieldCategories {
        id
        description
        customFieldCategory {
          id
          name
        }
      }
      customFields {
        id
        customFieldDefinition {
          id
          apiName
          name
          modelType
          type
          customFieldCategory {
            id
            name
            sortOrder
          }
          definition {
            ... on CustomFieldDefinitionPeopleDropdownTypeDefinition {
              allowSelectingIndividualJobs
            }
            ... on CustomFieldDefinitionSelectTypeDefinition {
              options {
                id
                label
                isEnabled
              }
            }
            ... on CustomFieldDefinitionTextTypeDefinition {
              regex
              validationError
            }
          }
          description
          isMandatory
        }
        sectionType {
          id
          name
          sortOrder
        }
        visibility {
          id
          name
        }
        sortOrder
      }
      documentFolders {
        id
        label
        documentFolderId
        visibility {
          id
          name
        }
      }
    }
  }
`

const onboardingPersonFragment = gql`
  ${customFieldValueUnionFragment}
  fragment OnboardingPersonFragment on OnboardingPerson {
    id
    addresses {
      id
      fullAddress
      addressType {
        id
        name
      }
      customFieldValues {
        ...CustomFieldValueUnionFragment
      }
    }
    emailAddresses {
      id
      email
      customFieldValues {
        ...CustomFieldValueUnionFragment
      }
    }
    phoneNumbers {
      id
      number
      country {
        id
      }
      customFieldValues {
        ...CustomFieldValueUnionFragment
      }
    }
    medicalConditions {
      id
      body
      isPublic
    }
    title {
      id
    }
    gender {
      id
    }
    firstName
    middleName
    lastName
    preferredName
    dateOfBirth
    emergencyContactEmailAddress
    emergencyContactName
    emergencyContactPhoneNumber
    emergencyContactRelationship
    emergencyContactPhoneNumberCountry {
      id
    }
    workRight {
      id
      countryId
    }
    workRightExpiryDate
    customFieldValues {
      ...CustomFieldValueUnionFragment
    }
    jobs {
      id
      companyStartDate
      positionStartDate
      jobRequirements: jobRequirementsAsAtJobStart {
        id
        startDate
        endDate
        jobRequirementableId
        jobRequirementableType
        jobRequirementable {
          ... on JobRequirementGroup {
            id
            name
            qualificationTemplates {
              id
              name
            }
          }
          ... on QualificationTemplate {
            id
            name
          }
        }
      }
    }
    compositeQualifications {
      currentStatusId
      currentExpiryDate
      currentIssueDate
      currentQualificationInstanceId
      currentNotes
      id
      issuingOrganisation
      registrationNumber
      renewalStatusId
      renewalIssueDate
      renewalExpiryDate
      renewalQualificationInstanceId
      isCompliant
      currentAttachments {
        id
        displayName
        extension
        size
        humanReadableSize
        createdAt
        createdAtDateTime
        downloadUrlAbsolute
        uploadStatus
        personId
        downloadUrl
        folderId
        category
      }
      renewalAttachments {
        id
        displayName
        extension
        size
        humanReadableSize
        createdAt
        createdAtDateTime
        downloadUrlAbsolute
        uploadStatus
        personId
        downloadUrl
        folderId
        category
      }
      compositeCompliance {
        restrictedStartDate
        restrictedEndDate
        isCompliant
      }
      qualificationTemplate {
        id
        name
        registrationNumberName
        qualificationExpiryType {
          id
        }
        qualificationRegistrationNumberVisibilityType {
          id
        }
        qualificationAttachmentRequirementType {
          id
        }
      }
    }
  }
`

const GET_ONBOARDING_FORM_INSTANCE_AND_PERSON = gql`
  ${onboardingFormFragment}
  ${onboardingPersonFragment}
  query GetOnboardingFormInstanceAndPerson {
    getOnboardingFormInstance {
      ...OnboardingFormInstanceFragment
    }
    getOnboardingPerson {
      ...OnboardingPersonFragment
    }
  }
`
const GET_ONBOARDING_FORM_INSTANCE = gql`
  ${onboardingFormFragment}
  query GetOnboardingFormInstance($onboardingFormId: ID!) {
    getOnboardingFormInstanceById(onboardingFormId: $onboardingFormId) {
      ...OnboardingFormInstanceFragment
    }
  }
`

const GET_ONBOARDING_PERSON = gql`
  ${onboardingPersonFragment}
  query GetOnboardingPerson {
    getOnboardingPerson {
      ...OnboardingPersonFragment
    }
  }
`

const GET_ONBOARDING_FORM_COMPLETION_STATUS_AND_USER_PRE_START = gql`
  query getOnboardingFormCompletionStatusAndUserPreStart {
    getOnboardingFormInstance {
      id
      submittedAt
      person {
        id
        user {
          id
          activeFrom
        }
      }
    }
  }
`

const useGetOnboardingFormInstanceAndPerson = () => {
  return useQuery<GetOnboardingFormInstanceAndPerson>(GET_ONBOARDING_FORM_INSTANCE_AND_PERSON, {
    errorBehaviour: 'do-nothing',
  })
}

const useGetOnboardingPerson = () => {
  return useQuery<GetOnboardingPerson>(GET_ONBOARDING_PERSON, {
    errorBehaviour: 'do-nothing',
  })
}

const useGetOnboardingFormInstance = (onboardingFormId: string) => {
  return useQuery<GetOnboardingFormInstance>(GET_ONBOARDING_FORM_INSTANCE, {
    errorBehaviour: 'do-nothing',
    variables: {
      onboardingFormId,
    },
  })
}

const useGetOnboardingFormCompletionStatusAndUserPreStart = () => {
  return useQuery<getOnboardingFormCompletionStatusAndUserPreStart>(
    GET_ONBOARDING_FORM_COMPLETION_STATUS_AND_USER_PRE_START,
    {
      errorBehaviour: 'do-nothing',
    },
  )
}

export {
  GET_ONBOARDING_PERSON,
  useGetOnboardingFormInstance,
  useGetOnboardingFormInstanceAndPerson,
  useGetOnboardingPerson,
  useGetOnboardingFormCompletionStatusAndUserPreStart,
}

export type {
  ICompositeQualification,
  ICustomFieldValue,
  IJob,
  IJobRequirement,
  IOnboardingDocumentField,
  IOnboardingField,
  IOnboardingFormInstance,
  IOnboardingPerson,
  OnboardingCustomField,
  OnboardingCustomFieldDefinition,
}
