import { Variables } from '@intellihr/ui-components'
import styled, { css } from 'styled-components'

const StyledGrid = styled.div<{ mobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${Variables.Spacing.s2XLarge}px;

  ${({ mobile }) =>
    mobile &&
    css`
      grid-template-columns: 1fr;
    `}
`

export { StyledGrid }
