import { Inline, Tag } from '@intellihr/blueberry'
import {
  Avatar,
  CircleSkeleton,
  DropdownMenu,
  Button,
  GridLayout,
  Props,
  TelephoneText,
  Text,
  TextSkeleton,
  TooltipPopover,
  UnstyledLink,
  Variables,
  FontAwesomeIcon,
} from '@intellihr/ui-components'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'
import React, { useMemo } from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { usePermissionCheck } from 'src/services/permissions/components/PermissionCheck'
import { urlForRoute } from 'src/services/routes'
import { getColorForEmploymentStatus } from 'src/domain/EDM/public/services/personEmploymentStatusService'
import { useS3Assets } from 'src/services/assets/helper'
import { isOnLeave } from '../helpers'
import { PersonTag } from '../PersonTag'
import { useGetPersonForHoverCard, IPerson } from './hooks'
import { ActionMenu, BodyContainer, HeaderContainer, StyledHeaderOverlay, StyledPrimaryPhoneNumber } from './style'

interface IPersonHoverCardContentProps {
  personId: string
  openInNewTab: boolean
}

const GeneratedJobNames: React.FC<{ jobs: IPerson['jobs'] }> = ({ jobs }) => (
  <>
    {jobs.map((job) => (
      <Text
        key={job.id}
        color={Variables.Color.n200}
        isInline={false}
        isTruncated
        type={Props.TypographyType.XSmall}
        weight={Variables.FontWeight.fwSemiBold}
        margins={{ bottom: Variables.Spacing.s2XSmall }}
      >
        {job.jobName.name}
      </Text>
    ))}
  </>
)

const PersonHoverCardContent: React.FC<IPersonHoverCardContentProps> = ({ personId, openInNewTab }) => {
  const tFeature = useScope('edm:features.PersonHoverCard')
  const { data: { getPerson: person, getTenantConfig } = {} } = useGetPersonForHoverCard(personId)
  const coverPictureUrl = useS3Assets('person-profile/cover-default.png')
  const { passedPermissionSlugs, someValid } = usePermissionCheck({
    targetType: 'people',
    targetId: personId,
    permissionSlugs: [
      'people.profile.view',
      'people.diary_notes.create',
      'people.training.create',
      'people.goals.create',
    ],
  })

  const coverUrls = useMemo(() => {
    const urls = [coverPictureUrl]

    if ((getTenantConfig?.defaultProfileCoverImage ?? '') !== '') {
      urls.unshift(getTenantConfig?.defaultProfileCoverImage ?? '')
    }

    if (person?.profileCoverPictureUrl) {
      urls.unshift(person.profileCoverPictureUrl)
    }

    return urls
  }, [person?.profileCoverPictureUrl, coverPictureUrl, getTenantConfig?.defaultProfileCoverImage])
  const onLeave = useMemo(() => {
    if (!person?.jobs) {
      return false
    }

    return person.jobs.some((job) => isOnLeave(job.jobLeave))
  }, [person])

  const dropdownMenuSections = useMemo(() => {
    const sections = []

    if (passedPermissionSlugs?.includes('people.profile.view')) {
      sections.push({
        text: tFeature('personActionDropdown.viewProfile'),
        href: urlForRoute('people.profile', { personId }),
      })
    }

    if (passedPermissionSlugs?.includes('people.diary_notes.create')) {
      sections.push({
        text: tFeature('personActionDropdown.addDiaryNote'),
        href: urlForRoute('people.diary_notes.create', { personId }),
      })
    }

    if (passedPermissionSlugs?.includes('people.training.create')) {
      sections.push({
        text: tFeature('personActionDropdown.addTraining'),
        href: urlForRoute('people.trainings.create', { personId }),
      })
    }

    if (passedPermissionSlugs?.includes('people.goals.create')) {
      sections.push({
        text: tFeature('personActionDropdown.addGoal'),
        href: urlForRoute('people.goals.create', { personId }),
      })
    }

    return sections
  }, [passedPermissionSlugs, personId, tFeature])

  if (!passedPermissionSlugs || !person) {
    return (
      <>
        <HeaderContainer backgroundUrls={[]}>
          <StyledHeaderOverlay>
            <div>
              <CircleSkeleton size={Props.AvatarSize.Large} />
            </div>
            <TextSkeleton
              width={100}
              type={Props.TypographyType.Small}
              margins={{ top: 8, bottom: 4 }}
            />
            <TextSkeleton
              width={150}
              type={Props.TypographyType.XSmall}
              margins={{ top: 2, bottom: 0 }}
            />
          </StyledHeaderOverlay>
        </HeaderContainer>
        <BodyContainer>
          <TextSkeleton
            width={70}
            type={Props.TypographyType.XSmall}
            margins={{ top: 0, bottom: 2 }}
          />
          <TextSkeleton
            width={120}
            type={Props.TypographyType.XSmall}
            margins={{ top: 0, bottom: 2 }}
          />
          <TextSkeleton
            width={100}
            type={Props.TypographyType.XSmall}
            margins={{ top: 0, bottom: 2 }}
          />
          <TextSkeleton
            width={120}
            type={Props.TypographyType.XSmall}
            margins={{ top: 0, bottom: 2 }}
          />
          <TextSkeleton
            width={80}
            type={Props.TypographyType.XSmall}
            margins={{ top: 8, bottom: 3 }}
          />
          <GridLayout
            margins={{ top: 4, bottom: 4 }}
            verticalAlignment={GridLayout.VerticalAlignment.Middle}
            gutterMarginX={Variables.Spacing.sXSmall}
          >
            <GridLayout.Cell size="shrink">
              <CircleSkeleton size={Props.AvatarSize.Small} />
            </GridLayout.Cell>
            <GridLayout.Cell>
              <TextSkeleton
                width={125}
                type={Props.TypographyType.Small}
                margins={{ top: 0, bottom: 0 }}
              />
              <TextSkeleton
                width={140}
                type={Props.TypographyType.XSmall}
                margins={{ top: 2, bottom: 0 }}
              />
            </GridLayout.Cell>
          </GridLayout>
        </BodyContainer>
      </>
    )
  }
  const [primaryJob, ...otherJobs] = person.jobs

  return (
    <>
      <HeaderContainer backgroundUrls={coverUrls}>
        <UnstyledLink href={urlForRoute('people.profile', { personId: person.id })}>
          <StyledHeaderOverlay>
            <div>
              <Avatar
                initials={person.initials}
                imageUrl={person.profilePictureUrl ?? undefined}
                size={Props.AvatarSize.Large}
                statusDot={getColorForEmploymentStatus(onLeave, person.employmentStatusId)}
              />
            </div>
            <Text
              weight={Variables.FontWeight.fwSemiBold}
              type={Props.TypographyType.Small}
              color={Variables.Color.n100}
              margins={{ top: 8, bottom: 4 }}
            >
              {person.displayName}
            </Text>
            <Inline>
              {primaryJob && (
                <Text
                  type={Props.TypographyType.XSmall}
                  color={Variables.Color.n100}
                >
                  {primaryJob.jobName.name}
                </Text>
              )}
              {otherJobs.length > 0 && (
                <TooltipPopover
                  width={450}
                  variant={TooltipPopover.Variant.Dark}
                  toggleComponent={({ openMenu, closeMenu, toggleComponentRef, ariaProps }) => (
                    <span
                      onMouseEnter={openMenu}
                      onMouseLeave={closeMenu}
                      ref={toggleComponentRef}
                      {...ariaProps}
                      style={{ pointerEvents: 'auto' }}
                    >
                      <Tag tone="neutral">+ {otherJobs.length}</Tag>
                    </span>
                  )}
                >
                  <GeneratedJobNames jobs={otherJobs} />
                </TooltipPopover>
              )}
            </Inline>
          </StyledHeaderOverlay>
        </UnstyledLink>
        {someValid && (
          <ActionMenu>
            <DropdownMenu
              toggleComponent={({ toggleMenu, toggleComponentRef, ariaProps }) => (
                <Button
                  onClick={toggleMenu}
                  iconAlignment="center"
                  innerRef={toggleComponentRef}
                  buttonOverrides={{ ...ariaProps }}
                  size="small"
                  componentContext="person-hover-card-button-more-options"
                  icon={
                    <FontAwesomeIcon
                      icon="ellipsis-v"
                      type="solid"
                    />
                  }
                />
              )}
              sections={dropdownMenuSections}
            />
          </ActionMenu>
        )}
      </HeaderContainer>
      <BodyContainer>
        {person.primaryJob && (
          <Text
            isInline={false}
            isTruncated
            type={Props.TypographyType.XSmall}
            weight={Variables.FontWeight.fwSemiBold}
            margins={{
              bottom: Variables.Spacing.s2XSmall,
            }}
          >
            {person.primaryJob.jobBusinessUnit.businessUnit.name}
          </Text>
        )}
        {person.primaryJob && !person.primaryJob.jobLocation.location?.isNoLocation && (
          <Text
            isInline={false}
            isTruncated
            type={Props.TypographyType.XSmall}
            margins={{
              bottom: Variables.Spacing.s2XSmall,
            }}
          >
            {person.primaryJob.jobLocation.location?.name}
          </Text>
        )}
        {person.primaryJob?.isOnExtendedLeave && (
          <>
            <Tag
              testId="person-hover-card-pill-on-extended-leave"
              tone="neutral"
              staticColor="orange"
            >
              {tFeature('labelOnExtendedLeave')}
            </Tag>
            {person.primaryJob.jobLeave && (
              <Text type={Props.TypographyType.XSmall}>
                {' '}
                {tFeature('labelUntilDate', {
                  date: moment(person.primaryJob.jobLeave.endDate).format('D MMM YYYY'),
                })}
              </Text>
            )}
          </>
        )}
        {person.primaryEmailAddress && (
          <Text
            isInline={false}
            type={Props.TypographyType.XSmall}
            margins={{
              bottom: Variables.Spacing.s2XSmall,
            }}
          >
            <Text.Link
              href={`mailto:${person.primaryEmailAddress.email}`}
              target="_blank"
            >
              {person.primaryEmailAddress.email}
            </Text.Link>
          </Text>
        )}
        {person.primaryPhoneNumber && (
          <StyledPrimaryPhoneNumber>
            <TelephoneText
              phoneNumber={person.primaryPhoneNumber.number}
              countryCode={person.primaryPhoneNumber.country?.isoCode}
              type={Props.TypographyType.XSmall}
              isLink
              showFlag={false}
              componentContext="person-hover-card-telephone-text"
            />
          </StyledPrimaryPhoneNumber>
        )}
        {person.primaryJob?.jobSupervisor.supervisorJob && (
          <>
            <Text
              tag="p"
              type={Props.TypographyType.XSmall}
              color={Variables.Color.n600}
              margins={{ top: 8, bottom: 3 }}
            >
              {tFeature('supervisorTagTitle')}
            </Text>
            <PersonTag
              openInNewTab={openInNewTab}
              person={person.primaryJob.jobSupervisor.supervisorJob.person}
              href={urlForRoute('people.profile', {
                personId: person.primaryJob.jobSupervisor.supervisorJob.person.id,
              })}
              positionTitle={person.primaryJob.jobSupervisor.supervisorJob.jobName.name}
              avatarSize={Props.AvatarSize.Small}
              statusDot={getColorForEmploymentStatus(
                person.primaryJob.jobSupervisor.supervisorJob.isOnExtendedLeave,
                person.primaryJob.jobSupervisor.supervisorJob.jobStatus,
              )}
            />
          </>
        )}
      </BodyContainer>
    </>
  )
}

export { PersonHoverCardContent }
