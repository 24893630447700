interface IGenericSortBy {
  id: string
  desc?: boolean
}

const noop = () => undefined

const scrollToTop = () => window.scrollTo(0, 0)

/* prettier-ignore */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

const isEmail = (email: string) => EMAIL_REGEX.exec(email)

const isEmpty = (obj: object | undefined | null) => !Object.entries(obj || {}).length

const stringIsNumber = (value?: string | null): value is string =>
  value !== '' && value !== null && !isNaN(Number(value))

const parseFloatOrUndefined = (value?: string) => (stringIsNumber(value) ? parseFloat(value) : undefined)

const isAbsoluteUrl = (url?: string | null) => {
  if (url) {
    const isAbsolute = new RegExp('^([a-z]+://|//)', 'i')

    return isAbsolute.test(url)
  }
  return false
}

function isArrayOfStrings(testValue: unknown): testValue is string[] {
  if (!Array.isArray(testValue)) {
    return false
  }

  for (let i = 0; i < testValue.length; i++) {
    if (typeof testValue[i] !== 'string') {
      return false
    }
  }

  return true
}

const getLocalTimezoneFromIntl = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const includesAll = <T>(sourceArray: T[], includes: T[]) => {
  return includes.every((includeValue) => sourceArray.includes(includeValue))
}

const sortTableRows = <T, K extends IGenericSortBy>(rows: T[], sortBy: K[]): T[] => {
  // Handle sorting of child items
  const getDescendantProp = <T>(obj: T, desc: string) => {
    const arr = desc.split('.')

    while (arr.length) {
      obj = obj[arr.shift() ?? 0]
    }
    return obj
  }

  return sortBy.length
    ? [...rows].sort((a, b) => {
        for (let i = 0; i < sortBy.length; ++i) {
          const aId = getDescendantProp(a, sortBy[i].id)
          const bId = getDescendantProp(b, sortBy[i].id)

          if (aId > bId) {
            return sortBy[i].desc ? -1 : 1
          }
          if (aId < bId) {
            return sortBy[i].desc ? 1 : -1
          }
        }
        return 0
      })
    : rows
}

export {
  getLocalTimezoneFromIntl,
  includesAll,
  isAbsoluteUrl,
  isArrayOfStrings,
  isEmail,
  isEmpty,
  noop,
  parseFloatOrUndefined,
  scrollToTop,
  sortTableRows,
  stringIsNumber,
}
