import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'
import { listCustomFieldDefinitionsFragment } from './listCustomFieldDefinitionsFragment'
import { useListCustomFieldDefinitionsWithPermissions } from './listDefinitionsWithManagePermission'
import { ListAllCustomFieldDefinitions, ListAllCustomFieldCategories } from './__gql__/indexCodeGen'

const customFieldDefinitionFragment = gql`
  fragment CustomFieldDefinitionFragment on CustomFieldDefinition {
    id
    name
    description
    apiName
    isSensitive
    isMandatory
    customFieldCategoryNullable {
      id
      name
    }
  }
`

const customFieldValueUnionFragment = gql`
  fragment CustomFieldValueUnionFragment on CustomFieldValue {
    apiName
    modelId
    type
    startDate
    endDate
    definition {
      id
      name
      isSensitive
    }
    valueUnion {
      ... on CustomFieldValueMultiSelect {
        ids
        labels
      }
      ... on CustomFieldValueSingleSelect {
        id
        label
      }
      ... on CustomFieldValueNumber {
        number
      }
      ... on CustomFieldValueText {
        text
        regexValid
      }
      ... on CustomFieldValuePeopleDropdown {
        options {
          job {
            id
            isOnExtendedLeave
            jobName {
              id
              name
            }
          }
          person {
            id
            initials
            displayName
            employmentStatusId
            profilePictureUrl(size: MEDIUM)
            primaryJob {
              id
              isOnExtendedLeave
              jobName {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const LIST_ALL_CUSTOM_FIELD_DEFINITIONS = gql`
  ${listCustomFieldDefinitionsFragment}

  query ListAllCustomFieldDefinitions {
    ...AllCustomFieldDefinitions
  }
`

const LIST_ALL_CUSTOM_FIELD_CATEGORIES_FOR_REM = gql`
  query ListAllCustomFieldCategories($modelType: [CustomFieldDefinitionModelType!]) {
    listAllCustomFieldCategories(isEnabled: true, modelType: $modelType) {
      id
      name
      sortOrder
    }
  }
`

const useListAllCustomFieldDefinitionsQuery = () =>
  useQuery<ListAllCustomFieldDefinitions>(LIST_ALL_CUSTOM_FIELD_DEFINITIONS, {
    errorBehaviour: 'do-nothing',
  })

const useListAllCustomFieldCategoriesQuery = (modelType: CustomFieldDefinitionModelType) =>
  useQuery<ListAllCustomFieldCategories>(LIST_ALL_CUSTOM_FIELD_CATEGORIES_FOR_REM, {
    errorBehaviour: 'do-nothing',
    variables: {
      modelType: [modelType],
    },
  })

export {
  LIST_ALL_CUSTOM_FIELD_DEFINITIONS,
  useListAllCustomFieldDefinitionsQuery,
  customFieldValueUnionFragment,
  listCustomFieldDefinitionsFragment,
  customFieldDefinitionFragment,
  useListCustomFieldDefinitionsWithPermissions,
  useListAllCustomFieldCategoriesQuery,
}
