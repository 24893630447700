import { Variables } from '@intellihr/ui-components'
import styled, { css } from 'styled-components'

const Container = styled.div`
  position: relative;
`

const HoverCard = styled.div<{ arrowDirection: 'TOP' | 'BOTTOM'; width: number }>`
  position: absolute;
  width: ${({ width }) => width}px;
  transform: translateX(-50%);
  background-color: ${Variables.Color.n100};
  margin-bottom: 12px;
  box-shadow: ${Variables.BoxShadow.bsLv3Active};
  border-radius: 4px;
  z-index: ${Variables.ZIndex.zIndexDropdownMenu};

  &:before {
    // the outline of the arrow
    position: absolute;
    content: '';

    left: 50%;
    transform: translateX(-50%);

    border-left: 9px solid transparent;
    border-right: 9px solid transparent;

    ${({ arrowDirection }) =>
      arrowDirection === 'TOP'
        ? css`
            top: -11px;
            border-bottom: 11px solid rgba(0, 0, 0, 0.4);
          `
        : css`
            top: 100%;
            border-top: 11px solid rgba(0, 0, 0, 0.4);
          `}
    z-index: ${Variables.ZIndex.zIndexDropdownMenu + 1};
  }

  &:after {
    // the arrow of the popover
    position: absolute;
    content: '';

    left: 50%;
    transform: translateX(-50%);

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    ${({ arrowDirection }) =>
      arrowDirection === 'TOP'
        ? css`
            top: -11px;
            border-bottom: 11px solid ${Variables.Color.n100};
          `
        : css`
            top: 100%;
            border-top: 11px solid ${Variables.Color.n100};
          `}
    z-index: ${Variables.ZIndex.zIndexDropdownMenu + 2};
  }
`

export { Container, HoverCard }
