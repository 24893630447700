import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from 'src/services/apollo'
import { Props, Text, Variables } from '@intellihr/ui-components'
import { emailRegex } from 'src/services/reduxForm/components/ReduxForm/services/rules'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { GET_EMAIL_ADDRESS_USAGE_COUNT } from './gql'
import { GetEmailAddressUsageCount, GetEmailAddressUsageCountVariables } from './__gql__/gqlCodeGen'

const useEmailUniquenessCheck = (currentEmailValue?: string, currentEmailId?: string) => {
  const tFeature = useScope('edm:features.PersonCreateForm')
  const [emailForQuery, setEmailForQuery] = useState('')
  const currentEmailIdArray: [string] | undefined = currentEmailId !== undefined ? [currentEmailId] : undefined
  const [loadEmailAddressUsageCount, { data, loading }] = useLazyQuery<
    GetEmailAddressUsageCount,
    GetEmailAddressUsageCountVariables
  >(GET_EMAIL_ADDRESS_USAGE_COUNT, {
    errorBehaviour: 'do-nothing',
    variables: {
      emailAddress: emailForQuery,
      ignoreIds: currentEmailIdArray,
    },
  })

  const handleBlur = useCallback(() => {
    if (currentEmailValue && currentEmailValue.match(emailRegex)) {
      setEmailForQuery(currentEmailValue)
      loadEmailAddressUsageCount()
    }
  }, [loadEmailAddressUsageCount, currentEmailValue])

  useEffect(() => {
    if (currentEmailValue) {
      handleBlur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let actionMessage

  if (emailForQuery === currentEmailValue) {
    if ((data?.getEmailAddressUsageCount ?? 0) > 0) {
      actionMessage = (
        <Text
          color={Variables.Color.y600}
          type={Props.TypographyType.Small}
        >
          {tFeature('textEmailNotUnique', { count: data?.getEmailAddressUsageCount ?? 0 })}
        </Text>
      )
    }

    if (!data?.getEmailAddressUsageCount && loading) {
      actionMessage = (
        <Text
          color={Variables.Color.b600}
          type={Props.TypographyType.Small}
        >
          {tFeature('textEmailUniquenessLoading')}
        </Text>
      )
    }
  }

  return {
    actionMessage,
    handleBlur,
  }
}

export { useEmailUniquenessCheck }
