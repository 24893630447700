import Immutable, { fromJS } from 'immutable'

class SessionStateLoader {
  private sessionStorage: Storage = window.sessionStorage

  private sessionKey = 'intelliHR:state'

  private defaultState = Immutable.Map()

  public loadState() {
    try {
      const sessionState = this.sessionStorage.getItem(this.sessionKey)

      if (!sessionState) {
        return this.defaultState
      }

      const serializedState = JSON.parse(sessionState)

      return fromJS({
        toastReducer: serializedState,
      })
    } catch (err) {
      return this.defaultState
    }
  }

  public saveState(state: object) {
    const serializedState = JSON.stringify(state) || ''
    this.sessionStorage.setItem(this.sessionKey, serializedState)
  }
}

export { SessionStateLoader }
