import React from 'react'
import { FontAwesomeIconValue } from '@intellihr/ui-components/types/domain/Icons'
import { Avatar, Props, Text, Variables, FontAwesomeIcon } from '@intellihr/ui-components'
import { ActionIconWrapper, ChipWrapper, TextWrapper, PreventShrinkWrapper } from './style'

interface IChipProps {
  avatarUrl?: string
  initials?: string
  statusDot?: 'primary' | 'secondary' | 'success' | 'warning' | 'alert' | 'neutral' | 'highlight' | 'dark'
  primaryText: string
  secondaryText?: string
  primaryIcon?: FontAwesomeIconValue
  primaryIconType?: 'solid' | 'regular' | 'light' | 'duotone'
  actionIcon?: FontAwesomeIconValue
  actionIconType?: 'solid' | 'regular' | 'light' | 'duotone'
  onActionClick?: () => void
  margins?: Props.IMargins
  onBodyClick?: () => void // not implemented yet
}

const Chip: React.FC<IChipProps> = ({
  avatarUrl,
  primaryIconType,
  primaryIcon,
  initials,
  statusDot,
  primaryText,
  secondaryText,
  actionIcon,
  onActionClick,
  onBodyClick,
  margins,
  actionIconType,
}) => {
  if (onBodyClick) {
    throw new Error('onBodyClick has not been implemented yet, if you wish to use it please implement it')
  }

  if (actionIcon && !onActionClick) {
    throw new Error('onActionClick must be provided if using actionIcon')
  }

  const showAvatar = avatarUrl || initials
  return (
    <ChipWrapper
      noAvatar={!showAvatar}
      noAction={!actionIcon}
      margins={margins}
    >
      {showAvatar && !primaryIcon && (
        <PreventShrinkWrapper>
          <Avatar
            initials={initials}
            imageUrl={avatarUrl}
            statusDot={statusDot}
            size={Props.AvatarSize.Small}
          />
        </PreventShrinkWrapper>
      )}
      {primaryIcon && (
        <PreventShrinkWrapper>
          <FontAwesomeIcon
            icon={primaryIcon}
            margins={{ right: Variables.Spacing.sSmall }}
            type={primaryIconType ?? 'regular'}
            size="small"
            color={Variables.Color.n800}
          />
        </PreventShrinkWrapper>
      )}
      <TextWrapper noAvatar={!showAvatar}>
        <Text
          type={Props.TypographyType.Small}
          isTruncated
        >
          {primaryText}
        </Text>
        {secondaryText && (
          <Text
            isTruncated
            type={Props.TypographyType.XSmall}
            color={Variables.Color.n700}
          >
            {secondaryText}
          </Text>
        )}
      </TextWrapper>
      {actionIcon && (
        <PreventShrinkWrapper>
          <ActionIconWrapper
            onClick={onActionClick}
            onTouchEnd={(event) => {
              // this function exists because the onClick event won't fire on mobile if
              // there is an parent element that uses touch events and preventDefault
              if (onActionClick) {
                onActionClick()
                // this prevents the emulated touch event from firing when on mobile so
                // we don't have two calls of onActionClick. this should be fine since there's
                // no items below this that have a default behaviour
                event.preventDefault()
              }
            }}
          >
            <FontAwesomeIcon
              icon={actionIcon}
              type={actionIconType ?? 'solid'}
              size="xsmall"
              color={Variables.Color.n500}
            />
          </ActionIconWrapper>
        </PreventShrinkWrapper>
      )}
    </ChipWrapper>
  )
}

export type { IChipProps }
export { Chip }
