import React from 'react'
import { InitialLoadBrandSpinner } from 'src/services/layout/components/InitialLoadBrandSpinner'
import { TenantContext } from 'src/services/user/TenantContext'
import { UserContext } from 'src/services/user/UserContext'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { ISession, ISessionProvider } from './hooks/useSessionProvider'
import { SessionDetails } from './hooks/__gql__/useSessionProviderCodeGen'

type IModule = NonNullable<SessionDetails['listAllModules'][0]>

interface IProps {
  sessionDetails: (session: ISession) => ISessionProvider
}

const SessionDetailsProvider: React.FC<React.PropsWithChildren<IProps>> = ({ sessionDetails, children }) => {
  const { data, timezone, dateFormat, timeFormat } = sessionDetails(getSession())

  if (data?.getUser && timezone) {
    /**
     * Our session details uses a format with the person at the top rather than the user for legacy reasons.
     * We fetch the logged in user details via the user id, then transform into that format here
     */
    const reformattedPerson = {
      id: data.getUser.person.id,
      displayName: data.getUser.person.displayName ?? 'Unknown Person',
      preferredOrFirstName: data.getUser.person.preferredOrFirstName ?? 'Unknown',
      lastName: data.getUser.person.lastName ?? 'Unknown',
      legalName: data.getUser.person.legalName ?? 'Unknown',
      initials: data.getUser.person.initials,
      profilePictureId: data.getUser.person.profilePictureId,
      profilePictureUrl: data.getUser.person.profilePictureUrl,
      primaryEmailAddress: data.getUser.person.primaryEmailAddress,
      user: {
        id: data.getUser.id,
        isAssumedAccount: data.getUser.isAssumedAccount,
        isAssumingAccountAsAnotherUser: data.getUser.isAssumingAccountAsAnotherUser,
        isOnboarding: data.getUser.isOnboarding,
        userPermissionGroups: data.getUser.userPermissionGroups,
        activeFrom: data.getUser.activeFrom ?? null,
        mfaEnabled: data.getUser.mfaEnabled,
        ssoId: data.getUser.ssoId,
      },
    }

    if (window) {
      window.walkMeVariables = {
        systemAdministratorRole: getSession().isSystemAdministrator,
        superUserRole: getSession().isSuperUser,
        userTimezone: data.getUserConfig.timezone,
        tenantName: data.getTenantConfig.tenantName,
        tenantCountry: data.getTenantConfig.country,
        tenantCurrency: data.getTenantConfig.currency,
        userId: data.getUser.id,
        personId: data.getUser.person.id,
        isAssumedAccount: data.getUser.isAssumedAccount,
        isAssumingAccountAsAnotherUser: data.getUser.isAssumingAccountAsAnotherUser,
        userActiveFrom: data.getUser.activeFrom ?? null,
      }
    }

    return (
      <TenantContext.Provider
        value={{
          tenantName: data.getTenantConfig.tenantName,
          tenantCountry: data.getTenantConfig.country,
          tenantCurrency: data.getTenantConfig.currency,
          language: data.getUserConfig.language,
          minPasswordLength: data.getTenantConfig.minPasswordLength,
          modules: data.listAllModules,
        }}
      >
        <UserContext.Provider
          value={{
            timezone,
            dateFormat: dateFormat ?? 'VERBOSE',
            timeFormat: timeFormat ?? 'HOURS_12',
            language: data.getUserConfig.language,
            person: reformattedPerson,
          }}
        >
          {children}
        </UserContext.Provider>
      </TenantContext.Provider>
    )
  }

  return (
    <div data-cy="session-details-provider-spinner">
      <InitialLoadBrandSpinner />
    </div>
  )
}

export { SessionDetailsProvider }

export type { IModule }
