import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  MarkOnboardingStepAsComplete,
  MarkOnboardingStepAsCompleteVariables,
} from './__gql__/useMarkOnboardingStepAsCompleteCodeGen'

const MARK_ONBOARDING_STEP_AS_COMPLETE = gql`
  mutation MarkOnboardingStepAsComplete($input: MarkOnboardingStepAsCompleteInput!) {
    markOnboardingStepAsComplete(input: $input) {
      success
    }
  }
`

const useMarkOnboardingStepAsComplete = () => {
  return useMutation<MarkOnboardingStepAsComplete, MarkOnboardingStepAsCompleteVariables>(
    MARK_ONBOARDING_STEP_AS_COMPLETE,
  )
}

export { MARK_ONBOARDING_STEP_AS_COMPLETE, useMarkOnboardingStepAsComplete }
