import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type { GetNavigationItems as IGetNavigationItems } from './__gql__/gqlCodeGen'

type INavigationItem = IGetNavigationItems['listNavigationItems']['primaryItems'][0]

const GET_NAVIGATION_ITEMS = gql`
  query GetNavigationItems {
    intelliHRLogos {
      onWhiteRGBUrl
    }
    listNavigationItems {
      primaryItems {
        key
        type
        label
        accentColor
        ... on NavigationItemSubMenu {
          primaryOptions {
            key
            href
            label
            description
            openNewTab
          }
          secondaryOptions {
            key
            href
            label
            openNewTab
          }
        }
        ... on NavigationItemLink {
          href
          openNewTab
        }
      }
      secondaryItems {
        key
        type
        label
        accentColor
        ... on NavigationItemSubMenu {
          primaryOptions {
            key
            href
            label
            description
            openNewTab
          }
          secondaryOptions {
            key
            href
            label
            openNewTab
          }
        }
        ... on NavigationItemLink {
          href
          openNewTab
        }
      }
    }
    listApps {
      nativeApps {
        type
        label
        href
      }
      externalApps {
        type
        label
        href
      }
    }
  }
`

const useGetNavigationItems = () => {
  return useQuery<IGetNavigationItems>(GET_NAVIGATION_ITEMS, {
    errorBehaviour: 'do-nothing',
  })
}

export { GET_NAVIGATION_ITEMS, useGetNavigationItems }
export type { INavigationItem }
