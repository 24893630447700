import styled from 'styled-components'

const BrowserBackButtonWrapper = styled.span`
  a > span {
    margin-bottom: 0.25rem;
    padding-left: 0;
    margin-left: 0;
  }
`

export { BrowserBackButtonWrapper }
