import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Avatar } from '@intellihr/blueberry'
import {
  TNavigationItem,
  INativeAppItem,
  IExternalAppItem,
} from '@intellihr/blueberry/dist/_private/Navigation/navigationTypes'
import { getIconForNavigationItem } from 'src/services/layout/components/PageFrame/components/Navigation/getIconForNavigationItem'
import {
  INavigationItem,
  useGetNavigationItems,
} from 'src/services/layout/components/PageFrame/components/Navigation/gql'

export const useNavigationItems = () => {
  const { data } = useGetNavigationItems()
  const { person } = useUserContext()
  const location = useLocation()

  const mapItems = useCallback(
    (item: INavigationItem) => {
      // Highlight People Directory
      const isPeopleDirectory = (key: string) =>
        key === 'peopleDirectory' && location.pathname === '/spa/people' && location.search !== ''
      // Highlight my profile when on any profile tab
      const isProfile = (key: string) => key === 'myProfile' && location.pathname.startsWith(`/spa/people/${person.id}`)
      // Exclude top level items that link to Profile
      const isNotProfile = (key: string) =>
        !(key !== 'user' && location.pathname.startsWith(`/spa/people/${person.id}`))
      const isSetting = (key: string) => key === 'settings' && location.pathname.startsWith('/spa/settings')

      const active =
        (item.__typename === 'NavigationItemLink' && item.href === location.pathname) ||
        (item.__typename === 'NavigationItemSubMenu' &&
          isNotProfile(item.key) &&
          item.primaryOptions.find(
            (option) => option.href === location.pathname || isPeopleDirectory(option.key) || isProfile(option.key),
          )) ||
        isSetting(item.key)
          ? // Causes issue with duplicates in secondaryItems highlighting multiple top level nav items
            // || item.secondaryOptions.find((option) => option.href === location.pathname)
            true
          : undefined

      // @todo replace spread operator with object.assign to improve performance
      return {
        ...item,
        active,
        id: item.key,
        icon:
          item.key !== 'user' ? (
            getIconForNavigationItem(item.key)
          ) : (
            <Avatar
              size="small"
              image={person.profilePictureUrl ?? undefined}
              name={person.displayName}
              initials={person.initials}
            />
          ),
        primaryOptions:
          item.__typename === 'NavigationItemSubMenu'
            ? item.primaryOptions.map((option) => ({
                ...option,
                id: option.key,
                active:
                  option.href === location.pathname || isPeopleDirectory(option.key) || isProfile(option.key)
                    ? true
                    : undefined,
                icon: getIconForNavigationItem(option.key),
              }))
            : undefined,
        secondaryOptions:
          item.__typename === 'NavigationItemSubMenu'
            ? item.secondaryOptions.map((option) => ({
                ...option,
                id: option.key,
                active: option.href === location.pathname ? true : undefined,
              }))
            : undefined,
      } as TNavigationItem
    },
    [location, person],
  )

  if (!data) {
    return null
  }

  return {
    logoUrl: data.intelliHRLogos.onWhiteRGBUrl,
    primaryItems: data.listNavigationItems.primaryItems.map(mapItems),
    secondaryItems: data.listNavigationItems.secondaryItems.map(mapItems),
    nativeApps: data.listApps.nativeApps as INativeAppItem[],
    externalApps: data.listApps.externalApps as IExternalAppItem[],
  }
}
