import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

interface IRoutedAnchorProps extends RouteComponentProps {
  href?: string
  target?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  className?: string
  anchorComponentProps: {
    blockNavigationOnLeftClick?: boolean
    onLeftClick?: (event: React.MouseEvent<HTMLElement>) => void
    useReactRouter?: boolean
    stopPropagation?: boolean
    download?: boolean
  }
}

const isExternalURL = (url: string): boolean => {
  try {
    return new URL(url).origin !== location.origin
  } catch (e) {
    return false
  }
}

class RoutedAnchor extends React.Component<React.PropsWithChildren<IRoutedAnchorProps>> {
  public static defaultProps: Partial<IRoutedAnchorProps> = {
    anchorComponentProps: {
      useReactRouter: true,
      stopPropagation: false,
    },
  }

  public isLeftClickEvent = (event: React.MouseEvent<HTMLElement>) => {
    // See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js#L35
    const isModified = event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
    const { target } = this.props

    return !event.defaultPrevented && event.button === 0 && !isModified && !target
  }

  public handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const {
      href,
      onClick,
      history,
      anchorComponentProps: { onLeftClick = () => undefined, stopPropagation = false },
    } = this.props

    if (stopPropagation) {
      event.stopPropagation()
    }

    if (onClick) {
      onClick(event)
    }

    if (this.isLeftClickEvent(event)) {
      if (this.isToPreventDefault) {
        event.preventDefault()
      }

      if (onLeftClick) {
        onLeftClick(event)
      }

      if (this.isToPreventDefault) {
        return history.push(href!)
      }
    }
  }

  public render() {
    const { children, href, target, className, anchorComponentProps } = this.props

    return (
      <a
        className={className}
        href={href}
        target={target}
        onClick={this.handleClick}
        download={anchorComponentProps?.download}
        data-component-type={this.props['data-component-type']}
        data-cy={this.props['data-cy']}
        rel={target === '_blank' && isExternalURL(href ?? '') ? 'noreferrer noopener' : ''}
      >
        {children}
      </a>
    )
  }

  private get isToPreventDefault() {
    const {
      href,
      anchorComponentProps: { blockNavigationOnLeftClick = false, useReactRouter = true },
    } = this.props

    return useReactRouter && href && !blockNavigationOnLeftClick
  }
}

const WrappedRoutedAnchor = withRouter(RoutedAnchor)

export type { IRoutedAnchorProps }
export { WrappedRoutedAnchor as RoutedAnchor }
