import React, { useState } from 'react'
import {
  AvatarGroup,
  DropdownMenu,
  Variables,
  AvatarEntity,
  TextInput,
  UnstyledLink,
  FontAwesomeIcon,
} from '@intellihr/ui-components'
import { urlForRoute } from 'src/services/routes'
import { AvatarGroupSize } from '@intellihr/ui-components/types/domain/Avatars/AvatarGroup/style'
import { FilteredList } from 'src/services/filters/FilteredList'
import { StyledAttributeChangeComponent, StyledFilteredListWrapper, StyledTextInputWrapper } from './style'

interface IPersonGroupDropdownPerson {
  personName?: string | null
  jobName?: string
  initials?: string
  imageUrl?: string
  linkUrl?: string
  id?: string
  jobId?: string
}

interface IPersonGroupDropdown {
  /** A list of people to display */
  people: IPersonGroupDropdownPerson[]
  /** Placeholder to show in the dropdown search field */
  searchTextPlaceholder: string
  /** Text to show if there is no results found when searching in the dropdown*/
  noSearchResultsMessage: string
  /** Set the size of the avatar group */
  avatarGroupSize?: AvatarGroupSize
  /** maxAvatarCount for AvatarGroup */
  maxAvatarCount?: number
}

const PersonEntity: React.FC<{ person: IPersonGroupDropdownPerson }> = ({ person }) => {
  return (
    <StyledAttributeChangeComponent>
      <AvatarEntity
        initials={person.initials}
        primaryText={person.personName ?? 'Unknown'}
        secondaryText={person.jobName}
        imageUrl={person.imageUrl}
      />
    </StyledAttributeChangeComponent>
  )
}

/**
 * Given an array of people displays an AvatarGroup that will open
 * a dropdown when clicked on. This dropdown displays all of the
 * people in a searchable list of AvatarEntities.
 */
const PersonGroupDropdown: React.FC<IPersonGroupDropdown> = ({
  people,
  searchTextPlaceholder,
  noSearchResultsMessage,
  avatarGroupSize = 'small',
  maxAvatarCount,
}) => {
  const [personSearch, setPersonSearch] = useState<string>('')

  if (people.length > 0) {
    return (
      <DropdownMenu
        toggleComponent={({ toggleMenu, toggleComponentRef, ariaProps }) => (
          <AvatarGroup
            componentContext="timeline-avatar-group"
            avatars={people.map((person) => ({ initials: person.initials, imageUrl: person.imageUrl }))}
            size={avatarGroupSize}
            isHoverable
            onClick={toggleMenu}
            wrapperRef={toggleComponentRef}
            wrapperOverrides={ariaProps}
            maxAvatarCount={maxAvatarCount ?? null}
          />
        )}
      >
        {() => {
          return (
            <>
              {people.length > 1 && (
                <StyledTextInputWrapper>
                  <TextInput
                    placeholder={searchTextPlaceholder}
                    icon={
                      <FontAwesomeIcon
                        type="solid"
                        icon="search"
                      />
                    }
                    name={'personSearchInput'}
                    value={personSearch}
                    onChange={(value) => setPersonSearch(value)}
                    handleClear={() => setPersonSearch('')}
                    margins={{ bottom: Variables.Spacing.sSmall }}
                  />
                </StyledTextInputWrapper>
              )}
              <StyledFilteredListWrapper>
                <FilteredList<IPersonGroupDropdownPerson>
                  rowCallback={(data) => {
                    const { id: personId, jobId } = data.row

                    if (!personId) {
                      return undefined
                    }

                    const href =
                      data.row.linkUrl ??
                      (jobId && urlForRoute('people.jobs.show', { personId, jobId })) ??
                      urlForRoute('people.show', { personId })

                    return (
                      <UnstyledLink
                        key={data.row.id}
                        href={href}
                      >
                        <PersonEntity person={data.row} />
                      </UnstyledLink>
                    )
                  }}
                  noDataComponent={noSearchResultsMessage}
                  filters={[
                    {
                      kind: 'valueFilter',
                      paths: ['personName', 'jobName'],
                      caseSensitive: false,
                      filterValue: personSearch,
                    },
                  ]}
                  rowData={people}
                />
              </StyledFilteredListWrapper>
            </>
          )
        }}
      </DropdownMenu>
    )
  }

  return null
}

export type { IPersonGroupDropdownPerson }
export { PersonGroupDropdown, PersonEntity }
