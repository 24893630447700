import React from 'react'
import { Route as ReactRoute, RouteProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { usePermissionCheck } from 'src/services/permissions/components/PermissionCheck'
import { AccessDenied } from 'src/services/error/components/AccessDenied'
import { useModuleCheckWithSlug } from 'src/services/productPlan/hooks'
import type { ModuleSlug } from 'src/services/productPlan/hooks'
import { PageNotFound } from 'src/services/error'

enum PermissionCheckType {
  AllValid = 'AllValid',
  SomeValid = 'SomeValid',
}

interface IPermissionCheck {
  permissionSlugs: string[]
  targetPersonId?: string
  checkType?: PermissionCheckType
  targetType?: 'functional' | 'people'
}

interface IModuleCheck {
  moduleSlug: ModuleSlug | ModuleSlug[]
}

interface IRouteProps extends RouteProps {
  functionalPermissionCheck?: IPermissionCheck
  personPermissionCheck?: IPermissionCheck
  moduleCheck?: IModuleCheck
}

const SentryRoute = Sentry.withSentryRouting(ReactRoute)

const RouteModuleCheck: React.FC<React.PropsWithChildren<{ slug: ModuleSlug | ModuleSlug[] }>> = ({
  slug,
  children,
}) => {
  if (!useModuleCheckWithSlug(slug)) {
    return <PageNotFound />
  }
  return <>{children}</>
}

const RoutePermissionCheck: React.FC<React.PropsWithChildren<IPermissionCheck>> = ({
  permissionSlugs,
  targetPersonId,
  checkType,
  targetType,
  children,
}) => {
  const { someValid, allValid, permissions, failedPermissionSlugs } = usePermissionCheck({
    targetId: targetPersonId,
    targetType: targetType ?? 'people',
    permissionSlugs,
  })

  if (!permissions) {
    return <div data-cy="hierarchical-permission-checker-spinner" />
  }

  if (
    (checkType === PermissionCheckType.AllValid && !allValid) ||
    (checkType === PermissionCheckType.SomeValid && !someValid)
  ) {
    return <AccessDenied failedPermissions={failedPermissionSlugs} />
  }

  return <>{children}</>
}

const Route: React.FC<React.PropsWithChildren<IRouteProps>> = (props) => {
  const { personPermissionCheck, functionalPermissionCheck, moduleCheck, children } = props

  let sentryRoute = <SentryRoute {...props}>{children}</SentryRoute>

  if (personPermissionCheck) {
    sentryRoute = (
      <RoutePermissionCheck
        permissionSlugs={personPermissionCheck.permissionSlugs}
        targetPersonId={personPermissionCheck.targetPersonId}
        checkType={personPermissionCheck.checkType ?? PermissionCheckType.SomeValid}
        targetType="people"
      >
        {sentryRoute}
      </RoutePermissionCheck>
    )
  }

  if (functionalPermissionCheck) {
    sentryRoute = (
      <RoutePermissionCheck
        permissionSlugs={functionalPermissionCheck.permissionSlugs}
        checkType={functionalPermissionCheck.checkType ?? PermissionCheckType.SomeValid}
        targetType="functional"
      >
        {sentryRoute}
      </RoutePermissionCheck>
    )
  }

  if (moduleCheck) {
    sentryRoute = <RouteModuleCheck slug={moduleCheck.moduleSlug}>{sentryRoute}</RouteModuleCheck>
  }

  return sentryRoute
}

export { PermissionCheckType, Route }
