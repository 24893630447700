import humps from 'humps'
import { IAsyncAnalyticsOption } from 'src/domain/PlatformSetup/public/displays/PeopleSearchBar/interface'
import { urlForAnalyticsPortalRoute, urlForLapisRoute } from 'src/services/routes'
import { DocumentType, PageKey } from '../entities/types'

// Can remove the above import for GlobalSearchBar
// interface IAsyncAnalyticsOption {
//   label: string
//   value: string
//   group: 'Analytics'
//   type: DocumentType
//   entityKey: string
// }

const getAnalyticsRouteForPeopleSearch = (option: IAsyncAnalyticsOption) => {
  switch (option.type) {
    case DocumentType.Page: {
      if (option.entityKey === PageKey.anonymousFormData) {
        return urlForAnalyticsPortalRoute('analytics.pages', {
          pageKey: humps.decamelize(option.entityKey, { separator: '-' }),
        })
      }
      return urlForLapisRoute('analytics.pages', { pageKey: humps.decamelize(option.entityKey, { separator: '-' }) })
    }
    case DocumentType.Form: {
      return urlForLapisRoute('analytics.forms', { formDesignId: option.entityKey })
    }
  }
}

export { getAnalyticsRouteForPeopleSearch }
