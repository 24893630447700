import React, { useEffect, useRef, useState } from 'react'
import { Alert, Stack } from '@intellihr/blueberry'
import { Embed, MESSAGE_KIND } from '@super-api/super-api-embed'
import styled from 'styled-components'
import { useToasts } from 'src/services/toasts'
import { FormattedText } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'

const StyledIframeContainer = styled.div`
  & > div > iframe {
    border: none;
  }

  .superApiEmbed {
    display: none;
  }
`

enum toastType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

interface IOnboardingSuperAPIDetailStepProps {
  embedUrl: string
  onSubmit: () => void
}

const SuperAPIEmbed: React.FC<IOnboardingSuperAPIDetailStepProps> = ({ embedUrl, onSubmit }) => {
  const postToast = useToasts()
  const embedRef = useRef<HTMLDivElement | null>(null)
  const [embed, setEmbed] = useState<Embed | null>(null)

  useEffect(() => {
    if (embedRef.current === null || embed !== null) {
      return
    }

    // Create the embed with the reference to the dom node and the URL
    const onboardingSessionSettingsEmbed = new Embed({
      element: embedRef.current,
      url: embedUrl,
      loaderClass: 'superApiEmbed',
    })

    // Handle toast message being broadcast and show them using our custom toast
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onboardingSessionSettingsEmbed.on(MESSAGE_KIND.TOAST, (data: any) => {
      if (data.kind === toastType.ERROR) {
        postToast({
          type: 'alert',
          content: data.message,
          logType: 'error',
        })
      }

      if (data.kind === toastType.WARNING) {
        postToast({
          type: 'alert',
          content: data.message,
          logType: 'warning',
        })
      }

      if (data.kind === toastType.INFO) {
        postToast({
          type: 'alert',
          content: data.message,
          logType: 'info',
        })
      }

      if (data.kind === toastType.SUCCESS) {
        postToast({
          type: 'success',
          content: data.message,
        })
      }
    })

    // Handle the user clicking "Continue" at the end of the embed - onSubmit
    // will flag the step as complete and continue to the next step
    onboardingSessionSettingsEmbed.on(MESSAGE_KIND.ONBOARDING_SESSION_FINISHED, onSubmit)

    setEmbed(onboardingSessionSettingsEmbed)

    // When the component is unloaded, call the teardown function which will
    // automatically remove the event listeners we bound in the code above
    return () => {
      if (embed !== null) {
        ;(embed as Embed).teardown()
      }
    }
  }, [embed, postToast, embedUrl, onSubmit])

  return (
    <Stack
      space="large"
      testId="super-api-employee-embed"
    >
      <StyledIframeContainer>
        <div ref={embedRef}></div>
      </StyledIframeContainer>
    </Stack>
  )
}

const SuperAPIEmbedErrorMessage: React.FC = () => {
  const tFeature = useScope('edm:features.Onboarding')
  const calloutDefaultText = `The Australia onboarding module failed to load

  Possible causes:
  
  - Something failed on our partner's end
  - The business entity for this job has an incomplete onboarding configuration
  
  First, wait a few minutes and try to [refresh]({{currentPage}}) the page if that doesn't work, reach out to your HR contact
  `

  return (
    <Alert
      testId="embed_failed_to_load_callout"
      tone="critical"
    >
      <FormattedText
        text={tFeature('embedFailedToLoadCallout', {
          defaultValue: calloutDefaultText,
        })}
      />
    </Alert>
  )
}

export { SuperAPIEmbed, SuperAPIEmbedErrorMessage }
