import { DocumentType } from 'src/scenes/Analytics/services/entities/types'

enum Group {
  PeopleJob = 'People',
  Analytics = 'Analytics',
  Heading = 'Heading',
}

interface IAsyncOption {
  label: string
  value: string
  group: Group
}

interface IAsyncAnalyticsOption extends IAsyncOption {
  group: Group.Analytics
  type: DocumentType
  entityKey: string
}

interface IAsyncHeadingOption extends IAsyncOption {
  group: Group.Heading
  text: Group.Analytics | Group.PeopleJob
  disabled: true
}

export { Group, IAsyncOption, IAsyncAnalyticsOption, IAsyncHeadingOption }
