import styled from 'styled-components'
import { Variables } from '@intellihr/ui-components'

const StyledAttributeChangeComponent = styled.div`
  margin-bottom: ${Variables.Spacing.sSmall}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledTimelineContentSection = styled.div`
  margin-bottom: ${Variables.Spacing.sMedium}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledTextInputWrapper = styled.div`
  min-width: 240px;
`

const StyledFilteredListWrapper = styled.div`
  max-height: 520px;
  overflow: auto;
`

const StyledModalButton = styled.button`
  color: ${Variables.Color.n500};
  cursor: pointer;
  outline: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${Variables.Color.i400};
  }
`

export {
  StyledAttributeChangeComponent,
  StyledTimelineContentSection,
  StyledTextInputWrapper,
  StyledFilteredListWrapper,
  StyledModalButton,
}
