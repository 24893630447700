import styled from 'styled-components'
import { Variables } from '@intellihr/ui-components'
import React from 'react'
import { CompactCensor } from 'src/services/uiComponentCandidates/CompactCensor'

const DetailsLeft = styled.span`
  border-radius: ${Variables.Style.borderRadius}px 0 0 ${Variables.Style.borderRadius}px;
  padding: 1px 4px;
  border: 1px solid ${Variables.Color.n250};
  background: ${Variables.Color.n250};
  color: ${Variables.Color.n700};
  line-height: 18px;
  font-size: 14px;
  flex-shrink: 0;
`

const DetailsRight = styled.span`
  border-radius: 0 ${Variables.Style.borderRadius}px ${Variables.Style.borderRadius}px 0;
  padding: 1px 4px;
  color: ${Variables.Color.n700};
  border: 1px solid ${Variables.Color.n300};
  line-height: 18px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledWrapper = styled.div`
  display: flex;
  max-width: 350px;
`

const CustomFieldDetails: React.FC<
  React.PropsWithChildren<{ customField: { title: string; apiName: string }; sensitive: boolean }>
> = ({ customField: { title, apiName }, children, sensitive }) => {
  return (
    <StyledWrapper data-cy={`${apiName}-custom-field-details`}>
      <DetailsLeft>{title}</DetailsLeft>
      <DetailsRight>{sensitive ? <CompactCensor>{children}</CompactCensor> : children}</DetailsRight>
    </StyledWrapper>
  )
}

export { CustomFieldDetails }
