import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type { GetPersonForHoverCard, GetPersonForHoverCardVariables } from './__gql__/hooksCodeGen'

type IPerson = NonNullable<GetPersonForHoverCard['getPerson']>

const GET_PERSON_FOR_HOVER_CARD = gql`
  query GetPersonForHoverCard($personId: ID!) {
    getTenantConfig {
      defaultProfileCoverImage
    }
    getPerson(id: $personId) {
      id
      preferredOrFirstName
      lastName
      displayName
      initials
      profilePictureUrl(size: MEDIUM)
      profileCoverPictureUrl(size: PROFILE_COVER_HOVER_CARD)
      employmentStatusId
      jobs(whereJobIsPrimaryOrActive: true) {
        id
        jobName {
          id
          name
        }
        jobLeave {
          id
          startDate
          endDate
        }
      }
      primaryPhoneNumber {
        id
        number
        country {
          id
          dialCode
          isoCode
        }
      }
      primaryEmailAddress {
        id
        email
      }
      primaryJob {
        id
        jobStatus
        isPrimaryJob
        isOnExtendedLeave
        jobLeave {
          id
          endDate
        }
        jobName {
          id
          name
        }
        jobBusinessUnit {
          id
          businessUnit {
            id
            name
          }
        }
        jobLocation {
          id
          location {
            id
            isNoLocation
            name
          }
        }
        jobSupervisor {
          id
          supervisorJob {
            id
            jobName {
              id
              name
            }
            isOnExtendedLeave
            jobStatus
            person {
              id
              preferredOrFirstName
              lastName
              displayName
              initials
              profilePictureUrl(size: SMALL)
            }
          }
        }
      }
    }
  }
`

const useGetPersonForHoverCard = (personId: string) =>
  useQuery<GetPersonForHoverCard, GetPersonForHoverCardVariables>(GET_PERSON_FOR_HOVER_CARD, {
    errorBehaviour: 'do-nothing',
    variables: { personId },
  })

export type { IPerson }
export { useGetPersonForHoverCard }
