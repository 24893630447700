import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import { SubmitOnboardingForm, SubmitOnboardingFormVariables } from './__gql__/useSubmitOnboardingFormCodeGen'

const SUBMIT_ONBOARDING_FORM = gql`
  mutation SubmitOnboardingForm($input: SubmitOnboardingFormInputV2!) {
    submitOnboardingFormInstanceV2(input: $input) {
      onboardingFormInstance {
        id
      }
    }
  }
`

const useSubmitOnboardingForm = (successMessage: string) => {
  return useMutation<SubmitOnboardingForm, SubmitOnboardingFormVariables>(SUBMIT_ONBOARDING_FORM, {
    successMessage,
  })
}

export { useSubmitOnboardingForm }
