import React, { useState, useMemo, useCallback, memo, useContext } from 'react'
import { Avatar, Search, SearchLabel, IconAnalytics } from '@intellihr/blueberry'
import type { TSearchItem } from '@intellihr/blueberry'
import { SegmentAction, SegmentCategory, sendSegmentEvent } from 'src/services/segment/helper'
import { urlForRoute } from 'src/services/routes'
import { AnalyticsPagesContext } from 'src/scenes/Analytics/services/entities'
import { PageKey, DocumentType } from 'src/scenes/Analytics/services/entities/types'
import { AnalyticsPermissionContext } from 'src/scenes/Analytics/services/permissions'
import { AnalyticsSearchContext } from 'src/scenes/Analytics/services/search'
import { getAnalyticsRouteForPeopleSearch } from 'src/scenes/Analytics/services/routes'
import { Page } from 'src/scenes/Analytics/services/entities/pages'
import { constructAnalyticsOptions } from '../services/analyticsPages'
import { usePerformPeopleSearch } from './gql'

const PAGE_LIMIT = 5
const PERSON_LIMIT = 10

interface IGlobalSearchBar {
  variant: 'bar' | 'icon'
  showPastStaffFilter?: boolean
}

const GlobalSearchBar: React.FC<IGlobalSearchBar> = memo(({ showPastStaffFilter, variant }) => {
  const { hasAccess: hasAnalyticsAccess } = useContext(AnalyticsPermissionContext)
  const { formDesigns } = useContext(AnalyticsPagesContext)
  const [pastStaffFilterValue, setPastStaffFilterValue] = useState(() => false)
  const [searchText, setSearchText] = useState('')
  const { data: dropdownSearchResults, loading } = usePerformPeopleSearch(
    {
      searchText,
      showPastStaff: pastStaffFilterValue,
    },
    searchText.length < 1,
  )
  const miniSearch = useContext(AnalyticsSearchContext)

  const options = useMemo(() => {
    const results: TSearchItem[] = []

    const getAnalyticsPageTitle = (type: string, entityKey: string) => {
      if (type === DocumentType.Page) {
        return Page.all()[entityKey as PageKey].title
      }
      if (type === DocumentType.Form) {
        const { title: t } = formDesigns[entityKey]!
        return t
      }

      return entityKey
    }

    if (dropdownSearchResults?.performPeopleDropdownSearch.results.length) {
      results.push({
        type: 'group',
        label: 'People',
        items: dropdownSearchResults.performPeopleDropdownSearch.results.slice(0, PERSON_LIMIT).map((result) => {
          const person = result.person

          return {
            type: 'person',
            href: urlForRoute('people.show', { personId: person.id }),
            label: (
              <SearchLabel
                name={person.displayName ?? ''}
                description={
                  person.primaryJob
                    ? `${person.primaryJob.jobName.name} · 
                  ${result.businessUnit?.name}, 
                  ${person.primaryJob.jobBusinessEntity.businessEntity.name}, 
                  ${person.primaryJob.jobLocation.location?.name}`
                    : undefined
                }
                image={
                  <Avatar
                    image={person.profilePictureUrl ? person.profilePictureUrl : undefined}
                    initials={person.initials}
                  />
                }
                disabled={person.employmentStatusId === 'EMPLOYMENT_STATUS_PAST_STAFF' ? true : undefined}
              />
            ),
          }
        }),
      })
    }

    if (hasAnalyticsAccess) {
      const analyticsResults = constructAnalyticsOptions(miniSearch, searchText, PAGE_LIMIT)

      if (analyticsResults.length > 0) {
        results.push({
          type: 'group',
          label: 'Analytics',
          items: analyticsResults.map((result) => {
            const title = getAnalyticsPageTitle(result.type, result.entityKey)
            return {
              type: 'page',
              href: getAnalyticsRouteForPeopleSearch(result),
              label: (
                <SearchLabel
                  name={title}
                  image={<IconAnalytics />}
                />
              ),
            }
          }),
        })
      }
    }

    return results
  }, [dropdownSearchResults, formDesigns, hasAnalyticsAccess, miniSearch, searchText])

  /* Update search query and toggle past staff on/off */
  const handleInputChange = useCallback(
    ({ query, pastStaffFilterValue }: { query: string; pastStaffFilterValue: boolean }) => {
      setSearchText(query)
      setPastStaffFilterValue(pastStaffFilterValue)
    },
    [],
  )

  /* Send analytics */
  const handleOpen = useCallback(() => {
    sendSegmentEvent({
      eventName: 'Global search entry',
      category: SegmentCategory.PLATFORM,
      action: SegmentAction.SEARCH,
      label: 'Global Search',
    })
  }, [])

  return (
    <Search
      variant={variant}
      items={options}
      isLoading={loading}
      onChange={handleInputChange}
      showPastStaffFilter={showPastStaffFilter}
      pastStaffFilterValue={pastStaffFilterValue}
      onOpen={handleOpen}
      id="global_search_bar"
    />
  )
})

export { GlobalSearchBar }
