import { QueryResult } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { cloudLogger } from 'src/services/error'
import { GraphiteNetworkError, GraphiteServerError } from 'src/services/graphQL/errors'

const getErrorMessage = (error: Error) => error.message.replace(/^GraphQL error: /g, '')

function globalGraphQLErrorHandler({ graphQLErrors, networkError, response, operation }: ErrorResponse): void {
  const errorId: string | undefined = operation.getContext().headers?.['trace-request-id']

  if (graphQLErrors) {
    response?.errors?.forEach((error) => {
      error.extensions['trace-request-id'] = errorId
    })

    const messages = graphQLErrors
      .map((error) => `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`)
      .join('; ')

    cloudLogger.info(messages, {
      errorId,
      userImpact: 'None',
      meta: { type: 'graphql', errors: graphQLErrors },
    })
  }

  if (networkError) {
    cloudLogger.info(networkError.message, {
      errorId,
      userImpact: 'None',
      meta: { type: 'network', errors: networkError },
    })
  }
}

const apolloErrorToGraphiteError = (queryResult: QueryResult) => {
  if (queryResult.error?.networkError) {
    return new GraphiteNetworkError(queryResult.error.message, queryResult)
  }

  return new GraphiteServerError(queryResult.error?.message, queryResult)
}

export { apolloErrorToGraphiteError, getErrorMessage, globalGraphQLErrorHandler }
