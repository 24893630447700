import React from 'react'
import { Variables, FontAwesomeIcon, Spinner, Text } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { IconWrapper, InputWrapper, PlaceholderWrapper, SpinnerWrapper, StyledInput } from '../style'

const InputComponent: React.FC<{
  inputProps: { [key: string]: unknown }
  loading: boolean
  id: string
  name: string
  isSingleSelect: boolean
  hasValuesSelected: boolean
  placeholder?: string
  isDisabled: boolean
  componentContext?: string
}> = ({
  inputProps,
  loading,
  id,
  name,
  isSingleSelect,
  placeholder,
  hasValuesSelected,
  isDisabled,
  componentContext,
}) => {
  const t = useScope('edm:features.PeopleDropdownInput')
  const icon = (
    <FontAwesomeIcon
      type="solid"
      icon="search"
      size="xsmall"
      color={Variables.Color.n400}
    />
  )

  return (
    <>
      {isSingleSelect && (!hasValuesSelected || inputProps.value !== '') && <IconWrapper>{icon}</IconWrapper>}
      <InputWrapper
        isSingleSelect={isSingleSelect}
        isDisabled={isDisabled}
        data-cy={componentContext}
      >
        {!isSingleSelect && icon}
        {inputProps.value === '' && (!isSingleSelect || !hasValuesSelected) ? (
          <PlaceholderWrapper>
            <Text
              margins={!isSingleSelect ? { left: Variables.Spacing.sMedium } : undefined}
              color={Variables.Color.n400}
            >
              {placeholder ?? t('placeholder')}
            </Text>
          </PlaceholderWrapper>
        ) : undefined}
        <StyledInput
          {...inputProps}
          id={id}
          name={name}
          isSingleSelect={isSingleSelect}
        />
        {loading && (
          <SpinnerWrapper>
            <Spinner
              type="fading-circle"
              size={20}
            />
          </SpinnerWrapper>
        )}
      </InputWrapper>
    </>
  )
}

export { InputComponent }
