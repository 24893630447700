import { useDispatch } from 'react-redux'
import { reset, submit } from 'redux-form'
import { useCallback } from 'react'

function useReduxFormSubmit(formName: string) {
  const dispatch = useDispatch()

  return useCallback(() => dispatch(submit(formName)), [dispatch, formName])
}

function useReduxFormReset(formName: string) {
  const dispatch = useDispatch()

  return useCallback(() => dispatch(reset(formName)), [dispatch, formName])
}

export { useReduxFormSubmit, useReduxFormReset }
