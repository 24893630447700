import React, { useMemo } from 'react'
import { DataTable, Props, RadioSet } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'

enum VisibilityOption {
  REQUIRED = 'dc4261b0-d0c8-479c-b469-baa193221f76',
  OPTIONAL = 'f59556b0-fb3c-4d8c-9411-ad68b4c813b1',
  HIDDEN = 'c1ef6361-24ba-4b74-9d72-722e5437c43c',
}

interface ISectionSelection {
  [key: string]: boolean
}

interface IFieldSelection {
  [key: string]: string
}

interface IField {
  id: string
  name: string
  visibilityOptions: VisibilityOption[]
}

interface IOnboardingTemplateFieldsTableProps {
  fields: IField[]
  selected: IFieldSelection
  setSelected: (selection: IFieldSelection) => void
  componentContext: string
}

const OnboardingTemplateFieldsTable: React.FC<IOnboardingTemplateFieldsTableProps> = ({
  fields,
  selected,
  setSelected,
  componentContext,
}) => {
  const t = useScope('edm:features.SettingsOnboardingTemplates')
  const idToLabel = useMemo(
    () => ({
      [VisibilityOption.REQUIRED]: t('visibilityOptions.required'),
      [VisibilityOption.OPTIONAL]: t('visibilityOptions.optional'),
      [VisibilityOption.HIDDEN]: t('visibilityOptions.hidden'),
    }),
    [t],
  )

  return (
    <DataTable<IField>
      columns={[
        {
          Header: t('headers.field'),
          accessor: 'name',
        },
        {
          Header: t('headers.visibility'),
          accessor: 'visibilityOptions',
          Cell: ({ row, value: options }) => {
            const { id } = row.original
            return (
              <RadioSet
                name={row.id}
                orientation={Props.Orientation.Horizontal}
                options={options.map((option: string) => {
                  return {
                    value: option,
                    label: idToLabel[option],
                    componentContext: `${id}-visibility-${option}`,
                  }
                })}
                value={selected[id]}
                onChange={(value: string) => {
                  setSelected({ ...selected, [id]: value })
                }}
              />
            )
          },
        },
      ]}
      data={fields}
      componentContext={componentContext}
    />
  )
}

export { IField, IFieldSelection, ISectionSelection, OnboardingTemplateFieldsTable, VisibilityOption }
