import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type { GetPageHeaderItems } from './__gql__/gqlCodeGen'

type PageHeaderFontAwesomeItem = Extract<
  GetPageHeaderItems['listNavigationTopbarItems'][number],
  { __typename?: 'NavigationTopbarFontAwesomeItem' }
>
type PageHeaderAvatarItem = Extract<
  GetPageHeaderItems['listNavigationTopbarItems'][number],
  { __typename?: 'NavigationTopbarAvatarItem' }
>

const topbarNavFragment = gql`
  fragment TopbarNavigation on Query {
    listNavigationTopbarItems {
      ... on NavigationTopbarFontAwesomeItem {
        id
        href
        items {
          id
          href
          label
          description
          icon
          iconType
          variant
          visible
        }
        label
        indicatorCount
        statusDot
        type
        icon
        iconType
      }
      ... on NavigationTopbarAvatarItem {
        id
        href
        items {
          id
          href
          label
          description
          icon
          iconType
          variant
          visible
        }
        label
        indicatorCount
        statusDot
        type
        initials
        avatarUrl
      }
    }
  }
`

const GET_PAGE_HEADER_ITEMS = gql`
  ${topbarNavFragment}
  query GetPageHeaderItems {
    ...TopbarNavigation
  }
`

const useGetPageHeaderItems = () => {
  return useQuery<GetPageHeaderItems>(GET_PAGE_HEADER_ITEMS, {
    errorBehaviour: 'do-nothing',
    pollInterval: 600_000,
  })
}

export type { PageHeaderFontAwesomeItem, PageHeaderAvatarItem }
export { useGetPageHeaderItems, topbarNavFragment, GET_PAGE_HEADER_ITEMS }
