import styled from 'styled-components'
import { Variables } from '@intellihr/ui-components'

const StyledCompactCensor = styled.div`
  border-color: ${Variables.Color.n400};
  display: inline-block;
  height: 100%;
`

const StyledDiv = styled.div<{ showChildren: boolean; timeoutComplete: boolean }>`
  min-width: 150px;
  background-color: ${Variables.Color.n250};
  text-align: center;
  opacity: ${({ showChildren }) => (showChildren ? '0' : '1')};
  visibility: ${({ showChildren }) => (showChildren ? 'hidden' : 'visible')};
  display: ${({ timeoutComplete }) => (timeoutComplete ? 'none' : 'inline-block')};
  transition: all 0.3s ease-out;
  cursor: pointer;
`

export { StyledCompactCensor, StyledDiv }
