import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'

const PERFORM_AUTH_CHECK = gql`
  query PerformAuthCheck($sourceUserId: ID!, $targetId: ID, $targetType: String!, $permissionSlugs: [String!]!) {
    performSingleHierarchicalAuthCheck(
      input: {
        sourceUserId: $sourceUserId
        permissionSlugs: $permissionSlugs
        targetResource: { id: $targetId, type: $targetType }
      }
    ) {
      authorisations {
        permissionSlug
        passes
      }
    }
  }
`

interface IPerformAuthCheckResponse {
  performSingleHierarchicalAuthCheck: {
    authorisations: Array<{
      permissionSlug: string
      passes: boolean
    }>
  }
}

interface IPerformAuthCheckVariables {
  sourceUserId: string
  permissionSlugs: string[]
  targetId?: string
  targetType: 'functional' | 'people'
}

const usePerformAuthCheckQuery = (
  errorBehaviour: 'throw-exception' | 'do-nothing',
  variables: IPerformAuthCheckVariables,
) => {
  return useQuery<IPerformAuthCheckResponse, IPerformAuthCheckVariables>(PERFORM_AUTH_CHECK, {
    errorBehaviour,
    variables,
    skip: variables.permissionSlugs.length === 0 || (variables.targetType === 'people' && !variables.targetId),
  })
}

export type { IPerformAuthCheckResponse, IPerformAuthCheckVariables }

export { PERFORM_AUTH_CHECK, usePerformAuthCheckQuery }
