import { Variables } from '@intellihr/ui-components'
import styled, { css } from 'styled-components'
import { IBrowserSelector } from 'src/services/reducers/browser/selectors'

const ToasterWrapper = styled.span<IBrowserSelector>`
  position: fixed;
  top: 12px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: ${Variables.ZIndex.zIndexToaster};
  max-width: 500px;

  ${(props: IBrowserSelector) =>
    props.isMobile &&
    css`
      max-width: none;
      left: ${Variables.Spacing.sMedium}px;
      right: ${Variables.Spacing.sMedium}px;
    `}

  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
`

export { ToasterWrapper }
