import { QueryResult } from '@apollo/client'

class GraphiteNetworkError extends Error {
  public originalQuery: QueryResult

  constructor(message: string | undefined, originalQuery: QueryResult) {
    super(message)

    this.originalQuery = originalQuery
  }
}

class GraphiteServerError extends Error {
  public originalQuery: QueryResult

  public errorId: string

  constructor(message: string | undefined, originalQuery: QueryResult) {
    super(message)

    this.originalQuery = originalQuery

    const errors =
      originalQuery.error?.graphQLErrors.flatMap((error) =>
        error.extensions['trace-request-id'] ? [error.extensions['trace-request-id']] : [],
      ) ?? []

    this.errorId = errors.join(', ')
  }
}

export { GraphiteNetworkError, GraphiteServerError }
