import { Tag, MangoTurnBack } from '@intellihr/blueberry'
import React from 'react'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { useErrorFooterButtons } from 'src/services/error/components/ErrorFooter'
import { StyledTurnBackWrapper, StyledTurnBackUl } from '../style'

const AccessDenied: React.FC<{ failedPermissions?: string[] }> = ({ failedPermissions }) => {
  const tCommon = useScope('common:error')
  const errorFooterButtons = useErrorFooterButtons()

  return (
    <StyledTurnBackWrapper>
      <MangoTurnBack
        variant="permission-error"
        title={tCommon('AccessDenied.header')}
        description={[
          tCommon('AccessDenied.messagePermissionDenied', {
            defaultValue: "It looks like you don't have permission to view this page",
          }),
          tCommon('AccessDenied.messagePermissionMissing', { defaultValue: 'Missing the following permissions:' }),
          <StyledTurnBackUl
            key="permissions-list"
            data-cy="failed-permissions"
          >
            {failedPermissions?.map((permission, index) => (
              <li
                data-cy={`failed-permission-${permission}`}
                key={index}
              >
                <Tag>{permission}</Tag>
              </li>
            ))}
          </StyledTurnBackUl>,
          tCommon('AccessDenied.messagePermissionShouldHave', {
            defaultValue:
              'If you think you should have permission to view this page, please contact your system administrator.',
          }),
        ]}
        actions={errorFooterButtons}
      />
    </StyledTurnBackWrapper>
  )
}

const MemoAccessDenied = React.memo(AccessDenied)

export { MemoAccessDenied as AccessDenied }
