import React, { useEffect, useMemo } from 'react'
import { HierarchicalSelectInput } from '@intellihr/ui-components'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'

interface ICustomInputForHierarchialSelectInputs {
  // type of list to calculate parent value
  type: string
  listOptions?: ReadonlyArray<{
    name: string
    id: string
    parentLocation?: { id: string } | null
    parentBusinessUnit?: { id: string } | null
  }>
  includeIds?: string[]
  props: ISelectInputProps
}

const CustomInputsForHierarchialSelectInput: React.FC<ICustomInputForHierarchialSelectInputs> = ({
  type,
  listOptions,
  includeIds,
  props,
}) => {
  const hierarchicalOptions = useMemo(
    () =>
      listOptions
        ? listOptions.flatMap((option) => {
            let parentValue: string | undefined
            if (!includeIds || includeIds.includes(option.id)) {
              if (type === 'businessUnit' && !includeIds) {
                parentValue = option.parentBusinessUnit?.id
              }
              if (type === 'location' && !includeIds) {
                parentValue = option.parentLocation?.id
              }
              return [
                {
                  label: option.name,
                  value: option.id,
                  parentValue,
                },
              ]
            }
            return []
          })
        : [],
    [listOptions, includeIds, type],
  )

  useEffect(() => {
    const keys = hierarchicalOptions.map((option) => option.value)
    if (keys.length > 0) {
      switch (typeof props.value) {
        case 'string':
          {
            if (!keys.includes(props.value)) {
              props?.onChange?.(null)
            }
          }
          break
        case 'object':
          {
            props?.onChange?.(props.value?.filter((value) => keys.includes(value as string)))
          }
          break
      }
    }
  }, [hierarchicalOptions, props])

  return (
    <HierarchicalSelectInput
      {...props}
      hierarchicalOptions={hierarchicalOptions}
    />
  )
}
export { CustomInputsForHierarchialSelectInput }
