import { PageHeaderAvatarItem, PageHeaderFontAwesomeItem } from './gql'

const isFontAwesomeTopbarItem = (test: unknown): test is PageHeaderFontAwesomeItem => {
  if (typeof test !== 'object' || !test) {
    return false
  }

  const topbarItem: { type?: unknown } = test

  if (typeof topbarItem.type !== 'string') {
    return false
  }

  return topbarItem.type === 'icon'
}

const isAvatarTopbarItem = (test: unknown): test is PageHeaderAvatarItem => {
  if (typeof test !== 'object' || !test) {
    return false
  }

  const topbarItem: { type?: unknown } = test

  if (typeof topbarItem.type !== 'string') {
    return false
  }

  return topbarItem.type === 'avatar'
}

export { isFontAwesomeTopbarItem, isAvatarTopbarItem }
