import gql from 'graphql-tag'
import { useMutation, useQuery } from 'src/services/apollo'
import { Reference } from '@apollo/client/utilities'
import { makeReference } from '@apollo/client'
import {
  CreateDocumentForOnboarding,
  CreateDocumentForOnboardingVariables,
  DeleteDocumentForOnboarding,
  DeleteDocumentForOnboardingVariables,
  SetOnboardingDocumentUploadStatus,
  SetOnboardingDocumentUploadStatusVariables,
  GetOnboardingPersonUploadedDocuments,
} from './__gql__/gqlCodeGen'

type IDocument = NonNullable<NonNullable<CreateDocumentForOnboarding['onboardingPersonDocumentCreate']>['document']>

const documentFragment = gql`
  fragment OnboardingPersonDocumentFragment on Document {
    id
    createdAtTimestamp
    displayName
    downloadUrlAbsolute
    humanReadableSize
    extension
    uploadStatus
    createdAt
  }
`

const CREATE_DOCUMENT = gql`
  ${documentFragment}

  mutation CreateDocumentForOnboarding($input: OnboardingPersonDocumentCreateInput!) {
    onboardingPersonDocumentCreate(input: $input) {
      presignedUrl
      document {
        ...OnboardingPersonDocumentFragment
      }
    }
  }
`

const SET_DOCUMENT_UPLOAD_STATUS = gql`
  mutation SetOnboardingDocumentUploadStatus($input: OnboardingPersonDocumentUpdateInput!) {
    onboardingPersonDocumentUpdate(input: $input) {
      document {
        id
        displayName
        downloadUrlAbsolute
        humanReadableSize
        extension
        uploadStatus
        createdAt
        createdAtDateTime
        createdAtTimestamp: createdAtDateTime
      }
    }
  }
`

const GET_ONBOARDING_PERSON_UPLOADED_DOCUMENTS = gql`
  query GetOnboardingPersonUploadedDocuments {
    getOnboardingPerson {
      id
      onboardingDocuments {
        onboardingTemplateDocumentFolderId
        document {
          id
          displayName
          downloadUrlAbsolute
          humanReadableSize
          extension
          uploadStatus
          createdAt
          createdAtDateTime
          createdAtTimestamp: createdAtDateTime
        }
      }
    }
  }
`

const DELETE_ONBOARDING_DOCUMENT = gql`
  mutation DeleteDocumentForOnboarding($input: OnboardingPersonDocumentDeleteInput!) {
    onboardingPersonDocumentDelete(input: $input) {
      documentId
    }
  }
`

const useOnboardingPersonCreateDocumentMutation = (personId: string) => {
  return useMutation<CreateDocumentForOnboarding, CreateDocumentForOnboardingVariables>(CREATE_DOCUMENT, {
    refetchQueries: ['GetOnboardingPersonUploadedDocuments'],
    update: (proxy, { data }) => {
      proxy.modify({
        id: proxy.identify(makeReference(`Person:${personId}`)),
        fields: {
          documents: (value: Reference[]) => [
            ...value,
            makeReference(`PersonDocument:${data?.onboardingPersonDocumentCreate.document.id}`),
          ],
        },
      })
    },
  })
}

const useOnboardingPersonDeleteDocumentMutation = () => {
  return useMutation<DeleteDocumentForOnboarding, DeleteDocumentForOnboardingVariables>(DELETE_ONBOARDING_DOCUMENT, {
    refetchQueries: ['GetOnboardingPersonUploadedDocuments'],
    update: (proxy, { data }) => {
      const normalizedId = proxy.identify({
        id: data?.onboardingPersonDocumentDelete.documentId,
        __typename: 'PersonDocument',
      })
      proxy.evict({ id: normalizedId })
      proxy.gc()
    },
    optimisticResponse: (deletedDocumentVariables: DeleteDocumentForOnboardingVariables) => ({
      onboardingPersonDocumentDelete: {
        __typename: 'OnboardingPersonDocumentDeleteResponse',
        documentId: deletedDocumentVariables.input.documentId,
      },
    }),
  })
}

const useSetOnboardingDocumentUploadStatusMutation = () => {
  return useMutation<SetOnboardingDocumentUploadStatus, SetOnboardingDocumentUploadStatusVariables>(
    SET_DOCUMENT_UPLOAD_STATUS,
    {
      refetchQueries: ['GetOnboardingPersonUploadedDocuments'],
    },
  )
}

const useGetOnboardingPersonUploadedDocuments = ({
  onCompleted,
}: {
  onCompleted?: (response: GetOnboardingPersonUploadedDocuments) => void
}) => {
  return useQuery<GetOnboardingPersonUploadedDocuments>(GET_ONBOARDING_PERSON_UPLOADED_DOCUMENTS, {
    onCompleted,
    errorBehaviour: 'do-nothing',
  })
}

export type { IDocument, GetOnboardingPersonUploadedDocuments }
export {
  documentFragment,
  useGetOnboardingPersonUploadedDocuments,
  useOnboardingPersonCreateDocumentMutation,
  useOnboardingPersonDeleteDocumentMutation,
  useSetOnboardingDocumentUploadStatusMutation,
}
