import React from 'react'
import { Subtract } from 'src/services/typescript'
import { TenantContext } from 'src/services/user/TenantContext'

interface ITenantDetailsProps {
  tenantDetails: {
    tenantName: string
    tenantCountry?: {
      id: string
      name: string
      isoCode: string
      dialCode: string
    } | null
    tenantCurrency?: {
      id: string
    } | null
  }
}

const withTenantDetails = <P extends {}>(
  TenantDetailsAwareComponent: React.ComponentType<P & Partial<ITenantDetailsProps>>,
) =>
  class WithTenantDetails extends React.Component<Subtract<P, ITenantDetailsProps>> {
    public render(): JSX.Element {
      return (
        <TenantContext.Consumer>
          {(tenantDetails) => {
            return (
              <TenantDetailsAwareComponent
                {...(this.props as P)}
                tenantDetails={tenantDetails}
              />
            )
          }}
        </TenantContext.Consumer>
      )
    }
  }

export type { ITenantDetailsProps }
export { withTenantDetails }
