import { gql } from '@apollo/client'
import { useMutation } from 'src/services/apollo'
import {
  OnboardingQualificationInstanceAttachmentUpdate,
  OnboardingQualificationInstanceAttachmentUpdateVariables,
} from './__gql__/useOnboardingQualificationInstanceAttachmentUpdateCodeGen'

const ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_UPDATE = gql`
  mutation OnboardingQualificationInstanceAttachmentUpdate(
    $documentId: ID!
    $uploadStatus: PersonDocumentUploadStatus
    $displayName: String
    $folderId: String
  ) {
    onboardingQualificationInstanceAttachmentUpdate(
      input: { documentId: $documentId, uploadStatus: $uploadStatus, displayName: $displayName, folderId: $folderId }
    ) {
      document {
        id
        uploadStatus
        displayName
        extension
        size
        humanReadableSize
        createdAtDateTime
        downloadUrlAbsolute
        personId
        folderId
        category
      }
    }
  }
`

const useOnboardingQualificationInstanceAttachmentUpdate = () => {
  return useMutation<
    OnboardingQualificationInstanceAttachmentUpdate,
    OnboardingQualificationInstanceAttachmentUpdateVariables
  >(ONBOARDING_QUALIFICATION_INSTANCE_ATTACHMENT_UPDATE, {})
}

export { useOnboardingQualificationInstanceAttachmentUpdate }
