import React, { useState } from 'react'
import { Button, Heading, Inline, Notice, Spinner, Stack, Text } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { QualificationsTable } from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/QualificationsStep/QualificationsTable/QualificationsTable'
import {
  IOnboardingPerson,
  ICompositeQualification,
  IJob,
  IJobRequirement,
} from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'
import { getCurrentIsoDate, useUserTimezoneDateModifiers } from 'src/services/helpers/dates'
import { ApprovalStatusIds } from 'src/services/constants/qualifications/approvalStatusIds'
import { QualificationTemplate } from 'src/__gql__/globalTypes'
import { IOnboardingFormSubmitData } from 'src/domain/Compliance/Qualifications/components/Forms/QualificationInstanceForm/QualificationInstanceForm'
import { getStatusType, statusType } from './QualificationsTable/QualificationsTable'
import { OnboardingStepType } from '../../types'
import { useOnboardingQualificationInstanceCreate } from './hooks/useOnboardingQualificationInstanceCreate'
import { IQualificationInstanceForModal, QualificationsModal } from './QualificationModal/QualificationsModal'
import { useOnboardingQualificationInstanceUpdate } from './hooks/useOnboardingQualificationInstanceUpdate'

interface IOnboardingStepProps {
  activeFrom: DateTime | null
  onboardingPerson: IOnboardingPerson
  onSubmit: (step: OnboardingStepType, skip?: boolean) => void
}

const isQualificationSupplied = (qualifications: ICompositeQualification[], requirementId: string): boolean => {
  const compositeQualification = qualifications.find((compositeQualification) => {
    return compositeQualification.qualificationTemplate.id === requirementId
  })

  const status = getStatusType(compositeQualification || null)
  return status === statusType.APPROVED || status === statusType.WAITING_FOR_REVIEW
}

const checkJobRequirementsSupplied = (
  jobRequirements: IJobRequirement[],
  qualifications: ICompositeQualification[],
): boolean => {
  let valid = true

  jobRequirements.forEach((requirement: IJobRequirement) => {
    if (valid) {
      if ('qualificationTemplates' in requirement.jobRequirementable) {
        requirement.jobRequirementable.qualificationTemplates.map((qualificationTemplate) => {
          if (qualificationTemplate && valid) {
            valid = isQualificationSupplied(qualifications, qualificationTemplate.id)
          }
        })

        // JobRequirementGroup processed
        // Break out to go to the next item in jobRequirements array
        return
      }

      valid = isQualificationSupplied(qualifications, requirement.jobRequirementable.id)
    }
  })

  return valid
}

const getAllJobRequirements = (onboardingPerson: IOnboardingPerson): IJobRequirement[] => {
  if (onboardingPerson && onboardingPerson.jobs && onboardingPerson.jobs.length <= 0) {
    return []
  }

  return onboardingPerson.jobs.flatMap((job: IJob) => {
    return job.jobRequirements.filter((requirement: IJobRequirement) => requirement)
  })
}

const refetchQualificationInstanceQueries = ['GetOnboardingPerson']

const QualificationsStep: React.FC<IOnboardingStepProps> = ({ activeFrom, onboardingPerson, onSubmit }) => {
  const tCommon = useScope('common:components')
  const tFeature = useScope('edm:features.Onboarding')
  const { isBefore } = useUserTimezoneDateModifiers()
  const today = getCurrentIsoDate()
  const isActive = activeFrom === null ? true : isBefore(activeFrom, today) // if active from is null then they have instant access
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [qualificationInstance, setQualificationInstance] = useState<IQualificationInstanceForModal | null>(null)
  const [createOnboardingQualificationInstance] = useOnboardingQualificationInstanceCreate()
  const successMessage = `${tFeature('successMessage')}`
  const [updateOnboardingQualificationInstance, { loading: updatingOnboardingQualificationInstance }] =
    useOnboardingQualificationInstanceUpdate(successMessage)

  if (updatingOnboardingQualificationInstance) {
    return <Spinner />
  }

  const jobRequirements = getAllJobRequirements(onboardingPerson)

  if (jobRequirements.length <= 0) {
    onSubmit(OnboardingStepType.RequiredQualifications, true)
    return <Spinner />
  }

  const isStepValid = checkJobRequirementsSupplied(jobRequirements, onboardingPerson.compositeQualifications)

  const openQualificationInstanceModal = async (
    providedQualificationInstance: IQualificationInstanceForModal | null,
    qualificationTemplateId: string,
  ) => {
    let qualificationForModal: IQualificationInstanceForModal | null = null

    if (
      providedQualificationInstance &&
      providedQualificationInstance.qualificationStatus.id === ApprovalStatusIds.Draft
    ) {
      qualificationForModal = providedQualificationInstance
    }

    if (!qualificationForModal) {
      const createDraftQualificationInstanceResult = await createOnboardingQualificationInstance({
        variables: {
          input: {
            qualificationTemplateId,
            qualificationStatusId: ApprovalStatusIds.Draft,
          },
        },
        refetchQueries: refetchQualificationInstanceQueries,
      })

      const createDraftQualificationInstance =
        createDraftQualificationInstanceResult.data?.onboardingQualificationInstanceCreate?.qualificationInstance
      if (createDraftQualificationInstance) {
        qualificationForModal = {
          id: createDraftQualificationInstance.id,
          qualificationStatus: createDraftQualificationInstance.qualificationStatus,
          issuingOrganisation: createDraftQualificationInstance.issuingOrganisation,
          registrationNumber: createDraftQualificationInstance.registrationNumber,
          issueDate: createDraftQualificationInstance.issueDate,
          expiryDate: createDraftQualificationInstance.expiryDate,
          notes: createDraftQualificationInstance.notes,
          qualificationTemplate: createDraftQualificationInstance.qualificationTemplate as QualificationTemplate,
          attachments: createDraftQualificationInstance.attachments,
        }
      }
    }

    if (qualificationForModal) {
      setQualificationInstance(qualificationForModal)
      setIsModalOpen(true)
    }
  }

  const handleModalSubmit = async (
    formData: IOnboardingFormSubmitData,
    qualificationInstance: IQualificationInstanceForModal,
  ) => {
    updateOnboardingQualificationInstance({
      variables: {
        input: {
          id: qualificationInstance.id,
          issuingOrganisation: formData.issuingOrganisation,
          registrationNumber: formData.registrationNumber,
          issueDate: formData.issueDate,
          expiryDate: formData.expiryDate,
          notes: formData.notes,
          qualificationStatusId: ApprovalStatusIds.AwaitingApproval,
        },
      },
      refetchQueries: refetchQualificationInstanceQueries,
    })

    setQualificationInstance(null)
    setIsModalOpen(false)
  }

  return (
    <Stack space="large">
      <Stack space="xSmall">
        <Heading>{tFeature('qualificationsHeading')}</Heading>
        <Text>{tFeature('qualificationsStepDescription')}</Text>
      </Stack>
      <QualificationsTable
        jobRequirements={jobRequirements}
        compositeQualifications={onboardingPerson.compositeQualifications}
        openQualificationInstanceModal={openQualificationInstanceModal}
      />
      <Text size="small">{tFeature('qualificationsStepHelp')}</Text>
      <Inline alignY="center">
        <Button
          onClick={() => {
            if (isStepValid) {
              onSubmit(OnboardingStepType.RequiredQualifications, false)
            }
          }}
          variant="primary"
          disabled={!isStepValid}
        >
          {tCommon('buttons.continue')}
        </Button>
        {isActive && (
          <Button
            onClick={() => {
              onSubmit(OnboardingStepType.RequiredQualifications, true)
            }}
            variant="tertiary"
          >
            {tCommon('buttons.skip')}
          </Button>
        )}
        {!isStepValid && !isActive && <Notice tone="info">{tFeature('qualificationsRequiredMessage')}</Notice>}
      </Inline>
      <QualificationsModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(formData) => handleModalSubmit(formData, qualificationInstance!)}
        submitting={updatingOnboardingQualificationInstance}
        qualificationInstance={qualificationInstance}
      />
    </Stack>
  )
}
export type { IOnboardingFormSubmitData }

export { QualificationsStep }
