import React from 'react'
import { Field } from 'redux-form'
import { ReduxVerticalFormField, useValidationRulesForField } from 'src/services/reduxForm'
import { TextInput } from '@intellihr/ui-components'
import { IOnboardingField } from '../hooks/useGetOnboardingFormInstanceAndPerson'
import { VisibilityOption } from '../../../../../forms/SettingsOnboardingTemplateForm/components/OnboardingTemplateFieldsTable'
import { fieldTypeIdToApiName, fieldTypeIdToComponentLookup } from '../PersonalDetailsFormStepContent'

const OnboardingMedicalConditionsField: React.FC<{
  fields: IOnboardingField[]
}> = ({ fields }) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <OnboardingFieldWrapper
            key={field.id}
            field={field}
            index={index}
          />
        )
      })}
    </>
  )
}

const OnboardingFieldWrapper: React.FC<{ field: IOnboardingField; index: number }> = ({ field, index }) => {
  const name = fieldTypeIdToApiName[field.fieldType.id]
  const isRequired = field.visibility.id === VisibilityOption.REQUIRED
  const validateRequired = useValidationRulesForField(name, ['required'])

  return (
    <Field
      name={name}
      component={ReduxVerticalFormField}
      validate={isRequired ? validateRequired : undefined}
      props={{
        component: fieldTypeIdToComponentLookup[field.fieldType.id]?.Component ?? TextInput,
        label: field.fieldType.name,
        isRequired,
        inputProps: {
          name,
          componentContext: `medical-condition-${index}`,
        },
      }}
    />
  )
}

export { OnboardingMedicalConditionsField }
