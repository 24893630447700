import { uniqBy } from 'lodash'
import { TypePolicies, TypePolicy } from '@apollo/client/cache/inmemory/policies'

interface ISimpleRedirectMap {
  typename: string
  getOneFieldName?: string
  getMultipleFieldName?: string
  keyFields?: string[]
}

/**
 * What is this?
 * -------------
 * The following sets up mappings between certain keys on the Query type
 * in the graphql store to point towards matching ids in the cache.
 *
 * This allows for lookups on getX fields to instantly resolve if the type
 * was ever retrieved before, rather than needing to wait on a response from
 * the backend for data you technically already have.
 *
 * If your team has types they want to add with this behaviour, just add them
 * to the mapping below.
 */
const simpleRedirectMaps: ISimpleRedirectMap[] = [
  {
    typename: 'BulkDataImport',
    getOneFieldName: 'getBulkDataImport',
  },
  {
    typename: 'BusinessEntity',
    getOneFieldName: 'getBusinessEntity',
    getMultipleFieldName: 'getBusinessEntities',
  },
  {
    typename: 'BusinessUnit',
    getOneFieldName: 'getBusinessUnit',
    getMultipleFieldName: 'getBusinessUnits',
  },
  {
    typename: 'FormDesign',
    getOneFieldName: 'getFormDesign',
  },
  {
    typename: 'Job',
    getOneFieldName: 'getJob',
    getMultipleFieldName: 'getJobs',
  },
  {
    typename: 'Location',
    getOneFieldName: 'getLocation',
    getMultipleFieldName: 'getLocations',
  },
  {
    typename: 'PayGrade',
    getOneFieldName: 'getPayGrade',
    getMultipleFieldName: 'getPayGrades',
  },
  {
    typename: 'PermissionGroup',
    getOneFieldName: 'getPermissionGroup',
    getMultipleFieldName: 'getPermissionGroups',
  },
  {
    typename: 'Person',
    getOneFieldName: 'getPerson',
    getMultipleFieldName: 'getPeople',
  },
  {
    typename: 'WorkClass',
    getOneFieldName: 'getWorkClass',
    getMultipleFieldName: 'getWorkClasses',
  },
  {
    typename: 'Establishment',
    getOneFieldName: 'getEstablishment',
    getMultipleFieldName: 'getEstablishments',
  },
  {
    typename: 'WorkType',
    getOneFieldName: 'getWorkType',
    getMultipleFieldName: 'getWorkTypes',
  },
  {
    typename: 'BulkDataImportSupportedUpload',
    getOneFieldName: 'bulkDataImportGetSupportedUpload',
    keyFields: ['typeString'],
  },
  {
    typename: 'SelfServiceAction',
    getOneFieldName: 'getSelfServiceAction',
  },
  {
    typename: 'User',
    getOneFieldName: 'getUser',
  },
]

const getTypePolicies = (): TypePolicies => {
  const fields: { [key: string]: TypePolicy } = {
    /*
     * As of apollo client 3 the cache will not auto merge unless it can find a key to merge by.
     * These fields are ambiguous so therefore will not be auto-merged. You can provide a merge function
     * to let apollo know how to merge these fields or provide true as a shorthand for a simple merge.
     * More reading can be found at https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
     */
    ComplianceQuery: {
      fields: { listContractorsForCompliance: { merge: true } },
    },
    MyFormComplianceQuery: {
      fields: { getMyStatsForDashboard: { merge: true } },
    },
    RemunerationQuery: { merge: true },
    SelfServiceActionQuery: { merge: true },
    TenantConfiguration: { merge: true, keyFields: ['__typename'] },
    Mutation: {
      fields: {
        goal: { merge: true },
        qualification: { merge: true },
      },
    },
    PayGrade: {
      keyFields: (object: { id: string; valueId?: string }) => {
        return object.valueId ? `PayGrade:${object.id}-${object.valueId}` : `PayGrade:${object.id}`
      },
    },
    BulkDataImportSupportedDownload: { keyFields: ['typeString'] },
    BulkDataImportSupportedUpload: { keyFields: ['typeString'] },
    FormPreview: { keyFields: ['key'] },
    Query: {
      fields: {
        auth: { merge: true },
        integrations: {
          merge: (existing, incoming) => {
            return {
              ...existing,
              ...incoming,
              xero: {
                ...existing?.xero,
                ...incoming?.xero,
              },
            }
          },
        },
        listOffsetNotifications: {
          keyArgs: false,
          merge: (existing, incoming, { args, readField }) => {
            const existingNotifications = existing?.notifications ?? []
            const incomingNotifications = incoming?.notifications ?? []
            const mergedNotifications = [...existingNotifications]

            if (args?.afterId === null) {
              mergedNotifications.unshift(...incomingNotifications)
            } else {
              const insertIndex = mergedNotifications.findIndex((notification) => {
                return readField<string>('id', notification) === args?.afterId
              })

              if (insertIndex >= 0) {
                mergedNotifications.splice(insertIndex + 1, 0, ...incomingNotifications)
              }
            }

            const dedupedNotifications = uniqBy(mergedNotifications, '__ref')

            return {
              ...existing,
              ...incoming,
              notifications: dedupedNotifications,
            }
          },
        },
        getUserConfig: { merge: true },
        personProfile: { merge: true },
        qualification: { merge: true },
        dashboard: { merge: true },
        compliance: { merge: true },
        formCompletionRecipients: { merge: true },
        goal: { merge: true },
        navigation: { merge: true },
        peopleJobSearch: { merge: true },
        user: { merge: true },
        analyticsSettings: { merge: true },
        analyticsPermission: { merge: true },
        analyticsFormData: { merge: true },
        analyticsConfiguration: { merge: true },
        remuneration: { merge: true },
        myFormCompliance: { merge: true },
        formCompliance: { merge: true },
        selfServiceAction: { merge: true },
        getFormDesign: { merge: true },
      },
    },
  }

  /*
   * This allows us to read related data that haws been loaded through a separate query and is cached instead of running a query.
   * What it is doing can be found at https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-read-function
   */
  simpleRedirectMaps.forEach((map) => {
    if (map.getOneFieldName) {
      fields.Query.fields![map.getOneFieldName] = {
        read: (existing, { args, toReference }) => {
          // If getX returns null rather than undefined, we want to still return null rather than fallback as it implies the value is deleted
          if (existing === null) {
            return null
          }

          return (
            existing ??
            toReference({
              __typename: map.typename,
              id: args?.id,
              ...map.keyFields?.reduce((acc, keyField) => {
                acc[keyField] = args?.[keyField]
                return acc
              }, {}),
            })
          )
        },
      }
    }

    if (map.getMultipleFieldName) {
      fields.Query.fields![map.getMultipleFieldName] = {
        read: (existing, { args, toReference }) => {
          if (existing === null) {
            return null
          }

          return (
            existing ??
            args?.ids?.map((id: string) =>
              toReference({
                __typename: map.typename,
                id,
                ...map.keyFields?.reduce((acc, keyField) => {
                  acc[keyField] = args?.[keyField]
                  return acc
                }, {}),
              }),
            )
          )
        },
      }
    }
  }, fields)

  return fields
}

export { getTypePolicies }
