import React from 'react'
import { DefaultsProvider } from '@intellihr/ui-components'
import { RoutedAnchor } from 'src/services/reactRouter'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import {
  graphqlDateFormatToMomentFormat,
  graphqlTimeFormatToMomentFormat,
} from 'src/services/uiComponentCandidates/Date/helper'
import { useScope } from '../i18n/LocalizationProvider'

const UIComponentsDefaultsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const t = useScope('common:uiComponents')
  const { dateFormat, timeFormat, timezone, language } = useUserContext()

  return (
    <DefaultsProvider
      value={{
        AnchorComponent: RoutedAnchor,
        tenorApiKey: process.env.REACT_APP_TENOR_API_KEY,
        i18nTranslationFunction: t,
        i18nLocale: language,
        dateFormat: graphqlDateFormatToMomentFormat(dateFormat),
        timeFormat: graphqlTimeFormatToMomentFormat(timeFormat),
        timezone,
      }}
    >
      {children}
    </DefaultsProvider>
  )
}

export { UIComponentsDefaultsProvider }
