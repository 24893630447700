import axios from 'axios'
import uuid from 'uuid'
import ErrorStackParser from 'error-stack-parser'
import StackTraceGPS from 'stacktrace-gps'
import { mapStackTrace } from 'sourcemapped-stacktrace'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { getSentry } from 'src/services/sentry/helper'
import { severityLevelFromString } from '@sentry/utils'
import { ScopeContext } from '@sentry/types'

type IErrorLevels = 'emergency' | 'alert' | 'critical' | 'error' | 'warning' | 'notice' | 'info' | 'debug'

interface IErrorData {
  id: string
  error?: Error
  location: string
  service: 'SPA'
  userAgent: string
  message: string
  level: IErrorLevels
  meta: {
    [key: string]: unknown
  }
  sendToSentry: boolean
}

interface ILoggerOptions {
  userImpact: string
  generateStack?: boolean
  meta?: {
    stack?: string
    [key: string]: unknown
  }
  errorId?: string
  sendToSentry?: boolean
}

const internalSentrySeverityMap = {
  emergency: severityLevelFromString('fatal'),
  alert: severityLevelFromString('fatal'),
  critical: severityLevelFromString('fatal'),
  error: severityLevelFromString('error'),
  warning: severityLevelFromString('warning'),
  notice: severityLevelFromString('info'),
  info: severityLevelFromString('info'),
  debug: severityLevelFromString('debug'),
}

const gps = new StackTraceGPS()

function getStackFromSourceMapStackTrace(stack: string): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      mapStackTrace(stack, (mappedStack) => {
        resolve(mappedStack.join())
      })
    } catch (e) {
      reject(e)
    }
  })
}

async function getStack(stack: string): Promise<string> {
  try {
    const stackFrames = ErrorStackParser.parse({ stack } as Error)
    const promises = stackFrames.map((sf) => gps.pinpoint(sf))
    const tracedStackFrames = await Promise.all(promises)

    return tracedStackFrames.map((sf) => sf.toString()).join('\n')
  } catch (e) {
    try {
      return await getStackFromSourceMapStackTrace(stack)
    } catch (ee) {
      return stack
    }
  }
}

async function getErrorData(
  level: IErrorLevels,
  error: string | Error,
  { userImpact, generateStack, meta = {}, errorId, sendToSentry = true }: ILoggerOptions,
): Promise<IErrorData> {
  if (typeof error !== 'string') {
    meta.stack = error.stack
  }

  if (generateStack) {
    meta.stack = new Error().stack
  }

  if (meta.stack) {
    // eslint-disable-next-line require-atomic-updates
    meta.stack = await getStack(meta.stack)
  }

  return {
    id: errorId || uuid.v4(),
    error: typeof error !== 'string' ? error : undefined,
    location: window.location.href,
    service: 'SPA',
    userAgent: navigator.userAgent,
    message: typeof error !== 'string' ? error.message : error,
    level,
    meta: {
      userImpact,
      ...meta,
    },
    sendToSentry,
  }
}

let informedAboutNoLogging = false

function logToSentry(errorData: IErrorData) {
  const sentry = getSentry()
  if (sentry) {
    const level = internalSentrySeverityMap[errorData.level]

    if (!level) {
      return null
    }

    if (!errorData.sendToSentry) {
      return null
    }

    const captureContext: Partial<ScopeContext> = {
      level,
      extra: errorData.meta,
    }

    if (errorData.error) {
      return sentry.captureException(errorData.error, captureContext)
    } else {
      return sentry.captureMessage(errorData.message, captureContext)
    }
  }

  return null
}

async function logToCloud(errorData: IErrorData) {
  const { REACT_APP_ERROR_REPORT_SERVICE_ENDPOINT } = process.env

  errorData.meta.sentryEventId = logToSentry(errorData)

  if (!REACT_APP_ERROR_REPORT_SERVICE_ENDPOINT) {
    if (!informedAboutNoLogging) {
      // eslint-disable-next-line no-console
      console.log('REACT_APP_ERROR_REPORT_SERVICE_ENDPOINT was not provided; disabling cloud logging')
      informedAboutNoLogging = true
    }

    return
  }

  const axiosConfig = {
    headers: {
      Authorization: await getSession().getAccessToken(),
    } as Record<string, string>,
  }

  return axios.post(REACT_APP_ERROR_REPORT_SERVICE_ENDPOINT, errorData, axiosConfig).catch(console.error) // eslint-disable-line no-console
}

const cloudLogger = {
  emergency: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('emergency', error, options)
    console.error(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  alert: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('alert', error, options)
    console.error(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  critical: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('critical', error, options)
    console.error(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  error: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('error', error, options)
    console.error(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  warning: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('warning', error, options)
    console.warn(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  notice: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('notice', error, options)
    console.info(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  info: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('info', error, options)
    console.info(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
  debug: async (error: string | Error, options: ILoggerOptions) => {
    const errorData = await getErrorData('debug', error, options)
    console.debug(error, errorData) // eslint-disable-line no-console
    return logToCloud(errorData)
  },
}

export type { IErrorLevels }
export { cloudLogger }
