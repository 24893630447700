import { useScope } from 'src/services/i18n/LocalizationProvider'

type StatusColor = 'dark' | 'secondary' | 'highlight' | 'neutral' | 'warning'
type TagVariants = {
  tone: 'highlight' | 'neutral' | 'info' | undefined
  staticColor: 'grey' | 'indigo' | 'blue' | 'green' | 'orange' | 'red' | 'purple' | undefined
}
type TagTone = {
  tone?: TagVariants['tone']
  staticColor?: TagVariants['staticColor']
}

/**
 * Returns the intended pill/ui component color for a given job status enum
 */
const getColorForJobStatus = (isOnExtendedLeave: boolean, jobStatus: string | null): StatusColor | undefined => {
  if (isOnExtendedLeave) {
    return 'warning'
  }

  switch (jobStatus) {
    case 'JOB_STATUS_FUTURE_JOB':
      return 'secondary'
    case 'JOB_STATUS_ENDING_JOB':
      return 'highlight'
    case 'JOB_STATUS_PAST_JOB':
      return 'neutral'
    default:
      return undefined
  }
}

/**
 * Returns the intended tag/blueberry color for a given employment status enum
 */
const getColorForEmploymentStatusTag = (
  isOnExtendedLeave: boolean,
  employmentStatusId?: string | null,
): TagTone | undefined => {
  if (isOnExtendedLeave) {
    return { tone: 'neutral', staticColor: 'orange' }
  }

  switch (employmentStatusId) {
    case 'EMPLOYMENT_STATUS_NO_JOB_RECORDS':
      return { tone: 'neutral', staticColor: 'indigo' }
    case 'EMPLOYMENT_STATUS_UPCOMING_STAFF':
      return { tone: 'neutral', staticColor: 'purple' }
    case 'EMPLOYMENT_STATUS_RETURNING_STAFF':
      return { tone: 'neutral', staticColor: 'blue' }
    case 'EMPLOYMENT_STATUS_EXITING_STAFF':
      return { tone: 'neutral', staticColor: 'red' }
    case 'EMPLOYMENT_STATUS_PAST_STAFF':
      return { tone: 'neutral', staticColor: 'grey' }
    default:
      return undefined
  }
}

/**
 * Returns the intended pill/ui component color for a given employment status enum
 */
const getColorForEmploymentStatus = (
  isOnExtendedLeave: boolean,
  employmentStatusId?: string | null,
): StatusColor | undefined => {
  if (isOnExtendedLeave) {
    return 'warning'
  }

  switch (employmentStatusId) {
    case 'EMPLOYMENT_STATUS_NO_JOB_RECORDS':
      return 'dark'
    case 'EMPLOYMENT_STATUS_UPCOMING_STAFF':
      return 'secondary'
    case 'EMPLOYMENT_STATUS_RETURNING_STAFF':
      return 'secondary'
    case 'EMPLOYMENT_STATUS_EXITING_STAFF':
      return 'highlight'
    case 'EMPLOYMENT_STATUS_PAST_STAFF':
      return 'neutral'
    default:
      return undefined
  }
}

const useTextForEmploymentStatus = () => {
  const t = useScope('edm:terminology')

  /**
   * Returns the human readable name of a given employment status enum
   */
  const getTextForEmploymentStatus = (
    isOnExtendedLeave: boolean,
    employmentStatusId?: string | null,
  ): string | undefined => {
    if (isOnExtendedLeave) {
      return t('Person.employmentStatusOnExtendedLeave')
    }

    switch (employmentStatusId) {
      case 'EMPLOYMENT_STATUS_NO_JOB_RECORDS':
        return t('Person.employmentStatusNoJobRecords')
      case 'EMPLOYMENT_STATUS_UPCOMING_STAFF':
        return t('Person.employmentStatusUpcoming')
      case 'EMPLOYMENT_STATUS_RETURNING_STAFF':
        return t('Person.employmentStatusReturning')
      case 'EMPLOYMENT_STATUS_EXITING_STAFF':
        return t('Person.employmentStatusExiting')
      case 'EMPLOYMENT_STATUS_PAST_STAFF':
        return t('Person.employmentStatusPast')
      default:
        return undefined
    }
  }
  return { getTextForEmploymentStatus }
}

const usePillsForEmploymentStatus = () => {
  const { getTextForEmploymentStatus } = useTextForEmploymentStatus()

  /**
   * Returns an array of pills for a given employment status enum
   */
  const getPillsForEmploymentStatus = (isOnExtendedLeave: boolean, employmentStatusId?: string | null) => {
    const pills: Array<{ tone: TagVariants['tone']; staticColor: TagVariants['staticColor']; text: string }> = []

    if (isOnExtendedLeave) {
      pills.push({
        tone: 'neutral',
        staticColor: 'orange',
        text: getTextForEmploymentStatus(true, employmentStatusId)!,
      })
    }

    if (employmentStatusId) {
      const color = getColorForEmploymentStatusTag(false, employmentStatusId)
      const text = getTextForEmploymentStatus(false, employmentStatusId)

      if (color && text) {
        pills.push({
          tone: color.tone ?? undefined,
          staticColor: color.staticColor ?? undefined,
          text,
        })
      }
    }

    return pills
  }
  return { getPillsForEmploymentStatus }
}

export { getColorForEmploymentStatus, useTextForEmploymentStatus, usePillsForEmploymentStatus, getColorForJobStatus }

export type { StatusColor, TagVariants }
