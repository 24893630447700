import { Inline, Tag } from '@intellihr/blueberry'
import { AvatarEntity, Text, UnstyledLink } from '@intellihr/ui-components'
import React, { useCallback, useMemo } from 'react'
import isUrl from 'validator/lib/isURL'
import {
  CustomFieldDefinition,
  CustomFieldValue,
  isMultiSelectCustomFieldValue,
  isNumberCustomFieldValue,
  isPeopleDropdownCustomFieldValue,
  isSingleSelectCustomFieldValue,
  isTextCustomFieldValue,
} from 'src/domain/EDM/public/services/customFieldsTypeGuardService'
import { EntityList } from 'src/services/uiComponentCandidates/EntityList'
import { urlForRoute } from 'src/services/routes'
import { getColorForEmploymentStatus } from 'src/domain/EDM/public/services/personEmploymentStatusService'
import { PersonGroupDropdown } from 'src/domain/EDM/public/components/displays/PersonGroupDropDown'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { CompactCensor } from 'src/services/uiComponentCandidates/CompactCensor'
import { CustomFieldType } from 'src/__gql__/globalTypes'
import { PersonHoverCardContent } from 'src/domain/EDM/internal/components/displays/HoverCards/PersonHoverCardContent'
import { Hoverable } from 'src/domain/EDM/internal/components/displays/HoverCards/Hoverable'
import { CustomFieldDetails } from './style'

enum CustomFieldRecordSize {
  TINY = 'TINY',
  COMPACT = 'COMPACT',
  STANDARD = 'STANDARD',
}

const useContent = (
  customFieldValue?: CustomFieldValue,
  options?: { size?: CustomFieldRecordSize; isChanged?: boolean },
) => {
  const t = useScope('edm:features.CustomFields')

  if (!customFieldValue?.valueUnion) {
    return null
  }

  if (isTextCustomFieldValue(customFieldValue)) {
    if (
      isUrl(customFieldValue.valueUnion.text, {
        require_protocol: true,
      })
    ) {
      if (customFieldValue.valueUnion.text === '') {
        return null
      }

      return (
        <Text>
          <Text.Link
            href={customFieldValue.valueUnion.text}
            openInNewTab
          >
            {customFieldValue.valueUnion.text}
          </Text.Link>
        </Text>
      )
    }

    return customFieldValue.valueUnion.text
  }
  if (isNumberCustomFieldValue(customFieldValue)) {
    return customFieldValue.valueUnion.number
  }
  if (isSingleSelectCustomFieldValue(customFieldValue)) {
    if (
      isUrl(customFieldValue.valueUnion.label, {
        require_protocol: true,
      })
    ) {
      if (customFieldValue.valueUnion.label === '') {
        return null
      }
      return (
        <Text>
          <Text.Link
            href={customFieldValue.valueUnion.label}
            openInNewTab
          >
            {customFieldValue.valueUnion.label}
          </Text.Link>
        </Text>
      )
    }

    return customFieldValue.valueUnion.label
  }
  if (isMultiSelectCustomFieldValue(customFieldValue)) {
    if (customFieldValue.valueUnion.labels.length === 0) {
      return null
    }

    return (
      <Inline>
        {customFieldValue.valueUnion.labels.map((label: string, index: number) => {
          return (
            <Tag
              tone="neutral"
              staticColor={options?.isChanged ? 'blue' : 'grey'}
              key={`${customFieldValue.apiName}-${index}`}
              testId={`multi-select-custom-field-brick-${customFieldValue.apiName}-${label}`}
            >
              {label}
            </Tag>
          )
        })}
      </Inline>
    )
  }
  if (isPeopleDropdownCustomFieldValue(customFieldValue)) {
    if (options?.size === CustomFieldRecordSize.COMPACT || options?.size === CustomFieldRecordSize.TINY) {
      if (customFieldValue.valueUnion.options.length === 0) {
        return null
      }
      if (customFieldValue.valueUnion.options.length === 1) {
        const { person } = customFieldValue.valueUnion.options[0]

        return (
          <UnstyledLink href={urlForRoute('people.show', { personId: person.id })}>
            {options.size === CustomFieldRecordSize.TINY ? (
              person.displayName ?? ''
            ) : (
              <AvatarEntity
                imageUrl={person.profilePictureUrl ?? undefined}
                initials={person.initials}
                primaryText={person.displayName ?? ''}
                size={AvatarEntity.Size.SmallCompact}
                componentContext={`custom-field-record-avatar-entity-${person.id}`}
              />
            )}
          </UnstyledLink>
        )
      }

      return (
        <PersonGroupDropdown
          people={customFieldValue.valueUnion.options.map(({ job, person }) => {
            const displayedJob = job ?? person.primaryJob

            return {
              id: person.id,
              personName: person.displayName,
              initials: person.initials,
              imageUrl: person.profilePictureUrl ?? undefined,
              linkUrl: urlForRoute('people.show', { personId: person.id }),
              jobName: displayedJob?.jobName.name,
              jobId: displayedJob?.id,
            }
          })}
          searchTextPlaceholder={t('peopleDropdownValuePresentation.filterPlaceholder')}
          noSearchResultsMessage={t('peopleDropdownValuePresentation.filterTextNoResults')}
        />
      )
    }

    return (
      <EntityList
        filterPlaceholder={t('peopleDropdownValuePresentation.filterPlaceholder')}
        avatarEntities={customFieldValue.valueUnion.options.map(({ job, person }) => {
          const displayedJob = job ?? person.primaryJob
          return {
            imageUrl: person.profilePictureUrl ?? undefined,
            href: urlForRoute('people.show', { personId: person.id }),
            initials: person.initials,

            primaryText: person.displayName ?? 'N/A',
            secondaryText: displayedJob?.jobName.name,
            statusDot: getColorForEmploymentStatus(displayedJob?.isOnExtendedLeave ?? false, person.employmentStatusId),
            componentContext: `custom-field-record-entity-list-${person.id}`,
          }
        })}
        componentContext={'custom-field-record-entity-list'}
        maxDisplayedEntities={4}
        collapsedEntityPrimaryText={t('peopleDropdownValuePresentation.collapsedEntityPrimaryText')}
      />
    )
  }

  return null
}

interface ICustomFieldValueOptions {
  size?: CustomFieldRecordSize
  isChanged?: boolean
  onShowCensor?: (value: boolean) => void
}

const useCustomFieldValueForRecord = (
  customFieldValue?: CustomFieldValue & CustomFieldDefinition,
  options?: ICustomFieldValueOptions,
) => {
  const content = useContent(customFieldValue, options)
  return customFieldValue?.definition.isSensitive ? (
    <CompactCensor onShowCensor={options?.onShowCensor}>{content}</CompactCensor>
  ) : (
    content
  )
}

const getCustomFieldValueBricks = (customFieldValues?: Array<CustomFieldValue & CustomFieldDefinition>) => {
  const values = customFieldValues?.flatMap((customFieldValue) => {
    if (!customFieldValue.valueUnion) {
      return []
    }

    const customField = {
      title: customFieldValue.definition.name,
      apiName: customFieldValue.apiName,
    } as const

    if (isTextCustomFieldValue(customFieldValue)) {
      if (isUrl(customFieldValue.valueUnion.text, { require_protocol: true })) {
        if (customFieldValue.valueUnion.text === '') {
          return []
        }

        return [
          <CustomFieldDetails
            key={customFieldValue.definition.id}
            customField={customField}
            sensitive={customFieldValue.definition.isSensitive}
          >
            <Text.Link
              href={customFieldValue.valueUnion.text}
              openInNewTab
            >
              {customFieldValue.valueUnion.text}
            </Text.Link>
          </CustomFieldDetails>,
        ]
      }

      return [
        <CustomFieldDetails
          key={customFieldValue.definition.id}
          customField={customField}
          sensitive={customFieldValue.definition.isSensitive}
        >
          {customFieldValue.valueUnion.text}
        </CustomFieldDetails>,
      ]
    }
    if (isNumberCustomFieldValue(customFieldValue)) {
      return [
        <CustomFieldDetails
          key={customFieldValue.definition.id}
          customField={customField}
          sensitive={customFieldValue.definition.isSensitive}
        >
          {customFieldValue.valueUnion.number}
        </CustomFieldDetails>,
      ]
    }
    if (isSingleSelectCustomFieldValue(customFieldValue)) {
      if (
        isUrl(customFieldValue.valueUnion.label, {
          require_protocol: true,
        })
      ) {
        if (customFieldValue.valueUnion.label === '') {
          return null
        }
        return [
          <CustomFieldDetails
            key={customFieldValue.definition.id}
            customField={customField}
            sensitive={customFieldValue.definition.isSensitive}
          >
            <Text.Link
              href={customFieldValue.valueUnion.label}
              openInNewTab
            >
              {customFieldValue.valueUnion.label}
            </Text.Link>
          </CustomFieldDetails>,
        ]
      }

      return [
        <CustomFieldDetails
          key={customFieldValue.definition.id}
          customField={customField}
          sensitive={customFieldValue.definition.isSensitive}
        >
          {customFieldValue.valueUnion.label}
        </CustomFieldDetails>,
      ]
    }
    if (isMultiSelectCustomFieldValue(customFieldValue)) {
      if (customFieldValue.valueUnion.labels.length === 0) {
        return []
      }

      return customFieldValue.valueUnion.labels.map((label: string, index: number) => {
        return (
          <CustomFieldDetails
            key={`${customFieldValue.apiName}-${index}`}
            customField={customField}
            sensitive={customFieldValue.definition.isSensitive}
          >
            {label}
          </CustomFieldDetails>
        )
      })
    }
    if (isPeopleDropdownCustomFieldValue(customFieldValue)) {
      return customFieldValue.valueUnion.options.map((option) => {
        const { person } = option

        return (
          <CustomFieldDetails
            key={customFieldValue.apiName}
            customField={customField}
            sensitive={customFieldValue.definition.isSensitive}
          >
            <Hoverable
              renderCardContent={() => (
                <PersonHoverCardContent
                  personId={person.id}
                  openInNewTab={false}
                />
              )}
            >
              {() => (
                <UnstyledLink href={urlForRoute('people.show', { personId: person.id })}>
                  {person.displayName}
                </UnstyledLink>
              )}
            </Hoverable>
          </CustomFieldDetails>
        )
      })
    }

    return []
  })

  return values
}

const useTranslateFieldType = () => {
  const tTerminology = useScope('edm:terminology')

  const typeMapping = useMemo(
    () => ({
      SINGLE_SELECT: tTerminology('CustomField.fieldTypeSingleSelect'),
      MULTI_SELECT: tTerminology('CustomField.fieldTypeMultiSelect'),
      TEXT: tTerminology('CustomField.fieldTypeText'),
      NUMBER: tTerminology('CustomField.fieldTypeNumber'),
      PEOPLE_DROPDOWN: tTerminology('CustomField.fieldTypePeopleDropdown'),
    }),
    [tTerminology],
  )

  return useCallback(
    (type: CustomFieldType) => typeMapping[type] ?? tTerminology('CustomField.fieldTypeUnknown'),
    [tTerminology, typeMapping],
  )
}

export { getCustomFieldValueBricks, CustomFieldRecordSize, useCustomFieldValueForRecord, useTranslateFieldType }
