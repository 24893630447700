import { IPeopleSearchJob, IPeopleSearchOtherJobs, IPerson } from './gql'

type IPeopleDropdownOption = IBusinessUnitOption | IInfoOption | IPersonOption | IControlOption

type IPeopleAndBusinessUnitOption = IBusinessUnitOption | IPersonOption

interface IInfoOption {
  type: 'info'
  message: string
  disabled: true
}

interface IBusinessUnitOption {
  type: 'businessUnit'
  name: string
  disabled: boolean
  value: IBusinessUnitValue
}

type IPeopleJobsValue = {
  personId: string
  person?: IPerson
  jobId?: string | null
  job?: IPeopleSearchJob | null
}

type IBusinessUnitValue = {
  businessUnitId: string
}

interface IPersonOption {
  type: 'person'
  person: IPerson
  job?: IPeopleSearchJob | null
  otherJobs: IPeopleSearchOtherJobs[]
  value: IPeopleJobsValue
}

interface IControlOption {
  type: 'control'
  name: string
  toggleSet: () => void
  isSet: boolean
}

const isControlOption = (option: IPeopleDropdownOption): option is IControlOption => {
  return option.type === 'control'
}

const isPersonOption = (option: IPeopleDropdownOption): option is IPersonOption => {
  return option.type === 'person'
}

const isBusinessUnitOption = (option: IPeopleDropdownOption): option is IBusinessUnitOption => {
  return option.type === 'businessUnit'
}

const isInfoOption = (option: IPeopleDropdownOption): option is IInfoOption => {
  return option.type === 'info'
}

const isBusinessUnitValue = (value?: { [key: string]: unknown }): value is IBusinessUnitValue => {
  return !!value?.businessUnitId
}

const isPeopleJobsValue = (value?: { [key: string]: unknown }): value is IPeopleJobsValue => {
  return !!value?.personId
}

type IPeopleDropdownValue = IPeopleJobsValue | IBusinessUnitValue

export type {
  IBusinessUnitOption,
  IBusinessUnitValue,
  IControlOption,
  IInfoOption,
  IPeopleDropdownOption,
  IPeopleDropdownValue,
  IPeopleJobsValue,
  IPerson,
  IPersonOption,
  IPeopleAndBusinessUnitOption,
}
export { isBusinessUnitOption, isBusinessUnitValue, isControlOption, isInfoOption, isPeopleJobsValue, isPersonOption }
