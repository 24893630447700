import { IGoogleMapApiParams } from '../declaration'

interface IParams extends IGoogleMapApiParams {
  base: string
  callback: string
}

const urlBuilder = ({ base: url, key, libraries, language, version, callback }: IParams) => {
  url += '?'

  if (key) {
    url += `key=${key}&`
  }

  if (libraries && libraries.length) {
    url += `libraries=${libraries.join(',')}&`
  }

  if (language) {
    url += `language=${language}&`
  }

  if (version) {
    url += `version=${version}&`
  }

  url += `callback=${callback}`

  return url
}

export { urlBuilder }
