import React, { createContext, useMemo, useContext } from 'react'
import { LocalizationContext } from 'src/services/i18n/LocalizationProvider'
import { Focus, Page } from './pages'
import { FormDesign } from './forms'
import { FocusKey, PageKey } from './types'
import { AccessibleAnalyticsFeatureInfoContext } from './AnalyticsPlanModule'

type TFocuses = Partial<Record<FocusKey, Focus>>

type TPages = Partial<Record<PageKey, Page>>

type TFormDesigns = Partial<Record<string, FormDesign>>

interface IAnalyticsPagesContext {
  focuses: TFocuses
  pages: TPages
  formDesigns: TFormDesigns
}

const AnalyticsPagesContext = createContext<IAnalyticsPagesContext>({
  focuses: {} as Record<FocusKey, Focus>,
  pages: {} as Record<PageKey, Page>,
  formDesigns: {},
})

interface IAnalyticsPagesProviderProps {
  enableBetaPageAccess: boolean
  formDesigns: FormDesign[]
}

const AnalyticsPagesProvider: React.FC<React.PropsWithChildren<IAnalyticsPagesProviderProps>> = ({
  enableBetaPageAccess,
  formDesigns: _formDesigns,
  children,
}) => {
  const accessibleAnalyticsFeatureInfo = useContext(AccessibleAnalyticsFeatureInfoContext)
  const { withScope, i18n } = useContext(LocalizationContext)
  const isLoaded = i18n.isInitialized && i18n.exists('analyticsCommon:main')
  const { language: userConfigedLng } = i18n

  const formDesigns = useMemo(
    () =>
      _formDesigns.reduce<TFormDesigns>(
        (acc, formDesign) => ({
          ...acc,
          [formDesign.key]: formDesign,
        }),
        {},
      ),
    [_formDesigns],
  )

  const [filteredFocuses, filteredPages] = useMemo(() => {
    if (!isLoaded) {
      return [{}, {}]
    }

    const analyticsT = withScope('analyticsCommon:main')

    /* eslint-disable no-console */
    console.log(`load analytics index with language: ${userConfigedLng}`)

    Focus.init(analyticsT)
    Page.init(analyticsT)

    return Object.entries(Focus.all()).reduce<[TFocuses, TPages]>(
      ([_focuses, _pages], [focusKey, _focus]: [FocusKey, Focus]) => {
        const allowedPageKeys = _focus.pages
          .filter(
            (page) =>
              accessibleAnalyticsFeatureInfo.isPageAccessible(page.key) && (enableBetaPageAccess || !page.isBeta),
          )
          .map(({ key }) => key)

        const allowedFormDesignIds = _formDesigns.filter(({ focus }) => focus.key === focusKey).map(({ key }) => key)

        if (!allowedPageKeys.length && !allowedFormDesignIds.length) {
          return [_focuses, _pages]
        }

        const focus = new Focus(focusKey, _focus.title, _focus.description, allowedPageKeys, allowedFormDesignIds)
        const pages = focus.pages.reduce<TPages>(
          (acc, page) => ({
            ...acc,
            [page.key]: page,
          }),
          {},
        )

        return [
          { ..._focuses, [focus.key]: focus },
          { ..._pages, ...pages },
        ]
      },
      [{}, {}],
    )
  }, [withScope, _formDesigns, accessibleAnalyticsFeatureInfo, enableBetaPageAccess, userConfigedLng, isLoaded])

  return (
    <AnalyticsPagesContext.Provider
      value={{
        focuses: filteredFocuses,
        pages: filteredPages,
        formDesigns,
      }}
    >
      {children}
    </AnalyticsPagesContext.Provider>
  )
}

export { AnalyticsPagesProvider, AnalyticsPagesContext }
