import gql from 'graphql-tag'
import { useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import moment from 'moment'
import { useQuery } from 'src/services/apollo'
import * as Uuid from 'src/services/constants/uuid.yml'
import { AnalyticsPermission } from 'src/scenes/Analytics/services/permissions'
import { DocumentType, FocusKey } from './types'
import type { IDocument } from './types'
import { Focus } from './pages'

const LIST_ALL_ANALYTICS_FORM_DESIGNS = gql`
  query ListAllAnalyticsFormDesigns {
    analyticsFormData {
      listFormDesigns {
        id
        name
        description
        category {
          id
          name
        }
        estimation
        numAllFormInstances
        numCompletedFormInstances
        lastIssuedAt
        lastCompletedAt
      }
    }
  }
`

interface IRawAnalyticsForm {
  id: string
  name: string
  description: string | null
  estimation: boolean
  numAllFormInstances: number
  numCompletedFormInstances: number
  lastIssuedAt: string | null
  lastCompletedAt: string | null
  category: {
    id: string
    name: string
  } | null
}

interface IQueryData {
  analyticsFormData?: {
    listFormDesigns?: IRawAnalyticsForm[]
  }
}

interface IFormDesignCategory {
  id: string
  title: string
}

class FormDesign {
  public readonly key: string

  public readonly category: IFormDesignCategory

  public readonly title: string

  public readonly description: string | null

  public readonly isEstimation: boolean

  public readonly numAllFormInstances: number

  public readonly numCompletedFormInstances: number

  public readonly completionRate: number

  public readonly lastIssuedAt: moment.Moment | null

  public readonly lastCompletedAt: moment.Moment | null

  private readonly _focusKey: FocusKey

  public get focus(): Focus {
    const focuses = Focus.all()

    return focuses[this._focusKey]
  }

  public constructor(
    key: string,
    category: IFormDesignCategory,
    title: string,
    description: string | null,
    isEstimation: boolean,
    numAllFormInstances: number,
    numCompletedFormInstances: number,
    lastIssuedAt: string | null,
    lastCompletedAt: string | null,
  ) {
    this.key = key
    this.category = category
    this._focusKey = FocusKey.play
    this.title = title
    this.description = description
    this.isEstimation = isEstimation
    this.numAllFormInstances = numAllFormInstances
    this.numCompletedFormInstances = numCompletedFormInstances
    this.completionRate = numAllFormInstances && numCompletedFormInstances / numAllFormInstances
    this.lastIssuedAt = lastIssuedAt ? moment(lastIssuedAt) : null
    this.lastCompletedAt = lastCompletedAt ? moment(lastCompletedAt) : null
  }

  public toDocument(): IDocument {
    return {
      key: {
        tile: this.key,
        category: this._focusKey,
        type: DocumentType.Form,
      },
      title: this.title,
      categoryTitle: this.category.title,
      keywords: [],
    }
  }
}

const useAnalyticsFormDesigns = (permission: AnalyticsPermission): { loading: boolean; formDesigns: FormDesign[] } => {
  const { loading: loadingFormDesigns, data: { analyticsFormData: { listFormDesigns = [] } = {} } = {} } =
    useQuery<IQueryData>(LIST_ALL_ANALYTICS_FORM_DESIGNS, { errorBehaviour: 'do-nothing', skip: !permission.hasAccess })

  const formDesigns = useMemo(
    () =>
      listFormDesigns.map<FormDesign>(
        ({
          id,
          name,
          description,
          category: _category,
          estimation,
          numAllFormInstances,
          numCompletedFormInstances,
          lastIssuedAt,
          lastCompletedAt,
        }) => {
          let category: IFormDesignCategory = {
            id: Uuid.Nil,
            title: 'Uncategorised',
          }

          if (_category) {
            category = {
              id: _category.id,
              title: _category.name,
            }
          }

          return new FormDesign(
            id,
            category,
            name,
            description,
            estimation,
            numAllFormInstances,
            numCompletedFormInstances,
            lastIssuedAt,
            lastCompletedAt,
          )
        },
      ),
    [listFormDesigns],
  )

  return {
    loading: loadingFormDesigns,
    formDesigns,
  }
}

export { FormDesign, useAnalyticsFormDesigns }
