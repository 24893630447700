import queryString from 'query-string'
import { useMemo } from 'react'
import {
  IRedirectChain,
  isValidUrlParams,
  isValidRedirectChain,
  IRedirectChainInput,
  ISupportedRedirectChainRoute,
} from './types'
import { generateRedirectChainUrl, getLocalStorage } from './helpers'

/**
 * Given a redirect chain this will generate all of the required details and return the first url.
 * This consists of generating a random id that references the redirect chain, storing the redirect
 * chain in local storage using that id, and then returning a url with the correct params representing
 * the first item in the chain.
 * @param redirectChain
 */
const startRedirectChain = (redirectChainInput: IRedirectChainInput) => {
  const chainId = Math.floor(Math.random() * 90000) + 10000
  const index = 0

  const redirectChain: IRedirectChain = {
    ...redirectChainInput,
    chain: redirectChainInput.chain.filter((route): route is ISupportedRedirectChainRoute => !!route),
  }

  const storedRedirectChains = getLocalStorage() ?? {}
  localStorage.setItem('redirect_chains', JSON.stringify({ ...storedRedirectChains, [chainId]: redirectChain }))

  return generateRedirectChainUrl(redirectChain, index, chainId.toString())
}

/**
 * Grabs the redirect chain params out of the url and generates a url from the redirect chain
 * local storage referencing the item after the one that is currently in the
 * redirect_chain_index url param. The new url will have a redirect_chain_index that references
 * the new route.
 *
 * @param fallback The fallback route to redirect to if not on a redirect chain.
 * @param skipCurrentRoute Removes the current route from the redirect chain after generating the URL.
 *                         This allows pages which determine they need to be skipped in the count to do so.
 */
const getNextRedirectAndIncrement = ({
  fallback,
  skipCurrentRoute = false,
}: {
  fallback: string
  skipCurrentRoute?: boolean
}) => {
  const queryParams = queryString.parse(location.search)
  if (!isValidUrlParams(queryParams)) {
    return fallback
  }

  const chainId = queryParams.redirectChainId
  const currentIndex = Number(queryParams.redirectChainIndex)
  const storedRedirectChains = getLocalStorage()
  const currentChain = storedRedirectChains?.[chainId]
  if (!currentChain || !isValidRedirectChain(currentChain)) {
    return fallback
  }

  if (skipCurrentRoute) {
    // Make a new chain without the skipped route.
    const newChainId = Math.floor(Math.random() * 90000) + 10000
    const newChain: IRedirectChain = {
      ...currentChain,
      chain: currentChain.chain.filter((route, index) => index !== currentIndex),
    }
    localStorage.setItem('redirect_chains', JSON.stringify({ ...storedRedirectChains, [newChainId]: newChain }))

    return generateRedirectChainUrl(newChain, currentIndex, newChainId.toString()) ?? fallback
  }

  return generateRedirectChainUrl(currentChain, currentIndex + 1, chainId) ?? fallback
}

/**
 * gets general details relating to the redirect chain such as the url params and
 * the actual chain that is stored in local storage.
 */
const getCurrentRedirectChainDetails = () => {
  const queryParams = queryString.parse(location.search)
  if (!isValidUrlParams(queryParams)) {
    return null
  }

  const chains = getLocalStorage()
  const currentChain = chains?.[queryParams.redirectChainId]
  if (!chains || !isValidRedirectChain(currentChain)) {
    return null
  }

  return {
    id: queryParams.redirectChainId,
    currentIndex: Number(queryParams.redirectChainIndex),
    chainDetails: currentChain,
  }
}

const useCurrentRedirectChainDetails = () => {
  return useMemo(getCurrentRedirectChainDetails, [])
}

export {
  useCurrentRedirectChainDetails,
  getCurrentRedirectChainDetails,
  getNextRedirectAndIncrement,
  startRedirectChain,
}
