import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type {
  GetSuperApiEmbedUrlForOnboardingSession,
  GetSuperApiEmbedUrlForOnboardingSessionVariables,
} from './__gql__/gqlCodeGen'

const GET_SUPER_API_EMBED_FOR_ONBOARDING_SESSION = gql`
  query GetSuperApiEmbedUrlForOnboardingSession($onboardingSessionId: ID!) {
    getSuperApiEmbedUrlForOnboardingSession(onboardingSessionId: $onboardingSessionId)
  }
`

const useGetSuperApiEmbedUrlForOnboardingSession = (onboardingSessionId: string) => {
  return useQuery<GetSuperApiEmbedUrlForOnboardingSession, GetSuperApiEmbedUrlForOnboardingSessionVariables>(
    GET_SUPER_API_EMBED_FOR_ONBOARDING_SESSION,
    {
      errorBehaviour: 'do-nothing',
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: {
        onboardingSessionId,
      },
    },
  )
}

export { useGetSuperApiEmbedUrlForOnboardingSession }
