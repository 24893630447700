import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import { createContext, useMemo } from 'react'
import { GetAccessibleAnalyticsFeatureInfo } from './__gql__/AnalyticsPlanModuleCodeGen'
import { PageKey } from './types'

const GET_ACCESSIBLE_ANALYTICS_FEATURE_INFO_QUERY = gql`
  query GetAccessibleAnalyticsFeatureInfo {
    analyticsDomain {
      getAccessibleAnalyticsFeatureInfo {
        pages {
          key
          code
          isStandardPage
          isPermitted
        }
        individualFormDataAnalysis
      }
    }
  }
`

const useAccessibleAnalyticsFeatureInfo = () => {
  const {
    loading,
    data: { analyticsDomain: { getAccessibleAnalyticsFeatureInfo: rawAnalyticsFeatureInfo } } = {
      analyticsDomain: {
        getAccessibleAnalyticsFeatureInfo: null,
      },
    },
  } = useQuery<GetAccessibleAnalyticsFeatureInfo>(GET_ACCESSIBLE_ANALYTICS_FEATURE_INFO_QUERY, {
    errorBehaviour: 'do-nothing',
  })

  const accessibleAnalyticsFeatureInfo = useMemo(() => {
    if (!rawAnalyticsFeatureInfo) {
      return NULL_ACCESSIBLE_ANALYTICS_FEATURE_INFO
    }
    return new AccessibleAnalyticsFeatureInfo(rawAnalyticsFeatureInfo)
  }, [rawAnalyticsFeatureInfo])

  return {
    loading,
    accessibleAnalyticsFeatureInfo,
  }
}

interface IAccessibleAnalyticsFeatureInfo {
  isPageAccessible: (page: PageKey) => boolean
}

type TRawAccessibleAnalyticsFeatureInfo = NonNullable<
  GetAccessibleAnalyticsFeatureInfo['analyticsDomain']['getAccessibleAnalyticsFeatureInfo']
>

type TAccessiblePageInfo = TRawAccessibleAnalyticsFeatureInfo['pages'][number]

class AccessibleAnalyticsFeatureInfo implements IAccessibleAnalyticsFeatureInfo {
  private readonly base: TRawAccessibleAnalyticsFeatureInfo

  private readonly pageIdx: Map<PageKey, TAccessiblePageInfo>

  constructor(rawFeatureInfo: TRawAccessibleAnalyticsFeatureInfo) {
    this.base = rawFeatureInfo
    this.pageIdx = this.base.pages.reduce((agg, page) => {
      const pageKey = PageKey[page.key]
      if (pageKey) {
        agg.set(pageKey, page)
      }
      return agg
    }, new Map<PageKey, TAccessiblePageInfo>())
  }

  public isPageAccessible(page: PageKey) {
    const pageInfo = this.pageIdx.get(page)

    return !!(pageInfo && pageInfo.isPermitted)
  }
}

const NULL_ACCESSIBLE_ANALYTICS_FEATURE_INFO: IAccessibleAnalyticsFeatureInfo = {
  isPageAccessible: () => false,
}

const AccessibleAnalyticsFeatureInfoContext = createContext<IAccessibleAnalyticsFeatureInfo>(
  NULL_ACCESSIBLE_ANALYTICS_FEATURE_INFO,
)

export { useAccessibleAnalyticsFeatureInfo, AccessibleAnalyticsFeatureInfoContext }
