import React from 'react'
import { Variables, UnstyledLink, AvatarEntity, DropdownMenu, GridLayout } from '@intellihr/ui-components'
import { isEqual } from 'lodash'
import { IAvatarEntityProps } from '@intellihr/ui-components/types/domain/Avatars/AvatarEntity/AvatarEntity'
import { FilterableAvatarEntityList } from './subcomponents/FilterableAvatarEntityList'
import { StyledPeopleButton } from './style'
import { ILinkableAvatarEntityProps } from './types'

interface IAvatarEntityGroupProps {
  avatarEntities: Array<
    IAvatarEntityProps & {
      href: string
    }
  >
  maxDisplayedEntities?: number
  componentContext?: string
  filterPlaceholder: string
  collapsedEntityPrimaryText: string
}

const LinkableAvatarEntity: React.FC<{ avatarEntity: ILinkableAvatarEntityProps }> = ({ avatarEntity }) => {
  const { href, ...spreadProps } = avatarEntity

  return (
    <UnstyledLink href={href}>
      <AvatarEntity
        {...spreadProps}
        isHoverable
      />
    </UnstyledLink>
  )
}

const CollapsedEntityButton: React.FC<{
  collapsedCount: number
  toggleMenu: () => void
  toggleComponentRef: React.RefObject<HTMLDivElement>
  ariaProps: object
  collapsedEntityPrimaryText: string
}> = ({ ariaProps, collapsedCount, toggleComponentRef, collapsedEntityPrimaryText, toggleMenu }) => {
  return (
    <StyledPeopleButton
      onClick={toggleMenu}
      ref={toggleComponentRef}
      {...ariaProps}
    >
      <AvatarEntity
        initials={`+${collapsedCount}`}
        primaryText={collapsedEntityPrimaryText}
        isHoverable
      />
    </StyledPeopleButton>
  )
}

const DropdownContent: React.FC<{ entities: ILinkableAvatarEntityProps[]; filterPlaceholder: string }> = ({
  filterPlaceholder,
  entities,
}) => {
  return (
    <FilterableAvatarEntityList
      avatarEntityList={entities}
      filterPlaceholder={filterPlaceholder}
    />
  )
}

const EntityList: React.FC<IAvatarEntityGroupProps> = ({
  maxDisplayedEntities = 8,
  avatarEntities,
  componentContext,
  filterPlaceholder,
  collapsedEntityPrimaryText,
}) => {
  let displayedEntities = avatarEntities

  if (avatarEntities.length > maxDisplayedEntities) {
    displayedEntities = avatarEntities.slice(0, maxDisplayedEntities - 1)
  }
  const formattedEntities = displayedEntities.map((avatarEntity, index) => {
    return {
      size: {
        tablet: displayedEntities.length > 1 ? 6 : ('fullWidth' as const),
        min: 'fullWidth' as const,
      },
      key: index.toString(),
      content: <LinkableAvatarEntity avatarEntity={avatarEntity} />,
    }
  })

  if (avatarEntities.length > maxDisplayedEntities) {
    formattedEntities.push({
      size: { tablet: 6, min: 'fullWidth' as const },
      key: 'collapsedDirectReports',
      content: (
        <DropdownMenu
          hasInitialFocus
          toggleComponent={(props) => {
            return (
              <CollapsedEntityButton
                {...props}
                collapsedCount={(avatarEntities?.length ?? 0) - (maxDisplayedEntities - 1)}
                collapsedEntityPrimaryText={collapsedEntityPrimaryText}
              />
            )
          }}
        >
          {() => (
            <DropdownContent
              entities={avatarEntities}
              filterPlaceholder={filterPlaceholder}
            />
          )}
        </DropdownMenu>
      ),
    })
  }

  return (
    <GridLayout
      componentContext={componentContext}
      gutterMarginY={{ desktop: Variables.Spacing.sLarge, min: Variables.Spacing.sSmall }}
      cells={formattedEntities}
      margins={{ top: Variables.Layout.l2XSmall, bottom: Variables.Layout.l2XSmall }}
    />
  )
}

const MemoEntityList = React.memo(
  EntityList,
  (prevProps, nextProps) =>
    nextProps.filterPlaceholder === prevProps.filterPlaceholder &&
    nextProps.collapsedEntityPrimaryText === prevProps.collapsedEntityPrimaryText &&
    nextProps.maxDisplayedEntities === prevProps.maxDisplayedEntities &&
    nextProps.componentContext === prevProps.componentContext &&
    isEqual(nextProps.avatarEntities, prevProps.avatarEntities),
)

export { MemoEntityList as EntityList }
