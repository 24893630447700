import { SelectInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import React from 'react'
import { useQuery } from 'src/services/apollo'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'
import { ListAllCountries } from './__gql__/CountrySelectInputCodeGen'

const LIST_ALL_COUNTRIES = gql`
  query ListAllCountries {
    listAllCountries(orderBy: [{ field: WORK_RIGHT_USAGE, order: DESC }]) {
      id
      name
    }
  }
`

const CountrySelectInput: React.FC<ISelectInputProps> = (props) => {
  const { data } = useQuery<ListAllCountries>(LIST_ALL_COUNTRIES, { errorBehaviour: 'do-nothing' })
  const options =
    data?.listAllCountries.map((country) => ({
      label: country.name,
      value: country.id,
    })) ?? []

  return (
    <SelectInput
      {...props}
      isFetching={!data?.listAllCountries}
      options={options}
    />
  )
}

export { LIST_ALL_COUNTRIES, CountrySelectInput }
