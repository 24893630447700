import gql from 'graphql-tag'

const listCustomFieldDefinitionsFragment = gql`
  fragment AllCustomFieldDefinitions on Query {
    listAllCustomFieldDefinitions(isEnabled: true) {
      id
      name
      modelType
      type
      apiName
      description
      isSensitive
      isMandatory
      customFieldCategoryNullable {
        id
        name
      }
      definition {
        ... on CustomFieldDefinitionSelectTypeDefinition {
          options(isEnabled: true) {
            id
            label
          }
        }
        ... on CustomFieldDefinitionPeopleDropdownTypeDefinition {
          allowSelectingIndividualJobs
        }
        ... on CustomFieldDefinitionTextTypeDefinition {
          regex
          validationError
        }
      }
    }
  }
`

export { listCustomFieldDefinitionsFragment }
