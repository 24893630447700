import { Props, VerticalForm } from '@intellihr/ui-components'
import { IHintWrapperProps } from '@intellihr/ui-components/types/domain/Formats/HintWrapper'
import { ITooltipPopoverProps } from '@intellihr/ui-components/types/domain/Popovers'
import { isNil } from 'lodash'
import React from 'react'
import { BaseFieldProps, GenericFieldHTMLAttributes, WrappedFieldProps } from 'redux-form'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IReduxVerticalFormFieldProps<InputProps = { [i: string]: any }> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  label?: string
  description?: string
  isRequired?: boolean
  inputProps?: InputProps
  actionMessage?: JSX.Element
  tooltipMessage?: JSX.Element | string
  tooltipProps?: ITooltipPopoverProps

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  margins?: Props.IMargins
  hintOptions?: {
    hint: JSX.Element | string
    label: string
    hintWrapperProps?: Partial<IHintWrapperProps>
  }
  componentContext?: string
}

type IReduxVerticalFormFieldParam = GenericFieldHTMLAttributes | BaseFieldProps<IReduxVerticalFormFieldProps>

class ReduxVerticalFormField extends React.Component<WrappedFieldProps & IReduxVerticalFormFieldProps> {
  public static defaultProps: Partial<WrappedFieldProps & IReduxVerticalFormFieldProps> = {
    isRequired: false,
  }

  public render(): JSX.Element {
    const {
      input,
      meta,
      component: InputComponent,
      isRequired,
      label,
      inputProps,
      description,
      actionMessage,
      tooltipMessage,
      tooltipProps,
      hintOptions,
      margins,
      componentContext,
    } = this.props

    return (
      <VerticalForm.Field
        hintOptions={hintOptions}
        tooltipMessage={tooltipMessage}
        tooltipProps={tooltipProps}
        label={label}
        description={description}
        inputName={input.name}
        actionMessage={actionMessage}
        errorMessages={meta.touched && meta.error}
        isRequired={isRequired}
        componentContext={componentContext}
        margins={margins}
      >
        <InputComponent
          aria-label={label}
          {...input}
          {...inputProps}
          handleBlur={input.onBlur}
          isInvalid={meta.touched && !isNil(meta.error)}
        />
      </VerticalForm.Field>
    )
  }
}

export type { IReduxVerticalFormFieldParam }
export { ReduxVerticalFormField }
