import styled from 'styled-components'

const ShowForPrint = styled.div`
  display: none;

  @media print {
    display: block;
  }
`

export { ShowForPrint }
