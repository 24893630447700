import React, { useCallback, useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  ButtonLink,
  DataTable,
  Inline,
  Spinner,
  Stack,
  TDataTableColumn,
  TextStyle,
} from '@intellihr/blueberry'
import { Date } from 'src/services/uiComponentCandidates/Date'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { PersonDocument, PersonDocumentUploadStatus } from 'src/__gql__/globalTypes'
import axios from 'axios'
import { useToasts } from 'src/services/toasts'

interface ICreateQualificationAttachmentParameters {
  variables: {
    qualificationInstanceId: string
    filename: string
    mime: string
    size: number
    extension: string
  }
  refetchQueries?: string[]
}

interface IUpdateQualificationAttachmentParameters {
  variables: {
    documentId: string
    displayName: string
    uploadStatus: PersonDocumentUploadStatus
    folderId: string
  }
  refetchQueries?: string[]
}

interface IDeleteQualificationAttachmentParameters {
  variables: {
    id: string
  }
  refetchQueries?: string[]
}

interface IQualificationInstanceAttachmentProps {
  qualificationInstanceId: string
  attachments: PersonDocument[]
  setAttachments: (attachments: PersonDocument[]) => void
  createQualificationAttachment: (
    input: ICreateQualificationAttachmentParameters,
  ) => Promise<{ document: PersonDocument; presignedUploadUrl: string } | undefined>
  updateQualificationAttachment: (input: IUpdateQualificationAttachmentParameters) => Promise<void>
  deleteQualificationAttachment: (input: IDeleteQualificationAttachmentParameters) => Promise<void>
  isAttachmentLoading?: boolean
}

const QualificationInstanceAttachment: React.FC<IQualificationInstanceAttachmentProps> = ({
  qualificationInstanceId,
  attachments,
  setAttachments,
  createQualificationAttachment,
  updateQualificationAttachment,
  deleteQualificationAttachment,
  isAttachmentLoading = false,
}) => {
  const tFeature = useScope('qualifications:components.QualificationInstancePage')
  const tCommon = useScope('common:components')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const postToast = useToasts()
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const handleAttachmentDelete = (id: string) => {
    deleteQualificationAttachment({
      variables: { id },
      refetchQueries: ['GetOnboardingFormInstanceAndPerson'],
    }).then(() => {
      // Refetching doesn't rerender the component for some reason
      // Removing the attachment here should be fast enough and would save rerendering a lot of the tree
      setAttachments(attachments.filter((value) => value.id !== id))
    })
  }

  const handleQualificationAttachmentUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]
        setIsUploading(true)

        try {
          const response = await createQualificationAttachment({
            variables: {
              qualificationInstanceId,
              filename: file.name,
              mime: file.type && file.type !== '' ? file.type : 'application/octet-stream',
              size: file.size,
              extension: file.name.split('.').pop() || '',
            },
          })

          if (response) {
            const { document, presignedUploadUrl } = response

            const uploadresponse = await axios.put(presignedUploadUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
            })

            if (uploadresponse.status !== 200) {
              throw new Error('Failed to upload file')
            }

            await updateQualificationAttachment({
              variables: {
                documentId: document.id,
                uploadStatus: PersonDocumentUploadStatus.SUCCESS,
                displayName: document.displayName,
                folderId: document.id,
              },
              refetchQueries: ['GetOnboardingFormInstanceAndPerson'],
            })

            const singleDocument = {
              ...document,
              __typename: 'PersonDocument' as const,
            }

            const updatedAttachments = [...attachments]
            updatedAttachments.push(singleDocument)

            setAttachments(updatedAttachments)
          }
        } catch (error) {
          postToast({
            content: tCommon('uploadFile.errorMessage', {
              defaultValue: 'There was an error uploading the file',
            }),
            type: 'alert',
          })
        }

        setIsUploading(false)
      }
    },
    [
      postToast,
      qualificationInstanceId,
      tCommon,
      attachments,
      setAttachments,
      createQualificationAttachment,
      updateQualificationAttachment,
    ],
  )

  const onButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const columns: Array<TDataTableColumn<PersonDocument>> = [
    {
      accessorKey: 'displayName',
      header: `${tFeature('input.file')}`,
    },
    {
      accessorKey: 'createdAtDateTime',
      header: `${tFeature('input.dateAdded')}`,
      cell: ({ row }) => {
        return (
          <Date
            date={row.original.createdAtDateTime}
            fallback=""
            showTime={true}
          />
        )
      },
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: ({ row }) => {
        return (
          <ButtonGroup>
            <ButtonLink
              variant="tertiary"
              size="small"
              href={row.original.downloadUrlAbsolute}
              download
            >
              {tCommon('buttons.view')}
            </ButtonLink>
            <Button
              variant="destructive"
              size="small"
              onClick={() => {
                handleAttachmentDelete(row.original.id)
              }}
            >
              {tCommon('buttons.delete')}
            </Button>
          </ButtonGroup>
        )
      },
    },
  ]

  if (isUploading || isAttachmentLoading) {
    return <Spinner testId="qualifications-modal-attachment-delete-spinner" />
  }

  return (
    <Stack>
      <Inline alignY="center">
        <TextStyle variant="strong">{tFeature('input.attachments')}</TextStyle>
        <div>
          <input
            type="file"
            ref={fileInputRef}
            data-cy="qualification-instance-attachment"
            style={{ display: 'none' }}
            onChange={handleQualificationAttachmentUpload}
          />
          <Button
            size="small"
            variant="secondary"
            onClick={onButtonClick}
          >
            {tCommon('uploadFile.uploadFile')}
          </Button>
        </div>
      </Inline>
      <DataTable
        columns={columns}
        data={attachments}
      />
    </Stack>
  )
}

export type {
  ICreateQualificationAttachmentParameters,
  IUpdateQualificationAttachmentParameters,
  IDeleteQualificationAttachmentParameters,
}
export { QualificationInstanceAttachment }
