import React, { useState, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Container, HoverCard } from './style'

interface IHoverableProps {
  children: (hovered: boolean) => React.ReactNode
  renderCardContent: () => React.ReactNode
  width?: number
}

const getParentBoundingRect = (parentRef: React.RefObject<HTMLDivElement>) => {
  if (!parentRef.current) {
    return { left: 0, top: 0, width: 0, height: 0 }
  }

  return parentRef.current.getBoundingClientRect()
}

const Hoverable: React.FC<IHoverableProps> = ({ children, renderCardContent, width = 275 }) => {
  const [enteringTimeoutId, setEnteringTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const [leavingTimeoutId, setLeavingTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const [hovered, setHovered] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const parentBoundingRect = getParentBoundingRect(parentRef)
  const parentInTopSideOfWindow = useMemo(() => {
    const parentYCenter = parentBoundingRect.top + parentBoundingRect.height / 2
    return parentYCenter < window.innerHeight * (2 / 3)
  }, [parentBoundingRect.height, parentBoundingRect.top])
  const parentAnchorOffset = useMemo(() => {
    return {
      x: parentBoundingRect.left + window.scrollX,
      y: parentBoundingRect.top + window.scrollY,
    }
  }, [parentBoundingRect])

  return (
    <Container
      ref={parentRef}
      onMouseEnter={() => {
        setHovered(true)
        if (leavingTimeoutId !== null) {
          clearTimeout(leavingTimeoutId)

          setLeavingTimeoutId(null)
        }

        if (openPopover) {
          return
        }

        setEnteringTimeoutId(
          setTimeout(() => {
            setOpenPopover(true)
          }, 400),
        )
      }}
      onMouseLeave={() => {
        if (enteringTimeoutId !== null) {
          clearTimeout(enteringTimeoutId)
          setHovered(false)

          setEnteringTimeoutId(null)
        }

        if (!openPopover) {
          setHovered(false)
          return
        }

        setLeavingTimeoutId(
          setTimeout(() => {
            setHovered(false)
            setOpenPopover(false)
          }, 200),
        )
      }}
    >
      {children(hovered)}
      {openPopover &&
        ReactDOM.createPortal(
          <HoverCard
            width={width}
            arrowDirection={parentInTopSideOfWindow ? 'TOP' : 'BOTTOM'}
            style={{
              left: parentBoundingRect.width / 2 + parentAnchorOffset.x,
              bottom: parentInTopSideOfWindow
                ? undefined
                : document.documentElement.clientHeight - parentAnchorOffset.y,
              top: parentInTopSideOfWindow ? parentAnchorOffset.y + parentBoundingRect.height + 11 : undefined,
            }}
          >
            {renderCardContent()}
          </HoverCard>,
          document.body,
        )}
    </Container>
  )
}

export { Hoverable }
