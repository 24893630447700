import { get } from 'lodash'

interface ITransformedSelectInputOptions {
  label: string
  value: string | undefined
}

const transformSelectInputOptions = <T>(
  responseData: T[],
  paths: { label: keyof T; value: keyof T },
): ITransformedSelectInputOptions[] => {
  const options = []

  for (const data of responseData) {
    options.push({
      label: get(data, paths.label, 'Unknown Label (Invalid Path)') as string,
      value: get(data, paths.value, undefined) as string | undefined,
    })
  }

  return options
}

export type { ITransformedSelectInputOptions }
export { transformSelectInputOptions }
