import { isEmpty } from 'lodash'
import { History } from 'history'
import * as Sentry from '@sentry/react'
import { ApolloError } from '@apollo/client'

const shouldUseSentry = (): boolean => {
  try {
    return !isEmpty(process.env.REACT_APP_SENTRY_DSN) && process.env.REACT_APP_SENTRY_DSN !== 'null' // could be string value null in SSM
  } catch (e) {
    return false
  }
}

const setupSentry = (history: History) => {
  if (shouldUseSentry()) {
    // Get the hostname from the full Graphite URL or set the origin to the Sentry default
    const gqlDomain =
      process.env.REACT_APP_FEDERATION_GRAPHQL_ENDPOINT?.replace(/^https?:\/\//i, '')?.split('/')[0] || ''

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
      tracePropagationTargets: [gqlDomain],
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_COMMIT_SHA,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      sampleRate: Number(process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE),
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
      beforeSend: (event, hint) => {
        // Don't log apollo graphql errors
        if (
          hint?.originalException &&
          hint.originalException instanceof ApolloError &&
          hint.originalException.graphQLErrors.length > 0
        ) {
          return null
        }

        return event
      },
    })
  }
}

const getSentry = () => {
  if (shouldUseSentry()) {
    return Sentry
  }

  return null
}

export { setupSentry, getSentry }
