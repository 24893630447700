import { gql, useQuery } from '@apollo/client'
import type { GetSupportedLanguages } from './__gql__/indexCodeGen'

type ILanguage = GetSupportedLanguages['listSupportedLanguages'][number]

const GET_SUPPORTED_LANGUAGES = gql`
  query GetSupportedLanguages {
    listSupportedLanguages {
      slug
      localisedName
      englishName
      nativeName
    }
  }
`

const useGetSupportedLanguagesQuery = () => useQuery<GetSupportedLanguages>(GET_SUPPORTED_LANGUAGES)

export type { ILanguage }
export { GET_SUPPORTED_LANGUAGES, useGetSupportedLanguagesQuery, GetSupportedLanguages }
