import { Variables } from '@intellihr/ui-components'
import styled from 'styled-components'

const StyledTurnBackWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 3rem ${Variables.Spacing.sLarge}px;
`

const StyledTurnBackUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Variables.Spacing.sXSmall}px;
  margin-bottom: 0;
`

export { StyledTurnBackWrapper, StyledTurnBackUl }
