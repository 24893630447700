import React, { useMemo, useCallback, useState, useEffect } from 'react'
import * as configCatJS from 'configcat-js'
import { cloudLogger } from 'src/services/error'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { SettingValue } from 'configcat-js'
import { SettingTypeOf } from 'configcat-common/lib/RolloutEvaluator'

type ConfigCat = ReturnType<typeof configCatJS.createClient>

interface IConfigCatContextValue {
  configCatClient: ConfigCat | undefined
}

interface IConfigCat {
  getConfigCatValue: <V extends SettingValue>(key: string, defaultValue: V, callback: (value: V) => void) => void
}

interface IUseConfigCatWithKeyState<TValue> {
  value: TValue
  loading: boolean
}

interface IConfigCatConsumerProps<V> {
  configCatKey: string
  defaultValue: V
  children: (value: V) => React.ReactElement
}

interface IConfigCatConsumerState<V> {
  loading: boolean
  value: V
}

let configCat: ConfigCat
const ConfigCatContext = React.createContext<IConfigCatContextValue>({ configCatClient: undefined })
const ConfigCatProvider = ConfigCatContext.Provider

const getConfigCat = (): ConfigCat | undefined => {
  if (!configCat && process.env.REACT_APP_CONFIGCAT_KEY) {
    const CONFIG_CAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY
    configCat = configCatJS.getClient(CONFIG_CAT_KEY, configCatJS.PollingMode.LazyLoad, {
      cacheTimeToLiveSeconds: 300,
    })
  }

  return configCat
}

const getConfigCatUser = () => ({
  identifier: getSession().userId,
  custom: {
    Tenant: getSession().tenant.replace(/_/g, '-'),
    'Base URL': window.location.origin,
  },
})

const getConfigCatValueSafely = async <V extends SettingValue>(
  configCatClient: ConfigCat | undefined,
  values: { key: string; defaultValue: V; user: ReturnType<typeof getConfigCatUser> },
): Promise<SettingTypeOf<V> | V> => {
  if (configCatClient !== undefined) {
    try {
      return configCatClient.getValueAsync(values.key, values.defaultValue, values.user)
    } catch (e) {
      if (e instanceof Error) {
        cloudLogger.error(e, {
          userImpact: 'Unable to load configcat - Possible incorrect configuration shown',
          meta: { values },
        })
      }

      return Promise.resolve(values.defaultValue)
    }
  }

  return Promise.resolve(values.defaultValue)
}

// this is for use in cases where it is not a react component
const getConfigCatValueAsync = async <V extends SettingValue>(
  key: string,
  defaultValue: V,
): Promise<SettingTypeOf<V> | V> => {
  return getConfigCatValueSafely(getConfigCat(), { key, defaultValue, user: getConfigCatUser() })
}

const useConfigCat = (): IConfigCat => {
  const user = useMemo(getConfigCatUser, [])
  const { configCatClient } = React.useContext(ConfigCatContext)

  const getConfigCatValue = useCallback(
    async (key: string, defaultValue: SettingValue, callback: CallableFunction) => {
      callback(await getConfigCatValueSafely(configCatClient, { key, defaultValue, user }))
    },
    [configCatClient, user],
  )

  return {
    getConfigCatValue,
  }
}

// This is to replace useConfigCat
const useConfigCatWithKey = <TValue extends SettingValue = boolean>(
  configCatKey: string,
  defaultValue: SettingTypeOf<TValue>,
) => {
  const { getConfigCatValue } = useConfigCat()
  const [state, setState] = useState<IUseConfigCatWithKeyState<SettingTypeOf<TValue>>>({
    value: defaultValue,
    loading: true,
  })

  useEffect(() => {
    getConfigCatValue(configCatKey, defaultValue, (value) => {
      setState({ value, loading: false })
    })
  }, [getConfigCatValue, configCatKey, defaultValue])

  return state
}

const useFeatureFlag = (featureFlagKey: string) => useConfigCatWithKey(featureFlagKey, false)

const ConfigCatConsumer = <T extends SettingValue>({
  configCatKey,
  defaultValue,
  children,
}: IConfigCatConsumerProps<T>): React.ReactElement | null => {
  const { getConfigCatValue } = useConfigCat()
  const [state, setState] = useState<IConfigCatConsumerState<T>>({ value: defaultValue, loading: true })

  useEffect(() => {
    getConfigCatValue(configCatKey, defaultValue, (value) => {
      setState({
        loading: false,
        value,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state.loading) {
    return null
  }

  return children(state.value)
}

export type { IConfigCatContextValue }
export {
  ConfigCatProvider,
  ConfigCatConsumer,
  getConfigCat,
  useConfigCat,
  useConfigCatWithKey,
  useFeatureFlag,
  getConfigCatValueAsync,
}
