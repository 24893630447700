import gql from 'graphql-tag'
import { useQuery } from 'src/services/apollo'
import type {
  GetValuesForDropdownSearch,
  GetValuesForDropdownSearchVariables,
  PerformPeopleDropdownSearch,
  PerformPeopleDropdownSearchVariables,
  PersonForPersonDropdownFragment as IPerson,
} from './__gql__/gqlCodeGen'

type IPeopleSearchJob = NonNullable<PerformPeopleDropdownSearch['performPeopleDropdownSearch']['results'][0]['job']>
type IBusinessUnit = NonNullable<
  PerformPeopleDropdownSearch['performPeopleDropdownSearch']['results'][0]['businessUnit']
>
type IPeopleSearchOtherJobs = NonNullable<
  PerformPeopleDropdownSearch['performPeopleDropdownSearch']['results'][0]['otherJobs'][0]
>

const jobForPersonDropdownFragment = gql`
  fragment JobForPersonDropdownFragment on Job {
    id
    isOnExtendedLeave
    jobName {
      id
      name
    }
    jobBusinessEntity {
      id
      businessEntity {
        id
        name
        country {
          id
        }
      }
    }
    jobLocation {
      id
      location {
        id
        name
      }
    }
  }
`

const personForPersonDropdownFragment = gql`
  ${jobForPersonDropdownFragment}

  fragment PersonForPersonDropdownFragment on Person {
    id
    displayName
    initials
    employmentStatusId
    profilePictureUrl(size: SMALL)
    primaryJob {
      ...JobForPersonDropdownFragment
    }
  }
`

const PERFORM_PEOPLE_DROPDOWN_SEARCH = gql`
  ${personForPersonDropdownFragment}
  ${jobForPersonDropdownFragment}

  query PerformPeopleDropdownSearch(
    $searchText: String
    $showPastStaff: Boolean
    $showNoJobRecords: Boolean
    $showIndividualJobs: Boolean
    $hierarchicalPermissionsFilter: [String!]
    $validJobIds: [String!]
    $hasUserAccount: Boolean
  ) {
    performPeopleDropdownSearch(
      searchText: $searchText
      showPastStaff: $showPastStaff
      showNoJobRecords: $showNoJobRecords
      showIndividualJobs: $showIndividualJobs
      hierarchicalPermissionsFilter: $hierarchicalPermissionsFilter
      validJobIds: $validJobIds
      hasUserAccount: $hasUserAccount
    ) {
      noResultsReason
      results {
        person {
          ...PersonForPersonDropdownFragment
        }
        job {
          ...JobForPersonDropdownFragment
        }
        businessUnit {
          id
          name
        }
        otherJobs {
          ...JobForPersonDropdownFragment
        }
      }
    }
  }
`

const GET_PEOPLE_FOR_DROPDOWN_SEARCH = gql`
  ${personForPersonDropdownFragment}
  query GetValuesForDropdownSearch($peopleIds: [ID!]!, $jobIds: [ID!]!, $businessUnitIds: [ID!]!) {
    getBusinessUnits(ids: $businessUnitIds) {
      id
      name
    }
    getPeople(ids: $peopleIds) {
      ...PersonForPersonDropdownFragment
    }
    getJobs(ids: $jobIds) {
      ...JobForPersonDropdownFragment
    }
  }
`

const usePerformPeopleDropdownSearch = (variables: PerformPeopleDropdownSearchVariables, skip: boolean) => {
  return useQuery<PerformPeopleDropdownSearch, PerformPeopleDropdownSearchVariables>(PERFORM_PEOPLE_DROPDOWN_SEARCH, {
    errorBehaviour: 'do-nothing',
    variables,
    skip,
  })
}

const useGetValuesForDropdownSearch = (variables: GetValuesForDropdownSearchVariables) => {
  return useQuery<GetValuesForDropdownSearch, GetValuesForDropdownSearchVariables>(GET_PEOPLE_FOR_DROPDOWN_SEARCH, {
    errorBehaviour: 'do-nothing',
    variables,
  })
}

export type { IPeopleSearchJob, IBusinessUnit, IPeopleSearchOtherJobs, IPerson }
export {
  usePerformPeopleDropdownSearch,
  useGetValuesForDropdownSearch,
  GET_PEOPLE_FOR_DROPDOWN_SEARCH,
  PERFORM_PEOPLE_DROPDOWN_SEARCH,
}
