import React from 'react'
import { FontAwesomeIcon, Variables, Text } from '@intellihr/ui-components'
import { useScope } from 'src/services/i18n/LocalizationProvider'

const SuccessContentOverride: React.FC = () => {
  const t = useScope('common:components.DocumentTable')
  return (
    <div>
      <Text
        color={Variables.Color.i400}
        margins={{ right: Variables.Spacing.sXSmall }}
      >
        {t('successOverrideText', { defaultValue: 'Success' })}
      </Text>
      <FontAwesomeIcon
        type="regular"
        icon="check"
        color={Variables.Color.i400}
      />
    </div>
  )
}

export { SuccessContentOverride }
