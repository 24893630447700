import React, { useState, useCallback, useMemo, useRef, useEffect, MutableRefObject } from 'react'
import ReactSelect, { Option, OnChangeHandler } from 'react-select'
import { isEqual } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { getColorForEmploymentStatus } from 'src/domain/EDM/public/services/personEmploymentStatusService'
import { PeopleDropdownStyleWrapper } from './style'
import {
  IBusinessUnitOption,
  IPeopleAndBusinessUnitOption,
  IPeopleDropdownOption,
  IPeopleDropdownValue,
  IPerson,
  IPersonOption,
  isBusinessUnitValue,
  isPeopleJobsValue,
} from './types'
import { ValueComponent } from './subcomponents/ValueComponent'
import { InputComponent } from './subcomponents/InputComponent'
import { OptionComponent } from './subcomponents/OptionComponent'
import { IPeopleSearchJob, useGetValuesForDropdownSearch } from './gql'
import { usePeopleSearch } from './hooks'
import { formatOptionForOnChange } from './helpers'

interface IPeopleDropdownInputProps {
  /** Whether the dropdown should close after people are selected (by default, it will remain open) */
  closeOnSelect?: boolean
  /** Optional prop that will filter the results by the given permissions */
  hierarchicalPermissionsFilter?: string[]
  /** Whether the input should show as disabled */
  isDisabled?: boolean
  /** if the field has failed form validation */
  isInvalid?: boolean
  /** If the field should  selecting one value */
  /** The name of the input in the form */
  name: string
  /** Fired when the value is updated (when items are selected) */
  onChange?: (value: IPeopleDropdownValue[] | null) => void
  /** Replaces the default placeholder text */
  placeholder?: string
  /**
   *  Sets if you can only select the person/job in the people dropdown or person/job and business unit.
   *  If 'people_jobs_only' is set, the value array will contain only { personId: string, jobId?: string } objects.
   *  If ‘people_jobs_business_units’ is set, the value array can also contain { businessUnitId: string } objects.
   *  */
  selectableValues?: 'people_jobs_only' | 'people_jobs_business_units'
  /** When false, don't show the job count for selected items (defaults to true) */
  showJobCountForSelectedItems?: boolean
  /**
   * Whether we should show separate records for each job in the select.
   * When true -> jobId in the value will have the job ids for records selected
   * When false -> jobId will be null, and selecting a person is assumed to
   * select their primary job
   */
  showIndividualJobs: boolean
  /** Whether it should show people without job records. defaults to true. */
  showNoJobRecords?: boolean
  /** Current value of the input */
  value?: IPeopleDropdownValue[]
  /** Component Context */
  componentContext?: string
  /** Optional prop that will filter the results to only include the given people */
  validJobIds?: string[]
  /**
   * For flexibility this option can have 3 values. True, False, and Null.
   * True: Will add a condition to only show people with user accounts.
   * False: Will add a condition to only show people without user accounts.
   * Null: This is the default option. There will be no change and show both, people with and without user accounts.
   **/
  hasUserAccount?: boolean
  /** Whether selection of multiple options is allowed */
  isSingleSelect?: boolean
}

interface IPeopleDropdownInputReactSelectProps {
  closeOnSelect: boolean
  validJobIds?: string[]
  hierarchicalPermissionsFilter?: string[]
  isDisabled: boolean
  isInvalid: boolean
  isSingleSelect: boolean
  name: string
  onChange?: (value: IPeopleDropdownValue[] | null) => void
  placeholder?: string
  selectableValues: 'people_jobs_only' | 'people_jobs_business_units'
  selectedOptions: IPeopleDropdownOption[]
  showJobCountForSelectedItems: boolean
  showIndividualJobs: boolean
  showNoJobRecords: boolean
  componentContext?: string
  hasUserAccount?: boolean
}

const alwaysTrue = () => true

/**
 * A multi-select input for choosing between people in the system.
 * Value shape: IPeopleDropdownValue[]
 */
const PeopleDropdownInput: React.FC<IPeopleDropdownInputProps> = (props) => {
  const {
    closeOnSelect = false,
    hierarchicalPermissionsFilter,
    isDisabled = false,
    isInvalid = false,
    name,
    onChange,
    placeholder,
    selectableValues = 'people_jobs_only',
    showJobCountForSelectedItems = true,
    showIndividualJobs = false,
    isSingleSelect = false,
    showNoJobRecords = true,
    value: parentValue,
    componentContext,
    validJobIds,
    hasUserAccount,
  } = props

  const value = useMemo(() => (Array.isArray(parentValue) ? parentValue : []), [parentValue])
  const { data: selectedData } = useGetValuesForDropdownSearch({
    peopleIds: value.flatMap((valueOption) => {
      if (isPeopleJobsValue(valueOption)) {
        return [valueOption.personId]
      }
      return []
    }),
    jobIds: value.flatMap((valueOption) => {
      if (isPeopleJobsValue(valueOption) && valueOption.jobId) {
        return [valueOption.jobId]
      }

      return []
    }),
    businessUnitIds: value.flatMap((valueOption) => {
      if (isBusinessUnitValue(valueOption)) {
        return [valueOption.businessUnitId]
      }
      return []
    }),
  })

  const selectedOptions = useMemo(() => {
    const selectedOptions: IPeopleAndBusinessUnitOption[] = value.flatMap<IPeopleAndBusinessUnitOption>(
      (valueOption) => {
        if (isPeopleJobsValue(valueOption)) {
          const person = selectedData?.getPeople.find((selectedPerson) => selectedPerson.id === valueOption.personId)
          const job = selectedData?.getJobs.find((selectedJob) => selectedJob.id === valueOption.jobId)

          if (person && (!valueOption.jobId || job) && (!validJobIds || (job && validJobIds.includes(job.id)))) {
            const personOption: IPersonOption = {
              type: 'person',
              person: person as IPerson,
              job: (job as IPeopleSearchJob) ?? null,
              otherJobs: [],
              value: {
                person,
                job: (job as IPeopleSearchJob) ?? null,
                personId: person.id,
                jobId: job?.id,
              },
            }

            return [personOption]
          }
        }

        if (isBusinessUnitValue(valueOption)) {
          const businessUnit = selectedData?.getBusinessUnits.find(
            (selectedBusinessUnit) => selectedBusinessUnit.id === valueOption.businessUnitId,
          )

          if (businessUnit) {
            const businessUnitOption: IBusinessUnitOption = {
              type: 'businessUnit',
              name: businessUnit.name,
              disabled: false,
              value: {
                businessUnitId: businessUnit.id,
              },
            }

            return [businessUnitOption]
          }
        }

        return []
      },
    )

    return selectedOptions
  }, [selectedData?.getBusinessUnits, selectedData?.getJobs, selectedData?.getPeople, validJobIds, value])

  useEffect(() => {
    if (selectedOptions.length !== 0 && selectedOptions.length !== value.length) {
      const validOptions = selectedOptions.filter((option) => {
        return option.type === 'person' || option.type === 'businessUnit'
      })

      if (!isEqual(validOptions, value) && onChange) {
        onChange(validOptions.flatMap((option) => formatOptionForOnChange(option, showIndividualJobs)))
      }
    }
  }, [onChange, selectedOptions, showIndividualJobs, value])

  return (
    <PeopleDropdownInputReactSelect
      closeOnSelect={closeOnSelect}
      validJobIds={validJobIds}
      isSingleSelect={isSingleSelect}
      hierarchicalPermissionsFilter={hierarchicalPermissionsFilter}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      selectableValues={selectableValues}
      selectedOptions={selectedOptions}
      showJobCountForSelectedItems={showJobCountForSelectedItems}
      showIndividualJobs={showIndividualJobs}
      showNoJobRecords={showNoJobRecords}
      componentContext={componentContext}
      hasUserAccount={hasUserAccount}
    />
  )
}

const PeopleDropdownInputReactSelect: React.FC<IPeopleDropdownInputReactSelectProps> = (props) => {
  const {
    closeOnSelect,
    hierarchicalPermissionsFilter,
    isDisabled,
    isInvalid,
    name,
    onChange,
    placeholder,
    selectableValues,
    selectedOptions,
    showJobCountForSelectedItems,
    showIndividualJobs,
    isSingleSelect,
    showNoJobRecords,
    componentContext,
    validJobIds,
    hasUserAccount,
  } = props

  const selectRef = useRef<(ReactSelect<IPeopleDropdownValue> & { closeMenu: () => void }) | null>(null)
  const [searchText, setSearchText] = useState<string>('')
  const [setDebouncedSearch] = useDebouncedCallback(setSearchText, 300, { maxWait: 1000 })

  const value = useMemo(
    () =>
      selectedOptions.flatMap<Option<IPeopleDropdownValue>>((option) => {
        switch (option.type) {
          case 'person': {
            const job = option.job ?? option.person.primaryJob
            return [
              {
                type: 'person' as const,
                value: {
                  personId: option.person.id,
                  jobId: option.job?.id ?? null,
                  person: option.person,
                  job: option.job,
                },
                displayName: option.person.displayName,
                jobName: job?.jobName.name,
                statusDot: getColorForEmploymentStatus(
                  job?.isOnExtendedLeave ?? false,
                  option.person.employmentStatusId,
                ),
                profilePictureUrl: option.person.profilePictureUrl,
                initials: option.person.initials,
              },
            ]
          }
          case 'businessUnit':
            return [
              {
                type: 'businessUnit',
                name: option.name,
                value: {
                  businessUnitId: option.value.businessUnitId,
                },
                disabled: false,
              },
            ]
          default:
            return []
        }
      }),
    [selectedOptions],
  )

  const { options, loading } = usePeopleSearch(searchText, {
    showIndividualJobs,
    currentlySelected: value,
    hierarchicalPermissionsFilter,
    selectableValues,
    showNoJobRecords,
    validJobIds,
    hasUserAccount,
  })

  const handleChange = useCallback<OnChangeHandler<IPeopleDropdownValue>>(
    (newOptions: IPeopleDropdownOption[]) => {
      if (!onChange) {
        return
      }

      if (!Array.isArray(newOptions)) {
        newOptions = newOptions ? [newOptions] : []
      }

      const validOptions = newOptions.filter((option) => {
        if (option.type === 'control') {
          option.toggleSet()
        }
        return option.type === 'person' || option.type === 'businessUnit'
      })

      if (!isEqual(validOptions, value)) {
        onChange(validOptions.flatMap((option) => formatOptionForOnChange(option, showIndividualJobs)))
      }

      if (validOptions.length === newOptions.length && closeOnSelect) {
        selectRef.current?.closeMenu()
      }

      return
    },
    [onChange, showIndividualJobs, selectRef, closeOnSelect, value],
  )

  const inputRenderer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (inputRendererProps: any) => (
      <InputComponent
        inputProps={inputRendererProps}
        loading={loading}
        id={name}
        isSingleSelect={isSingleSelect}
        placeholder={placeholder}
        hasValuesSelected={value.length > 0}
        name={`${name}[]`}
        isDisabled={isDisabled}
        componentContext={componentContext ? `${componentContext}-input` : undefined}
      />
    ),
    [loading, name, isSingleSelect, placeholder, value.length, isDisabled, componentContext],
  )

  const valueComponent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (valueProps: any) => (
      <ValueComponent
        {...valueProps}
        isDisabled={isDisabled}
        onRemove={isSingleSelect ? () => handleChange([]) : valueProps?.onRemove}
        showJobCountForSelectedItems={showJobCountForSelectedItems}
      />
    ),
    [showJobCountForSelectedItems, isSingleSelect, handleChange, isDisabled],
  )

  const optionComponent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (optionProps: any) => (
      <OptionComponent
        {...optionProps}
        selectableValues={selectableValues}
      />
    ),
    [selectableValues],
  )

  const handleInputChange = useCallback(
    (input: string) => {
      setDebouncedSearch(input)
      return input
    },
    [setDebouncedSearch],
  )

  return (
    <PeopleDropdownStyleWrapper
      isInvalid={isInvalid}
      isSingleSelect={isSingleSelect}
      data-cy={componentContext}
    >
      <ReactSelect<IPeopleDropdownValue>
        autoFocus={false}
        closeOnSelect={false}
        ref={selectRef as MutableRefObject<ReactSelect<IPeopleDropdownValue>>}
        disabled={isDisabled}
        multi={!isSingleSelect}
        arrowRenderer={null}
        clearable={false}
        placeholder=""
        onChange={handleChange}
        options={options}
        inputRenderer={inputRenderer}
        valueComponent={valueComponent}
        openOnFocus
        optionComponent={optionComponent}
        filterOption={alwaysTrue}
        onInputChange={handleInputChange}
        value={isSingleSelect ? value[0] : value}
        onSelectResetsInput={false}
        searchable
      />
    </PeopleDropdownStyleWrapper>
  )
}

export { PeopleDropdownInput, PeopleDropdownInputReactSelect }
