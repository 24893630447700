import React from 'react'
import { Variables } from '@intellihr/ui-components'
import { Option } from 'react-select'
import { Chip } from 'src/services/uiComponentCandidates/Chip'
import { IPeopleDropdownValue, isBusinessUnitValue, isPeopleJobsValue } from '../../types'
import { BusinessUnitValueComponent } from './BusinessUnitValueComponent'

const ValueComponent: React.FC<{
  value: Option<IPeopleDropdownValue>
  onRemove?: (option: Option<IPeopleDropdownValue>) => void
  showJobCountForSelectedItems: boolean
  isDisabled: boolean
}> = ({ onRemove, value, showJobCountForSelectedItems, isDisabled }) => {
  if (isBusinessUnitValue(value.value)) {
    return (
      <BusinessUnitValueComponent
        name={value.name}
        id={value.value.businessUnitId}
        onRemove={() => onRemove?.(value)}
        isDisabled={isDisabled}
        showJobCountForSelectedItems={showJobCountForSelectedItems}
      />
    )
  }

  if (isPeopleJobsValue(value.value)) {
    return (
      <Chip
        margins={{
          top: Variables.Spacing.s2XSmall,
          bottom: Variables.Spacing.s2XSmall,
          left: Variables.Spacing.s2XSmall,
          right: Variables.Spacing.sXSmall,
        }}
        primaryText={value.displayName ?? 'N/A'}
        secondaryText={value.jobName}
        avatarUrl={value.profilePictureUrl}
        initials={value.initials}
        statusDot={value.statusDot}
        actionIcon={!isDisabled ? 'times' : undefined}
        actionIconType={!isDisabled ? 'light' : undefined}
        onActionClick={!isDisabled ? () => onRemove?.(value) : undefined}
      />
    )
  }

  return null
}

export { ValueComponent }
