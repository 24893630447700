import React from 'react'
import {
  BrandSpinnerFrame,
  BrandSpinnerInner,
  BrandWordmarkSVG,
  BrandLoadingBarContainer,
  BrandLoadingBarSVG,
} from './style'

const InitialLoadBrandSpinner = () => (
  <BrandSpinnerFrame data-cy="page-spinner">
    <BrandSpinnerInner>
      <BrandWordmarkSVG
        width="208"
        height="81"
        viewBox="0 0 208 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_303_38)">
          <path
            d="M4.12479 0C1.7731 0 0 1.87359 0 4.12687C0 6.38016 1.81043 8.25375 4.12479 8.25375C6.43915 8.25375 8.24957 6.38016 8.24957 4.12687C8.24957 1.87359 6.40804 0 4.12479 0Z"
            fill="#3C479D"
          />
          <path
            d="M141.269 8.25375C143.553 8.25375 145.394 6.38016 145.394 4.12687C145.394 1.87359 143.521 0 141.269 0C139.017 0 137.145 1.87359 137.145 4.12687C137.145 6.38016 138.918 8.25375 141.269 8.25375Z"
            fill="#3C479D"
          />
          <path
            d="M171.175 47.5555V32.0688H159.205V47.5555H153.133V13.75H159.205V26.336H171.175V13.7811H177.241V47.5555H171.175Z"
            fill="black"
          />
          <path
            d="M194.568 13.75C201.455 13.75 205.306 18.7296 205.306 23.7777C205.306 27.0519 203.67 30.0894 200.702 31.8323L208 47.5244H201.592L195.146 33.3013H190.916V47.5617H184.844V13.75H194.562H194.568ZM190.885 27.637H194.568C197.741 27.637 198.935 25.4895 198.935 23.8151C198.935 21.9726 197.741 19.4828 194.568 19.4828H190.885V27.637Z"
            fill="black"
          />
          <path
            d="M7.50217 13.7126H0.751953V47.5555H7.50217V13.7126Z"
            fill="#3C479D"
          />
          <path
            d="M31.7797 13.1028C28.5384 13.1028 24.9611 15.2503 22.8147 19.3149L22.5409 13.719H16.0645V47.5619H22.8831L22.8147 30.6062C22.8147 26 26.5351 19.3833 31.7797 19.3833C33.9261 19.3833 35.6992 20.9893 36.1098 25.1162V47.5681H42.86V24.1327C42.86 17.1052 37.4723 13.1152 31.7797 13.1152"
            fill="#3C479D"
          />
          <path
            d="M31.7797 13.1025C28.5384 13.1025 24.9611 15.25 22.8147 19.3146L22.5409 13.7188H16.0645V47.5616H22.8831L22.8147 30.606C22.8147 25.9998 26.5351 19.3831 31.7797 19.3831C33.9261 19.3831 35.6992 20.989 36.1098 25.1159V47.5678H42.86V24.1324C42.86 17.1049 37.4723 13.115 31.7797 13.115"
            fill="#3C479D"
          />
          <path
            d="M31.7797 13.1025C28.5384 13.1025 24.9611 15.25 22.8147 19.3146L22.5409 13.7188H16.0645V47.5616H22.8831L22.8147 30.606C22.8147 25.9998 26.5351 19.3831 31.7797 19.3831C33.9261 19.3831 35.6992 20.989 36.1098 25.1159V47.5678H42.86V24.1324C42.86 17.1049 37.4723 13.115 31.7797 13.115"
            fill="#3C479D"
          />
          <path
            d="M61.6478 41.8913C59.6382 41.8913 58.6117 38.7852 58.6117 36.8058V20.124H66.8613V13.7812H58.6117V5.52124H51.8615V13.7812H47.3945V20.124H51.8615V36.8743C51.8615 42.1278 54.7607 48.2341 61.6478 48.2341C64.8207 48.2341 67.8567 46.8024 69.8973 45.1965L66.7928 39.1898C64.7087 40.9265 63.0102 41.885 61.6415 41.885"
            fill="#3C479D"
          />
          <path
            d="M86.3342 13.1028C78.7627 13.1028 70.9922 19.041 70.9922 30.6747C70.9922 42.3083 78.8374 48.2466 86.9812 48.2466C91.7219 48.2466 96.6306 46.0306 100.146 41.0821L95.1001 36.5444C92.9537 40.1235 89.7124 41.8664 86.7821 41.8664C82.6573 41.8664 78.3957 38.0819 78.0161 32.3491H100.693C100.83 31.4652 100.898 29.38 100.898 29.38C100.861 18.6364 93.6381 13.109 86.3404 13.109M78.2588 26.6163C79.2107 22.0101 82.7257 19.5203 86.1662 19.5203C89.6066 19.5203 92.9164 21.9416 93.4639 26.6163H78.2588Z"
            fill="#3C479D"
          />
          <path
            d="M129.367 0H122.617V47.5555H129.367V0Z"
            fill="#3C479D"
          />
          <path
            d="M144.647 13.7126H137.896V47.5555H144.647V13.7126Z"
            fill="#3C479D"
          />
          <path
            d="M114.094 0H107.344V47.5555H114.094V0Z"
            fill="#3C479D"
          />
          <path
            d="M0.751953 59.4568H207.999"
            stroke="#3C479D"
            strokeWidth="1.06"
          />
          <path
            d="M4.74689 72.796H4.82155L6.35201 77.1158H3.21021L4.74067 72.796H4.74689ZM4.18696 71.2834L0.671875 80.8568H1.88505L2.84936 78.1491H6.71907L7.68339 80.8568H8.89656L5.38147 71.2834H4.18696Z"
            fill="black"
          />
          <path
            d="M22.9193 71.2773V75.3357H18.4897V71.2773H16.7539V80.8507H18.4897V76.7861H22.9193V80.8507H24.6613V71.2773H22.9193Z"
            fill="black"
          />
          <path
            d="M34.2297 71.2773H35.9655V77.533C35.9655 78.2177 35.8037 78.8215 35.4802 79.3444C35.1567 79.8672 34.7025 80.2718 34.1239 80.5644C33.5454 80.8569 32.861 81.0001 32.0833 81.0001C31.3056 81.0001 30.6213 80.8569 30.0365 80.5644C29.4517 80.2718 29.0037 79.8672 28.6802 79.3444C28.3567 78.8215 28.2012 78.2239 28.2012 77.533V71.2773H29.9369V77.3836C29.9369 77.782 30.024 78.1368 30.2045 78.448C30.3787 78.7592 30.6275 79.002 30.951 79.1825C31.2745 79.3568 31.654 79.4502 32.0895 79.4502C32.525 79.4502 32.9108 79.363 33.2343 79.1825C33.5578 79.0082 33.8067 78.7655 33.9808 78.448C34.155 78.1368 34.2421 77.782 34.2421 77.3836V71.2773H34.2297Z"
            fill="black"
          />
          <path
            d="M47.4253 71.2773L44.5822 78.2177H44.4702L41.6332 71.2773H39.5117V80.8507H41.1728V74.2651H41.2599L43.904 80.8258H45.1483L47.7924 74.2776H47.8795V80.8507H49.5468V71.2773H47.4253Z"
            fill="black"
          />
          <path
            d="M56.8692 73.2442H56.9439L58.2379 77.0972H55.5752L56.8692 73.2442ZM55.8365 71.2834L52.4707 80.8568H54.3247L55.1148 78.4977H58.7108L59.5009 80.8568H61.3549L57.9829 71.2834H55.8427H55.8365Z"
            fill="black"
          />
          <path
            d="M70.4081 71.2773V77.8069H70.321L65.8229 71.2773H64.2676V80.8507H66.0033V74.3336H66.0842L70.5885 80.8507H72.1314V71.2773H70.4081Z"
            fill="black"
          />
          <path
            d="M75.6699 71.2773V80.8507H77.4057V76.7861H81.3812V75.3357H77.4057V72.7339H81.798V71.2773H75.6699Z"
            fill="black"
          />
          <path
            d="M91.8399 76.0639C91.8399 75.3356 91.7279 74.7256 91.5039 74.2276C91.2799 73.7297 90.9689 73.35 90.5645 73.0885C90.1663 72.8333 89.7059 72.7026 89.1895 72.7026C88.6732 72.7026 88.2128 72.8333 87.8084 73.0885C87.4102 73.3437 87.0992 73.7234 86.8752 74.2276C86.6512 74.7256 86.5392 75.3418 86.5392 76.0639C86.5392 76.7859 86.6512 77.4022 86.8752 77.9001C87.0992 78.3981 87.4102 78.7778 87.8084 79.0392C88.2066 79.2944 88.667 79.4251 89.1895 79.4251C89.7121 79.4251 90.1663 79.2944 90.5645 79.0392C90.9627 78.784 91.2737 78.4043 91.5039 77.9001C91.7279 77.4022 91.8399 76.7859 91.8399 76.0639ZM93.5819 76.0639C93.5819 77.0971 93.389 77.981 93.0033 78.7093C92.6175 79.4438 92.0949 80.004 91.4355 80.3962C90.776 80.7821 90.0232 80.9813 89.1895 80.9813C88.3559 80.9813 87.6031 80.7883 86.9374 80.3962C86.2779 80.004 85.7553 79.4438 85.3696 78.7093C84.9839 77.9748 84.791 77.0971 84.791 76.0639C84.791 75.0306 84.9839 74.1467 85.3696 73.4184C85.7553 72.6839 86.2779 72.1237 86.9374 71.7316C87.5969 71.3457 88.3497 71.1465 89.1895 71.1465C90.0294 71.1465 90.776 71.3394 91.4355 71.7316C92.0949 72.1237 92.6238 72.6839 93.0033 73.4184C93.389 74.1529 93.5819 75.0368 93.5819 76.0639Z"
            fill="black"
          />
          <path
            d="M101.827 76.5122L104.197 80.8507H102.262L99.9352 76.5122H101.827ZM96.8867 80.8507V71.2773H100.476C101.211 71.2773 101.827 71.4081 102.33 71.6633C102.828 71.9185 103.208 72.2795 103.463 72.7339C103.718 73.1945 103.848 73.7298 103.848 74.3398C103.848 74.9498 103.718 75.4851 103.457 75.9333C103.195 76.3815 102.816 76.73 102.312 76.9728C101.808 77.2156 101.186 77.34 100.452 77.34H97.8946V75.9022H100.215C100.644 75.9022 100.999 75.8399 101.273 75.7217C101.547 75.6034 101.752 75.4229 101.883 75.1926C102.013 74.9623 102.082 74.6759 102.082 74.3336C102.082 73.9912 102.013 73.7049 101.883 73.4622C101.746 73.2194 101.547 73.0389 101.273 72.9082C100.999 72.7837 100.644 72.7214 100.215 72.7214H98.6287V80.8445H96.8929L96.8867 80.8507Z"
            fill="black"
          />
          <path
            d="M115.289 74.5077H113.541C113.491 74.2214 113.398 73.9662 113.267 73.7421C113.131 73.518 112.969 73.3313 112.77 73.1757C112.571 73.0201 112.347 72.9018 112.092 72.8209C111.836 72.74 111.563 72.7026 111.27 72.7026C110.748 72.7026 110.287 72.8333 109.889 73.0948C109.485 73.3562 109.174 73.7359 108.944 74.2339C108.713 74.7318 108.601 75.3418 108.601 76.0639C108.601 76.7859 108.713 77.4146 108.944 77.9126C109.174 78.4105 109.485 78.7902 109.889 79.0454C110.287 79.3006 110.748 79.4251 111.264 79.4251C111.55 79.4251 111.818 79.3878 112.073 79.3131C112.322 79.2384 112.552 79.1263 112.751 78.977C112.95 78.8276 113.124 78.6408 113.261 78.423C113.398 78.2051 113.498 77.9561 113.547 77.676H115.296C115.233 78.1429 115.09 78.5661 114.873 78.9645C114.655 79.3629 114.375 79.7115 114.026 80.0165C113.678 80.3215 113.267 80.558 112.801 80.7261C112.334 80.8941 111.818 80.9813 111.245 80.9813C110.406 80.9813 109.653 80.7883 108.993 80.3962C108.334 80.004 107.811 79.4438 107.432 78.7093C107.052 77.9748 106.859 77.0909 106.859 76.0639C106.859 75.0368 107.052 74.1467 107.432 73.4184C107.811 72.6839 108.334 72.1237 108.993 71.7316C109.653 71.3394 110.399 71.1465 111.233 71.1465C111.768 71.1465 112.26 71.2212 112.72 71.3706C113.18 71.52 113.585 71.7378 113.946 72.0241C114.306 72.3105 114.599 72.6653 114.829 73.0823C115.059 73.4994 115.215 73.9724 115.283 74.5077"
            fill="black"
          />
          <path
            d="M118.543 71.2773V80.8507H124.802V79.4004H120.273V76.7861H124.441V75.3357H120.273V72.7339H124.764V71.2773H118.543Z"
            fill="black"
          />
          <path
            d="M141.032 74.2712H139.875C139.807 73.9351 139.689 73.6425 139.521 73.3935C139.353 73.1383 139.147 72.9267 138.905 72.7586C138.662 72.5844 138.395 72.4536 138.102 72.3665C137.81 72.2794 137.505 72.2358 137.188 72.2358C136.609 72.2358 136.086 72.3852 135.614 72.6777C135.147 72.9703 134.774 73.3998 134.5 73.9724C134.226 74.5451 134.083 75.2422 134.083 76.0701C134.083 76.898 134.22 77.6013 134.5 78.1678C134.774 78.7404 135.147 79.1699 135.614 79.4625C136.08 79.755 136.609 79.9044 137.188 79.9044C137.505 79.9044 137.81 79.8609 138.102 79.7737C138.395 79.6866 138.662 79.5558 138.905 79.3816C139.147 79.2073 139.353 78.9956 139.521 78.7404C139.689 78.4852 139.807 78.1927 139.875 77.8628H141.032C140.945 78.3545 140.784 78.7902 140.553 79.1761C140.323 79.5621 140.037 79.892 139.695 80.1596C139.353 80.4273 138.967 80.6327 138.544 80.7759C138.121 80.919 137.667 80.9875 137.181 80.9875C136.366 80.9875 135.638 80.7883 135.004 80.3899C134.369 79.9916 133.872 79.4251 133.505 78.6906C133.137 77.9561 132.957 77.0847 132.957 76.0701C132.957 75.0555 133.137 74.1903 133.505 73.4558C133.872 72.7213 134.369 72.1549 135.004 71.7565C135.638 71.3581 136.366 71.1589 137.181 71.1589C137.667 71.1589 138.115 71.2274 138.544 71.3706C138.967 71.5137 139.353 71.7129 139.695 71.9868C140.037 72.2545 140.323 72.5844 140.553 72.9703C140.784 73.3562 140.945 73.7919 141.032 74.2837"
            fill="black"
          />
          <path
            d="M151.51 76.0639C151.51 75.236 151.374 74.5326 151.094 73.9662C150.814 73.3998 150.447 72.964 149.98 72.6715C149.513 72.3789 148.985 72.2296 148.406 72.2296C147.827 72.2296 147.305 72.3789 146.832 72.6715C146.365 72.964 145.992 73.3935 145.712 73.9662C145.432 74.5389 145.301 75.236 145.301 76.0639C145.301 76.8917 145.438 77.5951 145.712 78.1615C145.986 78.7342 146.359 79.1637 146.832 79.4562C147.299 79.7488 147.821 79.8982 148.406 79.8982C148.991 79.8982 149.507 79.7488 149.98 79.4562C150.447 79.1637 150.82 78.7342 151.094 78.1615C151.367 77.5889 151.51 76.8917 151.51 76.0639ZM152.63 76.0639C152.63 77.0722 152.45 77.9437 152.083 78.6844C151.716 79.4189 151.218 79.9853 150.583 80.3837C149.949 80.7821 149.221 80.9813 148.406 80.9813C147.591 80.9813 146.863 80.7821 146.228 80.3837C145.594 79.9853 145.096 79.4189 144.729 78.6844C144.362 77.9499 144.182 77.0785 144.182 76.0639C144.182 75.0493 144.362 74.1841 144.729 73.4433C145.096 72.7088 145.594 72.1424 146.228 71.744C146.863 71.3457 147.591 71.1465 148.406 71.1465C149.221 71.1465 149.949 71.3457 150.583 71.744C151.218 72.1424 151.722 72.7088 152.083 73.4433C152.45 74.1778 152.63 75.0493 152.63 76.0639Z"
            fill="black"
          />
          <path
            d="M164.227 71.2773L160.979 79.2261H160.867L157.614 71.2773H156.232V80.8507H157.315V73.5804H157.408L160.395 80.8507H161.446L164.432 73.5804H164.526V80.8507H165.608V71.2773H164.227Z"
            fill="black"
          />
          <path
            d="M169.582 80.8507V71.2773H172.817C173.564 71.2773 174.18 71.4143 174.659 71.6819C175.138 71.9496 175.492 72.3168 175.723 72.7712C175.953 73.2319 176.071 73.7423 176.071 74.3025C176.071 74.8627 175.959 75.3793 175.729 75.8399C175.499 76.3005 175.144 76.6678 174.671 76.9417C174.192 77.2156 173.582 77.3525 172.836 77.3525H170.521V76.3254H172.799C173.315 76.3254 173.725 76.2383 174.037 76.0578C174.348 75.8773 174.572 75.6407 174.715 75.3357C174.858 75.0307 174.926 74.6884 174.926 74.3025C174.926 73.9166 174.858 73.5742 174.715 73.2754C174.572 72.9766 174.348 72.7339 174.03 72.5658C173.713 72.3915 173.296 72.3044 172.78 72.3044H170.745V80.8507H169.588H169.582Z"
            fill="black"
          />
          <path
            d="M181.876 72.796H181.95L183.481 77.1158H180.339L181.87 72.796H181.876ZM181.316 71.2834L177.801 80.8568H179.014L179.978 78.1491H183.848L184.812 80.8568H186.025L182.51 71.2834H181.316Z"
            fill="black"
          />
          <path
            d="M195.625 71.2773V78.8153H195.532L190.3 71.2773H189.18V80.8507H190.337V73.3377H190.43L195.644 80.8507H196.764V71.2773H195.625Z"
            fill="black"
          />
          <path
            d="M206.662 71.2773L204.012 75.7466H203.893L201.243 71.2773H199.918L203.371 76.9043V80.8507H204.534V76.9043L207.987 71.2773H206.662Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_303_38">
            <rect
              width="208"
              height="81"
              fill="white"
            />
          </clipPath>
        </defs>
      </BrandWordmarkSVG>
      <BrandLoadingBarContainer>
        <BrandLoadingBarSVG
          width="253"
          height="10"
          viewBox="0 0 253 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.66211 2.03338C7.91965 2.03338 8.18967 7.89351 13.9044 7.89351C19.6191 7.89351 19.5205 2.03338 24.7781 2.03338C31.2285 2.03338 30.3071 7.89351 36.3889 7.89351C41.8751 7.89351 42.1894 2.03338 47.4469 2.03338C52.9759 2.03338 53.7131 8.07664 59.0578 7.89351C64.4025 7.71038 64.5854 2.03338 70.3001 2.03338C75.4605 2.03338 75.8291 7.89351 81.7266 7.89351C86.2984 7.89351 87.9928 1.48423 92.7846 2.03337M84 7.34413C87.5017 5.87909 87.4045 2.03338 92.6621 2.03338C97.9196 2.03338 98.1896 7.89351 103.904 7.89351C109.619 7.89351 109.521 2.03338 114.778 2.03338C121.229 2.03338 120.307 7.89351 126.389 7.89351C131.875 7.89351 132.189 2.03338 137.447 2.03338C142.976 2.03338 143.713 8.07664 149.058 7.89351C154.402 7.71038 154.585 2.03338 160.3 2.03338C165.46 2.03338 165.829 7.89351 171.727 7.89351C176.298 7.89351 177.993 1.48423 182.785 2.03338M174 7.3442C177.502 5.87916 177.405 2.03345 182.662 2.03345C187.92 2.03345 188.19 7.89358 193.904 7.89358C199.619 7.89358 199.52 2.03345 204.778 2.03345C211.229 2.03345 210.307 7.89358 216.389 7.89358C221.875 7.89358 222.189 2.03345 227.447 2.03345C232.976 2.03345 233.713 8.07671 239.058 7.89358C244.402 7.71045 244.585 2.03345 250.3 2.03345"
            stroke="#FED956"
            strokeWidth="3.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </BrandLoadingBarSVG>
      </BrandLoadingBarContainer>
    </BrandSpinnerInner>
  </BrandSpinnerFrame>
)

export { InitialLoadBrandSpinner }
