import gql from 'graphql-tag'
import { useMemo } from 'react'
import { useQuery } from 'src/services/apollo'
import { AnalyticsPermission, NULL_PERMISSION_RESP } from '.'
import type { IAccessConfig, IAnonymousFormConfigSkeleton } from './interface'

const QUERY = gql`
  query CheckAnalyticsPermissions {
    analyticsPermission {
      checkMyAnalyticsPermission {
        access
        pages
        forms
        anonymousForms
        viewAllPages
      }
    }
  }
`

interface IMyAnalyticsPermission {
  access: boolean
  pages: Record<string, IAccessConfig>
  forms: Record<string, IAccessConfig>
  anonymousForms: IAnonymousFormConfigSkeleton
  viewAllPages: boolean
}

interface IQueryData {
  analyticsPermission?: {
    checkMyAnalyticsPermission?: IMyAnalyticsPermission
  }
}

const useAnalyticsPermission = () => {
  const { loading, data: { analyticsPermission: { checkMyAnalyticsPermission = NULL_PERMISSION_RESP } = {} } = {} } =
    useQuery<IQueryData>(QUERY, {
      errorBehaviour: 'do-nothing',
    })

  const permission = useMemo(() => new AnalyticsPermission(checkMyAnalyticsPermission), [checkMyAnalyticsPermission])

  return {
    loading,
    permission,
  }
}

export { useAnalyticsPermission }
