import { InputGroup, Props, TextInput } from '@intellihr/ui-components'
import gql from 'graphql-tag'
import { CountryCode, getExampleNumber } from 'libphonenumber-js'
import examplePhoneNumbers from 'libphonenumber-js/examples.mobile.json'
import { find, get, isNil, toUpper } from 'lodash'
import React, { useEffect, useState } from 'react'
import { BaseFieldsProps, WrappedFieldProps } from 'redux-form'
import { useQuery } from 'src/services/apollo'
import { getInputGroupErrorMessages } from 'src/services/reduxForm'
import { useTenantContext } from 'src/services/user/TenantContext/helpers/hook'
import { CountryIds } from 'src/services/constants/countries/countryIds'
import { ListCountriesQuery } from './__gql__/CountryTelephoneInputGroupCodeGen'
import { CountryDialCodeInput } from './components'

interface ICountryTelephoneInputGroupProps {
  label: string
  fieldWrapperComponent: React.ComponentType<
    React.PropsWithChildren<{
      label?: string
      margins?: Props.IMargins
      inputName?: string
      errorMessages?: string | string[]
      isRequired?: boolean
    }>
  >
  isRequired?: boolean
  margins?: Props.IMargins
}

const LIST_ALL_COUNTRIES = gql`
  query ListCountriesQuery {
    listAllCountries {
      id
      name
      isoCode
      dialCode
    }
  }
`

const CountryTelephoneInputGroup = (props: ICountryTelephoneInputGroupProps & BaseFieldsProps) => {
  const { names, label, margins, fieldWrapperComponent: FieldWrapperElement, isRequired = false } = props

  const countryDialCodeField: WrappedFieldProps = get(props, names[0], {})
  const phoneNumberField: WrappedFieldProps = get(props, names[1], {})

  const { tenantCountry } = useTenantContext()
  const selectedCountryId = countryDialCodeField.input.value || tenantCountry?.id || CountryIds.Australia
  const [selectedCountry, setSelectedCountry] = useState<ListCountriesQuery['listAllCountries'][0] | null>(null)

  const { data } = useQuery<ListCountriesQuery>(LIST_ALL_COUNTRIES, {
    errorBehaviour: 'do-nothing',
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    countryDialCodeField.input.onChange(selectedCountryId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const foundCountry = find(data?.listAllCountries, (country) => country.id === selectedCountryId)

    if (foundCountry) {
      setSelectedCountry(foundCountry)
    }
  }, [selectedCountryId, data])

  return (
    <FieldWrapperElement
      label={label}
      margins={margins}
      inputName={phoneNumberField.input.name}
      errorMessages={getInputGroupErrorMessages([countryDialCodeField, phoneNumberField])}
      isRequired={isRequired}
    >
      <InputGroup>
        <CountryDialCodeInput
          onChange={countryDialCodeField.input.onChange}
          selectedCountry={selectedCountry}
          value={selectedCountryId}
          countryData={data ? data.listAllCountries : undefined}
          loading={!data?.listAllCountries}
        />
        <TextInput
          {...phoneNumberField.input}
          margins={margins}
          onChange={phoneNumberField.input.onChange}
          isInvalid={phoneNumberField.meta.touched && !isNil(phoneNumberField.meta.error)}
          placeholder={
            selectedCountry
              ? getExampleNumber(toUpper(selectedCountry.isoCode) as CountryCode, examplePhoneNumbers)?.formatNational()
              : undefined
          }
          groupPosition="right"
          isDisabled={!data?.listAllCountries}
        />
      </InputGroup>
    </FieldWrapperElement>
  )
}

export type { ICountryTelephoneInputGroupProps }
export { LIST_ALL_COUNTRIES, CountryTelephoneInputGroup }
