import styled, { css } from 'styled-components'

const NavigationWrapper = styled.div`
  @media print {
    display: none;
  }
`

const HeaderWrapper = styled.div`
  @media print {
    display: none;
  }
`

const ContentWrapper = styled.div<{ desktop: boolean }>`
  ${({ desktop }) =>
    desktop &&
    css`
      padding-left: 15rem;
    `}

  @media print {
    padding-left: 0;
  }
`

export { NavigationWrapper, HeaderWrapper, ContentWrapper }
