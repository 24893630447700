import React from 'react'
import { Button, DataTable, Status, TDataTableColumn, TextStyle, Stack } from '@intellihr/blueberry'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import {
  ICompositeQualification,
  IJobRequirement,
} from 'src/domain/EDM/internal/components/pageContents/OnboardingPageContent/steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'
import { ApprovalStatusIds } from 'src/services/constants/qualifications/approvalStatusIds'
import { QualificationTemplate } from 'src/__gql__/globalTypes'
import { getCurrentIsoDate, useUserTimezoneDateModifiers } from 'src/services/helpers/dates'
import { useFrontendDateStringFormatter } from 'src/services/uiComponentCandidates/Date'
import { IQualificationInstanceForModal } from '../QualificationModal/QualificationsModal'

enum statusType {
  APPROVED = 1,
  WAITING_FOR_REVIEW = 2,
  NO_RECORD = 3,
  REJECTED = 4,
  EXPIRED = 5,
}

const rejectedStatusIds = [ApprovalStatusIds.Declined, ApprovalStatusIds.Revoked]

const approvedStatusIds = [
  ApprovalStatusIds.Future,
  ApprovalStatusIds.Current,
  ApprovalStatusIds.Approved,
  ApprovalStatusIds.ExpiringSoon,
]

interface IQualificationsTableProps {
  jobRequirements: IJobRequirement[]
  compositeQualifications: ICompositeQualification[]
  openQualificationInstanceModal: (
    qualificationInstance: IQualificationInstanceForModal | null,
    qualificationTemplate: string,
  ) => void
}

interface IOnboardingRequiredQualification {
  name: string
  qualificationInstance: IQualificationInstanceForModal | null
  qualificationTemplateId: string
  qualificationInstanceId: string | null
  status: statusType
}

const getStatusType = (qualification: ICompositeQualification | null) => {
  if (qualification) {
    const statusId =
      qualification.renewalQualificationInstanceId && qualification.renewalStatusId
        ? qualification.renewalStatusId
        : qualification.currentStatusId

    if (statusId === ApprovalStatusIds.Expired) {
      return statusType.EXPIRED
    }

    if (rejectedStatusIds.includes(statusId as ApprovalStatusIds)) {
      return statusType.REJECTED
    }

    if (approvedStatusIds.includes(statusId as ApprovalStatusIds)) {
      return statusType.APPROVED
    }

    if (statusId === ApprovalStatusIds.AwaitingApproval) {
      return statusType.WAITING_FOR_REVIEW
    }
  }

  return statusType.NO_RECORD
}

// For now we will just use the current qualification data in the composite, in the future we might need to support for renewal data
const parseExistingQualificationInstance = (
  compositeQualification: ICompositeQualification | null,
): IQualificationInstanceForModal | null => {
  if (!compositeQualification) {
    return null
  }

  if (
    compositeQualification.renewalQualificationInstanceId &&
    compositeQualification.renewalStatusId === ApprovalStatusIds.Draft
  ) {
    return {
      id: compositeQualification.renewalQualificationInstanceId,
      qualificationStatus: { id: compositeQualification.renewalStatusId },
      issuingOrganisation: compositeQualification.issuingOrganisation,
      registrationNumber: compositeQualification.registrationNumber ?? '',
      issueDate: compositeQualification.renewalIssueDate,
      expiryDate: compositeQualification.renewalExpiryDate,
      currentExpiryDate: compositeQualification.currentExpiryDate,
      notes: compositeQualification.currentNotes,
      qualificationTemplate: compositeQualification.qualificationTemplate as QualificationTemplate,
      attachments: compositeQualification.renewalAttachments,
    }
  }

  return {
    id: compositeQualification.currentQualificationInstanceId,
    qualificationStatus: { id: compositeQualification.currentStatusId },
    issuingOrganisation: compositeQualification.issuingOrganisation,
    registrationNumber: compositeQualification.registrationNumber ?? '',
    issueDate: compositeQualification.currentIssueDate,
    expiryDate: compositeQualification.currentExpiryDate,
    currentExpiryDate: compositeQualification.currentExpiryDate,
    notes: compositeQualification.currentNotes,
    qualificationTemplate: compositeQualification.qualificationTemplate as QualificationTemplate,
    attachments: compositeQualification.currentAttachments,
  }
}

const parseRequiredQualifications = (
  jobRequirements: IJobRequirement[],
  qualifications: ICompositeQualification[],
): IOnboardingRequiredQualification[] => {
  const requiredQualifications: IOnboardingRequiredQualification[] = []
  const requiredQualificationTemplateIds: string[] = []

  jobRequirements.forEach((requirement: IJobRequirement) => {
    // Job Requirement Group
    if ('qualificationTemplates' in requirement.jobRequirementable) {
      requirement.jobRequirementable.qualificationTemplates.map((qualificationTemplate) => {
        if (qualificationTemplate) {
          const qualificationTemplateId = qualificationTemplate.id
          const compositeQualification = qualifications.find((compositeQualification) => {
            return compositeQualification.qualificationTemplate.id === qualificationTemplate.id
          })

          if (!requiredQualificationTemplateIds.includes(qualificationTemplateId)) {
            requiredQualifications.push({
              name: qualificationTemplate.name,
              qualificationTemplateId,
              qualificationInstanceId: compositeQualification?.currentQualificationInstanceId ?? null,
              status: getStatusType(compositeQualification || null),
              qualificationInstance: parseExistingQualificationInstance(compositeQualification || null),
            })

            requiredQualificationTemplateIds.push(qualificationTemplateId)
          }
        }
      })

      // Break out to go to the next item in array
      return
    }

    // Standalone Job Requirement
    const compositeQualification = qualifications.find((compositeQualification) => {
      return compositeQualification.qualificationTemplate.id === requirement.jobRequirementable.id
    })

    const qualificationTemplateId = requirement.jobRequirementable.id

    if (!requiredQualificationTemplateIds.includes(qualificationTemplateId)) {
      requiredQualifications.push({
        name: requirement.jobRequirementable.name,
        qualificationTemplateId,
        qualificationInstanceId:
          compositeQualification?.renewalQualificationInstanceId ??
          compositeQualification?.currentQualificationInstanceId ??
          null,
        status: getStatusType(compositeQualification || null),
        qualificationInstance: parseExistingQualificationInstance(compositeQualification || null),
      })

      requiredQualificationTemplateIds.push(qualificationTemplateId)
    }
  })

  return requiredQualifications
}

const QualificationsTable: React.FC<IQualificationsTableProps> = ({
  jobRequirements,
  compositeQualifications,
  openQualificationInstanceModal,
}) => {
  const tFeature = useScope('edm:features.Onboarding')
  const today = getCurrentIsoDate()
  const { isAfter } = useUserTimezoneDateModifiers()
  const { convertFrontendDateString } = useFrontendDateStringFormatter()

  const columns: Array<TDataTableColumn<IOnboardingRequiredQualification>> = [
    {
      accessorKey: 'name',
      header: tFeature('qualificationNameHeader'),
    },
    {
      accessorKey: 'status',
      header: tFeature('qualificationStatusHeader'),
      cell: ({ row }) => {
        if (row.original.status === statusType.APPROVED) {
          const issueDate = row.original.qualificationInstance?.issueDate
          if (isAfter(issueDate, today)) {
            const statusText = convertFrontendDateString(tFeature('qualificationStatusValidFrom', { date: issueDate }))
            return <Status tone="neutral">{statusText}</Status>
          }
        }

        switch (row.original.status) {
          case statusType.APPROVED:
            return <Status tone="positive">{tFeature('qualificationStatusValid')}</Status>
          case statusType.WAITING_FOR_REVIEW:
            return <Status tone="pending">{tFeature('qualificationStatusSubmittedForReview')}</Status>
          case statusType.REJECTED:
            return (
              <Stack>
                <Status tone="criticalAlert">{tFeature('qualificationStatusRejected')}</Status>
                <TextStyle
                  variant="em"
                  testId={`onboarding-qualifications-row-${row.id}-rejected-instruction`}
                >
                  {tFeature('qualificationStatusRejectedInstruction')}
                </TextStyle>
              </Stack>
            )
          case statusType.EXPIRED:
            return (
              <Status tone="criticalAlert">
                {convertFrontendDateString(
                  tFeature('qualificationStatusExpiredOn', {
                    date: row.original.qualificationInstance?.currentExpiryDate,
                  }),
                )}
              </Status>
            )
          case statusType.NO_RECORD:
          default:
            return <Status tone="criticalAlert">{tFeature('qualificationStatusNotProvided')}</Status>
        }
      },
    },
    {
      id: 'actions',
      accessorKey: 'actions',
      header: '',
      meta: {
        isNarrow: true,
        align: 'right',
      },
      cell: ({ row }) => {
        if (
          row.original.status === statusType.APPROVED ||
          row.original.status === statusType.WAITING_FOR_REVIEW ||
          row.original.status === statusType.REJECTED
        ) {
          return null
        }

        return (
          <div style={{ width: '150px' }}>
            <Button
              variant="secondary"
              testId={`onboarding-qualifications-row-${row.id}-button`}
              onClick={() =>
                openQualificationInstanceModal(row.original.qualificationInstance, row.original.qualificationTemplateId)
              }
            >
              {tFeature('qualificationProvideDetailsBtn')}
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <DataTable
      testId="onboarding-qualifications-table"
      columns={columns}
      data={parseRequiredQualifications(jobRequirements, compositeQualifications).sort((a, b) => a.status - b.status)}
    />
  )
}

export { QualificationsTable, getStatusType, statusType }
