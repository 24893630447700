import { isEqual, isPlainObject } from 'lodash'
import React, { useCallback } from 'react'
import { formValueSelector, reduxForm, FormErrors } from 'redux-form'
import { InjectedFormProps } from 'redux-form/lib/reduxForm'
import { useSelector } from 'react-redux'
import { Variables } from '@intellihr/ui-components'

type ReduxFormChange = (field: string, value: unknown) => void

interface IFormChildProps<FieldValues = unknown> {
  /** Call to update the state manually */
  reduxFormChange: ReduxFormChange
  /** { fieldName: value } for observed fields */
  observedFieldValues: FieldValues
  /** The error for the overall form. This is set using the state of the _error key in the error messages returned by validate. */
  formError?: string
  /** If the form data is changed from the initial values */
  isDirty: boolean
  /** If the form failed to submit */
  submitFailed: boolean
  /** If the form data fails validation */
  invalid: boolean
  /** If the form data passes validation */
  valid: boolean
}

interface IFormBaseProps<FieldValues = {}> {
  /** Set of fields to pass through their values to the child function */
  observedFields?: Set<string>
  /** Child function for rendering the form */
  children: (props: IFormChildProps<FieldValues>) => React.ReactNode
  /** Component to render the form using */
  formComponent: React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormState = (state: any) => state.get('form')

const getObjectKeys = (object: object): string[] => {
  const acc: string[] = []

  if (Array.isArray(object)) {
    // For arrays, error object has undefined for all indexes up until the erroring value
    for (let i = 0; i < object.length; i++) {
      if (!object[i]) {
        continue
      }

      acc.push(...getObjectKeys(object[i]).map((key) => `[${i}]${key}`))
    }
  } else if (isPlainObject(object)) {
    for (let i = 0; i < Object.entries(object).length; i++) {
      const [objectKey, objectValue] = Object.entries(object)[i]
      const subKeys = getObjectKeys(objectValue).map((subKey) => `${objectKey}.${subKey}`)

      if (subKeys.length) {
        acc.push(...subKeys)
      } else if (!acc.includes(objectKey)) {
        acc.push(objectKey)
      }
    }
  }

  return acc
}

/**
 * Scroll any errors into view.
 * Technically, there can be multiple errors here which would mean
 * we should look at each of the matching elements and find the one
 * highest on the page, but as a best-effort solution we just scroll to
 * the first matching one if it exists.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmitFail = (errors?: FormErrors<FormData, any> | undefined) => {
  if (errors && Object.keys(errors).length > 0) {
    const errorKeyArray = getObjectKeys(errors)
    for (let i = 0; i < errorKeyArray.length; i++) {
      const errorElement = document.getElementById(errorKeyArray[i])
      if (errorElement) {
        window.scrollTo({
          behavior: 'smooth',
          top:
            errorElement.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            Variables.Spacing.s2XLarge,
        })
        break
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateFormValueSelector = (form: string, observedFieldsArray: string[]) => (state: any) => {
  if (observedFieldsArray.length === 0) {
    return {}
  }

  // formValueSelector() returns just the value for a single item, and an object for multiple items, so we need special handling
  if (observedFieldsArray.length === 1) {
    return {
      [observedFieldsArray[0]]: formValueSelector(form, getFormState)(state, observedFieldsArray[0]),
    }
  }

  return formValueSelector(form, getFormState)(state, ...observedFieldsArray)
}

const FormBase: React.FC<IFormBaseProps & InjectedFormProps<{}, IFormBaseProps>> = (props) => {
  const {
    form,
    handleSubmit,
    children,
    change: reduxFormChange,
    formComponent: FormElement,
    observedFields,
    error,
    dirty,
    submitFailed,
    invalid,
    valid,
  } = props

  const handleSubmitWithoutPropagation = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (handleSubmit) {
        handleSubmit(e)
      }
    },
    [handleSubmit],
  )

  const observedFieldsArray = Array.from(observedFields || [])
  const observedFieldValues = useSelector(generateFormValueSelector(form, observedFieldsArray), isEqual)

  return (
    <FormElement onSubmit={handleSubmitWithoutPropagation}>
      {children({
        reduxFormChange,
        observedFieldValues,
        formError: error,
        isDirty: dirty,
        submitFailed,
        invalid,
        valid,
      })}
    </FormElement>
  )
}

// Majority of form-related functions shouldn't trigger a rerender or are not used for rerendering
const FormBaseMemo = React.memo(
  FormBase,
  (prevProps, nextProps) =>
    prevProps.form === nextProps.form &&
    prevProps.children === nextProps.children &&
    prevProps.handleSubmit === nextProps.handleSubmit &&
    prevProps.error === nextProps.error &&
    prevProps.dirty === nextProps.dirty &&
    prevProps.submitFailed === nextProps.submitFailed &&
    prevProps.invalid === nextProps.invalid &&
    prevProps.valid === nextProps.valid,
)

const ReduxForm = reduxForm<{}, IFormBaseProps>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  getFormState,
  onSubmitFail,
})(FormBaseMemo)

export type { IFormChildProps, ReduxFormChange }
export { ReduxForm, getFormState }
