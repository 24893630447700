import React from 'react'
import { Modal, Spinner } from '@intellihr/blueberry'
import { PersonDocument, QualificationTemplate } from 'src/__gql__/globalTypes'
import {
  IOnboardingFormSubmitData,
  QualificationInstanceForm,
} from 'src/domain/Compliance/Qualifications/components/Forms/QualificationInstanceForm/QualificationInstanceForm'
import { useOnboardingQualificationInstanceAttachmentDelete } from 'src/domain/Compliance/Qualifications/mutations/useOnboardingQualificationInstanceAttachmentDelete'
import { useOnboardingQualificationInstanceAttachmentCreate } from 'src/domain/Compliance/Qualifications/mutations/useOnboardingQualificationInstanceAttachmentCreate'
import { useOnboardingQualificationInstanceAttachmentUpdate } from 'src/domain/Compliance/Qualifications/mutations/useOnboardingQualificationInstanceAttachmentUpdate'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import {
  ICreateQualificationAttachmentParameters,
  IDeleteQualificationAttachmentParameters,
  IUpdateQualificationAttachmentParameters,
} from 'src/domain/Compliance/Qualifications/components/Forms/QualificationInstanceForm/QualificationInstanceAttachment'

interface IQualificationModalProps {
  isModalOpen: boolean
  onClose: () => void
  onSubmit: (formData: IOnboardingFormSubmitData) => void
  submitting: boolean
  qualificationInstance: IQualificationInstanceForModal | null
}

const QualificationsModal: React.FC<IQualificationModalProps> = ({
  isModalOpen,
  onClose,
  onSubmit,
  submitting,
  qualificationInstance,
}) => {
  const tFeature = useScope('qualifications:components.QualificationInstancePage')
  const tCommon = useScope('common:components')
  const [createQualificationAttachment, { loading: isCreatingQualificationAttachment }] =
    useOnboardingQualificationInstanceAttachmentCreate(tCommon('uploadFile.successMessage'))
  const [updateQualificationAttachment, { loading: isUpdatingQualificationAttachment }] =
    useOnboardingQualificationInstanceAttachmentUpdate()
  const [deleteQualificationAttachment, { loading: isDeletingQualificationAttachment }] =
    useOnboardingQualificationInstanceAttachmentDelete(tFeature('deleteAttachmentSuccessMessage'))

  const handleCreateQualificationAttachment = async (input: ICreateQualificationAttachmentParameters) => {
    const response = await createQualificationAttachment(input)
    return response.data?.onboardingQualificationInstanceAttachmentCreate
  }

  const handleUpdateQualificationAttachment = async (input: IUpdateQualificationAttachmentParameters) => {
    updateQualificationAttachment(input)
  }

  const handleDeleteQualificationAttachment = async (input: IDeleteQualificationAttachmentParameters) => {
    deleteQualificationAttachment(input)
  }

  return (
    <Modal
      testId="qualification-modal"
      subtitle="Qualification"
      variant="content"
      title={qualificationInstance?.qualificationTemplate.name ?? ''}
      open={isModalOpen}
      onOpenChange={onClose}
    >
      {qualificationInstance && (
        <QualificationInstanceForm
          onSubmit={onSubmit}
          submitting={submitting}
          qualificationInstance={qualificationInstance}
          createQualificationAttachment={handleCreateQualificationAttachment}
          updateQualificationAttachment={handleUpdateQualificationAttachment}
          deleteQualificationAttachment={handleDeleteQualificationAttachment}
          isAttachmentLoading={
            isCreatingQualificationAttachment || isUpdatingQualificationAttachment || isDeletingQualificationAttachment
          }
          submitButtonAlignment={'right'}
        />
      )}
      {!qualificationInstance && <Spinner />}
    </Modal>
  )
}

// Just enough qualification instance data to pass to the modal
export interface IQualificationInstanceForModal {
  id: string
  qualificationStatus: { id: string }
  issuingOrganisation?: string | null
  registrationNumber?: string | null
  issueDate?: string | null
  expiryDate?: string | null
  currentExpiryDate?: string | null
  notes?: string | null
  qualificationTemplate: QualificationTemplate
  attachments: PersonDocument[]
}

export { QualificationsModal }
