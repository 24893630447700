import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { urlForRoute } from 'src/services/routes'
import type { Location } from 'history'
import { RedirectAs404 } from './services/reactRouter'
import { useUserContext } from './services/user/UserContext/helpers/hook'

const DashboardRouter = React.lazy(() =>
  import('src/scenes/Dashboard/Router').then((module) => ({ default: module.DashboardRouter })),
)
const CintraRouter = React.lazy(() =>
  import('src/scenes/Cintra/Router').then((module) => ({ default: module.CintraRouter })),
)
const ComplianceRouter = React.lazy(() =>
  import('src/scenes/Compliance/Router').then((module) => ({ default: module.ComplianceRouter })),
)
const ContractsRouter = React.lazy(() =>
  import('src/scenes/Contracts/Router').then((module) => ({ default: module.ContractsRouter })),
)
const ContractTemplatesRouter = React.lazy(() =>
  import('src/scenes/ContractTemplates/Router').then((module) => ({ default: module.ContractTemplatesRouter })),
)
const UsersRouter = React.lazy(() =>
  import('src/scenes/Users/Router').then((module) => ({ default: module.UsersRouter })),
)
const MeRouter = React.lazy(() => import('src/scenes/Me/Router').then((module) => ({ default: module.MeRouter })))
const NotificationsRouter = React.lazy(() =>
  import('src/scenes/Notifications/Router').then((module) => ({ default: module.NotificationsRouter })),
)
const PeopleRouter = React.lazy(() =>
  import('src/scenes/People/Router').then((module) => ({ default: module.PeopleRouter })),
)
const PerformanceRouter = React.lazy(() =>
  import('src/scenes/Performance/Router').then((module) => ({ default: module.PerformanceRouter })),
)
const WorkflowRouter = React.lazy(() =>
  import('src/scenes/Workflows/Router').then((module) => ({ default: module.WorkflowRouter })),
)
const WorkflowSchedulePage = React.lazy(() =>
  import('src/domain/LPA/internal/features/Workflows/components/pages/WorkflowSchedulePage').then((module) => ({
    default: module.WorkflowSchedulePage,
  })),
)
const SettingsRouter = React.lazy(() =>
  import('src/scenes/Settings/Router').then((module) => ({ default: module.SettingsRouter })),
)
const TasksRouter = React.lazy(() =>
  import('src/scenes/Tasks/Router').then((module) => ({ default: module.TasksRouter })),
)
const CompensationPlansRouter = React.lazy(() =>
  import('src/scenes/CompensationPlans/Router').then((module) => ({ default: module.CompensationPlansRouter })),
)
const FormDesignsRouter = React.lazy(() =>
  import('src/scenes/FormDesigns/Router').then((module) => ({ default: module.FormDesignsRouter })),
)
const AnonymousFormDesignsRouter = React.lazy(() =>
  import('src/scenes/AnonymousFormDesigns/Router').then((module) => ({ default: module.AnonymousFormDesignsRouter })),
)
const AnonymousPulsesRouter = React.lazy(() =>
  import('src/scenes/AnonymousPulses/Router').then((module) => ({ default: module.AnonymousPulsesRouter })),
)
const AnonymousRecurringPulsesRouter = React.lazy(() =>
  import('src/scenes/AnonymousRecurringPulses/Router').then((module) => ({
    default: module.AnonymousRecurringPulsesRouter,
  })),
)
const PulsesRouter = React.lazy(() =>
  import('src/scenes/Pulses/Router').then((module) => ({ default: module.PulsesRouter })),
)
const RecurringPulsesRouter = React.lazy(() =>
  import('src/scenes/RecurringPulses/Router').then((module) => ({ default: module.RecurringPulsesRouter })),
)
const FormTemplatesRouter = React.lazy(() =>
  import('src/scenes/FormTemplates/Router').then((module) => ({ default: module.FormTemplatesRouter })),
)
const NotesRouter = React.lazy(() =>
  import('src/scenes/Notes/Router').then((module) => ({ default: module.NotesRouter })),
)
const EstablishmentsRouter = React.lazy(() =>
  import('src/scenes/Establishments/Router').then((module) => ({ default: module.EstablishmentsRouter })),
)
const OrganisationObjectivesRouter = React.lazy(() =>
  import('src/scenes/OrganisationObjectives/Router').then((module) => ({
    default: module.OrganisationObjectivesRouter,
  })),
)
const AccessDeniedRouter = React.lazy(() =>
  import('src/scenes/AccessDenied/Router').then((module) => ({ default: module.AccessDeniedRouter })),
)
const JobPendingUpdatesRouter = React.lazy(() =>
  import('src/scenes/JobPendingUpdates/Router').then((module) => ({ default: module.JobPendingUpdatesRouter })),
)

const Routers: React.FC = () => {
  const user = useUserContext().person.user
  if (user.isOnboarding) {
    return <Redirect to={urlForRoute('onboarding')} />
  }

  return (
    <Switch>
      <Route
        path={urlForRoute('dashboard')}
        component={DashboardRouter}
      />
      <Route
        path={urlForRoute('cintra.index')}
        component={CintraRouter}
      />
      <Route
        path={urlForRoute('compliance.index')}
        component={ComplianceRouter}
      />
      <Route
        path={urlForRoute('contracts.index')}
        component={ContractsRouter}
      />
      <Route
        path={urlForRoute('contract_templates.index')}
        component={ContractTemplatesRouter}
      />
      <Route
        path={urlForRoute('users.index')}
        component={UsersRouter}
      />
      <Route
        path={urlForRoute('me.index')}
        component={MeRouter}
      />
      <Route
        path={urlForRoute('notifications')}
        component={NotificationsRouter}
      />
      <Route
        path={urlForRoute('people.index')}
        component={PeopleRouter}
      />
      <Route
        path={urlForRoute('performance.index')}
        component={PerformanceRouter}
      />
      <Route
        path={urlForRoute('workflows.index')}
        component={WorkflowRouter}
      />
      <Route
        path={urlForRoute('workflow_events.schedule')}
        component={WorkflowSchedulePage}
      />
      <Route
        path={urlForRoute('workflow_events.schedule.legacy')}
        component={({ location }: { location: Location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(/workflow_events/, 'workflow-events'),
            }}
          />
        )}
      />
      <Route
        path={urlForRoute('settings.index')}
        component={SettingsRouter}
      />
      <Route
        path={urlForRoute('form_designs.index')}
        component={FormDesignsRouter}
      />
      <Route
        path={urlForRoute('tasks.show')}
        component={TasksRouter}
      />
      <Route
        path={urlForRoute('compensation_plans.index')}
        component={CompensationPlansRouter}
      />
      <Route
        path={urlForRoute('anonymous_form_designs.index')}
        component={AnonymousFormDesignsRouter}
      />
      <Route
        path={urlForRoute('anonymous_pulses.edit')}
        component={AnonymousPulsesRouter}
      />
      <Route
        path={urlForRoute('anonymous_pulses.schedule')}
        component={AnonymousPulsesRouter}
      />
      <Route
        path={urlForRoute('anonymous_recurring_pulses.edit')}
        component={AnonymousRecurringPulsesRouter}
      />
      <Route
        path={urlForRoute('pulses.schedule')}
        component={PulsesRouter}
      />
      <Route
        path={urlForRoute('pulses.edit')}
        component={PulsesRouter}
      />
      <Route
        path={urlForRoute('pulses.index')}
        component={PulsesRouter}
      />
      <Route
        path={urlForRoute('recurring_pulses.index')}
        component={RecurringPulsesRouter}
      />
      <Route
        path={urlForRoute('templates.index')}
        component={FormTemplatesRouter}
      />
      <Route
        path={urlForRoute('notes.index')}
        component={NotesRouter}
      />
      <Route
        path={urlForRoute('workforce_planning.index')}
        component={EstablishmentsRouter}
      />
      <Route
        path={urlForRoute('establishments.index')}
        component={EstablishmentsRouter}
      />
      <Route
        path={urlForRoute('organisationObjectives.index')}
        component={OrganisationObjectivesRouter}
      />
      <Route
        path={urlForRoute('job_pending_updates.index')}
        component={JobPendingUpdatesRouter}
      />
      <Route
        path={urlForRoute('access_denied')}
        component={AccessDeniedRouter}
      />
      <Route component={RedirectAs404} />
    </Switch>
  )
}

const MemoRouters = React.memo(Routers)

export { MemoRouters as Routers }
