import React from 'react'
import { Variables, Margin, Text } from '@intellihr/ui-components'
import { IInfoOption } from '../../../types'

const InfoOption: React.FC<{ option: IInfoOption }> = ({ option }) => {
  return (
    <Margin
      margins={{
        left: Variables.Spacing.sSmall,
        right: Variables.Spacing.sSmall,
        top: Variables.Spacing.sSmall,
        bottom: Variables.Spacing.sSmall,
      }}
    >
      <Text color={Variables.Color.n800}>{option.message}</Text>
    </Margin>
  )
}

export { InfoOption }
