import { PureQueryOptions } from '@apollo/client'
import { getSession } from 'src/domain/PlatformSetup/public/services/sessionManagementService'
import { GET_SUPPORTED_LANGUAGES } from 'src/domain/PlatformSetup/public/services/supportedLanguagesService'
import { useMutation } from 'src/services/apollo'
import { GET_PAGE_HEADER_ITEMS } from 'src/services/layout/components/PageFrame/components'
import { GET_NAVIGATION_ITEMS } from 'src/services/layout/components/PageFrame/components/Navigation/gql'
import { SESSION_DETAILS } from 'src/services/user/SessionDetailsProvider/hooks/useSessionProvider'
import { UPDATE_PREFERENCES } from 'src/domain/PlatformSetup/internal/pageContents/AccountSettingsPageContent/components/gql'
import type {
  UpdatePreferences,
  UpdatePreferencesVariables,
} from 'src/domain/PlatformSetup/internal/pageContents/AccountSettingsPageContent/components/__gql__/gqlCodeGen'

const useUpdatePreferencesWithRefetch = (successMessage: string, refetchQueries: PureQueryOptions[]) => {
  const userId = getSession().userId

  return useMutation<UpdatePreferences, UpdatePreferencesVariables>(UPDATE_PREFERENCES, {
    refetchQueries: [
      { query: SESSION_DETAILS, variables: { userId } },
      { query: GET_SUPPORTED_LANGUAGES },
      { query: GET_PAGE_HEADER_ITEMS },
      { query: GET_NAVIGATION_ITEMS },
      ...refetchQueries,
    ],
    successMessage,
  })
}

export { useUpdatePreferencesWithRefetch }
