import React from 'react'
import { BlueberryProvider } from '@intellihr/blueberry'
import { useUserContext } from 'src/services/user/UserContext/helpers/hook'
import { useBlueberryI18n } from 'src/services/blueberry/useBlueberryI18n'
import { useHistory } from 'react-router-dom'

const BlueberryDefaultsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { timezone, language } = useUserContext()
  const blueberryI18n = useBlueberryI18n()
  const history = useHistory()

  return (
    <BlueberryProvider
      theme={undefined} // fallback to internal default
      i18n={blueberryI18n}
      locale={language}
      userTimezone={timezone}
      anchorOnClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        // let the browser handle keyboard modifiers and blank targets
        if (
          e.currentTarget.pathname.startsWith('/spa/') &&
          !e.ctrlKey &&
          !e.metaKey && // windows key and cmd key
          !e.shiftKey &&
          !e.altKey &&
          e.currentTarget.target !== '_blank'
        ) {
          e.preventDefault()
          history.push(e.currentTarget.pathname + e.currentTarget.search + e.currentTarget.hash)
        }
      }}
    >
      {children}
    </BlueberryProvider>
  )
}

export { BlueberryDefaultsProvider }
