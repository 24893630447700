import { Field } from 'redux-form'
import {
  IReduxVerticalFormFieldParam,
  ReduxVerticalFormField,
  useValidationRulesForField,
} from 'src/services/reduxForm'
import { TextInput, Variables, Props, Text, FormattedText } from '@intellihr/ui-components'
import React, { useCallback, useState } from 'react'
import {
  CustomFieldValue,
  isSelectType,
  isPeopleDropdownType,
  isTextCustomFieldValue,
  isNumberCustomFieldValue,
  isSingleSelectCustomFieldValue,
  isMultiSelectCustomFieldValue,
  isPeopleDropdownCustomFieldValue,
  isTextTypeWithRegex,
} from 'src/domain/EDM/public/services/customFieldsTypeGuardService'
import type {
  ICustomFieldDefinitionSelectTypeDefinition,
  ICustomFieldDefinition,
} from 'src/domain/EDM/public/services/customFieldsTypeGuardService'
import { PeopleDropdownInput } from 'src/domain/EDM/public/components/inputs/PeopleDropdownInput'
import { useScope } from 'src/services/i18n/LocalizationProvider'
import { CustomInputsForSelectInput } from 'src/domain/EDM/internal/components/inputs/CustomInputsForSelectInput'
import { ISelectInputProps } from '@intellihr/ui-components/types/domain/Inputs/SelectInput/SelectInput'
import { CustomFieldDefinitionModelType } from 'src/__gql__/globalTypes'

interface ICustomFieldFormValues {
  [key: string]:
    | string
    | string[]
    | Array<{ value: string; name: string }>
    | Array<{ personId: string; jobId: string | null }>
}

type ICustomFieldApiInputValue =
  | string
  | string[]
  | { id: string }
  | { ids: string[] }
  | { person_ids: string[]; job_ids: Array<string | null> }

function isArrayOfStrings(testValue: unknown): testValue is string[] {
  if (!Array.isArray(testValue)) {
    return false
  }

  for (let i = 0; i < testValue.length; i++) {
    if (typeof testValue[i] !== 'string') {
      return false
    }
  }

  return true
}

function isPeopleDropdownFormValue(testValue: unknown): testValue is Array<{ personId: string; jobId: string | null }> {
  if (!Array.isArray(testValue)) {
    return false
  }

  for (let i = 0; i < testValue.length; i++) {
    if (
      !testValue[i] ||
      typeof testValue[i] !== 'object' ||
      typeof testValue[i].personId !== 'string' ||
      (testValue[i].jobId && typeof testValue[i].jobId !== 'string')
    ) {
      return false
    }
  }

  return true
}

const formattedDescription = (description?: string | null) => {
  if (!description) {
    return null
  }

  return <FormattedText text={description} />
}

const getSelectOptions = (options: ICustomFieldDefinitionSelectTypeDefinition['options']) => {
  return options.map(({ id, label }) => {
    return {
      id,
      name: label,
    }
  })
}

const CustomFieldFormField: React.FC<{
  customField: ICustomFieldDefinition
  sectionName?: string
  initialValue?: CustomFieldValue | null
  prefixSectionName?: boolean
  mandatoryOverride?: boolean
}> = ({ customField, sectionName, initialValue, prefixSectionName = false, mandatoryOverride }) => {
  const t = useScope('edm:features.CustomFields')
  const [valueChange, setValueChange] = useState(false)

  const validateDecimal = useValidationRulesForField(
    customField.apiName,
    ['decimal'],
    sectionName ? `${sectionName}.customFields` : 'customFields',
  )
  const validateRequired = useValidationRulesForField(
    customField.apiName,
    ['required'],
    sectionName ? `${sectionName}.customFields` : 'customFields',
  )

  const validateTextRegex = useCallback(
    (value?: string) => {
      if (isTextTypeWithRegex(customField.definition) && value && valueChange) {
        if (value.match(customField.definition.regex)) {
          return undefined
        }

        return customField.definition.validationError
      }

      return undefined
    },
    [customField, valueChange],
  )

  const fieldName = prefixSectionName
    ? `${sectionName}.customFields.${customField.apiName}`
    : `customFields.${customField.apiName}`

  const isMandatory = mandatoryOverride ?? customField.isMandatory

  switch (customField.type) {
    case 'NUMBER':
      return (
        <Field<IReduxVerticalFormFieldParam>
          key={customField.id}
          name={fieldName}
          validate={isMandatory ? [validateRequired, validateDecimal] : validateDecimal}
          component={ReduxVerticalFormField}
          props={{
            isRequired: isMandatory,
            label: customField.name,
            component: TextInput,
            componentContext: `number-input-custom-field-${customField.apiName}`,
            tooltipMessage: formattedDescription(customField.description) ?? undefined,
            tooltipProps: { width: 320 },
          }}
        />
      )
    case 'TEXT':
      return (
        <Field<IReduxVerticalFormFieldParam>
          key={customField.id}
          name={fieldName}
          component={ReduxVerticalFormField}
          validate={isMandatory ? [validateTextRegex, validateRequired] : validateTextRegex}
          onChange={() => {
            setValueChange(true)
          }}
          props={{
            isRequired: isMandatory,
            label: customField.name,
            component: TextInput,
            componentContext: `text-input-custom-field-${customField.apiName}`,
            tooltipMessage: formattedDescription(customField.description) ?? undefined,
            tooltipProps: { width: 320 },
            actionMessage:
              isTextCustomFieldValue(initialValue) && initialValue.valueUnion?.regexValid === false && !valueChange ? (
                <Text
                  color={Variables.Color.y600}
                  type={Props.TypographyType.Small}
                >
                  {t('actionMessageRegexInvalid')}
                </Text>
              ) : undefined,
          }}
        />
      )
    case 'MULTI_SELECT':
      if (isSelectType(customField.definition)) {
        return (
          <Field<IReduxVerticalFormFieldParam>
            key={customField.id}
            name={fieldName}
            component={ReduxVerticalFormField}
            validate={isMandatory ? validateRequired : undefined}
            props={{
              isRequired: isMandatory,
              label: customField.name,
              component: CustomFieldSelectInput,
              tooltipMessage: formattedDescription(customField.description) ?? undefined,
              tooltipProps: { width: 320 },
              inputProps: {
                isMultiSelect: true,
                componentContext: `multi-select-input-custom-field-${customField.apiName}`,
                options: getSelectOptions(customField.definition.options),
              },
            }}
          />
        )
      }
      return <></>
    case 'SINGLE_SELECT':
      if (isSelectType(customField.definition)) {
        return (
          <Field<IReduxVerticalFormFieldParam>
            key={customField.id}
            name={fieldName}
            component={ReduxVerticalFormField}
            validate={isMandatory ? validateRequired : undefined}
            props={{
              isRequired: isMandatory,
              label: customField.name,
              component: CustomFieldSelectInput,
              tooltipMessage: formattedDescription(customField.description) ?? undefined,
              tooltipProps: { width: 320 },
              inputProps: {
                componentContext: `single-select-input-custom-field-${customField.apiName}`,
                options: getSelectOptions(customField.definition.options),
              },
            }}
          />
        )
      }
      return <></>
    case 'PEOPLE_DROPDOWN':
      if (isPeopleDropdownType(customField.definition)) {
        return (
          <Field<IReduxVerticalFormFieldParam>
            key={customField.id}
            name={fieldName}
            component={ReduxVerticalFormField}
            validate={isMandatory ? validateRequired : undefined}
            props={{
              isRequired: isMandatory,
              label: customField.name,
              component: PeopleDropdownInput,
              actionMessage: (
                <Text
                  color={Variables.Color.n600}
                  type={Props.TypographyType.Small}
                  componentContext={`people-dropdown-input-custom-field-description-${customField.apiName}`}
                >
                  {t('peopleDropdownValueForm.formInputActionMessage')}
                </Text>
              ),
              tooltipMessage: formattedDescription(customField.description) ?? undefined,
              tooltipProps: { width: 320 },
              inputProps: {
                componentContext: `people-dropdown-input-custom-field-${customField.apiName}`,
                showIndividualJobs: customField.definition.allowSelectingIndividualJobs,
              },
            }}
          />
        )
      }
      return <></>
    default:
      return <></>
  }
}

interface IGetCustomFieldsFormFieldsParams {
  customFields: readonly ICustomFieldDefinition[]
  modelType: CustomFieldDefinitionModelType
  initialValues?: CustomFieldValue[]
  sectionName?: string
  prefixSectionName?: boolean
  mandatoryFieldOverrides?: { [key: string]: boolean }
}

const getCustomFieldsFormFields = ({
  customFields,
  modelType,
  initialValues,
  sectionName,
  prefixSectionName,
  mandatoryFieldOverrides,
}: IGetCustomFieldsFormFieldsParams) => {
  return customFields
    .filter((customField) => customField.modelType === modelType)
    .map((customField) => {
      const initialValue = initialValues?.find((initialValue) => initialValue.apiName === customField.apiName)
      return (
        <CustomFieldFormField
          customField={customField}
          key={customField.id}
          sectionName={sectionName}
          prefixSectionName={prefixSectionName}
          initialValue={initialValue}
          mandatoryOverride={mandatoryFieldOverrides ? mandatoryFieldOverrides[customField.id] : undefined}
        />
      )
    })
}

const CustomFieldSelectInput: React.FC<ISelectInputProps> = ({ ...props }) => {
  const { options, ...leftoverProps } = props
  return (
    <CustomInputsForSelectInput
      listOptions={options as unknown as ReadonlyArray<{ name: string; id: string }>}
      props={leftoverProps}
    />
  )
}

const convertCustomFieldFormValuesToApi = (
  customFields: ReadonlyArray<{ apiName: string; type: string; modelType: string }>,
  customFieldValues: ICustomFieldFormValues,
  initialValues: ICustomFieldFormValues = {},
  ignoreUnchanged = false,
  modelTypeFilter: string | undefined = undefined,
): Array<{ apiName: string; value: ICustomFieldApiInputValue | null }> =>
  Object.entries(customFieldValues).flatMap<{ apiName: string; value: ICustomFieldApiInputValue | null }>(
    ([apiName, value]) => {
      const customField = customFields.find(
        (field) => field.apiName === apiName && (field.modelType === modelTypeFilter || modelTypeFilter === undefined),
      )
      if (customField) {
        if (value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0)) {
          return [
            {
              apiName,
              value: null,
            },
          ]
        }

        if (customField.type === 'NUMBER' && typeof value === 'string') {
          return [
            {
              apiName,
              value,
            },
          ]
        }

        if (customField.type === 'TEXT' && typeof value === 'string') {
          if (apiName in initialValues) {
            const initialValue = initialValues[apiName]
            if (!ignoreUnchanged && initialValue === value) {
              return []
            }
          }

          return [
            {
              apiName,
              value,
            },
          ]
        }

        if (customField.type === 'SINGLE_SELECT' && typeof value === 'string') {
          return [
            {
              apiName,
              value: {
                id: value,
              },
            },
          ]
        }

        if (customField.type === 'MULTI_SELECT' && isArrayOfStrings(value)) {
          return [
            {
              apiName,
              value: {
                ids: value,
              },
            },
          ]
        }

        if (customField.type === 'PEOPLE_DROPDOWN' && isPeopleDropdownFormValue(value)) {
          return [
            {
              apiName,
              value: {
                person_ids: value.map((option) => option.personId),
                job_ids: value.map((option) => option.jobId),
              },
            },
          ]
        }
        throw Error(`custom field type ${customField.type} does not exist or has invalid value`)
      }

      return []
    },
  )

const convertCustomFieldApiValuesToForm = (customFieldValues: readonly CustomFieldValue[]): ICustomFieldFormValues =>
  customFieldValues.reduce((acc, customFieldValue) => {
    if (!customFieldValue.valueUnion) {
      acc[customFieldValue.apiName] = null
      return acc
    }

    if (isNumberCustomFieldValue(customFieldValue)) {
      acc[customFieldValue.apiName] = customFieldValue.valueUnion.number
    }

    if (isTextCustomFieldValue(customFieldValue)) {
      acc[customFieldValue.apiName] = customFieldValue.valueUnion.text
    }

    if (isSingleSelectCustomFieldValue(customFieldValue)) {
      acc[customFieldValue.apiName] = customFieldValue.valueUnion.id
    }

    if (isMultiSelectCustomFieldValue(customFieldValue)) {
      acc[customFieldValue.apiName] = customFieldValue.valueUnion.ids
    }

    if (isPeopleDropdownCustomFieldValue(customFieldValue)) {
      acc[customFieldValue.apiName] = customFieldValue.valueUnion.options.map((option) => ({
        personId: option.person.id,
        jobId: option.job?.id,
      }))
    }
    return acc
  }, {})

export type { ICustomFieldFormValues, ICustomFieldApiInputValue }
export {
  convertCustomFieldApiValuesToForm,
  convertCustomFieldFormValuesToApi,
  getCustomFieldsFormFields,
  CustomFieldFormField,
}
