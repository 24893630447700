import React, { useState, useCallback } from 'react'
import type { ApolloClient } from '@apollo/client'
import { getCurrentIsoDate, useUserTimezoneDateModifiers } from 'src/services/helpers/dates'
import { Spinner } from '@intellihr/blueberry'
import { PersonalDetailsFormStep } from './steps/PersonalDetailsFormStep/PersonalDetailsFormStep'
import { QualificationsStep } from './steps/QualificationsStep/QualificationsStep'
import { useMarkOnboardingStepAsComplete } from './hooks/useMarkOnboardingStepAsComplete'
import { type IOnboardingStatus, OnboardingStepType } from './types'
import { LandingPageStep } from './steps/LandingPageStep/LandingPageStep'
import { IOnboardingPerson } from './steps/PersonalDetailsFormStep/hooks/useGetOnboardingFormInstanceAndPerson'
import { SuperAPIDetailStep } from './steps/SuperAPIDetailStep/SuperAPIDetailStep'

// We pass the redirect in as a prop to prevent errors during testing
interface IOnboardingPageContentProps {
  dashboardRedirect: () => void
  activeFrom: DateTime | null
  status: IOnboardingStatus
  onboardingPerson: IOnboardingPerson | null
  apolloClient: ApolloClient<object>
}

// We only the value and not the Enum key itself
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onboardingStepTypesArray: string[] = Object.entries(OnboardingStepType).map(([_, value]) => value)

const OnboardingPageContent: React.FC<IOnboardingPageContentProps> = ({
  dashboardRedirect,
  activeFrom,
  status,
  onboardingPerson,
  apolloClient,
}) => {
  const { isSameOrBefore } = useUserTimezoneDateModifiers()
  const [currentStepId, setCurrentStepId] = useState(status?.currentStep?.onboardingStepType.id)
  const [markOnboardingStepAsComplete, { loading: isMarkingOnboardingStepAsComplete }] =
    useMarkOnboardingStepAsComplete()
  const onboardingJobId = status.currentStep?.job?.id

  const progressStep = useCallback(() => {
    // Safety check incase somehow this is called on the landing page
    if (currentStepId === OnboardingStepType.Completed) {
      return
    }

    let currentStepIndex = onboardingStepTypesArray.indexOf(currentStepId!) + 1
    // Set next step to the current step to that there's no null case
    let nextStepId = currentStepId

    while (currentStepIndex < onboardingStepTypesArray.length) {
      nextStepId = onboardingStepTypesArray[currentStepIndex]

      // Is the next step apart of the required onboarding steps for this person
      const stepInOnboardingPlan = status.onboardingSteps.find((step) => step?.onboardingStepType.id === nextStepId)

      if (stepInOnboardingPlan && (!stepInOnboardingPlan.completedAt || stepInOnboardingPlan.completedAt === null)) {
        break
      }

      // if we get here increment the index and try again
      currentStepIndex++
    }

    setCurrentStepId(nextStepId)
  }, [status, currentStepId, setCurrentStepId])

  const markStepAsComplete = (step: OnboardingStepType, skip = false) => {
    if (!isMarkingOnboardingStepAsComplete) {
      markOnboardingStepAsComplete({
        variables: {
          input: {
            onboardingStepTypeId: step,
            jobId: onboardingJobId,
            skipRequirementValidation: skip ?? false,
          },
        },
      }).then((response) => {
        if (response && response.data && response.data.markOnboardingStepAsComplete.success) {
          progressStep()
        }
      })
    }
  }

  const handleLandingPageRender = (
    activeFrom: DateTime | null,
    dashboardRedirect: () => void,
    apolloClient: ApolloClient<object>,
  ) => {
    return (
      <LandingPageStep
        startDate={activeFrom}
        dashboardRedirect={dashboardRedirect}
        apolloClient={apolloClient}
      />
    )
  }

  // If the user is not onboarding and not prestart redirect to the dashboard
  if (!status.isOnboarding || status.currentStep === null) {
    const today = getCurrentIsoDate()
    const hasPreStartPassed = isSameOrBefore(activeFrom, today) ?? false
    if (activeFrom === null || hasPreStartPassed) {
      dashboardRedirect()
      return <Spinner />
    }

    return handleLandingPageRender(activeFrom, dashboardRedirect, apolloClient)
  }

  switch (currentStepId) {
    case OnboardingStepType.PersonalDetails:
      return (
        <PersonalDetailsFormStep
          onboardingPerson={onboardingPerson!}
          onboardingFormId={
            status.onboardingSteps.find((step) => step?.onboardingStepType.id === currentStepId)!
              .onboardingFormInstance!.id
          }
          onSubmit={progressStep}
        />
      )
    case OnboardingStepType.SuperAPIDetails:
      return (
        <SuperAPIDetailStep
          onboardingSessionId={status.currentStep?.onboardingSessionId}
          onSubmit={() => markStepAsComplete(OnboardingStepType.SuperAPIDetails)}
        />
      )
    case OnboardingStepType.RequiredQualifications:
      return (
        <QualificationsStep
          activeFrom={activeFrom}
          onboardingPerson={onboardingPerson!}
          onSubmit={markStepAsComplete}
        />
      )
    case OnboardingStepType.Completed:
      return handleLandingPageRender(activeFrom, dashboardRedirect, apolloClient)
  }

  // Should be unreachable
  // But we need it to satisfy typescript
  return null
}

export { OnboardingPageContent }
