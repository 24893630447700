/* eslint-disable dot-notation */
import { isArrayOfStrings } from 'src/services/helpers'
import { CustomFieldDefinitionModelType, CustomFieldType } from 'src/__gql__/globalTypes'
import {
  CustomFieldDefinitionFragment,
  CustomFieldValueUnionFragment,
} from '../customFieldsGQLService/__gql__/indexCodeGen'

type ValueUnionMulti = Extract<
  NonNullable<CustomFieldValueUnionFragment['valueUnion']>,
  { __typename?: 'CustomFieldValueMultiSelect' }
>
type ValueUnionNumber = Extract<
  NonNullable<CustomFieldValueUnionFragment['valueUnion']>,
  { __typename?: 'CustomFieldValueNumber' }
>
type ValueUnionPeopleDropdown = Extract<
  NonNullable<CustomFieldValueUnionFragment['valueUnion']>,
  { __typename?: 'CustomFieldValuePeopleDropdown' }
>
type ValueUnionSingle = Extract<
  NonNullable<CustomFieldValueUnionFragment['valueUnion']>,
  { __typename?: 'CustomFieldValueSingleSelect' }
>
type ValueUnionText = Extract<
  NonNullable<CustomFieldValueUnionFragment['valueUnion']>,
  { __typename?: 'CustomFieldValueText' }
>

interface ICustomFieldDefinitionSelectTypeDefinition {
  options: ReadonlyArray<{
    id: string
    label: string
  }>
}

interface ICustomFieldDefinitionPeopleDropdownDefinition {
  readonly __typename: 'CustomFieldDefinitionPeopleDropdownTypeDefinition'
  allowSelectingIndividualJobs: boolean
}

interface ICustomFieldDefinitionTextTypeDefinition {
  regex: string
  validationError: string
}

interface ICustomFieldDefinition {
  id: string
  apiName: string
  name: string
  modelType: CustomFieldDefinitionModelType
  type: string
  definition?:
    | ICustomFieldDefinitionSelectTypeDefinition
    | ICustomFieldDefinitionPeopleDropdownDefinition
    | ICustomFieldDefinitionTextTypeDefinition
    | {}
    | null
  description?: string | null
  isMandatory: boolean
}

type BaseCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion?: object | null
}

type NumberCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion: ValueUnionNumber
}

type TextCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion: ValueUnionText | null
}

type SingleSelectCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion: ValueUnionSingle
}

type MultiSelectCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion: ValueUnionMulti
}

type PeopleDropdownCustomFieldValue = {
  apiName: string
  type: CustomFieldType
  valueUnion: ValueUnionPeopleDropdown
}

type CustomFieldValue = CustomFieldValueUnionFragment

type CustomFieldDefinition = {
  definition: CustomFieldDefinitionFragment
}

const isSelectType = (definition: unknown): definition is ICustomFieldDefinitionSelectTypeDefinition => {
  if (typeof definition !== 'object' || !definition) {
    return false
  }

  // eslint-disable-next-line dot-notation
  return definition['options'] !== undefined
}

const isPeopleDropdownType = (definition: unknown): definition is ICustomFieldDefinitionPeopleDropdownDefinition => {
  if (typeof definition !== 'object' || !definition) {
    return false
  }

  // eslint-disable-next-line dot-notation
  return definition['allowSelectingIndividualJobs'] !== undefined
}

const isTextTypeWithRegex = (definition: unknown): definition is ICustomFieldDefinitionTextTypeDefinition => {
  if (typeof definition !== 'object' || !definition) {
    return false
  }

  return 'regex' in definition || 'validationError' in definition
}

function isBaseCustomFieldValue(customFieldValue: unknown): customFieldValue is BaseCustomFieldValue {
  if (typeof customFieldValue !== 'object' || !customFieldValue) {
    return false
  }

  const customFieldValueTestable: { apiName?: unknown; type?: unknown } = customFieldValue

  if (typeof customFieldValueTestable.apiName !== 'string' || typeof customFieldValueTestable.type !== 'string') {
    return false
  }

  return true
}

function isTextCustomFieldValue(test: unknown): test is TextCustomFieldValue {
  if (!isBaseCustomFieldValue(test)) {
    return false
  }

  const type = test.type === 'TEXT'
  const valueUnion = test.valueUnion === null || typeof test.valueUnion?.['text'] === 'string'

  return type && valueUnion
}

function isNumberCustomFieldValue(test: unknown): test is NumberCustomFieldValue {
  if (!isBaseCustomFieldValue(test)) {
    return false
  }

  const type = test.type === 'NUMBER'
  const valueUnion = test.valueUnion === null || typeof test.valueUnion?.['number'] === 'string'

  return type && valueUnion
}

function isSingleSelectCustomFieldValue(test: unknown): test is SingleSelectCustomFieldValue {
  if (!isBaseCustomFieldValue(test)) {
    return false
  }

  const type = test.type === 'SINGLE_SELECT'
  const valueUnion =
    test.valueUnion === null ||
    (typeof test.valueUnion?.['id'] === 'string' && typeof test.valueUnion?.['label'] === 'string')

  return type && valueUnion
}

function isMultiSelectCustomFieldValue(test: unknown): test is MultiSelectCustomFieldValue {
  if (!isBaseCustomFieldValue(test)) {
    return false
  }

  const type = test.type === 'MULTI_SELECT'
  const valueUnion =
    test.valueUnion === null ||
    (isArrayOfStrings(test.valueUnion?.['ids']) && isArrayOfStrings(test.valueUnion?.['labels']))

  return type && valueUnion
}

function isPeopleDropdownCustomFieldValue(test: unknown): test is PeopleDropdownCustomFieldValue {
  if (!isBaseCustomFieldValue(test)) {
    return false
  }

  const type = test.type === 'PEOPLE_DROPDOWN'
  const valueUnion =
    test.valueUnion === null ||
    (Array.isArray(test.valueUnion?.['options']) &&
      (test.valueUnion?.['options'].every((option) => option.person) ?? false))

  return type && valueUnion
}

function isCustomFieldValue(customFieldValue: unknown): customFieldValue is CustomFieldValue {
  const isText = isTextCustomFieldValue(customFieldValue)
  const isNumber = isNumberCustomFieldValue(customFieldValue)
  const isSingleSelect = isSingleSelectCustomFieldValue(customFieldValue)
  const isMultiSelect = isMultiSelectCustomFieldValue(customFieldValue)
  const isPeopleDropdown = isPeopleDropdownCustomFieldValue(customFieldValue)

  return isText || isNumber || isSingleSelect || isMultiSelect || isPeopleDropdown
}

function isCustomFieldDefinition(customFieldValue: unknown): customFieldValue is CustomFieldDefinition {
  if (typeof customFieldValue !== 'object' || !customFieldValue) {
    return false
  }

  return customFieldValue['definition'] !== undefined
}

export type {
  CustomFieldValue,
  CustomFieldDefinition,
  ICustomFieldDefinitionSelectTypeDefinition,
  ICustomFieldDefinitionPeopleDropdownDefinition,
  ICustomFieldDefinitionTextTypeDefinition,
  ICustomFieldDefinition,
  ValueUnionMulti,
  ValueUnionPeopleDropdown,
  ValueUnionText,
  ValueUnionNumber,
  ValueUnionSingle,
}
export {
  isCustomFieldValue,
  isCustomFieldDefinition,
  isTextCustomFieldValue,
  isNumberCustomFieldValue,
  isSingleSelectCustomFieldValue,
  isMultiSelectCustomFieldValue,
  isPeopleDropdownCustomFieldValue,
  isSelectType,
  isPeopleDropdownType,
  isTextTypeWithRegex,
}
