import React from 'react'
import { Route } from 'react-router'
import { Switch as ReactRouterSwitch } from 'react-router-dom'
import { RedirectAs404 } from 'src/services/reactRouter'

interface ISwitchProps {
  disable404?: boolean
}

class Switch extends React.PureComponent<React.PropsWithChildren<ISwitchProps>> {
  public render() {
    const { children, disable404 = false } = this.props

    return (
      <ReactRouterSwitch>
        {children}

        {!disable404 && <Route component={RedirectAs404} />}
      </ReactRouterSwitch>
    )
  }
}

export { Switch }
